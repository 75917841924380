import { Breadcrumbs, Link, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import WebhookEventsFilter from 'components/Filter/WebhookEvents/WebhookEventsFilter'
import { ContentColumn, SubHeader } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { SubscriptionWebhook } from 'models'
import { Link as RouterLink } from 'react-router-dom'

import WebhookEventsTable, { WebhookEventsTableProps } from '../WebhookEventsTable/WebhookEventsTable'
import styles from './WebhookEvents.module.scss'

export interface WebhookEventsProps {
  webhook?: SubscriptionWebhook
  isLoadingWebhooks?: boolean
}

export default function WebhookEvents({
  webhook,
  isLoadingWebhooks,
  ...tableProps
}: WebhookEventsProps & WebhookEventsTableProps) {
  const { currentSubscriptionId } = useCurrentSubscription()

  const isEmpty = tableProps.data.length === 0
  const hasData = tableProps.isFilterApplied || !isEmpty
  const showFilter = hasData && !tableProps.error && tableProps.onFilter

  return (
    <>
      <Breadcrumbs aria-label='breadcrumbs' separator='/' className='mt-2'>
        <Link
          component={RouterLink}
          to={buildRoute(AppRoute.Webhooks, { subscriptionId: currentSubscriptionId })}
          color='inherit'
          underline='hover'
        >
          Webhooks
        </Link>
        <Typography component='span'>Webhook events</Typography>
      </Breadcrumbs>
      <ContentColumn>
        <SubHeader
          title={<Typography variant='h2'>{webhook?.url ?? 'Webhook events'}</Typography>}
          description={webhook?.description ?? null}
          actions={
            showFilter && <WebhookEventsFilter isApplied={tableProps.isFilterApplied} onFilter={tableProps.onFilter} />
          }
        />
        <WebhookEventsTable {...tableProps} className={styles.tableContainer} />
      </ContentColumn>
    </>
  )
}
