import { BillingType, RegionCode } from 'models'

import { FREE_SUBSCRIPTION_API_CALLS_LIMIT, FREE_SUBSCRIPTION_UNIQUE_VISITORS_LIMIT, REGIONS } from '../const'

export function getRegionByCode(code: RegionCode) {
  return REGIONS[code]
}

export function getFreeSubscriptionLimit(billingType: BillingType) {
  return billingType === BillingType.UniqueVisitors
    ? FREE_SUBSCRIPTION_UNIQUE_VISITORS_LIMIT
    : FREE_SUBSCRIPTION_API_CALLS_LIMIT
}
