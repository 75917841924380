import { Button } from '@compass/components'
import { InfoOutlined } from '@mui/icons-material'
import {
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { EntityEmptyState, SubHeader } from 'features/commonUI'
import { usePermissions } from 'hooks/permissions'
import {
  TrafficRule,
  TrafficRuleCreatePayload,
  TrafficRulePermissionType,
  TrafficRuleType,
  WorkspaceEnvironment,
} from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useMemo, useState } from 'react'

import { AllowListDialog } from '../AllowListDialog/AllowListDialog'
import styles from './AllowList.module.scss'

interface AllowListProps {
  handleBulkUpdateHeaderRules: (payload: TrafficRuleCreatePayload[]) => void
  isLoading: boolean
  trafficRules: TrafficRule[]
  trafficRuleType: TrafficRuleType.Origin | TrafficRuleType.AppPackageName
  isCreationLimitExceeded: boolean
  currentEnvironment: WorkspaceEnvironment
}

export function AllowList({
  handleBulkUpdateHeaderRules,
  isLoading,
  trafficRules,
  trafficRuleType,
  isCreationLimitExceeded,
  currentEnvironment,
}: AllowListProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { isReadOnly } = usePermissions()

  const onConfigureClick = useCallback(() => {
    ampli.configureOriginsClicked()
    setIsDialogOpen(true)
  }, [])

  const onSubmit = useCallback(
    (data: TrafficRuleCreatePayload[]) => {
      handleBulkUpdateHeaderRules(data)
      setIsDialogOpen(false)
    },
    [handleBulkUpdateHeaderRules]
  )

  return (
    <>
      <AllowListView
        trafficRuleType={trafficRuleType}
        onConfigureClick={onConfigureClick}
        isLoading={isLoading}
        trafficRules={trafficRules}
        isReadOnly={isReadOnly}
        isCreationLimitExceeded={isCreationLimitExceeded}
        currentEnvironment={currentEnvironment}
      />
      <AllowListDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSubmit={onSubmit}
        trafficRules={trafficRules}
        environment={currentEnvironment}
        trafficRuleType={trafficRuleType}
      />
    </>
  )
}

interface TextVariants {
  heading: string
  subHeading: string
  allowedCellName: string
  allowedSubHeading: string
  allAllowedText: string
  forbiddenCellName: string
  forbiddenSubHeading: string
  allForbiddenText: string
  forbiddenEmptyText: string
}

const labelsByTrafficRule: Record<TrafficRuleType.Origin | TrafficRuleType.AppPackageName, TextVariants> = {
  [TrafficRuleType.Origin]: {
    heading: 'Websites',
    subHeading: 'Filter unauthorized identification requests by origin.',
    allowedCellName: 'Allowed Origins',
    allowedSubHeading: 'Specify the origins that are allowed to make identification requests using your API keys.',
    allAllowedText: 'All websites are allowed by default',
    forbiddenCellName: 'Forbidden Origins',
    forbiddenSubHeading:
      'Specify the origins that are prohibited from making identification requests using your API keys.',
    allForbiddenText: 'All websites are forbidden by default',
    forbiddenEmptyText: 'No forbidden websites',
  },
  [TrafficRuleType.AppPackageName]: {
    heading: 'Apps',
    subHeading: 'Filter unauthorized identification requests from other apps.',
    allowedCellName: 'Allowed Apps',
    allowedSubHeading: 'Specify the apps that are allowed to make identification requests using your API keys.',
    allAllowedText: 'All apps are allowed by default',
    forbiddenCellName: 'Forbidden Apps',
    forbiddenSubHeading:
      'Specify the apps that are prohibited from making identification requests using your API keys.',
    allForbiddenText: 'All apps are forbidden by default',
    forbiddenEmptyText: 'No forbidden apps',
  },
}

export interface AllowListViewProps {
  trafficRules: TrafficRule[]
  trafficRuleType: TrafficRuleType.Origin | TrafficRuleType.AppPackageName
  className?: string
  isLoading?: boolean
  isReadOnly: boolean
  isCreationLimitExceeded: boolean
  onConfigureClick: (environment: WorkspaceEnvironment | undefined) => unknown
  currentEnvironment: WorkspaceEnvironment
}

export function AllowListView({
  trafficRules,
  trafficRuleType,
  className,
  isLoading,
  isReadOnly,
  isCreationLimitExceeded,
  onConfigureClick,
  currentEnvironment,
}: AllowListViewProps) {
  const relevantTrafficRules = trafficRules.filter(
    (rule) => rule.type === trafficRuleType && rule.workspaceEnvironmentId === currentEnvironment?.id
  )
  const allowedRules = relevantTrafficRules.filter(
    (rule) =>
      rule.permissionType === TrafficRulePermissionType.Allow &&
      rule.workspaceEnvironmentId === currentEnvironment?.id &&
      rule.value !== '*'
  )
  const forbiddenRules = relevantTrafficRules.filter(
    (rule) =>
      rule.permissionType === TrafficRulePermissionType.Deny &&
      rule.workspaceEnvironmentId === currentEnvironment?.id &&
      rule.value !== '*'
  )
  const asterisksRule = relevantTrafficRules.find(
    (rule) => rule.workspaceEnvironmentId === currentEnvironment?.id && rule.value === '*'
  )

  const labels = useMemo(() => labelsByTrafficRule[trafficRuleType], [trafficRuleType])

  const isEmpty =
    (!relevantTrafficRules.length || (relevantTrafficRules.length === 1 && asterisksRule !== undefined)) && !isLoading

  return (
    <Grid item xs={12} className={className}>
      <SubHeader
        title={labels.heading}
        description={<Typography variant='bodyM'>{labels.subHeading}</Typography>}
        actions={
          isEmpty ? null : (
            <Button onPress={() => onConfigureClick(currentEnvironment)} isDisabled={isReadOnly}>
              Configure
            </Button>
          )
        }
        className='mb-4'
      />

      {isEmpty ? (
        <Paper>
          <EntityEmptyState
            isButtonDisabled={isReadOnly || isCreationLimitExceeded}
            entity={trafficRuleType === TrafficRuleType.Origin ? 'origin_traffic_rule' : 'mobile_traffic_rule'}
            title={`No ${trafficRuleType === TrafficRuleType.Origin ? 'origin' : 'package'} rules`}
            description={`All ${
              trafficRuleType === TrafficRuleType.Origin ? 'websites' : 'apps'
            } are allowed by default.`}
            buttonText='Configure'
            onClick={() => onConfigureClick(currentEnvironment)}
          />
        </Paper>
      ) : (
        <div className={styles.blocks}>
          <TableContainer component={Paper} className={styles.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Grid className={styles.titleGrid}>
                      <Typography variant='inherit'>{labels.allowedCellName}</Typography>
                      <Tooltip className={styles.tooltip} title={labels.allowedSubHeading} arrow placement='top'>
                        <InfoOutlined fontSize='tiny' />
                      </Tooltip>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {isLoading ? (
                    <TableCell align='center'>
                      <Skeleton data-testid='websites-loading-skeleton-1' />
                    </TableCell>
                  ) : (
                    <TableCell className={styles.tableCell}>
                      {(relevantTrafficRules.length === 0 ||
                        asterisksRule?.permissionType === TrafficRulePermissionType.Allow) && (
                        <Typography variant='body2' color='textSecondary'>
                          {labels.allAllowedText}
                        </Typography>
                      )}
                      {allowedRules.map((rule) => (
                        <div key={rule.id}>{rule.value}</div>
                      ))}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer component={Paper} className={styles.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Grid className={styles.titleGrid}>
                      <Typography variant='inherit'>{labels.forbiddenCellName}</Typography>
                      <Tooltip className={styles.tooltip} title={labels.forbiddenSubHeading} arrow placement='top'>
                        <InfoOutlined fontSize='tiny' />
                      </Tooltip>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {isLoading ? (
                    <TableCell align='center'>
                      <Skeleton data-testid='websites-loading-skeleton-2' />
                    </TableCell>
                  ) : (
                    <TableCell className={styles.tableCell}>
                      {asterisksRule?.permissionType === TrafficRulePermissionType.Deny ? (
                        <Typography variant='body2' color='textSecondary'>
                          {labels.allForbiddenText}
                        </Typography>
                      ) : (
                        forbiddenRules.length === 0 && (
                          <Typography variant='body2' color='textSecondary'>
                            {labels.forbiddenEmptyText}
                          </Typography>
                        )
                      )}
                      {forbiddenRules.map((rule) => (
                        <Typography variant='body2' key={rule.id}>
                          {rule.value}
                        </Typography>
                      ))}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </Grid>
  )
}
