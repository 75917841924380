import { Button, DialogContent, DialogTrigger, LinkButton, ModalOverlay, Stack } from '@compass/components'
import { MDXProvider } from '@mdx-js/react'
import { Heading } from 'react-aria-components'
import { tv } from 'tailwind-variants'

import NavMdx from './entries/2/navigation.mdx'

const headerStyles = tv({
  base: 'flex flex-shrink-0 items-center h-11 pl-5',
})

export function NavigationAnnouncementModal() {
  return (
    <DialogTrigger>
      <Button variant='secondary'>See changes</Button>
      <ModalOverlay isDismissable>
        <DialogContent className='max-h-[90dvh]'>
          {({ close }) => (
            <>
              <header className={headerStyles()}>
                <Heading slot='title' className='mt-1.5 text-lg font-medium'>
                  What&apos;s new
                </Heading>
              </header>
              <div className='absolute top-6 w-full h-6 flex flex-col items-center justify-end px-1'>
                <div className='w-full scale-90 bg-white border border-b-0 rounded-t-lg h-1 opacity-30' />
                <div className='w-full scale-95 bg-white border border-b-0 rounded-t-lg h-1 opacity-60' />
              </div>
              <Stack
                gap={0}
                direction='column'
                className='relative z-10 m-1 px-8 py-7 overflow-y-auto rounded-t-lg bg-white border border-b-0'
              >
                <MDXProvider>
                  <div className='prose'>
                    <NavMdx /> {/* Render the imported MDX file */}
                  </div>
                </MDXProvider>
              </Stack>
              <Stack className='sticky bottom-0 justify-between mx-1 -mt-1 mb-1 p-4 bg-gray-100 border rounded-b-lg'>
                <Button onPress={close} variant='secondary' alt>
                  Dismiss
                </Button>
                <LinkButton
                  href='https://fingerprint.com/blog/product-updates-dashboard-improvements-november-2024/'
                  target='_blank'
                >
                  Read the blog
                </LinkButton>
              </Stack>
            </>
          )}
        </DialogContent>
      </ModalOverlay>
    </DialogTrigger>
  )
}
