import { useRouteState } from 'hooks/router'
import { useState } from 'react'

import UpgradeConfirmationDialog from '../UpgradeConfirmationDialog/UpgradeConfirmationDialog'

export function ConditionalUpgradeConfirmationDialog() {
  const routeState = useRouteState<{ isUpgradeConfirmationDialogOpen?: boolean }>()
  const [isUpgradeConfirmationDialogOpen, setIsUpgradeConfirmationDialogOpen] = useState(
    routeState?.isUpgradeConfirmationDialogOpen ?? false
  )

  return (
    <UpgradeConfirmationDialog
      isOpen={isUpgradeConfirmationDialogOpen}
      onClose={() => setIsUpgradeConfirmationDialogOpen(false)}
    />
  )
}
