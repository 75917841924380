import { Button } from '@compass/components'
import { Paper, TextField, Typography } from '@mui/material'
import { APITypesMap } from 'const'
import { isValidSubDomain } from 'helpers/validation'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import styles from './Create.module.scss'
import StepProps from './types'

interface CreateStepProps extends StepProps {
  onSubmit: (data: FormData) => void
  isLoading?: boolean
}

type FormData = APITypesMap['certificateCreate']['body'] & { domain: string }

export default memo(function CreateStep({ certificate, onSubmit, isLoading }: CreateStepProps) {
  const {
    register,
    setError,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      domain: certificate?.sans?.[0] ?? '',
    },
  })

  const [isValidDomain, setIsValidDomain] = useState(false)
  const domain = watch('domain')

  useEffect(() => {
    setIsValidDomain(isValidSubDomain(domain))
  }, [domain])

  const onFormSubmit = useCallback(
    (data: FormData) => {
      if (!data.domain) {
        setError('domain', {
          type: 'manual',
          message: 'You should provide a domain',
        })
        return
      }
      onSubmit(data)
    },
    [onSubmit, setError]
  )

  const handleDomainNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value.trim()
      if (!isValidSubDomain(newValue)) {
        setError('domain', {
          type: 'manual',
          message: 'Invalid domain format',
        })
      } else {
        clearErrors('domain')
      }
    },
    [setError, clearErrors]
  )

  const isDisabled = isLoading || !!errors.domain || !isValidDomain

  return (
    <Paper className={styles.container}>
      <Typography component='h2' variant='h6' className={styles.title}>
        Enter domain name
      </Typography>

      <form onSubmit={handleSubmit(onFormSubmit)} className={styles.form}>
        <TextField
          {...register('domain', {
            required: 'Domain is required',
            onChange: handleDomainNameChange,
          })}
          variant='outlined'
          id='domain_field'
          error={!!errors.domain}
          className={styles.domainNameInput}
          placeholder='metrics.yoursite.com'
          helperText={errors.domain?.message}
          fullWidth
          margin='normal'
          disabled={isLoading}
        />

        <Button type='submit' isDisabled={isDisabled}>
          Next
        </Button>
      </form>
    </Paper>
  )
})
