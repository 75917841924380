import { Box, Tab, Tabs, Typography } from '@mui/material'
import { ReactNode } from 'react'

interface EditableTab {
  id: string
  name: string
  component: ReactNode
}

export interface EditableTabsProps {
  selected: string
  onChange: (newTab: string) => void
  dirtyTabs: Record<string, boolean>
  tabs: EditableTab[]
}

export function EditableTabs({ selected, onChange, dirtyTabs, tabs }: EditableTabsProps) {
  return (
    <Box>
      <Tabs
        value={selected}
        onChange={(_, newValue) => onChange(newValue)}
        variant='scrollable'
        scrollButtons={false}
        sx={{
          height: '36px',
          minHeight: '36px',
          position: 'relative',

          '&::before': {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '1px',
            backgroundColor: '#E0E0E0',
            content: '""',
          },
        }}
      >
        {tabs.map(({ id, name }) => (
          <Tab
            data-testid={`tab-${id}`}
            key={id}
            label={<Label name={name} isDirty={dirtyTabs[id]} isSelected={selected === id} />}
            value={id}
            disableRipple
            sx={{
              padding: '16px 0',
              paddingTop: 0,
              marginRight: '24px',
              height: '36px',
              minHeight: '36px',
              minWidth: 0,
            }}
          />
        ))}
      </Tabs>

      {tabs.map(({ id, component }) => (
        <Box
          key={id}
          role='tabpanel'
          hidden={selected !== id}
          id={`tabpanel-${id}`}
          aria-labelledby={`tab-${id}`}
          marginTop='24px'
        >
          {component}
        </Box>
      ))}
    </Box>
  )
}

interface LabelProps {
  name: string
  isDirty?: boolean
  isSelected?: boolean
}

function Label({ name, isDirty, isSelected }: LabelProps) {
  return (
    <Box component='span' display='flex' flexDirection='row' alignItems='center' gap='8px' textTransform='none'>
      <Typography component='span' variant='bodyMMedium' color={isSelected ? undefined : '#757575'}>
        {name}
      </Typography>
      {isDirty && (
        <Typography
          component='span'
          variant='bodyS'
          sx={{
            color: '#663D00',
            display: 'flex',
            padding: '2px 8px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '44px',
            background: '#FFEFD1',
          }}
        >
          Unsaved changes
        </Typography>
      )}
    </Box>
  )
}
