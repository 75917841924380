import { useMutation, UseMutationOptions, UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { API, APITypesMap, apiUrl, EndpointList, GenericError, GenericResponse, SuccessResponse } from 'const'
import { useToast } from 'hooks/toast'
import { v4 as uuidv4 } from 'uuid'

import { useRefreshableJWTStorage } from '../jwt'

export type RequestMutationConfig<K extends EndpointList> = UseMutationOptions<
  APITypesMap[K]['response'],
  GenericError,
  MutationVars<K>
> & {
  errorHandling?: ErrorHandlingConfig
}

export type RequestMutationResult<K extends EndpointList> = UseMutationResult<
  APITypesMap[K]['response'],
  GenericError,
  MutationVars<K>,
  unknown
>

/**
 * Shortcut for creating any API mutations with react query
 * @param name API controller name
 *
 * @example
 * const { mutate: sendData, isLoading } = useRequestMutation('logIn')
 */
export function useRequestMutation<K extends EndpointList>(
  name: K,
  config?: RequestMutationConfig<K>
): RequestMutationResult<K> {
  const buildRequest = useBuildRequest()

  const { withErrorHandling } = useGenericErrorHandler(config?.errorHandling)

  return useMutation<APITypesMap[K]['response'], GenericError, MutationVars<K>>(async (options) => {
    const { data } = await withErrorHandling(buildRequest(name, options))

    return data
  }, config)
}

export type MutationVars<K extends EndpointList> = {
  data?: APITypesMap[K]['body']
  params?: APITypesMap[K]['params']
  queryParams?: APITypesMap[K]['queryParams']
  expand?: Array<APITypesMap[K]['expandable']>
}

/**
 * Nicely typed generic wrapper for any API request.
 * It uses API declaration structures for building request, input and output typing
 *
 * In this way we have an opportunity to rename it in place of use:
 *
 * @param name API controller name
 *
 * @example
 * try {
 *   const { ok, data, meta } = await buildRequest('logIn', { email: '', password: '' })
 * } catch (e) {
 *   console.log(e.param, e.message)
 * }
 */
export function useBuildRequest() {
  const { getAccessToken, clearTokens } = useRefreshableJWTStorage()

  return async function buildRequest<K extends EndpointList>(
    name: K,
    options: MutationVars<K> = {},
    signal?: AbortSignal
  ) {
    const { method, url, authorization, propagateErrorMessage, isUnwrapped } = API[name]
    const params: Record<string, string> = options.params || {}
    const queryParams = options.queryParams || ({} as Record<string, any>)

    const requestURL = apiUrl(url.replace(/:(\w+)/g, (_, param) => params[param]))

    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      'X-Correlation-Id': uuidv4(),
    }

    // set token
    const bearerToken = await getAccessToken()

    if (authorization && bearerToken) {
      headers['Authorization'] = `Bearer ${bearerToken}`
    }

    if (queryParams) {
      Object.entries(queryParams!).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((param) => {
            requestURL.searchParams.append(`${key}[]`, param)
          })
        } else {
          requestURL.searchParams.append(key, value)
        }
      })
    }

    // set expands
    options?.expand?.forEach((expand) => {
      requestURL.searchParams.append('expand[]', expand!)
    })

    // TODO: error logging

    return fetch(requestURL, {
      method,
      headers,
      body: JSON.stringify(options.data),
      signal,
    })
      .then(async (response) => {
        if (response.status === 401) {
          clearTokens()
          return
        }

        if (response.status === 403) {
          const errorBody = await response.json().catch(() => null)
          if (errorBody?.error?.message) {
            throw new Error(errorBody.error.message)
          }
          throw new Error('You are not authorized to perform that action.')
        }

        if (response.status === 429) {
          throw new Error('You are sending too many requests. Try again later.')
        }

        if (!response.ok) {
          const errorBody = await response.json().catch(() => null)
          // mgmt API will be sending non-200 responses
          // when ok=false
          // so !response.ok will be catching all those cases that were previously caught
          // in the next `then`, therefore we are parsing json body and passing control there
          if (errorBody?.ok !== undefined) {
            return errorBody
          }
          if (propagateErrorMessage) {
            if (errorBody?.error?.message) {
              throw new Error(errorBody.error.message, { cause: errorBody.error.data })
            }
          }

          throw new Error('Something went wrong')
        }

        if (!response.headers.get('Content-Type')?.includes('application/json')) {
          throw new Error('Invalid Response Encoding')
        }

        const data = response.json()

        return isUnwrapped
          ? {
              ok: true,
              data,
            }
          : data
      })
      .then((response: GenericResponse<APITypesMap[K]['response'], APITypesMap[K]['meta']>) => {
        if (response?.ok) {
          return response
        }

        // If server has not provided a error, fallback to hardcoded one
        return Promise.reject(response?.error || new Error('Something has gone wrong'))
      })
  }
}

/**
 * Since we use react-query hooks and some endpoints return meta information, we should have
 * an easy way to access meta information.
 *
 * @example
 * const = { data: { data, meta } } = useQuery(
 *  cacheParams,
 *  () => buildRequest('endpoint')
 * )
 * // Can be wrapped with
 * const = { data, meta } = withMeta(
 *   useQuery(
 *    cacheParams,
 *    () => buildRequest('endpoint')
 *   )
 * )
 */
export function withMeta<TResult, TMeta>(queryHook: UseQueryResult<SuccessResponse<TResult, TMeta>, GenericError>) {
  return { ...queryHook, data: queryHook.data?.data, meta: queryHook.data?.meta }
}

export function extractData<TResult>(promise: Promise<SuccessResponse<TResult>>): Promise<TResult> {
  return promise.then((result) => result?.data)
}

export interface ErrorHandlingConfig {
  forceToast?: boolean
}

/**
 * Returns true if the error should be handled globally at the base UI level.
 * Non-global errors are handled per page or per component.
 *
 * @param error
 */
export function isGlobalError(error: GenericError) {
  return !error.code || error.code === 'internal_error'
}

/**
 * Creates a wrapper for promises that shows generic errors using toast notifications.
 *
 * @example
 * const { withErrorHandling } = useGenericErrorHandler()
 * const { data } = useQuery('key', () => withErrorHandling(buildRequest('endpoint')))
 */
export function useGenericErrorHandler(config = { forceToast: false } as ErrorHandlingConfig) {
  const { showToast } = useToast()

  function withErrorHandling<T>(handle: Promise<T>): Promise<T> {
    return handle.catch((error) => {
      if (config.forceToast || isGlobalError(error)) {
        showToast({ message: error.message ?? 'Sorry an error occurred, please try again later.' })
      }

      throw error
    })
  }

  return { withErrorHandling }
}
