import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { APITypesMap, GenericError, PaginatedEndpointList } from '../const'
import { filterUndefinedProperties } from '../helpers/common'
import { NoSqlPagination, NoSqlReversePagination } from '../models'
import { extractData, MutationVars, useBuildRequest } from './api/base'

export type PaginationParams = NoSqlPagination | NoSqlReversePagination | Omit<NoSqlPagination, 'paginationKey'>
type Response<TEndpoint extends PaginatedEndpointList> = APITypesMap[TEndpoint]['response']

export interface PaginatedData<T> {
  data: T[]
  isLoading?: boolean
  error?: GenericError | null

  hasPreviousEntries: boolean
  hasNextEntries: boolean

  onRequestPreviousEntries: () => void
  onRequestNextEntries: () => void
}

export interface QueryDescriptor<TEndpoint extends PaginatedEndpointList> {
  key: string[]
  dataKey: keyof Response<TEndpoint>
  endpoint: TEndpoint
  options: Omit<MutationVars<TEndpoint>, 'queryParams'> & {
    queryParams?: Partial<APITypesMap[TEndpoint]['queryParams']>
  }
  config?: UseQueryOptions<Response<TEndpoint>, GenericError>
}

export interface UseNoSqlPaginatedQueryParams<
  TEndpoint extends PaginatedEndpointList,
  TPagination extends PaginationParams,
> {
  queryDescriptor: QueryDescriptor<TEndpoint>
  pagination: TPagination
  paginationType?: 'default' | 'reversed'
  refreshOnChange: string[]
}

export function useNoSqlPaginatedQuery<
  TEndpoint extends PaginatedEndpointList,
  TPagination extends PaginationParams,
  TData,
>({
  queryDescriptor,
  pagination,
  refreshOnChange,
}: UseNoSqlPaginatedQueryParams<TEndpoint, TPagination>): PaginatedData<TData> {
  const queryClient = useQueryClient()

  const buildRequest = useBuildRequest()
  const enabledByConfig = queryDescriptor.config?.enabled ?? true
  const hasValidIds = Object.values(queryDescriptor.options.params ?? {}).every((param) => !!param)
  const isReversed = (pagination as NoSqlReversePagination)?.reverse

  // URL params are always required. Whenever we don't have them, the query should be disabled.
  queryDescriptor.config = {
    ...queryDescriptor.config,
    enabled: enabledByConfig && hasValidIds,
  }

  const [currentPage, setCurrentPage] = useState(0)
  const [paginationKeys, setPaginationKeys] = useState<Array<undefined | string>>([undefined])

  const isLastPage = !paginationKeys[currentPage + 1]

  const paginatedQueryKey = [...queryDescriptor.key, ...Object.values(pagination), paginationKeys[currentPage]] as const

  const queryParams = filterUndefinedProperties<TPagination>({
    ...queryDescriptor.options.queryParams,
    ...pagination,
    paginationKey: paginationKeys[currentPage],
  })

  const { data, isFetching, error } = useQuery({
    queryKey: paginatedQueryKey,
    queryFn: () =>
      extractData(
        buildRequest(queryDescriptor.endpoint, {
          ...queryDescriptor.options,
          queryParams,
        })
      ),
    ...queryDescriptor.config,
    keepPreviousData: true,
    retry: retryUnlessNotFound,
    onError: (err: GenericError) => err, // type infer helper
  })

  const rows = (data?.[queryDescriptor.dataKey] as unknown as Array<TData>) ?? []
  const hasCachedData = Boolean(queryClient.getQueryData<Response<TEndpoint>>(paginatedQueryKey))
  const shouldUpdatePaginationKeys = data?.paginationKey !== paginationKeys[currentPage + 1]

  // Reset pagination when the entire data set changes (for example when switching between different subscriptions).
  // Since the hook can be used with multiple endpoints the dependency array needs to come from the parameters.
  // We know it will always be a string[] so react-hooks/exhaustive-deps can be disabled here
  useEffect(() => {
    resetPagination()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refreshOnChange)

  // Reset pagination when the dataset is reordered.
  useEffect(() => {
    resetPagination()
  }, [isReversed])

  // We should always have the paginationKey for the next page.
  useEffect(() => {
    if (!isFetching && data && shouldUpdatePaginationKeys) {
      // If the pagination key changes for the current page, all pages after it will change too.
      setPaginationKeys([...paginationKeys.slice(0, currentPage + 1), data.paginationKey])
    }
  }, [isFetching, data, currentPage, paginationKeys, shouldUpdatePaginationKeys])

  function handleRequestPreviousEntries() {
    setCurrentPage((page) => page - 1)
  }

  function handleRequestNextEntries() {
    setCurrentPage((page) => page + 1)
  }

  function resetPagination() {
    setCurrentPage(0)
    setPaginationKeys([undefined])
  }

  return {
    data: rows,

    hasPreviousEntries: currentPage > 0,
    hasNextEntries: !isLastPage || shouldUpdatePaginationKeys,

    error,
    // Don't show loaders when switching to a page that's already on the cache.
    isLoading: isFetching && !hasCachedData,

    onRequestPreviousEntries: handleRequestPreviousEntries,
    onRequestNextEntries: handleRequestNextEntries,
  }
}

export function retryUnlessNotFound(count: number, error: GenericError) {
  return error.code !== 'value_not_found' && count <= 3
}
