import { Tab, Tabs } from '@mui/material'
import { useCurrentSubscription } from 'features/subscription'
import { useCallback, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { USE_SUSPECT_SCORE_PAGE } from '../../../../const'
import styles from './TabNavigation.module.scss'

interface Props {
  items: {
    label: string
    to: string
    testid: string
  }[]
  value?: string
}

export function TabNavigation({ items, value }: Props) {
  const { pathname } = useLocation()
  const { currentSubscriptionId } = useCurrentSubscription()

  const substitute = useCallback(
    (path: string) => path.replace(':subscriptionId', currentSubscriptionId),
    [currentSubscriptionId]
  )

  const selectedRoute = useMemo(() => {
    if (value != null) {
      return value
    }

    const item =
      items.find((i) => pathname === substitute(i.to)) ?? items.find((i) => pathname.startsWith(substitute(i.to)))
    if (item != null) {
      return substitute(item.to)
    }

    return false
  }, [items, pathname, substitute, value])

  return (
    <Tabs
      value={selectedRoute}
      variant='scrollable'
      scrollButtons={'auto'}
      allowScrollButtonsMobile={true}
      classes={{
        root: styles.tabs,
        scrollButtons: styles.scrollButtons,
      }}
      className='w-full'
    >
      {items.map((item) => {
        const route = substitute(item.to)

        return (
          <Tab
            classes={{ root: styles.tab, selected: styles.tabSelected }}
            component={Link}
            data-testid={item.testid}
            key={item.label}
            label={item.label}
            to={route}
            value={route}
            disableRipple
          />
        )
      })}
    </Tabs>
  )
}

// Presets
export const smartSignalsNavItems = [
  { label: 'Statistics', to: '/workspaces/:subscriptionId/smart-signals', testid: 'tabnav-smart-signals' },
  { label: 'Bot detection', to: '/workspaces/:subscriptionId/botd', testid: 'tabnav-bot-detection' },
  ...(USE_SUSPECT_SCORE_PAGE
    ? [
        {
          label: 'Suspect Score',
          to: '/workspaces/:subscriptionId/suspect-score',
          testid: 'tabnav-suspect-score',
        },
      ]
    : []),
]
