import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Alert, Link, Paper } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import Loader from 'components/Loader/Loader'
import { ContentColumn, Header, MainColumn, SettingsLayout, SubHeader } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { APPLICATION_NAME_VALIDATION, DOMAIN_VALIDATION } from 'helpers/validation'
import { useSubscription, useSubscriptionRename, useSubscriptionUpdate, useToast } from 'hooks'
import { useHasMadeRequestsPreviously } from 'hooks/useHasMadeRequestsPreviously'
import { ampli } from 'models/ampli'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import GeneralSettingsEntry from './components/GeneralSettingsEntry/GeneralSettingsEntry'
import UpdateGeneralSettingDialog from './components/UpdateGeneralSettingDialog/UpdateGeneralSettingDialog'
import styles from './SubscriptionSettingsPage.module.scss'

type FieldName = 'name' | 'domain'
type DialogProps = {
  title: string
  validationRules: Record<string, unknown>
  onSubmit: (data: { newValue: string }) => void
}

type FormValues = { newValue: string }

export function SubscriptionSettingsPage() {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: subscription } = useSubscription(currentSubscriptionId)
  const { mutate: sendSubscriptionRenameRequest, isLoading: isRenamingApp } = useSubscriptionRename()
  const { mutate: sendUpdateSubscriptionRequest, isLoading: isUpdatingApp } = useSubscriptionUpdate()
  const [editField, setEditField] = useState<FieldName>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { hasMadeRequests, isLoading: isLoadingUsageStatus } = useHasMadeRequestsPreviously()
  const { showToast } = useToast()

  const editModalProps: Record<FieldName, DialogProps> = {
    name: {
      title: 'Change workspace name',
      validationRules: APPLICATION_NAME_VALIDATION,
      onSubmit: (data: FormValues) => {
        sendSubscriptionRenameRequest(
          { params: { id: subscription?.id ?? '' }, data: { name: data.newValue } },
          {
            onSuccess: () => {
              setIsModalOpen(false)
              ampli.renameApplicationComplete()
              showToast({ severity: 'success', message: 'Workspace name was updated' })
            },
          }
        )
      },
    },
    domain: {
      title: 'Change workspace domain',
      validationRules: DOMAIN_VALIDATION,
      onSubmit: ({ newValue }: FormValues) => {
        sendUpdateSubscriptionRequest(
          { params: { id: subscription?.id ?? '' }, data: { domain: newValue || null } },
          {
            onSuccess: () => {
              setIsModalOpen(false)
              showToast({ severity: 'success', message: 'Workspace domain was updated' })
            },
          }
        )
      },
    },
  }

  function handleOpenModal(field: FieldName) {
    setEditField(field)
    setIsModalOpen(true)
  }

  return (
    <>
      <Header title='Settings' />
      <MainColumn>
        <SettingsLayout>
          {subscription == null ? (
            <Loader data-testid='general-settings-page-loader' />
          ) : (
            <>
              {!isLoadingUsageStatus && !hasMadeRequests && (
                <Alert severity='warning' icon={<InfoOutlinedIcon />} className='sm:mt-2'>
                  We couldn&apos;t help but notice your workspace has no usage. Get up to speed in minutes using our
                  powerful integrations.{' '}
                  <Link
                    component={RouterLink}
                    to={buildRoute(AppRoute.Integrations, { subscriptionId: subscription.id })}
                    color='inherit'
                  >
                    Install Fingerprint now
                  </Link>
                </Alert>
              )}
              <ContentColumn>
                <SubHeader title='Workspace settings' />
                <Paper className={styles.settings}>
                  <GeneralSettingsEntry
                    label='Workspace name'
                    value={subscription.name}
                    linkText='Change name'
                    onClickLink={() => {
                      handleOpenModal('name')
                      ampli.renameApplicatonViewed()
                    }}
                  />
                  <GeneralSettingsEntry
                    label='Workspace domain'
                    value={subscription.domain!}
                    linkText={subscription.domain ? 'Change domain' : 'Add domain'}
                    onClickLink={() => handleOpenModal('domain')}
                  />
                </Paper>
              </ContentColumn>
            </>
          )}
        </SettingsLayout>
      </MainColumn>
      {isModalOpen && editField && (
        <UpdateGeneralSettingDialog
          isOpen
          onClose={() => {
            setIsModalOpen(false)
            if (editField === 'name') {
              ampli.renameApplicationCancelled()
            }
          }}
          editField={editField}
          defaultValue={subscription?.[editField]}
          disabled={isRenamingApp || isUpdatingApp}
          {...editModalProps[editField]}
        />
      )}
    </>
  )
}
