import { useCurrentSubscription } from 'features/subscription'
import { AnalyticsContext } from 'models'
import { FC, useCallback, useEffect, useState } from 'react'

import CloudflareSetupWizard from '../../../../components/Wizards/CloudflareSetup/CloudflareSetupWizard'
import { useCloudflareIntegrationData } from '../../../../hooks/api/integrations'
import IntegrationCloudflareEdit, { IntegrationCloudflareEditProps } from './Edit/IntegrationCloudflareEdit'

type Props = Pick<IntegrationCloudflareEditProps, 'onSubmit' | 'validateToken' | 'isAuthError'> & {
  onClose: () => void
}

const IntegrationCloudflareModal: FC<Props> = ({ onClose, onSubmit, validateToken, isAuthError }) => {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const {
    data: integrationData,
    isLoading: isLoadingIntegrationData,
    refetch: refetchIntegrationData,
  } = useCloudflareIntegrationData(subscriptionId)

  const [isDoingSetup, setIsDoingSetup] = useState(false)

  const onFinished = useCallback(async () => {
    refetchIntegrationData()
    onClose?.()
    setIsDoingSetup(false)
  }, [onClose, refetchIntegrationData])

  useEffect(() => {
    if (!isLoadingIntegrationData && !integrationData) {
      setIsDoingSetup(true)
    }
  }, [integrationData, isLoadingIntegrationData])

  if (!isLoadingIntegrationData && integrationData && !isDoingSetup) {
    return (
      <IntegrationCloudflareEdit
        isAuthError={isAuthError}
        validateToken={validateToken}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    )
  }

  return (
    <CloudflareSetupWizard
      analyticsContext={AnalyticsContext.AccountSettings}
      isEmbedded={true}
      isLoading={isLoadingIntegrationData}
      onClose={onClose}
      onFinished={onFinished}
    />
  )
}

export default IntegrationCloudflareModal
