import clsx from 'clsx'

import styles from './FakeDashboardBackground.module.scss'

export const FakeDashboardBackground = () => {
  const FakeDashboardTableRow = ({ ipAddressWidth }: { ipAddressWidth: string }) => {
    return (
      <tr>
        <th scope='row'>
          <span className={styles.skeleton} />
        </th>
        <th>
          <span className={ipAddressWidth} />
        </th>
        <th>
          <span className={clsx(styles.skeleton, styles.skeleton32)} />
        </th>
        <th>
          <span className={clsx(styles.skeleton, styles.skeleton40)} />
        </th>
      </tr>
    )
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <div className={styles.sidebarContent}>
            <div className={styles.sidebarHeader}>
              <div className={styles.select}>
                <svg focusable='false' fill='currentColor' aria-hidden='true' viewBox='0 0 24 24'>
                  <path d='M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2m6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56M12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96M4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56m2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8M12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96M14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2m.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56M16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2z' />
                </svg>
                <span>US-East-1</span>
              </div>
            </div>
            <ul className={styles.sidebarContentSection}>
              <li className={styles.sidebarItem}>
                <svg focusable='false' fill='currentColor' aria-hidden='true' viewBox='0 0 24 24'>
                  <path d='m12 5.69 5 4.5V18h-2v-6H9v6H7v-7.81zM12 3 2 12h3v8h6v-6h2v6h6v-8h3z' />
                </svg>
                Overview
              </li>
              <li className={clsx(styles.sidebarItem, styles.selected)}>
                <svg focusable='false' fill='currentColor' aria-hidden='true' viewBox='0 0 24 24'>
                  <path d='M4 6h18V4H4c-1.1 0-2 .9-2 2v11H0v3h14v-3H4zm19 2h-6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1m-1 9h-4v-7h4z' />
                </svg>
                Identification
              </li>
              <li className={styles.sidebarItem}>
                <svg focusable='false' fill='currentColor' aria-hidden='true' viewBox='0 0 24 24'>
                  <path d='M20 9V7c0-1.1-.9-2-2-2h-3c0-1.66-1.34-3-3-3S9 3.34 9 5H6c-1.1 0-2 .9-2 2v2c-1.66 0-3 1.34-3 3s1.34 3 3 3v4c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-4c1.66 0 3-1.34 3-3s-1.34-3-3-3m-2 10H6V7h12zm-9-6c-.83 0-1.5-.67-1.5-1.5S8.17 10 9 10s1.5.67 1.5 1.5S9.83 13 9 13m7.5-1.5c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5 1.5.67 1.5 1.5M8 15h8v2H8z' />
                </svg>
                Bot Detection
              </li>
              <li className={styles.sidebarItem}>
                <svg focusable='false' fill='currentColor' aria-hidden='true' viewBox='0 0 24 24'>
                  <path d='M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4m0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2' />
                </svg>
                Workspace Settings
              </li>
              <li className={styles.sidebarItem}>
                <svg focusable='false' fill='currentColor' aria-hidden='true' viewBox='0 0 24 24'>
                  <path d='M9.4 16.6 4.8 12l4.6-4.6L8 6l-6 6 6 6zm5.2 0 4.6-4.6-4.6-4.6L16 6l6 6-6 6z' />
                </svg>
                Developer Guides
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.navbar}>
            <div className={styles.navbarTitle}>Identification</div>
            <div className={styles.dashboardPreviewNavbarActions}>
              <button className={clsx(styles.buttonFeedback, styles.disabled)}>Have feedback?</button>
              <button className={clsx(styles.buttonQuestion, styles.disabled)}>
                <svg className={styles.muiQuestion} focusable='false' aria-hidden='true' viewBox='0 0 24 24'>
                  <path d='M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z' />
                </svg>
              </button>
              <div className={styles.avatar}>
                <span>G</span>
              </div>
            </div>
          </div>
          <table className={styles.eventsTable}>
            <thead className={styles.eventsTableHead}>
              <tr>
                <th scope='col' style={{ width: '25%' }}>
                  Visitor ID
                </th>
                <th scope='col' style={{ width: '25%' }}>
                  IP Address
                </th>
                <th scope='col' style={{ width: '25%' }}>
                  Request ID
                </th>
                <th scope='col' style={{ width: '25%' }}>
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 12 }).map((_, index) => (
                <FakeDashboardTableRow
                  key={index}
                  ipAddressWidth={
                    index % 2 === 0
                      ? clsx(styles.skeleton, styles.skeleton44)
                      : clsx(styles.skeleton, styles.skeleton60)
                  }
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.overlay} />
    </>
  )
}
