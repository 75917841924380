import clsx from 'clsx'

import styles from './OnboardingWrapper.module.scss'

export interface Classes {
  root?: string
}

export interface Props {
  classes?: Classes
  children?: React.ReactNode
}

export default function OnboardingWrapper({ children, classes = {} }: Props) {
  return <div className={clsx(styles.wrapper, classes.root)}>{children}</div>
}
