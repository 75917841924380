import { Button } from '@compass/components'
import { Paper, Skeleton, Typography } from '@mui/material'
import { SubHeader } from 'features/commonUI'
import { Pencil } from 'lucide-react'
import { BillingInfo } from 'models'

import styles from './BillingInfoCard.module.scss'

export interface BillingInfoCardProps {
  billingInfo?: Pick<BillingInfo, 'email' | 'taxId' | 'addressLine1'>
  isLoading?: boolean
  onEdit: () => void
}

export default function BillingInfoCard({ billingInfo, isLoading, onEdit }: BillingInfoCardProps) {
  return (
    <div className='flex flex-col gap-4'>
      <SubHeader
        title='Billing info'
        description='Manage the details which appear on invoices.'
        actions={
          <Button variant='secondary' onPress={onEdit}>
            <Pencil />
            Edit billing info
          </Button>
        }
      />
      <Paper component='section' className={styles.card}>
        <Info
          entries={[
            { label: 'Account email', value: billingInfo?.email },
            { label: 'Tax ID', value: billingInfo?.taxId },
            { label: 'Billing address', value: billingInfo?.addressLine1 },
          ]}
          isLoading={isLoading}
        />
      </Paper>
    </div>
  )
}

interface InfoProps {
  entries: Array<{
    label: string
    value?: string
  }>
  isLoading?: boolean
}

function Info({ entries, isLoading }: InfoProps) {
  return (
    <dl className={styles.info}>
      {entries.map(({ label, value }) => (
        <span key={label} className={styles.entry}>
          <Typography variant='bodyMMedium' component='dt'>
            {isLoading ? <Skeleton /> : (value ?? '-')}
          </Typography>
          <Typography variant='bodyS' component='dd'>
            {label}
          </Typography>
        </span>
      ))}
    </dl>
  )
}
