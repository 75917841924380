import clsx from 'clsx'
import { forwardRef, LegacyRef, PropsWithChildren } from 'react'

type BreakpointProps = {
  row?: boolean
  sm?: boolean
  md?: boolean
  lg?: boolean
  xl?: boolean
  '2xl'?: boolean
  gap?: number
  gapX?: number
  gapY?: number
  className?: string
}

function Breakpoint(
  { children, ...props }: PropsWithChildren<BreakpointProps>,
  ref: LegacyRef<HTMLDivElement> | undefined
) {
  const flexClasses = clsx({
    'flex w-full': true,
    'flex-col': !props.row,
    'flex-row': props.row,
    'sm:flex-row': props.sm,
    'md:flex-row': props.md,
    'lg:flex-row': props.lg,
    'xl:flex-row': props.xl,
    '2xl:flex-row': props['2xl'],
  })
  const x = props.gapX ?? props.gap
  const y = props.gapY ?? props.gap
  return (
    <div
      ref={ref}
      style={
        {
          '--gap-x': x,
          '--gap-y': y,
        } as any
      }
      className={clsx(
        flexClasses,
        'gap-x-[calc(var(--gap-x)*theme(space.1))]',
        'gap-y-[calc(var(--gap-y)*theme(space.1))]',
        props.className
      )}
    >
      {children}
    </div>
  )
}

const _Breakpoint = forwardRef(Breakpoint)
export { _Breakpoint as Breakpoint }
