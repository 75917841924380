import { InfoOutlined } from '@mui/icons-material'
import { Paper, Skeleton, Tooltip, Typography } from '@mui/material'
import { formatNum, formatNumShort } from 'helpers/format'
import { ChartBarDecreasing } from 'lucide-react'
import { DistributionNamedValue, DistributionReturnType, SubscriptionDistribution } from 'models'
import { ReactNode, useMemo } from 'react'

import { CAPTION_MAPPING, HEADER_MAPPING, INFO_MAPPING, LABEL_KEY_MAPPING } from './const'
import styles from './Distribution.module.scss'
import { DistributionLimiter } from './DistributionLimiter'
import { DistributionHeaderRow, DistributionRow } from './DistributionRow'

export function DistributionWidget({
  type,
  distribution,
  action,
  isError,
}: {
  type?: SubscriptionDistribution
  distribution?: DistributionReturnType
  action?: ReactNode
  isError: boolean
}) {
  const rows = useMemo(() => (distribution != null ? getRows(distribution) : undefined), [distribution])

  return (
    <Paper style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <DistributionHeader
        selected={type}
        info={distribution ? INFO_MAPPING[distribution.type] : undefined}
        action={action}
      />
      <div className={styles.distributionBody} style={{ flexGrow: 1 }}>
        {isError || (rows != null && rows.length === 0) ? (
          <ErrorContent
            message={
              isError ? 'There was an error displaying data.' : 'There is not enough data to display this chart.'
            }
          />
        ) : (
          <DistributionLimiter
            header={
              <DistributionHeaderRow
                keyLabel={type ? CAPTION_MAPPING[type] : undefined}
                valueLabel={type ? 'events' : undefined}
                className={styles.distributionHeaderRow}
              />
            }
            rows={rows}
            className={styles.rowContainer}
          />
        )}
      </div>
    </Paper>
  )
}

function getRows(value: DistributionReturnType) {
  const sumValue = getSumValue(value.data)
  const labelKey = LABEL_KEY_MAPPING[value.type] as keyof DistributionNamedValue<SubscriptionDistribution>

  return value.data.map((v, i) => {
    const parsedValue = BigInt(v.totalEvents)
    const formattedValue = parsedValue > 500000n ? formatNumShort(parsedValue) : formatNum(parsedValue)

    return (
      <DistributionRow
        key={i}
        index={i}
        label={v[labelKey]}
        value={formattedValue}
        percentage={getPercentage(v.totalEvents, sumValue)}
      />
    )
  })
}

function getSumValue<V extends DistributionNamedValue<SubscriptionDistribution>>(values: V[]) {
  return values.reduce((acc, curr) => acc + BigInt(curr.totalEvents), 0n)
}

function getPercentage(value: string, sumValue: bigint): number {
  return sumValue === 0n ? 0 : Number((BigInt(value) * 100000n) / sumValue) / 100000
}

function ErrorContent({ message }: { message: string }) {
  return (
    <div className={styles.errorContent}>
      <ChartBarDecreasing className='size-4 text-gray-600' />
      <Typography variant='bodyS'>{message}</Typography>
    </div>
  )
}

function DistributionHeader({
  selected,
  info,
  action,
}: {
  selected?: SubscriptionDistribution
  info?: string
  action?: ReactNode
}) {
  return (
    <div className={styles.distributionHeader}>
      <div className={styles.grow}>
        {selected ? (
          <Typography variant='bodyMMedium'>{HEADER_MAPPING[selected]}</Typography>
        ) : (
          <Skeleton width={160} height={20} />
        )}
        {info ? (
          <Tooltip
            title={
              <Typography variant='bodyS' color='white'>
                {info}
              </Typography>
            }
          >
            <InfoOutlined fontSize='14' color='secondary-text' />
          </Tooltip>
        ) : null}
      </div>
      {action ? action : null}
    </div>
  )
}
