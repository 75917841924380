import { GenericError } from 'const'
import {
  BotdCounterType,
  CallBasedChartDataPoint,
  StatsDataPoint,
  TableDataPoint,
  UsageChartData,
  UsageCounterType,
} from 'models'
import { DeepMap, FieldError } from 'react-hook-form'

/**
 * Several forms could have both validation form errors and API response errors.
 * This is a helper function for retrieving error message by a param name.
 * @example
 * <TextField
 *   name='email'
 *   {...getErrorParams('email', formErrors, apiError)}
 * />
 */
export function getErrorParams(
  param: string | string[],
  formErrors?: DeepMap<Record<string, any>, FieldError>,
  apiError?: GenericError | null,
  showErrorMessageForOtherParam?: boolean
) {
  function getErrorMessage(formParam: string) {
    // Giving FormHelperText a blank space makes it reserve space for the error message.
    // https://mui.com/material-ui/api/form-helper-text/#props
    let message = formErrors?.[formParam]?.message?.toString() ?? ' '

    if (apiError && apiError.message && apiError.param === (formParam ?? showErrorMessageForOtherParam)) {
      message = apiError.message
    }

    return message
  }

  if (Array.isArray(param)) {
    for (const currentParam of param) {
      const message = getErrorMessage(currentParam)

      if (message.trim().length > 0) {
        return { error: true, helperText: message ?? undefined }
      }
    }

    return { error: false, helperText: ' ' }
  } else {
    const message = getErrorMessage(param)

    return { error: message.trim().length > 0, helperText: message ?? undefined }
  }
}

/**
 * If API error is unpredictable, better to fallback it
 */
export function errorWithFallback(
  apiError: GenericError | null | undefined,
  param: string,
  message: string
): GenericError | undefined {
  // TODO: handle network connection and browsers error
  if (apiError) {
    if (!apiError.param || !apiError.message) {
      return { code: 'fallback', param, message }
    }

    return apiError
  }

  return undefined
}

/**
 * Wrapper for API dates which typed as dates, but actually is string
 */
export function date(apiDate: Date): Date
export function date(apiDate: string | undefined): Date
export function date(apiDate: Date | undefined): Date | undefined
export function date(apiDate: Date | string | undefined): Date | undefined {
  if (!apiDate) {
    return
  }

  return new Date(apiDate)
}

export function getUtmParams(queryParams: Record<string, string>, overrides?: Record<string, string>) {
  const utmInfo = Object.keys(queryParams)
    .filter((key) => key.startsWith('utm_'))
    .reduce((acc: Record<string, string>, key) => {
      acc[key] = queryParams[key]
      return acc
    }, {})

  return { ...utmInfo, ...overrides }
}

/**
 * This method transforms the counters data from the API and returns the data points for the chart and the table
 * in the format of an object where keys are counter types and values are array of data points
 * with timestamps and numeric counter values
 */
export function getChartDataPoints(data: UsageChartData) {
  const counters = Object.keys(data) as UsageCounterType[]
  const records = []

  if (counters.length > 0) {
    for (let i = 0; i < data[counters[0]]!.length; i++) {
      const record = counters.reduce<Partial<StatsDataPoint>>(
        (obj, counter) => {
          obj[counter] = data[counter]![i]?.value ?? 0
          return obj
        },
        { index: i, timestamp: data[counters[0]]![i].timestamp }
      )

      records.push(record)
    }
  }

  return records as (StatsDataPoint | CallBasedChartDataPoint)[]
}

export function getTableDataPoints(data: UsageChartData) {
  return getChartDataPoints(data).map<TableDataPoint>((p) => {
    const { index: deleted, ...point } = p
    return point
  })
}

export const usageCounterSortOrder = [
  'timestamp',
  UsageCounterType.UniqueVisitors,
  UsageCounterType.ApiCalls,
  UsageCounterType.ThrottledCalls,
  UsageCounterType.RestrictedCalls,
  BotdCounterType.Humans,
  BotdCounterType.GoodBots,
  BotdCounterType.BadBots,
]

export function sortByFixedOrder(keys: string[]) {
  return keys.slice().sort((a, b) => {
    return usageCounterSortOrder.indexOf(a) - usageCounterSortOrder.indexOf(b)
  }) as Array<keyof TableDataPoint>
}

export function formatSnippetObject(obj: string, indentationLevel = 0) {
  const baseIndentation = ' '.repeat(indentationLevel * 2)
  const indentation = ' '.repeat((indentationLevel + 1) * 2)

  return obj
    .replace(/,\s/g, `,\n${indentation}`)
    .replace('{', `{\n${indentation}`)
    .replace('}', `\n${baseIndentation}}`)
}
