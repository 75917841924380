export enum ContactSupportTopic {
  Generic = 'Contact Support',
  IntegrationSetup = 'Integration Setup Request',
  BotdEnable = 'Bot Detection Enable Request',
  IncreaseUsage = 'Increase Contract Usage',
  ExtendContract = 'Extend Contract Request',
  ReconsiderDowngrade = 'Reconsider Downgrade',
  ResumeSubscription = 'Resume Canceled Subscription',
  UpgradeToEnterprise = 'Upgrade To Enterprise',
}
