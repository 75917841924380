import { Alert, Typography } from '@mui/material'
import { AppRoute, buildRoute, useRouteParams } from 'appRoutes'
import { Stepper } from 'components/Stepper/Stepper'
import { APITypesMap, DOCS_SUBDOMAIN_INTEGRATION_URL } from 'const'
import { Header, MainColumn } from 'features/commonUI'
import { useDocumentTitle } from 'hooks'
import { SSLCertificate, SSLCertificateStatus } from 'models'
import React, { memo, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import InfoDrawer from '../../../../components/InfoDrawer/InfoDrawer'
import SubdomainIntegrationInfoDrawer from '../InfoDrawer/SubdomainIntegrationInfoDrawer'
import { CreateStep, IssuedStep, ValidationStep } from '../Steps'
import styles from './CertificateCreate.module.scss'

export interface CreateProps {
  certificate?: SSLCertificate
  onCertificateCreate: (data: APITypesMap['certificateCreate']['body']) => void
  isCreating?: boolean
}

interface StepMeta {
  label: string
  endIcon?: React.ReactNode
  component: React.ReactNode
}

enum CreationStep {
  Setup = 0,
  Validation = 1,
  Issued = 2,
  Invalid = -1,
}

export default memo(function CertificateCreate({ certificate, onCertificateCreate, isCreating }: CreateProps) {
  useDocumentTitle('Custom Subdomain')
  const history = useHistory()
  const location = useLocation()
  const { subscriptionId } = useRouteParams<AppRoute.SubdomainNew>()

  const currentStep = getCreationStep(certificate?.status)
  const isInvalidState = currentStep === CreationStep.Invalid

  useEffect(() => {
    if (certificate && certificate.id) {
      let targetRoute: AppRoute
      switch (currentStep) {
        case CreationStep.Validation:
          targetRoute = AppRoute.SubdomainValidate
          break
        case CreationStep.Issued:
          targetRoute = AppRoute.SubdomainIssued
          break
        default:
          return
      }
      if (location.pathname !== buildRoute(targetRoute, { subscriptionId })) {
        history.replace(buildRoute(targetRoute, { subscriptionId }, { certificate: certificate.id }))
      }
    }
  }, [certificate, currentStep, history, location.pathname, subscriptionId])

  const steps = useMemo<Record<CreationStep, StepMeta>>(
    () => ({
      [CreationStep.Setup]: {
        label: 'Enter Domain',
        component: <CreateStep onSubmit={onCertificateCreate} certificate={certificate} isLoading={isCreating} />,
      },
      [CreationStep.Validation]: {
        label: 'Validation',
        endIcon: validationInfo,
        component: <ValidationStep certificate={certificate!} />,
      },
      [CreationStep.Issued]: {
        label: 'Issued',
        component: <IssuedStep certificate={certificate!} />,
      },
      [CreationStep.Invalid]: {
        label: 'Validation',
        component: <ValidationStep certificate={certificate!} isInvalidState={true} />,
      },
    }),
    [certificate, onCertificateCreate, isCreating]
  )

  const stepLabels = [CreationStep.Setup, CreationStep.Validation, CreationStep.Issued].map((step) => ({
    label: steps[step].label,
    endIcon: steps[step].endIcon,
  }))

  return (
    <>
      <Header title='Configure subdomain' info={<SubdomainIntegrationInfoDrawer />} />
      <MainColumn>
        {currentStep === CreationStep.Validation && (
          <Alert severity='warning' className={styles.alert}>
            Please do not delete your CNAME record after this step as they are required for the custom subdomain to
            work.
          </Alert>
        )}

        <Stepper
          steps={stepLabels}
          activeStep={isInvalidState ? CreationStep.Validation : currentStep}
          finished={currentStep === CreationStep.Issued}
          classes={{ stepper: styles.stepper }}
        >
          {Object.entries(steps).map(([key, value]) => (
            <div key={key}>{key === currentStep.toString() && value.component}</div>
          ))}
        </Stepper>
      </MainColumn>
    </>
  )
})

const getCreationStep = (status?: SSLCertificateStatus) => {
  switch (status) {
    case SSLCertificateStatus.Default:
    case undefined:
      return CreationStep.Setup
    case SSLCertificateStatus.Created:
    case SSLCertificateStatus.Validation:
      return CreationStep.Validation
    case SSLCertificateStatus.Issued:
      return CreationStep.Issued
    default:
      return CreationStep.Invalid
  }
}

const validationInfo = (
  <InfoDrawer title='Confirming ownership' action='Read more' actionHref={DOCS_SUBDOMAIN_INTEGRATION_URL}>
    <Typography variant='body1'>
      This step is required to verify your ownership of the subdomain used on the certificate.
    </Typography>
  </InfoDrawer>
)
