import { Badge } from '@compass/components'
import { Theme, useMediaQuery } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { DISCORD_INVITE_URL, PRODUCTBOARD_PUBLIC_ROADMAP, STATUS_PAGE_URL } from 'const'
import { useLastSeen, WHATS_NEW_AVAILABLE, WHATS_NEW_IS_RECENT } from 'features/whatsNew'
import { useBuildRequest } from 'hooks/api/base'
import { useHasMadeRequestsPreviously } from 'hooks/useHasMadeRequestsPreviously'
import { CircleCheck, ExternalLink, LoaderCircle, Mailbox, Milestone, OctagonAlert, TriangleAlert } from 'lucide-react'
import { ServiceStatusResponse } from 'models'
import { ReactNode } from 'react'

import DiscordLogo from '../../../../icons/Discord.svg'
import { CommonMenuItem } from './CommonMenuSection'

const DOT_MAP: Record<ServiceStatusResponse['status'], { color: string; label: string; icon: ReactNode }> = {
  operational: {
    color: 'hsl(var(--fpds-color-green-8))',
    label: 'All systems are go',
    icon: <CircleCheck style={{ color: 'hsl(var(--fpds-color-green-8))' }} />,
  },
  degraded: {
    color: 'hsl(var(--fpds-color-yellow-8))',
    label: 'Systems degraded',
    icon: <TriangleAlert style={{ color: 'hsl(var(--fpds-color-yellow-8))' }} />,
  },
  downtime: {
    color: 'hsl(var(--fpds-color-red-8))',
    label: 'Systems are down',
    icon: <OctagonAlert style={{ color: 'hsl(var(--fpds-color-red-8))' }} />,
  },
}

function useServiceStatus() {
  const buildRequest = useBuildRequest()
  return useQuery({
    queryKey: ['service-status'],
    queryFn: () => buildRequest('serviceStatus'),
    staleTime: 60_000,
  })
}

function useServiceStatusLabel() {
  const { isLoading, data: response } = useServiceStatus()

  const status = response?.data?.status

  let {
    color: statusColor,
    label: statusLabel,
    icon: statusIcon,
  } = status
    ? DOT_MAP[status]
    : {
        color: 'hsl(var(--fpds-color-gray-6))',
        label: 'Loading status',
        icon: <LoaderCircle className='animate-spin text-gray-600' />,
      }

  if (!isLoading && status == null) {
    // Failed to get status for some reason, hide the placeholder.
    statusColor = 'hsl(var(--fpds-color-gray-10))'
    statusLabel = 'Service status'
    statusIcon = <ExternalLink />
  }

  return { statusColor, statusLabel, statusIcon }
}

export const useExternalCommonItems: () => CommonMenuItem[] = () => {
  const { isLoading } = useServiceStatus()
  const { statusColor, statusLabel, statusIcon } = useServiceStatusLabel()

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  const [_, setLastSeen] = useLastSeen('whats-new-modal')
  const { hasMadeRequests, isLoading: isLoadingHasMadeRequests } = useHasMadeRequestsPreviously({
    includeCanceled: true,
  })

  return [
    {
      key: 'discord',
      label: 'Join Discord',
      icon: <DiscordLogo fill='none' stroke='currentColor' />,
      link: DISCORD_INVITE_URL,
      target: '_blank',
      associatedRoutes: [],
    },
    ...(!isDesktop && WHATS_NEW_AVAILABLE && !isLoadingHasMadeRequests && hasMadeRequests
      ? [
          {
            key: 'whats-new',
            icon: <Mailbox />,
            component: () => (
              <div className='flex gap-2'>
                <span>What&apos;s new</span> {WHATS_NEW_IS_RECENT ? <Badge>New</Badge> : null}
              </div>
            ),
            onAction: () => {
              setLastSeen(null)
            },
          },
        ]
      : []),
    {
      key: 'roadmap',
      label: 'Roadmap',
      icon: <Milestone />,
      link: PRODUCTBOARD_PUBLIC_ROADMAP,
      target: '_blank',
      associatedRoutes: [],
    },
    {
      key: 'service-status',
      label: statusLabel,
      icon: statusIcon,
      link: STATUS_PAGE_URL,
      associatedRoutes: [],
      target: '_blank',
      style: {
        color: statusColor,
      },
      disabled: isLoading,
    },
  ]
}
