export type Difference = 'major' | 'minor' | 'patch' | 'same'

const SEMVER_REGEX =
  /^v?(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/

export class SemanticVersion {
  major: number
  minor: number
  patch: number
  label: string | undefined

  constructor(major: number, minor: number, patch: number, label: string | undefined) {
    this.major = major
    this.minor = minor
    this.patch = patch
    this.label = label
  }

  static fromString(value: string) {
    const parsed = SEMVER_REGEX.exec(value)
    if (parsed) {
      parsed.shift()
      const [major, minor, patch, label] = parsed
      return new SemanticVersion(parseInt(major), parseInt(minor), parseInt(patch), label)
    }

    return null
  }

  /**
   * Used to figure out whether SemanticVersion b is newer than a.
   * This function does not handle when a is newer than b.
   */
  static getDifference(a: SemanticVersion | null, b: SemanticVersion | null): Difference | null {
    if (a == null || b == null) {
      return null
    }

    if (a.major < b.major) {
      return 'major'
    }

    if (a.minor < b.minor) {
      return 'minor'
    }

    if (a.patch < b.patch) {
      return 'patch'
    }

    return 'same'
  }

  static compare(a: SemanticVersion | null, b: SemanticVersion | null): number {
    if (a == null) {
      return -1
    }

    if (b == null) {
      return 1
    }

    const majorDiff = a.major - b.major
    if (majorDiff !== 0) {
      return majorDiff
    }

    const minorDiff = a.minor - b.minor
    if (minorDiff !== 0) {
      return minorDiff
    }

    return a.patch - b.patch
  }
}
