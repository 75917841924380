import { useSubscription } from 'hooks'

import { ForcedTrialEndModal } from '../ForcedTrialEndModal/ForcedTrialEndModal'

export function ConditionalForcedTrialEndModal({ subscriptionId }: { subscriptionId: string }) {
  const { data: subscription, isLoading } = useSubscription(subscriptionId)

  if (isLoading || subscription == null) {
    return null
  }

  return (
    <ForcedTrialEndModal
      id={subscription.id}
      type={subscription.type}
      status={subscription.status}
      extendedTrialStartedAt={subscription.extendedTrialStartedAt}
    />
  )
}
