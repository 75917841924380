import { InfoOutlined } from '@mui/icons-material'
import { Alert, Link, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { DEFAULT_CERTIFICATE_LIMIT, DOCS_SUBDOMAIN_INTEGRATION_URL } from 'const'
import { ContentColumn, Header, MainColumn, SettingsLayout, SubHeader } from 'features/commonUI'
import { useDocumentTitle } from 'hooks'
import { ExpandedSubscription, SSLCertificate, SSLCertificateStatus } from 'models'
import { memo, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import AddEntityButton from '../../../../components/AddEntityButton/AddEntityButton'
import { TableContainer, TableFooter, TablePagination } from '../../../../components/Table/Table'
import { pluralize } from '../../../../helpers/pluralize'
import { CertificatesTable } from '../CertificatesTable/CertificatesTable'
import { useCertificatesList } from '../CertificatesTable/useCertificatesList'
import { CustomSubdomainCTA } from '../CustomSubdomainCTA/CustomSubdomainCTA'
import DeleteSubdomainDialog from '../DeleteSubdomainDialog/DeleteSubdomainDialog'
import styles from './CertificatesList.module.scss'
import { LegacyAlert } from './LegacyAlert'

export interface CertificatesListProps {
  certificates: SSLCertificate[]
  isLoading?: boolean
  errorMessage?: string
  canCreateNew?: boolean
  certificatesLimit: number
  subscription?: ExpandedSubscription
  onCertificateDelete: (certId: string) => void
  onCertificateAddClick: () => void
  subscriptionId: string
}

const isLegacyCertificate = (cert: SSLCertificate) => new Date(cert.createdAt) < new Date('2024-09-23')

export default memo(function CertificatesList(props: CertificatesListProps) {
  const {
    isLoading,
    errorMessage,
    certificates = [],
    certificatesLimit,
    subscription,
    onCertificateAddClick,
    onCertificateDelete,
    subscriptionId,
  } = props

  useDocumentTitle('Custom Subdomain')

  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const history = useHistory()

  const { certificateData, disabledMessage, showFreeLimitAlert, showRequestMoreCertificatesCTA } = useCertificatesList({
    isLoading,
    errorMessage,
    certificates,
    certificatesLimit,
    subscription,
    onCertificateAddClick,
  })

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedCertificate, setSelectedCertificate] = useState<SSLCertificate | null>(null)

  const [page, setPage] = useState(0)
  const rowsPerPage = 10

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const paginatedCertificates = certificates.slice(page * rowsPerPage, (page + 1) * rowsPerPage)

  const handleCertificateDelete = useCallback(
    (id: string) => {
      const certificate = certificates.find((cert) => cert.id === id)
      if (certificate) {
        setSelectedCertificate(certificate)
        setDeleteDialogOpen(true)
      }
    },
    [certificates]
  )

  const handleCloseDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(false)
    setSelectedCertificate(null)
  }, [])

  const handleCertificateClick = useCallback(
    (certId: string) => {
      const cert = certificates.find((c) => c.id === certId)
      if (cert) {
        const route =
          cert.status === SSLCertificateStatus.Issued ? AppRoute.SubdomainIssued : AppRoute.SubdomainValidate
        history.push(buildRoute(route, { subscriptionId }, { certificate: certId }))
      }
    },
    [certificates, history, subscriptionId]
  )

  const handleAddSubdomain = () => {
    history.push(buildRoute(AppRoute.SubdomainNew, { subscriptionId }))
  }

  const showLegacyAlert = useMemo(() => certificates.some(isLegacyCertificate), [certificates])
  const startIndex = page * rowsPerPage + 1
  const endIndex = Math.min((page + 1) * rowsPerPage, certificates.length)

  return (
    <>
      <Header title='Settings' />
      <MainColumn>
        <SettingsLayout>
          {showRequestMoreCertificatesCTA && <CustomSubdomainCTA />}
          <ContentColumn>
            <SubHeader
              title='Custom subdomains'
              description={
                <>
                  <Typography variant='bodyM'>
                    Subdomains are strongly recommended for protecting against ad blockers and ensuring accurate
                    identification.{' '}
                    <Link href={DOCS_SUBDOMAIN_INTEGRATION_URL} target='_blank' underline='hover'>
                      Learn more
                    </Link>
                  </Typography>
                </>
              }
              actions={
                <AddEntityButton
                  entityData={{
                    ...certificateData,
                    onAddClick: handleAddSubdomain,
                  }}
                  disabledMessage={disabledMessage}
                />
              }
            />
            <TableContainer component={Paper}>
              <CertificatesTable
                certificates={paginatedCertificates}
                isLoading={isLoading}
                errorMessage={errorMessage}
                onCertificateClick={handleCertificateClick}
                onCertificateDelete={handleCertificateDelete}
                lgUp={lgUp}
              />
              {certificates.length > rowsPerPage && (
                <TableFooter className={styles.footer}>
                  <Typography variant='bodyS'>
                    Showing {startIndex}-{endIndex} of {certificates.length} subdomains
                  </Typography>
                  <TablePagination
                    hasPreviousEntries={page > 0}
                    hasNextEntries={(page + 1) * rowsPerPage < certificates.length}
                    isLoading={isLoading}
                    onRequestPreviousEntries={() => handleChangePage(null, page - 1)}
                    onRequestNextEntries={() => handleChangePage(null, page + 1)}
                    className={styles.pagination}
                  />
                </TableFooter>
              )}
            </TableContainer>
          </ContentColumn>
          {showFreeLimitAlert && subscription && (
            <FreeLimitAlert subscription={subscription} certificatesLimit={certificatesLimit} />
          )}
          {showLegacyAlert && <LegacyAlert />}
        </SettingsLayout>
      </MainColumn>
      {selectedCertificate && (
        <DeleteSubdomainDialog
          open={deleteDialogOpen}
          onClose={handleCloseDeleteDialog}
          certificate={selectedCertificate}
          onDelete={() => {
            onCertificateDelete(selectedCertificate.id)
            handleCloseDeleteDialog()
          }}
        />
      )}
    </>
  )
})

const FreeLimitAlert = memo(function FreeLimitAlert({
  subscription,
  certificatesLimit,
}: {
  subscription: ExpandedSubscription
  certificatesLimit: number
}) {
  return (
    <Alert severity='info' icon={<InfoOutlined className={styles.icon} />} className={styles.alert}>
      <Typography variant='body2'>
        During your free trial, you can use up to{' '}
        {pluralize(certificatesLimit ?? DEFAULT_CERTIFICATE_LIMIT, 'custom subdomain')}.{' '}
        <Link
          href={buildRoute(AppRoute.SubscriptionPlan, {
            subscriptionId: subscription.id,
          })}
        >
          Upgrading
        </Link>{' '}
        gives you access to 50 subdomains with more available upon request.
      </Typography>
    </Alert>
  )
})
