import { Link, Typography } from '@mui/material'
import AddEntityButton from 'components/AddEntityButton/AddEntityButton'
import { COMPANY_NAME, DOCS_WEBHOOKS_URL } from 'const'
import { ContentColumn, Header, MainColumn, SubHeader } from 'features/commonUI'
import { useDocumentTitle } from 'hooks'
import { SubscriptionWebhook } from 'models'
import { ampli } from 'models/ampli'
import { memo, useCallback, useState } from 'react'
import { flushSync } from 'react-dom'

import WebhookDialog, { WebhookFormData } from '../WebhookDialog/WebhookDialog'
import WebhooksTable, { WebhooksProps } from '../WebhooksTable/WebhooksTable'

export interface WebhooksPageProps extends Omit<WebhooksProps, 'entityData' | 'onWebhookAdd' | 'onWebhookEdit'> {
  onWebhookAdd: (data: WebhookFormData) => void
  onWebhookEdit: (data: WebhookFormData) => void
}

export default memo(function Webhooks(props: WebhooksPageProps) {
  useDocumentTitle('Webhooks')

  const { webhooks, webhooksLimit, onWebhookAdd, onWebhookEdit } = props
  const [editableWebhook, setEditableWebhook] = useState<SubscriptionWebhook>()
  const [webhookEditModalIsOpen, setWebhookEditModalIsOpen] = useState(false)

  const handleAddWebhook = useCallback(() => {
    ampli.addWebhookClicked()
    setWebhookEditModalIsOpen(true)
  }, [])

  const handleEditWebhook = useCallback(
    (webhookId: string) => {
      const editedWebhook = webhooks.find((w) => w.id === webhookId)
      // React 18 is now batching state changes, and without flushSync it starts rendering the dialog
      // with undefined as the editable webhook, resulting in bad default values to be loaded in the form.
      flushSync(() => setEditableWebhook(editedWebhook))
      setWebhookEditModalIsOpen(true)
    },
    [webhooks]
  )

  const handleWebhookSubmit = useCallback(
    (data: WebhookFormData) => {
      setWebhookEditModalIsOpen(false)

      if (editableWebhook) {
        onWebhookEdit(data)
        setEditableWebhook(undefined)
      } else {
        onWebhookAdd(data)
      }
    },
    [editableWebhook, onWebhookAdd, onWebhookEdit]
  )

  const handleWebhookDialogClose = useCallback(() => {
    setWebhookEditModalIsOpen(false)
    setEditableWebhook(undefined)
  }, [])

  const webhookData = {
    name: 'Webhook',
    count: webhooks?.length ?? 0,
    limit: webhooksLimit,
    onAddClick: handleAddWebhook,
  }

  return (
    <>
      <Header title='Webhooks' />
      <MainColumn>
        <ContentColumn>
          <SubHeader
            title={<Typography variant='h2'>Webhooks</Typography>}
            description={
              <>
                <Typography variant='bodyM'>
                  A webhook is when {COMPANY_NAME} makes an HTTP POST request to your application&#39;s API whenever an
                  event occurs.&nbsp;
                  <Link
                    href={DOCS_WEBHOOKS_URL}
                    target='_blank'
                    underline='hover'
                    onClick={() => {
                      ampli.botDDocumentationViewed()
                    }}
                  >
                    Learn more
                  </Link>
                </Typography>
              </>
            }
            actions={<AddEntityButton entityData={webhookData} />}
          />
          <WebhooksTable
            {...props}
            entityData={webhookData}
            onWebhookAdd={handleAddWebhook}
            onWebhookEdit={handleEditWebhook}
          />
        </ContentColumn>

        <WebhookDialog
          open={webhookEditModalIsOpen}
          onClose={handleWebhookDialogClose}
          onSubmit={handleWebhookSubmit}
          webhook={editableWebhook}
        />
      </MainColumn>
    </>
  )
})
