import { Sell } from '@mui/icons-material'
import { Link } from '@mui/material'

import { SUPPORT_PAGE_URL } from '../../../../const'
import styles from './PlanDiscount.module.scss'

export function PlanDiscount() {
  return (
    <div className={styles.planDiscount}>
      <div className={styles.icon}>
        <Sell />
      </div>
      <div className={styles.text}>
        <strong>Your plan has a pricing discount.</strong>
        <div>
          For more details, please{' '}
          <Link href={SUPPORT_PAGE_URL} style={{ textDecoration: 'none' }}>
            get in touch
          </Link>{' '}
          with our support team.
        </div>
      </div>
    </div>
  )
}
