import { Button, DialogContent, LinkButton, ModalOverlay, Stack } from '@compass/components'
import { MDXProvider } from '@mdx-js/react'
import { IS_E2E_MODE, IS_TEST_MODE } from 'const'
import { useAuth } from 'hooks'
import { useHasMadeRequestsPreviously } from 'hooks/useHasMadeRequestsPreviously'
import { useCallback, useState } from 'react'
import { Heading } from 'react-aria-components'
import { tv } from 'tailwind-variants'

import { SHOW_WHATS_NEW_AFTER, WHATS_NEW_AVAILABLE } from './config'
import DrnMdx from './entries/1/drn.mdx'
import { useLastSeen } from './useLastSeen'

const headerStyles = tv({
  base: 'flex flex-shrink-0 items-center h-11 pl-5',
})

const ELEMENT_NAME = 'whats-new-modal'
export function WhatsNewModal({ overrideCI }: { overrideCI?: boolean }) {
  const [isOpen, setIsOpen] = useState<boolean | null>(null)

  const [lastSeen, setLastSeen, isLoading] = useLastSeen(ELEMENT_NAME)
  const { hasMadeRequests, isLoading: isLoadingHasMadeRequests } = useHasMadeRequestsPreviously({
    includeCanceled: true,
  })
  const { isImpersonating } = useAuth()

  const canDisplay = !(IS_TEST_MODE || IS_E2E_MODE) || overrideCI
  const shouldShow =
    !isLoading &&
    !isLoadingHasMadeRequests &&
    hasMadeRequests &&
    !isImpersonating &&
    WHATS_NEW_AVAILABLE &&
    (!lastSeen || new Date(lastSeen) < SHOW_WHATS_NEW_AFTER)
  const showModal = canDisplay && shouldShow

  if (showModal && !isOpen) {
    setIsOpen(true)
  }

  const onOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open)
      if (open === false) {
        setLastSeen(new Date().toISOString())
      }
    },
    [setLastSeen]
  )

  return (
    <ModalOverlay isDismissable isOpen={showModal && isOpen === true} onOpenChange={onOpenChange}>
      <DialogContent className='max-h-[90dvh]'>
        {({ close }) => (
          <>
            <header className={headerStyles()}>
              <Heading slot='title' className='mt-1.5 text-lg font-medium'>
                What&apos;s new
              </Heading>
            </header>
            <div className='absolute top-6 w-full h-6 flex flex-col items-center justify-end px-1'>
              <div className='w-full scale-90 bg-white border border-b-0 rounded-t-lg h-1 opacity-30' />
              <div className='w-full scale-95 bg-white border border-b-0 rounded-t-lg h-1 opacity-60' />
            </div>
            <Stack
              gap={0}
              direction='column'
              className='relative z-10 m-1 px-8 py-7 overflow-y-auto rounded-t-lg bg-white border border-b-0'
            >
              <MDXProvider>
                <div className='prose'>
                  <DrnMdx /> {/* Render the imported MDX file */}
                </div>
              </MDXProvider>
            </Stack>
            <Stack className='sticky bottom-0 justify-between mx-1 -mt-1 mb-1 p-4 bg-gray-100 border rounded-b-lg'>
              <Button onPress={close} variant='secondary' alt>
                Dismiss
              </Button>
              <LinkButton autoFocus href='https://dev.fingerprint.com/docs/drn-overview' target='_blank'>
                Read the docs
              </LinkButton>
            </Stack>
          </>
        )}
      </DialogContent>
    </ModalOverlay>
  )
}
