import { useCurrentSubscriptionData } from 'features/subscription'
import { IntegrationStep, SubscriptionStatus } from 'models'
import { useMemo } from 'react'

export function useHasMadeRequestsPreviously(config?: { includeCanceled?: boolean }) {
  const { subscription } = useCurrentSubscriptionData()
  const hasMadeRequests = useMemo(
    () =>
      subscription != null &&
      !(
        (config?.includeCanceled || subscription.status !== SubscriptionStatus.Canceled) &&
        subscription.integrationStep === IntegrationStep.ApiCalls
      ),
    [subscription, config?.includeCanceled]
  )

  return { hasMadeRequests, isLoading: hasMadeRequests === undefined }
}
