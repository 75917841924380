import { Skeleton } from '@mui/material'
import { PropsWithChildren } from 'react'

import { useRandomIntegerBetween } from '../../hooks/useRandomIntegerBetween'

type LoaderProps = {
  from?: number
  to?: number
}

export const LoadingValue = ({ children, from, to }: PropsWithChildren<LoaderProps>) => {
  if (children != null) {
    return <>{children}</>
  }

  return <Loader from={from} to={to} />
}

const Loader = ({ from = 30, to = 110 }: LoaderProps) => {
  const width = useRandomIntegerBetween(from, to)
  return <Skeleton width={width} />
}
