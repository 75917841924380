import { ComponentProps } from 'react'
import { composeRenderProps, OverlayArrow, Tooltip as AriaTooltip } from 'react-aria-components'
import { tv } from 'tailwind-variants'

const tooltipStyle = tv({
  base: [
    'max-w-lg text-wrap',
    'text-base font-medium',
    'px-2 py-1 rounded-md flex gap-1',
    'bg-gray-1000 text-gray-100',
    'shadow-400',
    'group',
    'will-change-transform',
  ],
  variants: {
    isEntering: {
      true: [
        'animate-in fade-in ease-in duration-200',
        'placement-bottom:slide-in-from-top-0.5',
        'placement-top:slide-in-from-bottom-0.5',
        'placement-left:slide-in-from-right-0.5',
        'placement-right:slide-in-from-left-0.5',
      ],
    },
    isExiting: {
      true: [
        'animate-out fade-out ease-out duration-50',
        'placement-bottom:slide-out-to-top-0.5',
        'placement-top:slide-out-to-bottom-0.5',
        'placement-left:slide-out-to-right-0.5',
        'placement-right:slide-out-to-left-0.5 ',
      ],
    },
  },
})

export type TooltipProps = Exclude<ComponentProps<typeof AriaTooltip>, 'className'>
export function Tooltip({ children, ...props }: TooltipProps) {
  return (
    <AriaTooltip
      offset={8}
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tooltipStyle({ ...renderProps, className })
      )}
    >
      {(renderProps) => (
        <>
          <OverlayArrow>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='12'
              height='6'
              viewBox='0 0 12 6'
              fill='none'
              className='group-placement-bottom:rotate-180 group-placement-left:-rotate-90 group-placement-right:rotate-90'
            >
              <path
                d='M4.93934 5.50991C5.52513 6.16336 6.47487 6.16336 7.06066 5.50991L12 0H0L4.93934 5.50991Z'
                fill='#181916'
              />
            </svg>
          </OverlayArrow>
          {typeof children == 'function' ? children(renderProps) : children}
        </>
      )}
    </AriaTooltip>
  )
}
