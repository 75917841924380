import { TextField } from '@mui/material'
import clsx from 'clsx'
import { ChangeEvent, useEffect, useState } from 'react'

import styles from './TextFieldWithCounter.module.scss'

export type Props = {
  maxSym: number
  placeholder?: string
  defaultValue?: string
  errorMessage?: string
  onChange?: (value: string) => void
}

export const TextFieldWithCounter = ({ placeholder, defaultValue, errorMessage, maxSym, onChange }: Props) => {
  const [text, setText] = useState(defaultValue)
  const [symLeft, setSymLeft] = useState(maxSym)

  useEffect(() => {
    if (defaultValue) {
      setSymLeft(maxSym - defaultValue.length)
    }
  }, [maxSym, defaultValue])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.currentTarget.value)
    setSymLeft(maxSym - e.currentTarget.value.length)
    onChange?.(e.currentTarget.value)
  }

  return (
    <TextField
      className={styles.textFieldWrap}
      multiline
      minRows={4}
      fullWidth
      type='text'
      placeholder={placeholder}
      error={errorMessage !== undefined || symLeft <= 0}
      variant='outlined'
      value={text}
      onChange={handleChange}
      helperText={
        errorMessage ? (
          <span className={styles.helperRed}>{errorMessage}</span>
        ) : (
          <span
            className={clsx({
              [styles.helper]: symLeft >= 100,
              [styles.helperYellow]: symLeft < 100,
              [styles.helperRed]: symLeft <= 0,
            })}
          >
            {symLeft} characters left
          </span>
        )
      }
      inputProps={{ className: styles.textField }}
    />
  )
}
