import { IS_PRODUCTION_MODE } from 'const'

interface Logger {
  log(message: string): void
  error(message: string): void
}

//TODO: [DI]: add some more powerful logging library here
class SimpleLogger implements Logger {
  log(message: string): void {
    // eslint-disable-next-line no-console
    console.log(message)
  }

  error(message: string): void {
    // eslint-disable-next-line no-console
    console.error(message)
  }
}

class NullLogger implements Logger {
  log() {}
  error() {}
}

export default IS_PRODUCTION_MODE ? new NullLogger() : new SimpleLogger()
