import { LinkButton } from '@compass/components'
import { Paper, Typography } from '@mui/material'
import clsx from 'clsx'
import { PropsWithChildren, ReactNode } from 'react'

import styles from './CtaCard.module.scss'

type Props = PropsWithChildren<{
  title: string
  subtitle: ReactNode
  ctaTitle: string
  ctaPath: string
  ctaOnClick?: () => void
}>

export function CtaCard({ title, subtitle, ctaTitle, ctaPath, ctaOnClick }: Props) {
  return (
    <Paper className={clsx(styles.card)}>
      <Typography variant='bodyMMedium'>{title}</Typography>
      <Typography variant='bodyS'>{subtitle}</Typography>
      <LinkButton
        className={styles.actionButton}
        target='_blank'
        href={ctaPath}
        onPress={ctaOnClick}
        variant='secondary'
      >
        {ctaTitle}
      </LinkButton>
    </Paper>
  )
}
