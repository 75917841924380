import { Check } from '@mui/icons-material'
import { Typography } from '@mui/material'
import clsx from 'clsx'

import styles from './FeatureList.module.scss'

export interface Feature {
  name: string
  badge?: string
}

export interface FeatureListProps {
  features: Feature[]
  className?: string
}

export function FeatureList({ features, className }: FeatureListProps) {
  return (
    <ul className={clsx(styles.features, className)}>
      {features.map(({ name }) => (
        <li key={name} className={styles.feature}>
          <Check fontSize='medium' className={styles.icon} />

          <Typography variant='body3' component='span'>
            {name}
          </Typography>
        </li>
      ))}
    </ul>
  )
}
