import { Button, Flex } from '@compass/components'
import { Send } from '@mui/icons-material'
import { Alert, AlertTitle, InputLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import clsx from 'clsx'
import { AVAILABLE_ROLES, GenericError, USER_ROLE_DESCRIPTIONS } from 'const'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from 'features/commonUI'
import { getErrorParams } from 'helpers/data'
import { muiRegister } from 'helpers/reactHookForm'
import { EMAIL_VALIDATION } from 'helpers/validation'
import { useCurrentUser } from 'hooks/api/users'
import { EditUserFormData, UserFormData, UserRole } from 'models'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { OutlinedFormControlLabel } from './OutlinedFormControlLabel/OutlinedFormControlLabel'
import styles from './UserDialog.module.scss'

export interface UserDialogProps extends DialogProps {
  data?: UserFormData
  error?: GenericError | null
  onAddUser: (data: UserFormData) => void
  onEditUser: (data: EditUserFormData) => void
}

export function UserDialog({ data, error, onAddUser, onEditUser, onClose, ...dialogProps }: UserDialogProps) {
  const isEditing = !!data

  const { data: currentUser } = useCurrentUser()
  const canUpdateRole = !isEditing || (data?.role !== UserRole.Owner && currentUser?.id !== data?.id)

  const {
    control,
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      ...userCreateDefaultValues,
      ...data,
    },
    shouldUnregister: true,
  })

  const [emailValue, setEmailValue] = useState<string | undefined>()
  const onFormBlur = useCallback(() => {
    setEmailValue(getValues('email'))
  }, [getValues])

  useEffect(() => {
    reset(data ?? userCreateDefaultValues)
  }, [data, reset])

  return (
    <Dialog onClose={onClose} {...dialogProps}>
      <form onSubmit={handleSubmit(isEditing ? onEditUser : onAddUser)} onBlur={onFormBlur}>
        <DialogTitle onClose={onClose}>{isEditing ? 'Manage user' : 'Invite someone new'}</DialogTitle>

        <DialogContent>
          {isEditing && (
            <Alert severity='warning' icon={false} className={styles.editModeAlert}>
              <AlertTitle>Heads up</AlertTitle> A user’s email address cannot be changed. If your team requires an email
              address change,{' '}
              <a href='https://fingerprint.com/support/' target='_blank' rel='noreferrer'>
                please reach out to us
              </a>
              .
            </Alert>
          )}

          <InputLabel htmlFor='name'>Name</InputLabel>
          <TextField
            variant='outlined'
            placeholder='Name'
            id='name'
            title='Name'
            inputProps={{ 'aria-label': 'name' }}
            {...muiRegister(register, 'name', { required: 'Name required.' })}
            {...getErrorParams('name', errors, error)}
            className={styles.field}
          />

          <InputLabel htmlFor='email'>User email</InputLabel>
          <TextField
            variant='outlined'
            placeholder='Email'
            id='email'
            title='Email'
            type='email'
            disabled={isEditing}
            {...muiRegister(register, 'email', { ...EMAIL_VALIDATION, disabled: isEditing })}
            inputProps={{ 'aria-label': 'email' }}
            {...getErrorParams('email', errors, error)}
            className={styles.field}
          />

          {canUpdateRole && (
            <>
              <InputLabel htmlFor='role'>Role</InputLabel>
              <Controller
                control={control}
                name='role'
                defaultValue={UserRole.Member}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    {AVAILABLE_ROLES.map(({ name, value }) => (
                      <OutlinedFormControlLabel
                        key={value}
                        value={value}
                        control={<Radio />}
                        className={styles.radioField}
                        label={({ checked }) => (
                          <span className={clsx(styles.radioFieldLabel, checked && styles.radioFieldLabelActive)}>
                            <Typography variant='bodyMMedium' className={styles.radioFieldLabelTitle}>
                              {name}
                            </Typography>

                            <br />

                            <Typography variant='bodyS' className={styles.radioFieldLabelSubtitle}>
                              {USER_ROLE_DESCRIPTIONS[value]}
                            </Typography>
                          </span>
                        )}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Flex mdRow className='gap-4'>
            {!isEditing && !!emailValue && (
              <Typography variant='bodyS'>
                We’ll send <strong>{emailValue}</strong> a fresh invite link, but they’ll need to accept to join your
                team.
              </Typography>
            )}
            <Flex row className='gap-4 justify-end items-center'>
              <Button variant='ghost' onPress={onClose}>
                Cancel
              </Button>
              <Button type='submit' isDisabled={isEditing && !isDirty}>
                {isEditing ? undefined : <Send fontSize='inherit' />}
                {isEditing ? 'Update user' : 'Send invite'}
              </Button>
            </Flex>
          </Flex>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const userCreateDefaultValues = {
  role: UserRole.Member,
}
