import { BaseReactProps } from 'helpers/types'
import { CardBrand } from 'models'
import { memo } from 'react'

const icons: Record<CardBrand, string> = {
  [CardBrand.Amex]: '/assets/images/cards/amex.svg',
  [CardBrand.Diners]: '/assets/images/cards/diners.svg',
  [CardBrand.Discover]: '/assets/images/cards/discover.svg',
  [CardBrand.Jcb]: '/assets/images/cards/jcb.svg',
  [CardBrand.MasterCard]: '/assets/images/cards/mastercard.svg',
  [CardBrand.Visa]: '/assets/images/cards/visa.svg',
  [CardBrand.UnionPay]: '/assets/images/cards/unionpay.svg',
  [CardBrand.Unknown]: '/assets/images/cards/unknown.svg',
}

export interface CardLogoProps extends BaseReactProps {
  cardBrand: CardBrand
}

export default memo(function CardLogo({ cardBrand, className }: CardLogoProps) {
  return (
    <img
      style={{ width: '40px', verticalAlign: 'middle' }}
      src={icons[cardBrand] ?? icons[CardBrand.Unknown]}
      alt='cardBrand'
      className={className}
    />
  )
})
