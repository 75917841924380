import screenshot from './screenshot.png'
import avatar from './valentin-duotone.png'

<time dateTime='2024-10-31' className='text-gray-800'>
  Oct 31, 2024
</time>

### Introducing Fingerprint’s New Android Device Reputation Network

Fingerprint identifies millions of Android devices globally every month.
When you identify an Android device using the Fingerprint SDK, that device
likely has previously already been identified by other Fingerprint
customers. Now, you can access all that historical information via
Fingerprint's
<a href='https://dev.fingerprint.com/docs/drn-overview' target='_blank'>Device Reputation Network (DRN) API</a>.

<figure>
  <img
    src={screenshot}
    alt='screenshot of the Fingerprint DRN API response'
    width='1430'
    height='1472'
  />
  <figcaption>Example response</figcaption>
</figure>

Once you've integrated the Android SDK into your app to identify devices,
you can retrieve a visitor ID for each device. With that visitor ID, you
make this API call to the DRN to get historical insights into that device’s
prior behavior. The DRN currently supports three global signals:
`regional_activity`, `suspect_score`, and `timestamps`.

Thank you.

<div className='not-prose flex items-center'>
  <span className='size-5 shadow-avatar rounded-sm overflow-hidden'>
    <img
      src={avatar}
      className='object-cover'
      alt='Portrait of Valentin Vasilyev, co-founder and CTO of FingerprintJS Inc.'
      width='20'
      height='20'
    />
  </span>
  <span className='pl-2'>
    <span className='font-medium'>
      Valentin Vasilyev
      <span className='text-gray-800 font-normal'>Co-founder & CTO</span>
    </span>
  </span>
</div>
