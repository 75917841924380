import { NavigationTab } from '@compass/NavigationTab'
import { matchPath, useLocation } from 'react-router-dom'

import { CommonMenuItem } from './CommonMenuSection'

export function SidebarMenuItem({ item }: { item: CommonMenuItem }) {
  const { pathname } = useLocation()
  const urls = item.associatedRoutes ?? [item.link]
  const selected = item.link === pathname || !!urls.find((route) => matchPath(pathname, { path: route }))

  return (
    <NavigationTab
      variant={selected ? 'secondary' : 'ghost'}
      tone={selected ? 'background1' : 'background2'}
      alt={!selected}
      href={item.link}
      data-testid={item['data-testid']}
      style={item.style}
      onPress={item.onAction}
      isDisabled={item.disabled}
    >
      {item.icon}
      <span className='flex flex-row w-full justify-between'>
        <span>{item.label}</span>
        {item.badge ? <span>{item.badge}</span> : null}
      </span>
    </NavigationTab>
  )
}
