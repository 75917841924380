import { useEffect, useRef } from 'react'

export function useIsFirstRender() {
  const firstRender = useRef(true)
  useEffect(() => {
    firstRender.current = false
  }, [])

  return firstRender.current
}
