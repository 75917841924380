import { useCurrentSubscription } from 'features/subscription'
import { useToast } from 'hooks'
import { useProductFeedback } from 'hooks/api/support'
import { useUpdateFeedbackData } from 'hooks/feedback'
import { useEffect, useState } from 'react'

import { UserFeedbackData } from '../../../../models'
import { FeedbackDrawerView } from './FeedbackDrawerView'

export type FeedbackDrawerProps = {
  isOpen: boolean
  alreadyRated: boolean
  onClose: () => void
  currentFeedbackData?: UserFeedbackData
}

export const FeedbackDrawer = (props: FeedbackDrawerProps) => {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { mutateAsync: updateFeedbackData } = useUpdateFeedbackData()
  const { mutate: submitFeedback } = useProductFeedback()
  const { showToast } = useToast()
  const [successMessageShown, setSuccessMessageShown] = useState(false)
  const [initialRating, setInitialRating] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (props.isOpen) {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.get('score') !== null || window.localStorage.getItem('__feedbackScore') !== null) {
        setInitialRating(parseInt(urlParams.get('score') ?? window.localStorage.getItem('__feedbackScore') ?? '0', 10))
        window.localStorage.removeItem('__feedbackScore')
      }
    }
  }, [props.isOpen])

  const onConfirm = ({
    message,
    rating,
    isCallRequested,
  }: {
    message: string
    rating: number
    isCallRequested: boolean
  }) => {
    Promise.allSettled([
      updateFeedbackData({
        data: {
          email: props.currentFeedbackData?.email,
          dashboardDrawer: props.currentFeedbackData?.dashboardDrawer,
          scorePoints: rating.toString(),
        },
      }),
      rating <= 8 && message.length > 0
        ? new Promise<void>((resolve, reject) =>
            submitFeedback(
              { data: { currentSubscriptionId, message, isCallRequested } },
              {
                onSuccess: () => resolve(),
                onError: reject,
              }
            )
          )
        : Promise.resolve(),
    ]).then(([updateResult, feedbackResult]) => {
      if (updateResult.status !== 'fulfilled' || feedbackResult.status !== 'fulfilled') {
        showToast({
          message: 'An error occured when submitting your feedback. Please contact support if this error persists.',
          severity: 'error',
        })
      } else {
        if (rating >= 9) {
          setSuccessMessageShown(true)
        } else {
          showToast({
            message: "Thanks! Feedback received. We'll follow up shortly.",
            severity: 'success',
          })
          props.onClose()
        }
      }
    })
  }

  return (
    <FeedbackDrawerView
      isOpen={props.isOpen}
      alreadyRated={props.alreadyRated}
      onClose={props.onClose}
      onConfirm={onConfirm}
      successMessageShown={successMessageShown}
      initialRating={initialRating}
    />
  )
}
