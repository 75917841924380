import { Region as FpjsRegion } from '@fingerprintjs/fingerprintjs-pro'

export const API_URL = process.env.REACT_APP_API_URL
export const VERSION = process.env.REACT_APP_VERSION

// This env var is provided by Vercel CI in most cases, except when the Vercel CLI is used directly
// See: https://vercel.com/docs/concepts/projects/environment-variables#system-environment-variables
export const GIT_REF = process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA
export const IS_DEV_MODE = process.env.NODE_ENV === 'development'
export const IS_TEST_MODE = process.env.NODE_ENV === 'test'
export const IS_PRODUCTION_MODE = process.env.NODE_ENV === 'production'
export const IS_QA_ENV = process.env.REACT_APP_IS_QA_ENV ?? false
export const IS_ON_VERCEL = process.env.REACT_APP_IS_ON_VERCEL ?? false
export const USE_AMPLITUDE_PROXY = process.env.REACT_APP_USE_AMPLITUDE_PROXY ?? false
export const USE_IDENTIFICATION_EXPORT = process.env.REACT_APP_USE_IDENTIFICATION_EXPORT ?? false
export const USE_ENCRYPTED_KEYS = process.env.REACT_APP_USE_ENCRYPTED_KEYS === 'true'
export const USE_DISABLE_BOTS_FEATURE = process.env.REACT_APP_USE_DISABLE_BOTS_FEATURE === 'true'
export const SHOW_MOBILE_REQUEST_FILTERING = process.env.REACT_APP_SHOW_MOBILE_REQUEST_FILTERING === 'true'
export const USE_WEBHOOK_SIGNATURE = process.env.REACT_APP_USE_WEBHOOK_SIGNATURE === 'true'
export const USE_MANAGEMENT_KEYS = process.env.REACT_APP_USE_MANAGEMENT_KEYS === 'true'
export const USE_WORKSPACE_ENVIRONMENT = process.env.REACT_APP_USE_WORKSPACE_ENVIRONMENT === 'true'
export const USE_SUSPECT_SCORE_PAGE = process.env.REACT_APP_USE_SUSPECT_SCORE_PAGE === 'true'

export const WHATS_NEW_RELEASE_TIME = process.env.REACT_APP_WHATS_NEW_RELEASE_TIME ?? '2099-12-31'

export const FPJS_SCRIPT_URL_PATTERN = process.env.REACT_APP_FPJS_SCRIPT_URL_PATTERN
export const FPJS_TOKEN = process.env.REACT_APP_FPJS_TOKEN ?? 'placeholder-key'
export const FPJS_REGION = process.env.REACT_APP_FPJS_REGION as FpjsRegion | undefined
export const FPJS_USE_TLS = process.env.REACT_APP_FPJS_USE_TLS === 'true'

// The default one is development environment
export const STRIPE_TEST_KEY = 'pk_test_viS9nkQ9LzXzE14WA5DvKVmZ00MTcFATZG'
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? (IS_PRODUCTION_MODE ? '' : STRIPE_TEST_KEY)

export const ROLLBAR_API_TOKEN = process.env.REACT_APP_ROLLBAR_API_TOKEN ?? ''

export const GTM_ID = process.env.REACT_APP_GTM_ID ?? ''
export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY ?? ''
export const AMPLITUDE_EXPERIMENT_DEPLOY_API_KEY = process.env.REACT_APP_AMPLITUDE_EXPERIMENT_DEPLOY_API_KEY ?? ''
export const AMPLITUDE_API_ENDPOINT = process.env.REACT_APP_AMPLITUDE_API_ENDPOINT ?? ''
export const AMPLITUDE_API_ENDPOINT_V2 = process.env.REACT_APP_AMPLITUDE_API_ENDPOINT_V2 ?? ''

export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL ?? ''
export const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME ?? ''
export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL ?? ''
/** Length of the random paths used for calling Vercel functions. */
export const RANDOM_PATH_LENGTH = parseInt(process.env.REACT_APP_RANDOM_PATH_LENGTH ?? '8', 10)
export const HOST =
  process.env.REACT_APP_VERCEL_ENV === 'preview' && typeof window !== 'undefined'
    ? `https://${window.location.hostname}`
    : 'https://dashboard.fingerprint.com'
export const WARDEN_URL = process.env.REACT_APP_WARDEN_URL ?? ''
export const CALENDLY_FEEDBACK_LINK = process.env.REACT_APP_CALENDLY_FEEDBACK_LINK ?? ''

export const IS_MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE === 'true'

export const getRedirectOriginsWhitelist = (): string[] => {
  const envVar = process.env.REACT_APP_REDIRECT_ORIGINS_WHITELIST
  if (!envVar) {
    return []
  }
  try {
    const origins = JSON.parse(envVar)
    return Array.isArray(origins) ? origins : []
  } catch (_e) {
    return []
  }
}

export const RANDOM_AMPLI = process.env.REACT_APP_RANDOM_AMPLI ?? '7'
export const STATUS_PAGE_URL = process.env.REACT_APP_STATUS_PAGE_URL ?? 'https://status.fingerprint.com/'
export const AD_BLOCKER_DETECTION_URL =
  process.env.REACT_APP_AD_BLOCKER_DETECTION_URL ?? 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'

export const IS_E2E_MODE = typeof window !== 'undefined' && window.playwright != null
