import { Typography } from '@mui/material'
import clsx from 'clsx'
import DashboardWidget from 'components/DashboardWidget/DashboardWidget'
import { ChipColor, HealthChip } from 'features/commonUI'

import styles from './HealthWidget.module.scss'

export interface HealthWidgetProps {
  title: string
  children?: React.ReactNode
  link?: React.ReactNode
  className?: string
  label?: React.ReactNode
  chipColor?: ChipColor
}

export function HealthWidget({ title, link, children, className, label, chipColor }: HealthWidgetProps) {
  return (
    <DashboardWidget
      title={title}
      chip={label ? <HealthChip color={chipColor ?? 'gray'} label={label} /> : null}
      link={link}
      className={clsx(styles.widget, className)}
    >
      {children}
    </DashboardWidget>
  )
}

export interface HealthWidgetBodyProps {
  icon?: React.ReactNode
  title: string
  subtitle?: React.ReactNode
  children?: React.ReactNode
}

export function HealthWidgetBody({ icon, title, subtitle, children }: HealthWidgetBodyProps) {
  return (
    <div className={styles.body}>
      <div>
        {icon ? <div className={styles.icon}>{icon}</div> : null}
        <Typography variant='h2' component='h4'>
          {title}
        </Typography>
        {subtitle ? <div className={styles.secondaryText}>{subtitle}</div> : null}
      </div>
      {children}
    </div>
  )
}
