import { Link, Typography } from '@mui/material'
import { PRIVACY_URL, TERMS_URL } from 'const'

import styles from './PolicyAgreementDisclaimer.module.scss'

export type PolicyAgreementDisclaimerProps = {
  onClickPrivacyPolicy?: () => void
  onClickTermsOfService?: () => void
}

export default function PolicyAgreementDisclaimer({
  onClickPrivacyPolicy = () => {},
  onClickTermsOfService = () => {},
}: PolicyAgreementDisclaimerProps) {
  return (
    <Typography variant='body2' component='div' className={styles.disclaimer}>
      By creating an account, you agree to our{' '}
      <Link href={PRIVACY_URL} target='_blank' underline='hover' onClick={onClickPrivacyPolicy}>
        Privacy&nbsp;Policy
      </Link>{' '}
      and{' '}
      <Link href={TERMS_URL} target='_blank' underline='hover' onClick={onClickTermsOfService}>
        Terms&nbsp;of&nbsp;Service
      </Link>
      .
    </Typography>
  )
}
