import { useQuery } from '@tanstack/react-query'
import { useBuildRequest, useGenericErrorHandler } from 'hooks/api/base'

export const useClientSdkPerformanceQuery = (subscriptionId: string) => {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['health', 'sdk', 'client', 'performance', subscriptionId] as const,
    queryFn: () =>
      withErrorHandling(buildRequest('clientSdkPerformance', { params: { subscriptionId } })).then(({ data }) => data),
  })
}
