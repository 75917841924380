import { AnalyticsContext } from 'models'
import React, { ComponentType, PropsWithChildren } from 'react'

import IntegrationReadmeCodeSnippet from './IntegrationReadmeCodeSnippet/IntegrationReadmeCodeSnippet'
import styles from './MDXBaseComponents.module.scss'

function UnsupportedComponent() {
  if (process.env.NODE_ENV === 'development') {
    throw new Error('Component is not supported')
  }

  return null
}

type MDXComponentMap = {
  wrapper?: ComponentType<PropsWithChildren>
  a?: ComponentType<PropsWithChildren>
  code?: ComponentType<PropsWithChildren>
  h1?: ComponentType<PropsWithChildren>
  h2?: ComponentType<PropsWithChildren>
  h3?: ComponentType<PropsWithChildren>
  h4?: ComponentType<PropsWithChildren>
  h5?: ComponentType<PropsWithChildren>
  h6?: ComponentType<PropsWithChildren>
  hr?: ComponentType<PropsWithChildren>
  blockquote?: ComponentType<PropsWithChildren>
  ol?: ComponentType<PropsWithChildren>
  ul?: ComponentType<PropsWithChildren>
  br?: ComponentType<PropsWithChildren>
  img?: ComponentType<PropsWithChildren>
  li?: ComponentType<PropsWithChildren>
  p?: ComponentType<PropsWithChildren>
  pre?: ComponentType<PropsWithChildren>
  strong?: ComponentType<PropsWithChildren>
  em?: ComponentType<PropsWithChildren>
}

function CodeSnippetWrapper({
  className,
  children,
  analyticsContext,
}: React.PropsWithChildren<{ className?: string; analyticsContext: AnalyticsContext }>) {
  // code block
  if (className && className.startsWith('language-')) {
    const language = className.slice('language-'.length)
    return (
      <IntegrationReadmeCodeSnippet language={language} analyticsContext={analyticsContext}>
        {children}
      </IntegrationReadmeCodeSnippet>
    )
  }

  // inline code
  return <code className={styles.code}>{children}</code>
}

export const createMDXBaseComponents = (analyticsContext: AnalyticsContext): MDXComponentMap => ({
  wrapper: (props) => <div className={styles.container}>{props.children}</div>,

  h1: (props) => <h1 className={styles.h1}>{props.children}</h1>,

  h2: (props) => <h2 className={styles.h2}>{props.children}</h2>,

  h3: (props) => <h3 className={styles.h3}>{props.children}</h3>,

  h4: (props) => <h4 className={styles.subheader}>{props.children}</h4>,

  h5: (props) => <h5 className={styles.subheader}>{props.children}</h5>,

  h6: (props) => <h6 className={styles.subheader}>{props.children}</h6>,

  ol: (props) => <ol className={styles.list}>{props.children}</ol>,

  ul: (props) => <ul className={styles.list}>{props.children}</ul>,

  a: (props) => (
    <a {...props} rel='noopener noreferrer' target='_blank' className={styles.anchor}>
      {props.children}
    </a>
  ),

  code: (props) => <CodeSnippetWrapper {...props} analyticsContext={analyticsContext} />,

  hr: UnsupportedComponent,
  blockquote: UnsupportedComponent,

  // The only context pre appears in is code blocks which is handled by
  // "code" component above
  pre: (props) => <>{props.children}</>,
})
