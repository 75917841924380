import { PaymentMethod, SubscriptionTier } from 'models'

export enum PurchaseType {
  PaidSubscription = 'paid',
  FreeSubscription = 'free',
  SubscriptionUpgrade = 'upgrade',
  Trial = 'trial',
}

export interface PurchaseData {
  type: PurchaseType
  paidWith?: PaymentMethod
  subscriptionId: string
  tier?: SubscriptionTier
}
