import { AssignmentTurnedIn, Check, Close, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Alert, Collapse, Paper, Tooltip } from '@mui/material'
import { WebhookEventsFilterProps } from 'components/Filter/WebhookEvents/WebhookEventsFilter'
import {
  Table,
  TableBody,
  TableBodyData,
  TableBodyEmpty,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from 'components/Table/Table'
import Tag, { TagProps } from 'components/Tag/Tag'
import { GenericError } from 'const'
import { formatDatetime } from 'features/commonUI'
import { getHttpStatusRange } from 'helpers/common'
import { WebhookEvent } from 'models'
import { ampli } from 'models/ampli'
import { useState } from 'react'

import EventDetails from './EventDetails/EventDetails'
import styles from './WebhookEventsTable.module.scss'

export interface WebhookEventsTableProps {
  data: WebhookEvent[]
  hasPreviousEntries?: boolean
  hasNextEntries?: boolean
  isLoading?: boolean
  error?: GenericError | null
  emptyAlert?: string
  isFilterApplied?: boolean
  onFilter: WebhookEventsFilterProps['onFilter']
  onRequestPreviousEntries?: () => void
  onRequestNextEntries?: () => void
  recentlyResentEvents?: string[]
  onResend: (requestId: string) => void
  className?: string
}

export default function WebhookEventsTable({
  data,
  hasPreviousEntries,
  hasNextEntries,
  isLoading,
  error,
  emptyAlert,
  onRequestPreviousEntries,
  onRequestNextEntries,
  recentlyResentEvents,
  onResend,
  className,
}: WebhookEventsTableProps) {
  return (
    <TableContainer component={Paper} className={className}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width='35%'>Request Id</TableCell>
            <TableCell width='25%'>Visitor Id</TableCell>
            <TableCell width='15%'>Status</TableCell>
            <TableCell width='20%' align='right'>
              Date
            </TableCell>
            <TableCell width='5%' />
          </TableRow>
        </TableHead>

        <TableBody columnCount={5} isLoading={isLoading} showBodyLoader={data.length === 0} error={error}>
          <TableBodyData>
            {data.map((event) => (
              <EventRow
                key={event.requestID}
                didResendRecently={recentlyResentEvents?.includes(event.requestID)}
                onResend={onResend}
                event={event}
              />
            ))}
          </TableBodyData>

          <TableBodyEmpty>
            <Alert severity='info'>{emptyAlert}</Alert>
          </TableBodyEmpty>
        </TableBody>
      </Table>

      <TableFooter>
        {!error && (
          <TablePagination
            hasNextEntries={hasNextEntries && data.length > 0}
            hasPreviousEntries={hasPreviousEntries}
            onRequestNextEntries={onRequestNextEntries}
            onRequestPreviousEntries={onRequestPreviousEntries}
            isLoading={isLoading}
          />
        )}
      </TableFooter>
    </TableContainer>
  )
}

interface EventRowProps {
  event: WebhookEvent
  didResendRecently?: boolean
  onResend: (requestId: string) => void
}

function EventRow({ event, didResendRecently, onResend }: EventRowProps) {
  const { requestID, visitorID, responseStatusCode, timestamp, verification } = event

  const [isExpanded, setIsExpanded] = useState(false)

  function toggleExpanded() {
    setIsExpanded((value) => !value)
    if (!isExpanded) {
      ampli.webhookEventDetailsViewed()
    }
  }

  return (
    <>
      <TableRow onClick={toggleExpanded} interactive>
        <TableCell monospace classes={{ root: styles.eventCell }}>
          {requestID}
          {verification && (
            <Tooltip title='Verification event' placement='top' arrow>
              <AssignmentTurnedIn fontSize='small' className={styles.verification} />
            </Tooltip>
          )}
        </TableCell>
        <TableCell monospace classes={{ root: styles.eventCell }}>
          {visitorID}
        </TableCell>
        <TableCell classes={{ root: styles.eventCell }}>
          <Tag {...getStatusTagProps(responseStatusCode)} className={styles.tag} />
        </TableCell>
        <TableCell align='right' classes={{ root: styles.eventCell }}>
          {formatDatetime(new Date(timestamp), 'precise')}
        </TableCell>
        <TableCell classes={{ root: styles.eventCell }}>
          {isExpanded ? (
            <ExpandLess fontSize='small' className={styles.chevron} />
          ) : (
            <ExpandMore fontSize='small' className={styles.chevron} />
          )}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={5} classes={{ root: styles.detailCell }}>
          <Collapse in={isExpanded}>
            <EventDetails didResendRecently={didResendRecently} onResend={onResend} {...event} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function getStatusTagProps(statusCode: number): TagProps {
  if (getHttpStatusRange(statusCode) === 200) {
    return {
      label: 'Succeeded',
      color: 'green',
      icon: <Check />,
    }
  }

  return {
    label: 'Failed',
    color: 'red',
    icon: <Close />,
  }
}
