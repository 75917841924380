import { Grid } from '@mui/material'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'

import styles from './Layout.module.scss'

type Props = {
  children: React.ReactNode
  className?: string
}

export function MainContent({ children, className }: Props) {
  return (
    <MainGrid className={className}>
      <Grid item xs={12}>
        {children}
      </Grid>
    </MainGrid>
  )
}

type MainGridProps = {
  spacing?: number
  className?: string
}
export function MainGrid({ children, spacing = 3, className }: PropsWithChildren<MainGridProps>) {
  return (
    <Grid container spacing={spacing} className={clsx(styles.content, className)}>
      {children}
    </Grid>
  )
}

export function MainColumn({ children, className }: PropsWithChildren<{ className?: string }>) {
  return <div className={clsx([styles.column, className])}>{children}</div>
}
