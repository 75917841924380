import { Paper, Stack } from '@mui/material'
import { Datum } from '@nivo/line'
import clsx from 'clsx'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import reduce from 'lodash/reduce'
import { ClientSDKPerformanceStatsResponse } from 'models'

import { calculateTicks } from './calculateTicks'
import { EmptyState } from './EmptyState'
import { HealthChart } from './HealthChart'
import { HealthChartHeader } from './HealthChartHeader'
import styles from './OperationsChart.module.scss'

export type OperationsChartProps = {
  performance: ClientSDKPerformanceStatsResponse | undefined
  className?: string
}
const COLORS = ['#F44336', '#FF9800']
export const OperationsChart = ({ performance, className }: OperationsChartProps) => {
  const lines = extractOperationsLines(performance)
  const axisYTicks = Math.min(5, Math.max(1, ...lines.flatMap((l) => l.data.map((d) => (d.y ?? 0) as number))))
  const isEmpty = lines.every((l) => l.data.length === 0)

  return (
    <Paper className={clsx(className, styles.root)}>
      <Stack gap={3}>
        <HealthChartHeader
          title='Identification requests over time'
          tooltip='Client and server-side errors encountered by the JavaScript Agent, including timeouts.'
        />
        <Stack gap={2}>
          {isEmpty ? (
            <EmptyState />
          ) : (
            <HealthChart data={lines} colors={COLORS} axisYTicks={axisYTicks} className={styles.chart} />
          )}
        </Stack>
      </Stack>
    </Paper>
  )
}

function extractOperationsLines(performance: ClientSDKPerformanceStatsResponse | undefined) {
  if (performance === undefined) {
    return [
      { id: 'Error', data: [] },
      { id: 'Timeout', data: [] },
    ]
  }

  const timestampAggregatedData = groupBy(
    performance.stats.flatMap(({ data }) => data),
    ({ timestamp }) => new Date(timestamp).valueOf()
  )

  const ticks = calculateTicks(performance.from, performance.to)
  for (const tick of ticks) {
    const oldValue = timestampAggregatedData[tick.valueOf()]
    if (oldValue === undefined) {
      timestampAggregatedData[tick.valueOf()] = [
        {
          timeoutErrorsCount: 0,
          genericErrorsCount: 0,
          getLatencyMedian: null,
          timestamp: tick.toISOString(),
        },
      ]
    }
  }

  const operationsMap = mapValues(timestampAggregatedData, (sameTime) =>
    reduce(
      sameTime,
      (sum, value) => ({
        timeoutErrorsCount: sum.timeoutErrorsCount + (value.timeoutErrorsCount ?? 0),
        genericErrorsCount: sum.genericErrorsCount + (value.genericErrorsCount ?? 0),
        timestamp: sum.timestamp,
      }),
      {
        timeoutErrorsCount: 0,
        genericErrorsCount: 0,
        timestamp: new Date(sameTime[0].timestamp),
      }
    )
  )

  const timeoutData: Datum[] = []
  const errorData: Datum[] = []

  for (const tick of ticks) {
    const value = operationsMap[tick.valueOf()]

    timeoutData.push({
      x: value.timestamp,
      y: value.timeoutErrorsCount,
    })

    errorData.push({
      x: value.timestamp,
      y: value.genericErrorsCount,
    })
  }

  return [
    { id: 'Error', data: errorData },
    { id: 'Timeout', data: timeoutData },
  ]
}
