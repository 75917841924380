import * as fpjsReact from '@fingerprintjs/fingerprintjs-pro-react'
import { AppRoute } from 'appRoutes'
import { COOKIE_NAMES, GenericError, IS_PRODUCTION_MODE } from 'const'
import { getCookie } from 'helpers/cookies'
import { errorWithFallback, getUtmParams } from 'helpers/data'
import logger from 'helpers/logger'
import { useDocumentTitle, useHomepageRedirect, useOAuth, useSignUpMutation, useSsoStatusCheck } from 'hooks'
import { useQueryParams } from 'hooks/queryParams'
import { useSmartSignals } from 'hooks/smartSignals'
import { ComponentProps, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { visitedSignup } from '../../../../helpers/vendor'
import { useSavedEmail } from '../../../../hooks/savedEmail'
import { FakeDashboardInfoLayout } from '../../components/AnimatedLayout/FakeDashboardInfoLayout'
import { SignUpForm } from '../../components/SignUpForm/SignUpForm'

export function SignUpPage() {
  useHomepageRedirect()
  useDocumentTitle('Sign Up')

  useEffect(() => {
    // Make sure that analytics is enabled on the Sign Up page.
    // The analytics useEffect only triggers the event on the first page load.
    // In case the user navigates to the Sign Up page later, this will trigger the GTM to load.
    visitedSignup()
  }, [])

  const { data: visitorData, isLoading: isLoadingFpjs, getData } = fpjsReact.useVisitorData({ extendedResult: true })
  const { data: smartSignals } = useSmartSignals(visitorData?.requestId)
  const botDetected = smartSignals?.products?.botd?.data?.bot?.result === 'bad' ? 'true' : 'false'
  const suspectScore = smartSignals?.products?.suspectScore?.data?.result?.toString() ?? '0'

  const { mutate: sendSignUpRequest, isLoading, error: signUpError } = useSignUpMutation(botDetected, suspectScore)
  const { savedEmail, rememberEmail } = useSavedEmail()
  const queryParams = useQueryParams()
  const history = useHistory()

  const [email, setEmail] = useState(savedEmail)
  const [error, setError] = useState<GenericError>()

  const { data: ssoStatus } = useSsoStatusCheck(email)
  const { data: oAuthProviders, isLoading: isLoadingOAuth } = useOAuth()

  const refTag = queryParams['ref']

  useEffect(() => {
    if (signUpError) {
      setError(signUpError)
    }
  }, [signUpError])

  useEffect(() => {
    if (ssoStatus?.sso.isEnabled) {
      setError({
        code: 'sso_enabled',
        param: 'domain',
        message: 'Your company already has an account with Single Sign On enabled, please try to log in instead.',
      })
    } else if (error?.code === 'sso_enabled') {
      setError(undefined)
    }
  }, [ssoStatus?.sso.isEnabled, error?.code])

  interface HistoryProps {
    landingPage: string
    visitedPages: string[]
    lastPage: string
    previousPage: string
    utmParams: Record<string, string>
  }

  const onSubmit = useCallback<ComponentProps<typeof SignUpForm>['onSubmit']>(
    async ({ name, email: emailAddress, password }) => {
      const historyDataCookies = getCookie(COOKIE_NAMES.websiteData)
      const historyData: HistoryProps = historyDataCookies && JSON.parse(historyDataCookies).historyData
      const visitedPages = historyData?.visitedPages
      const landingPage = historyData?.landingPage
      const utmParams = historyData?.utmParams

      const hasUtmQueryParams = Object.keys(getUtmParams(queryParams)).length > 0

      let utmInfo

      // if the link has a query string we prefer it over cookies, since it can come from another source such as a marketing email
      if (hasUtmQueryParams) {
        utmInfo = getUtmParams(queryParams, { referral_url: document.referrer })
      } else {
        utmInfo = utmParams
          ? getUtmParams(utmParams, { referral_url: document.referrer })
          : { referral_url: document.referrer }
      }

      // Additional visitor id call is needed to send behavioral signals collected from the form
      // Unhandled errors are suppressed because we don't need to handle or know about them
      const secondVisitorData = await getData({ ignoreCache: true }).catch(() => undefined)

      const fpjsVisitorId = secondVisitorData?.visitorId ?? visitorData?.visitorId
      const botdRequestId = secondVisitorData?.requestId ?? visitorData?.requestId

      const isProductionEnv = IS_PRODUCTION_MODE || process.env.PRODUCTION_LIKE
      // on the server we allow to signup without a fingerprint for staging environments, and we should support it here
      if (fpjsVisitorId || !isProductionEnv) {
        sendSignUpRequest(
          {
            data: {
              name,
              email: emailAddress,
              password,
              fpjsVisitorId,
              utmInfo,
              visitedPages,
              landingPage,
              botdRequestId,
              signupSource: refTag,
            },
          },
          {
            onSuccess: () => {
              rememberEmail(emailAddress)
              history.replace(AppRoute.Onboarding) // will redirect to /signup/confirm/ when email is not confirmed
            },
          }
        )
      } else {
        const errorMessage = 'Sign Up request was blocked, try to disable ad blocker and try again.'
        logger.error(errorMessage)
        setError({
          code: 'value_missing',
          param: 'visitorId',
          message: errorMessage,
        })
      }
    },
    [visitorData, sendSignUpRequest, queryParams, history, rememberEmail, refTag, getData]
  )

  return (
    <FakeDashboardInfoLayout srcPage={'signup'}>
      <SignUpForm
        isLoading={isLoadingFpjs || isLoading}
        isLoadingOAuth={isLoadingOAuth}
        error={errorWithFallback(error, 'email', 'Failed to create account')}
        onSubmit={onSubmit}
        onCheckSso={(emailAddress: string) => {
          setEmail(emailAddress)
        }}
        oAuthProviders={oAuthProviders}
      />
    </FakeDashboardInfoLayout>
  )
}
