import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'

import styles from './CalloutBox.module.scss'

interface CalloutBoxProps {
  type: 'info' | 'warning'
  title: string
}

export default function CalloutBox({ type, title, children }: PropsWithChildren<CalloutBoxProps>) {
  return (
    <Box className={clsx(styles.callout, { [styles.info]: type === 'info', [styles.warning]: type === 'warning' })}>
      <Typography variant='bodyMMedium'>{title}</Typography>
      <Typography variant='body2' fontSize='12px'>
        {children}
      </Typography>
    </Box>
  )
}
