import { AppRoute, buildRoute } from 'appRoutes'
import { DashboardWidgetLink } from 'components/DashboardWidget/Link/DashboardWidgetLink'
import { ChipColor } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { SdkHealthStatus, UpdateUrgency } from 'models'
import { HealthIndicatorSeverity } from 'models/health'

import { HealthWidget, HealthWidgetBody } from '../HealthWidget'
import { WidgetSeverityIndicator } from '../WidgetSeverityIndicator/WidgetSeverityIndicator'

export type SdkHealthWidgetStatus =
  | 'latest'
  | 'not_applicable'
  | 'one_behind'
  | 'multiple_behind'
  | 'significantly_behind'
  | 'critically_behind'

const URGENCY_STATUS_MAP: Record<UpdateUrgency, SdkHealthWidgetStatus> = {
  unavailable: 'latest',
  available: 'one_behind',
  recommended: 'multiple_behind',
  required: 'significantly_behind',
  critical: 'critically_behind',
}

const STATUS_COLOR_MAP: Record<SdkHealthWidgetStatus, ChipColor> = {
  latest: 'green',
  not_applicable: 'gray',
  one_behind: 'blue',
  multiple_behind: 'yellow',
  significantly_behind: 'red',
  critically_behind: 'black',
}

const STATUS_LABEL_MAP: Record<SdkHealthWidgetStatus, string> = {
  latest: 'Latest',
  not_applicable: 'N/A',
  one_behind: 'Update available',
  multiple_behind: 'Update recommended',
  significantly_behind: 'Update required',
  critically_behind: 'Critical update required',
}

type SdkHealthWidgetAdapterProps = {
  sdkHealthStatus: SdkHealthStatus
  className?: string
}
export function SdkHealthWidgetAdapter({ sdkHealthStatus, className }: SdkHealthWidgetAdapterProps) {
  if (sdkHealthStatus.throttledRequestsPercentage == null) {
    return <SdkHealthWidget status='not_applicable' className={className} />
  }
  const status = URGENCY_STATUS_MAP[sdkHealthStatus.highestUrgency]

  return <SdkHealthWidget status={status} className={className} />
}

export interface SdkHealthWidgetProps {
  status: SdkHealthWidgetStatus
  className?: string
}
export function SdkHealthWidget({ status, className }: SdkHealthWidgetProps) {
  const { currentSubscriptionId } = useCurrentSubscription()
  const link = (
    <DashboardWidgetLink
      to={buildRoute(AppRoute.SdkHealth, { subscriptionId: currentSubscriptionId })}
      text='Go to SDK health page'
    />
  )

  return (
    <HealthWidget
      title='SDK Health'
      link={link}
      chipColor={STATUS_COLOR_MAP[status]}
      label={STATUS_LABEL_MAP[status]}
      className={className}
    >
      <SdkHealthWidgetInfo status={status} />
    </HealthWidget>
  )
}

function SdkHealthWidgetInfo({ status }: { status: SdkHealthWidgetStatus }) {
  switch (status) {
    case 'latest':
      return (
        <HealthWidgetBody
          icon={<WidgetSeverityIndicator severity={HealthIndicatorSeverity.Fine} />}
          title='All SDKs are fine'
          subtitle='Your client and server-side SDKs are up to date.'
        />
      )

    case 'not_applicable':
      return (
        <HealthWidgetBody
          icon={<WidgetSeverityIndicator severity={HealthIndicatorSeverity.NotApplicable} />}
          title='No data'
          subtitle='You haven&#39;t sent any requests yet.'
        />
      )

    case 'one_behind':
      return (
        <HealthWidgetBody
          icon={<WidgetSeverityIndicator severity={HealthIndicatorSeverity.Low} />}
          title='One version behind'
          subtitle={
            'Outdated client and server-side SDKs lower accuracy and may cause errors. ' +
            'An update is recommended to achieve maximum Fingerprint strength.'
          }
        />
      )

    case 'multiple_behind':
      return (
        <HealthWidgetBody
          icon={<WidgetSeverityIndicator severity={HealthIndicatorSeverity.Mid} />}
          title='Multiple versions behind'
          subtitle={
            'Outdated client and server-side SDKs lower accuracy and may cause errors. ' +
            'An update is recommended to achieve maximum Fingerprint strength.'
          }
        />
      )

    case 'significantly_behind':
      return (
        <HealthWidgetBody
          icon={<WidgetSeverityIndicator severity={HealthIndicatorSeverity.High} />}
          title='Significantly behind current version'
          subtitle={
            'Outdated client and server-side SDKs lower accuracy and may cause errors. ' +
            'An update is recommended to achieve maximum Fingerprint strength.'
          }
        />
      )

    case 'critically_behind':
      return (
        <HealthWidgetBody
          icon={<WidgetSeverityIndicator severity={HealthIndicatorSeverity.High} />}
          title='Critical features are unavailable, upgrade now'
          subtitle={
            'Outdated client and server-side SDKs lower accuracy and may cause errors. ' +
            'An update is recommended to achieve maximum Fingerprint strength.'
          }
        />
      )
  }
}
