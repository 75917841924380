import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

export function useIntegrationStatus(subscriptionId: string) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['subscription/integration_status', subscriptionId] as const,
    queryFn: async ({ queryKey: [_, subId] }) => {
      const result = await withErrorHandling(
        buildRequest('integrationStatusGet', { params: { subscriptionId: subId } })
      )
      return result.data
    },
    enabled: subscriptionId != null,
  })
}

export function useSkipIntegrationStep(subscriptionId: string) {
  const queryClient = useQueryClient()

  return useRequestMutation('integrationStepSkip', {
    onSuccess: () => {
      queryClient.invalidateQueries(['subscription/integration_status', subscriptionId])
    },
  })
}

export function useIntegrationStatusGetStarted(subscriptionId: string, enabled = true) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['subscription/integration_status', subscriptionId, 'get_started'] as const,
    queryFn: async ({ queryKey: [_, subId] }) => {
      const result = await withErrorHandling(
        buildRequest('integrationStatusGet', { params: { subscriptionId: subId } })
      )
      return result.data
    },
    enabled: subscriptionId != null && enabled,
  })
}
