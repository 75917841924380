import { Alert, Typography } from '@mui/material'
import { useCurrentSubscription } from 'features/subscription'
import { useTrafficRuleBulkUpdateMutation } from 'hooks'
import { TrafficRule, TrafficRuleCreatePayload, TrafficRuleType, WorkspaceEnvironment } from 'models'
import { useCallback } from 'react'

import { AllowList } from '../AllowList/AllowList'

interface WebSectionProps {
  trafficRules: TrafficRule[]
  isLoading: boolean
  isCreationLimitExceeded: boolean
  currentEnvironment: WorkspaceEnvironment
}

export default function MobileSection({
  trafficRules,
  isLoading,
  isCreationLimitExceeded,
  currentEnvironment,
}: WebSectionProps) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { mutate: sendTrafficRuleBulkUpdateRequest } = useTrafficRuleBulkUpdateMutation()

  const handleBulkUpdateTrafficRules = useCallback(
    (entities: TrafficRuleCreatePayload[]) => {
      const params = { subscriptionId }
      const data = { type: TrafficRuleType.AppPackageName, entities }
      sendTrafficRuleBulkUpdateRequest({ data, params })
    },
    [sendTrafficRuleBulkUpdateRequest, subscriptionId]
  )

  return (
    <>
      <Alert severity='info'>
        <Typography variant='bodyMMedium'>Available only for Android SDK v2.0.0+ and iOS SDK v2.1.3+</Typography>
        <Typography component='div' variant='bodyM'>
          Update your SDKs to prevent your API keys from being misused.
        </Typography>
      </Alert>
      <AllowList
        isCreationLimitExceeded={isCreationLimitExceeded}
        handleBulkUpdateHeaderRules={handleBulkUpdateTrafficRules}
        isLoading={isLoading}
        trafficRules={trafficRules}
        trafficRuleType={TrafficRuleType.AppPackageName}
        currentEnvironment={currentEnvironment}
      />
    </>
  )
}
