import { useOverviewPreferences } from 'hooks'
import { useIntegrationStatus, useSkipIntegrationStep } from 'hooks/api/integration_status'
import { WidgetName } from 'models'
import { useCallback } from 'react'

import { IntegrationStatusWidget } from '../IntegrationStatusWidget/IntegrationStatusWidget'

export function ConditionalIntegrationSubscriptionWidget({ subscriptionId }: { subscriptionId: string }) {
  const { getWidgetState, updateWidgetState } = useOverviewPreferences(subscriptionId)
  const handleCloseIntegrationStatus = () => {
    updateWidgetState(WidgetName.IntegrationStatus, { isHidden: true })
  }

  const { isHidden: isIntegrationStatusHidden } = getWidgetState(WidgetName.IntegrationStatus)
  const { data: integrationStatus } = useIntegrationStatus(subscriptionId)
  const showIntegrationStatus = !isIntegrationStatusHidden && integrationStatus
  const { mutate: skipIntegrationStep, isLoading: isSkipping } = useSkipIntegrationStep(subscriptionId)
  const onSkipIntegrationStep = useCallback(
    () => skipIntegrationStep({ params: { subscriptionId } }),
    [skipIntegrationStep, subscriptionId]
  )

  if (showIntegrationStatus) {
    return (
      <IntegrationStatusWidget
        integrationStatus={integrationStatus}
        isLoading={isSkipping}
        onSkip={onSkipIntegrationStep}
        onClose={handleCloseIntegrationStatus}
      />
    )
  }

  return null
}
