import { useRequestMutation } from './base'

export function useBdrMeetingScheduled() {
  return useRequestMutation('bdrMeetingScheduled', {
    errorHandling: {
      forceToast: true,
    },
  })
}

export function useContactSalesEnterpriseClosure() {
  return useRequestMutation('contactSalesEnterpriseClosure')
}
