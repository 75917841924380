export interface SubscriptionHealth {
  jsAgentHealth: JsAgentHealth
  throttledRequestsHealth: ThrottledRequestsHealth
}

export enum HealthIndicatorSeverity {
  Fine = 'fine',
  Low = 'low',
  Mid = 'mid',
  High = 'high',
  NotApplicable = 'notApplicable',
}

export interface HealthIndicator {
  severity: HealthIndicatorSeverity
}

export interface JsAgentHealth extends HealthIndicator {
  currentJsAgentVersion?: string
  latestJsAgentVersion: string
}

export interface ThrottledRequestsHealth extends HealthIndicator {
  throttledRequestsPercentage: number | null
  isTokenRateLimitAtMax: boolean
}

export type ClientSDKPerformanceStatsResponse = {
  from: string
  to: string
  stats: Array<{
    version: string
    data: ClientSDKPerfDataPoint[]
  }>
}

export type ClientSDKPerfDataPoint = {
  timestamp: string
  genericErrorsCount: number
  timeoutErrorsCount: number
  getLatencyMedian: number | null
}
