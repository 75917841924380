import { CustomDateRange, isPredefinedRange, PredefinedRange } from 'helpers/dateRange'
import { DateTime } from 'luxon'

/**
 * Interpreter for date picker view (to show which dates are selected)
 */
export function interpretDateRange({
  dateRange,
}: {
  dateRange?: CustomDateRange | PredefinedRange
} = {}): Partial<CustomDateRange | PredefinedRange> {
  if (dateRange == null || dateRange.startDate == null || dateRange.endDate == null) {
    return { endDate: undefined, startDate: undefined }
  }

  const now = DateTime.now()
  const startDate = dateRange.startDate.startOf('day')
  const endDate =
    isPredefinedRange(dateRange) && dateRange.id === 'today' ? dateRange.endDate : dateRange.endDate.endOf('day')

  if (startDate.hasSame(endDate, 'day')) {
    const isToday = startDate.hasSame(now, 'day')
    return {
      startDate: startDate.startOf('day'),
      endDate: isToday ? endDate : endDate.endOf('day'),
    }
  }

  return { startDate, endDate }
}
