import { FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import clsx from 'clsx'
import { GenericError } from 'const'
import { Controller, ControllerProps, useFormContext } from 'react-hook-form'

import { getErrorParams } from '../../helpers/data'
import { BaseReactProps } from '../../helpers/types'
import styles from './ControlledSelect.module.scss'

const PLACEHOLDER_VALUE = ''

export interface ControlledSelectItem {
  value: string
  displayValue: string | React.ReactNode
  disabled?: boolean
}

export interface ControlledSelectProps extends BaseReactProps {
  title: string
  label?: string
  placeholder?: string
  name: string
  items: ControlledSelectItem[]
  defaultValue?: any
  rules?: ControllerProps['rules']
  disabled?: boolean
  dense?: boolean
  error?: GenericError | null
  onChange?: (event: SelectChangeEvent<any>) => void
  'data-testid'?: string
}

export default function ControlledSelect({
  title,
  label,
  placeholder,
  name,
  items,
  defaultValue,
  rules,
  disabled,
  dense,
  error,
  className,
  onChange,
  'data-testid': testId,
}: ControlledSelectProps) {
  const {
    control,
    watch,
    formState: { errors: formErrors },
  } = useFormContext()
  const fieldError = getErrorParams(name, formErrors, error)
  const isPlaceholder = watch(name) === PLACEHOLDER_VALUE

  const placeholderOption = {
    value: PLACEHOLDER_VALUE,
    displayValue: placeholder,
    disabled: true,
  }
  const options = placeholder ? [placeholderOption, ...items] : [...items]

  return (
    <div className={className}>
      <InputLabel htmlFor={name} disabled={disabled}>
        {label ?? title}
      </InputLabel>

      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue ?? PLACEHOLDER_VALUE}
        rules={rules}
        render={({ field }) => (
          <Select
            id={name}
            SelectDisplayProps={{
              'aria-label': name,
            }}
            disabled={disabled}
            classes={{ select: clsx({ [styles.selectPlaceholder]: isPlaceholder }) }}
            {...field}
            onChange={(e) => {
              onChange?.(e)
              field.onChange(e)
            }}
            error={fieldError.error}
            fullWidth
            displayEmpty
            data-testid={testId}
          >
            {options.map(({ value, displayValue, disabled: optionDisabled }) => (
              <MenuItem key={value} value={value} disabled={optionDisabled}>
                {displayValue}
              </MenuItem>
            ))}
          </Select>
        )}
      />

      <FormHelperText classes={{ root: clsx({ [styles.denseHelperText]: dense }) }}>
        {fieldError.helperText}
      </FormHelperText>
    </div>
  )
}
