import { TagColor } from 'components/Tag/Tag'
import { User, UserRole } from 'models'

export type ManagedUser = User & {
  isEditable?: boolean
  isDeletable?: boolean
  canReceiveOwnership?: boolean
  isPendingTransfer?: boolean
}

export const USER_ROLE_COLORS: Record<UserRole, TagColor> = {
  [UserRole.Admin]: 'gray',
  [UserRole.Administrator]: 'green',
  [UserRole.Owner]: 'red',
  [UserRole.Member]: 'yellow',
  [UserRole.ReadOnly]: 'blue',
}
