import { LinkButton } from '@compass/components'
import { ShowChartOutlined } from '@mui/icons-material'
import { Box, Breadcrumbs, Link, Paper, Stack, Theme, Typography, useMediaQuery } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { Header, MainColumn } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useDocumentTitle } from 'hooks'
import { SdkHealth, SdkHealthSdkDetails } from 'models/sdkHealth'
import { Link as RouterLink } from 'react-router-dom'

import { SdkHealthFullTable } from '../../components/sdk-health/SdkHealthFullTable'
import { SdkHealthMobileView } from '../../components/sdk-health/SdkHealthMobileView'
import { RowType } from '../../components/sdk-health/SdkHealthRowDetails'
import { useSdkHealthQuery } from '../../hooks/useSdkHealthQuery'
import styles from './SdkHealthPage.module.scss'

export const SdkHealthPage = () => {
  useDocumentTitle('SDK Health Insights')

  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: sdkHealth, isError } = useSdkHealthQuery(currentSubscriptionId)

  return (
    <>
      <Header title='Health' />
      <MainColumn>
        <SdkBreadcrumbs />
        <SdkHealthPageContent isError={isError} sdkHealth={sdkHealth} />
      </MainColumn>
    </>
  )
}

const SdkBreadcrumbs = () => {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  return (
    <Breadcrumbs aria-label='breadcrumb' separator='/' className='mt-2'>
      <Link
        color='inherit'
        component={RouterLink}
        to={buildRoute(AppRoute.Health, { subscriptionId })}
        underline='hover'
      >
        Health
      </Link>
      <Typography component='span'>SDK</Typography>
    </Breadcrumbs>
  )
}

type SdkHealthPlatformInfoParams = {
  label: string
  sdks?: SdkHealthSdkDetails[]
  type: RowType
}
const SdkHealthPlatformInfo = ({ label, sdks, type }: SdkHealthPlatformInfoParams) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  const sdkRows = sdks?.flatMap((sdk) => {
    const { sdkName, latestVersion, latestVersionUrlChangelog, repository, versions } = sdk

    return versions.map((version) => ({
      sdkName,
      latestVersion,
      latestVersionUrlChangelog,
      repository,
      ...version,
    }))
  })

  return (
    <Stack gap={4}>
      <div>
        <Typography variant='h3'>{label}</Typography>
        <Typography variant='bodyM'>Usage from the last 30 days.</Typography>
      </div>
      {isDesktop ? (
        <SdkHealthFullTable type={type} rows={sdkRows} />
      ) : (
        <SdkHealthMobileView type={type} rows={sdkRows} />
      )}
    </Stack>
  )
}

type SdkHealthPageContentParams = {
  sdkHealth?: SdkHealth
  isError: boolean
}

export const SdkHealthPageContent = ({ sdkHealth: sdkHealthData, isError }: SdkHealthPageContentParams) => {
  const hideWebSection = sdkHealthData?.web?.length === 0
  const hideMobileSection = sdkHealthData?.mobile?.length === 0
  const hideServerSection = sdkHealthData?.server?.length === 0
  const showEmptySection = hideWebSection && hideMobileSection && hideServerSection

  return isError ? (
    <p>Unable to fetch SDK health information, please try again later.</p>
  ) : (
    <>
      {hideWebSection ? null : (
        <Paper className={styles.card}>
          <SdkHealthPlatformInfo label='Web' type='web' sdks={sdkHealthData?.web} />
        </Paper>
      )}
      {hideMobileSection ? null : (
        <Paper className={styles.card}>
          <SdkHealthPlatformInfo label='Mobile' type='mobile' sdks={sdkHealthData?.mobile} />
        </Paper>
      )}
      {hideServerSection ? null : (
        <Paper className={styles.card}>
          <SdkHealthPlatformInfo label='Server' type='server' sdks={sdkHealthData?.server} />
        </Paper>
      )}
      {showEmptySection ? (
        <Paper className={styles.card}>
          <EmptyState />
        </Paper>
      ) : null}
    </>
  )
}

const EmptyState = () => (
  <Box className={styles.emptyState}>
    <Box className={styles.emptyStateKeyIcon}>
      <ShowChartOutlined />
    </Box>
    <Box className={styles.emptyStateTextContent}>
      <Typography variant='semiBody1'>No usage data yet</Typography>
      <Typography variant='bodyS'>We need data to generate health logs. Make some API requests!</Typography>
    </Box>
    <LinkButton
      size='sm'
      className={styles.emptyStateButton}
      target='_blank'
      href='https://dev.fingerprint.com/docs/quick-start-guide'
    >
      View documentation
    </LinkButton>
  </Box>
)
