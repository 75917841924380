export function getCookie(name: string) {
  const cookieName = name + '='
  const cookieArray = document.cookie.split(';')

  for (let cookie of cookieArray) {
    cookie = cookie.trim()

    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length)
    }
  }
  return null
}
