import { useQuery, useQueryClient } from '@tanstack/react-query'
import { GenericError } from 'const'
import { SSLCertificate } from 'models'

import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

export function useCertificates(subscriptionId: string) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['subscription/certificates', subscriptionId] as const,
    queryFn: ({ queryKey: [_, subId] }) =>
      extractData(withErrorHandling(buildRequest('certificates', { params: { subscriptionId: subId } }))),
    refetchInterval: 5 * 1000, // automatically refetch every 5 sec
    enabled: subscriptionId != null,
  })
}

export function useCertificate(subscriptionId: string, id: string, withPolling?: boolean, expandSubscription = false) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['subscription/certificates', subscriptionId, id, expandSubscription] as const,
    queryFn: ({ queryKey: [_, subId, certId] }) =>
      extractData(
        withErrorHandling(
          buildRequest('certificateGet', {
            params: {
              id: certId,
              subscriptionId: subId,
            },
            expand: expandSubscription ? ['subscription'] : undefined,
          })
        )
      ),
    enabled: subscriptionId != null && id != null,
    refetchInterval: withPolling ? 10 * 1000 : undefined, // automatically refetch every 10 sec
    onError: (err: GenericError) => err, // type infer helper
  })
}

export function useAddCertificateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('certificateCreate', {
    onSuccess: (data) => {
      // Update the individual certificate query
      queryClient.setQueryData<SSLCertificate>(['subscription/certificates', data.subscriptionId, data.id], data)

      // Update the certificates list query
      queryClient.setQueryData<SSLCertificate[]>(['subscription/certificates', data.subscriptionId], (oldData) => {
        if (oldData) {
          return [...oldData, data]
        }
        return [data]
      })
    },
  })
}

export function useDeleteCertificateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('certificateDelete', {
    onSuccess: (_, variables) => {
      if (variables && 'params' in variables) {
        const { id, subscriptionId } = variables.params as { id: string; subscriptionId: string }

        // Remove the deleted certificate from the certificates list query
        queryClient.setQueryData<SSLCertificate[]>(
          ['subscription/certificates', subscriptionId],
          (oldData) => oldData?.filter((cert) => cert.id !== id) ?? []
        )

        // Remove the individual certificate query data
        queryClient.removeQueries(['subscription/certificates', subscriptionId, id])
      }
    },
  })
}
