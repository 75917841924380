import { Paper, Typography } from '@mui/material'
import clsx from 'clsx'
import { ReactNode } from 'react'

import styles from './IntegrationPageSection.module.scss'

export type IntegrationPageSectionDefinition = {
  title?: string
  isDanger?: boolean
  description?: string
  actions?: ReactNode
  content: ReactNode
  key: string
  info?: string
}

type Props = {
  section: IntegrationPageSectionDefinition
}

export default function IntegrationPageSection({ section }: Props) {
  return (
    <Paper className={styles.container}>
      {(section.title || section.description || section.actions) && (
        <div className={styles.header}>
          <div>
            <Typography variant='h3' className={clsx({ [styles.danger]: section.isDanger })}>
              {section.title}
            </Typography>
            {section.description && (
              <Typography className={styles.description} variant='body2'>
                {section.description}
              </Typography>
            )}
          </div>
          {section.actions && <div className={styles.actions}>{section.actions}</div>}
          {section.info && (
            <Typography variant='bodyM' className={styles.info}>
              {section.info}
            </Typography>
          )}
        </div>
      )}
      {section.content}
    </Paper>
  )
}
