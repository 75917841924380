import { Button } from '@compass/components'
import { Popover } from '@mui/material'
import clsx from 'clsx'
import { FilterIcon } from 'lucide-react'
import { PropsWithChildren, ReactNode, useRef } from 'react'

import styles from './Filter.module.scss'

export interface FilterProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  compact?: boolean
  className?: string
  containerClassName?: string
  triggerContent?: ReactNode
}

export function Filter({
  isOpen,
  onOpen,
  onClose,
  compact,
  className,
  containerClassName,
  triggerContent,
  children,
}: PropsWithChildren<FilterProps>) {
  const anchorRef = useRef(null)

  return (
    <>
      <Button variant='secondary' isIcon={compact} className={className} onPress={() => onOpen()} ref={anchorRef}>
        <FilterIcon />
        {compact ? null : (triggerContent ?? 'Filter')}
      </Button>

      <Popover
        keepMounted
        open={!!anchorRef.current && isOpen}
        anchorEl={anchorRef.current}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: clsx(styles.container, containerClassName) }}
      >
        {children}
      </Popover>
    </>
  )
}
