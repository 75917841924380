import { useQuery } from '@tanstack/react-query'
import { GenericError } from 'const/api'
import { useAuth, useToast } from 'hooks'
import { useHistory } from 'react-router-dom'

import { AppRoute } from '../../appRoutes'
import { useSavedEmail } from '../savedEmail'
import { useBuildRequest, useRequestMutation } from './base'
import { useUpdateUserContext } from './context'

export function useInvitationConfirmMutation() {
  const { setCredentials } = useAuth()
  const updateUserContext = useUpdateUserContext()

  return useRequestMutation('invitationConfirm', {
    onSuccess: ({ accessToken, refreshToken, context }) => {
      setCredentials({ accessToken, refreshToken })
      updateUserContext(context)
    },
  })
}

export function useInvitationValidate(invitationId: string, enabled = true) {
  const buildRequest = useBuildRequest()

  return useQuery({
    queryKey: ['invitationValidate', invitationId] as const,
    // This endpoint doesn't return anything when successful, that needs to be converted to a valid react query value.
    // It throws when the invitation is invalid.
    queryFn: () => buildRequest('invitationValidate', { params: { id: invitationId } }).then(() => ({})),
    enabled: invitationId != null && enabled,
    retry: (count, error: GenericError) => (error?.['code'] === 'value_invalid' ? false : count < 1),
  })
}

export function useDeleteAccount() {
  const { showToast } = useToast()
  const history = useHistory()
  const { removeCredentials } = useAuth()
  const { forgetEmail } = useSavedEmail()

  return useRequestMutation('deleteAccount', {
    onSuccess: async () => {
      forgetEmail()

      showToast({
        severity: 'success',
        message: 'Farewell. Your account was deleted.',
        duration: 5000,
      })
      await removeCredentials(false)
      history.replace(AppRoute.Login)
    },
    onError: () => {
      showToast({
        severity: 'error',
        message: 'An error occurred when deleting your account. Please contact support if this error persists.',
      })
    },
  })
}
