import { tv, type VariantProps } from 'tailwind-variants'

const dotVariants = tv({
  base: [
    'bg-current rounded-full',
    'animate-oscillate motion-reduce:animate-pulse duration-300',
    'transition-none',
    'not-sr-only',
  ],
  variants: {
    size: {
      sm: 'h-0.5 w-0.5',
      md: 'h-1 w-1',
      lg: 'h-4 w-4',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

type DotVariantProps = VariantProps<typeof dotVariants>

interface LoadingDotsProps extends DotVariantProps {
  isLoading?: boolean
  className?: string
}

export function LoadingDots({ className, size = 'md', isLoading = true }: LoadingDotsProps) {
  return (
    <div className={`flex justify-center items-center dark:invert ${className}`}>
      {isLoading ? <span className='sr-only'>Loading...</span> : null}
      <div className={`${dotVariants({ size })} mr-1`} style={{ animationDelay: '-0.5s' }} />
      <div className={`${dotVariants({ size })} mr-1`} style={{ animationDelay: '-0.25s' }} />
      <div className={dotVariants({ size })} />
    </div>
  )
}
