import { Close } from '@mui/icons-material'
import { IconButton, Skeleton, Typography } from '@mui/material'
import clsx from 'clsx'

import { formatNum, formatNumShort } from '../../../../../helpers/format'
import styles from './LabeledCounter.module.scss'

export interface LabeledCounterProps {
  label: string
  value: number
  total: number
  disabled?: boolean
  onToggle?: () => void
  color?: 'black' | 'gray' | 'red' | 'green'
  isLoading?: boolean
  className?: string
}

export default function LabeledCounter({
  label,
  value,
  total,
  disabled,
  onToggle,
  color = 'black',
  isLoading,
  className,
}: LabeledCounterProps) {
  return (
    <span className={clsx(styles.root, className)} data-testid='labeled-counter'>
      <Typography variant='body2'>
        <span className={clsx({ [styles.disabled]: disabled })}>{label}</span>
        {onToggle && (
          <IconButton
            title='Toggle counter'
            onClick={onToggle}
            disabled={!disabled && value === 0}
            className={clsx(styles.toggle, { [styles.disabledButton]: disabled })}
          >
            <Close />
          </IconButton>
        )}
      </Typography>

      {isLoading && <Skeleton height={26} width={128} />}

      {!isLoading && (
        <>
          <Typography className={clsx(styles.value, styles[color], { [styles.disabled]: disabled })}>
            {formatNum(value)}
          </Typography>

          {value > 0 && total > 0 && (
            <Typography className={clsx(styles.percentage, styles[color], { [styles.disabled]: disabled })}>
              {formatNumShort((value * 100) / total, 1)}%
            </Typography>
          )}
        </>
      )}
    </span>
  )
}
