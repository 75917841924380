import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import {
  useBillingInfo,
  useBillingInfoUpdateMutation,
  useConfirmationDialog,
  useDocumentTitle,
  useStripeCardElement,
  useSubscriptions,
  useToast,
} from 'hooks'
import { useInvoices } from 'hooks/api/invoices'
import {
  usePaymentMethodCreateMutation,
  usePaymentMethodDeleteMutation,
  usePaymentMethodMakeDefaultMutation,
  usePaymentMethods,
} from 'hooks/api/payment_methods'
import {
  AddressFormat,
  BillingDetails,
  BillingInfo,
  CardBrand,
  InvoiceListItem,
  PaginationAndSortingOptions,
  SubscriptionStatus,
  SubscriptionType,
} from 'models'
import { ampli } from 'models/ampli'
import { ComponentProps, useCallback, useMemo, useState } from 'react'

import Billing from '../../components/Billing/Billing'

const rowsPerPage = 10

export function BillingPage() {
  useDocumentTitle('Billing')

  const [addPaymentDialogOpen, setAddPaymentDialogOpen] = useState(false)
  const [editBillingInfoDialogOpen, setEditBillingInfoDialogOpen] = useState(false)
  const [invoiceRequestOptions, setInvoiceRequestOptions] = useState<PaginationAndSortingOptions<InvoiceListItem>>({
    limit: rowsPerPage,
    offset: 0,
  })

  const { data: paymentMethods = [], isLoading: paymentMethodsLoading } = usePaymentMethods()
  const { data: invoiceList = [], isLoading: invoicesLoading, meta: invoiceMeta } = useInvoices(invoiceRequestOptions)
  const { openDialog } = useConfirmationDialog()
  const { showToast } = useToast()

  const { mutate: makeDefaultRequest } = usePaymentMethodMakeDefaultMutation()
  const { mutate: deletePaymentMethodRequest } = usePaymentMethodDeleteMutation()
  const {
    mutate: createPaymentMethodRequest,
    error: stripeCreationError,
    isLoading: isAddingPaymentMethod,
  } = usePaymentMethodCreateMutation()
  const {
    mutate: createStripePaymentMethod,
    error: creationError,
    isLoading: isAddingStripePaymentMethod,
  } = useStripeCardElement()
  const { data: visitorData } = useVisitorData({ extendedResult: true })
  const { data: billingInfo, isLoading: isLoadingBillingInfo } = useBillingInfo()
  const {
    mutate: updateBillingInfo,
    error: updateBillingInfoError,
    isLoading: isUpdatingBillingInfo,
  } = useBillingInfoUpdateMutation()

  const { data: subscriptions } = useSubscriptions()

  const transformedInvoices = useMemo(() => {
    return invoiceList.map((inv) => {
      const sub = subscriptions?.find((s) => s.id === inv.subscriptionId)
      // when subscriptions are soft-deleted, the invoice name can't be loaded, so it falls back to an empty string.
      if (inv.subscriptionName === '' && !sub) {
        inv.subscriptionName = '[deleted]'
      }
      return inv
    })
  }, [invoiceList, subscriptions])

  const handleAddNewPaymentClick = useCallback(() => {
    setAddPaymentDialogOpen(true)
  }, [])

  const handleDialogCancel = useCallback(() => {
    setAddPaymentDialogOpen(false)
  }, [])

  const handleEditBillingInfoClick = useCallback(() => {
    setEditBillingInfoDialogOpen(true)
  }, [])

  const handleEditBillingInfoDialogCancel = useCallback(() => {
    setEditBillingInfoDialogOpen(false)
  }, [])

  const handleMakeDefault = useCallback<ComponentProps<typeof Billing>['onMakePaymentMethodDefault']>(
    (id) => {
      makeDefaultRequest(
        { params: { id } },
        {
          onSuccess: () => {
            const hasOutstandingPayments = subscriptions?.some((s) =>
              [SubscriptionStatus.PastDue, SubscriptionStatus.Unpaid].includes(s.status)
            )
            const newDefaultCardLast4 = paymentMethods.find((pm) => pm.id === id)?.cardLast4

            const message = hasOutstandingPayments
              ? `Payment method added. We'll retry payment in a moment using the card ending in  ****${newDefaultCardLast4}.`
              : `Done! Your default payment method is now the card ending in  ****${newDefaultCardLast4}.`

            showToast({
              severity: 'success',
              message,
            })
          },
          onError: () => {
            showToast({
              severity: 'error',
              message: 'An error occurred when trying to update your default payment method. Please try again.',
            })
          },
        }
      )
    },
    [makeDefaultRequest, showToast, subscriptions, paymentMethods]
  )

  const handleDeletePaymentMethod = (id: string) => {
    openDialog({
      label: 'Remove payment method?',
      onConfirm: () => {
        deletePaymentMethodRequest({ params: { id } })
      },
    })
  }

  const handleAddPaymentMethod = useCallback(
    (billingDetails: BillingDetails) => {
      createStripePaymentMethod(billingDetails, {
        onSuccess: (stripePaymentMethod) => {
          if (stripePaymentMethod && stripePaymentMethod?.card && billingDetails.name) {
            const { id, card } = stripePaymentMethod

            const createPayload = {
              stripeId: id,
              cardBrand: card.brand as CardBrand,
              cardLast4: card.last4,
              cardExpMonth: card.exp_month,
              cardExpYear: card.exp_year,
              cardCountry: card.country || undefined,
              cardFunding: card.funding,
              cardholderName: billingDetails.name,
            }

            createPaymentMethodRequest(
              { data: createPayload },
              {
                onSuccess: () => {
                  ampli.addPaymentMethod({ source: 'billing page' })
                  setAddPaymentDialogOpen(false)
                },
              }
            )
          }
        },
      })
    },
    [createStripePaymentMethod, createPaymentMethodRequest]
  )

  const handleUpdateBillingInfo = useCallback(
    (updatedBillingInfo: BillingInfo) => {
      const normalizedBillingInfo = { ...updatedBillingInfo }
      if (!normalizedBillingInfo.taxIdType) {
        delete normalizedBillingInfo.taxIdType
        delete normalizedBillingInfo.taxId
      }

      updateBillingInfo(
        { data: normalizedBillingInfo },
        {
          onSuccess: () => {
            setEditBillingInfoDialogOpen(false)
          },
        }
      )
    },
    [updateBillingInfo, setEditBillingInfoDialogOpen]
  )

  const hasPaidSubscriptions = subscriptions?.some(({ type }) => type !== SubscriptionType.Free)

  const trialSubscription = subscriptions?.find(({ type }) => type === SubscriptionType.TrialOnly)
  //if for some reason the user has a trial subscription but also another type of subscription that is not cancelled, we don't want to show the trial banner
  const hasActiveNonTrialSubscriptions = subscriptions?.some(
    ({ status, type }) => status !== SubscriptionStatus.Canceled && type !== SubscriptionType.TrialOnly
  )

  let addressFormat: AddressFormat = 'other'
  const countryCode = visitorData?.ipLocation?.country?.code
  if (countryCode) {
    switch (countryCode) {
      case 'US':
        addressFormat = 'us'
        break
      case 'CA':
        addressFormat = 'ca'
        break
    }
  }

  return (
    <Billing
      isPaymentMethodsLoading={paymentMethodsLoading}
      isAddPaymentMethodDialogOpen={addPaymentDialogOpen}
      isEditBillingInfoDialogOpen={editBillingInfoDialogOpen}
      isAddingPaymentMethod={isAddingPaymentMethod || isAddingStripePaymentMethod}
      paymentMethods={paymentMethods}
      paymentMethodsError={stripeCreationError || creationError}
      invoices={transformedInvoices}
      isLoadingInvoices={invoicesLoading}
      orderInvoices={invoiceRequestOptions.order}
      sortInvoicesBy={invoiceRequestOptions.sortBy}
      invoicesPage={Math.floor((invoiceRequestOptions.offset ?? 0) / rowsPerPage) + 1}
      invoicesRowsPerPage={rowsPerPage}
      totalInvoicesCount={invoiceMeta?.count ?? rowsPerPage}
      onAddNewPaymentClick={handleAddNewPaymentClick}
      onPaymentMethodDialogCancel={handleDialogCancel}
      onEditBillingInfoClick={handleEditBillingInfoClick}
      onEditBillingInfoDialogCancel={handleEditBillingInfoDialogCancel}
      onMakePaymentMethodDefault={handleMakeDefault}
      onAddPaymentMethod={handleAddPaymentMethod}
      onDeletePaymentMethod={handleDeletePaymentMethod}
      onInvoicesSort={(sortBy, order) => {
        setInvoiceRequestOptions({ ...invoiceRequestOptions, sortBy: sortBy, order: order })
      }}
      onInvoicesPageChange={(page) => {
        const offset = page * rowsPerPage
        const limit = rowsPerPage
        setInvoiceRequestOptions({ ...invoiceRequestOptions, offset, limit })
      }}
      addressFormat={addressFormat}
      billingInfo={billingInfo}
      onUpdateBillingInfo={handleUpdateBillingInfo}
      updateBillingInfoError={updateBillingInfoError}
      isLoadingBillingInfo={isLoadingBillingInfo || isUpdatingBillingInfo}
      subscriptionBillingDetails={subscriptions}
      hasPaidSubscriptions={hasPaidSubscriptions}
      trialSubscription={trialSubscription}
      hasActiveNonTrialSubscriptions={hasActiveNonTrialSubscriptions}
    />
  )
}
