import { Checkbox, FormControl, FormControlLabel, FormHelperText, Link, Typography } from '@mui/material'
import clsx from 'clsx'
import { PRIVACY_URL, TERMS_URL } from 'const'
import { useFormContext } from 'react-hook-form'

import { muiRegister } from '../../helpers/reactHookForm'
import styles from './UserConsent.module.scss'

export interface UserConsentProps {
  className?: string
}

export default function UserConsent({ className }: UserConsentProps) {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext()

  return (
    <FormControl
      error={errors.privacyPolicy !== undefined || errors.termsOfService !== undefined}
      className={clsx(styles.root, className)}
    >
      <FormControlLabel
        control={
          <Checkbox
            color='primary'
            id='privacyPolicy'
            {...muiRegister(register, 'privacyPolicy', { required: true })}
            classes={{ root: styles.checkbox }}
          />
        }
        label={
          <Typography variant='body2'>
            I have read and accept the{' '}
            <Link href={PRIVACY_URL} target='_blank' underline='hover'>
              Privacy Policy
            </Link>
          </Typography>
        }
        className={styles.control}
      />

      <FormControlLabel
        control={
          <Checkbox
            color='primary'
            id='termsOfService'
            {...muiRegister(register, 'termsOfService', { required: true })}
            classes={{ root: styles.checkbox }}
          />
        }
        label={
          <Typography variant='body2'>
            I have read and accept the{' '}
            <Link href={TERMS_URL} target='_blank' underline='hover'>
              Terms of Service
            </Link>
          </Typography>
        }
        className={styles.control}
      />
      <FormHelperText hidden={getValues('privacyPolicy') && getValues('termsOfService')}>
        You must accept our Privacy Policy and Terms of Service
      </FormHelperText>
    </FormControl>
  )
}
