import {
  composeRenderProps,
  FieldError as RACFieldError,
  FieldErrorProps,
  Group,
  GroupProps,
  Input as RACInput,
  InputProps,
  Label as RACLabel,
  LabelProps,
  Text,
  TextProps,
} from 'react-aria-components'
import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'

import { composeTailwindRenderProps, focusRing } from './utils'

export function Label(props: LabelProps) {
  return (
    <RACLabel
      {...props}
      className={twMerge(
        'text-base text-gray-1000 dark:text-gray-400 font-medium cursor-default w-fit',
        props.className
      )}
    />
  )
}

export function Description(props: TextProps) {
  return <Text {...props} slot='description' className={twMerge('text-base text-gray-800', props.className)} />
}

export function FieldError(props: FieldErrorProps) {
  return (
    <RACFieldError
      {...props}
      className={composeTailwindRenderProps(props.className, 'text-base text-red-800 forced-colors:text-red-800')}
    />
  )
}

export const fieldStyles = tv({
  base: 'px-2 flex-1 h-8 w-full min-w-0 outline outline-0 bg-white dark:bg-gray-900 text-base text-gray-1000 placeholder:text-gray-800 dark:text-gray-200 disabled:text-gray-600 dark:disabled:text-gray-600',
  variants: {
    size: {
      default: 'h-8',
      lg: 'h-10',
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

export const fieldBorderStyles = tv({
  variants: {
    isFocusWithin: {
      false: 'border-gray-400 dark:border-gray-500 forced-colors:border-primary',
      true: 'border-gray-600 dark:border-gray-300 forced-colors:border-primary',
    },
    isInvalid: {
      true: 'invalid:bg-red-100 invalid:border-red-800 invalid:dark:border-red-600 invalid:forced-colors:border-red-400',
    },
    isDisabled: {
      true: 'bg-gray-200 shadow-none disabled:border-gray-400 dark:border-gray-700 forced-colors:border-gray-400 cursor-not-allowed',
    },
  },
})

export const fieldGroupStyles = tv({
  extend: focusRing,
  base: 'group flex items-center h-9 bg-white dark:bg-gray-900 forced-colors:bg-white border-2 rounded-lg overflow-hidden',
  variants: fieldBorderStyles.variants,
})

export function FieldGroup(props: GroupProps) {
  return (
    <Group
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        fieldGroupStyles({ ...renderProps, className })
      )}
    />
  )
}

export function Input({ size, ...props }: InputProps & { size?: 'default' | 'large' }) {
  return <RACInput {...props} className={composeTailwindRenderProps(props.className, fieldStyles({ size }))} />
}
