import { Button, LinkButton } from '@compass/components'
import { CheckCircleOutlineOutlined, ChevronRight, ComputerOutlined, PhoneIphoneOutlined } from '@mui/icons-material'
import {
  Box,
  Grid,
  Link,
  Paper,
  Skeleton,
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepConnectorProps,
  StepContent,
  StepLabel,
  Stepper,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import { useCertificates, useSubscription, useToast } from 'hooks'
import { useIntegrationStatusGetStarted } from 'hooks/api/integration_status'
import { useSubscriptionTokens, useTokenCreateMutation } from 'hooks/api/tokens'
import { SquareArrowOutUpRightIcon } from 'lucide-react'
import { AnalyticsContext } from 'models'
import { ampli } from 'models/ampli'
import { Suspense, useCallback, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { ExperimentFlag, ExperimentValue, useLocalAmplitudeExperimentVariant } from '../../../../helpers/vendor'
import { IntegrationStep } from '../../../../models/integrationStatus'
import { apiKeyDisplayNameMap } from '../../../apiKeys/components/KeySection/KeySection'
import { createMDXBaseComponents } from '../../../integrations/components/IntegrationMetadata/MDX/MDXBaseComponents'
import { useCurrentSubscription } from '../../../subscription/hooks/currentSubscription'
import {
  IntegrationCategory,
  integrationList,
  IntegrationMetadata,
} from './../../../integrations/components/IntegrationMetadata/integrationMetadata'
import { IntegrationReadmeContext } from './../../../integrations/components/IntegrationMetadata/MDX/IntegrationReadmeContext'
import { ApiKeyWidgetData } from './../../../integrations/components/IntegrationMetadata/MDX/MDXApiKeyWidget/MDXApiKeyWidget'
import { getIntegrationReadmeProps } from './../../../integrations/components/IntegrationMetadata/utils'
import { useIntegrationData } from './../../../integrations/integrationHooks'
import styles from './Integrations.module.scss'

const ANALYTICS_CONTEXT = AnalyticsContext.GetStarted

const CustomStepConnector = styled(StepConnector)<StepConnectorProps>(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#E0E0E0',
  },
}))

function IntegrationComponentMDX({
  integration,
  currentSubscriptionId,
}: {
  integration: IntegrationMetadata
  currentSubscriptionId: string
}) {
  if (!integration.mdx) {
    throw new Error('Unsupported format')
  }

  const { data: subscription, isLoading: isSubscriptionLoading } = useSubscription(currentSubscriptionId)
  const { data: subscriptionTokens, isLoading: isTokenLoading } = useSubscriptionTokens(currentSubscriptionId)
  const { data: subscriptionCertificates, isLoading: isCertificateLoading } = useCertificates(currentSubscriptionId)
  const { integrationData, isLoading: isIntegrationDataLoading } = useIntegrationData(currentSubscriptionId)
  const { mutate: sendCreateApiKeyRequest, isLoading: isCreatingApiKey, data: secretApiKey } = useTokenCreateMutation()
  const { showToast } = useToast()

  const onGenerate = useCallback(
    (data: ApiKeyWidgetData) => {
      sendCreateApiKeyRequest(
        {
          data,
          params: { subscriptionId: currentSubscriptionId },
        },
        {
          onSuccess: (apiKeyData) => {
            ampli.apiKeyAdded({ apiKeyType: apiKeyDisplayNameMap[apiKeyData.type], environmentChanged: false })
          },
          onError: (e) => {
            showToast({ message: e.message, severity: 'error' })
          },
        }
      )
    },
    [sendCreateApiKeyRequest, currentSubscriptionId, showToast]
  )

  const isLoading = isSubscriptionLoading || isTokenLoading || isCertificateLoading || isIntegrationDataLoading

  const mdxProps = getIntegrationReadmeProps(
    integration?.integrationTag,
    subscription,
    subscriptionTokens,
    subscriptionCertificates,
    integrationData,
    secretApiKey
  )
  const keyWidgetProps = { onGenerate, apiKey: secretApiKey, isLoading: isCreatingApiKey }

  const loader = useMemo(
    () => (
      <Box>
        <Skeleton height={40} width={300} />
        <Skeleton />
        <Skeleton />
        <Skeleton width={100} />
      </Box>
    ),
    []
  )
  const MDXComponentsWithContext = createMDXBaseComponents(ANALYTICS_CONTEXT)

  return (
    <div>
      <Stack spacing={3} paddingTop='4px'>
        <Stack direction='row' flexWrap='wrap' alignItems='center' gap={2}>
          {integration.docsLink && (
            <LinkButton target='_blank' href={integration.docsLink} variant='outline'>
              Open docs
              <SquareArrowOutUpRightIcon />
            </LinkButton>
          )}
        </Stack>

        {isLoading ? (
          loader
        ) : (
          <IntegrationReadmeContext.Provider value={{ ...mdxProps, ...keyWidgetProps }}>
            <Suspense fallback={loader}>
              <integration.mdx {...mdxProps} components={MDXComponentsWithContext} />
            </Suspense>
          </IntegrationReadmeContext.Provider>
        )}
      </Stack>
    </div>
  )
}

const CheckInstallationButton = ({ onClick, loading }: { onClick: () => void; loading: boolean }) => (
  <Button onPress={onClick} isLoading={loading} className={styles.checkInstallationButton}>
    Check installation
  </Button>
)

export function Integrations() {
  const { variant: useCaseVariant } = useLocalAmplitudeExperimentVariant(ExperimentFlag.DirectToInstallV3)
  const isDirectToInstallCaptureExperiment = useCaseVariant?.value === ExperimentValue.Treatment

  const integrationsByCategory: Partial<Record<IntegrationCategory, IntegrationMetadata[]>> = useMemo(() => {
    return {
      [IntegrationCategory.WebLibraries]: integrationList.filter(
        (integration) => integration.category === IntegrationCategory.WebLibraries
      ),
      [IntegrationCategory.MobileLibraries]: integrationList.filter(
        (integration) => integration.category === IntegrationCategory.MobileLibraries
      ),
    }
  }, [])
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState<IntegrationCategory>(IntegrationCategory.WebLibraries)

  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationMetadata | undefined>(
    integrationsByCategory[selectedCategory]?.[0]
  )

  const { currentSubscriptionId } = useCurrentSubscription()

  const {
    data: integrationStatus,
    refetch: refetchIntegrationStatus,
    isFetching: isIntegrationStatusFetching,
  } = useIntegrationStatusGetStarted(currentSubscriptionId, false)

  const handleCardClick = (integration: IntegrationMetadata) => {
    setSelectedIntegration(integration)
    ampli.selectSnippetType({
      context: ANALYTICS_CONTEXT,
      snippetType: integration.integrationTag,
    })
  }

  const handlePlatformChange = (newValue: string) => {
    const currentCategory = newValue === 'Web' ? IntegrationCategory.WebLibraries : IntegrationCategory.MobileLibraries
    setSelectedCategory(currentCategory)
    setSelectedIntegration(integrationsByCategory[currentCategory]?.[0])
  }

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTabIndex(newValue)
    const currentCategory = newValue === 0 ? IntegrationCategory.WebLibraries : IntegrationCategory.MobileLibraries
    setSelectedCategory(currentCategory)
    setSelectedIntegration(integrationsByCategory[currentCategory]?.[0])
  }

  const handleCheckInstallation = () => {
    refetchIntegrationStatus().then((status) => {
      const checkResult = status.data?.currentStep === IntegrationStep.ApiCalls ? 'Failure' : 'Success'
      ampli.fingerprintInstallationChecked({ checkResult, 'App Page Path': AppRoute.SubscriptionGetStarted })
    })
  }

  const renderIntegrations = (integrations: IntegrationMetadata[] | undefined) => (
    <Grid container spacing={1} columns={7} sx={{ marginTop: 2 }}>
      {integrations?.map((integration) => (
        <Grid item xs={3.5} lg={1} key={integration.title}>
          <button
            className={clsx(styles.integrationButton, {
              [styles.selected]: selectedIntegration?.integrationTag === integration.integrationTag,
            })}
            onClick={() => handleCardClick(integration)}
          >
            <integration.iconComponent className={styles.icon} />
            <Typography variant='bodyM' className={styles.integrationTitle}>
              {integration.title}
            </Typography>
          </button>
        </Grid>
      ))}
    </Grid>
  )

  const renderPlatforms = () => (
    <Grid container spacing={1} columns={7} sx={{ marginTop: 2 }}>
      <Grid item xs={3.5} lg={1} key={'Web'}>
        <button
          className={clsx(styles.integrationButton, {
            [styles.selected]: selectedCategory === IntegrationCategory.WebLibraries,
          })}
          onClick={() => handlePlatformChange('Web')}
        >
          <ComputerOutlined
            className={clsx(styles.icon, {
              [styles.selected]: selectedCategory === IntegrationCategory.WebLibraries,
            })}
          />
          <Typography variant='bodyM' className={styles.integrationTitle}>
            Web
          </Typography>
        </button>
      </Grid>
      <Grid item xs={3.5} lg={1} key={'Mobile'}>
        <button
          className={clsx(styles.integrationButton, {
            [styles.selected]: selectedCategory === IntegrationCategory.MobileLibraries,
          })}
          onClick={() => handlePlatformChange('Mobile')}
        >
          <PhoneIphoneOutlined
            className={clsx(styles.icon, {
              [styles.selected]: selectedCategory === IntegrationCategory.MobileLibraries,
            })}
          />
          <Typography variant='bodyM' className={styles.integrationTitle}>
            Mobile
          </Typography>
        </button>
      </Grid>
    </Grid>
  )

  const steps = [
    ...(isDirectToInstallCaptureExperiment
      ? [
          {
            label: 'Choose a platform',
            content: (
              <>
                <Typography variant='bodyM'>To start, pick a preferred platform. Either can be used later.</Typography>
                {renderPlatforms()}
              </>
            ),
          },
        ]
      : []),
    {
      label: 'Choose your framework',
      content: (
        <>
          <Typography variant='bodyM'>You can always install on another platform later.</Typography>
          {!isDirectToInstallCaptureExperiment && (
            <Tabs classes={{ flexContainer: styles.tabs }} value={activeTabIndex} onChange={handleTabChange}>
              <Tab classes={{ root: styles.tab, selected: styles.selected }} label='Web' />
              <Tab classes={{ root: styles.tab, selected: styles.selected }} label='Mobile' />
            </Tabs>
          )}
          {renderIntegrations(integrationsByCategory[selectedCategory])}
        </>
      ),
    },
    {
      label: `Integrate with ${selectedIntegration?.title}`,
      content: selectedIntegration && (
        <IntegrationComponentMDX integration={selectedIntegration} currentSubscriptionId={currentSubscriptionId} />
      ),
    },
    {
      label: 'Send request',
      content: (
        <Typography variant='bodyM'>
          Open the page where you installed Fingerprint and wait a second, the script automatically runs on page load.
        </Typography>
      ),
    },
    {
      label: 'Verify installation',
      content: !integrationStatus ? (
        <Stack gap={1}>
          <Typography variant='bodyM'>Let&apos;s make sure the script is working as expected.</Typography>
          <div>
            <CheckInstallationButton onClick={handleCheckInstallation} loading={isIntegrationStatusFetching} />
          </div>
        </Stack>
      ) : integrationStatus?.currentStep === IntegrationStep.ApiCalls ? (
        <Stack gap={1}>
          <Typography variant='bodyM'>
            No API call has been made. Please make sure you opened the page where you installed Fingerprint.
          </Typography>
          <div>
            <CheckInstallationButton onClick={handleCheckInstallation} loading={isIntegrationStatusFetching} />
          </div>
        </Stack>
      ) : (
        <>
          <Typography variant='bodyM' className={styles.successMessage}>
            <CheckCircleOutlineOutlined color='success' className={styles.successIcon} />
            Success! Your script is working. Go to events to view live data.
          </Typography>
          <Link
            component={RouterLink}
            to={buildRoute(AppRoute.IdentificationEvents, { subscriptionId: currentSubscriptionId })}
            className={styles.eventsLink}
          >
            Go to events
            <ChevronRight className={styles.linkIcon} />
          </Link>
        </>
      ),
    },
  ]

  return (
    <Paper className={styles.container}>
      <Stepper orientation='vertical' activeStep={-1} connector={<CustomStepConnector />}>
        {steps.map((step, index) => (
          <Step key={index} active expanded>
            <StepLabel
              classes={{ labelContainer: styles.labelContainer, iconContainer: styles.stepIcon, label: styles.label }}
            >
              {step.label}
            </StepLabel>
            <StepContent classes={{ root: styles.content }}>{step.content}</StepContent>
          </Step>
        ))}
      </Stepper>
    </Paper>
  )
}
