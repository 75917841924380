import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material'
import { AppRoute } from 'appRoutes'
import clsx from 'clsx'
import { GenericError } from 'const'
import { getErrorParams } from 'helpers/data'
import { useState } from 'react'
import { RegisterOptions, useFormContext } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'

import { muiRegister } from '../../helpers/reactHookForm'
import { PASSWORD_VALIDATION } from '../../helpers/validation'
import styles from './PasswordInput.module.scss'

export interface PasswordInputProps {
  name?: string
  label?: string
  placeholder?: string
  autoFocus?: boolean
  canReset?: boolean
  /** Used to prefill the password reset form. */
  email?: string
  isLoading?: boolean
  error?: GenericError | null
  showErrorState?: boolean
  showHelperText?: boolean
  registerOptions?: RegisterOptions
  validate?: (password: string) => string | boolean
  inputProps?: OutlinedInputProps & { 'data-testid'?: string }
  className?: string
  fieldClass?: string
}

export default function PasswordInput(props: PasswordInputProps) {
  const { showHelperText = true, className } = props

  return (
    <div className={clsx(styles.root, className)}>
      <PasswordInputBase {...props} showHelperText={showHelperText} />
    </div>
  )
}

export function PasswordInputBase({
  name = 'password',
  label = 'Password',
  placeholder = 'Password',
  autoFocus,
  canReset,
  email,
  isLoading,
  error,
  showErrorState = true,
  showHelperText,
  registerOptions,
  validate,
  inputProps,
  fieldClass,
}: PasswordInputProps) {
  const [showPassword, setShowPassword] = useState(false)
  const {
    register,
    formState: { errors: formErrors },
  } = useFormContext()

  function handleClickShowPassword() {
    setShowPassword(!showPassword)
  }

  const errorParams = getErrorParams(name, formErrors, error)

  return (
    <>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        id={name}
        placeholder={placeholder}
        title='Password'
        {...muiRegister(register, name, {
          ...PASSWORD_VALIDATION,
          validate: validate ? (data) => validate(data) : undefined,
          ...registerOptions,
        })}
        inputProps={{ 'aria-label': name }}
        autoFocus={!isLoading && autoFocus}
        error={showErrorState && errorParams.error}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position='end'>
            {isLoading ? (
              <CircularProgress className={styles.progress} />
            ) : (
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={(e) => e.preventDefault()}
                edge='end'
                size='small'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            )}
          </InputAdornment>
        }
        fullWidth
        className={fieldClass}
        {...inputProps}
      />

      {(canReset || showHelperText) && (
        <FormHelperText error={errorParams.error}>
          {errorParams.helperText}{' '}
          {canReset && (
            <Link
              component={RouterLink}
              to={{ pathname: AppRoute.PasswordReset, state: { email } }}
              underline='hover'
              className={clsx(styles.reset, { [styles.resetError]: errorParams.error })}
            >
              Forgot password?
            </Link>
          )}
        </FormHelperText>
      )}
    </>
  )
}
