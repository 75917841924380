import { Price } from 'models'

export type Invoice = {
  id: string
  periodStart: Date
  periodEnd: Date
  amountRemaining: number
  amountPaid: number
  amountSubTotal: number
  amountTotal: number
  invoicePdf: string
  status: InvoiceStatus
  subscriptionId: string
  subscriptionName: string
  email: string
  isUpcoming: boolean
  discount: number
  lines: InvoiceLine[]
}

export enum InvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void',
}

export type InvoiceLine = {
  id: string
  amount: number
  currency: string
  description: string
  descriptionShort: string
  quantity: number
  periodStart: Date
  periodEnd: Date
  tierLabel?: string
  price: Price | null
}

export type InvoiceListItem = Pick<
  Invoice,
  'id' | 'periodStart' | 'periodEnd' | 'amountTotal' | 'subscriptionId' | 'subscriptionName' | 'status'
>
