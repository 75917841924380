import { useQuery, useQueryClient } from '@tanstack/react-query'
import { LIMIT_DEFAULTS, redirect_to } from 'const'
import { isOnboardingInProgress } from 'features/onboarding'
import { Limits, UserContext } from 'models'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth } from '../user'
import { extractData, useBuildRequest, useGenericErrorHandler } from './base'

export const QUERY_KEY = ['userContext'] as const

export function useUserContext(withPolling?: boolean, refetchOnFocus?: boolean) {
  const buildRequest = useBuildRequest()
  const history = useHistory()
  const { withErrorHandling } = useGenericErrorHandler()
  const { isAuthorized, isImpersonating } = useAuth()

  return useQuery({
    queryKey: QUERY_KEY,
    queryFn: () => extractData(withErrorHandling(buildRequest('userContextGet'))),
    enabled: isAuthorized,
    cacheTime: refetchOnFocus ? undefined : 60 * 60 * 1000,
    staleTime: refetchOnFocus ? undefined : 60 * 60 * 1000,
    refetchInterval: withPolling ? 60 * 1000 : undefined,
    onSuccess: (context) => {
      if (!isOnboardingInProgress(context.onboardingStep) && !isImpersonating && context.hasConsent === false) {
        let route = '/consent'

        if (history.location.pathname !== '/') {
          route += `?${redirect_to}=${encodeURIComponent(history.location.pathname)}`
        }

        history.replace(route)
      }
    },
  })
}

export function useEntityLimits(entityId: string) {
  const { data: context } = useUserContext()
  return context?.limits.filter((limit) => limit.entityId === entityId) ?? []
}

export function useEntityLimit(entityId: string, limitId: Limits) {
  return useEntityLimits(entityId).find((limit) => limit.id === limitId)?.value ?? LIMIT_DEFAULTS[limitId]
}

export function useUpdateUserContext() {
  const queryClient = useQueryClient()
  return useCallback(
    (context: Partial<UserContext>) => {
      const prevData = queryClient.getQueryData<UserContext>(QUERY_KEY)
      queryClient.setQueryData<UserContext>(QUERY_KEY, { ...(prevData as UserContext), ...context })
    },
    [queryClient]
  )
}
