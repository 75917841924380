import { Button, LinkButton } from '@compass/components'
import { Link } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { useLocation } from 'react-router-dom'

import { SUPPORT_PAGE_URL } from '../../const'
import { useAuth } from '../../hooks'
import styles from './OnboardingNavOptions.module.scss'

export default function OnboardingNavOptions({
  srcPage,
  onContactSupport,
}: {
  srcPage: string
  onContactSupport: () => void
}) {
  const { removeCredentials } = useAuth()
  const { search } = useLocation()

  let button
  switch (srcPage) {
    case 'login':
      button = (
        <LinkButton
          variant='outline'
          href={`${buildRoute(AppRoute.Signup)}?${search ?? ''}`}
          data-testid='layoutSignupButton'
        >
          Sign up free
        </LinkButton>
      )
      break
    case 'onboarding':
    case 'signupConfirm':
      button = (
        <Button variant='outline' onPress={() => removeCredentials()} data-testid='layoutLogoutButton'>
          Log out
        </Button>
      )
      break
    default:
      button = (
        <LinkButton variant='outline' href={`${AppRoute.Login}?${search ?? ''}`} data-testid='layoutLoginButton'>
          Log in
        </LinkButton>
      )
  }

  return (
    <div className={styles.container}>
      <div className={styles.navSupport}>
        <div className={styles.support}>
          Need help?{' '}
          <Link
            href={SUPPORT_PAGE_URL}
            underline='hover'
            target='_blank'
            onClick={onContactSupport}
            style={{ marginRight: '24px' }}
            data-testid='layoutContactSupportLink'
          >
            Contact support
          </Link>
        </div>
        {button}
      </div>
    </div>
  )
}
