import * as amplitude from '@amplitude/analytics-browser'
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { AppRoute } from 'appRoutes'
import { generateRandomString } from 'helpers/common'
import { useSmartSignals } from 'hooks/smartSignals'
import { ampli, DefaultConfiguration, Event } from 'models/ampli'
import { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'
import { matchPath, useHistory } from 'react-router-dom'

import {
  AMPLITUDE_API_ENDPOINT_V2,
  AMPLITUDE_API_KEY,
  IS_ON_VERCEL,
  IS_TEST_MODE,
  RANDOM_AMPLI,
  USE_AMPLITUDE_PROXY,
  VERSION,
} from '../../const'
import { useAuth } from '../../hooks'
import { useIsFirstRender } from '../../hooks/firstRender'
import { AmplitudeExperimentsProvider } from './amplitudeExperiments'

interface AmplitudeContextData {
  isClientLoaded: boolean
}

const AmplitudeContext = createContext<AmplitudeContextData>({ isClientLoaded: false })

export const useAmplitude = () => useContext(AmplitudeContext)

export function useLogPageView(onPageLoad: (pathname: string) => void) {
  const fired = useRef(false)
  const history = useHistory()
  const { isClientLoaded } = useAmplitude()
  const isFirstRender = useIsFirstRender()

  useEffect(() => {
    if (!fired.current && !isFirstRender && isClientLoaded) {
      onPageLoad(history.location.pathname)
      fired.current = true
    }
  }, [onPageLoad, history.location.pathname, fired, isFirstRender, isClientLoaded])
}

let host = 'dashboard.fingerprint.com'

// To avoid CORS error on staging
if (process.env.REACT_APP_VERCEL_ENV === 'preview') {
  if (process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === 'master') {
    host = 'dashboard.fpjs.sh'
  } else {
    host = window.location.hostname ?? 'dashboard.fpjs.sh'
  }
}

const randomPath = generateRandomString(parseInt(RANDOM_AMPLI, 10))
const shouldRedirect = IS_ON_VERCEL && USE_AMPLITUDE_PROXY
export const endpoint = shouldRedirect ? `${host}/${randomPath}?a` : AMPLITUDE_API_ENDPOINT_V2 && undefined

const EVENT_TYPE = 'App Page Viewed'

/**
 * This is a plugin that renames the Page view event_properties according to our analytics needs
 * @returns EnrichmentPlugin
 */
export const renameEventPropertiesEnrichment: amplitude.Types.EnrichmentPlugin = {
  name: 'rename-event-properties-enrichment',
  type: 'enrichment' as const,
  async setup() {},
  async execute(event: Event) {
    // Only apply to App Page View events
    if (event.event_type !== EVENT_TYPE) {
      return event
    }

    // Rename event properties
    // Remove path properties from route
    const originalEventProperties = event.event_properties
    const path = originalEventProperties?.['[Amplitude] Page Path']
    const anonimizedPath = removeParametersFromRoute(path)

    originalEventProperties &&
      (event.event_properties = {
        'App Page Domain': originalEventProperties['[Amplitude] Page Domain'],
        'App Page Location': originalEventProperties['[Amplitude] Page Location'],
        'App Page Path': anonimizedPath,
        'App Page Title': originalEventProperties['[Amplitude] Page Title'],
        'App Page URL': originalEventProperties['[Amplitude] Page URL'],
      })

    return event
  },
}

const routes = Object.values(AppRoute)
export function removeParametersFromRoute(path?: string): string | undefined {
  if (path == null) {
    return path
  }

  const route = routes.find((r) => matchPath(path, { path: r, exact: true }))

  if (route == null) {
    return path
  }

  const match = matchPath(path, { path: route, exact: true })
  if (match) {
    const cleaned = route.replaceAll(/(\/:[^/]*?Id)/gi, '')
    return Object.entries(match.params).reduce(
      (fillBack, [key, value]) => fillBack.replace(`/:${key}`, `/${value}`),
      cleaned
    )
  }

  return path
}

export function AmplitudeProvider({ children }: PropsWithChildren<{}>) {
  const { isImpersonating } = useAuth()
  const [isClientLoaded, setIsClientLoaded] = useState(false)
  const { data: visitorData } = useVisitorData(undefined, { immediate: true })
  const visitorId = visitorData?.visitorId
  const { data: smartSignals } = useSmartSignals(visitorData?.requestId)

  useEffect(() => {
    // Avoid loading Amplitude without visitorId as deviceId, since it may cause
    // event stream duplication when the new deviceId is assigned
    // Ensure Amplitude is initialized once botDetected results is known
    // TODO: refactor tests to drop `IS_TEST_MODE` check
    if ((!visitorId || !smartSignals?.products?.botd?.data) && !IS_TEST_MODE) {
      return
    }
    const shouldTrack = IS_TEST_MODE || (!isImpersonating && !!AMPLITUDE_API_KEY)
    const disabled = !shouldTrack

    const configuration: amplitude.Types.BrowserOptions = {
      optOut: disabled,
      deviceId: visitorId,
      serverUrl: endpoint,
      appVersion: VERSION,
      identityStorage: 'localStorage',
      defaultTracking: {
        attribution: true,
        pageViews: {
          eventType: EVENT_TYPE,
        },
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
      },
    }

    // Look out, dragons ahead.
    // Yes, it seems strange to both init and load ampli, while ampli.load also does init internally.
    // Every other permutation was either not logging the basic tracking events, or was missing the
    // first event in the tests. This way it tracks everything correctly, renames the page view events,
    // and tests also succeed.
    const apiKey = AMPLITUDE_API_KEY
    amplitude.add(renameEventPropertiesEnrichment)
    amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration })
    ampli.load({ disabled, client: { instance: amplitude } })

    setIsClientLoaded(true)
  }, [isImpersonating, visitorId, smartSignals])

  return (
    <AmplitudeContext.Provider value={{ isClientLoaded }}>
      <AmplitudeExperimentsProvider>{children}</AmplitudeExperimentsProvider>
    </AmplitudeContext.Provider>
  )
}
