import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'

import {
  AccountBannerName,
  AccountConfirmationsName,
  AccountConfirmationsState,
  AccountModalName,
  AccountModalState,
  AccountState,
  BannerName,
  BannerState,
  DEFAULT_ACCOUNT_STATE,
  DEFAULT_BANNER_STATE,
  DEFAULT_CONFIRMATION_STATE,
  DEFAULT_MODAL_STATE,
  DEFAULT_OVERVIEW_STATE,
  DEFAULT_WIDGET_STATE,
  SubscriptionOverviewState,
  UI_PREFERENCES_KEY,
  UiPreferences,
  WidgetName,
  WidgetState,
} from '../models'
import { useLocalStorage } from './localStorage'

export interface UiPreferencesContextData {
  uiPreferences: UiPreferences
  updateUiPreferences: (preferences: Partial<UiPreferences>) => void
}

const UiPreferencesContext = createContext<UiPreferencesContextData>({
  uiPreferences: {},
  updateUiPreferences: () => null,
})

export const useUiPreferences = () => useContext(UiPreferencesContext)

export const useOverviewPreferences = (subscriptionId?: string) => {
  const { uiPreferences, updateUiPreferences } = useUiPreferences()
  const overviewPreferences = subscriptionId ? uiPreferences?.subscriptionOverview?.[subscriptionId] : undefined
  const updateOverviewPreferences = useCallback(
    (overview: SubscriptionOverviewState) => {
      if (subscriptionId == null) {
        return
      }

      updateUiPreferences({
        ...uiPreferences,
        subscriptionOverview: { ...uiPreferences.subscriptionOverview, [subscriptionId]: overview },
      })
    },
    [subscriptionId, uiPreferences, updateUiPreferences]
  )

  const getWidgetState = (widget: WidgetName) => {
    return overviewPreferences?.widgets?.[widget] ?? DEFAULT_WIDGET_STATE
  }

  const getBannerState = (banner: BannerName) => {
    return overviewPreferences?.banners?.[banner] ?? DEFAULT_BANNER_STATE
  }

  const updateWidgetState = (widget: WidgetName, newState: Partial<WidgetState>) => {
    const oldState = overviewPreferences?.widgets?.[widget]

    updateOverviewPreferences({
      ...DEFAULT_OVERVIEW_STATE,
      ...overviewPreferences,
      widgets: { ...overviewPreferences?.widgets, [widget]: { ...DEFAULT_WIDGET_STATE, ...oldState, ...newState } },
    })
  }

  const updateBannerState = (banner: BannerName, newState: Partial<BannerState>) => {
    const oldState = overviewPreferences?.banners?.[banner]

    updateOverviewPreferences({
      ...DEFAULT_OVERVIEW_STATE,
      ...overviewPreferences,
      banners: {
        ...overviewPreferences?.banners,
        [banner]: { ...DEFAULT_BANNER_STATE, ...oldState, ...newState },
      } as Record<BannerName, BannerState>,
    })
  }

  return {
    overviewPreferences,
    updateOverviewPreferences,
    getWidgetState,
    getBannerState,
    updateWidgetState,
    updateBannerState,
  }
}

export const useAccountPreferences = () => {
  const { uiPreferences, updateUiPreferences } = useUiPreferences()
  const accountPreferences = uiPreferences?.account
  const updateAccountPreferences = (account: AccountState) =>
    updateUiPreferences({
      ...uiPreferences,
      account: { ...uiPreferences.account, ...account },
    })

  const getBannerState = (banner: AccountBannerName) => {
    return accountPreferences?.banners?.[banner] ?? DEFAULT_BANNER_STATE
  }

  const updateBannerState = (banner: AccountBannerName, newState: Partial<BannerState>) => {
    const oldState = accountPreferences?.banners?.[banner]

    updateAccountPreferences({
      ...DEFAULT_ACCOUNT_STATE,
      ...accountPreferences,
      banners: {
        ...accountPreferences?.banners,
        [banner]: { ...DEFAULT_BANNER_STATE, ...oldState, ...newState },
      } as Record<AccountBannerName, BannerState>,
    })
  }

  const handleCloseBanner = (banner: AccountBannerName) => {
    updateBannerState(banner, { isHidden: true })
  }

  const getModalState = (modal: AccountModalName) => {
    return accountPreferences?.modals?.[modal] ?? DEFAULT_MODAL_STATE
  }

  const updateModalState = (modal: AccountModalName, newState: Partial<AccountModalState>) => {
    const oldState = accountPreferences?.modals?.[modal]

    updateAccountPreferences({
      ...DEFAULT_ACCOUNT_STATE,
      ...accountPreferences,
      modals: { ...accountPreferences?.modals, [modal]: { ...DEFAULT_MODAL_STATE, ...oldState, ...newState } },
    })
  }

  const getConfirmationState = (subscriptionId: string, confirmationName: AccountConfirmationsName) => {
    const stored = accountPreferences?.confirmations?.[subscriptionId]
    if (!stored) {
      return DEFAULT_CONFIRMATION_STATE
    }
    return stored[confirmationName] ?? DEFAULT_CONFIRMATION_STATE
  }

  const updateConfirmationState = (
    subscriptionId: string,
    confirmationName: AccountConfirmationsName,
    newState: Partial<AccountConfirmationsState>
  ) => {
    const oldState = accountPreferences?.confirmations?.[confirmationName]

    updateAccountPreferences({
      ...DEFAULT_ACCOUNT_STATE,
      ...accountPreferences,
      confirmations: {
        ...accountPreferences?.confirmations,
        [subscriptionId]: {
          [confirmationName]: { ...DEFAULT_CONFIRMATION_STATE, ...oldState, ...newState },
        },
      },
    })
  }

  return {
    accountPreferences,
    updateAccountPreferences,
    getBannerState,
    updateBannerState,
    handleCloseBanner,
    getModalState,
    updateModalState,
    getConfirmationState,
    updateConfirmationState,
  }
}

export function UiPreferencesProvider({ children }: PropsWithChildren<{}>) {
  const [storedValue, setValue] = useLocalStorage<UiPreferences>(UI_PREFERENCES_KEY, {})
  const [uiPreferences, setUiPreferences] = useState<UiPreferences>(storedValue)

  const updateUiPreferences = useCallback((newPreferences: Partial<UiPreferences>) => {
    setUiPreferences((previousPreferences) => ({ ...previousPreferences, ...newPreferences }))
  }, [])

  useEffect(() => {
    setValue(uiPreferences)
  }, [setValue, uiPreferences])

  useEffect(() => {
    // reset feedback drawer on each app restart
    updateUiPreferences({ isFeedbackDrawerDismissed: undefined })
  }, [updateUiPreferences])

  return (
    <UiPreferencesContext.Provider value={{ uiPreferences, updateUiPreferences }}>
      {children}
    </UiPreferencesContext.Provider>
  )
}
