export interface BaseReactProps {
  className?: string
}

export enum MIMEType {
  PlainText = 'text/plain',
  Csv = 'text/csv',
}

export type Falsy = false | null | undefined | 0 | ''

export type PartialWithRequired<T, K extends keyof T> = Pick<T, K> & Partial<T>

export type RouterPath =
  | string
  | {
      pathname: string
      search?: string
      hash?: string
      state?: unknown
    }

export type ValueOf<T> = T[keyof T]
