import { Button } from '@compass/components'
import { Link, Paper, SvgIconTypeMap, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import Loader from 'components/Loader/Loader'
import Logo from 'components/Logo/Logo'
import PolicyAgreementDisclaimer from 'components/PolicyAgreementDisclaimer/PolicyAgreementDisclaimer'
import UserConsent from 'components/UserConsent/UserConsent'
import { WEBSITE_URL } from 'const'
import { ReactNode } from 'react'

import styles from './AuthWrapper.module.scss'

export type Props = {
  title?: string
  subtitle?: string | ReactNode
  children: ReactNode
  isLoading?: boolean
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | null
  showDisclaimer?: boolean
  showConsentForm?: boolean
}

export function AuthWrapper({ Icon, isLoading, title, subtitle, children, showDisclaimer, showConsentForm }: Props) {
  return (
    <div className={styles.authWrapper}>
      <Link href={WEBSITE_URL} underline='hover'>
        <Logo fill='#F04405' fillSecondary='#000' short={false} className={styles.logo} />
      </Link>
      <Paper className={styles.container}>
        <header className={styles.header}>
          {Icon && <Icon className={styles.icon} />}
          <Typography className={styles.title} variant='h6' component='h1'>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant='body2' className={styles.subtitle}>
              {subtitle}
            </Typography>
          )}
        </header>

        {children}

        {showConsentForm && (
          <>
            <UserConsent className={styles.consent} />
            <Button className={styles.submitButton} isDisabled={isLoading} size='lg' type='submit'>
              Submit
            </Button>
          </>
        )}

        {isLoading && <Loader />}
      </Paper>
      {showDisclaimer && <PolicyAgreementDisclaimer />}
    </div>
  )
}
