import { Header, MainColumn, SettingsLayout } from 'features/commonUI'
import { useLogPageView } from 'helpers/vendor'
import { useDocumentTitle, useSubscriptions } from 'hooks'
import { ampli } from 'models/ampli'

import ApplicationsOverview from './components/ApplicationsOverview/ApplicationsOverview'

export function AccountApplicationsPage() {
  useDocumentTitle('Workspaces')
  useLogPageView(() => ampli.applicationsViewed())

  const { data: subscriptions, isError, isLoading } = useSubscriptions()

  return (
    <>
      <Header title='Settings' />

      <MainColumn>
        <SettingsLayout>
          <ApplicationsOverview subscriptions={subscriptions} isError={isError} isLoading={isLoading} />
        </SettingsLayout>
      </MainColumn>
    </>
  )
}
