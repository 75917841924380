import { ButtonProps, Divider } from '@mui/material'
import { OAuthProvider, OAuthProviderHandlerMap } from 'models'

import { OAuthButton } from './OAuthButton'
import styles from './OAuthSection.module.scss'

export interface OAuthSectionProps extends ButtonProps {
  providers: OAuthProviderHandlerMap
  isLoading?: boolean
}

export function OAuthSection({ providers, isLoading }: OAuthSectionProps) {
  const buttons = Object.entries(providers).map(([provider, handler]) => (
    <OAuthButton
      key={provider}
      isDisabled={isLoading}
      provider={provider as OAuthProvider}
      variant='secondary'
      size='lg'
      onPress={handler}
      data-testid={`${provider}Button`}
    />
  ))

  if (!buttons.length) {
    return null
  }

  return (
    <div className={styles.oAuthSection}>
      <div className={styles.buttonBlock}>{buttons}</div>
      <Divider
        className={styles.divider}
        sx={{
          '&::before, &::after': {
            borderColor: '#F2F2F2',
          },
        }}
      >
        <span className={styles.dividerText}>or</span>
      </Divider>
    </div>
  )
}
