import { useCurrentSubscription } from 'features/subscription'
import { useSendWebhookTestEvent, useWebhooks } from 'hooks/api/webhooks'
import { ampli } from 'models/ampli'
import { useParams } from 'react-router-dom'

import WebhookVerificationPanel from '../../components/WebhookVerificationPanel/WebhookVerificationPanel'

export function WebhookVerificationPage() {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { webhookId } = useParams<{ webhookId: string }>()

  const { data: webhooks, isLoading, error: webhooksError } = useWebhooks(subscriptionId, true)

  const { mutate: sendTestEvent, isLoading: isSendingEvent, error: sendEventError } = useSendWebhookTestEvent()

  return (
    <WebhookVerificationPanel
      isLoading={isLoading}
      webhookId={webhookId}
      isSendingEvent={isSendingEvent}
      error={webhooksError ?? sendEventError}
      status={webhooks?.find(({ id }) => id === webhookId)?.verificationStatus}
      onSendEvent={() => {
        ampli.sendTestEventClicked()
        sendTestEvent({ params: { subscriptionId, webhookId } })
      }}
    />
  )
}
