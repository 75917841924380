import { Typography } from '@mui/material'

import styles from './CloudflareDescription.module.scss'

const benefits = [
  'Significant increase in accuracy in browsers with strict privacy features such as Safari or Firefox.',
  'Cookies are now recognized as “first-party.” This means they can live longer in the browser and extend the lifetime of visitorIds.',
  'Ad blockers will not block our Fingerprint JS agent from loading. Attempts to connect to an external URL will be stopped by most ad blockers while attempts to connect to the same site URL will be allowed.',
  'Ad blockers will not block our identification requests since they are sent to the specific path or subdomain that belongs to the same site.',
  'Insight and control over the identification requests that can be combined with other Cloudflare features like WAF or Analytics.',
  'With the Cloudflare Integration, you can manage unlimited subdomains and paths and provide Fingerprint services to all your customers at any scale while benefiting from all the 1st-party integration improvements.',
  'Cookie security: Cloudflare integration drops all the cookies sent from the origin website. The worker code is open-source so this behavior can be transparently verified and audited.',
  'Easy to meet compliance and auditing requirements.',
]

export default function CloudflareDescription() {
  return (
    <>
      <Typography variant='body1'>
        Cloudflare worker code is 100% open-source and available on GitHub. Once the Fingerprint JS agent is configured
        on your site correctly, the worker is responsible for delivering the latest fingerprinting client-side logic as
        well as proxying identification requests and responses between your site and Fingerprint&apos;s APIs.
      </Typography>
      <Typography variant='h3' className={styles.benefitsTitle}>
        Benefits
      </Typography>
      <ul>
        {benefits.map((benefit, index) => (
          <li key={index}>
            <Typography variant='body1'>{benefit}</Typography>
          </li>
        ))}
      </ul>
    </>
  )
}
