import { Button } from '@compass/components'
import { Dialog } from '@mui/material'
import { DialogActions, DialogContent, DialogProps, DialogTitle } from 'features/commonUI'
import * as React from 'react'
import { PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react'

interface DialogContextData {
  openDialog: (options: DialogOptions) => void
}

interface DialogOptions {
  label: string
  content?: React.ReactNode
  confirmText?: string
  rejectText?: string
  onConfirm?: () => void
  onReject?: () => void
}

const DialogContext = React.createContext<DialogContextData>({
  openDialog: () => null,
})

export function useConfirmationDialog() {
  const { openDialog } = useContext(DialogContext)

  return { openDialog }
}

export function DialogProvider({ children }: PropsWithChildren<{}>) {
  const [isOpened, setIsOpened] = useState(false)
  const [dialogOptions, setDialogOptions] = useState<DialogOptions | null>(null)

  const handleConfirm = useCallback(() => {
    setIsOpened(false)
    dialogOptions?.onConfirm && dialogOptions.onConfirm()
  }, [dialogOptions])

  const handleReject = useCallback(() => {
    setIsOpened(false)
    dialogOptions?.onReject?.()
  }, [dialogOptions])

  const openDialog = useCallback<(options: DialogOptions) => void>((options) => {
    setIsOpened(true)
    setDialogOptions(options)
  }, [])

  const context: DialogContextData = useMemo(
    () => ({
      openDialog,
    }),
    [openDialog]
  )

  return (
    <DialogContext.Provider value={context}>
      {children}
      {dialogOptions && (
        <ConfirmationDialog
          open={isOpened}
          title={dialogOptions.label}
          content={dialogOptions.content}
          confirmText={dialogOptions.confirmText}
          rejectText={dialogOptions.rejectText}
          onConfirm={handleConfirm}
          onReject={handleReject}
        />
      )}
    </DialogContext.Provider>
  )
}

export interface ConfirmationDialogProps extends Pick<DialogProps, 'open'> {
  title: string
  content?: React.ReactNode
  confirmText?: string
  rejectText?: string
  onConfirm: () => void
  onReject: () => void
}

export function ConfirmationDialog({
  open,
  title,
  content,
  confirmText = 'Ok',
  rejectText = 'Cancel',
  onConfirm,
  onReject,
}: ConfirmationDialogProps) {
  return (
    <Dialog open={open} onClose={onReject}>
      <DialogTitle onClose={onReject}>{title}</DialogTitle>
      {content && <DialogContent>{content}</DialogContent>}
      <DialogActions>
        <Button onPress={onReject} variant='ghost' data-testid='confirmationCancelButton'>
          {rejectText}
        </Button>
        <Button onPress={onConfirm} autoFocus data-testid='confirmationOkButton'>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
