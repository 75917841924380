import { AppRoute, buildRoute, SUBSCRIPTION_SHORTCUT_ROUTES } from 'appRoutes'
import { ACTIVE_SUBSCRIPTION_STATUSES, reservedSubscriptionPaths, WithSubscriptionId } from 'const'
import { useAuth, useLastViewedSubscription, useSubscription, useToast } from 'hooks'
import { useApplicationGroupTracking } from 'hooks/analytics'
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { useSubscriptions } from '../../../hooks/api/subscriptions'

export interface CurrentSubscriptionContextData {
  currentSubscriptionId: string
}

// TODO [VL] Find a better way to ensure the current id is valid.
// The context needs to return null when the id is unknown because that disbles react-query queries.
// However we need to use the id in a lot of places, so to prevent using ! everywhere we trick the type system into thinking this is not nullable.
// This does not cause runtime issues because all queries will be in the loading state while the id is not valid.
const defaultSubscriptionId = null as unknown as string

export const CurrentSubscriptionContext = createContext<CurrentSubscriptionContextData>({
  currentSubscriptionId: defaultSubscriptionId,
})

export const useCurrentSubscription = () => useContext(CurrentSubscriptionContext)

const SKIPPED_PATHS = new Set(SUBSCRIPTION_SHORTCUT_ROUTES)

export function CurrentSubscriptionProvider({ children }: PropsWithChildren<{}>) {
  const { isAuthorized } = useAuth()
  const { trackApplication } = useApplicationGroupTracking()
  const { push: redirect, location } = useHistory()
  const { showToast } = useToast()
  const routeSubscriptionId = useRouteMatch<WithSubscriptionId>('/workspaces/:subscriptionId')?.params?.subscriptionId

  const [lastViewedSubscription, setLastViewedSubscription] = useLastViewedSubscription()
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState<string>(defaultSubscriptionId)

  const { data: subscriptions } = useSubscriptions(false, isAuthorized)

  const checkRouteRedirect = useCallback(
    (id: string) => {
      if (SKIPPED_PATHS.has(location.pathname)) {
        return
      }

      // If the route contained a subscription id different from the calculated one, redirect the user and show an error message.
      if (routeSubscriptionId != null && id != null && routeSubscriptionId !== id) {
        redirect(buildRoute(AppRoute.SubscriptionOverview, { subscriptionId: id }))
        showToast({
          message: 'No workspace found with this id, redirecting.',
          severity: 'error',
        })
      }

      // If the route was matching the calculated subscription id, no need to redirect the user.
    },
    [location.pathname, redirect, routeSubscriptionId, showToast]
  )

  useEffect(() => {
    if (!isAuthorized) {
      return
    }

    // TODO [DS] currentSubscriptionId shouldn't be null since a lot of existing code expects it to have a value.
    // Until the react-query update is merged, tests can't wait for queries to finish, and tests fail because of incorrect subscription ids.
    const subscriptionIds = subscriptions?.map((s) => s.id) ?? [routeSubscriptionId, lastViewedSubscription]

    // The current subscription ID is in the route.
    // If it is accessible by the user, save the subscription id to the context.
    // If not, continue with the other options to select a possible id:
    //   1. last viewed subscription
    //   2. first active from the list of subscriptions
    //   3. non-active from the list of subscriptions
    if (
      routeSubscriptionId &&
      !reservedSubscriptionPaths.includes(routeSubscriptionId) &&
      subscriptionIds.includes(routeSubscriptionId)
    ) {
      setCurrentSubscriptionId(routeSubscriptionId)
      return
    }

    if (currentSubscriptionId == null || !subscriptionIds.includes(currentSubscriptionId)) {
      if (lastViewedSubscription && subscriptionIds.includes(lastViewedSubscription)) {
        // 1. last viewed subscription
        // The ID is not in the route, but we know the last viewed subscription and can restore it.
        setCurrentSubscriptionId(lastViewedSubscription)
        checkRouteRedirect(lastViewedSubscription)
        return
      }

      if (subscriptions && subscriptions.length > 0) {
        // 2. first active from the list of subscriptions
        // 3. non-active from the list of subscriptions
        // We don't know the current subscription, so we pick the first active one.
        const firstActiveSubscription =
          subscriptions.find((s) => ACTIVE_SUBSCRIPTION_STATUSES.includes(s.status)) ?? subscriptions[0]

        if (firstActiveSubscription != null) {
          setCurrentSubscriptionId(firstActiveSubscription.id)
          checkRouteRedirect(firstActiveSubscription.id)
        }
      }
    }
  }, [
    checkRouteRedirect,
    currentSubscriptionId,
    isAuthorized,
    lastViewedSubscription,
    redirect,
    routeSubscriptionId,
    subscriptions,
  ])

  useEffect(() => {
    if (currentSubscriptionId && lastViewedSubscription !== currentSubscriptionId) {
      setLastViewedSubscription(currentSubscriptionId)
    }
  }, [lastViewedSubscription, currentSubscriptionId, setLastViewedSubscription])

  const subscription = subscriptions?.find(({ id }) => id === currentSubscriptionId)
  const subscriptionId = subscription?.id
  useEffect(() => {
    if (subscriptionId) {
      trackApplication(subscriptionId)
    }
  }, [subscriptionId, trackApplication])

  return (
    <CurrentSubscriptionContext.Provider
      value={{
        currentSubscriptionId,
      }}
    >
      {children}
    </CurrentSubscriptionContext.Provider>
  )
}

// TODO [VL] Research whether or not it's viable to keep the subscription data on the provider instead of just the id.
export function useCurrentSubscriptionData() {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: subscription, isLoading, refetch } = useSubscription(currentSubscriptionId)

  return { subscription, isLoading, refetch }
}
