import { Grid } from '@mui/material'
import { CSSProperties, Fragment } from 'react'

import styles from './Message.module.scss'

export type Props = {
  avatarSrc: string
  title: string
  time: string
  message: string
  style?: CSSProperties
  className?: string
}

export const Message = ({ avatarSrc, time, title, message, style, className }: Props) => {
  return (
    <Grid display='flex' style={style} className={className}>
      <img src={avatarSrc} className={styles.avatar} alt='' />
      <div className={styles.text}>
        <div className={styles.from}>
          <strong className={styles.title}>{title}</strong>
          <span className={styles.time}>{time}</span>
        </div>
        <div className={styles.message}>
          {message.split('\n').map((item, idx) => {
            return (
              <Fragment key={idx}>
                {item}
                <br />
              </Fragment>
            )
          })}
        </div>
      </div>
    </Grid>
  )
}
