import clsx from 'clsx'
import { BaseReactProps } from 'helpers/types'
import { RegionCode } from 'models'
import { memo } from 'react'

import { getRegionByCode } from '../../helpers/content'
import styles from './RegionFlag.module.scss'

export interface RegionFlagProps extends BaseReactProps {
  regionCode: RegionCode
  size?: 'm' | 's'
}

export default memo(function RegionFlag({ regionCode, className, size = 'm', ...props }: RegionFlagProps) {
  const region = getRegionByCode(regionCode)

  if (typeof region?.flag === 'string') {
    return (
      <img
        src={region?.flag}
        className={clsx(styles.flagImage, className, size === 's' && styles.small)}
        alt={region?.title}
        {...props}
      />
    )
  }

  const FlagIcon = region?.flag
  return (
    <FlagIcon
      viewBox='2 2 20 20'
      className={clsx(styles.flagIcon, className, size === 's' && styles.small)}
      {...props}
    />
  )
})
