import { Grid } from '@mui/material'
import clsx from 'clsx'
import { User } from 'models'

import ControlledSelect, { ControlledSelectItem } from '../../../../components/ControlledSelect/ControlledSelect'
import { JOB_FUNCTION_TYPES, JOB_LEVEL_TYPES } from '../../../../const'
import styles from './JobLevelAndFunctionSelector.module.scss'

export type JobLevelAndFunctionValue = Pick<User, 'jobLevel' | 'jobFunction'>

export interface JobLevelAndFunctionSelectorProps {
  containerType?: 'dialog' | 'fullPage'
  optional?: boolean
}

const JOB_LEVEL_ITEMS: ControlledSelectItem[] = Object.values(JOB_LEVEL_TYPES).map(({ value, name }) => ({
  value,
  displayValue: name,
}))

const JOB_FUNCTION_ITEMS: ControlledSelectItem[] = Object.values(JOB_FUNCTION_TYPES).map(({ value, name }) => ({
  value,
  displayValue: name,
}))

export function JobLevelAndFunctionSelector({ containerType, optional }: JobLevelAndFunctionSelectorProps) {
  const fullPage = containerType === 'fullPage'

  return (
    <>
      <Grid item xs={12} md={fullPage ? 6 : 12} className={clsx({ [styles.fullPage]: fullPage })}>
        <ControlledSelect
          title='Job level'
          label={`What is your job level?${optional ? ' (Optional)' : ''}`}
          name='jobLevel'
          items={JOB_LEVEL_ITEMS}
          placeholder='Job level'
        />
      </Grid>

      <Grid item xs={12} md={fullPage ? 6 : 12} className={clsx({ [styles.fullPage]: fullPage })}>
        <ControlledSelect
          title='Job function'
          label={`What is your job function?${optional ? ' (Optional)' : ''}`}
          name='jobFunction'
          items={JOB_FUNCTION_ITEMS}
          placeholder='Job function'
        />
      </Grid>
    </>
  )
}
