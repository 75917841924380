import { AppRoute } from 'appRoutes'
import { useSubscriptions } from 'hooks'
import { OnboardingStep } from 'models'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useRequestMutation } from '../../hooks/api/base'
import { useUpdateUserContext, useUserContext } from '../../hooks/api/context'

export function useOnboardingRedirect() {
  const history = useHistory()
  const { isOnboardingInProgress: inProgress, isLoading } = useOnboarding()
  const { data: subscriptions, isLoading: isSubscriptionsLoading } = useSubscriptions()

  useEffect(() => {
    if (!isSubscriptionsLoading && subscriptions?.length === 0) {
      history.replace(AppRoute.Onboarding)
    }

    if (!isLoading && inProgress) {
      history.replace(AppRoute.Onboarding)
    }
  }, [history, isLoading, inProgress, isSubscriptionsLoading, subscriptions])
}

export function useOnboarding(withPolling?: boolean) {
  const { isLoading, data: context } = useUserContext(withPolling)
  const step = context?.onboardingStep

  return {
    isLoading,
    step,
    isOnboardingInProgress: isOnboardingInProgress(step),
  }
}

export function useOnboardingSkip() {
  const updateUserContext = useUpdateUserContext()

  return useRequestMutation('onboardingSkip', {
    onSuccess: () => {
      updateUserContext({ onboardingStep: OnboardingStep.Skipped })
    },
  })
}

export function isOnboardingInProgress(step?: OnboardingStep) {
  return step !== OnboardingStep.Finished && step !== OnboardingStep.Skipped
}
