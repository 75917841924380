import { Button } from '@compass/components'
import { Paper, Typography } from '@mui/material'
import { ExternalLayout } from 'features/commonUI'

import BadLink from './badLink.svg'
import styles from './SignUpConfirm.module.scss'

export type SignUpConfirmBrokenLinkProps = {
  onSignupClick?: () => void
}

export function SignUpConfirmBrokenLink({ onSignupClick }: SignUpConfirmBrokenLinkProps) {
  return (
    <ExternalLayout>
      <Paper className={styles.container}>
        <div className={styles.topImage}>
          <BadLink />
        </div>
        <h1 className={styles.title}>We couldn’t verify your email</h1>
        <Typography variant='body2' className={styles.description}>
          The link most likely broke. Click the button below to sign up again.
        </Typography>
        <Button onPress={onSignupClick} className={styles.button}>
          Sign up again
        </Button>
      </Paper>
    </ExternalLayout>
  )
}
