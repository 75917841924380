import { Collapse } from '@mui/material'
import clsx from 'clsx'
import { Children, PropsWithChildren, ReactElement, ReactNode, useContext } from 'react'

import { embeddedCtx } from '../../Wizards/CloudflareSetup/EmbeddedContext'
import WizardError from '../Error/WizardError'
import WizardHeader from '../Header/WizardHeader'
import styles from './WizardStep.module.scss'

export interface WizardStepProps {
  isEmbedded?: boolean
  title: string | ReactNode
  subtitle?: string | ReactNode
  error?: { message?: ReactNode } | null
  children?: ReactElement | ReactElement[]
}

export function WizardStepContent({ children }: PropsWithChildren<{}>) {
  return <>{children}</>
}

export function WizardStepErrorContent({ children }: PropsWithChildren<{}>) {
  return <>{children}</>
}

export function WizardStepActions({ children }: PropsWithChildren<{}>) {
  return <>{children}</>
}

export default function WizardStep({ title, subtitle, error, children }: WizardStepProps) {
  let content: ReactElement | undefined = undefined
  let errorContent: ReactElement | undefined = undefined
  let actions: ReactElement | undefined = undefined
  const isEmbedded = useContext(embeddedCtx)

  if (children) {
    Children.map(children, (child) => {
      switch (child.type) {
        case WizardStepContent:
          content = child
          break
        case WizardStepErrorContent:
          errorContent = child
          break
        case WizardStepActions:
          actions = child
          break
      }
    })
  }

  return (
    <div className={clsx(styles.step, { [styles.embedded]: isEmbedded })}>
      <WizardHeader title={title} subtitle={subtitle} />

      <Collapse in={!!error} className={styles.errorWrapper}>
        {error && <WizardError error={error} />}
      </Collapse>

      {errorContent ? errorContent : content}
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  )
}
