import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import { useRadioGroup } from '@mui/material/RadioGroup'
import { withStyles } from '@mui/styles'
import React from 'react'

type OutlinedFormControlLabelContentProps = { checked: boolean }
export type OutlinedFormControlLabelProps = Omit<FormControlLabelProps, 'label'> & {
  label: FormControlLabelProps['label'] | ((props: OutlinedFormControlLabelContentProps) => React.JSX.Element)
}

type StyledFormControlLabelProps = FormControlLabelProps & { checked: boolean }
const StyledFormControlLabel = withStyles({
  root: {
    padding: '7px 16px 7px 7px',
    background: ({ checked }) => (checked ? '#FFFCFA' : '#FFFFFF'),
    border: ({ checked }) => (checked ? '1px solid #FDE4DB' : '1px solid #EAEDF3'),
    borderRadius: '4px',
  },
  label: {
    padding: '9px 0', // Adds up to 16px total. This is needed to avoid restyling the control.
  },
})((props: StyledFormControlLabelProps) => <FormControlLabel {...props} />)

export function OutlinedFormControlLabel(props: OutlinedFormControlLabelProps) {
  const radioGroup = useRadioGroup()

  const isChecked = Boolean(props.checked || (radioGroup && radioGroup.value === props.value))

  return (
    <StyledFormControlLabel
      {...props}
      checked={isChecked}
      label={typeof props.label === 'function' ? props.label({ checked: isChecked }) : props.label}
    />
  )
}
