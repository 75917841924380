import { DateTime } from 'luxon'

export type GetSubscriptionStatsQuery = {
  from?: string
  to?: string
}

export enum SubscriptionStatistic {
  Usage = 'commonApiCalls',
  UniqueVisitors = 'uniqueVisitors',
  EventsPerVisitor = 'eventsPerVisitor',
  AverageRps = 'averageRPS',
  UniqueLinkedIds = 'uniqueLinkedIds',
  PeakRps = 'peakRPS',
  ThrottledApiCalls = 'throttledApiCalls',
  // AverageCallsPerVisitor = 'averageCallsPerVisitor', // TODO: is it same as eventsPerVisitor?
  // UniqueIpAddresses = 'uniqueIPAddresses',
  // NewVisitors = 'newVisitors',
  // ReturningVisitors = 'returningVisitors',
  // RestrictedApiCalls = 'restrictedApiCalls',
}

// These are static dates that match starting dates of corresponding data collection
// We show warning if an interval before these dates is requested and output what we can.
export const statisticsAvailabilityBoundaries: Record<SubscriptionStatistic, DateTime | null> = {
  [SubscriptionStatistic.Usage]: null,
  [SubscriptionStatistic.UniqueVisitors]: DateTime.fromISO('2023-01-01T00:00:00Z'),
  [SubscriptionStatistic.EventsPerVisitor]: DateTime.fromISO('2023-01-01T00:00:00Z'),
  [SubscriptionStatistic.AverageRps]: DateTime.fromISO('2022-12-30T10:50:00Z'),
  [SubscriptionStatistic.UniqueLinkedIds]: DateTime.fromISO('2022-03-29T15:30:00Z'),
  [SubscriptionStatistic.PeakRps]: DateTime.fromISO('2022-12-30T10:50:00Z'),
  [SubscriptionStatistic.ThrottledApiCalls]: null,
}

type ComparableValue<T> = {
  currentPeriod: T
  prevPeriod: T
}

export type SubscriptionStatisticValue = {
  [SubscriptionStatistic.UniqueVisitors]: ComparableValue<string>
  [SubscriptionStatistic.Usage]: ComparableValue<string>
  [SubscriptionStatistic.EventsPerVisitor]: ComparableValue<number>
  [SubscriptionStatistic.AverageRps]: ComparableValue<number>
  [SubscriptionStatistic.UniqueLinkedIds]: ComparableValue<string>
  [SubscriptionStatistic.PeakRps]: ComparableValue<number>
  [SubscriptionStatistic.ThrottledApiCalls]: ComparableValue<string>
}

type StatisticReturnTypesMap = {
  [k in SubscriptionStatistic]: {
    type: k
    data: SubscriptionStatisticValue[k]
  }
}

export type StatisticReturnTypes = StatisticReturnTypesMap[SubscriptionStatistic]

export type SubscriptionStatsResponse = {
  from: string
  to: string
  stats: StatisticReturnTypes[]
}
