import { LinkButton } from '@compass/components'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import {
  Alert,
  Breadcrumbs,
  Divider,
  Grid,
  Link,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { AppRoute } from 'appRoutes'
import clsx from 'clsx'
import { Header, MainColumn, SettingsLayout } from 'features/commonUI'
import { date } from 'helpers/data'
import { formatDate, formatMoney, formatNum } from 'helpers/format'
import { DateTime } from 'luxon'
import { Invoice, InvoiceLine } from 'models'
import { memo, useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Stripe from 'stripe'

import styles from './InvoiceView.module.scss'

interface LineGroup {
  priceId: string
  periodStart: Date
  periodEnd: Date
  lines: InvoiceLine[]
}

export default memo(function InvoiceView({ invoice }: { invoice?: Invoice }) {
  const lineGroups = useMemo(() => collectLineGroups(invoice?.lines ?? []), [invoice])

  if (invoice == null) {
    return (
      <>
        <Header title='Settings' />
        <MainColumn>
          <SettingsLayout>
            <Skeleton
              className={styles.skeleton}
              width='100%'
              height='200px'
              data-testid='invoice-loading-skeleton-rect'
            />
            <Skeleton className={styles.skeleton} width='100%' height='32px' />
            <Skeleton className={styles.skeleton} width='100%' height='32px' />
            <Skeleton className={styles.skeleton} width='100%' height='32px' />
            <Skeleton className={styles.skeleton} width='100%' height='32px' />
          </SettingsLayout>
        </MainColumn>
      </>
    )
  }

  const {
    amountRemaining,
    amountPaid,
    amountSubTotal,
    amountTotal,
    discount,
    status,
    subscriptionName,
    email,
    periodStart,
    periodEnd,
    invoicePdf,
    isUpcoming,
  } = invoice

  return (
    <>
      <Header title='Settings' />
      <MainColumn>
        <SettingsLayout>
          <Grid item sm={12} md={12}>
            <Breadcrumbs aria-label='breadcrumb' separator='/' className='leading-7'>
              <Link color='inherit' component={RouterLink} to={AppRoute.Billing} underline='hover'>
                Billing
              </Link>
              {!isUpcoming && <Typography component='span'>Invoice from {formatDate(date(periodStart))}</Typography>}
            </Breadcrumbs>
          </Grid>
          {isUpcoming && (
            <Grid item sm={12} md={12}>
              <Alert severity='info'>
                This is a preview of the upcoming invoice. The amount may increase along with your usage.
              </Alert>
            </Grid>
          )}
          <Grid item sm={12} md={12}>
            <Paper className='p-6'>
              <header className={styles.header}>
                <Typography variant='h4'>Invoice</Typography>
                {!isUpcoming && !!invoicePdf && (
                  <LinkButton href={invoicePdf} target='_blank' variant='secondary'>
                    Download PDF
                    <SaveAltIcon fontSize='inherit' />
                  </LinkButton>
                )}
              </header>
              <Table className={clsx(styles.infoTable)} padding={'none'}>
                <TableBody>
                  <TableRow>
                    <TableCell className={clsx(styles.borderless, styles.firstColumn)}>Invoice period</TableCell>
                    <TableCell className={styles.borderless}>
                      {formatDate(date(periodStart), 'MMM d, yyyy')} – {formatDate(date(periodEnd), 'MMM d, yyyy')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={clsx(styles.borderless, styles.firstColumn)}>Status</TableCell>
                    <TableCell className={clsx(styles.borderless, styles.status)}>{status}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={clsx(styles.borderless, styles.firstColumn)}>Billed to</TableCell>
                    <TableCell className={styles.borderless}>{email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={clsx(styles.borderless, styles.firstColumn)}>Workspace</TableCell>
                    <TableCell className={styles.borderless}>{subscriptionName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={clsx(styles.borderless, styles.firstColumn)}>Currency</TableCell>
                    <TableCell className={styles.borderless}>USD — US Dollar</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Divider className='border-dashed' />
              <Table className={clsx(styles.linesTable)}>
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell align='right'>Qty</TableCell>
                    <TableCell align='right'>Unit price</TableCell>
                    <TableCell align='right'>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lineGroups.map((group) => (
                    <>
                      <TableRow key={`${group.priceId}-date`} className={styles.itemDate}>
                        <TableCell colSpan={4}>{`${formatDate(date(group.periodStart), 'MMM d, yyyy')} - ${formatDate(
                          date(group.periodEnd),
                          'MMM d, yyyy'
                        )}`}</TableCell>
                      </TableRow>
                      <TableRow key={group.priceId} className={styles.item}>
                        <TableCell>{group.lines[0].descriptionShort}</TableCell>
                        <TableCell align='right'>
                          {formatNum(group.lines.reduce((sum, line) => sum + line.quantity, 0))}
                        </TableCell>
                        {group.lines[0].price?.tiers ? (
                          <>
                            <TableCell />
                            <TableCell />
                          </>
                        ) : (
                          <>
                            <TableCell align='right'>
                              {group.lines[0].price && group.lines[0].price.unitAmountDecimal != null
                                ? formatMoney(Number(group.lines[0].price.unitAmountDecimal) / 100)
                                : ''}
                            </TableCell>
                            <TableCell className={styles.bold} align='right'>
                              {formatMoney(group.lines[0].amount / 100)}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                      {group.lines[0].price?.tiers &&
                        group.lines.map((line) => (
                          <TableRow key={line.id} className={styles.subItem}>
                            <TableCell className={styles.firstColumn}>{line.tierLabel ?? line.description}</TableCell>
                            <TableCell align='right'>{formatNum(line.quantity)}</TableCell>
                            <TableCell align='right'>
                              {line.price && line.price.unitAmountDecimal != null
                                ? formatMoney(Number(line.price.unitAmountDecimal) / 100)
                                : ''}
                            </TableCell>
                            <TableCell className={styles.bold} align='right'>
                              {formatMoney(line.amount / 100)}
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  ))}
                </TableBody>
                <TableFooter className={styles.footer}>
                  <TableRow>
                    <TableCell align='right' colSpan={3} className={styles.borderless}>
                      Subtotal
                    </TableCell>
                    <TableCell align='right' className={styles.borderless}>
                      {formatMoney(amountSubTotal / 100)}
                    </TableCell>
                  </TableRow>
                  {discount > 0 && (
                    <TableRow>
                      <TableCell align='right' colSpan={3} className={styles.borderless}>
                        Discount
                      </TableCell>
                      <TableCell align='right' className={styles.borderless}>
                        -{formatMoney(discount / 100)}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell align='right' colSpan={3} className={styles.borderless}>
                      Total
                    </TableCell>
                    <TableCell align='right' className={styles.borderless}>
                      {formatMoney(amountTotal / 100)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' colSpan={3} className={styles.borderless}>
                      Amount paid
                    </TableCell>
                    <TableCell align='right' className={styles.borderless}>
                      -{formatMoney(amountPaid / 100)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' colSpan={3} className={clsx(styles.borderless, styles.bold)}>
                      Amount due
                    </TableCell>
                    <TableCell align='right' className={clsx(styles.borderless, styles.bold)}>
                      {formatMoney(amountRemaining / 100)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Paper>
          </Grid>
        </SettingsLayout>
      </MainColumn>
    </>
  )
})

function collectLineGroups(lines: InvoiceLine[]): LineGroup[] {
  const lineGroups = lines.reduce<Record<string, LineGroup>>((groups, line) => {
    const priceId = line.price?.id ?? 'none'
    if (!groups[priceId]) {
      groups[priceId] = {
        priceId,
        periodStart: line.periodStart,
        periodEnd: line.periodEnd,
        lines: [] as InvoiceLine[],
      } as LineGroup
    }

    groups[priceId].lines.push(line)
    return groups
  }, {})

  const sortedGroups = Object.values(lineGroups).sort((a, b) =>
    DateTime.fromJSDate(date(a.periodStart)) < DateTime.fromJSDate(date(b.periodStart)) ? -1 : 1
  )

  return sortedGroups.map((group) => ({ ...group, lines: getLineItemsWithTierLabels(group.lines) }))
}

function getLineItemsWithTierLabels(lines: InvoiceLine[]) {
  return lines.map((line, index) => {
    if (!line.price?.tiers) {
      return line
    }

    const { tierLabel, unitAmountDecimal } = getTierLabelAndUnitAmountForLine(index, line.price.tiers)
    return {
      ...line,
      tierLabel,
      price: {
        ...line.price,
        unitAmountDecimal,
      },
    }
  })
}

function getTierLabelAndUnitAmountForLine(
  lineIndex: number,
  tiers: Stripe.Price.Tier[]
): { tierLabel: string; unitAmountDecimal: string | null } {
  const tierIndex = Math.floor(lineIndex / 2)
  const lineIndexWithinTier = lineIndex % 2
  const tier = tiers[tierIndex]

  if (Number.isFinite(tier.up_to)) {
    let tierLabel: string
    let unitAmountDecimal: string | null = null

    if (lineIndexWithinTier === 0) {
      tierLabel = `First ${tier.up_to}`
      unitAmountDecimal = tier.unit_amount_decimal ?? '0'
    } else {
      tierLabel = `Flat fee for first ${tier.up_to}`
    }

    return { tierLabel, unitAmountDecimal }
  }

  if (tier.unit_amount != null || tier.unit_amount_decimal != null) {
    let previousUpTo = 0
    for (let i = tierIndex - 1; i >= 0; i--) {
      if (tiers[i].up_to != null) {
        previousUpTo = tiers[i].up_to as number
      }
    }

    return { tierLabel: `${previousUpTo + 1} and above`, unitAmountDecimal: tier.unit_amount_decimal }
  }

  throw new Error("Can't get the tier label")
}
