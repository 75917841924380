import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Grid, IconButton, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { DateTime } from 'luxon'

import { MONTHS } from '../../../../helpers/dateRange'
import styles from './Header.module.scss'

interface HeaderProps {
  date: DateTime
  minDate: DateTime
  maxDate: DateTime
  setDate: (date: DateTime) => void
  nextDisabled: boolean
  prevDisabled: boolean
  onClickNext: () => void
  onClickPrevious: () => void
}

const generateYears = (from: DateTime, to: DateTime) => {
  const fromYear = from.get('year')
  const toYear = to.get('year')
  const years = []

  for (let i = fromYear; i <= toYear; i++) {
    years.push(i)
  }

  return years
}

export default function Header({
  date,
  minDate,
  maxDate,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
}: HeaderProps) {
  const handleMonthChange = (event: SelectChangeEvent<number>) => {
    setDate(date.set({ month: (event.target.value as number) + 1 }))
  }

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setDate(date.set({ year: (event.target.value as number) + 1 }))
  }

  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      <Grid item className={styles.iconContainer}>
        <IconButton className={styles.icon} disabled={prevDisabled} onClick={onClickPrevious} size='large'>
          <ChevronLeft color={prevDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
      <Grid item>
        <Select
          variant='standard'
          value={date.get('month') - 1}
          onChange={handleMonthChange}
          MenuProps={{ disablePortal: true }}
        >
          {MONTHS.map((month, idx) => (
            <MenuItem key={month} value={idx}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item>
        <Select
          variant='standard'
          value={date.get('year')}
          onChange={handleYearChange}
          MenuProps={{ disablePortal: true }}
          data-testid='date-range-picker-year-select'
        >
          {generateYears(minDate, maxDate).map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item className={styles.iconContainer}>
        <IconButton className={styles.icon} disabled={nextDisabled} onClick={onClickNext} size='large'>
          <ChevronRight color={nextDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
    </Grid>
  )
}
