import { GTM_ID, IS_TEST_MODE } from 'const'
import { SubscriptionType } from 'models'
import TagManager from 'react-gtm-module'

// GTM API requires dataLayer access through global window variable
declare global {
  interface Window {
    dataLayer: object[]
  }
}

export enum EventAction {
  Create = 'Create',
  Cancel = 'Cancel',
  CancelTrial = 'CancelTrial',
  Upgrade = 'Upgrade',
  IntentComplete = 'IntentComplete',
}

export enum EventCategory {
  Signup = 'Signup',
  Subscription = 'Subscription',
}

export enum EventLabel {
  FormFill = 'FormFill',
  Free = 'Free',
  Paid = 'Paid',
}

// We not decided yet about event payload format
export enum EventType {
  LegacyEvent = 'legacy.event',
  EnableAnalytics = 'event.enableAnalytics',
  VisitedSignup = 'event.visitedSignup',
  VisitedOnboarding = 'event.visitedOnboarding',
  UserIdSet = 'event.userIdSet',
}

export enum CustomEventGTM {
  AccountSignUp = 'account_signup',
  TrialStarted = 'trial_start',
  FirstSeenApiCall = 'first_seen_api_call',
}

type SendEventProps = {
  action: EventAction
  category: EventCategory
  label?: EventLabel
}

type DataLayerProperties = {
  created_at: string // ISO Date string
  trialing: boolean
  paying: boolean
  enterprise: boolean
  region: string
  workspace_count: number
  milestone_1_api_call: boolean
  milestone_100_api_call: boolean
  milestone_subdomain: boolean
  milestone_webhook: boolean
  milestone_filtering: boolean
}

const EMPTY_DATA_LAYER_PROPERTIES: Partial<DataLayerProperties> = {
  created_at: undefined,
  trialing: undefined,
  paying: undefined,
  enterprise: undefined,
  region: undefined,
  workspace_count: undefined,
  milestone_1_api_call: undefined,
  milestone_100_api_call: undefined,
  milestone_subdomain: undefined,
  milestone_webhook: undefined,
  milestone_filtering: undefined,
}

export function updateDataLayerProperties(props: DataLayerProperties) {
  if (IS_TEST_MODE) {
    return
  }

  window.dataLayer && window.dataLayer.push(props)
}

export function resetDataLayerProperties() {
  if (IS_TEST_MODE) {
    return
  }

  window.dataLayer && window.dataLayer.push(EMPTY_DATA_LAYER_PROPERTIES)
}

// Shortcut for window.dataLayer.push with test mode disabling
export function sendLegacyEvent(props: SendEventProps) {
  if (IS_TEST_MODE) {
    return
  }

  window.dataLayer.push({ event: EventType.LegacyEvent, eventProps: { ...props } })
}

// Ref: https://support.google.com/tagmanager/answer/7679219?hl=en
export function sendCustomEventGTM(event: CustomEventGTM) {
  if (IS_TEST_MODE) {
    return
  }

  window.dataLayer.push({ event })
}

export function enableAnalytics() {
  if (IS_TEST_MODE) {
    return
  }

  window.dataLayer.push({ event: EventType.EnableAnalytics, enableAnalytics: true })
}

export function visitedSignup() {
  if (IS_TEST_MODE) {
    return
  }

  window.dataLayer.push({ event: EventType.VisitedSignup, enableAnalytics: true })
}

export function visitedOnboarding() {
  if (IS_TEST_MODE) {
    return
  }

  window.dataLayer.push({ event: EventType.VisitedOnboarding, enableAnalytics: true })
}

export function trackUserId(userId?: string) {
  if (IS_TEST_MODE) {
    return
  }

  if (userId) {
    window.dataLayer.push({ event: EventType.UserIdSet, userId })
  }
}

export function initGTM() {
  if (IS_TEST_MODE) {
    return
  }

  TagManager.initialize({ gtmId: GTM_ID })
}

export function trackSignupEmailEntered() {
  sendLegacyEvent({ action: EventAction.IntentComplete, category: EventCategory.Signup, label: EventLabel.FormFill })
}

export function trackAccountCreated() {
  sendLegacyEvent({ action: EventAction.Create, category: EventCategory.Signup, label: EventLabel.FormFill })
}

export function trackSubscriptionCanceled(type: SubscriptionType) {
  sendLegacyEvent({
    action: EventAction.Cancel,
    category: EventCategory.Subscription,
    label: getSubscriptionEventLabel(type),
  })
}

export function trackTrialCanceled() {
  sendLegacyEvent({
    action: EventAction.CancelTrial,
    category: EventCategory.Subscription,
  })
}

export function trackSubscriptionCreated(type: SubscriptionType) {
  sendLegacyEvent({
    action: EventAction.Create,
    category: EventCategory.Subscription,
    label: getSubscriptionEventLabel(type),
  })
}

export function trackSubscriptionUpgraded() {
  sendLegacyEvent({
    action: EventAction.Upgrade,
    category: EventCategory.Subscription,
  })
}

function getSubscriptionEventLabel(type: SubscriptionType) {
  if (type === SubscriptionType.Free) {
    return EventLabel.Free
  }

  return EventLabel.Paid
}
