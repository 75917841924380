import { Button, ButtonGroup } from '@compass/components'
import { Hash, Percent } from 'lucide-react'

interface DiffTypeSelectorProps {
  value: 'percent' | 'absolute'
  onChange: (type: 'percent' | 'absolute') => void
  disabled: boolean
}

export function DiffTypeSelector({ value, onChange, disabled }: DiffTypeSelectorProps) {
  return (
    <ButtonGroup>
      <Button
        variant='secondary'
        isDisabled={disabled}
        onPress={() => onChange('percent')}
        tone={value === 'percent' ? 'background2' : undefined}
        className={value === 'percent' ? '[&_svg]:text-current shadow-inset-t-black-200' : undefined}
        aria-label='View values as percentages of total number of API calls'
      >
        <Percent />
      </Button>
      <Button
        variant='secondary'
        isDisabled={disabled}
        onPress={() => onChange('absolute')}
        tone={value === 'absolute' ? 'background2' : undefined}
        className={value === 'absolute' ? '[&_svg]:text-current shadow-inset-t-black-200' : undefined}
        aria-label='View values as total number of calls for each smart signal'
      >
        <Hash />
      </Button>
    </ButtonGroup>
  )
}
