import 'swiper/swiper.min.css'

import { ReactElement } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { SubscriptionDisplayPlan } from '../../../../const'
import { Promotion } from '../../../../models'
import { PlanCard } from '../PlanCard/PlanCard'
import styles from './PlanSelector.module.scss'

export interface Plan {
  plan: SubscriptionDisplayPlan
  currentPromotion?: Promotion
  valueLabel: string
  action: string
  actionHref?: string
  onAction?: () => void
  locked?: boolean
  isHidden?: boolean
  topBadge?: string
  priceSubtitleContent?: ReactElement
  oldPrice?: number
}

export interface PlanSelectorProps {
  currentPlan?: SubscriptionDisplayPlan
  isTrial?: boolean
  plans: Plan[]
  isLoading?: boolean
}

export function PlanSelector({ currentPlan, isTrial, plans, isLoading }: PlanSelectorProps) {
  return (
    <Swiper spaceBetween={16} slidesPerView='auto' direction='horizontal' mousewheel className={styles.plans}>
      {plans
        .filter((it) => !it.isHidden)
        .map(({ plan, valueLabel, action, onAction, actionHref, locked, topBadge, priceSubtitleContent, oldPrice }) => (
          <SwiperSlide key={plan} className={styles.plan}>
            <PlanCard
              priceLabel={valueLabel}
              topBadge={topBadge}
              priceSubtitleContent={priceSubtitleContent}
              oldPrice={oldPrice}
              dense
              plan={plan}
              currentPlan={currentPlan}
              action={action}
              onAction={onAction}
              actionHref={actionHref}
              locked={locked}
              isTrialSubscription={isTrial}
              isLoading={isLoading}
              className={styles.planCard}
            />
          </SwiperSlide>
        ))}
    </Swiper>
  )
}
