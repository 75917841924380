import { Button } from '@compass/components'
import { Close } from '@mui/icons-material'
import { IconButton, InputLabel, TextField, Tooltip, Typography } from '@mui/material'
import clsx from 'clsx'
import { BotdVisit, FilterDescriptor } from 'models'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { getErrorParams } from '../../../helpers/data'
import { getMatchingFilter } from '../../../helpers/filter'
import { muiRegister } from '../../../helpers/reactHookForm'
import { requestIdRegex } from '../../../helpers/regex'
import { camelCaseToPascalCase } from '../../../helpers/string'
import { ValueOf } from '../../../helpers/types'
import { BotdVisitFilter } from '../../../hooks/botdVisits'
import { Filter } from '../Filter'
import styles from './BotdFilter.module.scss'

export type BotdFilterFormType = Pick<BotdVisit, 'requestId'>

type BotdFilterFormData = {
  filter: string
}

export interface BotdFilterProps {
  appliedFilter?: BotdVisitFilter
  onFilter: (key?: keyof BotdFilterFormType, value?: ValueOf<BotdFilterFormType>) => void
  className?: string
}

export default function BotdFilter({ appliedFilter, onFilter, className }: BotdFilterProps) {
  const defaultValues = {
    filter: appliedFilter?.value ?? '',
  }

  const [isOpen, setIsOpen] = useState(false)
  const [appliedValue, setAppliedValue] = useState<BotdFilterFormData>(defaultValues)

  const isApplied = !!appliedFilter?.value

  const {
    handleSubmit,
    register,
    reset,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<BotdFilterFormData>({
    defaultValues,
  })

  const filter = watch('filter')
  const filterLabels = filters.map(({ label }) => camelCaseToPascalCase(label)).join(' or ')
  const match = getMatchingFilter(filters, filter)

  function handleOpen() {
    setIsOpen(true)
  }

  function handleClose() {
    clearErrors('filter')
    setIsOpen(false)
  }

  function handleFilter(data: BotdFilterFormData) {
    const matchFilter = getMatchingFilter(filters, data.filter)
    if (matchFilter?.key) {
      onFilter(matchFilter.key, data.filter)
      setAppliedValue(data)

      handleClose()
    }
  }

  function handleClear() {
    reset(defaultValues)
    onFilter()
    setAppliedValue(defaultValues)

    handleClose()
  }

  useEffect(() => {
    // If the filter was changed from the outside, reset the value.
    reset(defaultValues)
    setAppliedValue(defaultValues)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilter])

  return (
    <Filter isOpen={isOpen} onOpen={handleOpen} onClose={handleClose} className={className}>
      <form onSubmit={handleSubmit(handleFilter)} className={styles.container}>
        <header className={styles.header}>
          <Typography variant='semiBody1' component='h1'>
            Filter
          </Typography>
          {isApplied && (
            <Button variant='ghost' onPress={handleClear} className={styles.clearButton}>
              Clear filter
            </Button>
          )}
        </header>

        <InputLabel htmlFor='filter'>{match?.label ?? filterLabels}</InputLabel>
        <TextField
          id='filter'
          autoFocus
          variant='outlined'
          placeholder={`Enter ${filterLabels}`}
          spellCheck={false}
          {...getErrorParams('filter', errors)}
          {...muiRegister(register, 'filter', {
            validate: (value) => (getMatchingFilter(filters, value) ? true : 'Invalid format.'),
          })}
          InputProps={{
            classes: {
              input: clsx({ [styles.monospace]: filter?.length > 0 }),
            },
            endAdornment: appliedValue.filter.length > 0 && (
              <Tooltip title='Clear filter' arrow placement='top'>
                <IconButton onClick={handleClear} title='Clear filter' edge='end' size='small'>
                  <Close />
                </IconButton>
              </Tooltip>
            ),
          }}
          className={styles.input}
        />

        <footer className={styles.footer}>
          <Button variant='ghost' className={styles.textButton} onPress={handleClose} aria-label='Cancel'>
            Cancel
          </Button>
          <Button variant='primary' type='submit' aria-label='Apply filter' className={styles.button}>
            Show
          </Button>
        </footer>
      </form>
    </Filter>
  )
}

const filters: FilterDescriptor<BotdFilterFormType>[] = [
  { key: 'requestId', label: 'Request ID', selector: (data) => requestIdRegex.test(data) },
]
