import { Button, LinkButton } from '@compass/components'
import { AccountCircle, CheckCircleOutlined, Close } from '@mui/icons-material'
import {
  Checkbox,
  Dialog,
  Fade,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { CALENDLY_FEEDBACK_LINK } from 'const'
import { DialogActions, DialogContent, DialogTitle, TextFieldWithCounter } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useToast } from 'hooks'
import { useProductFeedback } from 'hooks/api/support'
import { useCurrentUser } from 'hooks/api/users'
import { ElementType, useState } from 'react'

import alvinAvatar from './alvin.png'
import gabeAvatar from './gabe.png'
import styles from './ProductFeedback.module.scss'

export const ProductFeedback = (props: { triggerComponent: ElementType; triggerProps: any }) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      <props.triggerComponent
        {...props.triggerProps}
        onClick={() => {
          setDialogOpen(true)
        }}
      />

      <ProductFeedbackDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
    </>
  )
}

export const ProductFeedbackDialog = ({
  dialogOpen,
  setDialogOpen,
}: {
  dialogOpen: boolean
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { mutate: submitFeedback, isLoading } = useProductFeedback()
  const [message, setMessage] = useState('')
  const { showToast } = useToast()
  const [error, setError] = useState<string | undefined>()
  const [isCallRequested, setIsCallRequested] = useState(false)
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false)

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const content = (
    <>
      <Typography variant='body1' fontSize={14} marginBottom='4px'>
        How can we improve this page? (this goes to our Slack)
      </Typography>
      <TextFieldWithCounter
        maxSym={500}
        onChange={setMessage}
        placeholder='Message #product-team'
        errorMessage={error}
      />
    </>
  )
  const onConfirm = () => {
    if (message === '') {
      setError('Please enter a message. This is required.')
    } else if (message.length > 500) {
      setError(undefined)
    } else {
      setError(undefined)
      submitFeedback(
        { data: { currentSubscriptionId, message, isCallRequested } },
        {
          onSuccess: () => {
            if (isCallRequested) {
              setIsFeedbackSubmitted(true)
            } else {
              showToast({
                message: 'Thanks! Feedback received.',
                severity: 'success',
              })
              setDialogOpen(false)
            }
          },
          onError: () => {
            showToast({
              message:
                'An error occurred when submitting your feedback. Please contact support if this error persists.',
              severity: 'error',
            })
          },
        }
      )
    }
  }
  const onReject = () => {
    setDialogOpen(false)
  }

  return (
    <Dialog open={dialogOpen} fullScreen={smDown} maxWidth='sm' fullWidth={true} onClose={onReject}>
      <div style={{ opacity: isFeedbackSubmitted ? 0 : 1, transition: 'opacity 0.3s' }}>
        <DialogTitle onClose={onReject}>
          <Typography variant={smDown ? 'h2' : 'h1'}>Give feedback on this page</Typography>
        </DialogTitle>
        {content && <DialogContent>{content}</DialogContent>}
        <DialogActions>
          <Stack justifyContent='space-between' width='100%' direction={{ xs: 'column', sm: 'row' }}>
            <FormControlLabel
              componentsProps={{ typography: { variant: 'bodyS' } }}
              label="I'd love to discuss feedback over Zoom with Fingerprint"
              control={<Checkbox checked={isCallRequested} onChange={(e) => setIsCallRequested(e.target.checked)} />}
            />
            <Button onPress={onConfirm} size='lg' isDisabled={isLoading}>
              Send feedback
            </Button>
          </Stack>
        </DialogActions>
      </div>
      <SuccessMessage onClose={onReject} isFeedbackSubmitted={isFeedbackSubmitted} />
    </Dialog>
  )
}

function SuccessMessage({ onClose, isFeedbackSubmitted }: { onClose: () => void; isFeedbackSubmitted: boolean }) {
  return (
    <Fade in={isFeedbackSubmitted} className={styles.calendlyMessage}>
      <div>
        <IconButton size='small' onClick={onClose} className={styles.closeButton} data-testid='dialog-close-button'>
          <Close />
        </IconButton>
        <Stack alignItems='center' spacing='8px'>
          <CheckCircleOutlined color='success' />
          <Typography variant='h3'>Thanks, feedback received!</Typography>
          <Typography variant='body1' fontSize={14} marginBottom='4px' className={styles.bodyText}>
            If you&apos;re interested in chatting with us, click below to schedule a time.
          </Typography>
          <LinkButton size='lg' className={styles.calendlyButton} href={CALENDLY_FEEDBACK_LINK} target='_blank'>
            Schedule a meeting
          </LinkButton>
          <div className={styles.avatars}>
            <img src={alvinAvatar} alt='' className={styles.avatar} />
            <img src={gabeAvatar} alt='' className={styles.avatar} />
            <span className={styles.userAvatar}>
              <UserInitialsOrIcon />
            </span>
          </div>
          <Typography variant='body1' fontSize={12} className={styles.bodyText}>
            3 guests
          </Typography>
        </Stack>
      </div>
    </Fade>
  )
}

function UserInitialsOrIcon() {
  const { data, isLoading } = useCurrentUser()

  if (isLoading) {
    return null
  }

  if (!data?.name) {
    return <AccountCircle />
  }

  const nameParts = data.name.split(' ')
  const firstLetter = nameParts.shift()?.charAt(0)?.toUpperCase()
  const lastLetter = nameParts.pop()?.charAt(0)?.toUpperCase()

  return (
    <>
      {firstLetter ?? ''}
      {lastLetter ?? ''}
    </>
  )
}
