import { Check, ChevronRight } from 'lucide-react'
import React from 'react'
import {
  composeRenderProps,
  Menu as AriaMenu,
  MenuItem as AriaMenuItem,
  MenuItemProps as AriaMenuItemProps,
  MenuProps as AriaMenuProps,
  Separator,
  SeparatorProps,
  Text,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import { DropdownSection, DropdownSectionProps } from '../Listbox'
import { PopoverProps } from '../Popover'

interface MenuProps<T> extends AriaMenuProps<T> {
  placement?: PopoverProps['placement']
}

const menuStyles = tv({
  base: 'p-1 outline outline-0 max-h-[inherit] overflow-auto [clip-path:inset(0_0_0_0_round_.75rem)]',
})

export function Menu<T extends object>(props: MenuProps<T>) {
  return <AriaMenu {...props} className={menuStyles({ class: props.className })} />
}

interface MenuItemProps<T> extends AriaMenuItemProps<T> {
  variant?: 'destructive'
  description?: string
  prefix?: React.ReactNode
  suffix?: React.ReactNode
}

const menuItemStyles = tv({
  base: [
    'flex',
    'items-center justify-center whitespace-nowrap min-h-9 px-2 py-1',
    'rounded',
    'text-base text-gray-1000',
    'transition-colors duration-100',
    'outline-none',
    'cursor-pointer',
  ],
  variants: {
    variant: {
      destructive: 'text-red-800',
    },
    isOpen: {
      true: 'bg-gray-200',
    },
    isHovered: {
      true: 'bg-gray-200',
    },
    isFocused: {
      true: 'bg-gray-200',
    },
    isFocusVisible: {
      true: 'bg-gray-200',
    },
    isPressed: {
      true: 'bg-gray-200',
    },
    isSelected: {
      true: 'bg-gray-200',
    },
    isDisabled: {
      true: ['disabled:text-gray-600', '[&_svg]:disabled:text-gray-600', 'disabled:cursor-not-allowed'],
    },
  },
})

export function MenuItem<T extends object>({ description, prefix, suffix, ...props }: MenuItemProps<T>) {
  return (
    <AriaMenuItem
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        menuItemStyles({ ...renderProps, variant: props.variant, className })
      )}
    >
      {composeRenderProps(props.children, (children, { selectionMode, isSelected, hasSubmenu }) => (
        <>
          {prefix && <span className='flex-shrink-0 text-gray-800'>{prefix}</span>}
          <span className={`${prefix ? 'ml-2' : ''} flex flex-col flex-1 font-medium truncate`}>
            {children}
            {props.textValue && (
              <Text slot='label' className='text-base font-medium'>
                {props.textValue}
              </Text>
            )}
            {description && (
              <Text slot='description' className='text-2xs text-gray-800 font-normal truncate'>
                {description}
              </Text>
            )}
          </span>
          {suffix && !isSelected && (
            <span className='ml-2 flex-shrink-0 text-gray-900 [&_svg]:w-4 [&_svg]:h-4'>{suffix}</span>
          )}
          {selectionMode !== 'none' && isSelected && (
            <Check size={16} aria-hidden className='flex-shrink-0 text-gray-800' />
          )}
          {hasSubmenu && (
            <ChevronRight
              size={16}
              aria-hidden
              className={`${suffix ? 'ml-2' : 'ml-auto'} flex-shrink-0 text-gray-800`}
            />
          )}
        </>
      ))}
    </AriaMenuItem>
  )
}

export function MenuSeparator(props: SeparatorProps) {
  return <Separator {...props} className='-mx-1 my-1 border-b border-gray-300 dark:border-zinc-700' />
}

export function MenuSection<T extends object>(props: DropdownSectionProps<T>) {
  return <DropdownSection {...props} />
}
