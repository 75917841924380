import { TimerOutlined } from '@mui/icons-material'
import { AppRoute, buildRoute } from 'appRoutes'
import { PaymentMethods } from 'components'
import DashboardBanner from 'components/DashboardBanner/DashboardBanner'
import { GenericError } from 'const'
import { Header, MainColumn, SettingsLayout } from 'features/commonUI'
import { humanizeTimeToDate } from 'helpers/date'
import {
  AddressFormat,
  BillingDetails,
  BillingInfo,
  ExpandedSubscription,
  InvoiceListItem,
  OrderLowercase,
  PaymentMethod,
  SubscriptionStatus,
} from 'models'

import BillingInfoCard from '../BillingInfoCard/BillingInfoCard'
import BillingPlanDetails, { SubscriptionBillingDetails } from '../BillingPlanDetails/BillingPlanDetails'
import { ConditionalOverduePaymentCallout } from '../ConditionalOverduePaymentCallout/ConditionalOverduePaymentCallout'
import EditBillingInfoDialog from '../EditBillingInfoDialog/EditBillingInfoDialog'
import InvoiceList from '../InvoiceList/InvoiceList'

export interface BillingProps {
  isPaymentMethodsLoading: boolean
  isAddPaymentMethodDialogOpen: boolean
  isEditBillingInfoDialogOpen: boolean
  paymentMethods: PaymentMethod[]
  paymentMethodsError: GenericError | null
  isAddingPaymentMethod: boolean
  isLoadingBillingInfo?: boolean
  isUpdatingBillingInfo?: boolean

  invoices: InvoiceListItem[]
  isLoadingInvoices: boolean
  orderInvoices?: 'asc' | 'desc'
  sortInvoicesBy?: keyof InvoiceListItem
  invoicesPage: number
  invoicesRowsPerPage: number
  totalInvoicesCount: number
  billingInfo?: BillingInfo
  addressFormat?: AddressFormat

  onAddNewPaymentClick: () => void
  onPaymentMethodDialogCancel: () => void
  onEditBillingInfoClick: () => void
  onEditBillingInfoDialogCancel: () => void
  onMakePaymentMethodDefault: (id: string) => void
  onAddPaymentMethod: (billingDetails: BillingDetails) => void
  onDeletePaymentMethod: (id: string) => void
  onUpdateBillingInfo: (newBillingInfo: Partial<BillingInfo>) => void

  onInvoicesSort: (orderBy: keyof InvoiceListItem, order: OrderLowercase) => void
  onInvoicesPageChange: (page: number) => void

  updateBillingInfoError?: GenericError | null

  subscriptionBillingDetails?: SubscriptionBillingDetails[]
  hasPaidSubscriptions?: boolean

  trialSubscription?: ExpandedSubscription
  hasActiveNonTrialSubscriptions?: boolean
}

export default function Billing({
  isPaymentMethodsLoading,
  isAddPaymentMethodDialogOpen,
  isEditBillingInfoDialogOpen,
  paymentMethods,
  paymentMethodsError,
  isAddingPaymentMethod,
  isLoadingBillingInfo,

  invoices,
  isLoadingInvoices,
  orderInvoices,
  sortInvoicesBy,
  invoicesPage,
  invoicesRowsPerPage,
  totalInvoicesCount,
  billingInfo,
  addressFormat = 'other',

  onAddNewPaymentClick,
  onPaymentMethodDialogCancel,
  onMakePaymentMethodDefault,
  onEditBillingInfoClick,
  onEditBillingInfoDialogCancel,
  onAddPaymentMethod,
  onDeletePaymentMethod,
  onUpdateBillingInfo,
  onInvoicesSort,
  onInvoicesPageChange,

  updateBillingInfoError,

  subscriptionBillingDetails,
  hasPaidSubscriptions,
  trialSubscription,
  hasActiveNonTrialSubscriptions,
}: BillingProps) {
  return (
    <>
      <Header title='Settings' />
      <MainColumn>
        <SettingsLayout>
          {trialSubscription && !hasActiveNonTrialSubscriptions && (
            <>
              {trialSubscription.status !== SubscriptionStatus.Canceled ? (
                <DashboardBanner
                  variant='warning'
                  icon={<TimerOutlined color='primary' />}
                  title={`Your free trial ends in ${humanizeTimeToDate(trialSubscription.trialEndAt, false)}`}
                  action='View plans'
                  actionUrl={buildRoute(AppRoute.SubscriptionPlan, { subscriptionId: trialSubscription.id })}
                >
                  To keep accurately identifying your traffic across web and mobile, choose a plan.
                </DashboardBanner>
              ) : (
                <DashboardBanner
                  variant='warning'
                  icon={<TimerOutlined color='primary' />}
                  title='Your free trial has ended'
                  action='View plans'
                  actionUrl={buildRoute(AppRoute.SubscriptionPlan, { subscriptionId: trialSubscription.id })}
                >
                  To keep on accurately identifying your traffic across web and mobile, choose a plan today.
                </DashboardBanner>
              )}
            </>
          )}

          <ConditionalOverduePaymentCallout linkToBillingPage={false} />

          {subscriptionBillingDetails && !!subscriptionBillingDetails.length && (
            <BillingPlanDetails subscriptions={subscriptionBillingDetails} />
          )}

          {hasPaidSubscriptions && (
            <BillingInfoCard
              billingInfo={billingInfo}
              onEdit={onEditBillingInfoClick}
              isLoading={isLoadingBillingInfo}
            />
          )}

          <PaymentMethods
            isLoading={isPaymentMethodsLoading}
            isAddingPaymentMethod={isAddingPaymentMethod}
            addDialogIsOpen={isAddPaymentMethodDialogOpen}
            paymentMethods={paymentMethods}
            error={paymentMethodsError}
            onAddNewPaymentClick={onAddNewPaymentClick}
            onDialogCancel={onPaymentMethodDialogCancel}
            onMakeDefault={onMakePaymentMethodDefault}
            onAddPaymentMethod={onAddPaymentMethod}
            onDelete={onDeletePaymentMethod}
          />

          <InvoiceList
            isLoading={isLoadingInvoices}
            invoices={invoices}
            order={orderInvoices}
            sortBy={sortInvoicesBy}
            page={invoicesPage}
            totalCount={totalInvoicesCount ?? invoicesRowsPerPage}
            rowsPerPage={invoicesRowsPerPage}
            onSort={onInvoicesSort}
            onPageChange={onInvoicesPageChange}
          />
        </SettingsLayout>
      </MainColumn>

      <EditBillingInfoDialog
        format={addressFormat}
        billingInfo={billingInfo}
        open={isEditBillingInfoDialogOpen}
        onClose={onEditBillingInfoDialogCancel}
        onUpdate={onUpdateBillingInfo}
        error={updateBillingInfoError}
        isLoading={isLoadingBillingInfo}
      />
    </>
  )
}
