import { type ClassValue, default as clsx } from 'clsx'
import { composeRenderProps } from 'react-aria-components'
import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const focusRing = tv({
  base: 'outline outline-primary invalid:outline-red-500 dark:outline-orange-500 forced-colors:outline-primary transition-colors ease duration-100',
  variants: {
    isFocused: {
      true: 'focus:outline-2 outline-offset-0 focus:border-primary focus:outline-orange-500 invalid:border-red-800 invalid:focus:border-red-800',
    },
    isFocusVisible: {
      false: 'outline-0',
      true: 'outline-2 outline-offset-2 focus:outline-orange-700',
    },
  },
})

export function composeTailwindRenderProps<T>(
  className: string | ((v: T) => string) | undefined,
  tw: string
): string | ((v: T) => string) {
  return composeRenderProps(className, (classn) => twMerge(tw, classn))
}
