import { Box, CircularProgress, CircularProgressProps } from '@mui/material'
import { ReactElement } from 'react'

import styles from './CooldownProgress.module.scss'

export function CooldownProgress(props: CircularProgressProps & { value: number; icon: ReactElement }) {
  return (
    <Box className={styles.container}>
      <CircularProgress
        size={24}
        color='inherit'
        variant='determinate'
        data-testid='loader'
        {...props}
        className={styles.progress}
      />
      <Box className={styles.icon}>{props.icon}</Box>
    </Box>
  )
}
