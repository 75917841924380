import { Button, Flex, Stack } from '@compass/components'
import { Paper, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import clsx from 'clsx'
import CardDescription from 'components/CardDescription/CardDescription'
import CardLogo from 'components/CardLogo'
import { GenericError } from 'const'
import { SubHeader } from 'features/commonUI'
import { useLogPageView } from 'helpers/vendor'
import { usePermissions } from 'hooks/permissions'
import { Plus, TrashIcon } from 'lucide-react'
import { BillingDetails, PaymentMethod } from 'models'
import { ampli } from 'models/ampli'
import { memo } from 'react'

import Loader from '../Loader/Loader'
import NewMethodDialog from './NewMethodDialog'
import style from './PaymentMethods.module.scss'

export interface PaymentMethodsProps {
  className?: string
  isLoading: boolean
  isAddingPaymentMethod: boolean
  paymentMethods: PaymentMethod[]
  addDialogIsOpen: boolean
  error: GenericError | null
  onAddNewPaymentClick: () => void
  onDialogCancel: () => void
  onMakeDefault: (id: string) => void
  onAddPaymentMethod: (billingDetails: BillingDetails) => void
  onDelete: (id: string) => void
}

export default memo(function PaymentMethods({
  className,
  isLoading,
  isAddingPaymentMethod,
  paymentMethods,
  addDialogIsOpen,
  error,
  onAddNewPaymentClick,
  onDialogCancel,
  onAddPaymentMethod,
  onMakeDefault,
  onDelete,
}: PaymentMethodsProps) {
  const { isReadOnly } = usePermissions()
  useLogPageView(() => ampli.paymentMethodPageViewed())

  return (
    <div className='flex flex-col gap-4'>
      <SubHeader
        title='Payment methods'
        description='Make sure your payment methods are up to date to avoid service interruptions in your workspaces.'
        actions={
          <Button
            type='submit'
            onPress={onAddNewPaymentClick}
            isDisabled={isReadOnly}
            data-testid='addPaymentMethodButton'
          >
            <Plus />
            Add payment method
          </Button>
        }
      />
      <Paper className={clsx(style.root, className)}>
        <Table>
          <TableBody>
            {paymentMethods.length === 0 ? (
              <TableRow>
                <TableCell align='center' colSpan={2} className='px-0'>
                  <Typography>No payment methods</Typography>
                </TableCell>
              </TableRow>
            ) : (
              paymentMethods
                .sort((a, b) => (a.id > b.id ? 1 : -1))
                .map((paymentMethod) => (
                  <TableRow key={paymentMethod.id}>
                    <TableCell className='px-0'>
                      <Stack gap={2}>
                        <CardLogo cardBrand={paymentMethod.cardBrand} />
                        <CardDescription paymentMethod={paymentMethod} />
                      </Stack>
                    </TableCell>
                    <TableCell align='right' className='px-0'>
                      <Flex row className='justify-end gap-2'>
                        <Button
                          variant='secondary'
                          type='submit'
                          aria-label='Set as default'
                          isDisabled={isReadOnly || paymentMethod.isDefault}
                          onPress={() => onMakeDefault(paymentMethod.id)}
                        >
                          Set default
                        </Button>
                        <Button
                          variant='ghost'
                          aria-label='Remove payment method'
                          isDisabled={isReadOnly}
                          onPress={() => onDelete(paymentMethod.id)}
                          isIcon
                        >
                          <TrashIcon />
                        </Button>
                      </Flex>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>

        {isLoading && <Loader testId='payment-methods-loader' />}
      </Paper>
      <NewMethodDialog
        open={addDialogIsOpen}
        isLoading={isAddingPaymentMethod}
        error={error}
        onAddPaymentMethod={onAddPaymentMethod}
        onCancel={onDialogCancel}
      />
    </div>
  )
})
