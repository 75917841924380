import { ButtonOrLink } from '@compass/ButtonOrLink'
import { Check } from '@mui/icons-material'
import { List, ListItem, ListItemIcon, ListItemText, Paper, Skeleton, Typography } from '@mui/material'
import clsx from 'clsx'
import { BaseReactProps } from 'helpers/types'
import { CSSProperties, ReactElement } from 'react'

import {
  PLAN_FEATURES,
  SUBSCRIPTION_DISPLAY_PLANS,
  SUBSCRIPTION_PLAN_PRICES,
  SubscriptionDisplayPlan,
} from '../../../../const'
import { formatPriceInDollars } from '../../../../helpers/format'
import styles from './PlanCard.module.scss'

export interface PlanCardProps extends BaseReactProps {
  plan: SubscriptionDisplayPlan
  currentPlan?: SubscriptionDisplayPlan
  priceLabel?: string
  variant?: 'contained' | 'outlined'
  action?: string
  onAction?: () => void
  actionHref?: string
  locked?: boolean
  dense?: boolean
  isTrialSubscription?: boolean
  isLoading?: boolean
  isHighlighted?: boolean
  topBadge?: string
  priceSubtitleContent?: ReactElement
  oldPrice?: number
}

export function PlanCard({
  plan,
  currentPlan,
  priceLabel,
  variant,
  action,
  onAction,
  actionHref,
  locked,
  isTrialSubscription,
  dense,
  isLoading,
  className,
  topBadge,
  priceSubtitleContent,
  oldPrice,
}: PlanCardProps) {
  interface ActionProps {
    to?: string
    href?: string
    onPress?: () => void
  }

  const price = SUBSCRIPTION_PLAN_PRICES[plan]
  const selected = currentPlan && plan === currentPlan
  const isDowngrade = currentPlan && plan < currentPlan
  let actionName = action
  if (isLoading) {
    actionName = 'Loading'
  } else if (selected && !isTrialSubscription) {
    actionName = 'Current plan'
  } else if (isDowngrade && !isTrialSubscription) {
    actionName = currentPlan === SubscriptionDisplayPlan.Enterprise ? 'Included' : 'Downgrade'
  }

  const actionProps: ActionProps = {
    onPress: onAction,
    href: actionHref,
  }

  return (
    <Paper className={clsx(styles.plan, { [styles.selected]: selected }, className)}>
      {topBadge && (
        <div className={styles.upperBadge}>
          <span>{topBadge}</span>
        </div>
      )}
      <Typography variant='semiBody2' component='h3' className={styles.heading}>
        {SUBSCRIPTION_DISPLAY_PLANS[plan].name}
      </Typography>

      {priceLabel && (
        <Typography variant='body3' component='span' className={styles.label}>
          {isLoading ? <Skeleton width={96} /> : priceLabel}
        </Typography>
      )}

      <Typography variant='h2' component='span' className={clsx(styles.price)}>
        {oldPrice && (
          <Typography variant='h2' component='span' className={styles.oldPrice}>
            {isLoading ? <Skeleton width={40} /> : formatPriceInDollars(oldPrice, false)}
          </Typography>
        )}
        {isLoading ? <Skeleton width={64} /> : <Price price={price} />}
      </Typography>

      {priceSubtitleContent ? (
        <Typography variant='bodyS'>{priceSubtitleContent}</Typography>
      ) : (
        <span className={styles.marginTop} />
      )}

      <List dense disablePadding className={styles.list}>
        {PLAN_FEATURES[plan].map(({ name, badge }) => (
          <ListItem key={name} disableGutters disablePadding={dense}>
            <ListItemIcon className={clsx(styles.icon)}>{<Check />}</ListItemIcon>

            <ListItemText
              style={{ margin: 0 }}
              primary={
                <>
                  <Typography component='span' className={clsx(styles.listItemText)}>
                    {name}
                  </Typography>
                  {badge && (
                    <Typography variant='body3' component='span' className={styles.badge}>
                      {badge}
                    </Typography>
                  )}
                </>
              }
              primaryTypographyProps={{ variant: 'body3' }}
            />
          </ListItem>
        ))}
      </List>

      {action && (
        <ButtonOrLink
          {...actionProps}
          variant={(variant ?? (!actionProps.href && (isDowngrade || price === 'custom'))) ? 'outline' : undefined}
          fullWidth
          isDisabled={isLoading || (selected && !isTrialSubscription) || locked}
          data-testid={`subscription-plan-action-${plan}`}
          className={styles.button}
          size='lg'
        >
          {actionName}
        </ButtonOrLink>
      )}
    </Paper>
  )
}

interface PriceProps {
  price: number | 'custom'
  labelStyles?: CSSProperties
}

function Price({ price, labelStyles }: PriceProps) {
  if (price === 'custom') {
    return <>Custom pricing</>
  }

  return (
    <>
      {formatPriceInDollars(price, false)}
      <Typography variant='body2' component='span' style={{ ...labelStyles, color: '#999' }}>
        /month
      </Typography>
    </>
  )
}
