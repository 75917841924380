import { Breakpoint, Paper, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'
import { PropsWithChildren } from 'react'

export interface ResponsivePaperProps {
  breakpoint?: Breakpoint | number
  className?: string
}

export default function ResponsivePaper({ breakpoint, className, children }: PropsWithChildren<ResponsivePaperProps>) {
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down(breakpoint ?? 'sm'))

  return fullWidth ? <div className={className}>{children}</div> : <Paper className={className}>{children}</Paper>
}
