import { Button, LinkButton } from '@compass/components'
import { Alert, Link } from '@mui/material'
import { AppRoute } from 'appRoutes'
import { CreatePasswordInput, StaticMessage } from 'components'
import { APITypesMap, GenericError } from 'const'
import { errorWithFallback } from 'helpers/data'
import { useIsFirstRender } from 'hooks/firstRender'
import { FormProvider, useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'

import { AuthForm } from '../AuthForm/AuthForm'
import styles from '../AuthForm/AuthForm.module.scss'
import { AuthWrapper } from '../AuthWrapper/AuthWrapper'
import { Props } from '../types'

export type FormData = APITypesMap['passwordResetConfirm']['body']
export type ResetPasswordProps = {
  resetSuccess: boolean
  validationError: GenericError | null
}

export function ResetPasswordConfirmForm({
  onSubmit,
  error,
  isLoading, // loading reset confirm query
  resetSuccess,
  validationError,
}: Props<FormData> & ResetPasswordProps) {
  const formMethods = useForm<FormData>({ mode: 'onChange' })
  const {
    handleSubmit,
    formState: { isValid: isFormValid },
  } = formMethods
  const isFirstRender = useIsFirstRender()

  // should show error page if validationError contains "Not found" OR if we have password reset query error
  // TODO: make this prettier
  if (error || validationError?.message === 'Not found') {
    if (validationError && !error) {
      error = {
        // make this prettier in particular
        ...validationError,
        code: 'value_invalid',
      }
    }
    const message = error?.code === 'value_invalid' ? 'The link you followed is no longer valid.' : error?.message
    return (
      <StaticMessage
        Icon={null}
        title='Invalid Link'
        message={
          <span className={styles.reset}>
            {message}{' '}
            <Link component={RouterLink} to={{ pathname: AppRoute.PasswordReset }} underline='hover'>
              Forgot password?
            </Link>
          </span>
        }
      />
    )
  }

  if (resetSuccess) {
    return (
      <AuthWrapper isLoading={isLoading} title='Create New Password'>
        <Alert severity='success' className={styles.success} data-testid='password_update_success'>
          Password updated successfully.
        </Alert>
        <LinkButton
          aria-label='Go to log in'
          variant='outline'
          data-testid='goto_login'
          href={AppRoute.Login}
          size='lg'
          fullWidth
        >
          Go to log in
        </LinkButton>
      </AuthWrapper>
    )
  }

  return (
    <AuthWrapper title='Create new password'>
      <AuthForm onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...formMethods}>
          <CreatePasswordInput
            autoFocus={!isLoading}
            error={errorWithFallback(validationError, 'password', 'Unable to set new password')}
          />

          <Button
            isDisabled={isFirstRender || !isFormValid || isLoading}
            className={styles.button}
            data-testid='setNewPasswordButton'
            aria-label='Continue'
            size='lg'
            type='submit'
            fullWidth
          >
            Continue
          </Button>
        </FormProvider>
      </AuthForm>
    </AuthWrapper>
  )
}
