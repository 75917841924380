import { PropsWithChildren } from 'react'
import { Modal, ModalOverlay } from 'react-aria-components'
import { tv, VariantProps } from 'tailwind-variants'

const sidebarStyle = tv({
  slots: {
    overlay: [
      'fixed top-0 left-0 w-full h-[--visual-viewport-height] isolate z-[1201] flex items-center justify-center p-4 text-center bg-black/70',
      'entering:animate-in entering:fade-in entering:duration-300 entering:ease-in-out-cubic',
      'exiting:animate-out exiting:fade-out exiting:duration-300 exiting:ease-in-out-cubic',
    ],
    container: ['h-[--visual-viewport-height] flex flex-col '],
  },
  variants: {
    variant: {
      persistent: {
        container: ['sticky top-0 bottom-0'],
      },
      floating: {
        container: ['fixed top-0 bottom-0'],
      },
    },
    position: {
      left: {
        container: ['left-0'],
      },
      right: {
        container: ['right-0'],
      },
    },
  },
  compoundSlots: [
    {
      slots: ['container'],
      variant: 'floating',
      position: 'left',
      className: [
        'entering:animate-appear-left entering:duration-300 entering:ease-in-out-cubic',
        'exiting:animate-disappear-left exiting:duration-300 exiting:ease-in-out-cubic',
      ],
    },
  ],
  defaultVariants: {
    variant: 'persistent',
    position: 'left',
  },
})

type SidebarProps = VariantProps<typeof sidebarStyle> & {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  className?: string
}

export function Sidebar({ children, variant, isOpen, onOpenChange, className }: PropsWithChildren<SidebarProps>) {
  const { container, overlay } = sidebarStyle({ variant })

  return variant === 'floating' ? (
    <ModalOverlay isDismissable className={overlay()} isOpen={!!isOpen} onOpenChange={onOpenChange}>
      <Modal className={container({ className })}>{children}</Modal>
    </ModalOverlay>
  ) : (
    <aside className={container({ className })}>{children}</aside>
  )
}

;<div className='entering:animate-in entering:fade-in' />
