import { LinkButton } from '@compass/LinkButton'
import { Alert, Paper, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material'
import DashboardBanner from 'components/DashboardBanner/DashboardBanner'
import { SupportEmail } from 'components/SupportEmail'
import { ContentColumn, SubHeader } from 'features/commonUI'
import { usePermissions } from 'hooks'
import { Plus } from 'lucide-react'
import { ampli } from 'models/ampli'

import { AppRoute, buildRoute } from '../../../../appRoutes'
import ApplicationsCompactTable from '../ApplicationsCompactTable/ApplicationsCompactTable'
import ApplicationsList from '../ApplicationsList/ApplicationsList'
import ApplicationsTable from '../ApplicationsTable/ApplicationsTable'
import styles from './ApplicationsOverview.module.scss'
import { ApplicationsOverviewSubscriptionDetails } from './ApplicationsOverviewData'

export type ApplicationsOverviewProps = {
  isLoading?: boolean
  isError?: boolean
  subscriptions?: ApplicationsOverviewSubscriptionDetails[]
}

export default function ApplicationsOverview({ subscriptions, isLoading, isError }: ApplicationsOverviewProps) {
  const theme = useTheme()

  const displayFullTable = useMediaQuery(theme.breakpoints.up('lg'))
  const displayCompactTable = useMediaQuery(theme.breakpoints.between('sm', 'lg'))
  const displayList = useMediaQuery(theme.breakpoints.down('sm'))

  const displayLoadingState = !subscriptions && isLoading
  const displayErrorState = !isLoading && isError

  const { isReadOnly, subscriptionPermissions } = usePermissions()

  return (
    <>
      <ContentColumn>
        <SubHeader
          title='Workspaces'
          description='Region-specific workspaces that you are managing within your Fingerprint account.'
          actions={
            !isReadOnly && subscriptionPermissions.canCreate(subscriptions) ? (
              <LinkButton href={buildRoute(AppRoute.AddSecondaryApplication)}>
                <Plus />
                New workspace
              </LinkButton>
            ) : null
          }
        />
        <div>
          {displayLoadingState && <Skeleton height='100px' />}
          {displayErrorState && (
            <div className={styles.errorSection}>
              <Alert severity='error'>
                Something went wrong. Please try again later or contact <SupportEmail />
              </Alert>
            </div>
          )}

          {!!subscriptions && (
            <Paper className='p-4'>
              {displayFullTable && <ApplicationsTable subscriptions={subscriptions} />}
              {displayCompactTable && <ApplicationsCompactTable subscriptions={subscriptions} />}
              {displayList && <ApplicationsList subscriptions={subscriptions} />}
            </Paper>
          )}
        </div>
      </ContentColumn>

      <div className={styles.bannerSection}>
        <DashboardBanner
          variant='info'
          title={
            <Typography variant='h3'>Need additional security, support, and enterprise-scale features?</Typography>
          }
          action='Talk to Sales'
          actionUrl={AppRoute.ContactSalesEnterprise}
          onClickAction={() => {
            ampli.appCtaClicked({ 'App Page Path': AppRoute.WorkspaceList, ctaName: 'Talk to Sales' })
          }}
        >
          <Typography variant='bodyM'>Talk to our Sales team to learn more about Enterprise pricing.</Typography>
        </DashboardBanner>
      </div>
    </>
  )
}
