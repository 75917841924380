import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo, useState } from 'react'

export function usePaginationForQuery<ResultType, ErrorType, ValueType>(
  query: UseInfiniteQueryResult<ResultType, ErrorType>,
  extractor: (result: ResultType) => ValueType[]
) {
  const { data, fetchNextPage, hasNextPage } = query
  const pages = data?.pages
  const dataLength = pages?.length ?? 0
  const lastPageIsEmpty =
    pages !== undefined && pages[pages.length - 1] !== undefined && extractor(pages[pages.length - 1]).length === 0
  const lastIndexWithData = data === undefined ? 0 : lastPageIsEmpty ? Math.max(0, dataLength - 2) : dataLength - 1

  const [currentPageNumber, setCurrentPageNumber] = useState(0)
  useEffect(() => setCurrentPageNumber(lastIndexWithData), [lastIndexWithData])
  const values = useMemo(
    () => pages?.[currentPageNumber] && extractor(pages[currentPageNumber]),
    [currentPageNumber, pages, extractor]
  )

  const goToPreviousPage = useCallback(() => setCurrentPageNumber((p) => Math.max(0, p - 1)), [])
  const goToNextPage = useCallback(() => {
    if (currentPageNumber === dataLength - 1) {
      fetchNextPage()
    } else {
      setCurrentPageNumber((p) => p + 1)
    }
  }, [currentPageNumber, dataLength, fetchNextPage])

  const hasPreviousEntries = currentPageNumber > 0
  const hasNextEntries = currentPageNumber < lastIndexWithData || (!lastPageIsEmpty && hasNextPage)

  return {
    values,
    currentPageNumber,
    pageCount: lastIndexWithData,
    hasPreviousEntries,
    goToPreviousPage,
    hasNextEntries,
    goToNextPage,
  }
}
