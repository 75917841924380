import { Alert, Link } from '@mui/material'
import { ReactNode } from 'react'

import { SUPPORT_EMAIL_MAILTO } from '../../../const'
import styles from './WizardError.module.scss'

export interface WizardErrorProps {
  error: { message?: string | ReactNode }
}

export default function WizardError({ error }: WizardErrorProps) {
  return (
    <Alert severity='error' className={styles.alert}>
      {error.message ?? (
        <>
          Something went wrong, please try again later or{' '}
          <Link href={SUPPORT_EMAIL_MAILTO} underline='none'>
            contact support
          </Link>
          .
        </>
      )}
    </Alert>
  )
}
