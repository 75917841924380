import { Stack } from '@compass/components'
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt'
import { Divider, Grid, Typography } from '@mui/material'
import clsx from 'clsx'
import { toCustomDateRange, useDefaultPredefinedRanges } from 'features/commonUI'
import { DateTime, Duration } from 'luxon'
import { useState } from 'react'

import { CustomDateRange, DateRange, parseOptionalDate, PredefinedRange } from '../../helpers/dateRange'
import Month from './components/Month/Month'
import styles from './DateRangePicker.module.scss'
import DefinedRanges from './DefinedRanges'
import { Marker, NavigationAction } from './types'

export interface DateRangePickerProps {
  initialDateRange?: PredefinedRange | CustomDateRange
  definedRanges?: PredefinedRange[]
  initialMinDate?: DateTime
  initialMaxDate?: DateTime
  maxRange?: Duration
  today: DateTime
  className?: string | string[]
  onChange: (dateRange: CustomDateRange | PredefinedRange) => void
}

export default function DateRangePicker({
  initialDateRange,
  initialMinDate,
  initialMaxDate,
  maxRange = Duration.fromDurationLike({ years: 1 }),
  today,
  definedRanges,
  className,
  onChange,
}: DateRangePickerProps) {
  const [minDateValid, setMinDateValid] = useState(getDefaultMinDate(initialMinDate, today))
  const [maxDateValid, setMaxDateValid] = useState(getDefaultMaxDate(initialMaxDate, today))
  const [initialFirstMonth, initialSecondMonth] = getValidatedMonths(initialDateRange || {}, minDateValid, maxDateValid)

  const { ranges } = useDefaultPredefinedRanges()
  const dateRanges = definedRanges ?? Object.values(ranges)

  const [dateRange, setDateRange] = useState<DateRange>(initialDateRange ?? dateRanges[0])
  const [hoverDay, setHoverDay] = useState<DateTime>()
  const [firstMonth, setFirstMonth] = useState<DateTime>(initialFirstMonth || today.minus({ months: 1 }))
  const [secondMonth, setSecondMonth] = useState<DateTime>(initialSecondMonth || today)

  const setFirstMonthValidated = (date: DateTime) => {
    setFirstMonth(date)
    if (date >= secondMonth) {
      setSecondMonth(date.plus({ months: 1 }))
    }
  }

  const setSecondMonthValidated = (date: DateTime) => {
    setSecondMonth(date)
    if (date <= firstMonth) {
      setFirstMonth(date.minus({ months: 1 }))
    }
  }

  const setDefinedRange = (range: PredefinedRange) => {
    setDateRange(range)
    onChange(range)

    setFirstMonth(
      range.startDate.hasSame(range.endDate, 'month') ? range.startDate.minus({ month: 1 }) : range.startDate
    )
    setSecondMonth(range.endDate)
  }

  const onDayClick = (day: DateTime, marker: Marker) => {
    const isOnlyOneDateSelected = !!dateRange.startDate !== !!dateRange.endDate

    if (isOnlyOneDateSelected) {
      let newRange: Required<DateRange>

      if (dateRange.startDate) {
        const endDay = day.endOf('day')
        newRange = { startDate: dateRange.startDate.startOf('day'), endDate: endDay > today ? today : endDay }
      } else {
        const endDay = dateRange.endDate?.endOf('day') ?? today.endOf('day')
        newRange = { startDate: day.startOf('day'), endDate: endDay > today ? today : endDay }
      }

      onChange(toCustomDateRange(newRange))
      setDateRange(toCustomDateRange(newRange))
      setMinDateValid(getDefaultMinDate(initialMinDate, today))
      setMaxDateValid(getDefaultMaxDate(initialMaxDate, today))
    } else {
      if (maxRange) {
        if (marker === Marker.FirstMonth) {
          const newMaxDate = day.plus(maxRange)
          setMaxDateValid(newMaxDate)

          if (secondMonth > newMaxDate) {
            setSecondMonth(newMaxDate)
          }
        } else {
          const newMinDate = day.minus(maxRange)

          if (!initialMinDate || initialMinDate < newMinDate) {
            setMinDateValid(newMinDate)
          }

          if (firstMonth < newMinDate) {
            setFirstMonth(newMinDate)
          }
        }
      }
      setDateRange({ startDate: day, endDate: undefined })
    }
    setHoverDay(day)
  }

  const onMonthNavigate = (marker: Marker, action: NavigationAction) => {
    if (marker === Marker.FirstMonth) {
      const firstNew = firstMonth.plus({ months: action })
      if (firstNew < secondMonth) {
        setFirstMonth(firstNew)
      }
    } else {
      const secondNew = secondMonth.plus({ months: action })
      if (firstMonth < secondNew) {
        setSecondMonth(secondNew)
      }
    }
  }

  const onDayHover = (date: DateTime) => {
    if (dateRange.startDate && !dateRange.endDate && (!hoverDay || !date.hasSame(hoverDay, 'day'))) {
      setHoverDay(date)

      if (date < dateRange.startDate) {
        setDateRange({ startDate: undefined, endDate: dateRange.startDate })
      }
    } else if (dateRange.endDate && !dateRange.startDate && (!hoverDay || !date.hasSame(hoverDay, 'day'))) {
      setHoverDay(date)

      if (date > dateRange.endDate) {
        setDateRange({ startDate: dateRange.endDate, endDate: undefined })
      }
    }
  }

  // helpers
  const inHoverRange = (day: DateTime) => {
    if (dateRange.startDate && dateRange.endDate) {
      return day >= dateRange.startDate && day <= dateRange.endDate
    } else if (dateRange.startDate && hoverDay) {
      return day >= dateRange.startDate && day <= hoverDay
    } else if (dateRange.endDate && hoverDay) {
      return day >= hoverDay && day <= dateRange.endDate
    } else {
      return false
    }
  }

  const helpers = {
    inHoverRange,
  }

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate,
  }

  const canNavigateCloser = secondMonth.diff(firstMonth, 'months').months >= 2
  const canNavigateFirstMonthBack = firstMonth >= minDateValid
  const canNavigateSecondMonthForward = secondMonth <= maxDateValid
  const commonProps = {
    dateRange,
    minDate: minDateValid,
    maxDate: maxDateValid,
    helpers,
    handlers,
  }
  const headerDateFormat = 'MMMM dd, yyyy'

  return (
    <div data-testid='date-range-picker' className={clsx(styles.dateRangePicker, className)}>
      <Stack direction={{ default: 'column', sm: 'row' }}>
        <div>
          <Stack
            direction={{ default: 'column', md: 'row' }}
            className='p-4 items-center md:items-start md:px-16 md:py-6 justify-between'
          >
            <Typography variant='subtitle1' data-testid={`date-range-picker-first-month-header`}>
              {dateRange.startDate?.toFormat(headerDateFormat) ?? 'Start Date'}
            </Typography>
            <div>
              <ArrowRightAlt color='action' />
            </div>
            <Typography variant='subtitle1' data-testid={`date-range-picker-second-month-header`}>
              {dateRange.endDate?.toFormat(headerDateFormat) ?? 'End Date'}
            </Typography>
          </Stack>
          <Divider />
          <Stack direction={{ default: 'column', md: 'row' }}>
            <Month
              {...commonProps}
              date={firstMonth}
              setDate={setFirstMonthValidated}
              canNavBack={canNavigateFirstMonthBack}
              canNavForward={canNavigateCloser}
              marker={Marker.FirstMonth}
            />
            <div className={styles.divider} />
            <Month
              {...commonProps}
              date={secondMonth}
              setDate={setSecondMonthValidated}
              canNavBack={canNavigateCloser}
              canNavForward={canNavigateSecondMonthForward}
              marker={Marker.SecondMonth}
            />
          </Stack>
        </div>
        <div className={styles.divider} />
        <Grid>
          <DefinedRanges ranges={dateRanges} setRange={(range) => setDefinedRange(range)} />
        </Grid>
      </Stack>
    </div>
  )
}

const getDefaultMinDate = (minDate: DateTime | undefined, today: DateTime) =>
  parseOptionalDate(minDate, today.minus({ years: 1 }))

const getDefaultMaxDate = (maxDate: DateTime | undefined, today: DateTime) => parseOptionalDate(maxDate, today)

function getValidatedMonths(range: DateRange, minDate: DateTime, maxDate: DateTime) {
  const { startDate, endDate } = range
  if (startDate && endDate) {
    const newStart = DateTime.max(startDate, minDate)
    const newEnd = DateTime.min(endDate, maxDate)

    return [newStart.hasSame(newEnd, 'month') ? newStart.minus({ month: 1 }) : newStart, newEnd]
  }
  return [startDate, endDate]
}
