import { useCallback, useState } from 'react'
import { DeepPartial } from 'react-hook-form'

import {
  AccountCredentialsData,
  ChooseDomainData,
  CloudflareSetupData,
  CloudflareSetupSteps,
  DeployWorkerData,
} from '../components/Wizards/CloudflareSetup/CloudflareSetupWizard'
import { deepMerge } from '../helpers/common'
import { useLocalStorage } from './localStorage'
import { useAuth } from './user'

export const USER_DATA_STORAGE_KEY = 'userData'

export const RESEND_CONFIRMATION_TIMER_STORAGE_KEY = 'resendConfirmationTimer'
export const LAST_VIEWED_SUBSCRIPTION_STORAGE_KEY = 'lastViewedSubscription'
export const CLOUDFLARE_SETUP_STORAGE_KEY = 'cloudflareSetup'
export const HAS_SEEN_DEVELOPER_TOOLS = 'hasSeenDevsTools'

/**
 * @deprecated Use `useUserProperty` instead
 */
function useUserStorage() {
  const { accessTokenPayload } = useAuth()
  const id = accessTokenPayload?.id ?? ''

  const [userData, setUserData] = useLocalStorage<Record<string, Record<string, unknown>>>(USER_DATA_STORAGE_KEY, {})

  function setUserProperty<T>(key: string, value: T) {
    setUserData((data) => ({
      ...data,
      [id]: {
        ...data?.[id],
        [key]: value,
      },
    }))

    return value
  }

  function getUserProperty<T>(key: string, defaultValue?: T) {
    return (userData?.[id]?.[key] as T) ?? defaultValue
  }

  function deleteUserProperty(key: string) {
    setUserProperty(key, undefined)
  }

  return { setUserProperty, getUserProperty, deleteUserProperty }
}

// TODO [VL] Refactor all uses of useUserStorage to use this instead.
export function useUserProperty<T>(key: string) {
  const { accessTokenPayload } = useAuth()
  const id = accessTokenPayload?.id ?? ''
  const [userData, setUserData] = useLocalStorage<Record<string, Record<string, unknown>>>(USER_DATA_STORAGE_KEY, {})

  const setUserProperty = useCallback(
    (value: T | undefined) => {
      setUserData((data) => ({
        ...data,
        [id]: {
          ...data?.[id],
          [key]: value,
        },
      }))
    },
    [id, key, setUserData]
  )

  return [userData[id]?.[key] as T | undefined, setUserProperty] as const
}

export function useResendConfirmationTimer() {
  const [lastSent, setLastSent] = useUserProperty(RESEND_CONFIRMATION_TIMER_STORAGE_KEY)

  const markLastSentNow = useCallback(() => setLastSent(Date.now()), [setLastSent])

  const getLastSent = useCallback(() => {
    if (!lastSent) {
      markLastSentNow()
      return Date.now()
    }
    return lastSent as number
  }, [lastSent, markLastSentNow])

  return {
    getLastSent,
    markLastSentNow,
  }
}

export function useLastViewedSubscription() {
  const { setUserProperty, getUserProperty } = useUserStorage()

  const [lastViewedSubscription, setLastViewedSubscription] = useState(
    getUserProperty<string>(LAST_VIEWED_SUBSCRIPTION_STORAGE_KEY)
  )

  function handleSetLastViewedSubscription(subscriptionId: string) {
    setLastViewedSubscription(subscriptionId)
    setUserProperty(LAST_VIEWED_SUBSCRIPTION_STORAGE_KEY, subscriptionId)
  }

  return [lastViewedSubscription, handleSetLastViewedSubscription] as const
}

const DEFAULT_CLOUDFLARE_SETUP_DATA: CloudflareSetupData = {
  currentStep: 0,
  furthestCompletedStep: 0,
}

export function useCloudflareSetup() {
  const [cloudflareSetupDataInLocalStorage, setCloudflareSetupDataInLocalStorage] =
    useUserProperty<CloudflareSetupData>(CLOUDFLARE_SETUP_STORAGE_KEY)

  const [cloudflareSetupData, setCloudflareSetupData] = useState(() => {
    const initialData = cloudflareSetupDataInLocalStorage ?? DEFAULT_CLOUDFLARE_SETUP_DATA
    /**
     * We cannot restart the Wizard from the Choose Domain step
     * We don't store the Cloudflare token in local storage, so we must ask for it again
     */
    if (initialData.currentStep === CloudflareSetupSteps.StepChooseDomain) {
      initialData.currentStep = CloudflareSetupSteps.StepAccountCredentials
    }

    return initialData
  })

  function handleUpdateData(patch: DeepPartial<CloudflareSetupData>) {
    setCloudflareSetupData((currentData) => {
      const newData = deepMerge(currentData, patch)

      setCloudflareSetupDataInLocalStorage(newData)
      return newData
    })
  }

  function reset() {
    setCloudflareSetupData(DEFAULT_CLOUDFLARE_SETUP_DATA)
    setCloudflareSetupDataInLocalStorage(DEFAULT_CLOUDFLARE_SETUP_DATA)
  }

  function setCurrentStep(newStep: number) {
    handleUpdateData({
      currentStep: newStep,
      furthestCompletedStep: Math.max(newStep, cloudflareSetupData.furthestCompletedStep),
    })
  }

  function setFurthestCompletedStep(furthestCompletedStep: number) {
    handleUpdateData({ furthestCompletedStep })
  }

  function setAccountCredentials(newAccountCredentials: AccountCredentialsData) {
    handleUpdateData({ stepData: { [CloudflareSetupSteps.StepAccountCredentials]: newAccountCredentials } })
  }

  function setDomainData(newDomainData: ChooseDomainData) {
    handleUpdateData({ stepData: { [CloudflareSetupSteps.StepChooseDomain]: newDomainData } })
  }

  function setDeployWorkerData(deployWorkerData: DeployWorkerData) {
    handleUpdateData({ stepData: { [CloudflareSetupSteps.StepDeployWorker]: deployWorkerData } })
  }

  return [
    cloudflareSetupData,
    {
      reset,
      setCurrentStep,
      setFurthestCompletedStep,
      setAccountCredentials,
      setDomainData,
      setDeployWorkerData,
    },
  ] as const
}
