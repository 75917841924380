import { InfoOutlined } from '@mui/icons-material'
import { Alert, Typography } from '@mui/material'
import { memo } from 'react'

import styles from './CertificatesList.module.scss'

export const LegacyAlert = memo(function LegacyAlert() {
  return (
    <Alert severity='info' icon={<InfoOutlined className={styles.icon} />}>
      <Typography variant='body2'>
        Certificates now support a single domain for easier management and improved developer experience.
      </Typography>
    </Alert>
  )
})
