import { Lock } from '@mui/icons-material'
import { StaticMessage } from 'components'
import { APITypesMap } from 'const'
import { ExternalLayout } from 'features/commonUI'
import { useConsensualRedirect, useDocumentTitle } from 'hooks'
import { FormProvider, useForm } from 'react-hook-form'

import { useUserConsentUpdateMutation } from '../../hooks/api/users'

type FormData = APITypesMap['userConsentUpdate']['body']

export function UserConsentPage() {
  useConsensualRedirect()
  useDocumentTitle('User Consent')

  const formMethods = useForm<FormData>({ mode: 'onChange' })
  const { handleSubmit } = formMethods
  const { mutate: updateUserConsent } = useUserConsentUpdateMutation()

  return (
    <ExternalLayout>
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(({ termsOfService, privacyPolicy }) =>
            updateUserConsent({ data: { termsOfService, privacyPolicy } })
          )}
        >
          <StaticMessage
            Icon={Lock}
            title='User Consent'
            message='Please read and accept our Terms of Service and Privacy Policy to proceed'
            showConsentForm={true}
          />
        </form>
      </FormProvider>
    </ExternalLayout>
  )
}
