import { OAuthProvider, UserRole } from 'models'

import { UserEditingStartedProperties } from './ampli'

export enum SignupType {
  Password = 'password',
  GoogleOAuth = 'google sso',
  GitHubOAuth = 'github sso',
  MicrosoftOAuth = 'microsoft sso',
}

export enum AnalyticsContext {
  Onboarding = 'onboarding',
  Overview = 'overview',
  AccountSettings = 'account settings',
  MarketingPage = 'marketing page',
  Other = 'other',
  Identification = 'identification',
  GetStarted = 'get started',
  ApplicationSettings = 'application settings',
}

export namespace AnalyticsCategory {
  export interface Signup {
    signupType: SignupType
  }

  export interface Stepper {
    step: string
  }

  export interface Context {
    context?: AnalyticsContext
  }

  export interface OnboardingContext extends Context {
    context: AnalyticsContext.Onboarding
    step: string
  }
}

export const SignupTypeByOAuthProvider: Record<OAuthProvider, SignupType> = {
  [OAuthProvider.Google]: SignupType.GoogleOAuth,
  [OAuthProvider.GitHub]: SignupType.GitHubOAuth,
  [OAuthProvider.Microsoft]: SignupType.MicrosoftOAuth,
}

export const USER_ROLE_ANALYTICS_LABELS: Partial<Record<UserRole, UserEditingStartedProperties['userRole']>> = {
  [UserRole.Member]: 'Member',
  [UserRole.ReadOnly]: 'Read-only',
  [UserRole.Administrator]: 'Admin',
  [UserRole.Owner]: 'Owner',
}
