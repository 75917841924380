import { useCurrentSubscription } from 'features/subscription'
import { useLogPageView } from 'helpers/vendor'
import { useDocumentTitle, useSubscription } from 'hooks'
import { useEvent } from 'hooks/api/event'
import { useVisitsByVisitorInfiniteQuery } from 'hooks/visits'
import { ampli } from 'models/ampli'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { VisitDetails } from '../../components/VisitDetails/VisitDetails'

export function IdentificationVisitDetailsPage() {
  useDocumentTitle('Visit Details')
  useLogPageView(() => ampli.identificationEventDetailsViewed())

  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: currentSubscription, isLoading: isLoadingSubscription } = useSubscription(subscriptionId)
  const { requestId } = useParams<{ requestId: string }>()
  const {
    data: event,
    isLoading: isLoadingEvent,
    error,
  } = useEvent({
    subscriptionId,
    requestId,
  })

  const identification = event?.products.identification?.data
  const query = useVisitsByVisitorInfiniteQuery({
    subscriptionId,
    visitorId: identification?.visitorId ?? '',
    limit: 3,
  })

  const pageStartRef = useRef<HTMLElement | null>(null)
  useEffect(() => {
    if (pageStartRef.current) {
      pageStartRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [requestId])

  return (
    <>
      <span ref={pageStartRef} />
      <VisitDetails
        event={event}
        subscription={currentSubscription}
        isLoading={isLoadingEvent || isLoadingSubscription}
        error={error}
        query={query}
      />
    </>
  )
}
