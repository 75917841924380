import { PropsWithChildren } from 'react'
import { tv, VariantProps } from 'tailwind-variants'

export const flexStyle = tv({
  base: ['flex flex-col w-full flex-grow'],
  variants: {
    row: { true: 'flex-row' },
    smRow: { true: 'sm:flex-row' },
    mdRow: { true: 'md:flex-row' },
    lgRow: { true: 'lg:flex-row' },
    xlRow: { true: 'xl:flex-row' },
    '2xlRow': { true: '2xl:flex-row' },
  },
})

type FlexProps = PropsWithChildren<{ className?: string } & VariantProps<typeof flexStyle>>
export function Flex({ children, className, ...props }: FlexProps) {
  return (
    <div
      className={flexStyle({
        ...props,
        className,
      })}
    >
      {children}
    </div>
  )
}
