import { Alert, Link, Paper, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import BotdFilter, { BotdFilterProps } from 'components/Filter/Botd/BotdFilter'
import { SupportEmail } from 'components/SupportEmail'
import { DOCS_BOTD_QUICK_START_URL, GenericError } from 'const'
import {
  ContentColumn,
  interpretDateRange,
  PersistentDateRangePicker,
  SubHeader,
  useDefaultPredefinedRanges,
} from 'features/commonUI'
import { DateRange } from 'helpers/dateRange'
import { BotdVisitFilter } from 'hooks/botdVisits'
import { UsageChartData, UsageCounterType } from 'models'
import { ampli } from 'models/ampli'

import BotdTable, { BotdTableProps } from '../BotdTable/BotdTable'
import styles from './BotdOverview.module.scss'
import { HistoryWidget, HistoryWidgetProps } from './HistoryWidget/HistoryWidget'

export interface BotdOverviewProps extends HistoryWidgetProps {
  counters?: UsageChartData
  tableProps: BotdTableProps
  error?: GenericError | null
  isLoading?: boolean
  appliedFilter?: BotdVisitFilter
  onFilter: BotdFilterProps['onFilter']
  onApplyRange: (range: DateRange) => void
}

export function BotdOverview({ counters, tableProps, error, isLoading, ...props }: BotdOverviewProps) {
  const { ranges } = useDefaultPredefinedRanges()

  const header = (
    <SubHeader
      title={<Typography variant='h2'>Bot detection</Typography>}
      description={
        <Typography variant='bodyM'>
          View detected malicious and friendly bots.&nbsp;
          <Link
            href={DOCS_BOTD_QUICK_START_URL}
            target='_blank'
            underline='hover'
            onClick={() => {
              ampli.botDDocumentationViewed()
            }}
          >
            Learn more
          </Link>
        </Typography>
      }
      actions={
        <>
          <BotdFilter onFilter={props.onFilter} appliedFilter={props.appliedFilter} className={styles.control} />
          {counters && (
            <PersistentDateRangePicker
              onApplyRange={props.onApplyRange}
              defaultDateRange={ranges.today}
              route={AppRoute.Botd}
              routeParams={{ subscriptionId: props.subscriptionId }}
              timezone={props.timezone}
              rangeInterpreter={(range) => interpretDateRange({ dateRange: range })}
            />
          )}
        </>
      }
    />
  )

  if (error) {
    return (
      <>
        {header}
        <Alert severity='error' className={styles.alert}>
          {error?.message ?? (
            <>
              Something went wrong. Please try again later or contact <SupportEmail />
            </>
          )}
        </Alert>
      </>
    )
  }

  const hasApiCalls = counters?.[UsageCounterType.ApiCalls]?.some(({ value }) => value > 0)

  return (
    <>
      {header}

      <Paper className='p-6 relative'>
        <HistoryWidget counters={counters} {...props} />
      </Paper>

      {hasApiCalls ? (
        <ContentColumn>
          <SubHeader title='Bot visits' />
          <Paper className='p-6 relative'>
            <BotdTable isFiltering={!!props.appliedFilter} {...tableProps} />
          </Paper>
        </ContentColumn>
      ) : null}
    </>
  )
}
