import { DateTime } from 'luxon'
import { UsageCounterPeriod } from 'models'

import { Falsy } from './types'

export const WEEK_DAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

export type DateRange = {
  startDate?: DateTime
  endDate?: DateTime
}

export type CustomDateRange = Required<DateRange> & {
  compareStart: DateTime
  compareEnd: DateTime
}

export type PredefinedRange = CustomDateRange & {
  id:
    | 'today'
    | 'last_7_days'
    | 'last_30_days'
    | 'last_3_months'
    | 'last_6_months'
    | 'last_12_months'
    | 'week_to_date'
    | 'month_to_date'
  label:
    | 'Today'
    | 'Last 7 days'
    | 'Last 30 days'
    | 'Last 3 months'
    | 'Last 6 months'
    | 'Last 12 months'
    | 'Week to date'
    | 'Month to date'
}

export function isPredefinedRange(date: DateRange | CustomDateRange | PredefinedRange): date is PredefinedRange {
  return (date as any).label !== undefined
}

export function calculateGranularityFromRange(range: Required<DateRange>) {
  const { startDate, endDate } = range
  if ((endDate.diff(startDate, 'hour').hours ?? 0) <= 48) {
    return UsageCounterPeriod.Hour
  }

  if ((endDate.diff(startDate, 'days').days ?? 0) <= 61) {
    return UsageCounterPeriod.Day
  }

  return UsageCounterPeriod.Month
}

export const getDaysInMonth = (date: DateTime) => {
  // note: using en-US locale here to forcefully set week start to Sunday to match
  // with predefined days sequence in date picker
  const startWeek = date.setLocale('en-US').startOf('month').startOf('week', { useLocaleWeeks: true })
  const endWeek = date.setLocale('en-US').endOf('month').endOf('week', { useLocaleWeeks: true })
  const days = []
  for (let curr = startWeek; curr < endWeek; ) {
    days.push(curr)
    curr = curr.plus({ days: 1 })
  }
  return days
}

export const isStartOfRange = ({ startDate }: DateRange, day: DateTime) => startDate && day.hasSame(startDate, 'day')

export const isEndOfRange = ({ endDate }: DateRange, day: DateTime) =>
  endDate && day.endOf('day').hasSame(endDate, 'day')

export const inDateRange = ({ startDate, endDate }: DateRange, day: DateTime) =>
  startDate &&
  endDate &&
  ((day >= startDate && day <= endDate) || day.hasSame(startDate, 'day') || day.endOf('day').hasSame(endDate, 'day'))

export const isRangeSameDay = ({ startDate, endDate }: DateRange) => {
  if (startDate && endDate) {
    return startDate.hasSame(endDate, 'day')
  }
  return false
}

export const parseOptionalDate = (date: DateTime | Date | string | Falsy, defaultValue: DateTime) => {
  if (date) {
    let parsed: DateTime
    if (typeof date === 'string') {
      parsed = DateTime.fromISO(date)
    } else if (date instanceof DateTime) {
      parsed = date
    } else {
      parsed = DateTime.fromJSDate(date)
    }
    if (parsed.isValid) {
      return parsed
    }
  }
  return defaultValue
}
