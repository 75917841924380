import { Typography } from '@mui/material'
import { COMPANY_NAME, DOCS_SUBDOMAIN_INTEGRATION_URL, PRODUCT_NAME } from 'const'

import InfoDrawer from '../../../../components/InfoDrawer/InfoDrawer'
import styles from './SubdomainIntegrationInfoDrawer.module.scss'

export default function SubdomainIntegrationInfoDrawer() {
  return (
    <InfoDrawer title='Subdomain setup' action='Read more' actionHref={DOCS_SUBDOMAIN_INTEGRATION_URL}>
      <Typography variant='body1'>
        Using a subdomain is required for correct identification while using {PRODUCT_NAME}.
      </Typography>

      <Typography variant='semiBody1' component='h4' className={styles.subtitle}>
        The benefits of using a subdomain
      </Typography>

      <ul className={styles.infoList}>
        <li>Significant increase to accuracy in Safari.</li>
        <li>
          Cookies are now recognized as “first-party.” This means they can live longer in the browser and extend the
          lifetime of visitorIds.
        </li>
        <li>Ad blockers will not block our JS Agent from loading.</li>
        <li>{COMPANY_NAME} becomes harder for automated blockers and fraudsters to detect.</li>
      </ul>
    </InfoDrawer>
  )
}
