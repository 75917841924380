import { Close } from '@mui/icons-material'
import { IconButton, Paper, Typography } from '@mui/material'
import clsx from 'clsx'

import styles from './DashboardWidget.module.scss'

export type DashboardWidgetVariant = 'standalone' | 'table'

export interface DashboardWidgetProps {
  title: string
  action?: React.ReactNode
  chip?: React.ReactNode
  children?: React.ReactNode
  link?: React.ReactNode
  className?: string
  onClose?: () => void
}

export default function DashboardWidget({
  title,
  action,
  chip,
  children,
  link,
  className,
  onClose,
}: DashboardWidgetProps) {
  return (
    <Paper className={clsx(className, styles.widget)} component='section'>
      <header className={styles.header}>
        <Typography variant='semiBody1' component='h2'>
          {title}
        </Typography>
        {chip ?? null}
        {action ? <span className={styles.action}>{action}</span> : null}
      </header>
      {children}
      {link}
      {onClose ? (
        <IconButton aria-label='close' onClick={onClose} className={styles.closeButton} size='large'>
          <Close />
        </IconButton>
      ) : null}
    </Paper>
  )
}
