import React, { ForwardedRef, forwardRef } from 'react'

import { Button, type ButtonProps } from './Button/Button'
import { LinkButton, type LinkButtonProps } from './LinkButton'

export type ButtonOrLinkProps = (
  | (ButtonProps & { href?: never })
  | (LinkButtonProps & { href?: LinkButtonProps['href'] })
) & {
  children?: React.ReactNode
}

export const ButtonOrLink = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonOrLinkProps>((props, ref) =>
  props.href === undefined ? (
    <Button {...(props as ButtonProps)} ref={ref as ForwardedRef<HTMLButtonElement>} />
  ) : (
    <LinkButton {...(props as LinkButtonProps)} ref={ref as ForwardedRef<HTMLAnchorElement>} />
  )
)
