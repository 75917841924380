import { createContext, useContext } from 'react'

import { IntegrationReadmeProps } from '../utils'
import { ApiKeyWidgetProps } from './MDXApiKeyWidget/MDXApiKeyWidget'

export const IntegrationReadmeContext = createContext<IntegrationReadmeProps & ApiKeyWidgetProps>({
  onGenerate: () => {},
})

export const useIntegrationReadmeContext = () => useContext(IntegrationReadmeContext)
