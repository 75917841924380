import { useQuery, useQueryClient } from '@tanstack/react-query'

import { SuccessResponse } from '../../const'
import { UserSubscriptionSettings } from '../../models'
import { useAuth } from '../user'
import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

const KEY = 'userSubscriptionSettings' as const

export function useUserSubscriptionSettings<T extends keyof UserSubscriptionSettings>(
  subscriptionId: string | undefined,
  settingsName: T,
  enabled = true
) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()
  const { isAuthorized } = useAuth()

  return useQuery({
    queryKey: [KEY, subscriptionId, settingsName] as const,
    queryFn: () =>
      extractData<UserSubscriptionSettings[T]>(
        withErrorHandling(
          buildRequest('userSubscriptionSettings', { params: { settingsName, subscriptionId: subscriptionId ?? '' } })
        ) as Promise<SuccessResponse<UserSubscriptionSettings[T]>>
      ),
    enabled: enabled && !!subscriptionId && isAuthorized,
  })
}

export function useUserSubscriptionSettingsUpdate() {
  const queryClient = useQueryClient()

  return useRequestMutation('userSubscriptionSettingsUpdate', {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY])
    },
  })
}
