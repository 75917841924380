import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useToast } from 'hooks/toast'
import { DateTime } from 'luxon'
import {
  ApplicationFeature,
  ExpandedSubscription,
  OnboardingStep,
  Subscription,
  SubscriptionStatus,
  SubscriptionType,
} from 'models'

import { getFreeSubscriptionLimit } from '../../helpers/content'
import { useUiPreferences } from '../uiPreferences'
import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'
import { useUpdateUserContext } from './context'

export const subscriptionQueryKeyForId = (id: string | null) => ['subscriptions', id] as const

export function useUpgradeMutation() {
  const queryClient = useQueryClient()

  const { showToast } = useToast()
  return useRequestMutation('subscriptionUpgrade', {
    onSuccess: () => {
      queryClient.invalidateQueries(['subscriptions', 'subscription'])
    },
    onError: (error) => {
      showToast({ severity: 'error', message: error.message ?? 'Something went wrong when upgrading the workspace' })
    },
  })
}

// get subscription with expand
export function useSubscription(subscriptionId: string | null, withPolling?: boolean) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: subscriptionQueryKeyForId(subscriptionId),
    queryFn: ({ queryKey: [_, subId] }) =>
      withErrorHandling(
        buildRequest('subscriptionGet', {
          params: {
            id: subId!,
          },
          expand: ['usageQuota', 'upcomingAmount', 'projectedUsage', 'latestTier', 'promotions', 'discounts'],
        })
      ).then(({ data }) => data),
    enabled: subscriptionId != null,
    refetchInterval: withPolling ? 60 * 1000 : undefined, // automatically refetch every 1 min
  })
}

export function useSubscriptions(withPolling?: boolean, enabled = true) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['subscriptions'] as const,
    queryFn: () =>
      withErrorHandling(
        buildRequest('subscriptions', {
          expand: ['usageQuota', 'latestTier', 'promotions', 'discounts'],
        })
      ).then(({ data }) => data),
    enabled,
    refetchInterval: withPolling ? 60 * 1000 : undefined, // automatically refetch every 1 min
  })
}

export function useSubscriptionStartMutation() {
  const queryClient = useQueryClient()
  const updateUserContext = useUpdateUserContext()

  return useRequestMutation('subscriptionStart', {
    onSuccess: () => {
      queryClient.invalidateQueries(['subscriptions'])
      updateUserContext({ onboardingStep: OnboardingStep.Install, hasConsent: true })
    },
  })
}

export function useSubscriptionCreateRequest() {
  return useRequestMutation('subscriptionCreate')
}

export function useSubscriptionUpdate() {
  const queryClient = useQueryClient()

  return useRequestMutation('subscriptionUpdate', {
    onSuccess: ({ id, ...data }) => {
      const cacheKey = subscriptionQueryKeyForId(id)
      const prevData = queryClient.getQueryData<ExpandedSubscription>(cacheKey)

      if (prevData) {
        queryClient.setQueryData<ExpandedSubscription>(cacheKey, { ...prevData, ...data })
      }
    },
  })
}

export function useSubscriptionRename() {
  const queryClient = useQueryClient()

  return useRequestMutation('subscriptionUpdate', {
    onSuccess: ({ id, ...data }) => {
      const cacheKey = subscriptionQueryKeyForId(id)
      const prevData = queryClient.getQueryData<ExpandedSubscription>(cacheKey)

      if (prevData) {
        queryClient.setQueryData<ExpandedSubscription>(cacheKey, { ...prevData, ...data })
      }
    },
    errorHandling: {
      forceToast: true,
    },
  })
}

export function useSubscriptionDelete(subscription?: Subscription) {
  const queryClient = useQueryClient()

  return useRequestMutation('subscriptionDelete', {
    onSuccess: () => {
      if (subscription) {
        const cacheKey = subscriptionQueryKeyForId(subscription.id)
        queryClient.setQueryData<ExpandedSubscription>(cacheKey, {
          ...subscription,
          cancelAt: new Date(Date.now()).toISOString(),
        })
      }
    },
  })
}

export function useSubscriptionTrialSelfExtend() {
  const queryClient = useQueryClient()
  const { updateUiPreferences } = useUiPreferences()
  return useRequestMutation('subscriptionTrialSelfExtend', {
    onSuccess: () => {
      updateUiPreferences({
        isForcedTrialModalDismissed: false,
        isTrialExtendedModalDismissed: false,
      })
      queryClient.invalidateQueries(['subscriptions'])
    },
  })
}

export function useSubscriptionSurvey() {
  return useRequestMutation('subscriptionCancellationSurvey', {
    errorHandling: {
      forceToast: true,
    },
  })
}

const subscriptionFeatureQueryKey = 'subscriptionFeatures' as const

export function useApplicationFeatures(subscriptionId: string) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: [subscriptionFeatureQueryKey, subscriptionId] as const,
    queryFn: ({ queryKey: [_, subId] }) =>
      extractData(withErrorHandling(buildRequest('subscriptionFeatures', { params: { subscriptionId: subId } }))),
  })
}

export function useIsApplicationFeatureEnabled(subscriptionId: string, featureName: ApplicationFeature['featureName']) {
  const { data } = useApplicationFeatures(subscriptionId)
  return data?.some((feature) => feature.featureName === featureName) || false
}

export function useApplicationFeatureToggleMutation() {
  const queryClient = useQueryClient()
  return useRequestMutation('subscriptionFeatureToggle', {
    onSuccess: (_, { params: { subscriptionId } = {} }) => {
      queryClient.invalidateQueries([subscriptionFeatureQueryKey, subscriptionId])
    },
  })
}

export function useCancelTrial(subscription?: ExpandedSubscription) {
  const queryClient = useQueryClient()

  return useRequestMutation('trialCancel', {
    onSuccess: () => {
      if (subscription) {
        const cacheKey = subscriptionQueryKeyForId(subscription.id)
        const now = new Date()
        queryClient.setQueryData<ExpandedSubscription>(cacheKey, {
          ...subscription,
          usageQuota: { ...subscription.usageQuota!, quota: getFreeSubscriptionLimit(subscription.billingType) },
          type: SubscriptionType.Free,
          status: SubscriptionStatus.Active,
          trialEndAt: DateTime.fromJSDate(now).toISO() ?? undefined,
          currentPeriodStartedAt: DateTime.fromJSDate(now).toISO() ?? undefined,
          currentPeriodEndsAt: DateTime.fromJSDate(now).plus({ month: 1 }).toISO() ?? undefined,
        })
      }
    },
  })
}
