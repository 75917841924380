import { Theme, useMediaQuery } from '@mui/material'
import { MegaphoneIcon } from 'lucide-react'

import { CommonMenuItem } from './CommonMenuSection'

export const useFeedbackMenuCommonItems: (props: { onFeedbackClicked: () => void }) => CommonMenuItem[] | null = ({
  onFeedbackClicked,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  if (isDesktop) {
    return null
  }

  return [
    {
      key: 'feedback',
      icon: <MegaphoneIcon />,
      label: 'Share feedback',
      onAction: onFeedbackClicked,
    },
  ]
}
