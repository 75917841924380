import { Typography } from '@mui/material'
import { memo } from 'react'

import DropboxLogo from './dropbox.svg'
import styles from './InfoColumn.module.scss'
import TrustpilotLogo from './trustpilot.svg'
import UsbankLogo from './usbank.svg'

export default memo(function InfoColumn() {
  return (
    <div className={styles.container}>
      <div className={styles.children}>
        <header className={styles.header}>
          <Typography component='h1' className={styles.title}>
            Powerful visitor identification
            <br />
            for high-traffic websites
          </Typography>
        </header>
        <ul className={styles.list}>
          <li>14-day free trial with unlimited API calls</li>
          <li>Get started in under 10 minutes</li>
          <li>Compliant with GDPR & CCPA regulations</li>
        </ul>
        <div className={styles.trusted}>Trusted by top organizations like:</div>
        <div className={styles.organizations}>
          <UsbankLogo />
          <TrustpilotLogo />
          <DropboxLogo />
        </div>
      </div>
    </div>
  )
})
