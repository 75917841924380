import { CopyButton } from 'components'
import { PropsWithChildren } from 'react'
import { PrismAsyncLight } from 'react-syntax-highlighter'
import highlightTheme from 'react-syntax-highlighter/dist/cjs/styles/prism/coy'

import styles from './CodeSnippet.module.scss'

export interface CodeSnippetProps {
  language: string
  children: string
  showCopyButton?: boolean
  showLineNumbers?: boolean
  onCopy?: () => void
  onSelect?: (selection?: string) => void
  className?: string
}

const checkSelection = (cb?: (selection?: string) => void) => () => {
  const selection = window.getSelection()?.toString()
  if (selection !== '') {
    cb?.(selection)
  }
}

const PRISM_LINE_NUMBER_STYLE = { minWidth: 28 }
const PRISM_CUSTOM_STYLE = {
  backgroundColor: '#F5F5F5',
  padding: '12px',
  paddingRight: '30px', // copy button padding
  borderRadius: '4px',
  fontFamily: 'JetBrains Mono, monospace',
  fontSize: '14px',
  margin: '0',
  overflow: 'auto',
}
const PRISM_CODE_TAG_PROPS = { style: { color: '#c92c2c', font: 'inherit' as const } }

/**
 * Provides a syntax-highlighted code block
 */
export function CodeSnippet({
  language,
  showLineNumbers,
  showCopyButton = true,
  onCopy,
  onSelect,
  className,
  children,
}: PropsWithChildren<CodeSnippetProps>) {
  return (
    <div className={styles.codeWrapper} onMouseUp={checkSelection(onSelect)}>
      {showCopyButton && <CopyButton text={children} textButton className={styles.copy} onCopy={onCopy} />}
      <PrismAsyncLight
        showLineNumbers={showLineNumbers}
        lineNumberStyle={PRISM_LINE_NUMBER_STYLE}
        wrapLines
        language={language}
        style={highlightTheme}
        customStyle={PRISM_CUSTOM_STYLE}
        codeTagProps={PRISM_CODE_TAG_PROPS}
        className={className}
      >
        {children}
      </PrismAsyncLight>
    </div>
  )
}
