import { ComponentProps, forwardRef, LegacyRef } from 'react'
import { Separator as AriaSeparator } from 'react-aria-components'
import { tv, VariantProps } from 'tailwind-variants'

const separatorStyles = tv({
  base: 'border-gray-300 border-t-0 border-l-0 border-r border-b',
  variants: {
    size: {
      default: 'm-0',
      sm: 'm-3',
      md: 'm-5',
      lg: 'm-7',
    },
    orientation: {
      horizontal: 'my-0',
      vertical: 'mx-0',
    },
  },
  defaultVariants: {
    orientation: 'vertical',
    size: 'default',
  },
})

export type SeparatorProps = ComponentProps<typeof AriaSeparator> & VariantProps<typeof separatorStyles>
function Separator(props: SeparatorProps, ref?: LegacyRef<HTMLAnchorElement>) {
  return (
    <AriaSeparator
      {...props}
      ref={ref}
      orientation='horizontal'
      className={separatorStyles({
        size: props.size ?? 'default',
        orientation: props.orientation ?? 'vertical',
        className: props.className,
      })}
    />
  )
}

const _Separator = forwardRef(Separator)

export { _Separator as Separator }
