import { GIT_REF, IS_DEV_MODE, IS_PRODUCTION_MODE, IS_QA_ENV, IS_TEST_MODE, ROLLBAR_API_TOKEN } from 'const'
import Rollbar from 'rollbar'

const ignoredFetchTargets = [
  'a.usbrowserspeed.com', // metadata.io tries to load this resource, can be blocked by ad blockers
  'a.remarketstats.com', // metadata.io tries to load this resource, can be blocked by ad blockers
]

export const rollbarConfig: Rollbar.Configuration = {
  accessToken: ROLLBAR_API_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: !IS_DEV_MODE && !IS_TEST_MODE,

  hostSafeList: [
    'fingerprint.com', // Prod
    'fpjs.pro', // Prod Fingerprint Agent CDN
    'fpjs.sh', // Stage
    '.vercel.app', // Previews
  ],

  payload: {
    environment: IS_PRODUCTION_MODE && !IS_QA_ENV ? 'production' : 'staging',

    client: {
      javascript: {
        code_version: GIT_REF,
        source_map_enabled: true,
      },
    },

    server: {
      root: '/',
    },
  },

  filterTelemetry: function (e) {
    try {
      if (
        e.type === 'network' &&
        (e.body.subtype === 'xhr' || e.body.subtype === 'fetch') &&
        typeof e.body.url === 'string' &&
        ignoredFetchTargets.some((ignored) => (e.body.url as string).includes(ignored))
      ) {
        return true
      }
    } catch {
      // ignore, event format issue
    }

    // Do not filter out the rest of the telemetry data, log it.
    return false
  },
}
