import { Check, FileCopyOutlined } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { copyToClipboard } from '../../helpers/clipboard'
import styles from './CopyButton.module.scss'

export interface CopyButtonProps {
  text: string
  labelText?: string
  className?: string
  textButton?: boolean
  onCopy?: () => void
}

export function CopyButton({ text, labelText, className, textButton, onCopy }: CopyButtonProps) {
  const [isCopied, setIsCopied] = useState(false)

  async function onCopyClick() {
    await copyToClipboard(text)
    setIsCopied(true)
    onCopy?.()
  }

  useEffect(() => {
    if (!isCopied) {
      return
    }

    const timer = setTimeout(() => setIsCopied(false), 1000)
    return () => clearTimeout(timer)
  }, [isCopied])

  return textButton ? (
    <div onClick={onCopyClick} className={clsx(styles.copyTextButton, className)}>
      {isCopied && <span className={styles.copyText}>Copied</span>}
      {isCopied ? <Check className={styles.copyIcon} /> : <FileCopyOutlined className={styles.copyIcon} />}
    </div>
  ) : (
    <Tooltip title='Copied' open={isCopied} placement='bottom'>
      <IconButton onClick={onCopyClick} aria-label={labelText} size='large' className={className}>
        {isCopied ? <Check className={styles.copyIcon} /> : <FileCopyOutlined className={styles.copyIcon} />}
      </IconButton>
    </Tooltip>
  )
}
