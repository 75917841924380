import {
  NavigationTab,
  SelectItem,
  SelectListBox,
  SelectPopover,
  SelectSection,
  SelectTrigger,
  SelectValue,
} from '@compass/components'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import { USE_WORKSPACE_ENVIRONMENT } from 'const'
import { useCurrentSubscription } from 'features/subscription'
import { AppWindowMac, Boxes, CreditCard, Globe, Inbox, Info, UserRound, UsersRound } from 'lucide-react'
import { PropsWithChildren, useMemo } from 'react'
import { Select } from 'react-aria-components'
import { matchPath, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { CommonMenuItem, CommonMenuSection } from './Menus/CommonMenuSection'

export function SettingsLayout({ children, className }: PropsWithChildren<{ className?: string }>) {
  return (
    <div className='flex flex-col lg:flex-row lg:items-start gap-6'>
      <SettingsMenu />
      <div className={clsx('relative flex flex-col gap-6 sm:gap-8 w-full h-full', className)}>{children}</div>
    </div>
  )
}

const useSettingsMenuItems = () => {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  const menuSections: CommonMenuSection[] = useMemo(
    () => [
      {
        key: 'workspace',
        title: 'Workspace',
        items: [
          {
            icon: <Info />,
            key: 'general',
            label: 'General',
            'data-testid': 'tabnav-general',
            link: buildRoute(AppRoute.SubscriptionSettings, { subscriptionId }),
          },
          ...(USE_WORKSPACE_ENVIRONMENT
            ? [
                {
                  icon: <Boxes />,
                  key: 'environments',
                  label: 'Environments',
                  link: buildRoute(AppRoute.Environments, { subscriptionId }),
                  'data-testid': 'tabnav-environments',
                },
              ]
            : []),
          {
            icon: <Globe />,
            key: 'subdomains',
            label: 'Subdomains',
            link: buildRoute(AppRoute.Subdomains, { subscriptionId }),
            'data-testid': 'tabnav-subdomains',
          },
        ],
      },
      {
        key: 'account',
        title: 'Account',
        items: [
          {
            icon: <AppWindowMac />,
            key: 'workspaces',
            label: 'Workspaces',
            link: buildRoute(AppRoute.WorkspaceList, { subscriptionId }),
            'data-testid': 'tabnav-workspaces',
          },
          {
            icon: <UsersRound />,
            key: 'team',
            label: 'Team',
            link: buildRoute(AppRoute.UserManagement, { subscriptionId }),
            'data-testid': 'tabnav-team',
          },
          {
            icon: <CreditCard />,
            key: 'billing',
            label: 'Billing',
            link: buildRoute(AppRoute.Billing, { subscriptionId }),
            associatedRoutes: [AppRoute.Billing, AppRoute.Invoice, AppRoute.UpcomingInvoice],
            'data-testid': 'tabnav-billing',
          },
        ],
      },
      {
        key: 'user',
        title: 'User',
        items: [
          {
            icon: <UserRound />,
            key: 'profile',
            label: 'My profile',
            link: buildRoute(AppRoute.ProfileSettings, { subscriptionId }),
            'data-testid': 'tabnav-profile',
          },
          {
            icon: <Inbox />,
            key: 'notifications',
            label: 'My notifications',
            link: buildRoute(AppRoute.NotificationSettings, { subscriptionId }),
            'data-testid': 'tabnav-notifications',
          },
        ],
      },
    ],
    [subscriptionId]
  )

  const { pathname } = useLocation()
  const menuItems = useMemo(() => menuSections.flatMap((s) => s.items), [menuSections])
  const selected = menuItems.find(
    ({ link, associatedRoutes }) =>
      link === pathname || !!associatedRoutes?.find((route) => matchPath(pathname, { path: route }))
  )

  return { menuSections, selected }
}

export function SettingsMenu() {
  const theme = useTheme()
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const { menuSections, selected } = useSettingsMenuItems()

  return (
    <>
      {lgDown ? (
        <MenuSelector menuSections={menuSections} selected={selected} />
      ) : (
        <MenuColumn menuSections={menuSections} selected={selected} />
      )}
    </>
  )
}

function MenuColumn({ menuSections, selected }: { menuSections: CommonMenuSection[]; selected?: CommonMenuItem }) {
  return (
    <ul className='sticky top-16 w-[180px] flex flex-col gap-6 flex-shrink-0'>
      {menuSections.map((section) => (
        <li key={section.key} className='flex flex-col'>
          <Typography className='pr-3 py-2 text-2xs text-gray-800 font-medium tracking-wide uppercase'>
            {section.title}
          </Typography>
          <ul className='space-y-px'>
            {section.items.map((item) => (
              <li className='w-full' key={item.key}>
                <NavigationTab
                  fullWidth
                  variant='ghost'
                  tone={selected?.key === item.key ? 'background2' : 'background1'}
                  className={twMerge(selected?.key === item.key && 'bg-gray-300')}
                  href={item.link}
                  data-testid={item['data-testid']}
                >
                  {item.icon}
                  {item.label}
                </NavigationTab>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}

function MenuSelector({ menuSections, selected }: { menuSections: CommonMenuSection[]; selected?: CommonMenuItem }) {
  return (
    <Select<CommonMenuItem> placeholder='Settings menu' aria-label='Settings menu' defaultSelectedKey={selected?.key}>
      <SelectTrigger fullWidth>
        <SelectValue className='space-x-2'>
          {({ selectedText }) => {
            const selectedSection = menuSections.find((s) => s.items.find((i) => i.label === selectedText))
            return selectedSection?.title ? (
              <>
                <span className='text-gray-800'>{selectedSection.title}</span>
                <span className='text-gray-800'>&#47;</span>
                <span>{selectedText}</span>
              </>
            ) : (
              <>{selectedText}</>
            )
          }}
        </SelectValue>
      </SelectTrigger>
      <SelectPopover aria-label='Settings menu options' className='w-[var(--trigger-width)]'>
        <SelectListBox>
          {menuSections.map((section) => (
            <SelectSection key={section.key} title={section.title}>
              {section.items.map((item) => (
                <SelectItem key={item.key} id={item.key} value={item} href={item.link}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectSection>
          ))}
        </SelectListBox>
      </SelectPopover>
    </Select>
  )
}
