import { ConditionalOverduePaymentCallout } from 'features/billing'
import { Header, MainColumn } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'

import { WorkspaceStatusBar } from '../WorkspaceStatusBar/WorkspaceStatusBar'
import { Banners } from './Banners'
import { ConditionalForcedTrialEndModal } from './ConditionalForcedTrialEndModal'
import { ConditionalIntegrationSubscriptionWidget } from './ConditionalIntegrationSubscriptionWidget'
import { ConditionalUpgradeConfirmationDialog } from './ConditionalUpgradeConfirmationDialog'
import { Insights } from './Insights'

export default function SubscriptionOverview() {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  return (
    <>
      <Header title='Overview' />
      <MainColumn className='[&>*:first-child]:sm:mt-2'>
        <Banners>
          <ConditionalIntegrationSubscriptionWidget subscriptionId={subscriptionId} />
          <ConditionalOverduePaymentCallout subscriptionId={subscriptionId} />
        </Banners>
        <WorkspaceStatusBar />
        <Insights />
      </MainColumn>
      <ConditionalUpgradeConfirmationDialog />
      <ConditionalForcedTrialEndModal subscriptionId={subscriptionId} />
    </>
  )
}
