export interface SubscriptionWebhook {
  id: string
  customerId: string
  subscriptionId: string
  verificationStatus: WebhookVerificationStatus
  active?: boolean
  eventTypes: Array<WebhookEventTypes>
  url: string
  basicAuthUser?: string
  basicAuthPassword?: string
  description?: string
  createdAt: Date
  webhookSignatures?: Array<WebhookSignature>
}

export interface WebhookSignature {
  id: string
  webhookId: string
  signatureKey: string
  createdAt: string
  deletedAt?: string
}

export enum WebhookVerificationStatus {
  Verified = 'verified',
  Verifying = 'verifying',
  Unverified = 'unverified',
  Failed = 'failed',
}

export enum WebhookEventTypes {
  VisitorIdentify = 'visitor.identify',
}
