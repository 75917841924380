import { SubscriptionStatistic } from '../../../../models'

export const WIDGETS: Record<
  SubscriptionStatistic,
  { title: string; info: string; type: 'bigint' | 'precise'; optionLabel: string }
> = {
  [SubscriptionStatistic.Usage]: {
    title: 'Usage',
    info: 'Number of billable API requests made in the selected period.',
    type: 'bigint',
    optionLabel: 'Usage',
  },
  [SubscriptionStatistic.EventsPerVisitor]: {
    title: 'Events per visitor',
    info: 'Average number of identification events for every unique identified visitor in the selected period.',
    type: 'precise',
    optionLabel: 'Events per visitor',
  },
  [SubscriptionStatistic.AverageRps]: {
    title: 'Average RPS',
    info: 'Rolling average of number of API requests per second in the selected period.',
    type: 'precise',
    optionLabel: 'Average RPS',
  },
  [SubscriptionStatistic.UniqueVisitors]: {
    title: 'Unique visitors',
    info: 'Number of distinct visitor IDs seen in the selected time period.',
    type: 'bigint',
    optionLabel: 'Unique visitors',
  },
  [SubscriptionStatistic.UniqueLinkedIds]: {
    title: 'Unique Linked IDs',
    info: 'Number of unique linked IDs that were provided during identification.',
    type: 'bigint',
    optionLabel: 'Unique Linked IDs',
  },
  [SubscriptionStatistic.PeakRps]: {
    title: 'Peak RPS',
    info: 'Peak number of API requests per second in the selected period.',
    type: 'precise',
    optionLabel: 'Peak RPS',
  },
  [SubscriptionStatistic.ThrottledApiCalls]: {
    title: 'Throttled API Calls',
    info: 'Number of API calls that went over the RPS limit and were throttled.',
    type: 'bigint',
    optionLabel: 'Throttled API Calls',
  },
}
