import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import { AppRoute, buildRoute, useRouteParams } from 'appRoutes'
import { Header, MainColumn } from 'features/commonUI'
import { AnalyticsContext } from 'models'
import { ampli } from 'models/ampli'
import { useEffect } from 'react'
import { Link as RouterLink, Redirect } from 'react-router-dom'

import {
  IntegrationCategory,
  IntegrationMetadata,
  sdkIntegrations,
  ThirdPartyIntegration,
} from '../../components/IntegrationMetadata/integrationMetadata'
import { useIntegrationByTag } from '../../integrationHooks'
import { IntegrationCloudflarePage } from '../IntegrationCloudflare/IntegrationCloudflare'
import { IntegrationCloudFrontPage } from '../integrationCloudFront/IntegrationCloudFront'
import { IntegrationCustomPage } from '../IntegrationCustom/IntegrationCustom'
import { IntegrationGenericPage } from '../IntegrationGeneric/IntegrationGeneric'
import { IntegrationGenericMDX } from '../IntegrationGeneric/IntegrationGenericMDX'

export function IntegrationPage() {
  const { subscriptionId, integrationTag } = useRouteParams<AppRoute.Integration>()
  const integration = useIntegrationByTag(integrationTag)

  // TODO: the event is migrated from legacy snippet selection
  // consider renaming the event and making in more generic
  useEffect(() => {
    if (sdkIntegrations.includes(integrationTag)) {
      ampli.selectSnippetType({
        context: AnalyticsContext.AccountSettings,
        snippetType: integrationTag,
      })
    }
  }, [integrationTag])

  if (!integration) {
    return <Redirect to={buildRoute(AppRoute.Integrations, { subscriptionId })} />
  }

  return (
    <>
      <Header title='SDKs & integrations' />
      <MainColumn>
        <Breadcrumbs aria-label='breadcrumb' separator='/' className='mt-2'>
          <Link
            color='inherit'
            underline='hover'
            component={RouterLink}
            to={buildRoute(AppRoute.Integrations, { subscriptionId })}
          >
            Integrations
          </Link>
          <Typography component='span'>{integration.title}</Typography>
        </Breadcrumbs>

        {integration.beforeContent && (
          <Box mb='24px'>
            <integration.beforeContent />
          </Box>
        )}
        <IntegrationComponent integration={integration} />
        {integration.afterContent && (
          <Box mt='24px'>
            <integration.afterContent />
          </Box>
        )}
      </MainColumn>
    </>
  )
}

function IntegrationComponent({ integration }: { integration: IntegrationMetadata }) {
  const hasCodeSnippet =
    integration.category === IntegrationCategory.WebLibraries ||
    integration.category === IntegrationCategory.MobileLibraries ||
    integration.category === IntegrationCategory.ServerLibraries

  switch (integration.integrationTag) {
    case ThirdPartyIntegration.Cloudflare:
      return <IntegrationCloudflarePage />
    case ThirdPartyIntegration.CloudFront:
      return <IntegrationCloudFrontPage />
    case ThirdPartyIntegration.CustomProxy:
      return <IntegrationCustomPage />
    case ThirdPartyIntegration.FastlyVCL:
    case ThirdPartyIntegration.Azure:
    case ThirdPartyIntegration.Akamai:
      return <IntegrationGenericPage {...integration} />
    default:
      return <IntegrationGenericMDX integration={integration} needsSubscription={hasCodeSnippet} />
  }
}
