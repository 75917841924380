import { Button } from '@compass/components'
import { InfoOutlined } from '@mui/icons-material'
import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Select,
  Slider,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { SUPPORT_EMAIL_MAILTO, USE_WORKSPACE_ENVIRONMENT } from 'const'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, formatDate } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { getErrorParams } from 'helpers/data'
import { clamp } from 'helpers/math'
import { muiRegister } from 'helpers/reactHookForm'
import { useToast } from 'hooks'
import { useEntityLimit } from 'hooks/api/context'
import { useEncryptionKeyUpdateMutation, useTokenSettingsUpdateMutation } from 'hooks/api/tokens'
import { useUsers } from 'hooks/api/users'
import { ApiKey, ApiKeyType, Limits } from 'models'
import { ampli } from 'models/ampli'
import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { useWorkspaceEnvironments } from '../../../../hooks/api/environment'
import { PRIVATE_KEY_TYPES, privateWarning } from '../../content'
import { KeyDescription } from '../KeyDescription/KeyDescription'
import { apiKeyDisplayNameMap } from '../KeySection/KeySection'
import styles from './ManageApiKeyDialog.module.scss'

export interface TokenSettings extends Pick<ApiKey, 'id' | 'rateLimit' | 'name'> {
  disabled: boolean
}

export interface ManageApiKeyDialogProps extends DialogProps {
  apiKey: ApiKey
  onClose: () => void
}

// connected component
export default function ManageApiKeyDialog({ apiKey, ...dialogProps }: ManageApiKeyDialogProps) {
  const { mutate: sendTokenSettingsUpdateRequest, isLoading } = useTokenSettingsUpdateMutation()
  const { mutate: sendEncryptionKeyUpdateRequest, isLoading: isLoadingEncryptionKey } = useEncryptionKeyUpdateMutation()
  const { currentSubscriptionId } = useCurrentSubscription()
  const { showToast } = useToast()
  const { data: users } = useUsers()

  const createdByUser = users?.find((user) => user.id === apiKey.createdBy)
  const createdByNameOrEmail = createdByUser?.name ?? createdByUser?.email

  const onUpdate = useCallback<
    (data: {
      name: string | undefined
      description: string | undefined
      disabled: any
      rateLimit: any
      id: any
    }) => void
  >(
    (data) => {
      const nameChanged = apiKey.name !== data.name
      const descriptionChanged = apiKey.description !== data.description
      const wasEnabled = !apiKey.disabledAt && data.disabled
      const wasDisabled = !!apiKey.disabledAt && !data.disabled
      const rateChanged = apiKey.rateLimit !== Number(data.rateLimit)

      if (apiKey.type === ApiKeyType.Encryption) {
        sendEncryptionKeyUpdateRequest(
          {
            data: {
              name: data.name,
              description: data.description,
            },
            params: { keyId: data.id, subscriptionId: currentSubscriptionId },
          },
          {
            onSuccess: () => {
              ampli.apiKeySettingsSaved({
                apiKeyType: apiKeyDisplayNameMap[apiKey.type],
                nameChanged,
                descriptionChanged,
                wasEnabled,
                wasDisabled,
                rateChanged,
              })

              dialogProps.onClose()
              showToast({ message: 'Success! Your API token was updated.', severity: 'success' })
            },
          }
        )
        return
      }

      sendTokenSettingsUpdateRequest(
        {
          data: {
            rateLimit: Number(data.rateLimit),
            name: data.name,
            disabled: data.disabled,
            description: data.description,
          },
          params: { id: data.id, subscriptionId: currentSubscriptionId },
        },
        {
          onSuccess: () => {
            ampli.apiKeySettingsSaved({
              apiKeyType: apiKeyDisplayNameMap[apiKey.type],
              nameChanged,
              descriptionChanged,
              wasEnabled,
              wasDisabled,
              rateChanged,
            })

            if (rateChanged && apiKey.rateLimit) {
              ampli.apiRateLimitChangedSaved({
                previousApiRateLimit: apiKey.rateLimit,
                newApiRateLimit: Number(data.rateLimit),
              })
            }
            dialogProps.onClose()
            showToast({ message: 'Success! Your API token was updated.', severity: 'success' })
          },
        }
      )
    },
    [
      apiKey,
      sendTokenSettingsUpdateRequest,
      currentSubscriptionId,
      sendEncryptionKeyUpdateRequest,
      dialogProps,
      showToast,
    ]
  )
  return (
    <ManageApiKeyDialogView
      apiKey={apiKey}
      onUpdate={onUpdate}
      createdByNameOrEmail={createdByNameOrEmail}
      {...dialogProps}
      isLoading={isLoading || isLoadingEncryptionKey}
    />
  )
}

export interface ManageApiKeyDialogViewProps extends ManageApiKeyDialogProps {
  isLoading: boolean
  onUpdate: (settings: TokenSettings) => void
  createdByNameOrEmail: string | undefined
}

interface ManageTokenFormFields
  extends Pick<ApiKey, 'id' | 'rateLimit' | 'name' | 'description' | 'workspaceEnvironmentId'> {
  active: boolean
}

// display component for storybook
export function ManageApiKeyDialogView({
  apiKey,
  onUpdate,
  createdByNameOrEmail,
  ...dialogProps
}: ManageApiKeyDialogViewProps) {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: workspaceEnvironmentsData, isLoading: isLoadingEnvironments } =
    useWorkspaceEnvironments(currentSubscriptionId)
  const maxRateLimit = useEntityLimit(currentSubscriptionId, Limits.TokenRate)

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm<ManageTokenFormFields>({
    defaultValues: {
      active: !apiKey.disabledAt,
      name: apiKey.name,
      description: apiKey.description,
      rateLimit: apiKey.rateLimit,
      workspaceEnvironmentId: apiKey.workspaceEnvironmentId,
    },
  })

  const isActive = watch('active')
  const minRate = 0.1
  const rateStep = maxRateLimit / 10

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog {...dialogProps} fullScreen={smDown} classes={{ paper: styles.dialog }}>
      <form
        onSubmit={handleSubmit((data) => {
          const { active, ...payload } = data // remove the 'active' field and replace it with 'disabled'
          onUpdate({ ...payload, disabled: !active })
        })}
        noValidate
      >
        <DialogTitle onClose={dialogProps.onClose} className={styles.dialogTitle}>
          <Typography variant='h1' component='div' className={styles.heading} data-testid='manage-api-key-title'>
            Manage {apiKeyDisplayNameMap[apiKey.type].toLowerCase()} key
          </Typography>
        </DialogTitle>

        <DialogContent className={styles.dialogContent}>
          <Grid container rowGap={'40px'}>
            <Grid item xs={12}>
              <Stack direction='column' rowGap='20px'>
                {PRIVATE_KEY_TYPES.includes(apiKey.type) && (
                  <Alert severity='warning' icon={<InfoOutlined />} sx={{ width: '100%' }}>
                    {privateWarning(apiKey.type)}
                  </Alert>
                )}
                <Typography variant='bodyM'>
                  <KeyDescription keyType={apiKey.type} />
                </Typography>
                <CreatedByText apiKey={apiKey} createdByNameOrEmail={createdByNameOrEmail} />
              </Stack>
            </Grid>

            <Grid container item xs={12} alignItems='center' rowGap={'24px'}>
              <input hidden id='id' defaultValue={apiKey.id} {...register('id')} />

              {USE_WORKSPACE_ENVIRONMENT &&
                workspaceEnvironmentsData &&
                !isLoadingEnvironments &&
                [ApiKeyType.Public, ApiKeyType.Secret].includes(apiKey.type) && (
                  <Grid item xs={12}>
                    <InputLabel htmlFor='workspaceEnvironmentId'>Environment</InputLabel>
                    <Select
                      id='workspaceEnvironmentId'
                      native
                      readOnly
                      required
                      labelId='environment-label-id'
                      inputProps={{
                        'aria-label': 'Environment',
                      }}
                      {...muiRegister(register, 'workspaceEnvironmentId', {
                        required: true,
                      })}
                      fullWidth
                      className={`${styles.field} ${styles.disabled}`}
                    >
                      {workspaceEnvironmentsData?.map((environment) => (
                        <option key={environment.id} value={environment.id}>
                          {environment.name}
                        </option>
                      ))}
                    </Select>
                  </Grid>
                )}

              <Grid item xs={12}>
                <InputLabel htmlFor='name'>Name</InputLabel>
                <TextField
                  autoFocus
                  autoComplete='off'
                  id='name'
                  data-testid='manage-apikey-dialog-name-input'
                  aria-label='api-key-name-input'
                  placeholder={apiKey.name}
                  variant='outlined'
                  {...muiRegister(register, 'name', {
                    validate: (value) => {
                      // we don't allow empty strings as names
                      if (value.trim().length > 0) {
                        return true
                      }
                      return 'Please give your API key a name.'
                    },
                  })}
                  {...getErrorParams('name', errors)}
                  fullWidth
                  FormHelperTextProps={{ classes: { root: styles.helperText } }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor='description'>Description (optional)</InputLabel>
                <TextField
                  autoComplete='off'
                  id='description'
                  data-testid='manage-apikey-description-field'
                  aria-label='api-key-description-input'
                  placeholder={apiKey.description}
                  variant='outlined'
                  {...muiRegister(register, 'description')}
                  fullWidth
                />
              </Grid>

              {[ApiKeyType.BrowserProxy, ApiKeyType.Encryption, ApiKeyType.Management].includes(apiKey.type) ? (
                <input type='hidden' defaultValue={apiKey.rateLimit} {...register('rateLimit')} />
              ) : (
                <Grid item xs={12}>
                  <InputLabel htmlFor='rateLimit'>Rate limit</InputLabel>
                  <FormControl fullWidth variant='outlined'>
                    <Controller
                      name='rateLimit'
                      control={control}
                      render={({ field }) => (
                        <OutlinedInput
                          id='rateLimit'
                          margin='dense'
                          inputProps={{
                            type: 'number',
                            min: minRate,
                            step: rateStep,
                            max: maxRateLimit,
                          }}
                          endAdornment={
                            <InputAdornment position='end'>
                              <Typography className={styles.rateCaption} variant='caption'>
                                requests / s
                              </Typography>
                            </InputAdornment>
                          }
                          {...field}
                          onChange={(event) => field.onChange(clamp(Number(event.target.value), minRate, maxRateLimit))}
                        />
                      )}
                    />
                  </FormControl>
                  <Controller
                    name='rateLimit'
                    control={control}
                    render={({ field }) => (
                      <Slider
                        className={styles.slider}
                        marks
                        min={minRate}
                        step={rateStep}
                        max={maxRateLimit}
                        valueLabelDisplay='auto'
                        {...field}
                        onChange={(_, value) => field.onChange(value)}
                      />
                    )}
                  />
                  <Grid container direction='row' justifyContent='space-between'>
                    <Grid item>
                      <Typography variant='body2'>{minRate}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant='body2'>{maxRateLimit}</Typography>
                    </Grid>
                  </Grid>
                  <Typography className={styles.rateHint} variant='body2'>
                    Need a higher rate limit?{' '}
                    <Link href={SUPPORT_EMAIL_MAILTO} color='primary' variant='body2' underline='hover'>
                      Get in touch with our support team
                    </Link>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className={styles.actions}>
          {apiKey.type !== ApiKeyType.Encryption && (
            <FormControlLabel
              className={styles.activeButton}
              control={
                <Controller
                  name='active'
                  control={control}
                  render={({ field }) => (
                    <Switch
                      id='active'
                      data-testid='manage-apikey-active-toggle'
                      color='primary'
                      checked={field.value}
                      {...field}
                      onChange={(event) => field.onChange(event.target.checked)}
                    />
                  )}
                />
              }
              label={isActive ? 'Active' : 'Disabled'}
            />
          )}

          <Box className={styles.buttons}>
            <Button variant='ghost' onPress={dialogProps.onClose}>
              Cancel
            </Button>
            <Button type='submit' isDisabled={!!errors.name} data-testid='manage-apikey-dialog-submit'>
              Save changes
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  )
}

function CreatedByText({ apiKey, createdByNameOrEmail }: { apiKey: ApiKey; createdByNameOrEmail: string | undefined }) {
  const createdByText = createdByNameOrEmail ? `by ${createdByNameOrEmail} ` : ''
  return (
    <Typography variant='bodyM'>{`This API key was created ${createdByText}on ${formatDate(
      apiKey.createdAt
    )}.`}</Typography>
  )
}
