import { Button } from '@compass/components'
import { Replay } from '@mui/icons-material'
import { Paper, Typography } from '@mui/material'
import { ExternalLayout } from 'features/commonUI'

import BadLink from './badLink.svg'
import styles from './SignUpConfirm.module.scss'

export type SignUpConfirmLinkInvalidProps = {
  descriptionText: string
  onResend?: () => void
  onLogin?: () => void
  isLoggedIn?: boolean
}

export function SignUpConfirmLinkInvalid({
  descriptionText,
  onResend,
  onLogin,
  isLoggedIn,
}: SignUpConfirmLinkInvalidProps) {
  return (
    <ExternalLayout srcPage={isLoggedIn ? 'signupConfirm' : undefined}>
      <Paper className={styles.container}>
        <div className={styles.topImage}>
          <BadLink />
        </div>
        <h1 className={styles.title}>We couldn’t verify your email</h1>
        <Typography variant='body2' className={styles.description}>
          {descriptionText}
        </Typography>
        {isLoggedIn ? (
          <Button onPress={onResend} className={styles.button}>
            <Replay fontSize='inherit' />
            Resend confirmation email
          </Button>
        ) : (
          <Button onPress={onLogin} className={styles.button}>
            Log in to resend confirmation email
          </Button>
        )}
      </Paper>
    </ExternalLayout>
  )
}
