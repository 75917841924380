import { InsertDriveFileOutlined } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { StaticMessage } from 'components'
import { DOCS_ROOT, DOCS_URL, getRedirectOriginsWhitelist } from 'const'
import { ExternalLayout } from 'features/commonUI'
import logger from 'helpers/logger'
import { useReadmeLogin, useUnauthorizedRedirect } from 'hooks'
import { useQueryParams } from 'hooks/queryParams'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import RedirectIcon from './icons/redirect.svg'

export function ReadmeLoginPage() {
  useUnauthorizedRedirect()

  const { redirectUrl } = useQueryParams()
  const [canRedirect, setCanRedirect] = useState(false)
  const { data: readmeLoginData, isError, error } = useReadmeLogin()
  const history = useHistory()

  useEffect(() => {
    // Wait at least 1 second to prevent the page from flashing.
    const timer = setTimeout(() => setCanRedirect(true), 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    if (!canRedirect || !readmeLoginData) {
      return
    }

    let redirect = DOCS_ROOT
    try {
      const { origin, href } = new URL(redirectUrl)

      if (origin === DOCS_ROOT) {
        redirect = href
      } else {
        const whitelistedOrigins = getRedirectOriginsWhitelist()
        if (whitelistedOrigins.includes(origin)) {
          redirect = href
        } else {
          // TODO: replace with a logger call when we actually have one in production
          // eslint-disable-next-line no-console
          console.error(`The "${origin}" origin is not allowed for redirect`)
        }
      }
    } catch (e: any) {
      logger.error(e.message)
    }

    window.location.assign(`${redirect}?auth_token=${readmeLoginData.token}`)
  }, [canRedirect, history, readmeLoginData, redirectUrl])

  if (isError) {
    return (
      <ExternalLayout>
        <StaticMessage
          Icon={InsertDriveFileOutlined}
          title='Readme.com'
          message={error?.message ?? 'There was a problem with your login.'}
          showRedirect
          redirectText='Go to docs'
          redirectHref={redirectUrl ?? DOCS_URL}
        />
      </ExternalLayout>
    )
  }

  return (
    <ExternalLayout>
      <Stack alignItems='center' justifyContent='center' height='100%' paddingY={8}>
        <RedirectIcon />
        <Typography data-testid='readme-login-title' variant='h1' textAlign='center' mt={6}>
          Redirecting you to documentation
        </Typography>
        <Typography variant='bodyM' textAlign='center' mt={2}>
          This little maneuver’s gonna cost us 51 milliseconds.
        </Typography>
      </Stack>
    </ExternalLayout>
  )
}
