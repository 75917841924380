import { LoadingDots } from '@compass/LoadingDots'
import { Tooltip } from '@compass/Tooltip'
import { forwardRef, ReactNode, Ref, useEffect, useState } from 'react'
import {
  Button as AriaButton,
  ButtonProps as AriaButtonProps,
  composeRenderProps,
  TooltipTrigger,
} from 'react-aria-components'
import { tv, VariantProps } from 'tailwind-variants'

export const buttonStyles = tv({
  base: [
    'relative',
    'select-none',
    'inline-flex flex-shrink-0 items-center justify-center whitespace-nowrap gap-2',
    'rounded-md',
    '[.button-group_&]:flex-grow',
    '[.button-group_&]:rounded-none [.button-group_&]:first:rounded-l-md [.button-group_&]:last:rounded-r-md',
    '[.button-group-vertical_&]:rounded-none [.button-group-vertical_&]:first:rounded-t-md [.button-group-vertical_&]:last:rounded-b-md',
    'text-base font-medium',
    '[&_svg]:flex-shrink-0',
    'transition-colors duration-100',
    'outline-none',
    'focus-visible:z-10 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary',
    'cursor-pointer disabled:cursor-not-allowed',
    'disabled:[&_svg]:text-gray-600',
  ],
  variants: {
    variant: {
      primary: [
        'bg-primary text-white border border-orange-800',
        'shadow-button',
        'hover:bg-primary-hover',
        'pressed:bg-primary-active pressed:border-orange-900 pressed:shadow-inset-t-black-100',
        'disabled:bg-orange-500 disabled:border-orange-600 disabled:text-gray-100',
      ],
      secondary: [
        'bg-white text-gray-1000 border border-gray-400',
        'shadow-button',
        '[&_svg]:text-gray-900',
        'hover:bg-gray-200',
        'pressed:bg-gray-300 pressed:shadow-inset-t-black-100',
        'disabled:bg-white disabled:border-gray-400 disabled:text-gray-600 [&_svg]:disabled:text-gray-600',
      ],
      destructive: [
        'bg-white text-red-800 border border-gray-400',
        'shadow-button',
        'hover:text-white hover:bg-red-800 hover:border-red-800',
        'pressed:bg-red-900 pressed:border-red-900 pressed:shadow-inset-t-black-100',
        'disabled:bg-red-500 disabled:border-red-600 disabled:text-gray-100',
      ],
      outline: [
        'bg-transparent text-gray-1000 border border-gray-400',
        '[&_svg]:text-gray-900',
        'hover:bg-gray-200',
        'pressed:bg-gray-300',
        'disabled:bg-transparent border-gray-400 disabled:text-gray-600 [&_svg]:disabled:text-gray-600',
      ],
      ghost: [
        'bg-transparent',
        'text-gray-1000',
        '[&_svg]:text-gray-900',
        'border border-transparent',
        'hover:bg-gray-200',
        'pressed:bg-gray-300',
        'disabled:text-gray-600 [&_svg]:disabled:text-gray-600',
        // 'mix-blend-multiply',
      ],
      none: '',
    },
    tone: {
      background1: [
        'bg-gray-100 hover:bg-gray-200',
        'pressed:bg-gray-300',
        'disabled:bg-gray-100 disabled:border-gray-400 disabled:text-gray-600',
      ],
      background2: [
        'bg-gray-200 hover:bg-gray-300',
        'pressed:bg-gray-400',
        'disabled:bg-gray-200 disabled:border-gray-400 disabled:text-gray-600',
      ],
      opaque: [
        'bg-transparent hover:bg-white/70',
        'pressed:bg-white/50',
        'disabled:bg-white/50 disabled:text-gray-600',
      ],
    },
    alt: {
      true: 'text-gray-900',
    },
    size: {
      default: ['h-8 px-3 py-2', '[&_svg]:w-4 [&_svg]:h-4'],
      sm: ['h-6 px-3 text-xs', '[&_svg]:w-3 [&_svg]:h-3'],
      lg: ['h-9 px-4', '[&_svg]:w-6 [&_svg]:h-6'],
    },
    isIcon: {
      true: 'p-0',
    },
    fullWidth: {
      true: 'w-full',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'default',
  },
  compoundVariants: [
    {
      variant: 'secondary',
      tone: 'background1',
    },
    {
      variant: 'secondary',
      tone: 'background2',
    },
    {
      variant: 'outline',
      tone: 'background1',
      className: 'bg-transparent',
    },
    {
      variant: 'outline',
      tone: 'background2',
      className: 'bg-transparent',
    },
    {
      variant: 'ghost',
      tone: 'background1',
      className: 'bg-transparent',
    },
    {
      variant: 'ghost',
      tone: 'background2',
      className: 'bg-transparent',
    },
    {
      variant: 'ghost',
      tone: 'opaque',
      className: 'bg-transparent',
    },
    {
      size: 'sm',
      isIcon: true,
      className: 'w-6',
    },
    {
      size: 'default',
      isIcon: true,
      className: 'w-8',
    },
    {
      size: 'lg',
      isIcon: true,
      className: 'w-10',
    },
  ],
})

type Constraint =
  | {
      variant: 'primary'
      tone?: never
    }
  | {
      variant: 'secondary'
      tone?: 'background1' | 'background2'
    }
  | {
      variant: 'outline'
      tone?: 'background1' | 'background2'
    }
  | {
      variant: 'ghost'
      tone?: 'background1' | 'background2' | 'opaque'
    }
  | { variant?: 'destructive' | 'none' }
type ConstrainedVariantProps = VariantProps<typeof buttonStyles> & Constraint

export type ButtonExtraProps = ConstrainedVariantProps & {
  isLoading?: boolean
  tooltip?: ReactNode
}

export type ButtonProps = AriaButtonProps & ButtonExtraProps
function Button(
  {
    variant = 'primary',
    tone,
    alt,
    size = 'default',
    isIcon,
    fullWidth,
    isLoading,
    tooltip,
    children,
    ...props
  }: ButtonProps,
  ref?: Ref<HTMLButtonElement>
) {
  const [hasLoaded, setHasLoaded] = useState(false)
  useEffect(() => {
    if (isLoading && !hasLoaded) {
      setHasLoaded(true)
    }
  }, [hasLoaded, isLoading])

  const styleProps = { variant, tone, alt, size, fullWidth, isIcon } as const
  return (
    <TooltipTrigger delay={1000}>
      <AriaButton
        {...props}
        isDisabled={props.isDisabled || isLoading}
        className={composeRenderProps(props.className, (className, renderProps) =>
          buttonStyles({
            ...renderProps,
            ...styleProps,
            className,
          })
        )}
        ref={ref}
      >
        {(renderProps) => (
          <>
            {isLoading || hasLoaded ? (
              <LoadingDots
                isLoading={isLoading}
                className={`${
                  isLoading ? 'opacity-100' : 'motion-safe:scale-150 opacity-0 duration-100'
                } absolute transition-all motion-safe:animate-shrink-in`}
              />
            ) : null}
            <span
              className={`${
                isLoading ? 'motion-safe:scale-95 opacity-0 transition-all scale-150' : 'opacity-100 transition-opacity'
              } flex flex-shrink-0 flex-grow items-center justify-center gap-2 whitespace-nowrap`}
            >
              {typeof children == 'function' ? children(renderProps) : children}
            </span>
          </>
        )}
      </AriaButton>
      {(tooltip ?? props['aria-label']) ? <Tooltip>{tooltip ?? props['aria-label']}</Tooltip> : null}
    </TooltipTrigger>
  )
}

const _Button = forwardRef(Button)
export { _Button as Button }
