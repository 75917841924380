import { useCurrentSubscription } from 'features/subscription'
import { useCallback } from 'react'

import { useValidateCloudflareApiTokenMutation } from '../../../../hooks'
import {
  useCloudflareIntegrationData,
  useRemoveCloudflareIntegration,
  useUpdateCloudflareToken,
} from '../../../../hooks/api/integrations'
import { resolveCloudflareIntegrationStatus } from '../../cloudflareHelpers'
import { ThirdPartyIntegration } from '../../components/IntegrationMetadata/integrationMetadata'
import { IntegrationStatus } from '../../components/IntegrationPage/types'
import { useIntegrationStatsQuery } from '../../hooks/useIntegrationStatsQuery'
import IntegrationCloudflareContents from './IntegrationCloudflareContents'

// TODO: Move outside of /pages/
export function IntegrationCloudflarePage() {
  const { currentSubscriptionId } = useCurrentSubscription()

  const { data: cloudflareData, isLoading: getIsLoading } = useCloudflareIntegrationData(currentSubscriptionId, true)
  const { data: requestsMade, isLoading: statsIsLoading } = useIntegrationStatsQuery(
    ThirdPartyIntegration.Cloudflare,
    currentSubscriptionId
  )

  const isLoading = getIsLoading || statsIsLoading

  const { mutateAsync: updateToken, isLoading: isUpdating } = useUpdateCloudflareToken()
  const { mutateAsync: removeIntegration, isLoading: isRemoving } = useRemoveCloudflareIntegration()
  const { mutateAsync: validateToken, isLoading: isValidatingToken } = useValidateCloudflareApiTokenMutation()

  const handleValidateToken = useCallback(
    async (token: string) => {
      if (!cloudflareData?.accountId) {
        return false
      }

      const response = await validateToken({
        data: {
          apiToken: token,
          subscriptionId: currentSubscriptionId,
          accountId: cloudflareData.accountId,
        },
      })

      return Boolean(response?.isValid)
    },
    [cloudflareData, currentSubscriptionId, validateToken]
  )

  const handleUpdateToken = useCallback(
    async (apiToken: string) => {
      await updateToken({
        data: {
          apiToken,
          subscriptionId: currentSubscriptionId,
        },
      })
    },
    [currentSubscriptionId, updateToken]
  )

  const handleRemoval = useCallback(async () => {
    if (cloudflareData == null) {
      return
    }

    await removeIntegration({
      params: {
        subscriptionId: currentSubscriptionId,
        id: cloudflareData.id,
      },
    })
  }, [cloudflareData, currentSubscriptionId, removeIntegration])

  const status = resolveCloudflareIntegrationStatus(cloudflareData)

  const error = status === IntegrationStatus.IssueDetected ? cloudflareData?.lastDeploymentError : undefined

  return (
    <IntegrationCloudflareContents
      validateToken={handleValidateToken}
      status={status}
      subscriptionId={currentSubscriptionId}
      cloudflareData={cloudflareData}
      isLoading={isLoading}
      error={error}
      onUpdate={handleUpdateToken}
      onRemove={handleRemoval}
      isDialogLoading={isUpdating || isValidatingToken || isRemoving}
      requestsMade={requestsMade}
    />
  )
}
