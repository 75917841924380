import { Button } from '@compass/components'
import { ArrowRightAlt, SwapHoriz } from '@mui/icons-material'
import { Alert, Checkbox, DialogProps, FormControlLabel, Typography } from '@mui/material'
import clsx from 'clsx'
import PasswordInput from 'components/PasswordInput/PasswordInput'
import { GenericError } from 'const'
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'features/commonUI'
import { muiRegister } from 'helpers/reactHookForm'
import { UserContext } from 'models'
import { useEffect } from 'react'
import { FormProvider, useFormContext } from 'react-hook-form'

import { ManagedUser } from '../UserManagement/UserManagementData'
import styles from './TransferOwnershipDialog.module.scss'

export interface TransferOwnershipDialogProps extends DialogProps {
  context: UserContext
  target?: ManagedUser
  onConfirmTransfer: (password: string, target?: ManagedUser) => void
  onConfirmSsoTransfer: () => void
  onClose: () => void
  error?: GenericError | null
}

export function TransferOwnershipDialog({
  onClose,
  target,
  onConfirmSsoTransfer,
  onConfirmTransfer,
  context,
  error,
  ...props
}: TransferOwnershipDialogProps) {
  const formMethods = useFormContext()
  const { handleSubmit, reset, watch, register, formState } = formMethods
  const { isSubmitSuccessful } = formState
  const { name, email } = target ?? { name: 'this user' }

  const acknowledgement = watch('acknowledgement')
  const isSso = context.isSsoEnabled

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset])

  return (
    <Dialog onClose={onClose} classes={{ paper: styles.dialog }} {...props}>
      <form
        onSubmit={handleSubmit(({ password }) => {
          if (isSso) {
            onConfirmSsoTransfer()
          } else {
            onConfirmTransfer(password, target)
          }
        })}
      >
        <FormProvider {...formMethods}>
          <DialogTitle>
            <Typography component='span' variant='h1'>
              Transfer ownership
            </Typography>
          </DialogTitle>

          <DialogContent className={styles.content}>
            <Alert severity='warning' icon={false} className={clsx([styles.row, styles.alert])}>
              <Typography variant='bodyMMedium'>How does this work?</Typography>
              <br />
              <Typography variant='bodyM'>
                We’ll send {name} {email && `(${email})`} an email to accept the transfer. If they accept the transfer,
                your role will change to Member.
              </Typography>
            </Alert>

            <div className={clsx([styles.infographic, styles.row])}>
              <Typography variant='bodyMMedium' className={clsx(styles.avatar)}>
                You
              </Typography>
              <ArrowRightAlt />
              <Typography variant='bodyMMedium' className={clsx([styles.avatar, styles.newAvatar])}>
                {name?.[0]}
              </Typography>
            </div>

            {!isSso && <PasswordInput label='Enter your password to confirm' error={error} />}

            <FormControlLabel
              control={<Checkbox />}
              label={
                <Typography variant='bodyS' className={styles.label}>
                  I acknowledge transferring ownership to <strong>{name}</strong> means they will have administrative
                  control over this Fingerprint account.
                </Typography>
              }
              {...muiRegister(register, 'acknowledgement', { required: true })}
            />
          </DialogContent>

          <DialogActions className={styles.actions}>
            <Button variant='ghost' onPress={onClose}>
              Cancel
            </Button>
            <Button variant='destructive' type='submit' isDisabled={!acknowledgement}>
              <SwapHoriz fontSize='inherit' />
              Transfer ownership
            </Button>
          </DialogActions>
        </FormProvider>
      </form>
    </Dialog>
  )
}
