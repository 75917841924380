import IntegrationPageSection, {
  IntegrationPageSectionDefinition,
} from '../IntegrationPageSection/IntegrationPageSection'
import IntegrationTopFrame, { IntegrationTopFrameProps } from '../IntegrationTopFrame/IntegrationTopFrame'
import styles from './IntegrationPageContents.module.scss'

export type IntegrationPageContentsProps = IntegrationTopFrameProps & {
  sections?: IntegrationPageSectionDefinition[]
}

export default function IntegrationPageContents({ sections, ...props }: IntegrationPageContentsProps) {
  return (
    <div className={styles.contents}>
      <IntegrationTopFrame {...props} />
      {sections?.map((section) => <IntegrationPageSection section={section} key={section.key} />)}
    </div>
  )
}
