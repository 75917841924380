import { MenuBook, RemoveCircleOutline, Restore } from '@mui/icons-material'
import { Box, IconButton, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'
import { useFormContext } from 'react-hook-form'

import { muiRegister } from '../../../../helpers/reactHookForm'
import { Platform, SuspectScore } from '../../../../models'
import { ampli } from '../../../../models/ampli'
import { PLATFORM_NAMES, SUSPECT_SCORE_DOCS, SUSPECT_SCORE_NAMES } from '../../content'

export interface SuspectScoreProps {
  id: SuspectScore
  platform: Platform
  defaultWeight: number
}

export function SuspectScoreItem({ id, platform, defaultWeight }: SuspectScoreProps) {
  const { formState, getFieldState, setValue, register, watch } = useFormContext()
  const { errors } = formState
  const { isDirty } = getFieldState(id, formState)
  const value = watch(id)
  const minScore = 0
  const maxScore = 10_000

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const sxRegular = {
    display: 'grid',
    gridTemplateColumns: '1fr 96px 112px',
    gridTemplateAreas: `'name input actions'`,
    columnGap: '16px',
    padding: '16px 0',
    alignItems: 'center',
    '&:not(:last-child)': { borderBottom: '1px solid #E0E0E0' },
  }
  const sxSmall = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `'name actions' 'input input'`,
    rowGap: '12px',
    alignItems: 'center',
    padding: '16px',
    borderRadius: '4px',
    border: '1px solid #F2F2F2',
  }

  function restoreDefault() {
    // Cannot use form defaults since those are used for dirty state tracking.
    setValue(id, defaultWeight.toString(), { shouldDirty: true })
    ampli.smartSignalWeightReset({ smartSignal: id, clientType: PLATFORM_NAMES[platform] })
  }

  function disable() {
    setValue(id, '0', { shouldDirty: true })
    ampli.smartSignalWeightDisabled({ smartSignal: id, clientType: PLATFORM_NAMES[platform] })
  }

  return (
    <Box sx={smDown ? sxSmall : sxRegular} alignItems='center'>
      <Typography variant='bodyMMedium' component='span' sx={{ gridArea: 'name' }}>
        {SUSPECT_SCORE_NAMES[id]}
        {isDirty && (
          <Typography variant='bodyS' component='span' sx={{ marginLeft: '8px', fontStyle: 'italic' }}>
            unsaved
          </Typography>
        )}
      </Typography>
      <TextField
        id={id}
        type='number'
        inputProps={{ min: minScore, max: maxScore }}
        {...muiRegister(register, id, { min: minScore, max: maxScore, required: true })}
        onFocus={() =>
          ampli.smartSignalWeightFocused({
            smartSignal: id,
            clientType: PLATFORM_NAMES[platform],
          })
        }
        onBlur={() =>
          ampli.smartSignalWeightChanged({
            smartSignal: id,
            clientType: PLATFORM_NAMES[platform],
            weightValue: value,
          })
        }
        error={!!errors[id]}
        sx={{ gridArea: 'input' }}
        data-testid={`${id}-input`}
      />
      <Actions
        id={id}
        platform={platform}
        defaultWeight={defaultWeight}
        disable={disable}
        restoreDefault={restoreDefault}
      />
    </Box>
  )
}

interface ActionProps {
  id: SuspectScore
  platform: Platform
  defaultWeight: number
  disable: () => void
  restoreDefault: () => void
}

function Actions({ id, platform, defaultWeight, disable, restoreDefault }: ActionProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 11px',
        gridArea: 'actions',
        justifySelf: 'flex-end',
      }}
    >
      <Tooltip placement='top' title='Set weight to 0, disabling the Smart Signal'>
        <IconButton size='small' onClick={disable} data-testid={`${id}-action-disable`}>
          <RemoveCircleOutline fontSize='tiny' />
        </IconButton>
      </Tooltip>
      <Tooltip placement='top' title={`Restore default weight value (${defaultWeight})`}>
        <IconButton size='small' onClick={restoreDefault} data-testid={`${id}-action-reset`}>
          <Restore fontSize='tiny' />
        </IconButton>
      </Tooltip>
      <Tooltip placement='top' title='Open Smart Signal in docs'>
        <IconButton
          size='small'
          href={SUSPECT_SCORE_DOCS[id]}
          target='_blank'
          onClick={() => ampli.smartSignalDocsOpened({ smartSignal: id, clientType: PLATFORM_NAMES[platform] })}
          data-testid={`${id}-action-docs`}
        >
          <MenuBook fontSize='tiny' />
        </IconButton>
      </Tooltip>
    </Box>
  )
}
