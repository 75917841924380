import { ControllerRenderProps, UseFormRegisterReturn } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { FieldPath } from 'react-hook-form/dist/types/path'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

type ReactHookFormRegister<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>> = (
  name: TFieldName,
  options?: RegisterOptions<TFieldValues, TFieldName>
) => UseFormRegisterReturn

export function muiRegister<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>(
  register: ReactHookFormRegister<TFieldValues, TFieldName>,
  name: TFieldName,
  options?: RegisterOptions<TFieldValues, TFieldName>
) {
  return extractRef(register(name, options))
}

export function extractRef(register: UseFormRegisterReturn | ControllerRenderProps, property = 'inputRef') {
  const { ref, ...rest } = register

  return {
    [property]: ref,
    ...rest,
  }
}
