import { useUnauthorizedRedirect } from 'hooks'
import { useEffect } from 'react'
import { Switch, useHistory } from 'react-router-dom'

export type Props = {
  children: React.ReactNode
}

export function PrivateRoutes({ children }: Props) {
  const redirect = useUnauthorizedRedirect()
  const history = useHistory()

  useEffect(() => {
    if (redirect) {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.get('score') !== null) {
        window.localStorage.setItem('__feedbackScore', urlParams.get('score') ?? '0')
      }

      history.replace(redirect)
    }
  }, [redirect, history])

  if (redirect) {
    return
  }

  return <Switch>{children}</Switch>
}
