import { Alert, Snackbar } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

export interface ToastProps {
  id?: string
  icon?: React.ReactNode | boolean
  message: string | React.ReactNode
  severity?: 'error' | 'success' | 'info' | 'warning' | undefined
  duration?: number
}
export const Toast = ({ id, icon, message, severity = 'error', duration = 3000 }: ToastProps) => {
  const [open, setOpen] = useState(!!message)
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    setOpen(!!message)
  }, [message, id])

  return (
    <Snackbar
      key={id}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
    >
      <Alert icon={icon} severity={severity} data-testid='global-toast-message'>
        {message}
      </Alert>
    </Snackbar>
  )
}
