import CardLogo from 'components/CardLogo'
import { CardBrand } from 'models'
import { memo } from 'react'

import styles from './CardBrands.module.scss'

export default memo(function CardBrands() {
  return (
    <div className={styles.root}>
      {Object.values(CardBrand)
        .filter((brand) => brand !== CardBrand.Unknown)
        .map((brand) => (
          // Without a container, safari doesn't set the height automatically based on image size.
          // https://stackoverflow.com/a/10760276/10061028
          <span key={brand} className={styles.container}>
            <CardLogo key={brand} cardBrand={brand} />
          </span>
        ))}
    </div>
  )
})
