export enum PasswordStrengthLevel {
  Weak,
  Good,
  Strong,
}

export const StrengthLevelsInOrder = [
  PasswordStrengthLevel.Weak,
  PasswordStrengthLevel.Good,
  PasswordStrengthLevel.Strong,
]

export interface PasswordStrengthResponse {
  strengthLevel: PasswordStrengthLevel
  feedback?: string
}
