export type IntegrationFeature = {
  isSetUp: boolean
}

export enum IntegrationStep {
  ApiCalls = 'apiCalls',
  CustomSubdomain = 'subdomainIntegration',
  TrafficRules = 'trafficRules',
  Webhooks = 'webhooks',
  Finished = 'finished',
}

export interface SubscriptionIntegrationStatus {
  currentStep: IntegrationStep
  proxyIntegration?: IntegrationFeature
  hasServerApiCall?: IntegrationFeature
  [IntegrationStep.ApiCalls]: IntegrationFeature & { apiCallsSinceLastAggregation?: string }
  [IntegrationStep.CustomSubdomain]: IntegrationFeature
  [IntegrationStep.TrafficRules]: IntegrationFeature
  [IntegrationStep.Webhooks]: IntegrationFeature
}

export type IntegrationStepUnfinished = Exclude<IntegrationStep, 'finished'>

export const IntegrationStepsInOrder = [
  IntegrationStep.ApiCalls,
  IntegrationStep.CustomSubdomain,
  IntegrationStep.TrafficRules,
  IntegrationStep.Webhooks,
  IntegrationStep.Finished,
]

export const IntegrationStepsInOrderClickable: IntegrationStepUnfinished[] =
  IntegrationStepsInOrder.filter<IntegrationStepUnfinished>(
    (step): step is IntegrationStepUnfinished => step !== IntegrationStep.Finished
  )
