export enum ChangePlanStep {
  Overview = 1,
  Feedback,
  Action,
  Confirmation,
}

export interface ChangePlanProps {
  step: ChangePlanStep
  onChangeStep: (newStep: ChangePlanStep) => void
}
