import { ComponentProps, ForwardedRef, forwardRef } from 'react'
import { composeRenderProps, Link } from 'react-aria-components'
import { tv } from 'tailwind-variants'

import { ButtonExtraProps, buttonStyles } from './Button/Button'

const tabStyles = tv({
  extend: buttonStyles,
  base: 'justify-start',
  variants: {
    size: {
      lg: 'px-2',
    },
  },
})

export type NavigationTabProps = ComponentProps<typeof Link> & ButtonExtraProps & { isIcon?: never }
function NavigationTab(
  { variant = 'ghost', tone, alt, size = 'default', fullWidth, isLoading, tooltip, ...props }: NavigationTabProps,
  ref?: ForwardedRef<HTMLAnchorElement>
) {
  const styleProps = { variant, tone, alt, size, fullWidth } as const

  return (
    <Link
      {...props}
      ref={ref}
      isDisabled={props.isDisabled || isLoading}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabStyles({
          ...renderProps,
          ...styleProps,
          className,
        })
      )}
    />
  )
}

const _NavigationTab = forwardRef(NavigationTab)

export { _NavigationTab as NavigationTab }
