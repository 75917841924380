import { Divider, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { Message } from './Message/Message'
import styles from './Preview.module.scss'

export type MessageData = {
  avatarSrc: string
  title: string
  time: string
  message: string
  style?: React.CSSProperties
}

export type Props = {
  header: string
  tag?: string
  messages: MessageData[]
  botMessage: string
  botAvatar: string
  maxLength?: number
  showDivider?: boolean
}

export const Preview = ({ header, tag, messages, botMessage, botAvatar, maxLength, showDivider = true }: Props) => {
  const currentTime = new Date()
    .toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
    .toUpperCase()

  const truncatedBotMessage =
    maxLength && botMessage.length > maxLength ? botMessage.slice(0, maxLength) + '...' : botMessage
  const [showBotMessage, setShowBotMessage] = useState(false)

  useEffect(() => {
    if (botMessage !== '') {
      setShowBotMessage(true)
    }
  }, [botMessage])
  return (
    <>
      {showBotMessage && (
        <div className={styles.slackMessage}>
          <Typography variant='h2' className={styles.header}>
            {header} {tag && <span className={styles.tag}>{tag}</span>}
          </Typography>
          {messages.map((msg, index) => (
            <Message
              key={index}
              avatarSrc={msg.avatarSrc}
              title={msg.title}
              time={msg.time}
              message={msg.message}
              style={{ ...(index > 0 ? { marginTop: '8px' } : {}), ...msg.style }}
            />
          ))}
          {showDivider && <Divider className={styles.divider}>Today</Divider>}
        </div>
      )}

      {showBotMessage && botMessage !== '' && (
        <Message
          avatarSrc={botAvatar}
          className={styles.botMessage}
          title='Formbot'
          time={currentTime}
          message={truncatedBotMessage}
        />
      )}
    </>
  )
}
