import { Chip } from '@mui/material'
import clsx from 'clsx'
import { ComponentProps } from 'react'

import styles from './HealthChip.module.scss'

const COLOR_CLASS_MAP: Record<ChipColor, string> = {
  gray: styles.grey,
  green: styles.green,
  blue: styles.blue,
  yellow: styles.yellow,
  red: styles.red,
  black: styles.black,
} as const

export type ChipColor = 'gray' | 'green' | 'blue' | 'yellow' | 'red' | 'black'
export const HealthChip = ({ color, ...props }: Omit<ComponentProps<typeof Chip>, 'color'> & { color: ChipColor }) => {
  return (
    <Chip
      {...props}
      classes={{
        root: clsx(styles.severity, COLOR_CLASS_MAP[color], props.className, props.classes?.root),
        label: clsx(styles.label, props.classes?.label),
      }}
    />
  )
}
