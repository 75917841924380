import { Grid, Paper, Typography } from '@mui/material'
import { DateTime } from 'luxon'

import {
  DateRange,
  getDaysInMonth,
  inDateRange,
  isEndOfRange,
  isRangeSameDay,
  isStartOfRange,
  WEEK_DAYS,
} from '../../../../helpers/dateRange'
import { Marker, NavigationAction } from '../../types'
import Day from '../Day/Day'
import Header from '../Header/Header'
import styles from './Month.module.scss'

interface MonthProps {
  date: DateTime
  marker: Marker
  dateRange: DateRange
  minDate: DateTime
  maxDate: DateTime
  canNavBack: boolean
  canNavForward: boolean
  setDate: (date: DateTime) => void
  helpers: {
    inHoverRange: (day: DateTime) => boolean
  }
  handlers: {
    onDayClick: (day: DateTime, marker: Marker) => void
    onDayHover: (day: DateTime, marker: Marker) => void
    onMonthNavigate: (marker: Marker, action: NavigationAction) => void
  }
}

const chunks = (array: ReadonlyArray<DateTime>, size: number): DateTime[][] =>
  Array.from({ length: Math.ceil(array.length / size) }, (_v, i) => array.slice(i * size, i * size + size))

export default function Month({
  helpers,
  handlers,
  date,
  dateRange,
  marker,
  setDate,
  minDate,
  maxDate,
  canNavBack,
  canNavForward,
}: MonthProps) {
  return (
    <Grid
      container
      square
      elevation={0}
      className={styles.root}
      component={Paper}
      data-testid={`date-range-picker-${marker === Marker.FirstMonth ? 'first' : 'second'}-month`}
    >
      <Header
        date={date}
        minDate={minDate}
        maxDate={maxDate}
        setDate={setDate}
        nextDisabled={!canNavForward}
        prevDisabled={!canNavBack}
        onClickPrevious={() => handlers.onMonthNavigate(marker, NavigationAction.Previous)}
        onClickNext={() => handlers.onMonthNavigate(marker, NavigationAction.Next)}
      />

      <Grid item container direction='row' justifyContent='space-between' className={styles.weekDaysContainer}>
        {WEEK_DAYS.map((day) => (
          <Typography color='textSecondary' key={day} variant='body2'>
            {day}
          </Typography>
        ))}
      </Grid>

      <Grid item container direction='column' justifyContent='space-between' className={styles.daysContainer}>
        {chunks(getDaysInMonth(date), 7).map((week, idx) => (
          <Grid key={idx} container direction='row' justifyContent='center'>
            {week.map((day) => {
              const isStart = isStartOfRange(dateRange, day)
              const isEnd = isEndOfRange(dateRange, day)
              const isRangeOneDay = isRangeSameDay(dateRange)
              const highlighted = inDateRange(dateRange, day) || helpers.inHoverRange(day)
              const isDisabled =
                !date.hasSame(day, 'month') || !(day >= minDate.startOf('day') && day <= maxDate.endOf('day'))

              return (
                <Day
                  key={day.toFormat('MM-dd-yyyy')}
                  filled={isStart || isEnd}
                  outlined={day.hasSame(DateTime.now(), 'day')}
                  highlighted={highlighted && !isRangeOneDay}
                  disabled={isDisabled}
                  startOfRange={isStart && !isRangeOneDay}
                  endOfRange={isEnd && !isRangeOneDay}
                  onClick={() => handlers.onDayClick(day, marker)}
                  onHover={() => handlers.onDayHover(day, marker)}
                  value={day.get('day')}
                />
              )
            })}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
