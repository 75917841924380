import { Typography } from '@mui/material'

import styles from './ErrorFallbackUI.module.scss'

export default function ErrorFallbackUI() {
  return (
    <div className={styles.error}>
      <Typography variant='h2' component='h2'>
        Something went wrong!
      </Typography>
      <Typography variant='body2' component='div'>
        Our engineers have been notified. Please try reloading the page if you haven&apos;t already.
      </Typography>
    </div>
  )
}
