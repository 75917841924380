import { useMediaQuery, useTheme } from '@mui/material'
import clsx from 'clsx'

import { CloudflareIntegration } from '../../../../models'
import IntegrationKeyValueList, {
  IntegrationKeyValueListProps,
} from '../IntegrationKeyValueList/IntegrationKeyValueList'
import styles from './CloudflareConfigurationValues.module.scss'

export type CloudflareConfigurationValuesProps = Pick<IntegrationKeyValueListProps, 'className' | 'itemClassName'> & {
  integrationData: CloudflareIntegration
  authError?: string
}

export default function CloudflareConfigurationValues({
  integrationData,
  authError,
  ...props
}: CloudflareConfigurationValuesProps) {
  const fullWorkerPath = `${integrationData.domain}/${integrationData.workerPath}`
  const fullAgentDownloadPath = `${integrationData.domain}/${integrationData.workerPath}/${integrationData.agentDownloadPath}`
  const fullIngressApiPath = `${integrationData.domain}/${integrationData.workerPath}/${integrationData.ingressApiPath}`

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <IntegrationKeyValueList
      {...props}
      testId='cloudflare-configuration-values'
      direction={smDown ? 'row' : 'column'}
      className={clsx(styles.list, props.className)}
      itemClassName={clsx(styles.item, props.itemClassName)}
      items={[
        {
          key: 'Worker name',
          value: integrationData.workerName,
          info: "Name of your integration's Cloudflare worker",
        },
        {
          key: 'Domain',
          value: integrationData.domain,
          info: "Your worker's domain",
        },
        {
          key: 'Account ID',
          value: integrationData.accountId,
          isCopyable: true,
          info: 'Your Cloudflare Account ID',
        },
        {
          key: 'Zone ID',
          value: integrationData.zoneId,
          isCopyable: true,
          info: "Your website's Cloudflare  Zone ID",
        },
        {
          key: 'Worker path',
          value: fullWorkerPath,
          isCopyable: true,
          info: "Your Cloudflare worker is accessible from this path on your website's domain",
        },
        {
          key: 'Script path',
          value: fullAgentDownloadPath,
          isCopyable: true,
          info: (
            <>
              Stored as <code>AGENT_SCRIPT_DOWNLOAD_PATH</code> in your worker&apos;s environment variables. Use this
              path as a download URL for the Fingerprint JS Agent.
            </>
          ),
        },
        {
          key: 'Endpoint',
          value: fullIngressApiPath,
          isCopyable: true,
          info: (
            <>
              Stored as <code>GET_RESULT_PATH</code> in your worker&apos;s environment variables. Use this path as an
              endpoint for Fingerprint identification requests.
            </>
          ),
        },
        {
          key: 'API token',
          // The API Token is encrypted, and we never fetch it, so we just show a placeholder value
          value: authError ? authError : '••••••••••••••••••••••••••••••••••',
          info: 'Your Cloudflare API token with the “Edit workers” permission that Fingerprint uses to keep your integration up to date',
          valueClassName: authError ? styles.errorValue : undefined,
        },
      ]}
    />
  )
}
