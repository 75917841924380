import { AppRoute, buildRoute } from 'appRoutes'
import { useOnboardingRedirect } from 'features/onboarding'
import { useCurrentSubscription } from 'features/subscription'
import { useDocumentTitle } from 'hooks'
import { useHistory } from 'react-router-dom'

export function HomeRedirectPage() {
  useDocumentTitle('Dashboard')
  useOnboardingRedirect()

  const history = useHistory()
  const { currentSubscriptionId } = useCurrentSubscription()

  if (currentSubscriptionId) {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('score') !== null) {
      window.localStorage.setItem('__feedbackScore', urlParams.get('score') ?? '0')
    }
    history.replace(buildRoute(AppRoute.SubscriptionOverview, { subscriptionId: currentSubscriptionId }))
  }

  return null
}
