import { canCancel, canCreate, canRename, canUpgrade } from 'helpers/subscription'
import { ApiKeyType, UserRole } from 'models'

import { canCreateUsers, canDeleteUsers, canEditUsers, canTransferOwnership } from '../helpers/user'
import { useAuth } from './user'

export function usePermissions() {
  const { accessTokenPayload } = useAuth()
  const currentRole = accessTokenPayload?.role

  const isReadOnly = currentRole === UserRole.ReadOnly

  return {
    isReadOnly,

    userPermissions: {
      canCreate: canCreateUsers(currentRole),
      canEdit: canEditUsers(currentRole),
      canDelete: canDeleteUsers(currentRole),
      canTransferOwnership: canTransferOwnership(currentRole),
    },

    subscriptionPermissions: {
      canUpgrade,
      canCreate,
      canCancel,
      canRename,
      canTransferOwnership,
    },

    apiKeyPermissions: {
      canManage: (type: ApiKeyType) =>
        !isReadOnly && !(type === ApiKeyType.Management && currentRole !== UserRole.Owner),
    },
  }
}
