import { ChevronRight } from '@mui/icons-material'
import { Link } from '@mui/material'
import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'

import styles from './DashboardWidgetLink.module.scss'

interface DashboardWidgetLinkProps {
  href?: string
  to?: string
  text: string
  target?: string
  className?: string
  'data-testid'?: string
}

export function DashboardWidgetLink({
  href,
  to,
  text,
  target,
  className,
  'data-testid': testid,
}: DashboardWidgetLinkProps) {
  const linkProps = to ? { to, title: text, component: RouterLink } : { href, title: text, target }
  return to ? (
    <Link
      color='inherit'
      className={clsx(styles.details, styles.secondaryText, className)}
      {...linkProps}
      underline='hover'
      data-testid={testid}
    >
      {text}
      <ChevronRight className={styles.chevron} />
    </Link>
  ) : (
    <Link
      color='inherit'
      className={clsx(styles.details, styles.secondaryText, className)}
      {...linkProps}
      underline='hover'
      data-testid={testid}
    >
      {text}
      <ChevronRight className={styles.chevron} />
    </Link>
  )
}
