import { Paper, Stack } from '@mui/material'
import clsx from 'clsx'
import { ClientSDKPerformanceStatsResponse } from 'models'

import { calculateTicks } from './calculateTicks'
import { EmptyState } from './EmptyState'
import { HealthChart } from './HealthChart'
import { HealthChartHeader } from './HealthChartHeader'
import styles from './LatencyChart.module.scss'

export type LatencyChartProps = {
  performance: ClientSDKPerformanceStatsResponse | undefined
  className?: string
}
export const LatencyChart = ({ performance, className }: LatencyChartProps) => {
  const lines = extractLatencyLines(performance)
  const colors = extractColors(performance)
  const axisYTicks = Math.min(6, Math.max(1, ...lines.flatMap((l) => l.data.map((d) => (d.y ?? 0) as number))))
  const isEmpty = lines.every((l) => l.data.length === 0)

  return (
    <Paper className={clsx(className, styles.root)}>
      <Stack gap={3}>
        <HealthChartHeader
          title='JavaScript Agent get() Request'
          tooltip='How long a Fingerprint identification request takes to return data from the server. Only requests from client-side JavaScript SDKs are counted.'
        />
        <Stack gap={2}>
          {isEmpty ? (
            <EmptyState />
          ) : (
            <HealthChart
              data={lines}
              colors={colors}
              axisYUnit='ms'
              axisYTicks={axisYTicks}
              className={styles.chart}
              fillGaps
            />
          )}
        </Stack>
      </Stack>
    </Paper>
  )
}

function extractLatencyLines(performance: ClientSDKPerformanceStatsResponse | undefined) {
  if (performance === undefined) {
    return []
  }

  const ticks = calculateTicks(performance.from, performance.to)
  const lines = performance.stats
    .map(({ version, data }) => {
      const timestampMap = new Map(data.map((point) => [new Date(point.timestamp).valueOf(), point.getLatencyMedian]))

      return {
        id: `JS v${version}`,
        data: ticks.map((t) => ({
          x: t,
          y: timestampMap.get(t.valueOf()) ?? null,
        })),
      }
    })
    .filter((line) => line.data.some((d) => d.y != null))
    .reverse()

  return lines
}

const COLORS_5 = ['#FF5D22', '#D94F1C', '#B34118', '#621B16', '#0A0A0A']
const COLORS_LOOP = [
  '#FFDA94',
  '#FF9800',
  ...COLORS_5,
  '#A6D0F2',
  '#0D3C61',
  '#4CAF50',
  '#1E4620',
  '#999999',
  '#212121',
]
function extractColors(performance: ClientSDKPerformanceStatsResponse | undefined) {
  if (performance === undefined) {
    return []
  }

  const versions = performance.stats.map((p) => p.version)

  if (versions.length < 6) {
    return COLORS_5
  }

  return Array(versions.length)
    .fill('')
    .map((_, index) => COLORS_LOOP[index % COLORS_LOOP.length])
}
