import { Check, FileCopyOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import clsx from 'clsx'
import { MouseEvent, PropsWithChildren, useEffect, useState } from 'react'

import { copyToClipboard } from '../../helpers/clipboard'
import styles from './WithCopyButton.module.scss'

export interface WithCopyButtonProps {
  text: string
  onCopy?: () => void
  className?: string
}

export function WithCopyButton({ text, children, onCopy, className }: PropsWithChildren<WithCopyButtonProps>) {
  const [isCopied, setIsCopied] = useState(false)

  async function onCopyClick(e: MouseEvent) {
    e.preventDefault()
    // This component might be on top of another interactive element.
    e.stopPropagation()

    await copyToClipboard(text)
    onCopy?.()
    setIsCopied(true)
  }

  useEffect(() => {
    if (!isCopied) {
      return
    }

    const timer = setTimeout(() => setIsCopied(false), 1000)
    return () => clearTimeout(timer)
  }, [isCopied])

  return (
    <Tooltip title='Copied' arrow open={isCopied} placement='bottom'>
      <span className={clsx(styles.copyable, className)} onClick={onCopyClick}>
        {children}
        {isCopied ? (
          <Check className={styles.copyIcon} color='primary' />
        ) : (
          <FileCopyOutlined className={styles.copyIcon} color='primary' />
        )}
      </span>
    </Tooltip>
  )
}
