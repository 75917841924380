import { Button } from '@compass/components'
import { InputLabel, Paper, Skeleton, TextField, Typography } from '@mui/material'
import { GenericError } from 'const'
import { ContentColumn, SubHeader } from 'features/commonUI'
import { useToast } from 'hooks'
import { useCurrentUser, useUserUpdateMutation } from 'hooks/api/users'
import { PersonalInfo, PersonalInfoFormData } from 'models'
import { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import Loader from '../../../components/Loader/Loader'
import { getErrorParams } from '../../../helpers/data'
import { muiRegister } from '../../../helpers/reactHookForm'
import { JobLevelAndFunctionSelector } from './JobLevelAndFunctionSelector/JobLevelAndFunctionSelector'
import styles from './SettingsForms.module.scss'

export function PersonalInfoForm() {
  const { showToast } = useToast()
  const { data: currentUser, isLoading: isLoadingCurrentUser } = useCurrentUser()
  const { mutate: updateUser, isLoading: isUpdatingUser, error: updateUserError } = useUserUpdateMutation()

  const handleUpdatePersonalInfo = useCallback(
    (payload: PersonalInfoFormData) => {
      updateUser(
        {
          data: { ...payload, jobLevel: payload.jobLevel ?? undefined, jobFunction: payload.jobFunction ?? undefined },
          params: { userId: currentUser?.id ?? '' },
        },
        {
          onSuccess: () => {
            showToast({
              message: 'Successfully updated personal info.',
              severity: 'success',
            })
          },
        }
      )
    },
    [currentUser?.id, showToast, updateUser]
  )

  return (
    <PersonalInfoFormContent
      currentUser={currentUser}
      isLoading={isLoadingCurrentUser || isUpdatingUser}
      error={updateUserError}
      onSubmit={handleUpdatePersonalInfo}
    />
  )
}

export interface PersonalInfoFormContentProps {
  currentUser?: PersonalInfo
  isLoading?: boolean
  error?: GenericError | null
  onSubmit: (data: PersonalInfoFormData) => void
}

export function PersonalInfoFormContent({ onSubmit, currentUser, error, isLoading }: PersonalInfoFormContentProps) {
  const formMethods = useForm<PersonalInfoFormData>({
    defaultValues: {
      name: '',
      jobLevel: undefined,
      jobFunction: undefined,
    },
    shouldUnregister: true,
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = formMethods

  useEffect(() => {
    formMethods.resetField('name', { defaultValue: currentUser?.name ?? '' })
    formMethods.resetField('jobLevel', { defaultValue: currentUser?.jobLevel ?? undefined })
    formMethods.resetField('jobFunction', { defaultValue: currentUser?.jobFunction ?? undefined })
  }, [currentUser, formMethods])

  return (
    <ContentColumn>
      <SubHeader
        title='Personal information'
        description='Your contact information and details about your role in your team.'
      />
      <Paper className={styles.card}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <InputLabel>Email</InputLabel>
            {isLoading ? (
              <Skeleton width={256} className={styles.value} />
            ) : (
              <Typography variant='semiBody1' className={styles.value}>
                {currentUser?.email}
              </Typography>
            )}

            <InputLabel htmlFor='name'>Name</InputLabel>
            <TextField
              id='name'
              type='text'
              variant='outlined'
              {...muiRegister(register, 'name', {
                required: 'Name required.',
              })}
              inputProps={{ 'aria-label': 'name' }}
              placeholder={'Name'}
              {...getErrorParams('name', errors, error)}
              className={styles.input}
            />

            <JobLevelAndFunctionSelector containerType='fullPage' />

            <Button
              type='submit'
              aria-label='Save Personal Info'
              isDisabled={!isDirty || isLoading}
              className={styles.button}
            >
              Save
            </Button>
          </form>

          {isLoading && <Loader testId='personal-info-form-loader' />}
        </FormProvider>
      </Paper>
    </ContentColumn>
  )
}
