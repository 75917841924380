import { Variant } from '@amplitude/experiment-js-client'

import { ExperimentFlag, ExperimentValue } from './const'
import { globalExperimentsDisable } from './globalSwitch'

/**
 * Even though `experimentClient` has it's own cache, we do not have a control over it.
 * Moreover `experimentClient.variant()` may function trigger `exposure` event automatically.
 * If we have a feature flag affecting multiple pages, we should avoid triggering `exposure` event multiple times
 * on several pages through the flow
 */
const EXPERIMENT_CACHE_LOCAL_STORAGE_KEY = 'fpjsDashboardExperimentCache'
const EXPERIMENT_CACHE_TTL = 15 * 60 * 1000 // 15 min
export const experimentCache: Record<
  ExperimentFlag,
  {
    variant: Variant
    fetchedAt: number
    exposedAt?: number
    isDefaultValue?: boolean
    isAssignedLocally?: boolean
  }
> = loadExperimentCache()

function loadExperimentCache() {
  return JSON.parse(localStorage.getItem(EXPERIMENT_CACHE_LOCAL_STORAGE_KEY) ?? '{}')
}

export function saveExperimentCache() {
  localStorage.setItem(EXPERIMENT_CACHE_LOCAL_STORAGE_KEY, JSON.stringify(experimentCache))
}

export function invalidateExperimentCache() {
  // Remove all experiment keys except locally assigned ones (which don't depend on userId/customerId)
  Object.keys(experimentCache)
    .reduce(
      (acc, key: ExperimentFlag) => (experimentCache[key].isAssignedLocally ? acc : acc.concat(key)),
      [] as ExperimentFlag[]
    )
    .forEach((keyToDelete) => {
      delete experimentCache[keyToDelete]
    })
  saveExperimentCache()
}

export function getExperimentVariantFromCache(flagKey: ExperimentFlag) {
  const cachedVariant = experimentCache[flagKey]

  if (!cachedVariant || Date.now() - cachedVariant.fetchedAt > EXPERIMENT_CACHE_TTL) {
    return
  }

  return cachedVariant
}

export function getLocalExperimentVariant(flagKey: ExperimentFlag) {
  if (globalExperimentsDisable) {
    return {
      variant: {
        value: ExperimentValue.Control,
        payload: null,
        expKey: '',
      },
      fetchedAt: Date.now(),
      exposedAt: Date.now(),
      isAssignedLocally: true,
    }
  }

  if (!experimentCache[flagKey]) {
    // Local evaluation
    const value = Math.random() < 0.5 ? ExperimentValue.Control : ExperimentValue.Treatment

    experimentCache[flagKey] = {
      variant: {
        value,
        payload: null,
        expKey: '',
      },
      fetchedAt: Date.now(),
      isAssignedLocally: true,
    }

    saveExperimentCache()
  }

  return experimentCache[flagKey]
}
