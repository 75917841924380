import { Grid, Link, Switch, Tooltip, Typography } from '@mui/material'
import { SubHeader } from 'features/commonUI'
import { ampli } from 'models/ampli'
import { useCallback } from 'react'

import { DOCS_SEARCH_BOTS_FILTERING } from '../../../../const'
import {
  useApplicationFeatureToggleMutation,
  useIsApplicationFeatureEnabled,
  usePermissions,
  useToast,
} from '../../../../hooks'
import { ApplicationFeatureName } from '../../../../models'
import { useCurrentSubscription } from '../../../subscription'
import styles from './BotsSection.module.scss'

export default function BotsSection() {
  const { mutate: sendApplicationFeatureToggleRequest } = useApplicationFeatureToggleMutation()
  const { currentSubscriptionId } = useCurrentSubscription()
  const { isReadOnly } = usePermissions()

  const isExcludeSearchBotsEnabled = useIsApplicationFeatureEnabled(
    currentSubscriptionId,
    ApplicationFeatureName.FilterSearchBots
  )
  const { showToast } = useToast()

  const handleChange = useCallback(() => {
    sendApplicationFeatureToggleRequest(
      {
        params: { subscriptionId: currentSubscriptionId },
        data: { featureName: ApplicationFeatureName.FilterSearchBots },
      },
      {
        onSuccess: () => {
          if (!isExcludeSearchBotsEnabled) {
            ampli.searchBotsFilteringEnabled()
          } else {
            ampli.searchBotsFilteringDisabled()
          }
        },
        onError: () => {
          showToast({
            message: 'An error occurred when trying to toggle the exclude search bots feature. Please try again.',
            severity: 'error',
          })
        },
      }
    )
  }, [currentSubscriptionId, sendApplicationFeatureToggleRequest, showToast, isExcludeSearchBotsEnabled])

  return (
    <BotsSectionView
      isToggleEngaged={isExcludeSearchBotsEnabled}
      onToggle={handleChange}
      isEditingDisabled={isReadOnly}
    />
  )
}

export interface BotsSectionViewProps {
  isToggleEngaged: boolean
  isEditingDisabled: boolean
  onToggle: () => void
}

// disconnected for storybook
export function BotsSectionView({ isToggleEngaged, isEditingDisabled, onToggle }: BotsSectionViewProps) {
  return (
    <Grid item xs={12} className={styles.grid}>
      <SubHeader
        title='Bots'
        description={<Typography variant='bodyM'>Filter out unwanted, automated identification requests.</Typography>}
        className='mb-4'
      />

      <section>
        <div className={styles.textSection}>
          <Tooltip title={isEditingDisabled ? 'You do not have sufficient permissions to edit this setting' : ''}>
            <div className={styles.switchContainer}>
              <Switch
                id='active'
                color='primary'
                checked={isToggleEngaged}
                onChange={onToggle}
                disabled={isEditingDisabled}
              />
            </div>
          </Tooltip>
          <div className={styles.text}>
            <Typography variant='h3' component='h1' className={styles.title}>
              Exclude Search Bots
            </Typography>
            <Typography variant='body2'>
              Turn on to exclude search engine bots from your billable usage.{' '}
              <Link underline='hover' className={styles.link} href={DOCS_SEARCH_BOTS_FILTERING} target={'_blank'}>
                Learn more
              </Link>
            </Typography>
          </div>
        </div>
      </section>
    </Grid>
  )
}
