import { Button, ButtonOrLink } from '@compass/components'
import { Close } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import clsx from 'clsx'
import { PropsWithChildren, ReactElement, ReactNode, useEffect, useState } from 'react'

import styles from './DashboardBanner.module.scss'

export interface DashboardBannerProps {
  title: string | React.ReactNode
  action?: string
  actionUrl?: string
  actionHref?: string
  secondaryAction?: string
  secondaryActionUrl?: string
  secondaryActionHref?: string
  secondaryActionOnClose?: () => void
  dismissTitle?: string
  dismissBody?: string | React.ReactNode
  onClose?: () => void
  onShow?: () => void
  onClickAction?: () => void
  onClickSecondaryAction?: () => void
  children?: string | ReactNode
  icon?: ReactElement
  variant?: 'primary' | 'alert' | 'warning' | 'info'
  wideButton?: boolean
  className?: string
  vertical?: boolean
  testIds?: {
    title?: string
    body?: string
    actionButton?: string
    secondaryActionButton?: string
  }
}

export default function DashboardBanner({
  title,
  action,
  actionUrl,
  actionHref,
  secondaryAction,
  secondaryActionUrl,
  secondaryActionHref,
  secondaryActionOnClose,
  dismissTitle,
  dismissBody,
  onClose,
  onShow,
  onClickAction,
  onClickSecondaryAction,
  children,
  icon,
  variant,
  wideButton,
  className,
  vertical,
  testIds,
}: DashboardBannerProps) {
  const [isDismissConfirmOpen, setIsDismissConfirmOpen] = useState(false)
  const shouldConfirmDismiss = dismissTitle && dismissBody

  function handleRequestDismiss() {
    setIsDismissConfirmOpen(true)
  }

  function handleCancelDismiss() {
    setIsDismissConfirmOpen(false)
  }

  function handleConfirmDismiss() {
    setIsDismissConfirmOpen(false)
    onClose?.()
    secondaryActionOnClose?.()
  }

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    onShow?.()
    // Avoid triggering on-rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <section
        className={clsx(styles.root, className, {
          [styles.alert]: variant === 'alert',
          [styles.warning]: variant === 'warning',
          [styles.vertical]: vertical,
          [styles.withoutCloseIcon]: !onClose,
        })}
      >
        <div className={styles.textSection}>
          {icon && (
            <span
              className={clsx(styles.icon, {
                [styles.alertIcon]: variant === 'alert',
                [styles.warningIcon]: variant === 'warning' || variant === 'info',
              })}
            >
              {icon}
            </span>
          )}
          <div className={styles.text}>
            <Typography variant='h3' component='h1' className={styles.title} data-testid={testIds?.title}>
              {title}
            </Typography>
            <Typography variant='body2' data-testid={testIds?.body}>
              {children}
            </Typography>
          </div>
        </div>

        <div className={clsx(styles.actions, { [styles.withClose]: onClose })}>
          {onClose && (
            <IconButton
              size='small'
              onClick={() => {
                if (shouldConfirmDismiss) {
                  handleRequestDismiss()
                } else {
                  onClose()
                }
              }}
            >
              <Close />
            </IconButton>
          )}
          <div className={styles.buttons}>
            {secondaryAction &&
              (secondaryActionOnClose ? (
                <Button
                  variant='ghost'
                  size='lg'
                  alt
                  className={clsx(styles.bannerButton, {
                    [styles.alertButtonSecondary]: variant === 'alert',
                    [styles.warningButtonSecondary]: variant === 'warning',
                  })}
                  data-testid={testIds?.secondaryActionButton}
                  onPress={() => {
                    if (shouldConfirmDismiss) {
                      handleRequestDismiss()
                    } else {
                      secondaryActionOnClose()
                    }
                  }}
                  fullWidth={smDown}
                >
                  {secondaryAction}
                </Button>
              ) : (
                <ButtonOrLink
                  target={secondaryActionHref ? '_blank' : undefined}
                  href={secondaryActionUrl ?? secondaryActionHref}
                  onPress={onClickSecondaryAction}
                  variant='ghost'
                  size='lg'
                  alt
                  data-testid={testIds?.secondaryActionButton}
                  className={clsx(styles.bannerButton, {
                    [styles.alertButtonSecondary]: variant === 'alert',
                    [styles.wideButton]: wideButton,
                  })}
                  fullWidth={smDown}
                >
                  {secondaryAction}
                </ButtonOrLink>
              ))}
            {action && (
              <ButtonOrLink
                target={actionHref ? '_blank' : undefined}
                href={actionUrl ?? actionHref}
                onPress={onClickAction}
                size='lg'
                data-testid={testIds?.actionButton}
                className={clsx(styles.bannerButton, {
                  [styles.alertButton]: variant === 'alert',
                  [styles.wideButton]: wideButton,
                })}
                fullWidth={smDown}
              >
                {action}
              </ButtonOrLink>
            )}
          </div>
        </div>
      </section>

      {shouldConfirmDismiss && (
        <ConfirmDismiss
          open={isDismissConfirmOpen}
          title={dismissTitle}
          onClose={handleCancelDismiss}
          onConfirm={handleConfirmDismiss}
        >
          {dismissBody}
        </ConfirmDismiss>
      )}
    </>
  )
}

interface ConfirmDismissProps {
  title: string
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

function ConfirmDismiss({ title, open, children, onClose, onConfirm }: PropsWithChildren<ConfirmDismissProps>) {
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: styles.confirmDismiss }}>
      <header className={styles.dialogTitle}>
        <Typography variant='h2'>{title}</Typography>
        <IconButton size='small' onClick={onClose}>
          <Close />
        </IconButton>
      </header>

      <DialogContent className={styles.dialogContent}>{children}</DialogContent>

      <DialogActions className={styles.dialogActions}>
        <Button onPress={onConfirm}>Done</Button>
      </DialogActions>
    </Dialog>
  )
}
