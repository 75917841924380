import { ErrorOutline, Schedule } from '@mui/icons-material'
import { StaticMessage } from 'components'
import { GenericError } from 'const'
import { errorWithFallback } from 'helpers/data'
import { useAuth, useDocumentTitle, useHomepageRedirect } from 'hooks'
import { useInvitationConfirmMutation, useInvitationValidate } from 'hooks/api/account'
import { useQueryParams } from 'hooks/queryParams'
import { ComponentProps, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { AppRoute, useRouteParams } from '../../../../appRoutes'
import { FakeDashboardInfoLayout } from '../../components/AnimatedLayout/FakeDashboardInfoLayout'
import { InvitationConfirmForm } from '../../components/InvitationConfirmForm/InvitationConfirmForm'

export function InvitationConfirmPage() {
  useHomepageRedirect()
  useDocumentTitle('Set up your user profile')

  const { id } = useRouteParams<AppRoute.InvitationConfirm>()
  const { redirect_to } = useQueryParams()
  const { confirmationCode } = useQueryParams()
  const history = useHistory()
  const { isAuthorized } = useAuth()

  const { mutate: sendInvitationConfirmRequest, isLoading, error } = useInvitationConfirmMutation()
  const { isError: isInvalid, isSuccess: isValid, error: validationError } = useInvitationValidate(id, !isAuthorized)

  // handle form submit
  const onSubmit = useCallback<ComponentProps<typeof InvitationConfirmForm>['onSubmit']>(
    (payload) => {
      sendInvitationConfirmRequest(
        { data: { ...payload, confirmationCode, termsOfService: true, privacyPolicy: true }, params: { id } },
        { onSuccess: () => history.replace(redirect_to ? decodeURIComponent(redirect_to) : '/') }
      )
    },
    [sendInvitationConfirmRequest, id, confirmationCode, history, redirect_to]
  )

  if (isInvalid) {
    return (
      <FakeDashboardInfoLayout>
        <StaticMessage
          Icon={ErrorOutline}
          title='Profile setup'
          message={errorWithFallback(validationError as GenericError, 'id', 'The link is invalid')?.message || ''}
          showRedirect
        />
      </FakeDashboardInfoLayout>
    )
  }

  if (error) {
    return (
      <FakeDashboardInfoLayout>
        <StaticMessage
          Icon={ErrorOutline}
          title='Profile setup'
          message={errorWithFallback(error, 'id', 'Could not set up your profile')?.message || ''}
          showRedirect
        />
      </FakeDashboardInfoLayout>
    )
  }

  if (isValid) {
    return (
      <FakeDashboardInfoLayout>
        <InvitationConfirmForm isLoading={isLoading} onSubmit={onSubmit} />
      </FakeDashboardInfoLayout>
    )
  }

  return (
    <FakeDashboardInfoLayout>
      <StaticMessage Icon={Schedule} isLoading={true} title='Please wait' message='Verifying link...' showRedirect />
    </FakeDashboardInfoLayout>
  )
}
