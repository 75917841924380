import FullpageLoader from 'components/Loader/FullpageLoader/FullpageLoader'
import { useLastViewedSubscription, useSubscriptions } from 'hooks'
import { DateTime } from 'luxon'
import { Redirect, useHistory } from 'react-router-dom'

import { date } from '../../../../helpers/data'

/**
 * Redirects /route to /workspaces/primarySubscriptionId/route
 *
 * Allows sharing links to subscription specific routes without knowing the subscription id.
 */
export function SubscriptionShortcutRedirect() {
  const history = useHistory()
  const [lastViewedSubscriptionId] = useLastViewedSubscription()
  const { primarySubscription, isLoading, error } = usePrimarySubscription()

  const path = history.location.pathname.split('/').slice(1).join('/')

  if (lastViewedSubscriptionId) {
    return <Redirect to={`/workspaces/${lastViewedSubscriptionId}/${path}`} />
  }

  if (error) {
    return <Redirect to='/' />
  }

  if (!isLoading && primarySubscription) {
    return <Redirect to={`/workspaces/${primarySubscription.id}/${path}`} />
  }

  return <FullpageLoader />
}

function usePrimarySubscription() {
  const { data: subscriptions, isLoading, error } = useSubscriptions()

  if (!subscriptions || isLoading || error) {
    return {
      primarySubscription: undefined,
      isLoading,
      error,
    }
  }

  const sortedSubscriptions = subscriptions.sort(
    ({ usageQuota: aUsageQuota, createdAt: aCreatedAt }, { usageQuota: bUsageQuota, createdAt: bCreatedAt }) => {
      const usageDiff = (bUsageQuota?.billed ?? 0) - (aUsageQuota?.billed ?? 0)

      if (usageDiff === 0) {
        return DateTime.fromJSDate(date(bCreatedAt)) < DateTime.fromJSDate(date(aCreatedAt)) ? -1 : 1
      }

      return usageDiff
    }
  )

  return { primarySubscription: sortedSubscriptions?.[0], isLoading, error }
}
