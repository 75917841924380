import { MailOutline } from '@mui/icons-material'
import { Link, SvgIconTypeMap, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { AppRoute } from 'appRoutes'
// TODO: remove dependencies on auth feature
// eslint-disable-next-line custom-rules/feature-root-import
import styles from 'features/auth/components/AuthForm/AuthForm.module.scss'
// eslint-disable-next-line custom-rules/feature-root-import
import { AuthWrapper } from 'features/auth/components/AuthWrapper/AuthWrapper'
import { Link as RouterLink } from 'react-router-dom'

export type Props = {
  title: string
  message: string | React.ReactNode
  showRedirect?: boolean
  showConsentForm?: boolean
  isLoading?: boolean
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | null
  redirectText?: string
  redirectTo?: string
  redirectHref?: string
}

export function StaticMessage({
  Icon = MailOutline,
  title,
  message,
  showRedirect = false,
  showConsentForm = false,
  isLoading = false,
  redirectText = 'Log In',
  redirectTo = AppRoute.Login,
  redirectHref,
}: Props) {
  return (
    <AuthWrapper Icon={Icon} isLoading={isLoading} title={title} showConsentForm={showConsentForm}>
      <Typography className={styles.message}>{message}</Typography>
      {showRedirect && (
        <div className={styles.redirect}>
          {redirectHref ? (
            <Link component='a' href={redirectHref} underline='hover'>
              {redirectText}
            </Link>
          ) : (
            <Link component={RouterLink} to={redirectTo} underline='hover'>
              {redirectText}
            </Link>
          )}
        </div>
      )}
    </AuthWrapper>
  )
}
