export enum BotResult {
  Good = 'good',
  Bad = 'bad',
  NotDetected = 'notDetected',
}

export enum GoodBot {
  Google = 'google',
  Mail = 'mail',
  Bing = 'bing',
  Apple = 'apple',
  Petal = 'petal',
  Naver = 'naver',
  Yahoo = 'yahoo',
  Baidu = 'baidu',
  Sogou = 'sogou',
  Exabot = 'exabot',
  Coccoc = 'coccoc',
  Seznam = 'seznam',
  Yandex = 'yandex',
  Bytedance = 'bytedance',
  Duckduckgo = 'duckduckgo',
}

export enum BadBot {
  Electron = 'electron',
  Nightmarejs = 'nightmarejs',
  Geb = 'geb',
  Awesomium = 'awesomium',
  Phantomas = 'phantomas',
  Couchjs = 'couchjs',
  Rhino = 'rhino',
  Nodejs = 'nodejs',
  Chromium = 'chromium',
  Casperjs = 'casperjs',
  Slimerjs = 'slimerjs',
  Sequentum = 'sequentum',
  Phantomjs = 'phantomjs',
  Puppeteer = 'puppeteer',
  Pyppeteer = 'pyppeteer',
  Playwright = 'playwright',
  Selenium = 'selenium',
  Headless = 'headless',
  HeadlessChrome = 'headlessChrome',
  IphoneSimulator = 'iphoneSimulator',
  Webdriver = 'webdriver',
  Beef = 'beef',
  Xenotix = 'xenotix',
  SleepyPuppy = 'sleepyPuppy',
  Sonar = 'sonar',
  LittleDoctor = 'littleDoctor',
  XBackdoor = 'xBackdoor',
  DominatorPro = 'dominatorPro',
  Unknown = 'unknown',

  // Fake search bots
  GoogleFakeBot = 'googleFakeBot',
  MailFakeBot = 'mailFakeBot',
  BingFakeBot = 'bingFakeBot',
  AppleFakeBot = 'appleFakeBot',
  PetalFakeBot = 'petalFakeBot',
  NaverFakeBot = 'naverFakeBot',
  YahooFakeBot = 'yahooFakeBot',
  BaiduFakeBot = 'baiduFakeBot',
  SogouFakeBot = 'sogouFakeBot',
  ExabotFakeBot = 'exabotFakeBot',
  CoccocFakeBot = 'coccocFakeBot',
  SeznamFakeBot = 'seznamFakeBot',
  YandexFakeBot = 'yandexFakeBot',
  BytedanceFakeBot = 'bytedanceFakeBot',
  DuckduckgoFakeBot = 'duckduckgoFakeBot',
}

export interface BotdVisit {
  requestId: string
  botResult: BotResult
  botType: GoodBot | BadBot
  referer: string
  timestamp: number
  ipAddress: string
  ipLocation?: {
    country?: {
      code: string
      name: string
    }
  }
}

export interface BotdVisitsFilter {
  from?: number
  before?: number
  requestId?: string
}

export interface PaginatedBotdVisitsResponse {
  events: BotdVisit[]
  paginationKey?: string
}

export interface BotdVisitDetails {
  bot: {
    result: string
    type: string
  }
  url: string
  ip: string
  time: string
  userAgent: string
}

const goodBots = Object.values(GoodBot) as string[]

export function isGoodBot(botType: string) {
  return goodBots.includes(botType)
}
