import { Link, Tooltip } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { formatDatetime } from 'features/commonUI'
import { SDKIntegration, ThirdPartyIntegration } from 'features/integrations'
import { useCurrentSubscription } from 'features/subscription'
import { formatDateTimeDistance, formatNum } from 'helpers/format'
import { SdkDifferenceSeverity } from 'models'
import { Link as RouterLink } from 'react-router-dom'

import { SemanticVersion } from '../../utils/SemanticVersion'
import { LoadingValue } from './LoadingValue'
import { RowType } from './SdkHealthRowDetails'

const INTEGRATION_NAME_MAP = new Map([
  ['.NET', SDKIntegration.DotNet],
  ['Akamai', ThirdPartyIntegration.Akamai],
  ['Android SDK', SDKIntegration.Android],
  ['Angular', SDKIntegration.Angular],
  ['Azure', ThirdPartyIntegration.Azure],
  ['Cloudflare', ThirdPartyIntegration.Cloudflare],
  ['Cloudfront', ThirdPartyIntegration.CloudFront],
  ['Custom Subdomain', undefined],
  ['Custom', undefined],
  ['Flutter (android)', SDKIntegration.Flutter],
  ['Flutter (ios)', SDKIntegration.Flutter],
  ['Flutter (js)', SDKIntegration.Flutter],
  ['Flutter', SDKIntegration.Flutter],
  ['Go', SDKIntegration.Golang],
  ['iOS SDK', SDKIntegration.IOS],
  ['Java', SDKIntegration.Java],
  ['JS Agent', SDKIntegration.JavaScript],
  ['Next.js', SDKIntegration.NextJS],
  ['Node', SDKIntegration.Node],
  ['PHP', SDKIntegration.PHP],
  ['Preact', SDKIntegration.Preact],
  ['Python', SDKIntegration.Python],
  ['React JS', SDKIntegration.React],
  ['React Native', SDKIntegration.ReactNative],
  ['React Next.js', SDKIntegration.NextJS],
  ['React Preact', SDKIntegration.Preact],
  ['SPA', undefined],
  ['Svelte', SDKIntegration.Svelte],
  ['Vue', SDKIntegration.Vue],
] as [string, SDKIntegration | ThirdPartyIntegration | undefined][])

export const SdkName = ({ value, integrationName }: { value?: string; integrationName?: string }) => {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  if (value == null) {
    return <LoadingValue />
  }

  const integrationTag = INTEGRATION_NAME_MAP.get(integrationName ?? '')
  if (integrationName != null && integrationTag !== undefined) {
    return (
      <Link
        component={RouterLink}
        to={buildRoute(AppRoute.Integration, {
          subscriptionId,
          integrationTag,
        })}
        underline='hover'
      >
        {value}
      </Link>
    )
  }

  return <>{value}</>
}

export const SdkVersion = ({
  value,
  severity,
  latestVersion,
}: {
  value?: string
  severity?: SdkDifferenceSeverity
  latestVersion?: string
}) => {
  if (value === '' || value === '0.0.0') {
    return null
  }

  if (value == null) {
    return <LoadingValue />
  }

  const currentSV = SemanticVersion.fromString(value)
  const latestSV = SemanticVersion.fromString(latestVersion ?? '')

  if (severity == null || severity === 'latest' || latestVersion == null || currentSV == null || latestSV == null) {
    return <>{`v${value}`}</>
  }

  if (severity === 'patch') {
    return <>{`v${value}`} (patch available)</>
  }

  const difference = latestSV[severity] - currentSV[severity]

  if (difference > 0) {
    return (
      <>
        {`v${value}`} ({difference} {severity} {`version${difference > 1 ? 's' : ''}`} behind)
      </>
    )
  }

  return <>{`v${value}`}</>
}

export const SdkLastRequest = ({ value }: { value?: string }) => (
  <LoadingValue>
    {value ? (
      <Tooltip title={formatDatetime(value, 'precise')} arrow>
        <span>{formatDateTimeDistance(value)}</span>
      </Tooltip>
    ) : null}
  </LoadingValue>
)

export const SdkRequestCount = ({ value, short }: { value?: number; short?: boolean }) => {
  if (value == null) {
    return <LoadingValue />
  }

  return <>{`${formatNum(value)}${short ? '' : ' requests'}`}</>
}

export const SdkLatestVersion = ({ value, url }: { value?: string; url?: string }) => {
  if (value === '' || value === '0.0.0') {
    return null
  }

  if (value == null) {
    return <LoadingValue />
  }

  if (url != null && url !== '') {
    return (
      <Link href={url} underline='hover' target='_blank'>
        {`v${value}`}
      </Link>
    )
  }

  return <>{`v${value}`}</>
}

const protocolRegex = /^https?:\/\//i
export const ResourceIdentifier = ({ value, type }: { value?: string; type: RowType }) => {
  if (type === 'web') {
    return <LoadingValue>{value?.replace(protocolRegex, '') ?? null}</LoadingValue>
  }

  return <LoadingValue>{value ?? null}</LoadingValue>
}
