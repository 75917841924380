import { ChevronsUpDown, OctagonAlert } from 'lucide-react'
import React from 'react'
import {
  Button,
  ButtonProps as AriaButtonProps,
  composeRenderProps,
  ListBox,
  ListBoxProps,
  Select as AriaSelect,
  SelectProps as AriaSelectProps,
  SelectValue as AriaSelectValue,
  SelectValueProps as AriaSelectValueProps,
  Separator,
  SeparatorProps,
  ValidationResult,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import { Description, FieldError, Label } from '../Field'
import { DropdownItem, DropdownItemProps, DropdownSection, DropdownSectionProps } from '../Listbox'
import { Popover, PopoverProps } from '../Popover'
import { composeTailwindRenderProps } from '../utils'

const styles = tv({
  base: [
    'relative',
    'select-none',
    'inline-flex flex-shrink-0 items-center whitespace-nowrap gap-2 pl-3 pr-10 py-2',
    'rounded-md',
    '[.button-group_&]:flex-grow',
    '[.button-group_&]:rounded-none [.button-group_&]:first:rounded-l-md [.button-group_&]:last:rounded-r-md',
    '[.button-group-vertical_&]:rounded-none [.button-group-vertical_&]:first:rounded-t-md [.button-group-vertical_&]:last:rounded-b-md',
    'text-base font-medium',
    'transition-colors duration-100',
    'outline-none',
    'focus-visible:z-10 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary',
    'disabled:cursor-not-allowed',
  ],
  variants: {
    isDisabled: {
      false: [
        'bg-white text-gray-1000 border border-gray-400',
        'shadow-button',
        '[&_svg]:text-gray-900',
        'hover:bg-gray-200',
        'pressed:bg-gray-300 pressed:shadow-inset-t-black-100',
        'disabled:bg-white disabled:border-gray-400 disabled:text-gray-600 [&_svg]:disabled:text-gray-600',
        'group-invalid:bg-red-100 group-invalid:border-red-800 group-invalid:dark:border-red-600 group-invalid:forced-colors:border-red-400',
      ],
      true: 'opacity-50 pointer-events-none',
    },
    tone: {
      secondary: [
        'bg-white text-gray-1000 border border-gray-400',
        'shadow-button',
        '[&_svg]:text-gray-900',
        'hover:bg-gray-200',
        'pressed:bg-gray-300 pressed:shadow-inset-t-black-100',
        'disabled:bg-white disabled:border-gray-400 disabled:text-gray-600 [&_svg]:disabled:text-gray-600',
      ],
      background1: [
        'bg-gray-100',
        'hover:bg-gray-200',
        'pressed:bg-gray-300',
        'disabled:bg-gray-100 disabled:border-gray-400 disabled:text-gray-600',
      ],
      background2: [
        'bg-gray-200',
        'hover:bg-gray-300',
        'pressed:bg-gray-400',
        'disabled:bg-gray-200 disabled:border-gray-400 disabled:text-gray-600',
      ],
    },
    size: {
      default: 'h-8',
      lg: 'h-9',
    },
    fullWidth: {
      true: 'w-full',
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

export function SelectValue<T extends object>({ className, ...props }: AriaSelectValueProps<T>) {
  return (
    <AriaSelectValue
      className={composeTailwindRenderProps(
        className,
        'line-clamp-1 placeholder-shown:text-gray-800 truncate [&>[slot=description]]:hidden'
      )}
      {...props}
    />
  )
}
interface SelectTriggerProps extends AriaButtonProps {
  prefix?: React.ReactNode
  label?: string
  isLabelInline?: boolean
  action?: React.ReactNode
  tone?: 'background1' | 'background2' | 'secondary'
  size?: 'default' | 'lg'
  fullWidth?: boolean
}

export function SelectTrigger({
  prefix,
  label,
  isLabelInline = false,
  action,
  tone = 'background1',
  size = 'default',
  fullWidth,
  ...props
}: SelectTriggerProps) {
  return (
    <>
      {((label && !isLabelInline) || action) && (
        <span className='flex items-center'>
          {label && !isLabelInline && <Label>{label}</Label>}
          {action && <span className='ml-auto'>{action}</span>}
        </span>
      )}
      <Button
        {...props}
        className={composeRenderProps(props.className, (className, renderProps) =>
          styles({ ...renderProps, tone, size, fullWidth, className })
        )}
      >
        {composeRenderProps(props.children, (children) => (
          <>
            {prefix && <span className='leading-4 flex-shrink-0 text-gray-800'>{prefix}</span>}
            {label && isLabelInline && <Label className='text-gray-800'>{label}</Label>}
            {children}
            <ChevronsUpDown
              size={16}
              aria-hidden
              className='absolute right-3 text-gray-800 dark:text-gray-300 forced-colors:text-gray-800 group-disabled:text-gray-600 dark:group-disabled:text-gray-400 forced-colors:group-disabled:text-gray-600'
            />
          </>
        ))}
      </Button>
    </>
  )
}

export function SelectPopover({ className, ...props }: PopoverProps) {
  return <Popover {...props} className={composeTailwindRenderProps(className, 'min-w-[var(--trigger-width,_150px)]')} />
}

export function SelectListBox<T extends object>({ className, ...props }: ListBoxProps<T>) {
  return (
    <ListBox
      {...props}
      className={composeTailwindRenderProps(
        className,
        'outline-none p-1 max-h-[inherit] overflow-auto [clip-path:inset(0_0_0_0_round_.5rem)]'
      )}
    />
  )
}

export function SelectItem(props: DropdownItemProps) {
  return <DropdownItem {...props} />
}

export function SelectSeparator(props: SeparatorProps) {
  return <Separator {...props} className='-mx-1 my-1 border-b border-gray-300 dark:border-gray-700' />
}

export function SelectSection<T extends object>(props: DropdownSectionProps<T>) {
  return <DropdownSection {...props} />
}

interface SelectProps<T extends object>
  extends Omit<AriaSelectProps<T>, 'children'>,
    Pick<SelectTriggerProps, 'prefix' | 'label' | 'isLabelInline' | 'action' | 'tone' | 'size'> {
  description?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
  items?: Iterable<T>
  children: React.ReactNode | ((item: T) => React.ReactNode)
}

export function Select<T extends object>({
  description,
  errorMessage,
  items,
  children,
  className,
  prefix,
  label,
  isLabelInline,
  action,
  tone,
  size,
  ...props
}: SelectProps<T>) {
  return (
    <AriaSelect {...props} className={composeTailwindRenderProps(className, 'group flex flex-col gap-1')}>
      <SelectTrigger
        prefix={prefix}
        label={label}
        isLabelInline={isLabelInline}
        action={action}
        tone={tone}
        size={size}
      >
        <SelectValue />
      </SelectTrigger>
      {description && <Description className='text-xs'>{description}</Description>}
      <FieldError className='flex items-center'>
        <>
          <OctagonAlert className='mr-2' size={14} />
          {errorMessage}
        </>
      </FieldError>
      <SelectPopover>
        <SelectListBox items={items}>{children}</SelectListBox>
      </SelectPopover>
    </AriaSelect>
  )
}
