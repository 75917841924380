import { List, ListItem, ListItemText } from '@mui/material'

import { useDateRangeContext } from '../../features/commonUI'
import { isPredefinedRange, PredefinedRange } from '../../helpers/dateRange'

type DefinedRangesProps = {
  setRange: (range: PredefinedRange) => void
  ranges: PredefinedRange[]
}

export default function DefinedRanges({ ranges, setRange }: DefinedRangesProps) {
  const { dateRange } = useDateRangeContext()
  return (
    <List>
      {ranges.map((range) => (
        <ListItem button key={range.label} onClick={() => setRange(range)}>
          <ListItemText
            primaryTypographyProps={{
              variant: 'body1',
              style: {
                fontWeight: dateRange && isPredefinedRange(dateRange) && range.id === dateRange.id ? '500' : '400',
              },
            }}
          >
            {range.label}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  )
}
