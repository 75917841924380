import { AppRoute, buildRoute, RouteParams } from 'appRoutes'
import { CustomDateRange, isPredefinedRange, PredefinedRange } from 'helpers/dateRange'
import { useQueryParams } from 'hooks/queryParams'
import { DateTime } from 'luxon'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { toCustomDateRange, useDateRangeContext, useDefaultPredefinedRanges } from '../DateRangeContext'
import { DefaultDateRangePicker, DefaultDateRangePickerProps } from './DefaultDateRangePicker'

type PersistentDateRangePickerProps<R extends AppRoute> = DefaultDateRangePickerProps & {
  defaultDateRange: CustomDateRange
  route: R
  routeParams: RouteParams<R>
}

/**
 * Date picker implementation which saves current value to the URL query params
 * and reads current values from query params
 */
export const PersistentDateRangePicker = <R extends AppRoute>({
  defaultDateRange,
  route,
  routeParams,
  ...dateRangeProps
}: PersistentDateRangePickerProps<R>) => {
  const history = useHistory()
  const { dateRange, setDateRange, today } = useDateRangeContext()
  const { ranges } = useDefaultPredefinedRanges()
  const { since, before, period, ...otherParams } = useQueryParams()

  useEffect(() => {
    setDateRange?.((oldDateRange) => {
      if (today && period) {
        return ranges[period as PredefinedRange['id']] ?? ranges.last_7_days
      } else if (since && before) {
        return toCustomDateRange({ startDate: DateTime.fromISO(since), endDate: DateTime.fromISO(before) })
      } else if (oldDateRange == null) {
        return defaultDateRange
      }
      return oldDateRange
    })
  }, [defaultDateRange, ranges, setDateRange, today, period, since, before])

  return (
    <DefaultDateRangePicker
      initialDateRange={dateRange}
      {...dateRangeProps}
      onApplyRange={(range: CustomDateRange | PredefinedRange) => {
        dateRangeProps.onApplyRange?.(range)
        history.replace(
          buildRoute(
            route,
            routeParams,
            isPredefinedRange(range)
              ? { ...otherParams, period: range.id }
              : {
                  ...otherParams,
                  since: range.startDate.toISO()!,
                  before: range.endDate.toISO()!,
                }
          )
        )
      }}
    />
  )
}
