import { Skeleton, Typography } from '@mui/material'
import { SUBSCRIPTION_STATUSES } from 'const'
import { useCurrentSubscription } from 'features/subscription'
import { useSubscription } from 'hooks'
import { SubscriptionStatus } from 'models'

import styles from './StatusWidget.module.scss'
import { Widget } from './Widget'

type StatusColor = 'blue' | 'green' | 'orange' | 'red' | 'gray'
const STATUS_COLOR_MAP: Record<SubscriptionStatus, StatusColor> = {
  [SubscriptionStatus.Creating]: 'green',
  [SubscriptionStatus.Trialing]: 'blue',
  [SubscriptionStatus.Active]: 'green',
  [SubscriptionStatus.ProofOfConcept]: 'green',
  [SubscriptionStatus.Canceled]: 'red',
  [SubscriptionStatus.PastDue]: 'orange',
  [SubscriptionStatus.Unpaid]: 'orange',
  [SubscriptionStatus.Incomplete]: 'orange',
  [SubscriptionStatus.IncompleteExpired]: 'orange',
  [SubscriptionStatus.Restricted]: 'orange',
}

export function StatusWidget() {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: subscription } = useSubscription(currentSubscriptionId)

  return <StatusWidgetContent status={subscription?.status} />
}

export function StatusWidgetContent({ status }: { status?: SubscriptionStatus }) {
  const label = status ? SUBSCRIPTION_STATUSES[status].name : undefined
  const color = status ? STATUS_COLOR_MAP[status] : ''

  return (
    <Widget title='Status'>
      <div className={styles.status} data-color={color}>
        <Typography variant='bodyMMedium'>{label ?? <Skeleton width={80} data-testid='skeleton' />}</Typography>
      </div>
    </Widget>
  )
}
