import { useUserSettings, useUserSettingsUpdate } from 'hooks/api/useUserSettings'
import { LastSeenElementItem } from 'models'
import { useCallback } from 'react'

const SETTING_NAME = 'last_seen_element'

export function useLastSeen(elementName: LastSeenElementItem['element']) {
  const { data: lastSeenElements } = useUserSettings(SETTING_NAME)
  const { mutate: updateUserSettings } = useUserSettingsUpdate(SETTING_NAME)

  const lastSeen = lastSeenElements?.find((e) => e.element === elementName)?.timestamp ?? null

  const setLastSeen = useCallback(
    (timestamp: string | null) => {
      updateUserSettings({
        data: {
          settings_name: SETTING_NAME,
          settings: lastSeenElements?.map((e) => (e.element !== elementName ? e : { ...e, timestamp })) ?? [],
        },
      })
    },
    [elementName, lastSeenElements, updateUserSettings]
  )

  return [lastSeen, setLastSeen, lastSeenElements == null] as const
}
