import {
  DistributionItemType,
  InsightItemType,
  SubscriptionDistribution,
  UserSubscriptionSettings,
} from '../../../../models'

export function distributionsFilter(metric: InsightItemType | DistributionItemType): metric is DistributionItemType {
  return metric.statType === 'distribution'
}

export function filterNonEmptyTypes(type: SubscriptionDistribution | undefined): type is SubscriptionDistribution {
  return type !== undefined
}

export function insightFilter(metric: InsightItemType | DistributionItemType): metric is InsightItemType {
  return metric.statType === 'insight'
}

export function replaceByIndex(
  settings: UserSubscriptionSettings['metric_list_preference'],
  index: number,
  value: InsightItemType | DistributionItemType
) {
  const stats = settings.filter(insightFilter)
  const distributions = settings.filter(distributionsFilter)
  if (value.statType === 'insight') {
    stats[index] = value
  }
  if (value.statType === 'distribution') {
    distributions[index] = value
  }

  return [...stats.slice(0, 4), ...distributions.slice(0, 3)]
}
