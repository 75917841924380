import { Table, TableBody, TableBodyData, TableCell, TableHead, TableRow } from 'components/Table/Table'
import { SdkRowDetails } from 'models/sdkHealth'
import { ComponentProps, useCallback, useState } from 'react'

import { SdkHealthFullTableRow } from './SdkHealthFullTableRow'
import { RowType } from './SdkHealthRowDetails'

const skeletonRows = [undefined, undefined]

type SdkHealthFullTableProps = {
  rows?: SdkRowDetails[]
  type: RowType
}
export const SdkHealthFullTable = ({ rows, type }: SdkHealthFullTableProps) => {
  const [openRowsInGroup, setOpenRowsInGroup] = useState(0)
  const handleToggle = useCallback<ComponentProps<typeof SdkHealthFullTableRow>['onToggle']>(
    (isOpen) => setOpenRowsInGroup((o) => o + (isOpen ? 1 : -1)),
    []
  )

  return (
    <Table style={{ minWidth: 'auto' }}>
      <TableHead>
        <TableRow>
          <TableCell width='20%'>SDK</TableCell>
          <TableCell width='20%'>Version</TableCell>
          <TableCell width='30%'>Requests</TableCell>
          <TableCell width='30%'>Last Request</TableCell>
          <TableCell width='180px'>Severity</TableCell>
          <TableCell align='right' width='44px' />
        </TableRow>
      </TableHead>

      <TableBody columnCount={6}>
        <TableBodyData>
          {(rows ?? skeletonRows).map((row, index) => (
            <SdkHealthFullTableRow
              key={index}
              type={type}
              row={row}
              openRowsInGroup={openRowsInGroup}
              onToggle={handleToggle}
            />
          ))}
        </TableBodyData>
      </TableBody>
    </Table>
  )
}
