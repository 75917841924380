import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { objectFromEntries } from '../helpers/common'

export const useQueryParams = () => {
  const searchStr = useLocation().search

  return useMemo(() => {
    const urlParamsEntries = new URLSearchParams(searchStr).entries()

    return objectFromEntries(urlParamsEntries)
  }, [searchStr])
}
