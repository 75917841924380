import { useQuery } from '@tanstack/react-query'
import { GenericError } from 'const'

import { WebhookEvent, WebhookEventStatus } from '../../models'
import { extractData, useBuildRequest, useRequestMutation } from './base'

interface WebhookEventBaseParams {
  subscriptionId: string
  webhookId: string
  enabled?: boolean
}

export interface UseWebhookEventParams extends WebhookEventBaseParams {
  requestId: string
  status?: WebhookEventStatus
}

export function useWebhookEvent({
  subscriptionId,
  webhookId,
  requestId,
  status = WebhookEventStatus.All,
  enabled,
}: UseWebhookEventParams) {
  const buildRequest = useBuildRequest()

  return useQuery<WebhookEvent, GenericError>({
    queryKey: ['webhookEvent', subscriptionId, webhookId, requestId, status] as const,
    queryFn: () =>
      extractData(
        buildRequest('webhookEventGet', {
          params: {
            subscriptionId,
            webhookId,
            requestId,
          },
          queryParams: {
            status,
          },
        })
      ),
    enabled: subscriptionId != null && webhookId != null && requestId != null && enabled,
  })
}

export function useWebhookEventResendMutation() {
  return useRequestMutation('webhookEventResend', { errorHandling: { forceToast: true } })
}
