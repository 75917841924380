import { Button } from '@compass/components'
import { Link, Typography } from '@mui/material'
import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { AppRoute, buildRoute } from 'appRoutes'
import { DOCS_SERVER_API, GenericError, USE_IDENTIFICATION_EXPORT } from 'const'
import { ContentColumn, Header, MainColumn, SubHeader } from 'features/commonUI'
import { useCurrentSubscription, useCurrentSubscriptionData } from 'features/subscription'
import { Download } from 'lucide-react'
import { PaginatedVisitsResponse, SubscriptionStatus, SubscriptionType, Visit } from 'models'
import { useCallback, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { useCurrentUser } from '../../../../hooks/api/users'
import { VisitsFilterView } from '../../utils/const'
import ExportIdentificationEventsModal from '../ExportEvent/ExportIdentificationEventsModal'
import { VisitHistoryFilter } from '../VisitHistoryFilter/VisitHistoryFilter'
import { VisitsTable } from '../VisitsTable/VisitsTable'
import styles from './VisitHistory.module.scss'

export type VisitHistoryProps = {
  query: UseInfiniteQueryResult<PaginatedVisitsResponse, GenericError>
  form: UseFormReturn<VisitsFilterView>
}

export function VisitHistory({ query, form }: VisitHistoryProps) {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: currentUser } = useCurrentUser()
  const getVisitPath = useCallback(
    (visit: Visit): string =>
      buildRoute(AppRoute.IdentificationEventDetails, {
        subscriptionId: currentSubscriptionId,
        requestId: visit.requestId,
      }),
    [currentSubscriptionId]
  )

  const [isFiltering, setIsFiltering] = useState(false)

  return (
    <>
      <Header title='Identification' />
      <MainColumn>
        <ContentColumn>
          <SubHeader
            title={<Typography variant='h2'>Identification events</Typography>}
            description={
              <>
                <Typography variant='bodyM'>
                  Select a row to view event details. You can also access this data using the&nbsp;
                  <Link href={DOCS_SERVER_API} target='_blank' underline='hover'>
                    server API
                  </Link>
                  .
                </Typography>
              </>
            }
            actions={
              <>
                <VisitorsExportButton className={styles.exportButton} />
                <VisitHistoryFilter
                  form={form}
                  onFilterChange={(filtering) => setIsFiltering(filtering)}
                  timezone={currentUser?.timezone}
                  subscriptionId={currentSubscriptionId}
                />
              </>
            }
          />
          <VisitsTable
            withContainer
            timezone={currentUser?.timezone}
            query={query}
            getVisitPath={getVisitPath}
            className={styles.tableContainer}
            isFiltering={isFiltering}
          />
        </ContentColumn>
      </MainColumn>
    </>
  )
}

const EXPORT_ENABLED_FOR_STATUSES = [
  SubscriptionStatus.Trialing,
  SubscriptionStatus.Active,
  SubscriptionStatus.ProofOfConcept,
]
const VisitorsExportButton = ({ className }: { className?: string }) => {
  const [exportEventsModalOpen, setExportEventsModalOpen] = useState(false)

  const { subscription } = useCurrentSubscriptionData()

  const isPocWithRestrictedStatus =
    subscription?.status === SubscriptionStatus.Restricted && subscription?.type === SubscriptionType.ProofOfConcept
  const isExportEnabled =
    !!USE_IDENTIFICATION_EXPORT &&
    !!subscription &&
    subscription.featureSettings?.visitsExport !== false &&
    (EXPORT_ENABLED_FOR_STATUSES.includes(subscription.status) || isPocWithRestrictedStatus)

  return (
    <>
      {isExportEnabled && (
        <Button variant='secondary' className={className} onPress={() => setExportEventsModalOpen(true)}>
          <Download />
          Export
        </Button>
      )}
      <ExportIdentificationEventsModal
        isOpen={exportEventsModalOpen}
        handleClose={() => setExportEventsModalOpen(false)}
      />
    </>
  )
}
