import React, { ReactElement } from 'react'

import { Button, ButtonProps } from '../Button/Button'

export type ButtonGroupProps = {
  children: ReactElement<ButtonProps> | ReactElement<ButtonProps>[]
  direction?: 'row' | 'column'
  fullWidth?: boolean
  className?: string
}

export const ButtonGroup = ({ children, direction = 'row', fullWidth, className }: ButtonGroupProps) => {
  return (
    <div
      role='group'
      className={`${direction === 'row' ? 'button-group -space-x-px' : 'button-group-vertical flex-col -space-y-px'} ${
        fullWidth ? 'flex w-full' : 'inline-flex'
      } ${className ?? ''}`}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.type === Button) {
          return child
        } else {
          throw new Error('ButtonGroup only accepts Button as children.')
        }
      })}
    </div>
  )
}
