import { Button } from '@compass/components'
import { Box, InputLabel, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { getErrorParams } from 'helpers/data'
import { muiRegister } from 'helpers/reactHookForm'
import { useToast } from 'hooks'
import { useEncryptionKeyDeleteMutation, useTokenDeleteMutation } from 'hooks/api/tokens'
import { ApiKey, ApiKeyType } from 'models'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import CalloutBox from '../CalloutBox/CalloutBox'
import styles from './DeleteApiKeyDialog.module.scss'

interface DeleteApiKeyDialogProps extends DialogProps {
  apiKey: ApiKey
  onClose: () => void
}

// connected component
export default function DeleteApiKeyDialog({ apiKey, ...dialogProps }: DeleteApiKeyDialogProps) {
  const { mutate: sendTokenDeleteRequest, isLoading } = useTokenDeleteMutation(apiKey.type)
  const { mutate: sendEncryptionKeyDeleteRequest, isLoading: isLoadingApiKeyDeletion } = useEncryptionKeyDeleteMutation(
    apiKey.type
  )
  const { currentSubscriptionId } = useCurrentSubscription()
  const { showToast } = useToast()

  const onDelete = useCallback(() => {
    if (apiKey.type === ApiKeyType.Encryption) {
      sendEncryptionKeyDeleteRequest(
        { params: { keyId: apiKey.id, subscriptionId: currentSubscriptionId } },
        {
          onSuccess: () => {
            dialogProps.onClose()
            showToast({ message: "It's been done. Your API token has been destroyed.", severity: 'success' })
          },
          onError: (error) => {
            showToast({
              message: error?.message ?? 'An error occurred when trying to delete your API token. Please try again.',
              severity: 'error',
            })
          },
        }
      )
      return
    }

    sendTokenDeleteRequest(
      { params: { id: apiKey.id, subscriptionId: currentSubscriptionId } },
      {
        onSuccess: () => {
          dialogProps.onClose()
          showToast({ message: "It's been done. Your API token has been destroyed.", severity: 'success' })
        },
        onError: (e) => {
          const message = e.message ?? 'An error occurred when trying to delete your API token. Please try again.'

          showToast({
            message,
            severity: 'error',
          })
        },
      }
    )
  }, [
    apiKey.type,
    apiKey.id,
    sendTokenDeleteRequest,
    currentSubscriptionId,
    sendEncryptionKeyDeleteRequest,
    dialogProps,
    showToast,
  ])

  return (
    <DeleteApiKeyDialogView
      {...dialogProps}
      onDelete={onDelete}
      apiKey={apiKey}
      isLoading={isLoading || isLoadingApiKeyDeletion}
    />
  )
}

export interface DeleteApiKeyDialogViewProps extends DeleteApiKeyDialogProps {
  onDelete: () => void
}

// display component for storybook
export function DeleteApiKeyDialogView({ onDelete, apiKey, isLoading, ...dialogProps }: DeleteApiKeyDialogViewProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ name: string }>({ defaultValues: { name: '' } })

  const inputValidationMessage = apiKey.name
    ? 'Please enter the API key name. This is required!'
    : 'Please enter the word “obliterate”. This is required!'

  const isTextMatch = (inputText: string) => {
    const textToMatch = apiKey.name ? apiKey.name : 'obliterate'
    const isMatch = inputText.toLowerCase().trim() === textToMatch.toLowerCase().trim()
    if (!isMatch) {
      const message = apiKey.name ? "The text you've entered does not match." : inputValidationMessage
      return message
    }
    return true
  }

  function LabelText() {
    if (apiKey.name) {
      return <>{'Type the name of the API key to confirm'}</>
    }
    return (
      <>
        Type <strong>obliterate</strong> to confirm
      </>
    )
  }

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog {...dialogProps} fullScreen={smDown} isLoading={isLoading} classes={{ paper: styles.dialog }}>
      <DialogTitle onClose={dialogProps.onClose} className={styles.dialogTitle}>
        <Typography variant='h1' component='div' className={styles.heading}>
          Delete API key
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onDelete)}>
        <DialogContent className={styles.dialogContent}>
          {apiKey.type === ApiKeyType.Encryption ? (
            <CalloutBox title='You are about to delete an inactive key!' type='warning'>
              Before proceeding, just make sure your backend isn&apos;t using this key
            </CalloutBox>
          ) : (
            <CalloutBox title='Deleting API keys cannot be undone' type='warning'>
              Before proceeding, please make sure your backend isn&apos;t using this key.
            </CalloutBox>
          )}
          <Box>
            <InputLabel htmlFor='name'>
              <LabelText />
            </InputLabel>
            <TextField
              autoFocus
              autoComplete='off'
              id='name'
              data-testid='delete-apikey-dialog-name-input'
              variant='outlined'
              {...muiRegister(register, 'name', { required: inputValidationMessage, validate: isTextMatch })}
              {...getErrorParams('name', errors)}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button variant='ghost' onPress={dialogProps.onClose}>
            Cancel
          </Button>
          <Button
            variant='destructive'
            type='submit'
            isDisabled={!!errors.name}
            data-testid='delete-apikey-dialog-submit'
          >
            Got it, delete this API key
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
