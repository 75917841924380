import { DateTime } from 'luxon'

export function calculateTicks(from: string, to: string) {
  const fromDate = DateTime.fromJSDate(new Date(from))
  const toDate = DateTime.fromJSDate(new Date(to))

  const ticks = []
  for (let date = fromDate; date <= toDate; date = date.plus({ minute: 5 })) {
    ticks.push(date.toJSDate())
  }

  return ticks
}
