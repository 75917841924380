import { Stack } from '@compass/components'
import { Skeleton, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import { GenericError } from 'const'
import {
  calculateGranularity,
  interpretDateRange,
  PersistentDateRangePicker,
  useDateRangeContext,
  useDefaultPredefinedRanges,
} from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { getTableDataPoints } from 'helpers/data'
import { getIdentificationCounterQueryType } from 'helpers/subscription'
import { useSubscription } from 'hooks'
import { useIntegrationStatus } from 'hooks/api/integration_status'
import { useUsageCounters } from 'hooks/api/usage_counters'
import { useCurrentUser } from 'hooks/api/users'
import {
  BillingType,
  ExpandedSubscription,
  IntegrationStep,
  SubscriptionIntegrationStatus,
  SubscriptionStatus as Status,
  UsageChartData,
  UsageCounterDataPoint,
} from 'models'

import { DistributionCharts } from '../Distributions/DistributionCharts'
import UsageTable from '../UsageTable/UsageTable'
import { NumericWidgets } from '../WorkspaceInsights/NumericWidgets'
import styles from './SubscriptionOverview.module.scss'
import { SubscriptionOverviewState } from './SubscriptionOverviewState'
import { UsageCharts } from './UsageCharts'

type Props = {
  subscription?: ExpandedSubscription
  integrationStatus?: SubscriptionIntegrationStatus
  usageCountersData?: UsageChartData
  usageCountersError?: GenericError | null
}

export function Insights() {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: subscription } = useSubscription(subscriptionId)
  const { data: currentUser } = useCurrentUser()

  const { dateRange } = useDateRangeContext()
  const granularity = calculateGranularity(dateRange?.startDate, dateRange?.endDate)

  const { data: usageCountersData, error: usageCountersError } = useUsageCounters({
    subscriptionId,
    // for usage counters handle, dates should be in user timezone
    from: dateRange?.startDate,
    to: dateRange?.endDate,
    queryType: getIdentificationCounterQueryType(subscription ?? { billingType: BillingType.ApiCalls }),
    period: granularity,
    timezone: currentUser?.timezone,
    enabled: currentUser != null,
  })

  const { data: integrationStatus } = useIntegrationStatus(subscriptionId)

  return (
    <div className={styles.insights}>
      <InsightsHeader timezone={currentUser?.timezone} subscriptionId={subscriptionId} />
      <NumericWidgets subscriptionId={subscription?.id} />
      <DistributionWidgets />
      <InsightsCharts
        integrationStatus={integrationStatus}
        subscription={subscription}
        usageCountersData={usageCountersData}
        usageCountersError={usageCountersError}
      />
      <InsightsUsageTable
        integrationStatus={integrationStatus}
        subscription={subscription}
        usageCountersData={usageCountersData}
        usageCountersError={usageCountersError}
      />
    </div>
  )
}

function InsightsHeader({ timezone, subscriptionId }: { timezone?: string; subscriptionId: string }) {
  const { setDateRange, today } = useDateRangeContext()
  const { ranges } = useDefaultPredefinedRanges()

  return (
    <Stack
      direction={{ default: 'column', sm: 'row' }}
      gapX={10}
      gapY={4}
      className='flex-wrap justify-between lg:items-start'
    >
      <Typography variant='h2'>Insights</Typography>

      {today && setDateRange && timezone ? (
        <PersistentDateRangePicker
          defaultDateRange={ranges.last_7_days}
          route={AppRoute.SubscriptionOverview}
          routeParams={{ subscriptionId }}
          rangeInterpreter={(dateRange) => interpretDateRange({ dateRange })}
          timezone={timezone}
        />
      ) : (
        <Skeleton width={200} />
      )}
    </Stack>
  )
}

function DistributionWidgets() {
  return (
    <div className={styles.distributionWidgets}>
      <DistributionCharts />
    </div>
  )
}

function InsightsCharts({ subscription, usageCountersData, usageCountersError, integrationStatus }: Props) {
  const { dateRange } = useDateRangeContext()
  const granularity = calculateGranularity(dateRange?.startDate, dateRange?.endDate)
  const overviewState = extractOverviewState(subscription, integrationStatus, usageCountersData, usageCountersError)

  if (subscription == null || (usageCountersData == null && usageCountersError == null) || granularity == null) {
    return <Skeleton width='100%' height={250} data-testid='usage-records-loader' />
  }

  return (
    <UsageCharts
      subscriptionId={subscription.id}
      apiRequestsState={overviewState}
      usageCountersData={usageCountersData}
      usageCountersError={usageCountersError}
      granularity={granularity}
    />
  )
}

function InsightsUsageTable({ subscription, usageCountersData, usageCountersError, integrationStatus }: Props) {
  const { dateRange } = useDateRangeContext()
  const granularity = calculateGranularity(dateRange?.startDate, dateRange?.endDate)
  const overviewState = extractOverviewState(subscription, integrationStatus, usageCountersData, usageCountersError)

  if (
    subscription != null &&
    overviewState === SubscriptionOverviewState.HasUsageCounters &&
    usageCountersData &&
    granularity
  ) {
    return <UsageTable data={getTableDataPoints(usageCountersData)} granularity={granularity} />
  }

  if (subscription != null && usageCountersData != null) {
    return null
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Skeleton width='100%' />
      <Skeleton width='100%' />
      <Skeleton width='100%' />
      <Skeleton width='100%' />
      <Skeleton width='100%' />
      <Skeleton width='100%' />
    </div>
  )
}

function extractOverviewState(
  subscription?: ExpandedSubscription,
  integrationStatus?: SubscriptionIntegrationStatus,
  usageCountersData?: UsageChartData,
  usageCountersError?: GenericError | null
): SubscriptionOverviewState {
  const hasRecentUsageCounters =
    usageCountersData &&
    (Object.values(usageCountersData) as UsageCounterDataPoint[][]).some((points) =>
      points.some((point) => point.value > 0)
    )

  if (subscription?.status !== Status.Canceled && integrationStatus?.currentStep === IntegrationStep.ApiCalls) {
    return SubscriptionOverviewState.Setup
  } else if (hasRecentUsageCounters) {
    return SubscriptionOverviewState.HasUsageCounters
  } else if (usageCountersError) {
    return SubscriptionOverviewState.UsageCountersError
  } else {
    return SubscriptionOverviewState.NoUsageCountersDuringPeriod
  }
}
