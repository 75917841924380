import { tv, type VariantProps } from 'tailwind-variants'

import { cn } from './utils'

const badgeVariants = tv({
  base: 'inline-flex items-center rounded-sm border px-2.5 py-0.5 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  variants: {
    variant: {
      solid: '',
      subtle: '',
    },
    tone: {
      default: '',
      secondary: '',
      info: '',
      success: '',
      warning: '',
      danger: '',
      special: '',
    },
    size: {
      sm: 'h-4 px-1 text-xs',
      md: 'h-5 px-1.5',
      lg: 'h-8 px-3 text-base',
    },
    mono: {
      true: 'font-mono',
      false: '',
    },
  },
  compoundVariants: [
    /* Default */
    {
      variant: 'solid',
      tone: 'default',
      class: 'bg-orange-300 border-orange-400 text-orange-900 shadow-[inset_0_-0.5px_0] shadow-orange-500/50',
    },
    {
      variant: 'subtle',
      tone: 'default',
      class: 'border-orange-400 text-orange-700',
    },
    /* Secondary */
    {
      variant: 'solid',
      tone: 'secondary',
      class: 'bg-gray-300 border-gray-400 text-gray-900 shadow-[inset_0_-0.5px_0] shadow-gray-500/50',
    },
    {
      variant: 'subtle',
      tone: 'secondary',
      class: 'border-gray-400 text-gray-800',
    },
    /* Info */
    {
      variant: 'solid',
      tone: 'info',
      class: 'bg-blue-300 border-blue-400 text-blue-900 shadow-[inset_0_-0.5px_0] shadow-blue-500/50',
    },
    {
      variant: 'subtle',
      tone: 'info',
      class: 'border-blue-400 text-blue-800',
    },
    /* Success */
    {
      variant: 'solid',
      tone: 'success',
      class: 'bg-green-300 border-green-400 text-green-900 shadow-[inset_0_-0.5px_0] shadow-green-500/50',
    },
    {
      variant: 'subtle',
      tone: 'success',
      class: 'border-green-400 text-green-800',
    },
    /* Warning */
    {
      variant: 'solid',
      tone: 'warning',
      class: 'bg-yellow-300 border-yellow-400 text-yellow-900 shadow-[inset_0_-0.5px_0] shadow-yellow-500/50',
    },
    {
      variant: 'subtle',
      tone: 'warning',
      class: 'border-yellow-400 text-yellow-800',
    },
    /* Danger */
    {
      variant: 'solid',
      tone: 'danger',
      class: 'bg-red-300 border-red-400 text-red-900 shadow-[inset_0_-0.5px_0] shadow-red-500/50',
    },
    {
      variant: 'subtle',
      tone: 'danger',
      class: 'border-red-400 text-red-800',
    },
    /* Special */
    {
      variant: 'solid',
      tone: 'special',
      class: 'bg-purple-300 border-purple-400 text-purple-900 shadow-[inset_0_-0.5px_0] shadow-purple-500/50',
    },
    {
      variant: 'subtle',
      tone: 'special',
      class: 'border-purple-400 text-purple-800',
    },
  ],
  defaultVariants: {
    variant: 'solid',
    tone: 'default',
    size: 'md',
    mono: false,
  },
})

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {
  mono?: boolean
}

function Badge({ className, variant, tone, size, mono, ...props }: BadgeProps) {
  return <span className={cn(badgeVariants({ variant, tone, size, mono }), className)} {...props} />
}

export { Badge, badgeVariants }
