import { Button } from '@compass/components'
import { TableHead } from '@mui/material'
import { DOCS_SUBDOMAIN_INTEGRATION_URL, PRODUCT_NAME } from 'const'
import { TrashIcon } from 'lucide-react'
import { SSLCertificate, SSLCertificateStatus } from 'models'
import { memo } from 'react'

import EntityTableBodyEmpty from '../../../../components/Table/CustomStatus/EntityTable'
import {
  Table,
  TableBody,
  TableBodyData,
  TableBodyEmpty,
  TableCell,
  TableRow,
} from '../../../../components/Table/Table'
import Tag from '../../../../components/Tag/Tag'

interface CertificatesTableProps {
  certificates: SSLCertificate[]
  isLoading?: boolean
  errorMessage?: string
  onCertificateClick: (certId: string) => void
  onCertificateDelete: (certId: string) => void
  lgUp: boolean
}

export const CertificatesTable = memo(function CertificatesTable({
  certificates,
  isLoading,
  errorMessage,
  onCertificateClick,
  onCertificateDelete,
}: CertificatesTableProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '70%' }}>Subdomain</TableCell>
          <TableCell style={{ width: '10%' }}>Status</TableCell>
          <TableCell style={{ width: '10%', textAlign: 'right' }} align='right'>
            Actions
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody columnCount={3} isLoading={isLoading} error={errorMessage}>
        <TableBodyData>
          {certificates.map(({ id, domainName, status }) => (
            <TableRow key={id} onClick={() => onCertificateClick(id)} interactive>
              <TableCell style={{ width: '70%' }}>{domainName}</TableCell>
              <TableCell style={{ width: '10%' }}>
                <CertificateStatus status={status} />
              </TableCell>
              <TableCell style={{ width: '10%', textAlign: 'right' }} align='right'>
                {status !== SSLCertificateStatus.Revoked && (
                  <Button
                    isIcon
                    variant='ghost'
                    aria-label='Remove subdomain'
                    onPress={() => {
                      onCertificateDelete(id)
                    }}
                  >
                    <TrashIcon />
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBodyData>

        <TableBodyEmpty>
          <EntityTableBodyEmpty
            entityData={{
              name: 'subdomain',
              count: certificates.length,
              limit: 0,
              onAddClick: () => {},
            }}
            title='Subdomain setup'
            body={
              <>
                Using a subdomain significantly improves accuracy and is required for correct identification when using{' '}
                {PRODUCT_NAME}.
              </>
            }
            docsUrl={DOCS_SUBDOMAIN_INTEGRATION_URL}
          />
        </TableBodyEmpty>
      </TableBody>
    </Table>
  )
})

const CertificateStatus = memo(function CertificateStatus({ status }: { status: SSLCertificateStatus }) {
  switch (status) {
    case SSLCertificateStatus.Default:
      return <Tag color='darkGray' label='Init' />
    case SSLCertificateStatus.Validation:
      return <Tag color='yellow' label='Awaiting validation' />
    case SSLCertificateStatus.Issued:
      return <Tag color='blue' label='Issued' />
    case SSLCertificateStatus.Revoked:
      return <Tag color='red' label='Removing' />
    case SSLCertificateStatus.Created:
      return <Tag color='green' label='Created' />
    default:
      return <Tag color='gray' label='Invalid' />
  }
})
