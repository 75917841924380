import { DEFAULT_PRODUCT } from 'const'
import { PurchaseLayout } from 'features/commonUI'
import { trackSubscriptionCreated } from 'helpers/vendor'
import { useDocumentTitle, useExistingOrNewPaymentMethod, useSubscriptionCreateRequest } from 'hooks'
import { usePaymentMethods } from 'hooks/api/payment_methods'
import { usePrice } from 'hooks/prices'
import { PurchaseData, PurchaseType, SubscriptionTier, SubscriptionType } from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useState } from 'react'

import SubscriptionCreateForm, {
  CreateSubscriptionFormData,
} from './components/SubscriptionCreate/SubscriptionCreateForm'

export function AddSecondaryApplicationPage() {
  useDocumentTitle('Create Workspace')

  const [purchaseData, setPurchaseData] = useState<PurchaseData | undefined>()

  const { mutate: sendCreateRequest, isLoading, error } = useSubscriptionCreateRequest()
  const [findOrCreatePaymentMethod, { isLoading: isPaymentMethodProcessing, error: paymentMethodError }] =
    useExistingOrNewPaymentMethod('create application page')
  const { data: paymentMethods = [], isLoading: paymentMethodsLoading } = usePaymentMethods()
  const { price } = usePrice(DEFAULT_PRODUCT)

  const onSubmit = useCallback(
    async (payload: CreateSubscriptionFormData) => {
      const paymentMethod = await findOrCreatePaymentMethod(payload.paymentMethodId as string | undefined, {
        name: payload.cardHolderName,
      })

      if (paymentMethod) {
        sendCreateRequest(
          {
            data: {
              name: payload.name,
              domain: payload.domain,
              regionCode: payload.regionCode,
              type: SubscriptionType.Paid,
              paymentMethod,
              tier: payload.tier,
              stripeBillingCycleAnchor: payload.billingCycleAnchor
                ? Math.floor(new Date(payload.billingCycleAnchor).getTime() / 1000)
                : undefined,
            },
          },
          {
            onSuccess: (result) => {
              ampli.subscriptionPurchased({
                subscriptionID: result.id,
                source: 'create application page',
                applicationType: SubscriptionType.Paid,
                billingPlanName: payload.tier === SubscriptionTier.Pro ? 'Fingerprint Pro' : 'Fingerprint Pro Plus',
              })

              trackSubscriptionCreated(SubscriptionType.Paid)

              setPurchaseData({
                type: PurchaseType.PaidSubscription,
                paidWith: paymentMethod,
                subscriptionId: result.id,
                tier: payload.tier,
              })
            },
          }
        )
      }
    },
    [sendCreateRequest, findOrCreatePaymentMethod]
  )

  return (
    <PurchaseLayout>
      <SubscriptionCreateForm
        onSubmit={onSubmit}
        isLoading={isLoading || paymentMethodsLoading || isPaymentMethodProcessing}
        error={paymentMethodError ?? error}
        paymentMethods={paymentMethods}
        billingData={price}
        purchaseData={purchaseData}
      />
    </PurchaseLayout>
  )
}
