import { JwtPayload } from 'jwt-decode'
import { ContactSupportTopic, IntegrationStep, SubscriptionDistribution, SubscriptionStatistic } from 'models'

import { ApiKeyCopiedProperties } from './ampli'

export enum CardBrand {
  Amex = 'amex',
  Diners = 'diners',
  Discover = 'discover',
  Jcb = 'jcb',
  MasterCard = 'mastercard',
  UnionPay = 'unionpay',
  Visa = 'visa',
  Unknown = 'unknown',
}

export type BillingDetails = {
  name?: string
}

export type PaymentMethod = {
  id: string
  customerId: string
  stripeId: string
  isDefault: boolean
  status: PaymentMethodStatus
  createdAt: Date
  cardBrand: CardBrand
  cardLast4: string
  cardExpMonth: number
  cardExpYear: number
  cardCountry?: string
  cardFunding: string
  cardholderName?: string
}

export type StripePaymentMethod = Pick<
  PaymentMethod,
  'cardBrand' | 'cardCountry' | 'cardExpMonth' | 'cardExpYear' | 'cardFunding' | 'cardLast4' | 'stripeId'
>

export enum SubscriptionStatus {
  Creating = 'creating',
  Trialing = 'trialing',
  Active = 'active',
  ProofOfConcept = 'proof_of_concept',
  Canceled = 'canceled',
  PastDue = 'past_due',
  Unpaid = 'unpaid',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  Restricted = 'restricted',
}

export enum SubscriptionBillingInterval {
  Year = 'year',
  Month = 'month',
}

// Note: Update playwright/tests/utils/models.ts accordingly if this changes
export enum RegionCode {
  Use1 = 'use1',
  Euc1 = 'euc1',
  Aps1 = 'aps1',
}

export enum SubscriptionType {
  Paid = 'paid',
  Free = 'free',
  Prepaid = 'prepaid',
  TrialOnly = 'trial_only',
  ProofOfConcept = 'proof_of_concept',
}

export enum SubscriptionTier {
  Pro = 'core_api_m_0.2',
  Plus = 'FingerprintPlusPro',
  Plus99 = 'pro_plus_q1_2024',
}

export enum RestrictedReason {
  UniqueVisitorsExceeded = 'uniqueVisitorsExceeded',
  ApiCallsExceeded = 'apiCallsExceeded',
  AccountEmailNotConfirmed = 'accountEmailNotConfirmed',
  SuspiciousActivity = 'suspiciousActivity',
  ManualReview = 'manualReview',
}

export enum BillingType {
  ApiCalls = 'api_calls',
  UniqueVisitors = 'unique_visitors',
}

export interface ApplicationProduct {
  product: AppProduct
  disabledAt?: string
  restrictedAt?: string
  restrictedReason?: RestrictedReason
}

export interface SubscriptionFeatureSettings {
  visitsExport?: boolean
}

export interface Subscription {
  id: string
  name: string
  type: SubscriptionType
  billingType: BillingType
  status: SubscriptionStatus
  regionCode: RegionCode
  domain?: string
  trialEndAt?: string
  extendedTrialStartedAt?: string
  cancelAt?: string
  canceledAt?: string
  downgradeScheduledAt?: string
  billingInterval: SubscriptionBillingInterval
  createdAt: string // "2020-05-18T16:31:20.312Z"
  currentPeriodStartedAt?: string
  currentPeriodEndsAt?: string
  restrictedReason?: RestrictedReason
  defaultPaymentMethodId?: string
  integrationStep?: IntegrationStep
  products: ApplicationProduct[]
  featureSettings?: SubscriptionFeatureSettings
}

export enum CouponType {
  Percent = 'percent',
  Amount = 'amount',
}

export enum CouponDurationType {
  Forever = 'forever',
  Repeating = 'repeating',
  Once = 'once',
}

export interface Coupon {
  type: CouponType
  duration: CouponDurationType
  durationInMonths?: number
  amountOff?: number
  percentOff?: number
}

export interface Promotion {
  id: number
  coupon?: Coupon
  startAt: string
  endAt: string
  priceLookupKey: SubscriptionTier
  acceptedAt: string
}

export interface Discount {
  id: number
  coupon?: Coupon
  createdAt: string
}

export type Quota = { quota: number; billed: number }

export interface ExpandedSubscription extends Subscription {
  tokens?: ApiKey[]
  usageQuota?: Quota
  relatedVisitorsQuota?: Quota
  upcomingAmount?: number
  projectedUsage?: number
  latestTier?: SubscriptionTier | null
  promotions?: Promotion[]
  discounts?: Discount[]
  experimentsMetadata?: Record<string, unknown>
}

export interface SSLCertificate {
  id: string
  domainName: string
  status: SSLCertificateStatus
  sans: string[]
  validationRecords: ValidationRecord[]
  subscriptionId: string
  targetBalancer?: TargetBalancer
  subscription?: ExpandedSubscription | string
  createdAt: Date
}

export interface ValidationRecord {
  id: string
  domainName: string
  dnsValidationName: string
  dnsValidationValue: string
  status: SSLCertificateValidationStatus
}

export enum SSLCertificateStatus {
  Default = 'default',
  Created = 'created',
  Validation = 'pending_validation',
  Issued = 'issued',
  Revoked = 'revoked',
}

export enum SSLCertificateValidationStatus {
  Pending = 'pending_validation',
  Validated = 'validated',
  Failed = 'failed',
}

interface TargetBalancer {
  dnsName: string
  staticIps: string[]
}

export interface ApiKey {
  id: string
  name?: string
  description?: string
  disabledAt?: Date
  createdAt: Date
  createdBy?: string
  customerId: string
  rateLimit?: number
  encodedKey?: string
  subscriptionId: string
  token?: string
  workspaceEnvironmentId?: string
  type: ApiKeyType
}

// Note: Update playwright/tests/utils/models.ts accordingly if this changes
export enum ApiKeyType {
  Public = 'browser',
  Secret = 'api',
  BrowserProxy = 'browser-proxy',
  Encryption = 'encryption',
  Management = 'management',
}

type ApiKeyTypeKeys = keyof typeof ApiKeyType
export const ApiKeyTypeLogMap: Record<(typeof ApiKeyType)[ApiKeyTypeKeys], ApiKeyCopiedProperties['apiKeyType']> = {
  [ApiKeyType.Public]: 'Public',
  [ApiKeyType.Secret]: 'Secret',
  [ApiKeyType.BrowserProxy]: 'Proxy',
  [ApiKeyType.Encryption]: 'Encryption',
  [ApiKeyType.Management]: 'Management',
}

export enum PaymentMethodStatus {
  Active = 'active',
}

export enum EmailType {
  SignupIntent = 'signup-intent',
  PasswordReset = 'password-reset',
  PaidSubscriptionCreated = 'subscription-created-paid',
  TrialSubscriptionCreated = 'subscription-created-trial',
  TrialCanceled = 'trial-canceled',
  SubscriptionCanceled = 'subscription-canceled',
  CancelSubscription = 'subscription-cancel',
  UnpaidSubscriptionNotification = 'notification-subscription-unpaid',
  DailySummaryIntentNoSubscription = 'daily-summary-intent-no-subscription',
  DailySummaryTrialNoUsage = 'daily-summary-trial-no-usage',
  DailySummaryActiveSubscription = 'daily-summary-active-subscription',
  CertificateIssued = 'certificate-issued',
  TrialWillEnd = 'trial-will-end',
}

export enum TrafficRulePermissionType {
  Allow = 'allow',
  Deny = 'deny',
}

export enum TrafficRuleType {
  Origin = 'origin',
  HTTPHeader = 'httpHeader',
  AppPackageName = 'appPackageName',
}

export enum TrafficRuleStringMatchRule {
  Contains = 'contains',
  BeginsWith = 'startsWith',
  EndsWith = 'endsWith',
  Regex = 'regex',
}

export type TrafficRuleMatchRule = TrafficRuleStringMatchRule

export interface TrafficRule {
  id: string
  customerId: string
  subscriptionId: string
  permissionType: TrafficRulePermissionType
  type: TrafficRuleType
  matchRule?: TrafficRuleMatchRule
  key?: string
  value?: string
  description?: string
  createdAt: Date
  workspaceEnvironmentId: string
}

export type TrafficRuleCreatePayload = Pick<
  TrafficRule,
  'permissionType' | 'matchRule' | 'key' | 'type' | 'value' | 'description' | 'workspaceEnvironmentId'
>

export interface UsageCounterDataPoint {
  timestamp: string
  value: number
}

export enum UsageCounterPeriod {
  Hour = 'hour',
  Day = 'day',
  Month = 'month',
}

export enum UsageCounterType {
  ApiCalls = 'api_calls',
  ThrottledCalls = 'throttled_calls',
  RestrictedCalls = 'restricted_calls',
  UniqueVisitors = 'unique_visitors',
}

export enum BotdCounterType {
  GoodBots = 'good_bots',
  BadBots = 'bad_bots',
  Humans = 'humans',
}

export type ClientCounterType = UsageCounterType | BotdCounterType

export enum AppProduct {
  Identification = 'identification',
  Botd = 'botd',
  Incognito = 'incognito',
  IpResolution = 'ip_resolution',
  Tampering = 'tampering',
  Vpn = 'vpn',
  Tor = 'tor',
  IpBlacklist = 'ip_blacklist',
  MobileRootApps = 'mobile_root_apps',
  MobileEmulator = 'mobile_emulator',
  Proxy = 'proxy',
  PrivacySettings = 'privacy_settings',
  MobileAppCloners = 'mobile_app_cloners',
  MobileRecentFactoryReset = 'mobile_recent_factory_reset',
  MobileJailbreak = 'mobile_jailbreak',
  MobileFrida = 'mobile_frida',
  VirtualMachine = 'virtual_machine',
  RawDeviceAttributes = 'raw_device_attributes',
  MobileGeolocationSpoofing = 'mobile_geolocation_spoofing',
}

export enum ChartType {
  UniqueVisitors,
  CallBased,
}

export type UsageChartData = Partial<Record<ClientCounterType, UsageCounterDataPoint[]>>

export type BaseStatsDataPoint = {
  index: number
  timestamp: string
}
export type StatsDataPoint = BaseStatsDataPoint & Record<UsageCounterType, number>
export type PartialStatsDataPoint = BaseStatsDataPoint & Partial<Record<UsageCounterType, number>>
export type CallBasedChartDataPoint = Omit<StatsDataPoint, UsageCounterType.UniqueVisitors>

export type FullTableDataPoint = Omit<StatsDataPoint, 'index'>
export type CallBasedTableDataPoint = Omit<CallBasedChartDataPoint, 'index'>
export type TableDataPoint = FullTableDataPoint | CallBasedTableDataPoint
export type PartialTableDataPoint = Omit<PartialStatsDataPoint, 'index'>

export enum FraudType {
  AccountTakeover = 'account-takeover',
  FraudulentPayments = 'fraudulent-payments',
  AccountSharing = 'account-sharing',
  BotDetection = 'bot-detection',
  Paywall = 'paywall',
  ClickFraud = 'click-fraud',
  Spam = 'spam',
  FakeLeads = 'fake-leads',
  BonusAbuse = 'bonus-abuse',
  PromoFraud = 'promo-fraud',
  ReferralFraud = 'referral-fraud',
  ReviewFraud = 'review-fraud',
  Other = 'other',
}

export enum IndustryType {
  MediaContent = 'media-content',
  SaaS = 'saas',
  Ecommerce = 'ecommerce',
  BankingFinance = 'banking-finance',
  SocialMedia = 'social-media',
  GamingGambling = 'gaming-gambling',
  Cryptocurrency = 'cryptocurrency',
  NonProfitGovernment = 'non-profit-government',
  AgencyContractor = 'agency-contractor',
  Other = 'other',
}

export enum JobLevelType {
  Executive = 'executive',
  Director = 'director',
  Manager = 'manager',
  IndividualContributor = 'individual-contributor',
  Other = 'other',
}

export enum JobFunctionType {
  Product = 'product',
  Engineering = 'engineering',
  RiskAndSecurity = 'risk-and-security',
  DataAndAnalytics = 'data-and-analytics',
  Marketing = 'marketing',
  Operations = 'operations',
  Other = 'other',
}

export interface AccountSettings {
  industry?: IndustryType
  fraudType?: FraudType
  otherFraudType?: string
}

export interface InsightItemType {
  statType: 'insight'
  statKey: SubscriptionStatistic
}

export interface DistributionItemType {
  statType: 'distribution'
  statKey: SubscriptionDistribution
}

export interface LastSeenElementItem {
  element: 'whats-new-modal'
  timestamp: string | null
}

export interface UserSubscriptionSettings {
  metric_list_preference: Array<InsightItemType | DistributionItemType>
}

export interface UserSettings {
  last_seen_element: Array<LastSeenElementItem>
}

export interface Notification {
  severity: Severity
  type: NotificationType
  isDismissable: boolean
}

export enum Severity {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
}

export enum NotificationType {
  SubdomainIntegration = 'subdomain-integration',
}

export enum UnsubscribeTypes {
  User = 'u',
  External = 'e',
}

export enum BroadcastUnsubscribedTopics {
  Newsletter = 'newsletter',
}

export enum ContextEntity {
  Subscription = 'subscription',
  Customer = 'customer',
}

export enum Limits {
  TokenRate = 'tokenRate',
  Tokens = 'tokens',
  Webhooks = 'webhooks',
  TrafficRules = 'trafficRules',
  SubscriptionTrials = 'subscriptionTrials',
  WorkspaceEnvironments = 'workspaceEnvironments',
  SslCertificates = 'sslCertificates',
}

export interface LimitContext {
  id: Limits
  entity: ContextEntity
  entityId: string
  value: number
}

export enum OnboardingStep {
  CreateSubscription = 'createSubscription',
  Install = 'sendEvents',
  Finished = 'finished',
  Skipped = 'skipped',
}

export enum UserRole {
  Admin = 'admin',
  Owner = 'owner',
  Administrator = 'administrator',
  Member = 'member',
  ReadOnly = 'readOnly',
}

export enum UserStatus {
  Active = 'active',
  Pending = 'pending',
}

export interface UserContext {
  email: string
  isEmailConfirmed: boolean
  limits: LimitContext[]
  onboardingStep: OnboardingStep
  hasConsent: boolean
  isSsoEnabled: boolean
}

export interface UserFeedbackData {
  scorePoints?: string // undefined if response was not provided yet
  // filled in cron with { timesShown: 0 }, undefined means feedback conditions are not met yet
  dashboardDrawer?: {
    timesShown?: string
  }
  email?: {
    timesSent?: string // numeric
    lastSent?: string
  }
}

export interface UserStats {
  customer: {
    createdAt: string
    workspaces: {
      workspaceCount: number
      hasTrialingOnly: boolean
      hasPaid: boolean
      hasPrepaid: boolean
      region: string
    }
    milestones: {
      oneApiCall: boolean
      hundredApiCalls: boolean
    }
    subdomains: {
      hasSubdomain: boolean
    }
    webhooks: {
      hasActiveWebhook: boolean
    }
    filteringRules: {
      hasFilteringRules: boolean
    }
  }
}

export type Order = 'ASC' | 'DESC'
export type OrderLowercase = 'asc' | 'desc'

export type PaginationParams = {
  limit?: string
  offset?: string
}

export type SortParams = {
  sortBy?: string
  order?: Order
}

export type SortingOptions<TModel> = {
  sortBy?: keyof TModel
  order?: OrderLowercase
}

export type PaginationAndSortingOptions<TModel> = {
  limit?: number
  offset?: number
  sortBy?: keyof TModel
  order?: OrderLowercase
}

export interface User {
  id: string
  name: string
  role: UserRole
  status: UserStatus
  email: string
  timezone: string
  customerId?: string
  jobLevel?: JobLevelType
  jobFunction?: JobFunctionType
  emailConfirmedAt?: string
}

export interface ReadmeLogin {
  token: string
}

export interface AccessTokenPayload extends JwtPayload {
  id: string
  customerId: string
  role: UserRole
  impersonatorId?: string
  exp: number
  iat: number
}

export interface RefreshTokenPayload extends JwtPayload {
  exp: number
  iat: number
}

export interface ContactSupportData {
  email: string
  message: string
  formDetails?: ContactSupportTopic
}

export interface WorkerData {
  zoneId: string
  workerName: string
}

export enum DeploymentStatus {
  Queued = 'queued',
  InProgress = 'in_progress',
  Completed = 'completed',
  Error = 'error',
  RemovalError = 'removal_error',
  RemovalQueued = 'removal_queued',
  RemovalInProgress = 'removal_in_progress',
  Deleted = 'deleted',
}

export type RequestsMadeByDay = {
  timestamp: number
  value: number
}

export interface ProxyIntegrationRequests {
  proxyRequests: RequestsMadeByDay[]
  proxySecretErrors: RequestsMadeByDay[]
  allRequests: RequestsMadeByDay[]
  nonProxyRequests: RequestsMadeByDay[]
}

export interface CloudflareIntegration {
  id: string
  domain: string
  workerName: string
  workerPath: string
  agentDownloadPath: string
  ingressApiPath: string
  lastDeploymentStatus: DeploymentStatus
  zoneId: string
  accountId: string
  proxySecretToken?: string
  lastDeploymentError?: string
  lastRemovalError?: string
  lastVersion?: string
  lastDeploymentCompletedAt?: string
}

export interface CloudflareDeploymentStatus {
  status: DeploymentStatus
  error: string | null
}

export interface WorkerDeploymentRequestResult {
  id: string
}

export interface CloudflareDomainsResponse {
  result: { zoneId: string; domain: string; status: string }[]
  count: number
}

export interface CloudflareSubdomainsResponse {
  result: { id: string; zoneId: string; name: string }[]
  count: number
}

export interface CloudFrontIntegration {
  managementLambdaPublicURL: string
  isEnabled: boolean
  lastDeploymentStatus: CloudFrontIntegrationDeploymentStatus | null
  lastDeploymentCompletedAt: string | null
  lastDeploymentError: string | null
}

export enum CloudFrontIntegrationDeploymentStatus {
  InProgress = 'in_progress',
  Completed = 'completed',
  Error = 'error',
}

export interface AccountTransferRequest {
  id: string
  customerId: string
  newOwnerId: string
  createdAt: Date
}

export interface AccountTransferCreate {
  newOwnerEmail: string
  password: string
  fpjsVisitorId?: string
}

export interface UserFormData {
  id?: string
  name: string
  email: string
  role: UserRole
  jobLevel?: string
  jobFunction?: string
}

export interface EditUserFormData {
  id?: string
  name?: string
  role?: UserRole
  timezone?: string
  jobLevel?: string
  jobFunction?: string
}

export interface UserConsentData {
  termsOfService?: boolean
  privacyPolicy?: boolean
}

export interface IdentificationEventExportBody {
  dateRange: {
    from: string
    to: string
  }
}

export interface ApplicationFeature {
  id: string
  featureName: ApplicationFeatureName
  subscriptionId: string
  enabledAt: Date
}

export enum ApplicationFeatureName {
  WebhookSignatures = 'webhook_signatures',
  FilterSearchBots = 'filter_search_bots',
}

export interface WorkspaceEnvironment {
  id: string
  name: string
}

export enum Platform {
  Browser = 'browser',
  Android = 'android',
  Ios = 'ios',
}

export enum SuspectScore {
  Incognito = 'incognito',
  Tampering = 'tampering',
  HighActivity = 'highActivity',
  BadBots = 'badBots',
  VpnTimezone = 'vpnTimezone',
  VpnPublic = 'vpnPublic',
  VpnMobile = 'vpnMobile',
  VirtualMachine = 'virtualMachine',
  PrivacySettings = 'privacySettings',
  IpBlocklistEmailSpam = 'ipBlocklistEmailSpam',
  IpBlocklistAttackSource = 'ipBlocklistAttackSource',
  Tor = 'tor',
  Proxy = 'proxy',
  Emulator = 'emulator',
  RootApps = 'rootApps',
  AppCloners = 'appCloners',
  Jailbreak = 'jailbreak',
  Frida = 'frida',
  DeveloperTools = 'developerTools',
  RemoteControl = 'remoteControl',
  VpnOsMismatch = 'vpnOsMismatch',
}

export type SuspectScores = Record<Platform, Partial<Record<SuspectScore, number>>>

export interface SuspectScoreResponse {
  defaults: SuspectScores
  scores: SuspectScores
}

export type UpdateSuspectScoreBody = Partial<SuspectScores>
