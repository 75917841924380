import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query'
import { GenericError, isGenericError } from 'const'

import { PaginatedVisitsResponse, Visit, VisitsFilter } from '../models'
import { useBuildRequest } from './api/base'

const DEFAULT_VISIT_QUERY_LIMIT = 10

export type VisitFilter = { key: keyof Visit; value: string }

export interface UsePaginatedVisitsParams {
  subscriptionId: string
  limit?: number
  reverse?: boolean
  filter?: VisitFilter
}

export const TOTAL_HITS_MAX_LIMIT = 100
export function useVisitsInfiniteQuery(
  params: { subscriptionId: string; limit?: number; reverse?: boolean } & VisitsFilter,
  options?: Omit<UseInfiniteQueryOptions<PaginatedVisitsResponse, GenericError>, 'getFetchMore'>
): UseInfiniteQueryResult<PaginatedVisitsResponse, GenericError> {
  const { subscriptionId, limit, ...rest } = params
  const buildRequest = useBuildRequest()

  const queryFn: QueryFunction<PaginatedVisitsResponse, QueryKey, string | undefined> = ({
    pageParam: paginationKey,
  }) =>
    buildRequest('visitsGet', {
      queryParams: {
        ...rest,
        limit: limit ?? DEFAULT_VISIT_QUERY_LIMIT,
        // In order to optimize the requests, only fetch the total hit counter on the first page.
        ...(paginationKey ? { paginationKey } : { trackTotalHits: TOTAL_HITS_MAX_LIMIT }),
      },
      params: { subscriptionId },
    })
      .then(({ data }) => data)
      .catch((e: GenericError) => {
        // mgmt-api handles old/deactivated subscriptions differently, map it to no value.
        if (isGenericError(e) && e.code === 'value_not_found') {
          return { visits: [] } satisfies PaginatedVisitsResponse
        }

        throw e
      })

  return useInfiniteQuery({
    queryKey: ['visits', params] as const,
    queryFn,
    getNextPageParam: (lastPage) => lastPage.paginationKey,
    retry: false,
    keepPreviousData: true,
    ...options,
    enabled: params.subscriptionId != null && (options?.enabled ?? true),
  })
}

export function useVisitsByVisitorInfiniteQuery(
  params: { subscriptionId: string; visitorId: string; limit?: number } & VisitsFilter,
  options?: Omit<UseInfiniteQueryOptions<PaginatedVisitsResponse, GenericError>, 'getFetchMore'>
): UseInfiniteQueryResult<PaginatedVisitsResponse, GenericError> {
  const { subscriptionId, visitorId, limit } = params
  const buildRequest = useBuildRequest()

  const queryFn: QueryFunction<PaginatedVisitsResponse, QueryKey, string | undefined> = ({
    pageParam: paginationKey,
  }) =>
    buildRequest('visitorGet', {
      queryParams: {
        limit: limit ?? 10,
        ...(paginationKey ? { paginationKey } : {}),
      },
      params: { subscriptionId, visitorId },
    }).then(({ data }) => data)

  return useInfiniteQuery({
    queryKey: ['visitor', subscriptionId, visitorId] as const,
    queryFn,
    getNextPageParam: (lastPage) => lastPage.paginationKey,
    retry: false,
    keepPreviousData: true,
    ...options,
    enabled: params.subscriptionId != null && (options?.enabled ?? true),
  })
}
