import { Typography } from '@mui/material'
import clsx from 'clsx'
import { ReactNode, useContext } from 'react'

import { embeddedCtx } from '../../Wizards/CloudflareSetup/EmbeddedContext'
import styles from './WizardHeader.module.scss'

export interface WizardHeaderProps {
  title: string | ReactNode
  subtitle?: string | ReactNode
}

export default function WizardHeader({ title, subtitle }: WizardHeaderProps) {
  const isEmbedded = useContext(embeddedCtx)
  return (
    <header className={clsx(styles.header, { [styles.embedded]: isEmbedded })}>
      <Typography variant='h1' className={styles.title}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant='body1' component='h2'>
          {subtitle}
        </Typography>
      )}
    </header>
  )
}
