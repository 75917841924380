import { Experiment, ExperimentUser, Variant } from '@amplitude/experiment-js-client'
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { AMPLITUDE_EXPERIMENT_DEPLOY_API_KEY } from 'const'
import logger from 'helpers/logger'
import { useAuth } from 'hooks'
import { ampli } from 'models/ampli'
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'

import { useAmplitude } from '../amplitude'
import {
  experimentCache,
  getExperimentVariantFromCache,
  getLocalExperimentVariant,
  invalidateExperimentCache,
  saveExperimentCache,
} from './cache'
import { ExperimentFlag, ExperimentValue } from './const'
import { globalExperimentsDisable } from './globalSwitch'

const experimentClient = Experiment.initializeWithAmplitudeAnalytics(AMPLITUDE_EXPERIMENT_DEPLOY_API_KEY, {
  automaticExposureTracking: false,
})

const AmplitudeExperimentsContext = createContext({
  experimentClient,
  loadingStatusByFlag: {} as Record<ExperimentFlag, boolean>,
  fetchExperimentFlagConfiguration: (_flagKeys: ExperimentFlag[]) => Promise.resolve(),
  invalidateExperimentCache,
})

export const useAmplitudeExperimentsContext = () => useContext(AmplitudeExperimentsContext)

export function AmplitudeExperimentsProvider({ children }: PropsWithChildren<{}>) {
  const { getData } = useVisitorData()
  const { accessTokenPayload } = useAuth()
  const customerId = accessTokenPayload?.customerId
  const userId = accessTokenPayload?.id

  const [loadingStatusByFlag, setLoadingStatusByFlag] = useState({} as Record<ExperimentFlag, boolean>)

  const fetchExperimentFlagConfiguration = useCallback(
    async (flagKeys: ExperimentFlag[]) => {
      const flagKeysToFetch: ExperimentFlag[] = []

      for (const flagKey of flagKeys) {
        if (!getExperimentVariantFromCache(flagKey)) {
          flagKeysToFetch.push(flagKey)
        }
      }

      const setLoading = (value: boolean) => {
        setLoadingStatusByFlag((state) => flagKeysToFetch.reduce((acc, flag) => ({ ...acc, [flag]: value }), state))
      }

      setLoading(true)

      const visitorData = await getData()
      if (!visitorData) {
        return
      }

      await fetchExperimentVariants(flagKeysToFetch, {
        device_id: visitorData.visitorId,
        user_id: userId,
        user_properties: customerId
          ? {
              customer: customerId,
              customer_id: customerId,
            }
          : undefined,
      })

      setLoading(false)
    },
    // https://github.com/fingerprintjs/fingerprintjs-pro-react/issues/132
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setLoadingStatusByFlag, customerId, userId]
  )

  return (
    <AmplitudeExperimentsContext.Provider
      value={{
        experimentClient,
        loadingStatusByFlag,
        fetchExperimentFlagConfiguration,
        invalidateExperimentCache,
      }}
    >
      {children}
    </AmplitudeExperimentsContext.Provider>
  )
}

export function useAmplitudeExperimentVariant(flagKey: ExperimentFlag) {
  const { loadingStatusByFlag, fetchExperimentFlagConfiguration } = useContext(AmplitudeExperimentsContext)
  const experimentVariantMetadata = getExperimentVariantFromCache(flagKey)

  const [shouldFetchVariant, setShouldFetchVariant] = useState(!experimentVariantMetadata && !globalExperimentsDisable)
  const [variant, setVariant] = useState<Variant | undefined>(
    globalExperimentsDisable ? { value: 'control' } : experimentVariantMetadata?.variant
  )

  const isLoading = loadingStatusByFlag[flagKey] ?? shouldFetchVariant

  // Fetch variant if not cached and not loading
  useEffect(() => {
    if (shouldFetchVariant) {
      fetchExperimentFlagConfiguration([flagKey])
      setShouldFetchVariant(false)
    }
  }, [fetchExperimentFlagConfiguration, flagKey, shouldFetchVariant])

  // Update value after fetching
  useEffect(() => {
    if (!isLoading && !variant) {
      setVariant(experimentVariantMetadata?.variant)
    }
  }, [isLoading, flagKey, experimentVariantMetadata, variant])

  // Manually send experiment `exposure` event if it is the first time a user gets this flag
  useEffect(() => {
    if (
      experimentVariantMetadata &&
      !experimentVariantMetadata.isDefaultValue &&
      !experimentVariantMetadata.exposedAt
    ) {
      sendExperimentExposureEvent(flagKey)
    }
  }, [flagKey, experimentVariantMetadata])

  return { variant, isLoading }
}

// Local evaluation is preferred for non-authorized pages on initial rendering, such as signup
// This allows to avoid rendering loading caused by remote variant fetching
export function useLocalAmplitudeExperimentVariant(flagKey: ExperimentFlag, sendExposureEvent = true) {
  const { isClientLoaded } = useAmplitude()
  const { variant, exposedAt } = getLocalExperimentVariant(flagKey)
  const { data } = useVisitorData({ extendedResult: true })
  const isIncognito = data?.incognito
  const visitorId = data?.visitorId

  // Manually send experiment `exposure` event if it is the first time a user gets this flag
  // Also avoid sending exposure in incognito, to avoid variant jumping
  useEffect(() => {
    if (visitorId && !isIncognito && !exposedAt && isClientLoaded && sendExposureEvent) {
      ampli.track({
        event_type: '$exposure',
        device_id: visitorId,
        event_properties: {
          flag_key: flagKey,
          variant: variant.value,
        },
      })

      experimentCache[flagKey].exposedAt = Date.now()
      saveExperimentCache()
    }
  }, [flagKey, isIncognito, visitorId, exposedAt, variant.value, isClientLoaded, sendExposureEvent])

  return { variant }
}

const defaultVariant = { value: ExperimentValue.Control, payload: null, expKey: '' }

async function fetchExperimentVariants(flagKeys: ExperimentFlag[], userProps: ExperimentUser = {}) {
  if (flagKeys.length > 0) {
    try {
      await experimentClient.fetch(userProps, { flagKeys })
    } catch (e) {
      if (e instanceof Error) {
        logger.error(`Can not fetch experiment configuration: ${e.message}`)
      }
    }

    // If `fetch` has failed, we always fall back to the default variant
    for (const flag of flagKeys) {
      const variant = experimentClient.variant(flag)
      const isVariantLoaded = !!variant?.value

      experimentCache[flag] = {
        variant: isVariantLoaded ? variant : defaultVariant,
        isDefaultValue: !isVariantLoaded,
        fetchedAt: Date.now(),
      }
    }

    saveExperimentCache()
  }
}

function sendExperimentExposureEvent(flagKey: ExperimentFlag) {
  if (!experimentCache[flagKey] || experimentCache[flagKey].exposedAt) {
    return
  }

  // Avoid tracking exposure for fallback variants
  if (experimentCache[flagKey].isDefaultValue) {
    return
  }

  experimentClient.exposure(flagKey)

  experimentCache[flagKey].exposedAt = Date.now()
  saveExperimentCache()
}
