import { Button } from '@compass/components'
import { InfoOutlined } from '@mui/icons-material'
import { Grid, InputLabel, Paper, TextField, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import DashboardBanner from 'components/DashboardBanner/DashboardBanner'
import FullpageLoader from 'components/Loader/FullpageLoader/FullpageLoader'
import { Stepper } from 'components/Stepper/Stepper'
import {
  GenericError,
  IS_PRODUCTION_MODE,
  PRICING_PAGE_URL,
  PRO_PLUS_99_BILLING_DATA,
  PRO_PLUS_BILLING_DATA,
  REGIONS,
  SUBSCRIPTION_TIER_TO_DISPLAY_PLAN,
  SubscriptionDisplayPlan,
} from 'const'
import { PlanCard } from 'features/planManagement'
import { useCurrentSubscription } from 'features/subscription'
import { getErrorParams } from 'helpers/data'
import { muiRegister } from 'helpers/reactHookForm'
import { domainNameRegex } from 'helpers/regex'
import { BillingData, PaymentMethod, PurchaseData, SubscriptionTier } from 'models'
import { ampli } from 'models/ampli'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import PaymentForm from '../PaymentForm/PaymentForm'
import PurchaseConfirmation from '../PurchaseConfirmation/PurchaseConfirmation'
import RegionSelector from '../RegionSelector/RegionSelector'
import styles from './SubscriptionCreateForm.module.scss'

const availableRegions = Object.values(REGIONS)

enum Step {
  PlanSelection,
  Info,
  Payment,
  Confirmation,
}

const STEP_LABELS: Record<Step, string> = {
  [Step.PlanSelection]: 'Select Plan',
  [Step.Info]: 'Info',
  [Step.Payment]: 'Payment',
  [Step.Confirmation]: 'Confirmation',
}

export type CreateSubscriptionFormData = {
  name: string
  domain?: string
  regionCode: string
  cardHolderName?: string
  paymentMethodId?: Pick<PaymentMethod, 'id'>
  tier?: SubscriptionTier
  billingCycleAnchor?: Date
}

export type Props = {
  error?: GenericError | null
  isLoading?: boolean
  paymentMethods: PaymentMethod[]
  billingData?: BillingData
  purchaseData?: PurchaseData
  onSubmit: (data: CreateSubscriptionFormData) => void
}

export default function SubscriptionCreateForm({
  error,
  isLoading,
  paymentMethods = [],
  billingData,
  purchaseData,
  onSubmit,
}: Props) {
  const formMethods = useForm<CreateSubscriptionFormData>()
  const {
    register,
    setValue,
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
  } = formMethods

  const [activeStep, setActiveStep] = useState(Step.PlanSelection)
  const { currentSubscriptionId } = useCurrentSubscription()

  const tier = watch('tier')
  const [tierPrice, setTierPrice] = useState(billingData)

  useEffect(() => {
    setTierPrice(
      tier === SubscriptionTier.Plus
        ? PRO_PLUS_BILLING_DATA
        : tier === SubscriptionTier.Plus99
          ? PRO_PLUS_99_BILLING_DATA
          : billingData
    )
  }, [tier, billingData])

  useEffect(() => {
    if (error?.param === 'domain' || error?.param === 'name') {
      setActiveStep(Step.Info)
    }
  }, [error])

  useEffect(() => {
    if (purchaseData) {
      setActiveStep(Step.Confirmation)
    }
  }, [purchaseData])

  return (
    <div className={styles.root}>
      <Stepper
        steps={Object.values(STEP_LABELS).map((label) => ({ label }))}
        activeStep={activeStep}
        onMoveBack={activeStep === Step.Confirmation ? undefined : () => setActiveStep(activeStep - 1)}
        action={activeStep === Step.Confirmation ? undefined : 'Cancel'}
        actionTo={buildRoute(AppRoute.SubscriptionOverview, { subscriptionId: currentSubscriptionId })}
      >
        <div className={styles.container}>
          {activeStep === Step.PlanSelection && (
            <>
              <DashboardBanner
                variant='warning'
                icon={<InfoOutlined color='primary' />}
                title='Get a discount when you sign up for an Annual plan'
                action='See pricing'
                actionHref={PRICING_PAGE_URL}
                secondaryAction='Contact sales'
                testIds={{
                  title: 'bannerTitle',
                  body: 'bannerSubheader',
                  actionButton: 'bannerSeePricingButton',
                  secondaryActionButton: 'bannerContactSalesButton',
                }}
                secondaryActionUrl={AppRoute.ContactSalesEnterprise}
                onClickSecondaryAction={() =>
                  ampli.appCtaClicked({ 'App Page Path': AppRoute.AddSecondaryApplication, ctaName: 'Contact sales' })
                }
                className={styles.banner}
              >
                Contact sales to set up or visit our pricing page for more details.
              </DashboardBanner>

              <Typography variant='h1Widget' data-testid='createAppFormHeader'>
                Create a workspace
              </Typography>
              <div className={styles.selector}>
                {/*
                // November Pricing Experiment (Pro plan is disabled in November 2023)
                // https://www.notion.so/fingerprintjs/November-Pricing-Experiment-acc992ef0092444db2fa07b5f12c3c7b
                <PlanCard
                  key='pro'
                  plan={SubscriptionDisplayPlan.Pro}
                  priceLabel='100,000 API calls incl.'
                  variant='outlined'
                  action='Select pro plan'
                  onAction={() => {
                    setValue('tier', SubscriptionTier.Pro)
                    setActiveStep(Step.Info)
                  }}
                /> */}
                <PlanCard
                  key='plus'
                  plan={SubscriptionDisplayPlan.Plus99}
                  priceLabel='20,000 API calls incl.'
                  action='Select plus plan'
                  onAction={() => {
                    setValue('tier', SubscriptionTier.Plus99)
                    setActiveStep(Step.Info)
                  }}
                />
              </div>
            </>
          )}

          {activeStep !== Step.Confirmation && (
            <FormProvider {...formMethods}>
              <form
                onSubmit={handleSubmit((payload: CreateSubscriptionFormData) => {
                  onSubmit({ ...payload })
                })}
              >
                <div hidden={activeStep !== Step.Info}>
                  <Typography variant='h1' className={styles.heading}>
                    General Info
                  </Typography>

                  <Paper className={styles.generalInfo}>
                    <Grid container columnSpacing={2} rowSpacing={1} className={styles.formContent}>
                      <Grid item xs={12}>
                        <InputLabel htmlFor='applicationName'>What is your workspace name?</InputLabel>
                        <TextField
                          fullWidth
                          id='applicationName'
                          type='text'
                          placeholder='My workspace'
                          variant='outlined'
                          {...muiRegister(register, 'name', {
                            required: 'Workspace name required',
                            maxLength: { value: 80, message: 'Workspace names can only be 80 characters long.' },
                          })}
                          {...getErrorParams('name', errors, error)}
                          inputProps={{ 'aria-label': 'applicationName' }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel htmlFor='domainName'>What is your domain name? (Optional)</InputLabel>
                        <TextField
                          fullWidth
                          id='domainName'
                          type='text'
                          placeholder='workspace.com'
                          variant='outlined'
                          {...muiRegister(register, 'domain', {
                            pattern: { value: domainNameRegex, message: 'Invalid domain name' },
                          })}
                          {...getErrorParams('domain', errors, error)}
                          inputProps={{ 'aria-label': 'domainName' }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <RegionSelector
                          regions={availableRegions}
                          className={styles.regionSelector}
                          source={'account settings'}
                        />
                      </Grid>

                      {!IS_PRODUCTION_MODE && (
                        <Grid item xs={12}>
                          <InputLabel htmlFor='billingCycleAnchor'>
                            Billing Cycle Ends At (Test Environment Only)
                          </InputLabel>
                          <TextField
                            fullWidth
                            id='billingCycleAnchor'
                            type='datetime-local'
                            variant='outlined'
                            {...muiRegister(register, 'billingCycleAnchor')}
                            inputProps={{ 'aria-label': 'billingCycleAnchor' }}
                          />
                        </Grid>
                      )}
                    </Grid>

                    <Button
                      isDisabled={isLoading}
                      className={styles.button}
                      aria-label='Proceed to Payment'
                      size='lg'
                      onPress={async () => {
                        const isPassingValidation = (await Promise.all([trigger('name'), trigger('domain')])).every(
                          (field) => field
                        )

                        if (isPassingValidation) {
                          setActiveStep(activeStep + 1)
                        }
                      }}
                    >
                      Create
                    </Button>
                  </Paper>
                </div>

                {activeStep === Step.Payment && (
                  <PaymentForm
                    displayPlan={tier ? SUBSCRIPTION_TIER_TO_DISPLAY_PLAN[tier] : undefined}
                    isLoading={isLoading}
                    error={error}
                    paymentMethods={paymentMethods}
                    billingData={tierPrice}
                  />
                )}
              </form>
            </FormProvider>
          )}
        </div>

        {activeStep === Step.Confirmation && purchaseData && (
          <PurchaseConfirmation purchaseData={purchaseData} billingData={tierPrice} />
        )}
      </Stepper>

      {isLoading && <FullpageLoader />}
    </div>
  )
}
