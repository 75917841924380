import { Grid } from '@mui/material'
import { AppRoute } from 'appRoutes'
import clsx from 'clsx'
import { GenericError, SubscriptionDisplayPlan } from 'const'
import { MainGrid } from 'features/commonUI'
import { BaseReactProps } from 'helpers/types'
import { BillingData, PaymentMethod, Subscription } from 'models'
import { memo } from 'react'

import { PurchaseSummary } from '../../../../components/PurchaseSummary/PurchaseSummary'
import StripeForm from '../../../../components/Stripe/StripeForm/StripeForm'
import UpgradeToEnterprise from '../../../../components/UpgradeToEnterprise/UpgradeToEnterprise'
import styles from './PaymentForm.module.scss'

export interface PaymentFormProps extends BaseReactProps {
  displayPlan?: SubscriptionDisplayPlan
  isLoading?: boolean
  error?: GenericError | null
  isTrial?: boolean
  subscription?: Subscription
  billingData?: BillingData
  paymentMethods: PaymentMethod[]
}

export default memo(function PaymentForm({
  displayPlan,
  isLoading,
  isTrial = false,
  error,
  paymentMethods,
  billingData,
  className,
}: PaymentFormProps) {
  return (
    <MainGrid>
      <Grid item container spacing={2} className={clsx(styles.form, className)}>
        <Grid item xs={12} lg={7}>
          <PurchaseSummary displayPlan={displayPlan} billingData={billingData} isTrial={isTrial} />
          <UpgradeToEnterprise className={styles.upgradeBanner} pathname={AppRoute.AddSecondaryApplication} />
        </Grid>

        <Grid item xs={12} lg={5}>
          <StripeForm isLoading={isLoading} error={error} paymentMethods={paymentMethods} />
        </Grid>
      </Grid>
    </MainGrid>
  )
})
