import { Alert } from '@mui/lab'
import { AlertTitle, Typography } from '@mui/material'

import { CloudflareIntegration } from '../../../../models'
import { IntegrationRemovalDialog } from '../../components/IntegrationRemovalDialog/IntegrationRemovalDialog'

export type IntegrationCloudflareRemovalProps = {
  onRemove: () => Promise<void>
  integration: CloudflareIntegration
  onClose: () => void
}

export function IntegrationCloudflareRemoval({ integration, onClose, onRemove }: IntegrationCloudflareRemovalProps) {
  const paths = [integration.agentDownloadPath, integration.ingressApiPath]

  return (
    <IntegrationRemovalDialog
      onSubmit={onRemove}
      integrationName={integration.workerName}
      onClose={onClose}
      title='Delete Cloudflare integration'
      beforeInputContent={
        <Alert severity='warning' icon={false}>
          <AlertTitle>Proceed with caution!</AlertTitle>
          <Typography variant='body2'>
            By deleting {integration.workerName}, the following paths will be removed:
          </Typography>
          <ul>
            {paths.map((path) => (
              <li key={path}>
                <Typography variant='body2'>
                  https://{integration.domain}/{integration.workerPath}/{path}
                </Typography>
              </li>
            ))}
          </ul>
          <Typography variant='body2'>
            <strong>API calls made using these routes will fail.</strong> Please ensure your Fingerprint Agent is
            utilizing a different installation method.
          </Typography>
        </Alert>
      }
    />
  )
}
