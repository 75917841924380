import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material'
import { Box, Card, Collapse, Stack, Theme, Typography, useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import { SdkRowDetails } from 'models/sdkHealth'
import { ComponentProps, useCallback, useState } from 'react'

import { LoadingValue } from './LoadingValue'
import { SdkLastRequest, SdkName, SdkRequestCount, SdkVersion } from './rowValueFormatters'
import styles from './SdkHealthMobileView.module.scss'
import { RowType, SdkHealthRowDetails } from './SdkHealthRowDetails'
import { VersionStatusTag } from './VersionStatusTag'

const skeletonRows = [undefined, undefined]

type SdkHealthMobileViewProps = {
  rows?: SdkRowDetails[]
  type: RowType
}
export const SdkHealthMobileView = ({ rows, type }: SdkHealthMobileViewProps) => {
  const [openRowsInGroup, setOpenRowsInGroup] = useState(0)
  const handleToggle = useCallback<ComponentProps<typeof SdkHealthPlatformInfoRow>['onToggle']>(
    (isOpen) => setOpenRowsInGroup((o) => o + (isOpen ? 1 : -1)),
    []
  )
  return (
    <Stack gap={1}>
      {(rows ?? skeletonRows).map((row, index) => (
        <SdkHealthPlatformInfoRow
          key={index}
          type={type}
          row={row}
          openRowsInGroup={openRowsInGroup}
          onToggle={handleToggle}
        />
      ))}
    </Stack>
  )
}

type SdkHealthPlatformInfoRowProps = {
  openRowsInGroup: number
  onToggle: (isOpen: boolean) => void
  row?: SdkRowDetails
  type: RowType
}
const SdkHealthPlatformInfoRow = ({ onToggle, openRowsInGroup, row, type }: SdkHealthPlatformInfoRowProps) => {
  const [isCollapsed, setCollapsed] = useState(true)
  const toggle = useCallback(() => {
    onToggle(isCollapsed)
    setCollapsed((c) => !c)
  }, [isCollapsed, onToggle])

  const isDimmed = openRowsInGroup > 0 && isCollapsed

  return (
    <Card
      variant='outlined'
      className={clsx(styles.card, {
        [styles.dimmed]: isDimmed,
      })}
    >
      <RowHeader toggle={toggle} isCollapsed={isCollapsed} row={row} />
      <Collapse in={!isCollapsed} timeout='auto' unmountOnExit>
        <Box className={styles.details}>
          <SdkHealthRowDetails type={type} row={row} />
        </Box>
      </Collapse>
    </Card>
  )
}

type RowHeaderProps = {
  isCollapsed: boolean
  toggle: () => void
  row?: SdkRowDetails
}
const RowHeader = ({ isCollapsed, toggle, row }: RowHeaderProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center' className={styles.rowHeader}>
      <Stack direction='column' gap={1}>
        <Typography variant='body1'>
          <SdkName value={row?.sdkName} />
        </Typography>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          gap={isMobile ? 0 : 2}
          divider={isMobile ? null : <Typography variant='bodyM'>·</Typography>}
        >
          <Typography variant='bodyM'>
            <SdkVersion value={row?.version} />
          </Typography>
          <Typography variant='bodyM'>
            <SdkRequestCount value={row?.numOfRequests} />
          </Typography>
          <Typography variant='bodyM'>
            <SdkLastRequest value={row?.lastRequestAt} />
          </Typography>
        </Stack>
      </Stack>
      <Stack direction='row' spacing={2} alignItems='center'>
        <LoadingValue>
          {(row?.updateUrgency ?? row?.severity) != null ? <VersionStatusTag row={row} /> : null}
        </LoadingValue>
        <div className={styles.toggle} onClick={toggle}>
          {isCollapsed ? <ExpandMoreOutlined color='disabled' /> : <ExpandLessOutlined color='disabled' />}
        </div>
      </Stack>
    </Stack>
  )
}
