import Tag from 'components/Tag/Tag'
import { SubscriptionStatus } from 'models'

export type StatusTagProps = { status: SubscriptionStatus; compact?: boolean }

export default function StatusTag({ compact, status }: StatusTagProps) {
  switch (status) {
    case SubscriptionStatus.Canceled:
      return <Tag compact={compact} bold label='Canceled' color='red' />
    case SubscriptionStatus.Restricted:
      return <Tag compact={compact} bold label='Restricted' color='red' />
    case SubscriptionStatus.Trialing:
      return <Tag compact={compact} bold label='Trial' color='yellow' />
    case SubscriptionStatus.ProofOfConcept:
      return <Tag compact={compact} bold label='Proof of Concept' color='yellow' />
    case SubscriptionStatus.Unpaid:
      return <Tag compact={compact} bold label='Payment Required' color='red' />
    case SubscriptionStatus.PastDue:
      return <Tag compact={compact} bold label='Retrying Payment' color='yellow' />
    default:
      return <Tag compact={compact} bold label='Active' color='green' />
  }
}
