import { Paper, Typography } from '@mui/material'
import Tag from 'components/Tag/Tag'
import { formatDate } from 'features/commonUI'
import { date } from 'helpers/data'
import { formatNum } from 'helpers/format'
import { ExpandedSubscription, SubscriptionType } from 'models'
import { useMemo } from 'react'

import styles from './BillingPlanDetails.module.scss'

const APPLICATION_COLOR_LIST = ['#FF5D22', '#D94F1C', '#B34118', '#621B16', '#0A0A0A']

function pickApplicationColor(idx: number) {
  return APPLICATION_COLOR_LIST[idx % APPLICATION_COLOR_LIST.length]
}

export type SubscriptionBillingDetails = Pick<
  ExpandedSubscription,
  'id' | 'name' | 'type' | 'usageQuota' | 'currentPeriodEndsAt' | 'status'
>

export type BillingPlanDetailsProps = {
  subscriptions: SubscriptionBillingDetails[]
}

function BillingPlanDetails({ subscriptions }: BillingPlanDetailsProps) {
  const prepaidSubscriptions = useMemo(
    () => subscriptions.filter((it) => [SubscriptionType.Prepaid, SubscriptionType.ProofOfConcept].includes(it.type)),
    [subscriptions]
  )

  if (!prepaidSubscriptions.length) {
    return null
  }

  const today = new Date()
  const totalUsageQuota = prepaidSubscriptions.reduce((acc, it) => acc + (it.usageQuota?.quota ?? 0), 0)
  const totalUsageBilled = prepaidSubscriptions.reduce((acc, it) => acc + (it.usageQuota?.billed ?? 0), 0)
  const totalUsageMax = Math.max(totalUsageQuota, totalUsageBilled)

  const firstPrepaidSubscription = prepaidSubscriptions[0]
  const currentPeriodEndDate =
    firstPrepaidSubscription.currentPeriodEndsAt && date(firstPrepaidSubscription.currentPeriodEndsAt)

  const hasFinishedPOC =
    firstPrepaidSubscription &&
    firstPrepaidSubscription.type === SubscriptionType.ProofOfConcept &&
    firstPrepaidSubscription.currentPeriodEndsAt &&
    today > new Date(firstPrepaidSubscription.currentPeriodEndsAt)

  const isPOC = firstPrepaidSubscription?.type === SubscriptionType.ProofOfConcept
  const dateLabel = () => {
    if (isPOC) {
      if (hasFinishedPOC) {
        return 'Trial ended on'
      } else {
        return 'Trial ends on'
      }
    }

    return 'Renewal date'
  }

  const planUsageLabel = () => {
    if (isPOC) {
      return 'Proof of Concept Usage'
    }

    return 'Plan Usage'
  }

  const planUsageDescription = () => {
    if (currentPeriodEndDate) {
      if (isPOC) {
        return (
          <Typography variant='bodyS' className={styles.usageSubtitle}>
            Total <strong>proof of concept</strong> workspace usage during the trial. {dateLabel()}{' '}
            <strong>{formatDate(currentPeriodEndDate)}</strong>
          </Typography>
        )
      }

      return (
        <Typography variant='bodyS' className={styles.usageSubtitle}>
          Total <strong>Enterprise</strong> workspace usage during this period. Usage resets on{' '}
          <strong>{formatDate(currentPeriodEndDate)}</strong>
        </Typography>
      )
    }

    return null
  }

  return (
    <Paper className={styles.container}>
      <Typography variant='h3' component='h2'>
        Plan Details
      </Typography>

      <div className={styles.detailsSection}>
        <div className={styles.detailsItem}>
          <Typography variant='bodyS'>Plan</Typography>
          <Typography variant='bodySMedium'>Enterprise</Typography>
          {isPOC && (
            <Tag
              label={`Proof of concept`}
              tooltip={`Your workspace is trialing Fingerprint Enterprise as a proof of concept.`}
              color='yellow'
              compact
              round
            />
          )}
        </div>

        <div className={styles.detailsItem}>
          <Typography variant='bodyS'>API limit</Typography>
          <Typography variant='bodySMedium'>{formatNum(totalUsageQuota)}</Typography>
        </div>

        {isPOC && (
          <div className={styles.detailsItem}>
            <Typography variant='bodyS'>Cost</Typography>
            <Typography variant='bodySMedium'>Free</Typography>
          </div>
        )}

        {!!currentPeriodEndDate && (
          <div className={styles.detailsItem}>
            <Typography variant='bodyS'>{dateLabel()}</Typography>
            <Typography variant='bodySMedium'>{formatDate(currentPeriodEndDate, 'short')}</Typography>
          </div>
        )}
      </div>

      <div className={styles.usageSection}>
        <Typography variant='bodyMMedium' component='h3' className={styles.usageTitle}>
          {planUsageLabel()}
        </Typography>

        {planUsageDescription()}

        <div className={styles.usageHintBlock}>
          <Typography variant='bodyS' className={styles.usageHintText}>
            API calls: {formatNum(totalUsageBilled)} of {formatNum(totalUsageQuota)}
          </Typography>

          {!!totalUsageQuota && (
            <Typography variant='bodyS' className={styles.usageHintText}>
              {Math.round((totalUsageBilled / totalUsageQuota) * 100)}%
            </Typography>
          )}
        </div>

        <div className={styles.usageBarContainer}>
          {prepaidSubscriptions.map((it, idx) => (
            <div
              key={it.id}
              className={styles.usageBarSection}
              style={{
                backgroundColor: pickApplicationColor(idx),
                width: `${Math.round(((it.usageQuota?.billed || 0) / totalUsageMax) * 100)}%`,
              }}
            />
          ))}
        </div>

        <div className={styles.usageDetailsContainer}>
          {prepaidSubscriptions.map((it, idx) => (
            <div key={it.id} className={styles.usageDetailsItem}>
              <div
                className={styles.usageDetailsItemColorHint}
                style={{ backgroundColor: pickApplicationColor(idx) }}
              />

              <Typography variant='bodySMedium'>{formatNum(it.usageQuota?.billed || 0)}</Typography>
              <Typography variant='bodyS'>{it.name}</Typography>
            </div>
          ))}
        </div>
      </div>
    </Paper>
  )
}

export default BillingPlanDetails
