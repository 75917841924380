import { AppRoute, useRouteParams } from 'appRoutes'
import { useInvoice } from 'hooks/api/invoices'
import { Redirect } from 'react-router-dom'

import { default as InvoiceView } from '../../components/InvoiceView/InvoiceView'

export function InvoicePage() {
  const { invoiceId: id, subscriptionId } = useRouteParams<AppRoute.Invoice>()
  const { data: invoice, error } = useInvoice(id, subscriptionId)

  return error ? <Redirect to={AppRoute.Billing} /> : <InvoiceView invoice={invoice} />
}
