import { useQuery } from '@tanstack/react-query'

import { useAuth } from '../user'
import { extractData, useBuildRequest, useGenericErrorHandler } from './base'

const USER_STATS_QUERY_KEY = ['userStats'] as const

export function useUserStats(enabled = true) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()
  const { isAuthorized } = useAuth()

  return useQuery({
    queryKey: USER_STATS_QUERY_KEY,
    queryFn: () => extractData(withErrorHandling(buildRequest('userStatsGet'))),
    enabled: enabled && isAuthorized,
    cacheTime: 60 * 60 * 1000,
    staleTime: 60 * 60 * 1000,
  })
}
