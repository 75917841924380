import { Button } from '@compass/components'
import { UnfoldLess, UnfoldMore } from '@mui/icons-material'
import { Skeleton } from '@mui/material'
import clsx from 'clsx'
import { CodeSnippet } from 'components'
import { useRef, useState } from 'react'

import styles from './CodeViewer.module.scss'

export interface CodeViewerProps {
  code: string
  language?: string
  showLineNumbers?: boolean
  skeletonPrefix?: string
  skeletonSuffix?: string
  skeletonIndent?: number
  loadingLines?: number
  isLoading?: boolean
  className?: string
  onCopy?: () => void
}

export default function CodeViewer({
  code,
  language = 'json',
  showLineNumbers,
  skeletonPrefix,
  skeletonSuffix,
  skeletonIndent,
  loadingLines = 10,
  isLoading,
  className,
  onCopy,
}: CodeViewerProps) {
  const viewerRef = useRef<HTMLElement | null>(null)
  const [expanded, setExpanded] = useState(false)

  const lineCount = (code.match(/\n/g) ?? []).length + 1
  const canExpand = lineCount > 11

  return (
    <section ref={viewerRef} className={clsx(styles.codeViewer, className)}>
      <div
        className={clsx(styles.codeWrapper, { [styles.expanded]: expanded || !canExpand })}
        style={{ maxHeight: expanded ? 4000 : 300 }}
      >
        {isLoading ? (
          <CodeSkeleton
            prefix={skeletonPrefix}
            suffix={skeletonSuffix}
            indent={skeletonIndent}
            loadingLines={loadingLines}
          />
        ) : (
          <CodeSnippet language={language} showLineNumbers={showLineNumbers} className={styles.code} onCopy={onCopy}>
            {code}
          </CodeSnippet>
        )}
      </div>

      {canExpand && (
        <Button variant='ghost' alt onPress={() => setExpanded((val) => !val)} isDisabled={isLoading} className='mt-4'>
          {expanded ? <UnfoldLess fontSize='inherit' /> : <UnfoldMore fontSize='inherit' />}
          {expanded ? 'Collapse lines' : 'Expand all lines'}
        </Button>
      )}
    </section>
  )
}

interface CodeSkeletonProps {
  prefix?: string
  suffix?: string
  indent?: number
  loadingLines?: number
}

function CodeSkeleton({ prefix, suffix, indent, loadingLines = 10 }: CodeSkeletonProps) {
  return (
    <div className={styles.codeSkeleton} data-testid='code-viewer-skeleton'>
      {prefix}
      {Array(loadingLines)
        .fill(undefined)
        .map((_, index) => (
          <Skeleton
            key={index}
            width={skeletonWidths[index % skeletonWidths.length]}
            height={20}
            style={{ marginLeft: indent }}
          />
        ))}
      {suffix}
    </div>
  )
}

// Pre-generated random values for consistency.
const skeletonWidths = [235, 224, 205, 243, 228]
