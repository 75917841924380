import { Button, LinkButton } from '@compass/components'
import { Check, Close, InfoOutlined, LockOutlined } from '@mui/icons-material'
import { CircularProgress, Paper, TableHead, Tooltip } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import EntityTableBodyEmpty from 'components/Table/CustomStatus/EntityTable'
import {
  Table,
  TableBody,
  TableBodyData,
  TableBodyEmpty,
  TableCell,
  TableContainer,
  TableRow,
} from 'components/Table/Table'
import Tag from 'components/Tag/Tag'
import { COMPANY_NAME, DOCS_WEBHOOKS_URL, GenericError } from 'const'
import { useCurrentSubscription } from 'features/subscription'
import { isSafari } from 'helpers/browser'
import { useConfirmationDialog } from 'hooks'
import { usePermissions } from 'hooks/permissions'
import { ChevronRightIcon, PencilIcon, TrashIcon } from 'lucide-react'
import { EntityData, SubscriptionWebhook, WebhookVerificationStatus } from 'models'
import { ampli } from 'models/ampli'
import { memo } from 'react'
import { useHistory } from 'react-router-dom'

import styles from './WebhooksTable.module.scss'

export interface WebhooksProps {
  entityData: EntityData
  className?: string
  webhooks: SubscriptionWebhook[]
  webhooksLimit: number
  isLoading?: boolean
  error?: GenericError | null
  onWebhookAdd: () => void
  onWebhookEdit: (id: string) => void
  onWebhookDelete: (id: string) => void
}

export default memo(function WebhooksTable({
  entityData,
  webhooks,
  isLoading,
  error,
  onWebhookEdit,
  onWebhookDelete,
}: WebhooksProps) {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { openDialog } = useConfirmationDialog()
  const { isReadOnly } = usePermissions()

  const history = useHistory()

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width='40%'>Url</TableCell>
            <TableCell width='25%'>
              Verification status
              <Tooltip title='Only verified webhooks will receive events' placement='top' arrow>
                <InfoOutlined color='inherit' fontSize='small' className={styles.infoIcon} />
              </Tooltip>
            </TableCell>
            <TableCell width='20%'>Status</TableCell>
            <TableCell width='10%'>Actions</TableCell>
            <TableCell width='5%' />
          </TableRow>
        </TableHead>

        <TableBody columnCount={5} isLoading={isLoading} error={error}>
          <TableBodyData>
            {webhooks.map(({ id, url, verificationStatus, active, basicAuthUser }) => {
              const verified = verificationStatus === WebhookVerificationStatus.Verified
              const authenticated = Boolean(basicAuthUser)
              const link = buildRoute(AppRoute.WebhookEvents, { subscriptionId: currentSubscriptionId, webhookId: id })

              return (
                <TableRow key={id} interactive onClick={() => history.push(link)}>
                  <TableCell className={clsx({ [styles.unverified]: !verified }, styles.url)}>
                    {authenticated && <LockOutlined className={styles.lock} />} <DisplayUrl url={url} />
                  </TableCell>
                  <TableCell>
                    <div className={styles.verificationCell}>
                      <VerificationLabel status={verificationStatus} />
                      {!verified && (
                        <LinkButton
                          href={buildRoute(AppRoute.WebhookVerification, {
                            subscriptionId: currentSubscriptionId,
                            webhookId: id,
                          })}
                          className={styles.actionButton}
                        >
                          Verify
                        </LinkButton>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <StatusLabel isActive={active} />
                  </TableCell>
                  <TableCell>
                    <div className='flex flex-row gap-2 items-center'>
                      <Button
                        isIcon
                        variant='ghost'
                        tone='background2'
                        aria-label='Edit Webhook'
                        onPress={() => {
                          ampli.editWebhookClicked()
                          onWebhookEdit(id)
                        }}
                        isDisabled={isReadOnly}
                      >
                        <PencilIcon />
                      </Button>

                      <Button
                        isIcon
                        variant='ghost'
                        tone='background2'
                        aria-label='Delete Webhook'
                        onPress={() => {
                          ampli.deleteWebhookClicked()
                          openDialog({
                            label: 'Delete Webhook?',
                            onConfirm: () => {
                              onWebhookDelete(id)
                            },
                          })
                        }}
                        isDisabled={isReadOnly}
                      >
                        <TrashIcon />
                      </Button>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Tooltip title='Webhook events' placement='top' arrow>
                      <ChevronRightIcon size={16} />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBodyData>

          <TableBodyEmpty>
            <EntityTableBodyEmpty
              entityData={entityData}
              title='Webhooks'
              body={
                <>
                  Webhook is when {COMPANY_NAME} makes an HTTP POST request to your application&apos;s API when an event
                  occurs. This way, {COMPANY_NAME} is able to immediately notify you when an event occurs and your
                  application doesn&apos;t have to perform complicated polling to determine if something new happened.
                  Webhooks are asynchronous and don&apos;t incur performance overhead when enabled.
                </>
              }
              docsUrl={DOCS_WEBHOOKS_URL}
              showAddButton
            />
          </TableBodyEmpty>
        </TableBody>
      </Table>
    </TableContainer>
  )
})

interface VerificationLabelProps {
  status?: SubscriptionWebhook['verificationStatus']
}

function VerificationLabel({ status }: VerificationLabelProps) {
  switch (status) {
    case 'unverified':
      return <Tag label='Unverified' color='gray' />
    case 'verifying':
      return (
        <Tag
          icon={
            <>
              <CircularProgress color='inherit' size={14} />
            </>
          }
          label='Verifying'
          color='yellow'
        />
      )
    case 'verified':
      return <Tag icon={<Check />} color='green' />
    case 'failed':
    default:
      return <Tag icon={<Close />} color='red' />
  }
}

interface StatusLabelProps {
  isActive?: boolean
}

function StatusLabel({ isActive }: StatusLabelProps) {
  return isActive ? <Tag label='Enabled' color='blue' /> : <Tag label='Disabled' color='gray' />
}

interface DisplayUrlProps {
  url: string
}

function DisplayUrl({ url }: DisplayUrlProps) {
  const willOverflow = url.length > 66

  // When something triggers text-overflow: ellipsis; Safari already shows a tooltip with the full text by default.
  return willOverflow && !isSafari() ? (
    <Tooltip title={url} placement='bottom-start'>
      <span className='text-wrap'>{url}</span>
    </Tooltip>
  ) : (
    <span className='text-wrap'>{url}</span>
  )
}
