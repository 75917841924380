import { Typography } from '@mui/material'
import clsx from 'clsx'
import { PropsWithChildren, ReactNode, ReactNodeArray } from 'react'

import styles from './ActionPanel.module.scss'

export interface ActionPanelProps {
  title?: string
  decoration?: ReactNode
  color?: 'gray' | 'green' | 'red'
  actions: ReactNode | ReactNodeArray
  className?: string
}

export default function ActionPanel({
  title,
  color = 'gray',
  decoration,
  children,
  actions,
  className,
}: PropsWithChildren<ActionPanelProps>) {
  return (
    <section className={clsx(styles.container, className)}>
      {decoration && <div className={clsx(styles.status, styles[color])}>{decoration}</div>}

      <div className={styles.info}>
        {title && (
          <Typography variant='semiBody1' className={styles.title}>
            {title}
          </Typography>
        )}

        {children}

        <footer className={styles.footer}>{actions}</footer>
      </div>
    </section>
  )
}
