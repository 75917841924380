import { Email, Schedule } from '@mui/icons-material'
import { StaticMessage } from 'components'
import { ExternalLayout } from 'features/commonUI'
import { useDocumentTitle, useUnsubscribeEmailMutation } from 'hooks'
import { useQueryParams } from 'hooks/queryParams'
import { EmailType } from 'models'
import { useEffect } from 'react'

export function UnsubscribePage() {
  useDocumentTitle('Unsubscribe from email')

  const { unsubscribeId, emailType } = useQueryParams() as { unsubscribeId: string; emailType: EmailType }
  const { mutate: sendUnsubscribeRequest, isSuccess, isError, error } = useUnsubscribeEmailMutation()

  // run ID checking after mount
  useEffect(() => {
    sendUnsubscribeRequest({ data: { unsubscribeId, emailType } })
  }, [sendUnsubscribeRequest, emailType, unsubscribeId])

  if (isSuccess) {
    return (
      <ExternalLayout>
        <StaticMessage Icon={Email} title='Successful' message='You have been unsubscribed' showRedirect />
      </ExternalLayout>
    )
  }

  if (isError && error) {
    return (
      <ExternalLayout>
        <StaticMessage
          showRedirect
          Icon={Schedule}
          title='Try again later'
          message={error.message || 'Please try again later'}
        />
      </ExternalLayout>
    )
  }

  return (
    <ExternalLayout>
      <StaticMessage showRedirect Icon={Schedule} isLoading title='Please wait' message='Unsubscribing...' />
    </ExternalLayout>
  )
}
