import { Alert, Skeleton, Typography } from '@mui/material'
import { UseInfiniteQueryResult } from '@tanstack/react-query'
import clsx from 'clsx'
import Loader from 'components/Loader/Loader'
import { TablePagination } from 'components/Table/Table'
import { GenericError } from 'const'
import { formatDatetime, GenericEmptyState } from 'features/commonUI'
import { RouterPath } from 'helpers/types'
import { usePaginationForQuery } from 'hooks/usePaginationForQuery'
import { History } from 'lucide-react'
import { PaginatedVisitsResponse, Visit } from 'models'
import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import styles from './VisitsList.module.scss'

export interface VisitsListProps {
  query: UseInfiniteQueryResult<PaginatedVisitsResponse, GenericError>
  pagination: ReturnType<typeof usePaginationForQuery<PaginatedVisitsResponse, GenericError, Visit>>
  footerContent?: ReactNode
  selectedRequestId?: string
  getVisitPath: (visit: Visit) => RouterPath
  isLoading?: boolean
  className?: string
}

export function VisitsList({
  query,
  pagination,
  footerContent,
  selectedRequestId,
  getVisitPath,
  isLoading,
  className,
}: VisitsListProps) {
  const { isFetching, error } = query
  const { values: visits, hasPreviousEntries, goToPreviousPage, hasNextEntries, goToNextPage } = pagination
  // Only having one visit is also considered empty since this list is used to show other visits by the same visitor.
  const isEmpty =
    (visits !== undefined && visits.length === 0) ||
    (visits !== undefined && visits.length === 1 && !hasPreviousEntries && !hasNextEntries)

  return (
    <div className={clsx(className)}>
      <ListBody
        data={visits ?? []}
        selectedRequestId={selectedRequestId}
        getVisitPath={getVisitPath}
        error={error}
        isLoading={isLoading}
        isEmpty={isEmpty}
      />

      {!isEmpty && (
        <div className='flex items-center gap-8'>
          {footerContent}
          {!error && (
            <TablePagination
              hasNextEntries={hasNextEntries}
              hasPreviousEntries={hasPreviousEntries}
              onRequestNextEntries={goToNextPage}
              onRequestPreviousEntries={goToPreviousPage}
              isLoading={isFetching}
              className='!m-0'
            />
          )}
        </div>
      )}

      {isLoading && visits !== undefined && visits.length === 0 && <Loader data-testid='visits-list-loader' />}
    </div>
  )
}

interface VisitEntryProps {
  requestId: string
  timestamp: number
  path: RouterPath
  isSelected?: boolean
  isLoading?: boolean
}

function VisitEntry({ requestId, timestamp, path, isSelected, isLoading }: VisitEntryProps) {
  return (
    <RouterLink to={path} className={clsx(styles.visitEntry, { [styles.selected]: isSelected })}>
      <span className={styles.visitTitle}>
        <Typography variant='bodyM' className={clsx(styles.requestId, styles.monospace)}>
          {isLoading ? <Skeleton width={172} /> : requestId}
        </Typography>
        {!isLoading && isSelected && (
          <Typography variant='bodyS' className={styles.badge}>
            This event
          </Typography>
        )}
      </span>
      <Typography variant='bodyS'>
        {isLoading ? <Skeleton width={80} /> : formatDatetime(new Date(timestamp), 'precise')}
      </Typography>
    </RouterLink>
  )
}

interface ListBodyProps {
  data: Visit[]
  selectedRequestId?: string
  getVisitPath: (visit: Visit) => RouterPath
  error?: GenericError | null
  isLoading?: boolean
  isEmpty?: boolean
}

function ListBody({ data, selectedRequestId, getVisitPath, error, isLoading, isEmpty }: ListBodyProps) {
  if (error) {
    return <Alert severity='error'>{error?.message ?? 'There was an error getting the visits.'}</Alert>
  }

  if (!isLoading && isEmpty) {
    return <EmptyState />
  }

  return (
    <ol className={styles.list}>
      {data.map((visit) => {
        const isSelected = selectedRequestId === visit.requestId

        return (
          <li key={visit.requestId} className={clsx(styles.item)}>
            <VisitEntry
              requestId={visit.requestId}
              timestamp={visit.timestamp}
              path={getVisitPath(visit)}
              isSelected={isSelected}
              isLoading={isLoading}
            />
          </li>
        )
      })}
    </ol>
  )
}

function EmptyState() {
  return (
    <GenericEmptyState
      icon={<History />}
      title='This visitor has no other events'
      description='Starting with a clean slate. No history to show yet...'
    />
  )
}
