import { User, UserRole } from 'models'

import { PartialWithRequired } from './types'

const FINGERPRINT_USER_DOMAINS = ['fingerprint.com', 'fingerprintjs.com']

export function canCreateUsers(currentRole?: UserRole) {
  if (!currentRole) {
    return false
  }

  return currentRole === UserRole.Owner || currentRole === UserRole.Admin || currentRole === UserRole.Administrator
}

export function canEditUsers(currentRole?: UserRole) {
  if (!currentRole) {
    return false
  }

  return currentRole === UserRole.Owner || currentRole === UserRole.Admin || currentRole === UserRole.Administrator
}

export function canDeleteUsers(currentRole?: UserRole) {
  if (!currentRole) {
    return false
  }

  return currentRole === UserRole.Owner || currentRole === UserRole.Admin || currentRole === UserRole.Administrator
}

export function canTransferOwnership(currentRole?: UserRole) {
  return currentRole === UserRole.Owner
}

export function isInternalFingerprintUser(user: PartialWithRequired<User, 'email'>) {
  const domain = user.email.split('@')[1]
  return FINGERPRINT_USER_DOMAINS.includes(domain)
}
