import { Typography } from '@mui/material'
import { formatDate } from 'features/commonUI'
import { AccountTransferRequest, UserStatus } from 'models'

import { ManagedUser } from '../UserManagement/UserManagementData'
import styles from '../UsersTable/UsersTable.module.scss'

export interface UserInvitedSinceProps {
  user: ManagedUser
  pendingAccountTransfer?: AccountTransferRequest
}

export function UserInvitedSince({ user, pendingAccountTransfer }: UserInvitedSinceProps) {
  const { id, status, emailConfirmedAt } = user

  if (status === UserStatus.Pending || !emailConfirmedAt) {
    return (
      <Typography variant='bodyM' className={styles.invitationPending}>
        Invitation pending
      </Typography>
    )
  } else if (pendingAccountTransfer?.newOwnerId === id) {
    return (
      <Typography variant='bodyM' className={styles.ownershipTransferPending}>
        Ownership transfer pending
      </Typography>
    )
  }

  return <Typography variant='bodyM'>{formatDate(emailConfirmedAt, 'numeric')}</Typography>
}
