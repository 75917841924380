export function objectFromEntries(entries: IterableIterator<[string, string]>) {
  const result = {} as Record<string, string>
  for (const [key, value] of entries) {
    result[key] = value
  }
  return result
}

export function filterUndefinedProperties<T>(obj: Record<string, any>) {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== undefined)) as T
}

export function objectFromSearchParams(entries: IterableIterator<[string, string]>) {
  const result = {} as Record<string, string | string[]>
  for (const [key, value] of entries) {
    if (result[key]) {
      if (!Array.isArray(result[key])) {
        result[key] = [result[key] as string]
      }

      ;(result[key] as string[]).push(value)
    } else {
      result[key] = value
    }
  }
  return result
}

export function getHttpStatusRange(statusCode: number) {
  return Math.floor(statusCode / 100) * 100
}

export function deepMerge<S, T>(obj: S, patch: T): S & T {
  function isObject(item: unknown) {
    return item && typeof item === 'object' && !Array.isArray(item)
  }

  const result = { ...obj } as Record<string, unknown>
  for (const key in patch) {
    if (isObject(patch[key])) {
      result[key] = deepMerge(result[key], patch[key])
    } else if (patch[key] !== undefined) {
      result[key] = patch[key]
    }
  }

  return result as S & T
}

export function filterObjectByKeys<K extends string | number | symbol>(
  obj: Partial<Record<K, unknown>>,
  predicate: (key: K) => boolean
) {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => predicate(key as K)))
}

export function generateRandomString(length: number) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

export function stringifyValues(obj: Record<string, { toString: () => string }>): Record<string, string> {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value.toString()]))
}

export function parseIntValues(obj: Record<string, string>): Record<string, number> {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, parseInt(value, 10)]))
}
