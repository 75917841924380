import { Button, LinkButton } from '@compass/components'
import { CheckCircleOutline } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import ResponsivePaper from 'components/ResponsivePaper/ResponsivePaper'
import { useCurrentSubscription } from 'features/subscription'
import { useDowngrade } from 'hooks/changePlan'
import { BillingData, ContactSupportTopic } from 'models'
import { useHistory } from 'react-router-dom'

import { FeatureList } from '../../../../components/FeatureList/FeatureList'
import FullpageLoader from '../../../../components/Loader/FullpageLoader/FullpageLoader'
import { PurchaseSummary } from '../../../../components/PurchaseSummary/PurchaseSummary'
import StatusDialog from '../../../../components/StatusDialog/StatusDialog'
import UpgradeToEnterprise from '../../../../components/UpgradeToEnterprise/UpgradeToEnterprise'
import { DEFAULT_PRODUCT, PLAN_FEATURES, SUBSCRIPTION_DISPLAY_PLANS, SubscriptionDisplayPlan } from '../../../../const'
import { date } from '../../../../helpers/data'
import { usePrice, useSubscription } from '../../../../hooks'
import { useUserContext } from '../../../../hooks/api/context'
import { useContactSupportMutation } from '../../../../hooks/api/support'
import { ChangePlanProps, ChangePlanStep } from './ChangePlan'
import styles from './Downgrade.module.scss'

export interface DowngradeProps extends ChangePlanProps {
  currentPlan: SubscriptionDisplayPlan
}

export default function Downgrade({ currentPlan, step, onChangeStep }: DowngradeProps) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: subscription, isLoading: isLoadingSubscription } = useSubscription(subscriptionId)
  const { data: userContext, isLoading: isLoadingUserContext } = useUserContext()
  const { mutate: downgrade, isLoading: isDowngrading } = useDowngrade()
  const { mutate: contactSupport, isLoading: isContactingSupport } = useContactSupportMutation()
  const { price } = usePrice(DEFAULT_PRODUCT)
  const email = userContext?.email ?? ''
  const history = useHistory()

  function handleDowngrade() {
    downgrade(
      {
        params: { subscriptionId },
      },
      { onSuccess: () => onChangeStep(ChangePlanStep.Confirmation) }
    )
  }

  function handleContactSupport() {
    contactSupport(
      {
        data: {
          email,
          message: `The user with email ${email} requested support instead of downgrading ${subscriptionId}.`,
          formDetails: ContactSupportTopic.ReconsiderDowngrade,
        },
      },
      {
        onSuccess: () => {
          history.push({
            pathname: buildRoute(AppRoute.SubscriptionPlan, { subscriptionId }),
            state: { didContactSupport: true },
          })
        },
      }
    )
  }

  switch (step) {
    case ChangePlanStep.Overview:
      return <Overview currentPlan={currentPlan} onConfirm={() => onChangeStep(ChangePlanStep.Feedback)} />
    case ChangePlanStep.Feedback:
      return (
        <Feedback
          onContact={handleContactSupport}
          onDowngrade={() => onChangeStep(ChangePlanStep.Action)}
          isLoading={isContactingSupport || isLoadingUserContext}
        />
      )
    case ChangePlanStep.Action:
      return (
        <Action
          newPlan={SubscriptionDisplayPlan.Pro}
          billingAnchor={date(subscription?.currentPeriodEndsAt)}
          billingData={price}
          onConfirm={handleDowngrade}
          isLoading={isLoadingSubscription || isLoadingUserContext || isDowngrading}
        />
      )
    case ChangePlanStep.Confirmation:
      return <Confirmation />
  }
}

interface OverviewProps {
  currentPlan: SubscriptionDisplayPlan
  onConfirm: () => void
}

function Overview({ currentPlan, onConfirm }: OverviewProps) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  return (
    <>
      <ResponsivePaper className={styles.overview}>
        <hgroup>
          <Typography variant='h2' component='h1'>
            Don’t rush to downgrade your subscription
          </Typography>
          <Typography variant='body3'>With Smart Signals you have access to these features:</Typography>
        </hgroup>

        <FeatureList features={PLAN_FEATURES[currentPlan]} className={styles.features} />

        <LinkButton href={buildRoute(AppRoute.SubscriptionOverview, { subscriptionId })} fullWidth>
          Stay on {SUBSCRIPTION_DISPLAY_PLANS[currentPlan].name}
        </LinkButton>
        <Button onPress={onConfirm} variant='secondary' fullWidth>
          Downgrade
        </Button>
      </ResponsivePaper>

      <UpgradeToEnterprise pathname={AppRoute.ChangePlan} />
    </>
  )
}

interface ActionProps {
  newPlan: SubscriptionDisplayPlan
  billingAnchor?: Date
  billingData?: BillingData
  onConfirm: () => void
  isLoading?: boolean
}

function Action({ newPlan, billingAnchor, billingData, onConfirm, isLoading }: ActionProps) {
  return (
    <>
      <hgroup>
        <Typography variant='h2' component='h1'>
          Downgrading to Fingerprint Pro
        </Typography>
        <Typography variant='body3'>Identify more visitors with accuracy</Typography>
      </hgroup>

      <PurchaseSummary
        billingAnchor={billingAnchor}
        features={PLAN_FEATURES[newPlan]}
        billingData={billingData}
        action='Downgrade'
        onAction={onConfirm}
      />

      {isLoading && <FullpageLoader testId='change-plan-loader' />}
    </>
  )
}

interface FeedbackProps {
  onContact: () => void
  onDowngrade: () => void
  isLoading?: boolean
}

function Feedback({ onContact, onDowngrade, isLoading }: FeedbackProps) {
  return (
    <StatusDialog
      title={`Not satisfied with ${SUBSCRIPTION_DISPLAY_PLANS[SubscriptionDisplayPlan.Plus].name}?`}
      subtitle='Talk to us to discuss your next steps.'
      primaryAction={{
        name: 'Contact us',
        onAction: onContact,
      }}
      secondaryAction={{
        name: 'Downgrade',
        onAction: onDowngrade,
      }}
      isLoading={isLoading}
    />
  )
}

function Confirmation() {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  return (
    <StatusDialog
      icon={<CheckCircleOutline color='primary' />}
      title='Your Plan Has Been Downgraded'
      subtitle='Changes will be applied at the end of your current billing cycle.'
      primaryAction={{
        name: 'Done',
        actionTo: buildRoute(AppRoute.SubscriptionOverview, { subscriptionId }),
      }}
    />
  )
}
