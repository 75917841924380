import { WarningAmber } from '@mui/icons-material'
import { AppRoute, buildRoute } from 'appRoutes'

import StatusDialog from '../../../../components/StatusDialog/StatusDialog'

interface CancelProps {
  subscriptionId: string
  currentPlan: string
  onCancel: () => void
  isLoading?: boolean
}

export default function Cancel({ subscriptionId, currentPlan, onCancel, isLoading }: CancelProps) {
  return (
    <StatusDialog
      icon={<WarningAmber color='error' />}
      title='Are you sure you want to cancel your subscription?'
      subtitle='Cancelling will delete all of your data and it cannot be recovered.'
      primaryAction={{
        name: `Stay on ${currentPlan}`,
        actionTo: buildRoute(AppRoute.SubscriptionOverview, { subscriptionId }),
      }}
      secondaryAction={{
        name: 'Cancel subscription',
        onAction: onCancel,
      }}
      isLoading={isLoading}
    />
  )
}
