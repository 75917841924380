import { Checkbox, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

import styles from './CheckboxOption.module.scss'

export interface CheckboxOptionProps {
  name: string
  label: string
  description: string
  disabled?: boolean
}

export function CheckboxOption({ name, label, description, disabled }: CheckboxOptionProps) {
  const { control } = useFormContext()

  return (
    <span className={styles.option}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            color='primary'
            checked={value ?? false}
            onChange={onChange}
            disabled={disabled}
            inputProps={{ 'aria-label': name }}
            className={styles.checkbox}
          />
        )}
      />

      <span className={styles.text}>
        <Typography variant='semiBody1' className={styles.label}>
          {label}
        </Typography>
        <Typography variant='body1' className={styles.description}>
          {description}
        </Typography>
      </span>
    </span>
  )
}
