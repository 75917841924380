import { getLocale, isLocale24h } from 'helpers/locale'

type TimeFormat = 'standard' | 'precise'
type DateFormat = 'standard' | 'short' | 'numeric' | 'shortWithYear'

// Datetime only needs the time format since the date format is always 'numeric'.
type DatetimeFormat = TimeFormat

export function formatTime(datetime: Date | string, format: TimeFormat = 'standard', timeZone?: string) {
  const dateObject = asDateObject(datetime)

  return dateObject.toLocaleString(getLocale(), { ...TIME_OPTIONS[format], timeZone }).toLocaleUpperCase(getLocale())
}

export function formatDate(datetime: Date | string, format: DateFormat = 'standard', timeZone?: string) {
  const dateObject = asDateObject(datetime)

  const omitYear = ['standard', 'short'].includes(format) && isCurrentYear(dateObject)
  const options = DATE_OPTIONS[format]

  return dateObject.toLocaleString(getLocale(), {
    ...options,
    year: omitYear ? undefined : options.year,
    timeZone,
  })
}

export function formatDatetime(datetime: Date | string, format: DatetimeFormat = 'standard', timeZone?: string) {
  const dateObject = asDateObject(datetime)

  return `${formatDate(dateObject, 'numeric', timeZone)} ${formatTime(dateObject, format, timeZone)}`
}

const TIME_OPTIONS: Record<TimeFormat, Intl.DateTimeFormatOptions> = {
  standard: {
    hour: 'numeric',
    minute: '2-digit',
    hour12: !isLocale24h(),
  },
  precise: {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: !isLocale24h(),
  },
}

const DATE_OPTIONS: Record<DateFormat, Intl.DateTimeFormatOptions> = {
  standard: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },

  short: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },

  shortWithYear: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },

  numeric: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
}

function asDateObject(datetime: Date | string) {
  return typeof datetime === 'string' ? new Date(datetime) : datetime
}

function isCurrentYear(datetime: Date) {
  return datetime.getFullYear() === new Date().getFullYear()
}
