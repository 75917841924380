import { Stack } from '@compass/components'
import { Skeleton, Typography } from '@mui/material'
import { ContentColumn, Header, MainColumn, SubHeader } from 'features/commonUI'
import { LatencyChart, OperationsChart, useClientSdkPerformanceQuery } from 'features/health'
import { useCurrentSubscription } from 'features/subscription'
import { useHealth } from 'hooks/api/health'

import { SdkHealthWidgetAdapter } from '../../components/widgets/SdkHealthWidget/SdkHealthWidget'
import { ThrottledRequestsHealthWidget } from '../../components/widgets/ThrottledRequestsHealthWidget/ThrottledRequestsHealthWidget'
import { useSdkHealthStatusQuery } from '../../hooks/useSdkHealthStatusQuery'
import styles from './HealthPage.module.scss'

export function HealthPage() {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: health, isLoading: isLoadingHealth } = useHealth(subscriptionId)
  const { data: sdkHealthStatus } = useSdkHealthStatusQuery(subscriptionId)
  const { data: performance } = useClientSdkPerformanceQuery(subscriptionId)

  return (
    <>
      <Header title='Health' />
      <MainColumn>
        <SubHeader title={<Typography variant='h2'>Health</Typography>} />
        <ContentColumn>
          <SubHeader title='System status' />
          <Stack direction={{ default: 'column', md: 'row' }} gap={4}>
            {isLoadingHealth || !health || sdkHealthStatus == null ? (
              <>
                <Skeleton
                  className={styles.widget}
                  variant='rectangular'
                  data-testid='health-widget-loading-skeleton-rect'
                />
                <Skeleton className={styles.widget} variant='rectangular' />
              </>
            ) : (
              <>
                <div data-testid='throttled-requests-widget' className='w-full'>
                  <ThrottledRequestsHealthWidget {...health.throttledRequestsHealth} className={styles.widget} />
                </div>
                <div data-testid='sdk-health-widget' className='w-full'>
                  <SdkHealthWidgetAdapter sdkHealthStatus={sdkHealthStatus} className={styles.widget} />
                </div>
              </>
            )}
          </Stack>
        </ContentColumn>
        {performance == null || performance.stats.length !== 0 ? (
          <Stack direction={{ default: 'column', lg: 'row' }} gap={4}>
            <ContentColumn className='w-full min-w-0'>
              <SubHeader title='Operations' />
              {performance ? (
                <OperationsChart performance={performance} className={styles.widget} />
              ) : (
                <Skeleton className={styles.widget} variant='rectangular' />
              )}
            </ContentColumn>
            <ContentColumn className='w-full min-w-0'>
              <SubHeader title='Latency' />
              {performance ? (
                <LatencyChart performance={performance} className={styles.widget} />
              ) : (
                <Skeleton className={styles.widget} variant='rectangular' />
              )}
            </ContentColumn>
          </Stack>
        ) : null}
      </MainColumn>
    </>
  )
}
