import { User } from 'models'

export type PersonalInfo = Pick<User, 'email' | 'name' | 'jobLevel' | 'jobFunction'>
export type PersonalInfoFormData = Pick<PersonalInfo, 'name' | 'jobLevel' | 'jobFunction'>

export type UpdatePasswordFormData = {
  password: string
  newPassword: string
}

export type TimeSettings = {
  timezone: string
}
export type TimeFormData = TimeSettings

export enum SummaryFrequency {
  Weekly = 'weekly',
  Daily = 'daily',
}

export type EmailNotificationsSettings = {
  summary: boolean
  summaryHour?: string
  summaryDay?: string | null
  subscriptionAlerts: boolean
  isUnsubscribedFromAll: boolean
}

export type EmailNotificationsUpdateBody = {
  summary: boolean
  summaryHour?: string
  summaryDay?: string | null
  subscriptionAlerts: boolean
}
