import { Typography } from '@mui/material'

import { Difference } from '../../../../health/utils/SemanticVersion'
import styles from './IntegrationVersionBadge.module.scss'

interface IntegrationVersionBadgeProps {
  versionBadge: Difference
}

export const IntegrationVersionBadge = ({ versionBadge }: IntegrationVersionBadgeProps) => {
  return (
    <Typography
      variant='bodySMedium'
      color='primary'
      className={styles.versionAvailable}
      data-testid='integration-version-diff-text'
    >
      {versionBadge} available
    </Typography>
  )
}
