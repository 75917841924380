import { useQuery, useQueryClient } from '@tanstack/react-query'

import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

const QUERY_KEY = 'suspectScore'

export function useSuspectScores(subscriptionId?: string) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: [QUERY_KEY, subscriptionId] as const,
    queryFn: ({ queryKey: [_, subId] }) =>
      extractData(withErrorHandling(buildRequest('suspectScores', { params: { subscriptionId: subId! } }))),
    enabled: !!subscriptionId,
  })
}

export function useSuspectScoresUpdateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('suspectScoresUpdate', {
    onSuccess: (data, { params: { subscriptionId } = {} }) => {
      queryClient.setQueryData([QUERY_KEY, subscriptionId], () => data)
    },
  })
}
