import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material'
import {
  CallBasedTableDataPoint,
  FullTableDataPoint,
  TableDataPoint,
  UsageCounterPeriod,
  UsageCounterType,
} from 'models'
import { useState } from 'react'

import { sortByFixedOrder } from '../../../../helpers/data'
import { formatDateByGranularity, formatNum } from '../../../../helpers/format'
import styles from './UsageTable.module.scss'

interface EnhancedTableProps {
  headCells: Array<keyof TableDataPoint>
  granularity: UsageCounterPeriod
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: Order
  orderBy: string
}

function EnhancedTableHead({ headCells, order, orderBy, onRequestSort, granularity }: EnhancedTableProps) {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell} sortDirection={orderBy === headCell ? order : false}>
            <TableSortLabel
              active={orderBy === headCell}
              direction={orderBy === headCell ? order : 'asc'}
              onClick={createSortHandler(headCell)}
            >
              {getColumnLabel(headCell, granularity)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function EnhancedTable({
  data,
  granularity,
}: {
  data: (FullTableDataPoint | CallBasedTableDataPoint)[]
  granularity: UsageCounterPeriod
}) {
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof TableDataPoint>('timestamp')

  const handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof TableDataPoint) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const headCells = sortByFixedOrder(Object.keys(data[0]))

  return (
    <Grid item xs={12}>
      <TableContainer data-testid='usage-table'>
        <Table aria-labelledby='tableTitle' size='small' aria-label='enhanced table'>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            granularity={granularity}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map((dataPoint) => {
              return (
                <TableRow hover tabIndex={-1} key={dataPoint.timestamp}>
                  <TableCell>{formatDateByGranularity(dataPoint.timestamp, granularity)}</TableCell>
                  {headCells
                    .filter((cell) => cell !== 'timestamp')
                    .map((cell) => (
                      <TableCell className={styles.totalsCell} key={cell}>
                        {formatNum(dataPoint[cell] as number)}
                      </TableCell>
                    ))}
                </TableRow>
              )
            })}
            <TableRow tabIndex={-1}>
              <TableCell className={styles.totalsCell}>Total</TableCell>
              {headCells
                .filter((cell) => cell !== 'timestamp')
                .map((cell) => (
                  <TableCell className={styles.totalsCell} key={cell}>
                    {formatNum(data?.map((point) => point[cell] as number)?.reduce((a, b) => a + b))}
                  </TableCell>
                ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

function getColumnLabel(column: keyof Partial<FullTableDataPoint>, granularity: UsageCounterPeriod) {
  switch (column) {
    case 'timestamp':
      return granularity === UsageCounterPeriod.Hour ? 'Time' : 'Date'
    case UsageCounterType.ApiCalls:
      return 'API Calls'
    case UsageCounterType.UniqueVisitors:
      return 'Unique Visitors'
    case UsageCounterType.RestrictedCalls:
      return 'Restricted Calls'
    case UsageCounterType.ThrottledCalls:
      return 'Throttled Calls'
  }
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
