import { Email, Schedule } from '@mui/icons-material'
import { StaticMessage } from 'components'
import { ExternalLayout } from 'features/commonUI'
import { useDocumentTitle, useUnsubscribeNewsletterMutation } from 'hooks'
import { useQueryParams } from 'hooks/queryParams'
import { BroadcastUnsubscribedTopics, UnsubscribeTypes } from 'models'
import { useEffect } from 'react'

export function UnsubscribeNewsletterPage() {
  useDocumentTitle('Unsubscribe from newsletter')

  const { token, type } = useQueryParams() as { token: string; type: UnsubscribeTypes }
  const { mutate: sendUnsubscribeRequest, isSuccess, isError, error } = useUnsubscribeNewsletterMutation()

  useEffect(() => {
    sendUnsubscribeRequest({
      params: { token, type, topic: BroadcastUnsubscribedTopics.Newsletter },
    })
  }, [sendUnsubscribeRequest, token, type])

  if (isSuccess) {
    return (
      <ExternalLayout>
        <StaticMessage
          showRedirect
          Icon={Email}
          title='Successful'
          message='You have been unsubscribed from the newsletter'
        />
      </ExternalLayout>
    )
  }

  if (isError && error) {
    return (
      <ExternalLayout>
        <StaticMessage
          showRedirect
          Icon={Schedule}
          title='Try again later'
          message={error.message ?? 'Please try again'}
        />
      </ExternalLayout>
    )
  }

  return (
    <ExternalLayout>
      <StaticMessage showRedirect Icon={Schedule} title='Please wait' message='Unsubscribing from the newsletter...' />
    </ExternalLayout>
  )
}
