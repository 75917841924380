import { AppRoute } from 'appRoutes'
import { AnalyticsCategory, AnalyticsContext } from 'models'
import { ampli } from 'models/ampli'
import { PropsWithChildren, useCallback, useMemo } from 'react'

import { CodeSnippet } from '../../../../../../components/CodeSnippet/CodeSnippet'
import { normalizeCodeAndReplacements } from '../../utils'
import { useIntegrationReadmeContext } from '../IntegrationReadmeContext'

export default function IntegrationReadmeCodeSnippet({
  language,
  children,
  analyticsContext = AnalyticsContext.AccountSettings,
}: PropsWithChildren<{
  language?: string
  analyticsContext?: AnalyticsCategory.Context['context']
}>) {
  const metadata = useIntegrationReadmeContext()
  let appPagePath = AppRoute.Integration

  // https://fingerprintjs.atlassian.net/browse/GROW-233 - Add app page path to snippet analytics (in the future we will have only context or app page path)
  switch (analyticsContext) {
    case AnalyticsContext.AccountSettings:
      appPagePath = AppRoute.Integration
      break
    case AnalyticsContext.GetStarted:
      appPagePath = AppRoute.SubscriptionGetStarted
      break
  }

  const analyticsSnippetType = useMemo(() => {
    if (!metadata.integrationTag && !metadata.integrationVariantTag) {
      return undefined
    }

    // Preserving current way onboarding reports analytics
    return metadata.integrationVariantTag ?? metadata.integrationTag
  }, [metadata.integrationVariantTag, metadata.integrationTag])

  const onCopySnippet = useCallback(() => {
    if (analyticsSnippetType) {
      ampli.copySnippet({
        context: analyticsContext,
        snippetType: analyticsSnippetType,
        'App Page Path': appPagePath,
      })
    }
  }, [analyticsSnippetType, analyticsContext, appPagePath])

  const onSelectSnippet = useCallback(() => {
    if (analyticsSnippetType) {
      ampli.selectSnippet({
        context: analyticsContext,
        snippetType: analyticsSnippetType,
        'App Page Path': appPagePath,
      })
    }
  }, [analyticsSnippetType, analyticsContext, appPagePath])

  if (typeof children !== 'string') {
    return null
  }

  return (
    <CodeSnippet language={language || ''} onCopy={onCopySnippet} onSelect={onSelectSnippet}>
      {normalizeCodeAndReplacements(children, metadata)}
    </CodeSnippet>
  )
}
