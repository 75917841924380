import { Box, Paper, Skeleton, Stack } from '@mui/material'
import { useCurrentSubscriptionData } from 'features/subscription'

import IntegrationListItem from '../IntegrationListItem/IntegrationListItem'
import { IntegrationMetadata } from '../IntegrationMetadata/integrationMetadata'

type Props = {
  integration: IntegrationMetadata
  external?: boolean
}

export function IntegrationLinkButton({ integration, external }: Props) {
  const { subscription } = useCurrentSubscriptionData()

  if (!subscription) {
    return (
      <Paper variant='buttonDisabled'>
        <Stack direction='row' alignItems='center' spacing={2} padding={2}>
          <Skeleton width={32} height={32} variant='circular' />

          <Box flexGrow='1' overflow='hidden' height={46}>
            <Skeleton height={27} variant='text' />
            <Skeleton height={16} width={90} variant='text' />
          </Box>
        </Stack>
      </Paper>
    )
  }

  return (
    <IntegrationListItem integration={integration} subscription={subscription} target={external ? '_blank' : '_self'} />
  )
}
