import Tag from 'components/Tag/Tag'
import { SubscriptionDisplayPlan } from 'const'
import { getSubscriptionDisplayPlan } from 'helpers/subscription'
import { ExpandedSubscription } from 'models'

type PartialSubscriptionData = Pick<ExpandedSubscription, 'type' | 'latestTier' | 'products'>
type SubscriptionPlanTagProps = { subscription: PartialSubscriptionData; compact?: boolean }

export function getPlanTagLabel(subscription: PartialSubscriptionData) {
  const displayPlan = getSubscriptionDisplayPlan(subscription)

  switch (displayPlan) {
    case SubscriptionDisplayPlan.Enterprise:
      return 'Enterprise'
    case SubscriptionDisplayPlan.Plus:
    case SubscriptionDisplayPlan.Plus99:
      return 'Pro Plus'
    case SubscriptionDisplayPlan.Pro:
      return 'Pro'
    case SubscriptionDisplayPlan.Free:
      return 'Free'
  }
}

export default function PlanTag({ subscription, compact }: SubscriptionPlanTagProps) {
  const displayPlan = getSubscriptionDisplayPlan(subscription)

  switch (displayPlan) {
    case SubscriptionDisplayPlan.Enterprise:
      return <Tag compact={compact} bold label='Enterprise' color='blue' />
    case SubscriptionDisplayPlan.Plus:
    case SubscriptionDisplayPlan.Plus99:
      return <Tag compact={compact} bold label='Pro Plus' color='red' />
    case SubscriptionDisplayPlan.Pro:
      return <Tag compact={compact} bold label='Pro' color='gray' />
    case SubscriptionDisplayPlan.Free:
      return <Tag compact={compact} bold label='Free' color='gray' />
  }
}
