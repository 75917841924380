import { Close } from '@mui/icons-material'
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { withStyles } from '@mui/styles'

import Loader from '../../../../components/Loader/Loader'

const StyledDialog = withStyles({
  paper: ({ isLoading }) => ({
    // Needed for the loader.
    position: 'relative',
    overflow: isLoading ? 'hidden' : 'auto',
  }),
})(MuiDialog)

export interface DialogProps extends Omit<MuiDialogProps, 'onClose' | 'onSubmit'> {
  onClose?: () => void
  isLoading?: boolean
}

export function Dialog({ isLoading, children, ...props }: DialogProps) {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <StyledDialog fullScreen={mdDown} fullWidth maxWidth='md' {...props}>
      {children}
      {isLoading && <Loader testId='dialog-loader' />}
    </StyledDialog>
  )
}

const StyledDialogTitle = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '32px',
    paddingBottom: '24px',
    '&>button:only-child': {
      marginLeft: 'auto',
    },
  },
})(MuiDialogTitle)

export interface DialogTitleProps extends MuiDialogTitleProps {
  hideCloseButton?: boolean
  onClose?: () => void
}

export function DialogTitle({ children, hideCloseButton, onClose, ...props }: DialogTitleProps) {
  return (
    <StyledDialogTitle {...props}>
      {children}
      {!hideCloseButton && onClose && (
        <IconButton size='small' onClick={onClose} style={{ padding: 0 }} data-testid='dialog-close-button'>
          <Close />
        </IconButton>
      )}
    </StyledDialogTitle>
  )
}

const StyledDialogContent = withStyles({
  root: {
    padding: '0 32px',
    paddingBottom: '32px',
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      padding: '32px',
    },
  },
})(MuiDialogContent)

export const DialogContent = StyledDialogContent

const StyledDialogActions = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '32px',
    paddingTop: '0',
    columnGap: '8px',
  },
})(MuiDialogActions)

export const DialogActions = StyledDialogActions
