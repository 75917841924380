import { useQuery } from '@tanstack/react-query'

import { useBuildRequest, useGenericErrorHandler } from './base'

export function useHealth(subscriptionId: string) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['subscription/health', subscriptionId] as const,
    queryFn: async ({ queryKey: [_, subId] }) => {
      const result = await withErrorHandling(buildRequest('healthGet', { params: { subscriptionId: subId } }))
      return result.data
    },
    enabled: subscriptionId != null,
  })
}
