import { Button } from '@compass/components'
import { Typography } from '@mui/material'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from 'features/commonUI'

import { ManagedUser } from '../UserManagement/UserManagementData'
import styles from './CancelTransferOwnershipDialog.module.scss'

export interface CancelTransferOwnershipDialogProps extends DialogProps {
  target?: ManagedUser
  onConfirmCancelTransfer: (target?: ManagedUser) => void
}

export function CancelTransferOwnershipDialog({
  target,
  onConfirmCancelTransfer,
  onClose,
  ...props
}: CancelTransferOwnershipDialogProps) {
  const { name, email } = target ?? { name: 'this user' }
  return (
    <Dialog onClose={onClose} {...props} className={styles.dialog}>
      <DialogTitle>
        <Typography component='span' variant='h1'>
          Cancel ownership transfer
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography variant='bodyM'>
          Are you sure you want to cancel the transfer of account ownership to {name} {email && `(${email})`}? This will
          instantly expire their invitation link. No changes have been made to their account.
        </Typography>
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button onPress={onClose}>Keep invite open</Button>
        <Button variant='destructive' onPress={() => onConfirmCancelTransfer(target)}>
          Yes, cancel ownership transfer
        </Button>
      </DialogActions>
    </Dialog>
  )
}
