import { Paper } from '@mui/material'
import { PropsWithChildren } from 'react'

import { HealthWidget } from './widgets/HealthWidget'
import { PlanWidget } from './widgets/PlanWidget'
import { ScheduleWidget } from './widgets/ScheduleWidget'
import { StatusWidget } from './widgets/StatusWidget'
import { UsageWidget } from './widgets/UsageWidget'
import styles from './WorkspaceStatusBar.module.scss'

export function WorkspaceStatusBar() {
  return (
    <WorkspaceStatusBarWrapper>
      <StatusWidget />
      <PlanWidget />
      <UsageWidget />
      <ScheduleWidget />
      <HealthWidget />
    </WorkspaceStatusBarWrapper>
  )
}

export function WorkspaceStatusBarWrapper({ children }: PropsWithChildren<{}>) {
  return (
    <Paper className={styles.paper} data-testid='status-bar'>
      <div className={styles.grid}>{children}</div>
    </Paper>
  )
}
