import { Button } from '@compass/components'
import { Paper, Typography } from '@mui/material'
import clsx from 'clsx'
import { ExternalLayout } from 'features/commonUI'
import { useEffect, useState } from 'react'

import styles from './SignUpConfirm.module.scss'

export type SignUpConfirmCheckYourMailProps = {
  email: string
  cooldown: number
  onResend: () => void
  isResendingConfirmation: boolean
}

export function SignUpConfirmCheckYourMail({
  email,
  cooldown,
  onResend,
  isResendingConfirmation,
}: SignUpConfirmCheckYourMailProps) {
  const [isButtonClicked, setButtonClicked] = useState(false)

  useEffect(() => {
    if (isResendingConfirmation) {
      setButtonClicked(true)
    }
  }, [isResendingConfirmation])

  const handleClick = () => {
    onResend()
    setButtonClicked(true)
  }

  return (
    <ExternalLayout srcPage='signupConfirm'>
      <Paper className={styles.container}>
        <h1 className={styles.title}>Now, go check your email.</h1>
        <Typography variant='body2' className={styles.description}>
          We sent you a confirmation email. Click the link inside to continue with account setup.
        </Typography>

        <section className={styles.postcardWrapper}>
          <div className={styles.postcard}>
            <h2>{email}</h2>
            <p>This email contains your magic link to set up Fingerprint.</p>
          </div>
        </section>

        {cooldown > 0 ? (
          <Typography variant='body2' className={clsx(styles.timer, { [styles.buttonClicked]: isButtonClicked })}>
            You may resend the email in {cooldown} seconds
          </Typography>
        ) : isResendingConfirmation ? (
          <Typography variant='body2' className={styles.sending}>
            Sending...
          </Typography>
        ) : (
          <Button variant='ghost' onPress={handleClick} className={styles.resendButton}>
            Send me another email
          </Button>
        )}
      </Paper>
    </ExternalLayout>
  )
}
