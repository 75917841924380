import { Alert } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { GenericError } from 'const'
import { GenericEmptyState } from 'features/commonUI'
import { getChartDataPoints } from 'helpers/data'
import { ChartColumn } from 'lucide-react'
import { ChartType, UsageChartData, UsageCounterPeriod, UsageCounterType } from 'models'

import { UsageChart } from '../UsageChart'
import { SubscriptionOverviewState } from './SubscriptionOverviewState'

export function UsageCharts({
  subscriptionId,
  apiRequestsState,
  usageCountersData,
  granularity,
  usageCountersError,
}: {
  subscriptionId: string
  apiRequestsState: SubscriptionOverviewState
  usageCountersData: UsageChartData | undefined
  usageCountersError?: GenericError | null
  granularity?: UsageCounterPeriod
}) {
  if (granularity == null) {
    return null
  }

  switch (apiRequestsState) {
    case SubscriptionOverviewState.HasUsageCounters:
      if (usageCountersData![UsageCounterType.UniqueVisitors]) {
        const { [UsageCounterType.UniqueVisitors]: uniqueVisitorsData, ...callBasedData } = usageCountersData!
        return (
          <>
            <UsageChart
              data={getChartDataPoints({ [UsageCounterType.UniqueVisitors]: uniqueVisitorsData })}
              granularity={granularity}
              chartType={ChartType.UniqueVisitors}
            />
            <UsageChart
              data={getChartDataPoints(callBasedData)}
              granularity={granularity}
              chartType={ChartType.CallBased}
            />
          </>
        )
      } else {
        return (
          <UsageChart
            data={getChartDataPoints(usageCountersData!)}
            granularity={granularity}
            chartType={ChartType.CallBased}
          />
        )
      }

    case SubscriptionOverviewState.NoUsageCountersDuringPeriod:
      return <Alert severity='info'>No calls have been made during this period.</Alert>

    case SubscriptionOverviewState.UsageCountersError:
      return <Alert severity='error'>{usageCountersError!.message}</Alert>

    case SubscriptionOverviewState.Setup:
    default:
      return (
        <GenericEmptyState
          icon={<ChartColumn />}
          title="There's nothing here"
          description='Statistics will appear here once your app is sending requests.'
          buttonProps={{
            children: 'Install Fingerprint now',
            variant: 'primary',
            href: buildRoute(AppRoute.Integrations, { subscriptionId }),
          }}
          className='bg-gray-200 border border-dashed border-gray-400 rounded shadow-inset-100'
        />
      )
  }
}
