import { useQuery } from '@tanstack/react-query'

import { extractData, useBuildRequest, useGenericErrorHandler } from './api/base'

export const useSubscriptionStatsQuery = (subscriptionId?: string, from?: string, to?: string, enabled = true) => {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['subscriptionStats', subscriptionId, from, to] as const,
    enabled: subscriptionId != null && from != null && to != null && enabled,
    queryFn: ({ queryKey: [_, subId, paramFrom, paramTo], signal }) =>
      extractData(
        withErrorHandling(
          buildRequest(
            'subscriptionStats',
            { params: { subscriptionId: subId! }, queryParams: { from: paramFrom, to: paramTo } },
            signal
          )
        )
      ),
  })
}
