import { Button } from '@compass/components'
import { Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { Plus } from 'lucide-react'

import { usePermissions } from '../../hooks/permissions'
import { EntityData } from '../../models'

interface AddEntityButtonProps {
  entityData: EntityData
  variant?: 'primary'
  onlyName?: boolean
  className?: string
  disabledMessage?: string
}

export default function AddEntityButton({
  entityData,
  variant = 'primary',
  onlyName,
  className,
  disabledMessage,
}: AddEntityButtonProps) {
  const { isReadOnly } = usePermissions()
  const { name, count, limit, onAddClick } = entityData

  const limitExceeded = count >= limit
  const buttonIsDisabled = isReadOnly || limitExceeded || !!disabledMessage
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const button = (
    <Button
      variant={variant}
      onPress={onAddClick}
      isDisabled={buttonIsDisabled}
      className={className}
      fullWidth={smDown}
    >
      <Plus />
      {onlyName ? name : `Add ${name.toLowerCase()}`}
    </Button>
  )

  const tooltipContent =
    disabledMessage || (limitExceeded ? `The number of ${name.toLowerCase()}s is limited to ${limit}` : '')

  return tooltipContent ? (
    <Tooltip title={tooltipContent}>
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  )
}
