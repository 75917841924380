import { Button, Stack } from '@compass/components'
import { NavigationAnnouncementModal } from 'features/whatsNew'
import { PaintbrushIcon, X } from 'lucide-react'

import { BannerDescriptorProps } from '../BannerSection/BannerSection'

export function NavigationAnnouncementCallout({ handleClose }: BannerDescriptorProps): JSX.Element | null {
  return (
    <Stack
      direction='column'
      gapX={8}
      gapY={4}
      className='p-6 md:px-4 md:py-2 rounded bg-orange-200 border border-orange-400 text-orange-900'
    >
      <Stack gap={4} className='justify-between items-center'>
        <Stack gap={3} className='items-center'>
          <PaintbrushIcon className='size-4 text-orange-800 hidden md:block' />
          Fingerprint has a new look: navigation, settings, and more.
        </Stack>
        <div className='hidden md:inline-flex ml-auto'>
          <NavigationAnnouncementModal />
        </div>
        <Button isIcon variant='ghost' tone='opaque' aria-label='Close' onPress={handleClose}>
          <X />
        </Button>
      </Stack>
      <div className='flex flex-col md:hidden'>
        <NavigationAnnouncementModal />
      </div>
    </Stack>
  )
}
