import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Alert, Link } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { SUPPORT_PAGE_URL } from 'const'
import { usePaymentMethods, useSubscriptions } from 'hooks'
import { SubscriptionStatus } from 'models'
import { Link as RouterLink } from 'react-router-dom'

interface ConditionalOverduePaymentCalloutProps {
  subscriptionId?: string
  linkToBillingPage?: boolean
}

export function ConditionalOverduePaymentCallout({
  subscriptionId,
  linkToBillingPage = true,
}: ConditionalOverduePaymentCalloutProps) {
  const { data: subscriptions, isLoading: isLoadingSubscriptions } = useSubscriptions()
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = usePaymentMethods()
  const defaultPaymentLast4 = paymentMethods?.find((paymentMethod) => paymentMethod.isDefault)?.cardLast4
  const relevantSubscriptions = subscriptionId ? subscriptions?.filter((s) => s.id === subscriptionId) : subscriptions

  const hasPastDueSubscriptions = relevantSubscriptions?.some((sub) => sub.status === SubscriptionStatus.PastDue)
  const hasUnpaidSubscriptions = relevantSubscriptions?.some((sub) => sub.status === SubscriptionStatus.Unpaid)

  if (isLoadingSubscriptions || isLoadingPaymentMethods || (!hasPastDueSubscriptions && !hasUnpaidSubscriptions)) {
    return null
  }

  if (hasUnpaidSubscriptions) {
    return (
      <Alert severity='warning' icon={<InfoOutlinedIcon />}>
        We noticed the last few payment attempts have failed. To ensure uninterrupted service,{' '}
        {linkToBillingPage ? (
          <Link component={RouterLink} to={buildRoute(AppRoute.Billing)} color='inherit'>
            please update your payment details
          </Link>
        ) : (
          <>please update your payment details</>
        )}
        . If issues persist, your service may be temporarily disabled. Please{' '}
        <Link href={SUPPORT_PAGE_URL} underline='always' color='inherit'>
          contact support
        </Link>{' '}
        with any questions.
      </Alert>
    )
  }

  return (
    <Alert severity='warning' icon={<InfoOutlinedIcon />}>
      Payment failed. No worries, we&apos;ll retry in 24 hours with card ending in <b>****{defaultPaymentLast4}</b>.
      Your service will continue without disruption for now.
    </Alert>
  )
}
