declare global {
  interface Window {
    Metadata: {
      siteScript: {
        sendData: (data: Record<string, unknown>) => void
      }
    }
  }
}

export function sendMetadata(email: string) {
  const formData = { email }
  if (window.Metadata?.siteScript) {
    window.Metadata.siteScript.sendData(formData)
  }
}
