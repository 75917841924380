import { Link, Paper, Typography } from '@mui/material'
import { ReactNode } from 'react'

import { EntityData } from '../../../models'
import AddEntityButton from '../../AddEntityButton/AddEntityButton'
import styles from './EntityTable.module.scss'

export interface EntityTableBodyEmptyProps {
  entityData?: EntityData

  showAddButton?: boolean

  title: ReactNode
  body: ReactNode
  docsUrl: string

  colSpan?: number
}

export default function EntityTableBodyEmpty({
  entityData,
  showAddButton,
  title,
  body,
  docsUrl,
}: EntityTableBodyEmptyProps) {
  return (
    <>
      <Paper elevation={0} className={styles.banner}>
        <Typography variant='semiBody1' className={styles.title}>
          {title}
        </Typography>
        <Typography variant='body2' className={styles.body}>
          {body}
        </Typography>

        <Link href={docsUrl} target='_blank' className={styles.docsLink} underline='hover'>
          Read documentation {'>'}
        </Link>
      </Paper>

      {showAddButton && entityData && (
        <AddEntityButton className={styles.button} entityData={entityData} variant='primary' />
      )}
    </>
  )
}
