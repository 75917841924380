import { Link } from '@mui/material'
import { ComponentType, ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import styles from './LinkWithIcon.module.scss'

export type Props = {
  href?: string
  to?: string
  target?: string
  rel?: string
  icon: ComponentType
  children: ReactNode
  onClick?: () => void
}

export default function LinkWithIcon({ children, icon: Icon, href, to, target, rel, onClick }: Props) {
  const linkProps = to ? { to, component: RouterLink } : { href }

  return (
    <Link className={styles.link} target={target} rel={rel} {...linkProps} underline='hover' onClick={onClick}>
      <Icon /> {children}
    </Link>
  )
}
