import { Button } from '@compass/components'
import { ErrorOutline } from '@mui/icons-material'
import { Alert, Box, Grid, InputLabel, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import Loader from '../../../../components/Loader/Loader'
import { getErrorParams } from '../../../../helpers/data'
import { muiRegister } from '../../../../helpers/reactHookForm'
import { useToast } from '../../../../hooks'
import { useWorkspaceEnvironmentsDeleteMutation } from '../../../../hooks/api/environment'
import { WorkspaceEnvironment } from '../../../../models'
import { ampli } from '../../../../models/ampli'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '../../../commonUI'
import { useCurrentSubscription } from '../../../subscription'
import styles from './DeleteWorkspaceEnvironmentDialog.module.scss'

export interface DeleteEnvironmentDialogProps extends DialogProps {
  workspaceEnvironment: WorkspaceEnvironment
  onClose: () => void
}

type DeleteWorkspaceEnvironmentData = {
  typedEnv: string
}

export default function DeleteWorkspaceEnvironmentDialog({
  workspaceEnvironment,
  ...dialogProps
}: DeleteEnvironmentDialogProps) {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm()

  const { showToast } = useToast()
  const { currentSubscriptionId } = useCurrentSubscription()
  const { mutate: sendDeleteWorkspaceEnvironmentRequest, isLoading } = useWorkspaceEnvironmentsDeleteMutation()

  const onSubmit = (data: DeleteWorkspaceEnvironmentData) => {
    if (data.typedEnv !== workspaceEnvironment.name) {
      setError('typedEnv', {
        type: 'validate',
        message: 'The typed environment name does not match the selected environment.',
      })
      return
    }

    sendDeleteWorkspaceEnvironmentRequest(
      {
        params: {
          workspaceEnvironmentId: workspaceEnvironment.id,
          subscriptionId: currentSubscriptionId,
        },
      },
      {
        onSuccess: () => {
          ampli.environmentDeleteStarted()
          showToast({
            severity: 'success',
            message: 'Done. Your environment has been destroyed.',
            duration: 5000,
          })
          dialogProps.onClose()
        },
        onError: () => {
          showToast({
            message: 'An error occurred when deleting your environment. Please try again.',
            severity: 'error',
          })
          dialogProps.onClose()
        },
      }
    )
  }

  return (
    <Dialog {...dialogProps} classes={{ paper: styles.dialog }}>
      {isLoading && <Loader testId='api-keys-loader' />}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogTitle onClose={dialogProps.onClose} className={styles.dialogTitle}>
          <Typography variant='h1' component='div' className={styles.heading}>
            Delete environment
          </Typography>
        </DialogTitle>

        <DialogContent className={styles.dialogContent}>
          <Grid rowGap={'40px'}>
            <Alert severity='warning' icon={<ErrorOutline className={styles.alertIcon} />} className={styles.alert}>
              Deleting this environment will remove its associated request filtering rules. Don&apos;t worry, events
              data will not be deleted
            </Alert>

            <InputLabel>Type the name of the environment to confirm</InputLabel>
            <TextField
              defaultValue={''}
              {...muiRegister(register, 'typedEnv')}
              {...getErrorParams('typedEnv', errors)}
              fullWidth
            />
          </Grid>
        </DialogContent>

        <DialogActions className={styles.actions}>
          <Box className={styles.buttons}>
            <Button variant='ghost' onPress={dialogProps.onClose}>
              Cancel
            </Button>
            <Button variant='destructive' type='submit'>
              I understand, delete environment
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  )
}
