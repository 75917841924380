import { errorWithFallback } from 'helpers/data'
import { useDocumentTitle, useHomepageRedirect, usePasswordResetCreateMutation } from 'hooks'
import { ComponentProps, useCallback, useEffect, useState } from 'react'

import { FakeDashboardInfoLayout } from '../../components/AnimatedLayout/FakeDashboardInfoLayout'
import { ResetPasswordForm } from '../../components/ResetPasswordForm/ResetPasswordForm'

const COOLDOWN_TIME = 10
const COOLDOWN_STEP = 100 / COOLDOWN_TIME

export function PasswordResetPage() {
  useHomepageRedirect()
  useDocumentTitle('Forgot Password')

  const { mutate: sendPasswordResetRequest, isLoading, isSuccess, error } = usePasswordResetCreateMutation()
  const [cooldownTime, setCooldownTime] = useState(0)

  const initCooldown = () => {
    setCooldownTime(Math.max(85, COOLDOWN_STEP * COOLDOWN_TIME))
  }

  useEffect(() => {
    let nextTimerValue = 0
    if (cooldownTime > 0) {
      nextTimerValue = cooldownTime - COOLDOWN_STEP
    }

    const handle = setInterval(() => {
      setCooldownTime(nextTimerValue)
      if (cooldownTime < 0) {
        clearInterval(handle)
      }
    }, 1000)
    return () => clearInterval(handle)
  }, [cooldownTime])

  const onSubmit = useCallback<ComponentProps<typeof ResetPasswordForm>['onSubmit']>(
    (payload) => {
      initCooldown()
      sendPasswordResetRequest({ data: payload })
    },
    [sendPasswordResetRequest]
  )

  return (
    <FakeDashboardInfoLayout srcPage={'reset'}>
      <ResetPasswordForm
        isLoading={isLoading}
        cooldown={isSuccess ? [cooldownTime / COOLDOWN_STEP, cooldownTime] : [0, 0]}
        error={errorWithFallback(error, 'email', 'Unable to reset password for this email')}
        onSubmit={onSubmit}
      />
    </FakeDashboardInfoLayout>
  )
}
