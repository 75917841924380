import { Button, Sidebar } from '@compass/components'
import { Theme, useMediaQuery } from '@mui/material'
import { AppRoute } from 'appRoutes'
import clsx from 'clsx'
import { SubscriptionSelect } from 'components/SubscriptionSelect/SubscriptionSelect'
import { useCurrentSubscription } from 'features/subscription'
import { useSubscription, useSubscriptions } from 'hooks'
import { useUserStats } from 'hooks/api/userStats'
import {
  Activity,
  House,
  KeyRound,
  MonitorSmartphone,
  Plug,
  ReceiptText,
  Settings,
  Shield,
  Webhook,
  X,
  Zap,
} from 'lucide-react'
import { IntegrationStep } from 'models/integrationStatus'
import { CSSProperties, useMemo } from 'react'

import Logo from '../../../../components/Logo/Logo'
import { CommonMenuSection } from '../Menus/CommonMenuSection'
import { SidebarMenuItem } from '../Menus/SidebarMenuItem'
import { SidebarPlanBanner } from '../SidebarPlanBanner/SidebarPlanBanner'
import { TrialExtendedModal } from '../TrialExtendedModal/TrialExtendedModal'
import FingerprintLogo from './FingerprintLogo.svg'
import styles from './SidebarMain.module.scss'

interface SidebarMainProps {
  mobileOpen?: boolean
  onClose?: () => void
  style?: CSSProperties
}

// TODO handle style prop
export function SidebarMain({ mobileOpen, onClose }: SidebarMainProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  return (
    <Sidebar
      variant={isDesktop ? 'persistent' : 'floating'}
      isOpen={mobileOpen || isDesktop}
      onOpenChange={(open: boolean) => {
        if (!open) {
          onClose?.()
        }
      }}
      className={clsx(styles.root, 'h-svh bg-gray-200')}
    >
      <div className={clsx(styles.drawerContent, 'flex min-h-0 min-w-0 w-full')} data-testid='global-nav'>
        <DrawerContent onClose={onClose} />
      </div>
    </Sidebar>
  )
}

interface DrawerContentProps {
  onClose?: () => void
}

function stripRoute(route: string) {
  return route.replace(AppRoute.SubscriptionRoot, '')
}

function DrawerContent({ onClose }: DrawerContentProps) {
  return (
    <>
      <HeaderRow onClose={onClose} />
      <MenuItems onClose={onClose} />
      <div className={styles.filler} />
      <BottomContent />
      <div className='sticky bottom-0 w-full bg-gray-200 p-4'>
        <Logo className={styles.logo} short={false} fillSecondary='#0D102B' />
      </div>
    </>
  )
}

function HeaderRow({ onClose }: { onClose?: () => void }) {
  const { data: subscriptions, isLoading } = useSubscriptions()

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  return (
    <div className='z-10 sticky top-0 w-full px-3 pt-2 bg-gray-200'>
      <div className={`${styles.header} pb-2`}>
        <SubscriptionSelect subscriptions={subscriptions} isLoading={isLoading} onShouldClose={onClose} />

        {!isDesktop ? (
          <Button isIcon variant='ghost' onPress={onClose} aria-label='Close menu'>
            <X />
          </Button>
        ) : null}
      </div>
    </div>
  )
}

function MenuItems({ onClose }: { onClose?: () => void }) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: currentSubscription } = useSubscription(subscriptionId)
  const { data: userStats } = useUserStats(true)

  const showGetStarted =
    userStats?.customer.milestones.hundredApiCalls === false ||
    currentSubscription?.integrationStep === IntegrationStep.ApiCalls

  const menuItemSections: CommonMenuSection[] = useMemo(
    () => [
      {
        key: 'overview',
        items: [
          ...(showGetStarted
            ? [
                {
                  key: 'get-started',
                  icon: <FingerprintLogo />,
                  label: 'Get started',
                  link: stripRoute(AppRoute.SubscriptionGetStarted),
                  associatedRoutes: [AppRoute.SubscriptionGetStarted],
                  'data-testid': 'globalSidebarGetStartedLink',
                  onAction: onClose,
                },
              ]
            : []),
          {
            key: 'overview',
            icon: <House />,
            label: 'Overview',
            link: stripRoute(AppRoute.SubscriptionOverview),
            associatedRoutes: [AppRoute.SubscriptionOverview],
            'data-testid': 'globalSidebarOverviewLink',
            onAction: onClose,
          },
        ],
      },
      {
        key: 'insights',
        items: [
          {
            key: 'identification',
            icon: <MonitorSmartphone />,
            label: 'Identification',
            link: stripRoute(AppRoute.IdentificationEvents),
            associatedRoutes: [AppRoute.IdentificationEvents],
            onAction: onClose,
          },
          {
            key: 'smart-signals',
            icon: <Zap />,
            label: 'Smart Signals',
            link: stripRoute(AppRoute.SmartSignals),
            associatedRoutes: [AppRoute.SmartSignals, AppRoute.Botd, AppRoute.SuspectScore],
            onAction: onClose,
          },
        ],
      },
      {
        key: 'features',
        items: [
          {
            key: 'api-keys',
            icon: <KeyRound />,
            label: 'API keys',
            link: stripRoute(AppRoute.ApiKeys),
            associatedRoutes: [AppRoute.ApiKeys],
            'data-testid': 'tabnav-apikeys',
            onAction: onClose,
          },
          {
            key: 'health',
            icon: <Activity />,
            label: 'Health',
            link: stripRoute(AppRoute.Health),
            associatedRoutes: [AppRoute.Health, AppRoute.SdkHealth],
            onAction: onClose,
          },
          {
            key: 'security',
            icon: <Shield />,
            label: 'Security',
            link: stripRoute(AppRoute.RequestFiltering),
            associatedRoutes: [AppRoute.RequestFiltering],
            onAction: onClose,
          },
          {
            key: 'webhooks',
            icon: <Webhook />,
            label: 'Webhooks',
            link: stripRoute(AppRoute.Webhooks),
            associatedRoutes: [AppRoute.Webhooks],
            onAction: onClose,
          },
          {
            key: 'sdks-integrations',
            icon: <Plug />,
            label: 'SDKs & integrations',
            link: stripRoute(AppRoute.Integrations),
            associatedRoutes: [AppRoute.Integrations],
            onAction: onClose,
          },
        ],
      },
      {
        key: 'settings',
        items: [
          {
            key: 'plan-usage',
            icon: <ReceiptText />,
            label: 'Plan & usage',
            'data-testid': 'tabnav-plan',
            link: stripRoute(AppRoute.SubscriptionPlan),
            associatedRoutes: [AppRoute.SubscriptionPlan, AppRoute.ChangePlan],
            onAction: onClose,
          },
          {
            key: 'settings',
            icon: <Settings />,
            label: 'Settings',
            link: stripRoute(AppRoute.SubscriptionSettings),
            associatedRoutes: [
              AppRoute.SubscriptionSettings,
              AppRoute.Environments,
              AppRoute.Subdomains,
              AppRoute.WorkspaceList,
              AppRoute.UserManagement,
              AppRoute.Billing,
              AppRoute.Invoice,
              AppRoute.UpcomingInvoice,
              AppRoute.ProfileSettings,
              AppRoute.NotificationSettings,
            ],
            onAction: onClose,
          },
        ],
      },
    ],
    [onClose, showGetStarted]
  )

  return (
    <div className='flex flex-col p-3 w-full gap-5'>
      {menuItemSections.map((section) => (
        <div key={section.key} className='flex flex-col w-full items-stretch gap-0'>
          {section.items.map((item) => (
            <SidebarMenuItem key={item.key} item={item} />
          ))}
        </div>
      ))}
    </div>
  )
}

function BottomContent() {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: currentSubscription } = useSubscription(subscriptionId)

  return (
    <>
      <div className={styles.fullWidth}>
        {currentSubscription && (
          <SidebarPlanBanner
            subscription={currentSubscription}
            sx={{ width: 'calc(100% + 24px)', p: '8px', mx: '-12px', my: 0 }}
          />
        )}
      </div>

      {currentSubscription && (
        <TrialExtendedModal
          status={currentSubscription.status}
          type={currentSubscription.type}
          trialEndAt={currentSubscription.trialEndAt}
          extendedTrialStartedAt={currentSubscription.extendedTrialStartedAt}
        />
      )}
    </>
  )
}
