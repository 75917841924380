import * as FPJS from '@fingerprintjs/fingerprintjs-pro'
import { FPJS_REGION, FPJS_SCRIPT_URL_PATTERN, FPJS_TOKEN, PRODUCT_NAME } from 'const'
import logger from 'helpers/logger'
import { RegionCode } from 'models'

let fpPromise: Promise<FPJS.Agent | undefined> | undefined

export default function initFpjsPro(): Promise<FPJS.Agent | undefined> {
  if (!fpPromise) {
    fpPromise = FPJS.load({
      scriptUrlPattern: FPJS_SCRIPT_URL_PATTERN,
      apiKey: FPJS_TOKEN,
      region: FPJS_REGION,
    }).then(
      (fp) => {
        logger.log(`Initialized ${PRODUCT_NAME}`)
        return fp
      },
      (error) => {
        // Errors are reported by the agent built-in monitoring
        logger.error(`Failed to initialize ${PRODUCT_NAME}: ${error.message}`)
        return undefined
      }
    )
  }

  return fpPromise
}

/**
 * Used for test purposes only
 */
export function purgeFpjsProCache() {
  fpPromise = undefined
}

export function regionCodeToRegion(regionCode: RegionCode): FPJS.Region {
  switch (regionCode) {
    case RegionCode.Euc1:
      return 'eu'
    case RegionCode.Aps1:
      return 'ap'
    case RegionCode.Use1:
      return 'us'
  }
}

export interface FPLoadConfigData {
  apiKey: string | undefined
  domainName?: string
  region: FPJS.Region
}
