import { ExternalLayout } from 'features/commonUI'
import { errorWithFallback } from 'helpers/data'
import {
  useDocumentTitle,
  useHomepageRedirect,
  usePasswordResetConfirmMutation,
  usePasswordResetGetMutation,
} from 'hooks'
import { ComponentProps, useCallback, useEffect } from 'react'

import { AppRoute, useRouteParams } from '../../../../appRoutes'
import { ResetPasswordConfirmForm } from '../../components/ResetPasswordConfirmForm/ResetPasswordConfirmForm'

export function PasswordResetConfirmPage() {
  useHomepageRedirect()
  useDocumentTitle('Forgot Password')

  const { id } = useRouteParams<AppRoute.PasswordResetConfirm>()

  const {
    mutate: sendPasswordResetConfirmRequest,
    isLoading: resetLoading,
    isSuccess: resetSuccess,
    error: resetError,
  } = usePasswordResetConfirmMutation()
  const { mutate: checkPasswordRequest, error: validationError } = usePasswordResetGetMutation()

  // handle form submit
  const onSubmit = useCallback<ComponentProps<typeof ResetPasswordConfirmForm>['onSubmit']>(
    (payload) => {
      sendPasswordResetConfirmRequest({ data: payload, params: { id } })
    },
    [sendPasswordResetConfirmRequest, id]
  )

  // run ID checking after mount
  useEffect(() => {
    checkPasswordRequest({ params: { id } })
  }, [checkPasswordRequest, id])

  return (
    <ExternalLayout srcPage='reset'>
      <ResetPasswordConfirmForm
        isLoading={resetLoading}
        resetSuccess={resetSuccess}
        error={errorWithFallback(resetError, 'password', 'Unable to set new password')}
        validationError={validationError}
        onSubmit={onSubmit}
      />
    </ExternalLayout>
  )
}
