import { useQuery } from '@tanstack/react-query'
import { BotdVisit, BotdVisitsFilter, NoSqlReversePagination } from 'models'

import { useBuildRequest } from './api/base'
import { PaginatedData, useNoSqlPaginatedQuery } from './noSqlPaginatedQuery'

const DEFAULT_BOTD_VISIT_QUERY_LIMIT = 10

export type BotdVisitFilter = { key: keyof BotdVisit; value: string }

export interface UsePaginatedBotdVisitsParams
  extends Omit<NoSqlReversePagination, 'limit' | 'paginationKey'>,
    BotdVisitsFilter {
  subscriptionId: string
  limit?: number
}

export function usePaginatedBotdVisits({
  subscriptionId,
  limit,
  reverse,
  from,
  before,
  requestId,
}: UsePaginatedBotdVisitsParams): PaginatedData<BotdVisit> {
  return useNoSqlPaginatedQuery({
    queryDescriptor: {
      key: [
        'botdVisits',
        subscriptionId,
        from?.toString() ?? '',
        before?.toString() ?? '',
        requestId ?? '',
        reverse ? 'asc' : 'desc',
      ],
      dataKey: 'events',
      endpoint: 'botdVisitsGet',
      options: {
        params: {
          subscriptionId,
        },
        queryParams: {
          from,
          before,
          requestId,
        },
      },
    },
    pagination: {
      limit: limit ?? DEFAULT_BOTD_VISIT_QUERY_LIMIT,
      reverse,
    },
    paginationType: 'reversed',
    refreshOnChange: [subscriptionId],
  })
}

export function useBotdVisit(subscriptionId: string, requestId: string, isEnabled = true) {
  const buildRequest = useBuildRequest()

  return useQuery({
    queryKey: ['botdVisit', requestId],
    queryFn: () =>
      buildRequest('botdVisitGet', {
        params: {
          subscriptionId,
          requestId,
        },
      }),
    enabled: isEnabled,
  })
}
