import { ButtonOrLink, Flex } from '@compass/components'
import { Typography } from '@mui/material'
import { ReactNode } from 'react'

import ResponsivePaper from '../ResponsivePaper/ResponsivePaper'
import styles from './StatusDialog.module.scss'

export interface Action {
  name: string
  onAction?: () => void
  actionTo?: string
}

export interface StatusDialogProps {
  icon?: ReactNode
  title: string | ReactNode
  subtitle: string | ReactNode
  primaryAction?: Action
  secondaryAction?: Action
  isLoading?: boolean
}

export default function StatusDialog({
  icon,
  title,
  subtitle,
  primaryAction,
  secondaryAction,
  isLoading,
}: StatusDialogProps) {
  return (
    <ResponsivePaper className={styles.container}>
      {icon && <span className={styles.iconWrapper}>{icon}</span>}

      <Typography variant='h2' data-testid='statusDialogHeader'>
        {title}
      </Typography>
      <Typography variant='body3'>{subtitle}</Typography>

      <Flex className='items-stretch gap-8'>
        {primaryAction ? (
          <ButtonOrLink
            isLoading={isLoading}
            href={primaryAction.actionTo}
            onPress={primaryAction.onAction}
            className={styles.primaryAction}
            data-testid='statusDialogPrimaryAction'
          >
            {primaryAction.name}
          </ButtonOrLink>
        ) : null}

        {secondaryAction ? (
          <ButtonOrLink
            variant='outline'
            isLoading={isLoading}
            href={secondaryAction.actionTo}
            onPress={secondaryAction.onAction}
            className={styles.secondaryAction}
          >
            {secondaryAction.name}
          </ButtonOrLink>
        ) : null}
      </Flex>
    </ResponsivePaper>
  )
}
