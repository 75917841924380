import { Button } from '@compass/components'
import { Autocomplete, InputLabel, Select, TextField } from '@mui/material'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from 'features/commonUI'
import { getErrorParams } from 'helpers/data'
import { formatMatchRule } from 'helpers/format'
import { muiRegister } from 'helpers/reactHookForm'
import { isValidRegex } from 'helpers/validation'
import {
  TrafficRule,
  TrafficRuleCreatePayload,
  TrafficRulePermissionType,
  TrafficRuleStringMatchRule,
  TrafficRuleType,
  WorkspaceEnvironment,
} from 'models'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { USE_WORKSPACE_ENVIRONMENT } from '../../../../const'
import { httpHeaderNames } from './HttpHeaderDialog.const'
import styles from './HttpHeaderDialog.module.scss'

export interface HttpHeaderDialogProps extends DialogProps {
  trafficRule?: TrafficRule
  onSubmit: (data: TrafficRuleCreatePayload & { id?: string }) => void
  environment: WorkspaceEnvironment
}

export default function HttpHeaderDialog({
  trafficRule,
  onSubmit,
  onClose,
  environment,
  ...dialogProps
}: HttpHeaderDialogProps) {
  const {
    handleSubmit,
    register,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    reset()
  }, [dialogProps.open, reset])

  return (
    <Dialog onClose={onClose} {...dialogProps}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogTitle onClose={onClose}>
          {trafficRule?.id ? 'Edit HTTP Header Rule' : 'Create HTTP Header Rule'}
        </DialogTitle>

        <DialogContent>
          {trafficRule?.id && <input type='hidden' value={trafficRule.id} {...register('id')} />}
          <input type='hidden' value={TrafficRulePermissionType.Deny} {...register('permissionType')} />
          <input type='hidden' value={TrafficRuleType.HTTPHeader} {...register('type')} />

          <InputLabel htmlFor='workspaceEnvironmentId'>Environment</InputLabel>
          {USE_WORKSPACE_ENVIRONMENT && (
            <Select
              id='workspaceEnvironmentId'
              native
              defaultValue={environment.id}
              readOnly
              required
              labelId='environment-label-id'
              inputProps={{
                'aria-label': 'Environment',
              }}
              {...muiRegister(register, 'workspaceEnvironmentId', {
                required: true,
              })}
              className={`${styles.field} ${styles.disabled}`}
            >
              <option key={environment.id} value={environment.id}>
                {environment.name}
              </option>
            </Select>
          )}

          <InputLabel htmlFor='description'>Description</InputLabel>
          <TextField
            id='description'
            className={styles.field}
            inputProps={{ 'aria-label': 'Description' }}
            defaultValue={trafficRule?.description}
            placeholder='Description'
            fullWidth
            variant='outlined'
            {...muiRegister(register, 'description')}
            {...getErrorParams('description', errors)}
          />

          <InputLabel htmlFor='key'>Header</InputLabel>
          <Controller
            defaultValue={trafficRule?.key ?? ''}
            name={'key'}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <Autocomplete
                id='key'
                freeSolo
                options={httpHeaderNames}
                className={styles.field}
                onChange={(_e, v) => {
                  field.onChange(v)
                }}
                fullWidth
                value={field.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Header'
                    inputProps={{ ...params.inputProps, 'aria-label': 'Header' }}
                    variant='outlined'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                    }}
                  />
                )}
              />
            )}
          />

          <InputLabel htmlFor='matchRule'>Match Rule</InputLabel>
          <Select
            id='matchRule'
            native
            defaultValue={trafficRule?.matchRule}
            required
            labelId='header-match-rule-label-id'
            inputProps={{ 'aria-label': 'Match Rule' }}
            {...muiRegister(register, 'matchRule', { required: true })}
            className={styles.select}
          >
            {Object.values(TrafficRuleStringMatchRule).map((matchRule) => (
              <option key={matchRule} value={matchRule}>
                {formatMatchRule(matchRule)}
              </option>
            ))}
          </Select>

          <InputLabel htmlFor='value'>Value</InputLabel>
          <TextField
            id='value'
            {...muiRegister(register, 'value', {
              required: true,
              validate: (value) => {
                const matchRule = getValues('matchRule')
                return matchRule !== TrafficRuleStringMatchRule.Regex || isValidRegex(value)
                  ? true
                  : 'Please enter a valid regex expression'
              },
            })}
            inputProps={{ 'aria-label': 'Value' }}
            className={styles.field}
            fullWidth
            defaultValue={trafficRule?.value}
            placeholder='Value'
            variant='outlined'
            {...getErrorParams('value', errors)}
          />
        </DialogContent>

        <DialogActions>
          <Button size='lg' type='submit' fullWidth aria-label='Save'>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
