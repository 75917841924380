import { OutlinedInputProps } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { PasswordStrengthLevel, PasswordStrengthResponse, StrengthLevelsInOrder } from 'models'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { GenericError, PASSWORD_STRENGTH_LEVELS } from '../../const'
import { getErrorParams } from '../../helpers/data'
import { PASSWORD_CREATE_VALIDATION } from '../../helpers/validation'
import { usePasswordStrength } from '../../hooks'
import { useDebounce } from '../../hooks/debounce'
import { PasswordInputBase } from '../PasswordInput/PasswordInput'
import styles from './CreatePasswordInput.module.scss'

export interface CreatePasswordInputProps {
  name?: string
  showStrengthBar?: boolean
  autoFocus?: boolean
  error?: GenericError | null
  className?: string
}

export default function CreatePasswordInput({ ...props }: CreatePasswordInputProps) {
  const { name = 'password' } = props
  const queryClient = useQueryClient()

  const [password, setPassword] = useState('')
  const debouncedPassword = useDebounce(password)
  const { trigger } = useFormContext()

  const { data: strength, isFetching, error: strengthCheckError } = usePasswordStrength(debouncedPassword)

  useEffect(() => {
    // If you clear the field and start typing again the strength bar shouldn't start with a value
    if (debouncedPassword === '') {
      queryClient.setQueryData<PasswordStrengthResponse>(['passwordStrength'], () => ({
        strengthLevel: PasswordStrengthLevel.Weak,
      }))
    }
  }, [debouncedPassword, queryClient])

  useEffect(() => {
    trigger(name)
  }, [strength, isFetching, strengthCheckError, trigger, name])

  function handlePasswordChanged(newPassword: string) {
    setPassword(newPassword)
  }

  function validator() {
    if (strength === undefined || isFetching) {
      return 'Checking password strength...'
    }

    if (strength.strengthLevel < PasswordStrengthLevel.Good) {
      return 'This password is not strong enough.'
    }

    return true
  }

  return (
    <CreatePasswordInputDisplay
      strength={password ? strength : undefined}
      onPasswordChanged={handlePasswordChanged}
      externalValidator={validator}
      strengthCheckError={!!strengthCheckError}
      isLoading={isFetching}
      inputProps={{
        id: 'new-password',
        autoComplete: 'new-password',
      }}
      {...props}
    />
  )
}

export interface CreatePasswordInputDisplayProps extends CreatePasswordInputProps {
  strength?: PasswordStrengthResponse
  onPasswordChanged?: (password: string) => void
  externalValidator?: (password?: string) => string | boolean
  strengthCheckError?: boolean
  inputProps?: OutlinedInputProps
  isLoading?: boolean
}

export function CreatePasswordInputDisplay({
  name = 'password',
  showStrengthBar,
  strength,
  error,
  onPasswordChanged,
  externalValidator,
  autoFocus,
  strengthCheckError,
  isLoading,
  inputProps,
  className,
}: CreatePasswordInputDisplayProps) {
  const [firstFocus, setFirstFocus] = useState(false)
  const {
    watch,
    formState: { isSubmitted, isValid },
  } = useFormContext()

  useEffect(() => {
    if (isSubmitted && isValid) {
      setFirstFocus(false)
    }
  }, [isSubmitted, isValid])

  const password = watch(name)
  useEffect(() => {
    onPasswordChanged?.(password)
  }, [password, onPasswordChanged])

  function validate(input: string) {
    if (externalValidator) {
      return externalValidator(input)
    }

    return true
  }

  return (
    <div className={clsx(styles.root, className)}>
      <PasswordInputBase
        name={name}
        label='Create a password'
        placeholder='Enter strong password'
        autoFocus={autoFocus && !isLoading}
        isLoading={isLoading}
        showErrorState={firstFocus}
        error={error}
        registerOptions={PASSWORD_CREATE_VALIDATION}
        validate={validate}
        fieldClass={styles.input}
        inputProps={{ ...inputProps, onFocus: () => setFirstFocus(true), 'data-testid': 'passwordField' }}
      />

      <StrengthIndicator
        name={name}
        showStrengthBar={showStrengthBar}
        currentLevel={strength?.strengthLevel}
        error={error}
        strengthCheckError={strengthCheckError}
        isLoading={isLoading}
        feedback={strength?.feedback}
        showHelperText={firstFocus}
      />
    </div>
  )
}

interface StrengthIndicatorProps {
  name: string
  showStrengthBar?: boolean
  currentLevel?: PasswordStrengthLevel
  feedback?: string
  error?: GenericError | null
  strengthCheckError?: boolean
  isLoading?: boolean
  showHelperText?: boolean
}

function StrengthIndicator({
  name,
  showStrengthBar,
  currentLevel,
  error,
  strengthCheckError,
  isLoading,
  feedback,
  showHelperText,
}: StrengthIndicatorProps) {
  const {
    formState: { errors: formErrors },
  } = useFormContext()
  const displayName = currentLevel !== undefined ? PASSWORD_STRENGTH_LEVELS[currentLevel].name : undefined
  const feedbackHelperText = feedback && feedback.length > 0 ? feedback : undefined
  const serverError = error?.param === name && error?.message ? error.message : undefined
  const loaderHelperText = !currentLevel || isLoading ? 'Checking password strength...' : undefined

  const passwordHelperText =
    serverError ??
    feedbackHelperText ??
    (formErrors?.[name]?.message as string) ??
    loaderHelperText ??
    displayName ??
    'Create a strong password.'
  const helperText = strengthCheckError ? 'Could not check password strength.' : passwordHelperText

  const hasErrors = getErrorParams(name, formErrors, error).error || strengthCheckError

  return (
    <div className={styles.strengthIndicator}>
      {showStrengthBar && <StrengthBar currentLevel={currentLevel} />}

      <span
        className={clsx(
          styles.indicatorLabel,
          styles[PASSWORD_STRENGTH_LEVELS[currentLevel ?? PasswordStrengthLevel.Weak].colorClass],
          {
            [styles.error]: hasErrors || currentLevel === PasswordStrengthLevel.Weak,
          }
        )}
        data-testid='passwordCheckResultText'
      >
        {showHelperText ? helperText : ''}
      </span>
    </div>
  )
}

interface StrengthBarProps {
  currentLevel?: PasswordStrengthLevel
}

function StrengthBar({ currentLevel }: StrengthBarProps) {
  return currentLevel === undefined ? (
    <div className={styles.indicatorBar}>
      {StrengthLevelsInOrder.map((level) => (
        <span key={level} className={clsx(styles.indicatorSegment)} />
      ))}
    </div>
  ) : (
    <div
      role='meter'
      aria-valuemin={PasswordStrengthLevel.Weak}
      aria-valuemax={PasswordStrengthLevel.Strong}
      aria-valuenow={currentLevel}
      aria-valuetext={`Password strength: ${PASSWORD_STRENGTH_LEVELS[currentLevel].name}`}
      className={styles.indicatorBar}
    >
      {StrengthLevelsInOrder.map((level) => (
        <span
          data-testid={`meter-segment-${level}`}
          key={level}
          className={clsx(styles.indicatorSegment, styles[PASSWORD_STRENGTH_LEVELS[currentLevel].colorClass], {
            [styles.active]: level <= currentLevel,
          })}
        />
      ))}
    </div>
  )
}
