import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { AppRoute, buildRoute } from 'appRoutes'
import { ExternalLayout } from 'features/commonUI'
import { useDocumentTitle, useSubscriptions, useSubscriptionStartMutation } from 'hooks'
import { OnboardingStep } from 'models'
import { ComponentProps, useCallback, useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import Loader from '../../components/Loader/Loader'
import { getClientTimezone } from '../../helpers/locale'
import { CustomEventGTM, sendCustomEventGTM, trackAccountCreated, visitedOnboarding } from '../../helpers/vendor'
import { useUserContext } from '../../hooks/api/context'
import SubscriptionCreate from './components/SubscriptionCreate/SubscriptionCreate'
import { useOnboarding, useOnboardingSkip } from './onboardingHooks'

export function OnboardingPage() {
  useDocumentTitle('Add Workspace')
  useEffect(() => {
    // Make sure that analytics is enabled on the Sign Up page.
    // The analytics useEffect only triggers the event on the first page load.
    // In case the user navigates to the Sign Up page later, this will trigger the GTM to load.
    visitedOnboarding()
  }, [])

  const { step, isOnboardingInProgress, isLoading: isLoadingOnboarding } = useOnboarding(true)
  const { data: visitorData } = useVisitorData({ extendedResult: true })

  const {
    mutate: startAccount,
    data: startData,
    isLoading: isCreating,
    error: accountError,
  } = useSubscriptionStartMutation()

  const { data: subscriptions = [], isLoading: isSubscriptionsLoading } = useSubscriptions(true)

  const [subscription] = subscriptions
  const subscriptionId = startData?.id ?? subscription?.id

  const { mutate: skipOnboarding } = useOnboardingSkip()
  const history = useHistory()

  const { data: context, isLoading: isLoadingContext } = useUserContext(true, true)
  const isEmailConfirmed = context?.isEmailConfirmed ?? false

  const onDirectToInstallRedirectV2 = useCallback(() => {
    skipOnboarding(
      {},
      {
        onSuccess: () => {
          history.push(buildRoute(AppRoute.SubscriptionGetStarted, { subscriptionId }))
        },
      }
    )
  }, [skipOnboarding, history, subscriptionId])

  const onSubmitCreateSubscription = useCallback<ComponentProps<typeof SubscriptionCreate>['onSubmit']>(
    async (payload) => {
      startAccount(
        {
          data: {
            name: payload.name,
            domain: payload.domain,
            regionCode: payload.regionCode,
            fraudType: payload.fraudType,
            otherFraudType: payload.otherFraudType,
            jobLevel: payload.jobLevel || undefined,
            jobFunction: payload.jobFunction || undefined,
            privacyPolicy: true,
            termsOfService: true,
            timezone: getClientTimezone(), // doesn't work in IE
            visitorLocation: visitorData?.ipLocation?.country?.name,
          },
        },
        {
          onSuccess: () => {
            trackAccountCreated()
            sendCustomEventGTM(CustomEventGTM.TrialStarted)
          },
        }
      )
    },
    [startAccount, visitorData]
  )

  useEffect(() => {
    if (step === OnboardingStep.Install) {
      onDirectToInstallRedirectV2()
    }
  }, [onDirectToInstallRedirectV2, step])

  if (isLoadingOnboarding) {
    return <ExternalLayout isLoading={true} />
  }

  if (step == null || [OnboardingStep.Install, OnboardingStep.Skipped].includes(step)) {
    return <Loader />
  }

  if (isLoadingContext) {
    return <ExternalLayout isLoading={true} />
  }

  if (step === OnboardingStep.Install) {
    return <Loader />
  }

  if (!isOnboardingInProgress && subscriptionId !== undefined) {
    return <Redirect to={buildRoute(AppRoute.SubscriptionOverview, { subscriptionId })} />
  }

  if (!isEmailConfirmed) {
    return <Redirect to={buildRoute(AppRoute.SignupConfirm)} />
  }

  return (
    <ExternalLayout isLoading={isLoadingContext} srcPage='onboarding'>
      <SubscriptionCreate
        isLoading={isCreating || isSubscriptionsLoading}
        onSubmit={onSubmitCreateSubscription}
        error={accountError}
      />
    </ExternalLayout>
  )
}
