export enum ExperimentFlag {
  UseCaseSelectorV2 = 'q3-2024-a-b-use-case-selector-v2-with-skip',
  DirectToInstallV3 = 'q3-2024-a-b-direct-to-install-v3',
  // Keep this to ensure this is a string-based enum
  // otherwise, there will be typing errors
  Dummy = 'dummy',
}

/**
 * The variant value is defined on the Amplitude's end, however they are usually expected
 * to be either `control` or `treatment`
 */
export enum ExperimentValue {
  // Existing logic/UI before the change/experiment
  Control = 'control',

  // New logic/UI during the experiment
  Treatment = 'treatment',
}
