import { useCurrentSubscription } from 'features/subscription'
import { useDocumentTitle, useToast } from 'hooks'
import { useVisitsInfiniteQuery } from 'hooks/visits'
import { DateTime, Settings } from 'luxon'
import { VisitsFilter } from 'models'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { PredefinedRange } from '../../../../helpers/dateRange'
import { useCurrentUser } from '../../../../hooks/api/users'
import { DateRangeContextProvider, useDefaultPredefinedRanges } from '../../../commonUI'
import { VisitHistory } from '../../components/VisitHistory/VisitHistory'
import { useSubmittedFilteredFormValues } from '../../hooks/useSubmittedFilteredFormValues'
import { defaultValues, VisitsFilterView } from '../../utils/const'

export function IdentificationVisitsPage() {
  const [tzReady, setTzReady] = useState(false)
  // Prefetch the current user for timezone.
  const { isLoading, data: currentUser } = useCurrentUser()
  useEffect(() => {
    if (currentUser?.timezone && !isLoading) {
      Settings.defaultZone = currentUser.timezone
      setTzReady(true)
    }
  }, [currentUser?.timezone, isLoading])

  return tzReady ? (
    <DateRangeContextProvider>
      <Page />
    </DateRangeContextProvider>
  ) : null
}

function Page() {
  useDocumentTitle('Identification Events')

  const { showToast } = useToast()

  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  const form = useForm<VisitsFilterView>({
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
  })

  const filters = useSubmittedFilteredFormValues(form)
  const { ranges } = useDefaultPredefinedRanges()
  const mappedFilters = translateFilters(filters, ranges)
  const query = useVisitsInfiniteQuery(
    {
      subscriptionId,
      ...mappedFilters,
    },
    {
      refetchOnWindowFocus: false,
      onError: () =>
        showToast({
          message: 'An error occurred when displaying events. Please try again.',
          severity: 'error',
        }),
    }
  )

  return <VisitHistory query={query} form={form} />
}

// Add the CIDR notation if it's missing.
function translateIpAddressFilter(ipAddress?: string) {
  if (ipAddress === undefined) {
    return {}
  }

  if (ipAddress === '' || ipAddress.includes('/')) {
    return { ipAddress }
  }

  const isIpV4 = ipAddress.includes('.')
  const postFix = isIpV4 ? 32 : 128

  return {
    ipAddress: `${ipAddress}/${postFix}`,
  }
}

function translateFilters(
  values: VisitsFilterView,
  ranges: Record<PredefinedRange['id'], PredefinedRange>
): VisitsFilter {
  return {
    ...values,
    ...translateIpAddressFilter(values.ipAddress),
    since: values.period
      ? ranges[values.period].startDate.valueOf()
      : values.since
        ? DateTime.fromJSDate(new Date(values.since)).startOf('day').valueOf()
        : undefined,
    before: values.period
      ? ranges[values.period].endDate.valueOf()
      : values.before
        ? DateTime.fromJSDate(new Date(values.before)).endOf('day').valueOf()
        : undefined,
  }
}
