import { Divider, Link, Typography } from '@mui/material'
import clsx from 'clsx'
import { SupportEmail } from 'components'
import { CNAME_RECORD_WIKI_URL, dnsLookupURL } from 'const'
import { SSLCertificate } from 'models'
import { ampli } from 'models/ampli'
import { memo, useMemo } from 'react'

import { DNSRecordsTable, DnsRecordType } from '../DNSRecordsTable/DNSRecordsTable'
import { ExportDNSRecordsButton } from '../ExportDNSRecordsButton/ExportDNSRecordsButton'
import styles from './steps.module.scss'
import StepProps from './types'

export interface ValidationStepProps extends Required<StepProps> {
  isInvalidState?: boolean
}

export default memo(function ValidationStep({ certificate, isInvalidState }: ValidationStepProps) {
  const validationRecords = useMemo(() => getValidationRecords(certificate), [certificate])
  const isEmpty = validationRecords.length === 0

  return (
    <ol className={styles.list}>
      <li style={{ listStyle: 'none' }} className={styles.listItem}>
        <span className={styles.tableItem}>
          <Typography component='span'>
            1. Add the following{' '}
            <Link href={CNAME_RECORD_WIKI_URL} target='_blank' underline='hover'>
              <b>CNAME record</b>
            </Link>{' '}
            to your DNS configuration.
          </Typography>

          {!isInvalidState && <ExportDNSRecordsButton records={validationRecords} />}
        </span>
        <DNSRecordsTable
          type='action'
          className={clsx(styles.dnsTable)}
          records={validationRecords}
          isInvalidState={isInvalidState}
        />
      </li>

      {!isEmpty && !isInvalidState && (
        <>
          <Divider className={styles.divider} />

          <li className={styles.listItem}>
            <Typography component='span'>
              Verify that your subdomain is working by using a DNS lookup tool (e.g. using{' '}
              <Link
                href={dnsLookupURL('CNAME', validationRecords[0].dnsValidationName)}
                target='_blank'
                underline='hover'
                onClick={() => ampli.dnsCheckerLinkClicked()}
              >
                DNS Checker
              </Link>
              ). If within 24 hours you don&#39;t receive an email confirming the creation of your certificate, please
              contact <SupportEmail />.
            </Typography>
          </li>
        </>
      )}
    </ol>
  )
})

function getValidationRecords(sslCertificate: SSLCertificate) {
  return sslCertificate.validationRecords.map((record) => ({ ...record, type: DnsRecordType.CNAME }))
}
