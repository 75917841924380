import { useQuery, useQueryClient } from '@tanstack/react-query'

import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

const emailNotificationSettingsCacheKey = ['emailNotificationSettings'] as const

export function useEmailNotificationSettings() {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler({ forceToast: true })

  return useQuery({
    queryKey: emailNotificationSettingsCacheKey,
    queryFn: () => extractData(withErrorHandling(buildRequest('emailNotificationSettingsGet'))),
  })
}

export function useEmailNotificationSettingsUpdateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('emailNotificationSettingsUpdate', {
    onSuccess: () => {
      queryClient.invalidateQueries(emailNotificationSettingsCacheKey)
    },
  })
}

export function useEnableEmailNotifications() {
  const queryClient = useQueryClient()

  return useRequestMutation('enableEmailNotifications', {
    onSuccess: () => {
      queryClient.invalidateQueries(emailNotificationSettingsCacheKey)
    },
  })
}
