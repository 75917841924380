import { useRequestMutation } from './base'

export function useContactSupportMutation() {
  return useRequestMutation('contactSupport', { errorHandling: { forceToast: true } })
}

export function useProductFeedback() {
  return useRequestMutation('productFeedback', {
    errorHandling: {
      forceToast: true,
    },
  })
}
