import { LinearProgress } from '@mui/material'

import styles from './FullpageLoader.module.scss'

export interface FullPageLoaderProps {
  testId?: string
}

export default function FullpageLoader({ testId = 'fullpage-loader' }: FullPageLoaderProps) {
  return (
    <div data-testid={testId} className={styles.loader}>
      <LinearProgress className={styles.progress} />
    </div>
  )
}
