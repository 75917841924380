import compass_components from './compass_components.png'

import avatarDaniel from './daniel-duotone.png'
import avatarDusan from './dusan-duotone.png'
import avatarGabe from './gabe-duotone.png'
import avatarMariam from './mariam-duotone.png'
import avatarOleg from './oleg-duotone.png'

<time dateTime='2024-11-07' className='text-gray-800'>
  Nov 7, 2024
</time>

### Fingerprint has a new look

We’re excited to unveil our newly redesigned dashboard. The new dashboard
design enhances usability, making it easier to navigate, analyze, and
configure your Fingerprint setup.

<figure>
  <img
    src={compass_components}
    alt='Various components of the improved Fingerprint Dashboard design'
    width='1481'
    height='934'
  />
  <figcaption>Sidebar / Settings / Smart Signals / Navbar</figcaption>
</figure>

Key updates include a **unified settings page** for account, workspace, and
user management, improved access to Smart Signals features, and a
**refreshed sidebar** for faster navigation.

Stay tuned as we continue to ship Dashboard improvements. If you have any
questions or suggestions, please share—your feedback goes directly to our
Slack.

Thank you.

<div
  className='not-prose flex flex-wrap items-center gap-2 overflow-hidden'
  title='5 contributors'
>
  <div className='flex items-center'>
    <span
      className='flex-shrink-0 hover:mr-2 border border-white shadow-avatar rounded-sm overflow-hidden cursor-help transition-all ease-out-cubic'
      title='Dániel Stein, Engineer'
    >
      <img
        src={avatarDaniel}
        className='object-cover rounded-[2px]'
        alt='Portrait of Dániel Stein, engineer at Fingerprint'
        width='20'
        height='20'
      />
    </span>
    <span
      className='flex-shrink-0 -ml-1 hover:mr-2 border border-white shadow-avatar rounded-sm overflow-hidden cursor-help transition-all ease-out-cubic'
      title='Dusan Lilic, Technical Product Manager'
    >
      <img
        src={avatarDusan}
        className='object-cover rounded-[2px]'
        alt='Portrait of Dusan Lilic, technical product manager at Fingerprint'
        width='20'
        height='20'
      />
    </span>
    <span
      className='flex-shrink-0 -ml-1 hover:mr-2 border border-white shadow-avatar rounded-sm overflow-hidden cursor-help transition-all ease-out-cubic'
      title='Gabe Fatone, Product Designer'
    >
      <img
        src={avatarGabe}
        className='object-cover rounded-[2px]'
        alt='Portrait of Gabe Fatone, product designer at Fingerprint'
        width='20'
        height='20'
      />
    </span>
    <span
      className='flex-shrink-0 -ml-1 hover:mr-2 border border-white shadow-avatar rounded-sm overflow-hidden cursor-help transition-all ease-out-cubic'
      title='Mariam Mahran, Test Engineer'
    >
      <img
        src={avatarMariam}
        className='flex-shrink-0 object-cover rounded-[2px]'
        alt='Portrait of Mariam Mahran, test engineer at Fingerprint'
        width='20'
        height='20'
      />
    </span>
    <span
      className='flex-shrink-0 -ml-1 border border-white shadow-avatar rounded-sm overflow-hidden cursor-help transition-all ease-out-cubic'
      title='Oleg Klimenko, Engineer'
    >
      <img
        src={avatarOleg}
        className='object-cover rounded-[2px]'
        alt='Portrait of Oleg Klimenko, engineer at Fingerprint'
        width='20'
        height='20'
      />
    </span>
  </div>
  <span className='text-gray-800 overflow-hidden'>
    <p className='truncate'>Dániel S, Dusan L, Gabe F, Mariam M, Oleg K</p>
  </span>
</div>
