import { ReactNode } from 'react'

import { Difference } from '../../../health/utils/SemanticVersion'

export enum IntegrationStatus {
  // Common statuses
  IssueDetected = 'Issue Detected',
  ChangesPending = 'Changes Pending',
  // Cloudflare statuses
  NotInstalled = 'Not Installed',
  Installed = 'Installed',
  RemovalPending = 'Deletion in progress',
  // CloudFront statuses
  UpdatedEnabled = 'Updates enabled',
  UpdatesDisabled = 'Updates disabled',
  // Custom proxy integration statuses
  ReceivingRequests = 'Receiving Requests',
  NoActivity = 'No activity',
}

export type Metadata = {
  title: string
  value?: ReactNode
  versionBadge?: Difference | null
}

export enum IntegrationUsageValueColor {
  AllRequests = '#212121',
  ProxySecretErrors = '#F44336',
  ProxyRequests = '#4CAF50',
  NonProxyRequests = '#2196F3',
  WithoutProxySecret = '#FF9800',
}
