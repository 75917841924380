import { LinkButton } from '@compass/components'
import { ShowChartOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { useCurrentSubscription } from 'features/subscription'

import styles from './EmptyState.module.scss'

export const EmptyState = () => {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  return (
    <Box className={styles.emptyState}>
      <Box className={styles.emptyStateKeyIcon}>
        <ShowChartOutlined />
      </Box>
      <Box className={styles.emptyStateTextContent}>
        <Typography variant='semiBody1'>Not enough data</Typography>
        <Typography variant='bodyS'>We need more data to generate this chart. Make some API requests!</Typography>
      </Box>
      <LinkButton
        size='sm'
        className={styles.emptyStateButton}
        href={buildRoute(AppRoute.Integrations, { subscriptionId })}
      >
        Go to integrations
      </LinkButton>
    </Box>
  )
}
