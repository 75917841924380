import { Alert, Box, Button, Grid, InputLabel, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import Loader from '../../../../components/Loader/Loader'
import { getErrorParams } from '../../../../helpers/data'
import { muiRegister } from '../../../../helpers/reactHookForm'
import { useToast } from '../../../../hooks'
import { useDeleteCertificateMutation } from '../../../../hooks/api/ssl_certificates'
import { SSLCertificate } from '../../../../models'
import { ampli } from '../../../../models/ampli'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '../../../commonUI'
import { useCurrentSubscription } from '../../../subscription'
import styles from './DeleteSubdomainDialog.module.scss'

export interface DeleteSubdomainDialogProps extends DialogProps {
  certificate: SSLCertificate
  onClose: () => void
  onDelete: () => void
}

type DeleteCertificateData = {
  typedDomain: string
}

export default function DeleteSubdomainDialog({ certificate, onDelete, ...dialogProps }: DeleteSubdomainDialogProps) {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm()

  const { showToast } = useToast()
  const { currentSubscriptionId } = useCurrentSubscription()
  const { mutate: deleteCertificate, isLoading } = useDeleteCertificateMutation()

  const onSubmit = (data: DeleteCertificateData) => {
    if (data.typedDomain !== certificate.domainName) {
      setError('typedDomain', {
        type: 'validate',
        message: 'Please enter the subdomain.',
      })
      return
    }

    deleteCertificate(
      {
        params: {
          id: certificate.id,
          subscriptionId: currentSubscriptionId,
        },
      },
      {
        onSuccess: () => {
          ampli.subdomainDeleted()
          showToast({
            severity: 'success',
            message: 'Done. Your subdomain has been deleted.',
            duration: 5000,
          })
          onDelete()
          dialogProps.onClose()
        },
        onError: () => {
          showToast({
            message: 'An error occurred when deleting your subdomain. Please try again.',
            severity: 'error',
          })
          dialogProps.onClose()
        },
      }
    )
  }

  return (
    <Dialog {...dialogProps} classes={{ paper: styles.dialog }}>
      {isLoading && <Loader testId='delete-subdomain-loader' />}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogTitle onClose={dialogProps.onClose} className={styles.dialogTitle}>
          <Typography variant='h1' component='div' className={styles.heading}>
            Delete subdomain?
          </Typography>
        </DialogTitle>

        <DialogContent className={styles.dialogContent}>
          <Grid rowGap={'40px'}>
            <Alert icon={false} severity='warning' className={styles.alert}>
              <Typography variant='bodyMMedium' component='h3' marginBottom={'4px'} color={'#663D00'}>
                You are about to delete {certificate.domainName}
              </Typography>
              <Typography variant='body3' component='p'>
                Before proceeding, just make sure your application isn&apos;t using this subdomain.
              </Typography>
            </Alert>

            <Typography variant='bodyM' component='p' marginBottom={'24px'} color={'#212121'}>
              If needed, you can always add this subdomain again at a later time.
            </Typography>

            <InputLabel>Type the subdomain to confirm</InputLabel>
            <TextField
              defaultValue={''}
              {...muiRegister(register, 'typedDomain')}
              {...getErrorParams('typedDomain', errors)}
              fullWidth
            />
          </Grid>
        </DialogContent>

        <DialogActions className={styles.actions}>
          <Box className={styles.buttons}>
            <Button size='large' color='lightGrey' variant='outlined' onClick={dialogProps.onClose}>
              Cancel
            </Button>
            <Button size='large' variant='contained' color='danger' type='submit'>
              Delete subdomain
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  )
}
