import { Stack } from '@compass/components'
import { Typography } from '@mui/material'
import clsx from 'clsx'
import { ComponentProps, PropsWithChildren } from 'react'

type SubHeaderProps = {
  title?: React.ReactNode
  description?: React.ReactNode
  actions?: React.ReactNode
  className?: string
} & Omit<ComponentProps<'div'>, 'title'>

export function SubHeader({ title, description, actions, className, ...props }: SubHeaderProps) {
  return (
    <Stack
      direction={{ default: 'column', lg: 'row' }}
      gapX={10}
      gapY={4}
      className={clsx(className, `flex-wrap justify-between ${description ? 'lg:items-end' : 'lg:items-start'} `)}
      {...props}
    >
      {title || description ? (
        <Stack direction='column' gap={1}>
          {title ? (
            typeof title === 'string' ? (
              <Typography variant='bodyLMedium' component='h3'>
                {title}
              </Typography>
            ) : (
              title
            )
          ) : null}
          {description ? (
            typeof description === 'string' ? (
              <Typography variant='bodyM'>{description}</Typography>
            ) : (
              description
            )
          ) : null}
        </Stack>
      ) : null}
      {actions ? (
        <Stack direction={{ default: 'column', sm: 'row' }} gap={2} className='lg:justify-end'>
          {actions}
        </Stack>
      ) : null}
    </Stack>
  )
}

export function ContentColumn({ children, className }: PropsWithChildren<{ className?: string }>) {
  return <div className={clsx('flex flex-col gap-4', className)}>{children}</div>
}
