import { InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { RegionFlag } from 'components'
import { Region, REGIONS } from 'const'
import { ampli } from 'models/ampli'
import { useEffect } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import styles from './RegionSelector.module.scss'

export type Props = {
  regions: Region[]
  className?: string
  source?: 'onboarding' | 'account settings'
}

export default function RegionSelector({ regions, className, source }: Props) {
  const { control } = useFormContext()

  const region = useWatch({ name: 'regionCode' })

  useEffect(() => {
    if (region) {
      ampli.selectRegion({ region, context: source })
    }
  }, [region, source])

  return (
    <>
      <InputLabel htmlFor='regionCode'>Where are your users located?</InputLabel>
      <Controller
        control={control}
        name='regionCode'
        defaultValue={REGIONS.use1.code}
        render={({ field }) => (
          <Select
            id='regionCode'
            variant='outlined'
            fullWidth
            MenuProps={{ classes: { list: styles.menu } }}
            {...field}
            classes={{ select: styles.select }}
            className={className}
          >
            {regions.map(({ title, code }) => (
              <MenuItem key={code} value={code} classes={{ root: styles.item }}>
                <RegionFlag regionCode={code} className={styles.flag} />

                <span className={styles.info}>
                  <Typography component='span' variant='body2'>
                    {title}
                  </Typography>
                </span>
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </>
  )
}
