import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material'
import { Box, Collapse } from '@mui/material'
import clsx from 'clsx'
import { TableCell, TableRow } from 'components/Table/Table'
import { SdkHealthVersionDetails, SdkRowDetails } from 'models/sdkHealth'
import { useCallback, useState } from 'react'

import { LoadingValue } from './LoadingValue'
import { SdkLastRequest, SdkName, SdkRequestCount, SdkVersion } from './rowValueFormatters'
import styles from './SdkHealthFullTableRow.module.scss'
import { RowType, SdkHealthRowDetails } from './SdkHealthRowDetails'
import { VersionStatusTag } from './VersionStatusTag'

type SdkHealthFullTableRowProps = {
  row?: SdkRowDetails
  versionDetails?: SdkHealthVersionDetails
  type: RowType
  openRowsInGroup: number
  onToggle: (isOpen: boolean) => void
}

export const SdkHealthFullTableRow = ({ row, type, openRowsInGroup, onToggle }: SdkHealthFullTableRowProps) => {
  const [isCollapsed, setCollapsed] = useState(true)
  const toggle = useCallback(() => {
    onToggle(isCollapsed)
    setCollapsed((c) => !c)
  }, [isCollapsed, onToggle])

  const isDimmed = openRowsInGroup > 0

  const firstRowProps = {
    className: clsx(styles.headerRowCell, { [styles.dimmed]: isDimmed }),
    onClick: toggle,
  }

  const mainCellProps = {
    className: clsx(styles.headerRowCell, styles.bold, { [styles.dimmed]: isDimmed && isCollapsed }),
    onClick: toggle,
  }

  return (
    <>
      <TableRow className={styles.row} interactive>
        <TableCell {...mainCellProps}>
          <SdkName value={row?.sdkName} />
        </TableCell>
        <TableCell {...firstRowProps}>
          <SdkVersion value={row?.version} />
        </TableCell>
        <TableCell {...firstRowProps}>
          <SdkRequestCount value={row?.numOfRequests} short />
        </TableCell>
        <TableCell {...firstRowProps}>
          <SdkLastRequest value={row?.lastRequestAt} />
        </TableCell>
        <TableCell {...mainCellProps}>
          <LoadingValue>
            {(row?.updateUrgency ?? row?.severity) != null ? <VersionStatusTag row={row} /> : null}
          </LoadingValue>
        </TableCell>
        <TableCell {...mainCellProps}>
          <div className={styles.toggle}>
            {isCollapsed ? (
              <ExpandMoreOutlined color='disabled' aria-label='Expand details' />
            ) : (
              <ExpandLessOutlined color='disabled' aria-label='Collapse details' />
            )}
          </div>
        </TableCell>
      </TableRow>
      <TableRow className={styles.secondRow}>
        <TableCell colSpan={6} className={styles.fullWidth}>
          <Collapse in={!isCollapsed} timeout='auto' unmountOnExit>
            <Box mb={2} mt={1}>
              <SdkHealthRowDetails type={type} row={row} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
