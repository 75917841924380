import { Button } from '@compass/components'
import { ErrorOutline } from '@mui/icons-material'
import { Alert, Link, Typography } from '@mui/material'
import { ContentColumn, SubHeader } from 'features/commonUI'
import { useMemo, useState } from 'react'

import VerifyOtpDialog from '../../components/VerifyOtpDialog/VerifyOtpDialog'
import { useAuth, usePaymentMethods, useSubscriptions } from '../../hooks'
import { useDeleteAccount } from '../../hooks/api/account'
import { useUserContext } from '../../hooks/api/context'
import { useSendOneTimePassword } from '../../hooks/api/email'
import { useUsers } from '../../hooks/api/users'
import { ExpandedSubscription, SubscriptionType, UserRole } from '../../models'
import { OtpConfirmationDialog } from './components/OtpConfirmationDialog'

const hasPaidAccount = (subs: ExpandedSubscription[] | undefined) => {
  return (
    subs &&
    subs.some(
      (subscription) => subscription.type === SubscriptionType.Prepaid || subscription.type === SubscriptionType.Paid
    )
  )
}

export function AccountDeleteSection() {
  const {
    mutate: sendOneTimePassword,
    isLoading: isSendingOneTimePassword,
    error: oneTimePasswordError,
  } = useSendOneTimePassword()
  const { accessTokenPayload } = useAuth()
  const currentRole = accessTokenPayload?.role
  const { mutate: deleteAccount, isLoading: isDeletingAccount } = useDeleteAccount()
  const { data: context } = useUserContext()
  const { data: users } = useUsers()
  const { data: subscriptions } = useSubscriptions()
  const { data: paymentMethods } = usePaymentMethods()
  const [isOtpSendDialogOpen, setIsOtpSendDialogOpen] = useState(false)
  const [isOtpConfirmationDialogOpen, setIsOtpConfirmationDialogOpen] = useState(false)

  const onOpenConfirm = () => {
    setIsOtpConfirmationDialogOpen(true)
  }

  function onDeleteAccount() {
    setIsOtpConfirmationDialogOpen(false)
    sendOneTimePassword(
      { data: { oneTimePasswordFlow: 'account_deletion' } },
      {
        onSuccess: () => {
          setIsOtpSendDialogOpen(true)
        },
      }
    )
  }

  function onConfirmDeleteAccount(otp: string) {
    deleteAccount({ data: { password: otp } }, {})
  }

  const handleClose = () => {
    setIsOtpSendDialogOpen(false)
    setIsOtpConfirmationDialogOpen(false)
  }

  const isLoading = currentRole == null || paymentMethods == null || subscriptions == null || users == null

  const errorMessage = useMemo(() => {
    const canDeleteAccount =
      currentRole === UserRole.Owner || currentRole === UserRole.Admin || currentRole === UserRole.Administrator

    if (!canDeleteAccount) {
      return (
        <>
          Heads up, account deletion is restricted for account owners and admins. Please,{' '}
          <Link href='https://fingerprint.com/support/' target='_blank' rel='noreferrer' color='inherit'>
            contact support
          </Link>{' '}
          if you wish to delete your account.
        </>
      )
    }

    if (users && users.length > 1) {
      return (
        <>
          Heads up, account deletion is restricted for accounts with team members. Please remove existing team members
          or{' '}
          <Link href='https://fingerprint.com/support/' target='_blank' rel='noreferrer' color='inherit'>
            contact support
          </Link>{' '}
          if you wish to delete your account.
        </>
      )
    }

    if (hasPaidAccount(subscriptions)) {
      return (
        <>
          Heads up, account deletion is restricted for accounts with a history of payments. Please,{' '}
          <Link href='https://fingerprint.com/support/' target='_blank' rel='noreferrer' color='inherit'>
            contact support
          </Link>{' '}
          if you wish to delete your account.
        </>
      )
    }

    if (paymentMethods && paymentMethods.length > 0) {
      return (
        <>
          Heads up, accounts with payment methods cannot be deleted. Please,{' '}
          <Link href='https://fingerprint.com/support/' target='_blank' rel='noreferrer' color='inherit'>
            contact support
          </Link>{' '}
          if you wish to delete your account.
        </>
      )
    }

    return null
  }, [currentRole, paymentMethods, subscriptions, users])

  return (
    <ContentColumn>
      <SubHeader
        title={
          <Typography variant='bodyLMedium' component='h3' className='text-red-800'>
            Account deletion
          </Typography>
        }
        description="Permanently delete your team's Fingerprint account. This cannot be undone, so please be certain."
      />
      {!isLoading && errorMessage ? (
        <Alert severity='warning' icon={<ErrorOutline />}>
          {errorMessage}
        </Alert>
      ) : null}
      <div>
        <Button variant='destructive' onPress={onOpenConfirm} isDisabled={isLoading || errorMessage != null}>
          Permanently delete account
        </Button>
      </div>

      <OtpConfirmationDialog
        calloutBox={{
          title: 'Proceed with caution!',
          message: (
            <div>
              Deleting your account is a permanent action and <strong> cannot be undone</strong>. We will temporarily
              store your information in our backup systems for 30 days and then the backups will be deleted. If you need
              to preserve events data, we recommend exporting.{' '}
            </div>
          ),
        }}
        extraInfo='Click “Continue” and we’ll send you a one-time password to confirm deletion.'
        confirmText='Continue'
        cancelText='Keep account'
        title='Delete account'
        onConfirm={onDeleteAccount}
        onClose={handleClose}
        open={isOtpConfirmationDialogOpen}
      />

      {context && (
        <VerifyOtpDialog
          context={context}
          onVerifyOtp={(otp) => onConfirmDeleteAccount(otp)}
          onSendOtp={onDeleteAccount}
          onClose={handleClose}
          isLoading={isSendingOneTimePassword || isDeletingAccount}
          error={oneTimePasswordError}
          open={isOtpSendDialogOpen}
        />
      )}
    </ContentColumn>
  )
}
