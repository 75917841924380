import { Link } from '@mui/material'

import { DOCS_SEALED_KEYS } from '../../../../const'
import { CalloutBox } from '../../index'

type EncryptionKeyCalloutBoxProps = {
  isEncryption: boolean
  hasAtLeastOneActive: boolean
}

export const EncryptionKeyCalloutBox = ({ isEncryption, hasAtLeastOneActive }: EncryptionKeyCalloutBoxProps) => {
  if (!isEncryption) {
    return null // Return null when isEncryption is false
  }

  return hasAtLeastOneActive ? (
    <CalloutBox title='Client-side payloads are encrypted' type='info'>
      Your workspace has an active encryption key, so client-side payloads are now encrypted.{' '}
      <Link href={DOCS_SEALED_KEYS} target='_blank' color='inherit'>
        Learn more
      </Link>
    </CalloutBox>
  ) : (
    <CalloutBox title='Client-side payloads are not encrypted' type='info'>
      To encrypt client-side payloads, select &quot;Activate Key&quot; from the three-dot menu. Payloads will always be
      encrypted when any encryption key is active.{' '}
      <Link href={DOCS_SEALED_KEYS} target='_blank' color='inherit'>
        Learn more
      </Link>
    </CalloutBox>
  )
}
