import { useToast } from '../toast'
import { useRequestMutation } from './base'

export function useSendOneTimePassword() {
  const { showToast } = useToast()

  return useRequestMutation('sendOneTimePassword', {
    onError: (error) => {
      showToast({
        severity: 'error',
        message: error.message ?? 'There was an error sending you the confirmation email. Please retry!',
      })
    },
  })
}
