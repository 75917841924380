import { useDocumentTitle } from '../../hooks'
import { PurchaseLayout } from '../commonUI'
import alvinAvatar from './alvin.jpg'
import { Form } from './components/Form/Form'
import gabeAvatar from './gabe.jpg'

const slackPreviewMessageData = [
  {
    avatarSrc: alvinAvatar,
    title: 'Alvin',
    time: '9:41 AM',
    message: 'We need to modernize our enterprise request form.',
  },
  {
    avatarSrc: gabeAvatar,
    title: 'Gabe',
    time: '9:43 AM',
    message: "Agreed 💯 let's make a form that messages Slack.",
  },
]

export const EnterpriseContactSalesPage = () => {
  useDocumentTitle('Contact Sales')

  return (
    <PurchaseLayout>
      <Form slackPreviewMessages={slackPreviewMessageData} />
    </PurchaseLayout>
  )
}
