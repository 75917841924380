import { useQuery, useQueryClient } from '@tanstack/react-query'
import { BillingInfo } from 'models'

import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

export function useBillingInfo() {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['billingInfo'] as const,
    queryFn: () => extractData(withErrorHandling(buildRequest('billingInfoGet'))),
  })
}

export function useBillingInfoUpdateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('billingInfoUpdate', {
    onSuccess: (updatedInfo) => {
      queryClient.setQueryData<BillingInfo>(['billingInfo'], updatedInfo)
    },
  })
}
