import {
  Badge,
  SelectItem,
  SelectListBox,
  SelectPopover,
  SelectSection,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
  Stack,
} from '@compass/components'
import { AppRoute, buildRoute } from 'appRoutes'
import { RegionFlag } from 'components'
import { SUBSCRIPTION_DISPLAY_PLANS, WithSubscriptionId } from 'const'
import { useCurrentSubscription } from 'features/subscription'
import { replaceSubscriptionInPath } from 'helpers/path'
import { getSubscriptionDisplayPlan } from 'helpers/subscription'
import { usePermissions } from 'hooks/permissions'
import { HardDrive, Plus } from 'lucide-react'
import { ExpandedSubscription, RegionCode, Subscription, SubscriptionStatus } from 'models'
import { useMemo } from 'react'
import { Select } from 'react-aria-components'
import { matchPath, useHistory } from 'react-router-dom'

export interface SubscriptionSelectProps {
  subscriptions?: Subscription[]
  isLoading: boolean
  onShouldClose?: () => void
}

export function SubscriptionSelect({ subscriptions, onShouldClose }: SubscriptionSelectProps) {
  const history = useHistory()
  const { currentSubscriptionId: selectedId } = useCurrentSubscription()

  const selectedWorkspace = useMemo(
    () => subscriptions?.find((subscription) => subscription.id === selectedId),
    [selectedId, subscriptions]
  )
  const activeWorkspaces = useMemo(
    () => subscriptions?.filter(({ status }) => status !== SubscriptionStatus.Canceled) ?? [],
    [subscriptions]
  )
  const canceledWorkspaces = useMemo(
    () => subscriptions?.filter(({ status }) => status === SubscriptionStatus.Canceled) ?? [],
    [subscriptions]
  )
  const { isReadOnly, subscriptionPermissions } = usePermissions()

  const handleSubscriptionClick = (id: string) => {
    if (id === 'new') {
      history.push(buildRoute(AppRoute.AddSecondaryApplication))
      onShouldClose?.()
      return
    }

    // Custom redirects based on the current path.
    if (matchPath(history.location.pathname, { path: AppRoute.IdentificationEventDetails })) {
      history.push(buildRoute(AppRoute.IdentificationEvents, { subscriptionId: id }))
      return
    }
    if (matchPath(history.location.pathname, { path: AppRoute.WebhookEvents })) {
      history.push(buildRoute(AppRoute.Webhooks, { subscriptionId: id }))
      return
    }

    const routeMatch = matchPath<WithSubscriptionId>(history.location.pathname, {
      path: AppRoute.SubscriptionRoot,
    })
    const isSubscriptionRoute = routeMatch && routeMatch.params.subscriptionId

    if (isSubscriptionRoute) {
      history.push(replaceSubscriptionInPath(history.location, id))
    } else {
      history.push(buildRoute(AppRoute.SubscriptionOverview, { subscriptionId: id }))
    }
  }

  return (
    <div className='w-full min-w-0'>
      <Select<Subscription>
        aria-label='Workspace selector'
        placeholder='Select a workspace'
        selectedKey={selectedWorkspace?.id}
        onSelectionChange={(key: string) => handleSubscriptionClick(key)}
      >
        <SelectTrigger
          className='border-none shadow-none h-11'
          tone='background2'
          fullWidth
          prefix={
            selectedWorkspace ? (
              <RegionFlag regionCode={selectedWorkspace?.regionCode ?? RegionCode.Use1} className='!w-4 !h-4' />
            ) : (
              <HardDrive size={16} />
            )
          }
        >
          <SelectValue<Subscription> className='flex flex-col text-left'>
            {({ selectedText, selectedItem, isPlaceholder }) => {
              const plan = selectedItem
                ? SUBSCRIPTION_DISPLAY_PLANS[getSubscriptionDisplayPlan(selectedItem as ExpandedSubscription)]
                : null

              return isPlaceholder ? (
                <>Loading workspaces&hellip;</>
              ) : (
                <>
                  <Stack gap={1.5}>
                    <span className='truncate'>{selectedText}</span>{' '}
                    {selectedItem?.status === SubscriptionStatus.Canceled ? (
                      <Badge tone='danger'>Canceled</Badge>
                    ) : selectedItem?.status === SubscriptionStatus.Trialing ? (
                      <Badge tone='warning'>Trial</Badge>
                    ) : null}
                  </Stack>
                  {plan && <span className='text-2xs text-gray-900 font-normal truncate'>{plan.name}</span>}
                </>
              )
            }}
          </SelectValue>
        </SelectTrigger>
        <SelectPopover>
          <SelectListBox>
            {activeWorkspaces.length > 0 ? (
              canceledWorkspaces.length > 0 ? (
                <SelectSection title='Active workspaces'>
                  {activeWorkspaces.map((w) => (
                    <SelectItem key={w.id} id={w.id} value={w}>
                      {w.name}
                    </SelectItem>
                  ))}
                </SelectSection>
              ) : (
                activeWorkspaces.map((w) => (
                  <SelectItem key={w.id} id={w.id} value={w}>
                    {w.name}
                  </SelectItem>
                ))
              )
            ) : null}

            {canceledWorkspaces.length > 0 ? (
              <SelectSection title='Canceled workspaces'>
                {canceledWorkspaces.map((w) => (
                  <SelectItem key={w.id} id={w.id} value={w}>
                    {w.name}
                  </SelectItem>
                ))}
              </SelectSection>
            ) : null}

            {!isReadOnly && subscriptionPermissions.canCreate(subscriptions) && (
              <>
                <SelectSeparator />
                <SelectSection>
                  <SelectItem key='new' id='new' prefix={<Plus size={16} />} data-testid='new-workspace'>
                    New workspace
                  </SelectItem>
                </SelectSection>
              </>
            )}
          </SelectListBox>
        </SelectPopover>
      </Select>
    </div>
  )
}
