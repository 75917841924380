import { DateRangeContextProvider } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useDocumentTitle, useSubscription } from 'hooks'
import { useCurrentUser } from 'hooks/api/users'
import { Settings } from 'luxon'
import { useEffect, useState } from 'react'

import SubscriptionOverview from './components/SubscriptionOverview/SubscriptionOverview'

export function SubscriptionOverviewPage() {
  const { currentSubscriptionId } = useCurrentSubscription()
  const [tzReady, setTzReady] = useState(false)
  // Prefetch the current user for timezone.
  const { isLoading, data: currentUser } = useCurrentUser()
  useEffect(() => {
    if (currentUser?.timezone && !isLoading) {
      Settings.defaultZone = currentUser.timezone
      setTzReady(true)
    }
  }, [currentUser?.timezone, isLoading])
  const { data: subscription } = useSubscription(currentSubscriptionId)

  useDocumentTitle(`Overview - ${subscription?.name ?? 'Subscription'}`)

  return tzReady ? (
    <DateRangeContextProvider>
      <SubscriptionOverview />
    </DateRangeContextProvider>
  ) : null
}
