import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useToast } from '../toast'
import { useAuth } from '../user'
import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'
import { useUpdateUserContext } from './context'

const QUERY_KEY = ['accountTransfer'] as const

export function useAccountTransferRequest() {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: QUERY_KEY,
    queryFn: () => extractData(withErrorHandling(buildRequest('accountTransferGet'))),
  })
}

export function useAccountTransferIntentMutation() {
  const { showToast } = useToast()

  return useRequestMutation('accountTransferIntent', {
    onError: (error) => {
      showToast({
        severity: 'error',
        message: error.message ?? 'There was an error sending you the confirmation email. Please retry!',
      })
    },
  })
}

export function useAccountTransferMutation() {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useRequestMutation('accountTransferCreate', {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    },
    onError: (error) => {
      if (error?.param !== 'otp') {
        showToast({ severity: 'error', message: error.message ?? 'Something went wrong when transferring ownership' })
      }
    },
  })
}

export function useCancelAccountTransferMutation() {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useRequestMutation('accountTransferDelete', {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    },
    onError: (error) => {
      showToast({
        severity: 'error',
        message: error.message ?? 'Something went wrong when canceling the ownership transfer',
      })
    },
  })
}

export function useAccountTransferConfirmMutation() {
  const queryClient = useQueryClient()
  const { setCredentials } = useAuth()
  const { showToast } = useToast()
  const updateUserContext = useUpdateUserContext()

  return useRequestMutation('accountTransferConfirm', {
    onSuccess: ({ accessToken, refreshToken, context }) => {
      setCredentials({ accessToken, refreshToken })
      updateUserContext(context)

      queryClient.invalidateQueries(QUERY_KEY)
      queryClient.invalidateQueries(['users'])
    },
    onError: (error) => {
      showToast({
        severity: 'error',
        message: error.message ?? 'Something went wrong when confirming the ownership transfer',
      })
    },
  })
}
