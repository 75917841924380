import styles from './AuthForm.module.scss'

export type Props = {
  onSubmit(data: React.FormEvent<HTMLFormElement>): unknown
  children: React.ReactNode
}

export function AuthForm({ onSubmit, children }: Props) {
  return (
    <form className={styles.container} onSubmit={onSubmit}>
      {children}
    </form>
  )
}
