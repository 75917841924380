import { useQuery, useQueryClient } from '@tanstack/react-query'
import { GenericError } from 'const'
import { CustomEventGTM, sendCustomEventGTM } from 'helpers/vendor'
import {
  ExpandedSubscription,
  OAuthProvider,
  OAuthProviderHandlerMap,
  OAuthProviderLinkMap,
  ReadmeLogin,
  SignupType,
  SsoAuthModel,
} from 'models'
import { ampli } from 'models/ampli'
import { useMemo } from 'react'

import { isValidEmail } from '../../helpers/validation'
import { useAuth } from '../user'
import { useResendConfirmationTimer } from '../userStorage'
import { extractData, useBuildRequest, useRequestMutation } from './base'
import { useUpdateUserContext } from './context'

export function useLogInMutation() {
  const queryClient = useQueryClient()
  const updateUserContext = useUpdateUserContext()

  return useRequestMutation('logIn', {
    onSuccess: ({ context, subscriptions }) => {
      updateUserContext(context)
      queryClient.setQueryData<ExpandedSubscription[]>(['subscriptions'], subscriptions)
    },
  })
}

export function useLogOutMutation() {
  return useRequestMutation('logOut')
}

export function useExchangeRefreshTokenMutation() {
  return useRequestMutation('exchangeRefreshToken')
}

export function useSsoStatusCheck(email: string, state?: SsoAuthModel) {
  const buildRequest = useBuildRequest()

  return useQuery({
    queryKey: ['sso', email, state] as const,
    queryFn: () =>
      extractData(
        buildRequest('ssoAuth', {
          data: {
            email,
            state: state ? encodeURI(window.btoa(JSON.stringify(state))) : undefined,
          },
        })
      ),
    enabled: isValidEmail(email),
    // Cache and stale times set to 10 minutes to prevent server overload when users navigate between signup and login pages.
    cacheTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: true,
  })
}

export function useOAuthLinks() {
  const buildRequest = useBuildRequest()

  return useQuery({
    queryKey: ['oauth'] as const,
    queryFn: () => extractData(buildRequest('oAuth')),
    // Cache and stale times set to 10 minutes to prevent server overload when users navigate between signup and login pages.
    cacheTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
}

export const defaultOAuthLinks: OAuthProviderLinkMap = {
  [OAuthProvider.Google]: '',
  [OAuthProvider.GitHub]: '',
  [OAuthProvider.Microsoft]: '',
}

export function useOAuth() {
  const { data: oAuthLinks = defaultOAuthLinks, isLoading, error } = useOAuthLinks()

  return useMemo(() => {
    const oAuthProviderMap = {} as OAuthProviderHandlerMap

    if (oAuthLinks) {
      Object.entries(oAuthLinks).forEach(([provider, link]) => {
        oAuthProviderMap[provider as OAuthProvider] = () => {
          window.location.assign(link)
        }
      })
    }

    return { data: oAuthProviderMap, isLoading, error }
  }, [oAuthLinks, isLoading, error])
}

export function useSsoLogInMutation() {
  const queryClient = useQueryClient()
  const updateUserContext = useUpdateUserContext()

  return useRequestMutation('ssoCallback', {
    onSuccess: ({ context, subscriptions }) => {
      updateUserContext(context)
      queryClient.setQueryData<ExpandedSubscription[]>(['subscriptions'], subscriptions)
    },
  })
}

export function useSignUpMutation(botDetected: string, suspectScore: string) {
  const { setCredentials } = useAuth()
  const updateUserContext = useUpdateUserContext()

  return useRequestMutation('signupIntentCreate', {
    onSuccess: ({ accessToken, refreshToken, context }) => {
      setCredentials({ accessToken, refreshToken })
      updateUserContext(context)
      sendCustomEventGTM(CustomEventGTM.AccountSignUp)
      ampli.createAccount({ signupType: SignupType.Password, botDetected, suspectScore })
    },
  })
}

export function useSignUpConfirmMutation() {
  const { setCredentials } = useAuth()
  const updateUserContext = useUpdateUserContext()

  return useRequestMutation('signupIntentConfirm', {
    onSuccess: ({ accessToken, refreshToken, context }) => {
      setCredentials({ accessToken, refreshToken })
      updateUserContext(context)
    },
  })
}

export function useSignUpConfirmResend() {
  const { markLastSentNow } = useResendConfirmationTimer()

  return useRequestMutation('signupConfirmResend', {
    onSuccess: () => {
      markLastSentNow()
    },
    errorHandling: { forceToast: true },
  })
}

export function usePasswordResetCreateMutation() {
  return useRequestMutation('passwordResetCreate')
}

export function usePasswordResetGetMutation() {
  return useRequestMutation('passwordResetGet')
}

export function usePasswordResetConfirmMutation() {
  return useRequestMutation('passwordResetConfirm')
}

export function usePasswordUpdateMutation() {
  const { setCredentials } = useAuth()

  return useRequestMutation('passwordUpdate', {
    onSuccess: ({ accessToken, refreshToken }) => {
      setCredentials({ accessToken, refreshToken })
    },
  })
}

export function useReadmeLogin() {
  const buildRequest = useBuildRequest()
  return useQuery<ReadmeLogin, GenericError>({
    queryKey: ['readmeLogin'] as const,
    queryFn: () => extractData(buildRequest('readmeLogin')),
  })
}

export function usePasswordStrength(password: string) {
  const buildRequest = useBuildRequest()

  return useQuery({
    queryKey: ['passwordStrength', password] as const,
    queryFn: () => extractData(buildRequest('passwordStrength', { data: { password } })),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
    enabled: password !== '',
  })
}
