import { Chip, Tooltip } from '@mui/material'
import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'

import styles from './Tag.module.scss'

export type TagColor = 'yellow' | 'blue' | 'red' | 'green' | 'gray' | 'darkGray' | 'black'

export interface TagProps {
  label?: string
  tooltip?: ReactNode
  pointer?: boolean
  color?: TagColor
  compact?: boolean
  icon?: ReactElement
  className?: string
  bold?: boolean
  round?: boolean
}

export default function Tag({ label, tooltip, pointer, color, compact, bold, round, icon, className }: TagProps) {
  const colorClass = colorClasses[color ?? 'gray']

  const chip = (
    <Chip
      label={label}
      avatar={icon}
      className={clsx(
        styles.tag,
        colorClass,
        {
          [styles.compact]: compact,
          [styles.bold]: bold,
          [styles.round]: round,
          [styles.pointer]: !!tooltip || pointer,
        },
        className
      )}
      classes={{ root: styles.chip, label: styles.label, avatar: styles.avatar }}
    />
  )

  return tooltip ? (
    <Tooltip title={tooltip} arrow placement='top'>
      {chip}
    </Tooltip>
  ) : (
    chip
  )
}

const colorClasses: Record<TagColor, string> = {
  yellow: styles.yellow,
  blue: styles.blue,
  red: styles.red,
  green: styles.green,
  gray: styles.gray,
  darkGray: styles.darkGray,
  black: styles.black,
}
