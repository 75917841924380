import { InfoOutlined } from '@mui/icons-material'
import { Skeleton, Tooltip, Typography } from '@mui/material'
import clsx from 'clsx'
import { ReactNode } from 'react'

import { CopyButton } from '../../../../components/CopyButton/CopyButton'
import styles from './IntegrationKeyValueList.module.scss'

export interface IntegrationKeyValueListItem {
  key: string
  value: string | ReactNode
  monospace?: boolean
  valueClassName?: string
  isCopyable?: boolean
  info?: ReactNode
  badge?: ReactNode
}

export interface IntegrationKeyValueListProps {
  items: Array<IntegrationKeyValueListItem>
  isLoading?: boolean
  className?: string
  itemClassName?: string
  direction?: 'row' | 'column'
  testId?: string
}

export default function IntegrationKeyValueList({
  items,
  isLoading,
  className,
  itemClassName,
  direction = 'column',
  testId,
}: IntegrationKeyValueListProps) {
  const minWidth =
    direction === 'column' ? Math.max(...items.map(({ key, info }) => key.length * 8 + (info ? 20 : 0))) : 0

  return (
    <div
      data-testid={testId ?? 'key-value-list'}
      className={clsx(
        styles.list,
        className,
        { [styles.listRow]: direction === 'row' },
        { [styles.listColumn]: direction === 'column' }
      )}
    >
      {items.map(({ key, value, monospace, valueClassName, isCopyable, info, badge }) => (
        <li key={key} className={clsx(styles.keyValue, itemClassName, { [styles.keyValueRow]: direction === 'row' })}>
          <Typography component='span' variant='bodyS' className={styles.key} style={{ minWidth }}>
            {key}

            {info && (
              <Tooltip placement='top' title={info}>
                <InfoOutlined className={styles.info} color='inherit' fontSize='inherit' />
              </Tooltip>
            )}
          </Typography>

          <div
            className={clsx(
              styles.valueContainer,
              { [styles.columnValue]: direction === 'column' },
              { [styles.rowValue]: direction === 'row' }
            )}
          >
            {typeof value === 'string' || isLoading ? (
              <Typography
                component='span'
                variant='bodyMMedium'
                className={clsx(styles.value, valueClassName, { [styles.monospace]: monospace })}
              >
                {isLoading ? <Skeleton width={128} data-testid='key-value-list-skeleton' /> : value}
              </Typography>
            ) : (
              value
            )}
            {isCopyable && !isLoading && <CopyButton className={styles.copyButton} text={value as string} />}
          </div>
          {badge && <div>{badge}</div>}
        </li>
      ))}
    </div>
  )
}
