import { Button } from '@compass/components'
import { Link, Popover, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import DateRangePicker from 'components/DateRangePicker/DateRangePicker'
import { formatDatetime, toCustomDateRange, useDateRangeContext } from 'features/commonUI'
import { useCurrentSubscriptionData } from 'features/subscription'
import { CustomDateRange, isPredefinedRange, PredefinedRange } from 'helpers/dateRange'
import { formatTimezone } from 'helpers/format'
import { getLocale } from 'helpers/locale'
import { CalendarIcon } from 'lucide-react'
import { DateTime, Duration } from 'luxon'
import { useEffect, useMemo, useRef, useState } from 'react'

import styles from './DefaultDateRangePicker.module.scss'

export interface DefaultDateRangePickerProps {
  initialDateRange?: PredefinedRange | CustomDateRange
  initialMinDate?: DateTime
  initialMaxDate?: DateTime
  definedRanges?: PredefinedRange[]
  className?: string
  maxRange?: Duration
  onOpen?: () => void
  onApplyRange?: (range: CustomDateRange | PredefinedRange) => void
  rangeInterpreter?: (range?: CustomDateRange | PredefinedRange) => Partial<CustomDateRange | PredefinedRange>
  timezone?: string
}

export function DefaultDateRangePicker({
  initialDateRange,
  initialMinDate,
  initialMaxDate,
  definedRanges,
  className,
  maxRange,
  onOpen,
  onApplyRange,
  rangeInterpreter,
  timezone,
}: DefaultDateRangePickerProps) {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { dateRange, setDateRange, today } = useDateRangeContext()
  const { subscription } = useCurrentSubscriptionData()

  useEffect(() => {
    if (initialDateRange && initialDateRange !== dateRange) {
      setDateRange?.(initialDateRange)
    }
  }, [setDateRange, initialDateRange, dateRange])

  const defaultDateRange = useMemo(() => {
    const optionalDates = [today.minus({ hours: 7 * 24 })]
    if (initialMinDate) {
      optionalDates.push(initialMinDate)
    }
    return toCustomDateRange({ startDate: DateTime.max(...optionalDates), endDate: today })
  }, [initialMinDate, today])

  const defaultInitialMinDate = useMemo(() => {
    const availableDates = [today.minus({ year: 1 })]
    if (subscription) {
      availableDates.push(DateTime.fromISO(subscription.createdAt))
    }

    return DateTime.max(...availableDates)
  }, [subscription, today])

  if (!initialMaxDate) {
    initialMaxDate = today
  }

  if (!initialMinDate) {
    initialMinDate = defaultInitialMinDate
  }

  const handleDateRangeChange = (range: CustomDateRange) => {
    onApplyRange?.(range)
  }

  const handleOpen = () => {
    setAnchorEl(buttonRef.current)
    onOpen?.()
  }

  const handleClose = () => {
    setAnchorEl(null)
    if (!dateRange?.startDate || !dateRange?.endDate) {
      return
    }

    onApplyRange?.(dateRange)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const interpreted = rangeInterpreter != null && dateRange != null ? rangeInterpreter(dateRange) : undefined

  return (
    <>
      <Button
        ref={buttonRef}
        aria-describedby={id}
        variant='secondary'
        onPress={handleOpen}
        data-testid='date-range-picker-button'
        className={className}
      >
        <CalendarIcon />
        {dateRange
          ? isPredefinedRange(dateRange)
            ? dateRange.label
            : `${dateRange.startDate?.setLocale(getLocale()).toLocaleString({
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })} - ${dateRange.endDate?.setLocale(getLocale()).toLocaleString({
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}`
          : 'Choose date range'}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {today && (
          <DateRangePicker
            onChange={handleDateRangeChange}
            definedRanges={definedRanges}
            initialDateRange={dateRange ?? defaultDateRange}
            initialMaxDate={initialMaxDate}
            initialMinDate={initialMinDate}
            maxRange={maxRange}
            today={today}
          />
        )}
        <div className={styles.actions}>
          {interpreted ? (
            <div className={styles.interpreted}>
              <Typography variant='body3' color='#0000008a'>
                {interpreted.startDate && formatDatetime(interpreted.startDate.toJSDate(), undefined, timezone)} &ndash;{' '}
                {interpreted.endDate && formatDatetime(interpreted.endDate.toJSDate(), undefined, timezone)}
              </Typography>{' '}
              {timezone ? (
                <Typography variant='body3'>
                  <Link href={AppRoute.ProfileSettings} underline='hover'>
                    {formatTimezone(timezone)}
                  </Link>
                </Typography>
              ) : null}
            </div>
          ) : null}
          <Button variant='primary' onPress={handleClose}>
            Apply
          </Button>
        </div>
      </Popover>
    </>
  )
}
