export const STRIPE_TAX_ID_TYPES = [
  { taxIdType: 'ad_nrt', country: 'Andorra', description: 'Andorran NRT number', exampleValue: 'A-123456-Z' },
  {
    taxIdType: 'ar_cuit',
    country: 'Argentina',
    description: 'Argentinian tax ID number',
    exampleValue: '12-3456789-01',
  },
  {
    taxIdType: 'au_abn',
    country: 'Australia',
    description: 'Australian Business Number (AU ABN)',
    exampleValue: '12345678912',
  },
  {
    taxIdType: 'au_arn',
    country: 'Australia',
    description: 'Australian Taxation Office Reference Number',
    exampleValue: '123456789123',
  },
  { taxIdType: 'eu_vat', country: 'Austria', description: 'European VAT number', exampleValue: 'ATU12345678' },
  { taxIdType: 'eu_vat', country: 'Belgium', description: 'European VAT number', exampleValue: 'BE0123456789' },
  { taxIdType: 'bo_tin', country: 'Bolivia', description: 'Bolivian tax ID', exampleValue: '123456789' },
  { taxIdType: 'br_cnpj', country: 'Brazil', description: 'Brazilian CNPJ number', exampleValue: '01.234.456/5432-10' },
  { taxIdType: 'br_cpf', country: 'Brazil', description: 'Brazilian CPF number', exampleValue: '123.456.789-87' },
  {
    taxIdType: 'bg_uic',
    country: 'Bulgaria',
    description: 'Bulgaria Unified Identification Code',
    exampleValue: '123456789',
  },
  { taxIdType: 'eu_vat', country: 'Bulgaria', description: 'European VAT number', exampleValue: 'BG0123456789' },
  { taxIdType: 'ca_bn', country: 'Canada', description: 'Canadian BN', exampleValue: '123456789' },
  {
    taxIdType: 'ca_gst_hst',
    country: 'Canada',
    description: 'Canadian GST/HST number',
    exampleValue: '123456789RT0002',
  },
  {
    taxIdType: 'ca_pst_bc',
    country: 'Canada',
    description: 'Canadian PST number (British Columbia)',
    exampleValue: 'PST-1234-5678',
  },
  {
    taxIdType: 'ca_pst_mb',
    country: 'Canada',
    description: 'Canadian PST number (Manitoba)',
    exampleValue: '123456-7',
  },
  {
    taxIdType: 'ca_pst_sk',
    country: 'Canada',
    description: 'Canadian PST number (Saskatchewan)',
    exampleValue: '1234567',
  },
  {
    taxIdType: 'ca_qst',
    country: 'Canada',
    description: 'Canadian QST number (Québec)',
    exampleValue: '1234567890TQ1234',
  },
  { taxIdType: 'cl_tin', country: 'Chile', description: 'Chilean TIN', exampleValue: '12.345.678-K' },
  { taxIdType: 'cn_tin', country: 'China', description: 'Chinese tax ID', exampleValue: '123456789012345678' },
  { taxIdType: 'co_nit', country: 'Colombia', description: 'Colombian NIT number', exampleValue: '123.456.789-0' },
  { taxIdType: 'cr_tin', country: 'Costa Rica', description: 'Costa Rican tax ID', exampleValue: '1-234-567890' },
  { taxIdType: 'eu_vat', country: 'Croatia', description: 'European VAT number', exampleValue: 'HR12345678912' },
  { taxIdType: 'eu_vat', country: 'Cyprus', description: 'European VAT number', exampleValue: 'CY12345678Z' },
  { taxIdType: 'eu_vat', country: 'Czech Republic', description: 'European VAT number', exampleValue: 'CZ1234567890' },
  { taxIdType: 'eu_vat', country: 'Denmark', description: 'European VAT number', exampleValue: 'DK12345678' },
  {
    taxIdType: 'do_rcn',
    country: 'Dominican Republic',
    description: 'Dominican RCN number',
    exampleValue: '123-4567890-1',
  },
  { taxIdType: 'ec_ruc', country: 'Ecuador', description: 'Ecuadorian RUC number', exampleValue: '1234567890001' },
  {
    taxIdType: 'eg_tin',
    country: 'Egypt',
    description: 'Egyptian Tax Identification Number',
    exampleValue: '123456789',
  },
  {
    taxIdType: 'sv_nit',
    country: 'El Salvador',
    description: 'El Salvadorian NIT number',
    exampleValue: '1234-567890-123-4',
  },
  { taxIdType: 'eu_vat', country: 'Estonia', description: 'European VAT number', exampleValue: 'EE123456789' },
  {
    taxIdType: 'eu_oss_vat',
    country: 'EU',
    description: 'European One Stop Shop VAT number for non-Union scheme',
    exampleValue: 'EU123456789',
  },
  { taxIdType: 'eu_vat', country: 'Finland', description: 'European VAT number', exampleValue: 'FI12345678' },
  { taxIdType: 'eu_vat', country: 'France', description: 'European VAT number', exampleValue: 'FRAB123456789' },
  { taxIdType: 'ge_vat', country: 'Georgia', description: 'Georgian VAT', exampleValue: '123456789' },
  { taxIdType: 'eu_vat', country: 'Germany', description: 'European VAT number', exampleValue: 'DE123456789' },
  { taxIdType: 'eu_vat', country: 'Greece', description: 'European VAT number', exampleValue: 'EL123456789' },
  { taxIdType: 'hk_br', country: 'Hong Kong', description: 'Hong Kong BR number', exampleValue: '12345678' },
  { taxIdType: 'eu_vat', country: 'Hungary', description: 'European VAT number', exampleValue: 'HU12345678' },
  {
    taxIdType: 'hu_tin',
    country: 'Hungary',
    description: 'Hungary tax number (adószám)',
    exampleValue: '12345678-1-23',
  },
  { taxIdType: 'is_vat', country: 'Iceland', description: 'Icelandic VAT', exampleValue: '123456' },
  { taxIdType: 'in_gst', country: 'India', description: 'Indian GST number', exampleValue: '12ABCDE3456FGZH' },
  {
    taxIdType: 'id_npwp',
    country: 'Indonesia',
    description: 'Indonesian NPWP number',
    exampleValue: '12.345.678.9-012.345',
  },
  { taxIdType: 'eu_vat', country: 'Ireland', description: 'European VAT number', exampleValue: 'IE1234567AB' },
  { taxIdType: 'il_vat', country: 'Israel', description: 'Israel VAT', exampleValue: '000012345' },
  { taxIdType: 'eu_vat', country: 'Italy', description: 'European VAT number', exampleValue: 'IT12345678912' },
  {
    taxIdType: 'jp_cn',
    country: 'Japan',
    description: 'Japanese Corporate Number (*Hōjin Bangō*)',
    exampleValue: '1234567891234',
  },
  {
    taxIdType: 'jp_rn',
    country: 'Japan',
    description:
      "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
    exampleValue: '12345',
  },
  {
    taxIdType: 'jp_trn',
    country: 'Japan',
    description: 'Japanese Tax Registration Number (*Tōroku Bangō*)',
    exampleValue: 'T1234567891234',
  },
  {
    taxIdType: 'ke_pin',
    country: 'Kenya',
    description: 'Kenya Revenue Authority Personal Identification Number',
    exampleValue: 'P000111111A',
  },
  { taxIdType: 'eu_vat', country: 'Latvia', description: 'European VAT number', exampleValue: 'LV12345678912' },
  {
    taxIdType: 'li_uid',
    country: 'Liechtenstein',
    description: 'Liechtensteinian UID number',
    exampleValue: 'CHE123456789',
  },
  { taxIdType: 'eu_vat', country: 'Lithuania', description: 'European VAT number', exampleValue: 'LT123456789123' },
  { taxIdType: 'eu_vat', country: 'Luxembourg', description: 'European VAT number', exampleValue: 'LU12345678' },
  { taxIdType: 'my_frp', country: 'Malaysia', description: 'Malaysian FRP number', exampleValue: '12345678' },
  { taxIdType: 'my_itn', country: 'Malaysia', description: 'Malaysian ITN', exampleValue: 'C 1234567890' },
  { taxIdType: 'my_sst', country: 'Malaysia', description: 'Malaysian SST number', exampleValue: 'A12-3456-78912345' },
  { taxIdType: 'eu_vat', country: 'Malta', description: 'European VAT number', exampleValue: 'MT12345678' },
  { taxIdType: 'mx_rfc', country: 'Mexico', description: 'Mexican RFC number', exampleValue: 'ABC010203AB9' },
  { taxIdType: 'eu_vat', country: 'Netherlands', description: 'European VAT number', exampleValue: 'NL123456789B12' },
  { taxIdType: 'nz_gst', country: 'New Zealand', description: 'New Zealand GST number', exampleValue: '123456789' },
  { taxIdType: 'no_vat', country: 'Norway', description: 'Norwegian VAT number', exampleValue: '123456789MVA' },
  { taxIdType: 'pe_ruc', country: 'Peru', description: 'Peruvian RUC number', exampleValue: '12345678901' },
  {
    taxIdType: 'ph_tin',
    country: 'Philippines',
    description: 'Philippines Tax Identification Number',
    exampleValue: '123456789012',
  },
  { taxIdType: 'eu_vat', country: 'Poland', description: 'European VAT number', exampleValue: 'PL1234567890' },
  { taxIdType: 'eu_vat', country: 'Portugal', description: 'European VAT number', exampleValue: 'PT123456789' },
  { taxIdType: 'eu_vat', country: 'Romania', description: 'European VAT number', exampleValue: 'RO1234567891' },
  { taxIdType: 'ro_tin', country: 'Romania', description: 'Romanian tax ID number', exampleValue: '1234567890123' },
  { taxIdType: 'ru_inn', country: 'Russia', description: 'Russian INN', exampleValue: '1234567891' },
  { taxIdType: 'ru_kpp', country: 'Russia', description: 'Russian KPP', exampleValue: '123456789' },
  { taxIdType: 'sa_vat', country: 'Saudi Arabia', description: 'Saudi Arabia VAT', exampleValue: '123456789012345' },
  { taxIdType: 'rs_pib', country: 'Serbia', description: 'Serbian PIB number', exampleValue: '12-3456789-01' },
  { taxIdType: 'sg_gst', country: 'Singapore', description: 'Singaporean GST', exampleValue: 'M12345678X' },
  { taxIdType: 'sg_uen', country: 'Singapore', description: 'Singaporean UEN', exampleValue: '123456789F' },
  { taxIdType: 'eu_vat', country: 'Slovakia', description: 'European VAT number', exampleValue: 'SK1234567891' },
  { taxIdType: 'eu_vat', country: 'Slovenia', description: 'European VAT number', exampleValue: 'SI12345678' },
  {
    taxIdType: 'si_tin',
    country: 'Slovenia',
    description: 'Slovenia tax number (davčna številka)',
    exampleValue: '12345678',
  },
  { taxIdType: 'za_vat', country: 'South Africa', description: 'South African VAT number', exampleValue: '4123456789' },
  { taxIdType: 'kr_brn', country: 'South Korea', description: 'Korean BRN', exampleValue: '123-45-67890' },
  {
    taxIdType: 'es_cif',
    country: 'Spain',
    description: 'Spanish NIF number (previously Spanish CIF number)',
    exampleValue: 'A12345678',
  },
  { taxIdType: 'eu_vat', country: 'Spain', description: 'European VAT number', exampleValue: 'ESA1234567Z' },
  { taxIdType: 'eu_vat', country: 'Sweden', description: 'European VAT number', exampleValue: 'SE123456789123' },
  {
    taxIdType: 'ch_vat',
    country: 'Switzerland',
    description: 'Switzerland VAT number',
    exampleValue: 'CHE-123.456.789 MWST',
  },
  { taxIdType: 'tw_vat', country: 'Taiwan', description: 'Taiwanese VAT', exampleValue: '12345678' },
  { taxIdType: 'th_vat', country: 'Thailand', description: 'Thai VAT', exampleValue: '1234567891234' },
  {
    taxIdType: 'tr_tin',
    country: 'Turkey',
    description: 'Turkish Tax Identification Number',
    exampleValue: '0123456789',
  },
  { taxIdType: 'ua_vat', country: 'Ukraine', description: 'Ukrainian VAT', exampleValue: '123456789' },
  {
    taxIdType: 'ae_trn',
    country: 'United Arab Emirates',
    description: 'United Arab Emirates TRN',
    exampleValue: '123456789012345',
  },
  {
    taxIdType: 'eu_vat',
    country: 'United Kingdom',
    description: 'Northern Ireland VAT number',
    exampleValue: 'XI123456789',
  },
  {
    taxIdType: 'gb_vat',
    country: 'United Kingdom',
    description: 'United Kingdom VAT number',
    exampleValue: 'GB123456789',
  },
  { taxIdType: 'us_ein', country: 'United States', description: 'United States EIN', exampleValue: '12-3456789' },
  { taxIdType: 'uy_ruc', country: 'Uruguay', description: 'Uruguayan RUC number', exampleValue: '123456789012' },
  { taxIdType: 've_rif', country: 'Venezuela', description: 'Venezuelan RIF number', exampleValue: 'A-12345678-9' },
  { taxIdType: 'vn_tin', country: 'Vietnam', description: 'Vietnamese tax ID number', exampleValue: '1234567890' },
]
