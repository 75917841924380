import { LocationDescriptor } from 'history'
import { PropsWithChildren } from 'react'
import { RouterProvider } from 'react-aria-components'
import { useHistory } from 'react-router'

declare module 'react-aria-components' {
  interface RouterConfig {
    routerOptions: LocationDescriptor
  }
}

export function CustomRouterProvider({ children }: PropsWithChildren) {
  const { push } = useHistory()
  return (
    <RouterProvider
      navigate={(path, options) =>
        options != null ? push(typeof options === 'string' ? options : { ...options, pathname: path }) : push(path)
      }
    >
      {children}
    </RouterProvider>
  )
}
