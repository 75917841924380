import { Button } from '@compass/components'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, Collapse, IconButton, InputLabel, Link, Paper, TextField, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import { SignupType } from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import CreatePasswordInput from '../../../../components/CreatePasswordInput/CreatePasswordInput'
import Logo from '../../../../components/Logo/Logo'
import { APITypesMap, PRIVACY_URL, TERMS_URL, WEBSITE_URL } from '../../../../const'
import { getErrorParams } from '../../../../helpers/data'
import { muiRegister } from '../../../../helpers/reactHookForm'
import { EMAIL_VALIDATION } from '../../../../helpers/validation'
import { trackSignupEmailEntered, useAmplitude } from '../../../../helpers/vendor'
import { useDebounce } from '../../../../hooks/debounce'
import { useIsFirstRender } from '../../../../hooks/firstRender'
import { LogInFormData } from '../LogInForm/LogInForm'
import { Props } from '../types'
import GitHubBrandLogoSvg from './github-logo.svg'
import GoogleBrandLogoSvg from './google-logo.svg'
import styles from './SignUpForm.module.scss'

export type SignUpFormData = Pick<APITypesMap['signupIntentCreate']['body'], 'name' | 'email' | 'password'>
export type SignUpProps = Props<SignUpFormData> & Required<Pick<Props<LogInFormData>, 'onCheckSso'>>

const globalFormErrorParams = ['visitorId', 'domain']

export function SignUpForm({ isLoading, isLoadingOAuth, error, onSubmit, onCheckSso, oAuthProviders }: SignUpProps) {
  const formMethods = useForm({
    defaultValues: { name: '', email: '', password: '' },
    mode: 'onChange',
  })
  const {
    handleSubmit,
    register,
    watch,
    formState: { isValid, errors, touchedFields },
  } = formMethods
  const email = watch('email')
  const debouncedEmail = useDebounce(email)
  const { search } = useLocation()
  const [emailMethodShown, setEmailMethodShown] = useState(false)

  useEffect(() => {
    onCheckSso(debouncedEmail)
  }, [debouncedEmail, onCheckSso])

  const isFirstRender = useIsFirstRender()
  const isGlobalError = globalFormErrorParams.includes(error?.param ?? '')
  const isBlockingError = error?.param === 'domain'
  const [showAlert, setShowAlert] = useState(false)

  function handleEmailFocus() {
    if (!touchedFields.email) {
      trackSignupEmailEntered()
    }
  }

  useEffect(() => {
    if (isGlobalError) {
      setShowAlert(true)
    }
  }, [isGlobalError])

  const onClickPrivacyPolicy = useCallback(() => {
    ampli.privacyPolicyClicked({ source: 'signup form' })
  }, [])

  const onClickTermsOfService = useCallback(() => {
    ampli.termsOfServiceClicked({ source: 'signup form' })
  }, [])

  const { isClientLoaded } = useAmplitude()

  useEffect(() => {
    // The domainName property is an enum that only accepts production domains, so we cannot use the HOST variable.
    if (isClientLoaded) {
      ampli.signupOptionsShown({ domainName: 'dashboard.fingerprint.com' })
    }
  }, [isClientLoaded])

  const handleSignupStart = (provider: SignupType) => {
    ampli.signupStarted({
      signupType: provider,
    })
  }

  return (
    <div className={styles.wrapper}>
      <Link href={WEBSITE_URL} underline='hover'>
        <Logo fill='#F04405' fillSecondary='#000' short={false} className={styles.logo} />
      </Link>
      <Paper className={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.root} id='signup-form-submit'>
          <FormProvider {...formMethods}>
            {isGlobalError && (
              <Collapse in={showAlert}>
                <Alert
                  className={styles.alert}
                  severity='error'
                  action={
                    error?.param === 'visitorId' ? (
                      <IconButton
                        aria-label='close'
                        color='inherit'
                        size='small'
                        onClick={() => {
                          setShowAlert(false)
                        }}
                      >
                        <CloseIcon fontSize='inherit' />
                      </IconButton>
                    ) : null
                  }
                >
                  {error?.message}
                </Alert>
              </Collapse>
            )}
            <header className={styles.header}>
              <Typography component='h1' className={styles.title}>
                Sign up
              </Typography>
              <div className={styles.subheader}>
                Already have an account?{' '}
                <Link
                  component={RouterLink}
                  to={{ pathname: AppRoute.Login, search }}
                  className={styles.buttonOption}
                  data-testid='layoutLoginButton'
                >
                  Log in
                </Link>
              </div>
            </header>

            <div style={{ display: 'grid', gap: 8 }}>
              {oAuthProviders && (
                <>
                  <Button
                    variant='outline'
                    size='lg'
                    isDisabled={isLoadingOAuth}
                    onPress={() => {
                      handleSignupStart(SignupType.GoogleOAuth)
                      oAuthProviders.GoogleOAuth()
                    }}
                  >
                    <GoogleBrandLogoSvg width={16} />
                    Continue with Google
                  </Button>
                  <Button
                    variant='outline'
                    size='lg'
                    isDisabled={isLoadingOAuth}
                    onPress={() => {
                      handleSignupStart(SignupType.GitHubOAuth)
                      oAuthProviders.GitHubOAuth()
                    }}
                  >
                    <GitHubBrandLogoSvg width={16} />
                    Continue with GitHub
                  </Button>
                </>
              )}

              <div className={styles.separatorLabel}>
                <hr className={styles.separator} />
                <span>OR</span>
              </div>

              {!emailMethodShown && (
                <Button
                  variant='ghost'
                  size='lg'
                  aria-label='Continue with email'
                  onPress={() => setEmailMethodShown(true)}
                  data-testid='showEmailButton'
                  isDisabled={isLoading}
                >
                  {isLoading ? 'Please wait...' : 'Continue with email'}
                </Button>
              )}
              {emailMethodShown && (
                <div style={{ display: 'grid' }}>
                  <InputLabel htmlFor='name'>Full name</InputLabel>
                  <TextField
                    variant='outlined'
                    placeholder='John Doe'
                    id='name'
                    title='Name'
                    inputProps={{ 'aria-label': 'name' }}
                    autoFocus={!isLoading}
                    {...muiRegister(register, 'name', { required: 'Name required.' })}
                    {...getErrorParams('name', errors, error)}
                    className={styles.input}
                    data-testid='fullNameField'
                  />

                  <InputLabel htmlFor='email'>Email address</InputLabel>
                  <TextField
                    variant='outlined'
                    placeholder='name@company.com'
                    id='email'
                    title='Email'
                    inputProps={{ 'aria-label': 'email' }}
                    {...muiRegister(register, 'email', EMAIL_VALIDATION)}
                    {...getErrorParams(['email', 'ip_address|fpjs_visitor_id'], errors, error)}
                    onFocus={handleEmailFocus}
                    className={styles.input}
                    data-testid='companyEmailField'
                  />

                  <CreatePasswordInput error={error} className={styles.input} />

                  <Button
                    variant='outline'
                    size='lg'
                    isDisabled={isFirstRender || !isValid || isBlockingError || isLoading}
                    aria-label='Continue with email'
                    type='submit'
                    data-testid='signupButton'
                    onPress={() => handleSignupStart(SignupType.Password)}
                  >
                    {isLoading ? 'Please wait...' : 'Continue with email'}
                  </Button>
                </div>
              )}
            </div>
          </FormProvider>
        </form>
      </Paper>
      <Typography variant='body2' component='div' className={styles.disclaimer}>
        By creating an account, you agree to our{' '}
        <Link href={PRIVACY_URL} target='_blank' underline='hover' onClick={onClickPrivacyPolicy}>
          Privacy&nbsp;Policy
        </Link>{' '}
        and{' '}
        <Link href={TERMS_URL} target='_blank' underline='hover' onClick={onClickTermsOfService}>
          Terms&nbsp;of&nbsp;Service
        </Link>
        .
      </Typography>
    </div>
  )
}
