import { Button } from '@compass/components'
import CheckIcon from '@mui/icons-material/Check'
import { InputLabel, TextField } from '@mui/material'
import { APITypesMap } from 'const'
import { getErrorParams } from 'helpers/data'
import { muiRegister } from 'helpers/reactHookForm'
import { EMAIL_VALIDATION } from 'helpers/validation'
import { useRouteState } from 'hooks/router'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { AuthForm } from '../AuthForm/AuthForm'
import styles from '../AuthForm/AuthForm.module.scss'
import { AuthWrapper } from '../AuthWrapper/AuthWrapper'
import { CooldownProgress } from '../CooldownProgress/CooldownProgress'
import { Props } from '../types'

export type ResetPasswordFormData = APITypesMap['passwordResetCreate']['body']
export type ResetPasswordProps = { cooldown?: [number, number] }

export function ResetPasswordForm({
  onSubmit,
  error,
  cooldown: [cooldownSeconds, cooldownPercents] = [0, 0],
  isLoading,
}: Props<ResetPasswordFormData> & ResetPasswordProps) {
  const state = useRouteState<{ email?: string }>()
  const [isSentOnce, setIsSentOnce] = useState(false)

  useEffect(() => {
    if (cooldownPercents > 0) {
      setIsSentOnce(true)
    }
  }, [cooldownPercents])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormData>({
    defaultValues: {
      email: state?.email ?? '',
    },
    mode: 'onChange',
  })

  const cooldownContent = (
    <>
      <CooldownProgress value={cooldownPercents} icon={<CheckIcon fontSize='small' />} />
      &nbsp; Email sent. You can resend in {cooldownSeconds}s
    </>
  )
  const buttonText = isSentOnce ? 'Resend email' : 'Continue'

  return (
    <AuthWrapper
      title='Reset password'
      subtitle='Enter your email address. If we have your email on file, you’ll receive instructions on how to reset your password.'
    >
      <AuthForm onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.field}>
          <InputLabel htmlFor='email'>Email address</InputLabel>
          <TextField
            disabled={cooldownPercents > 0}
            id='email'
            type='email'
            placeholder='name@company.com'
            data-testid='emailAddressField'
            {...muiRegister(register, 'email', EMAIL_VALIDATION)}
            variant='outlined'
            autoFocus={!isLoading}
            {...getErrorParams('email', errors, error)}
            fullWidth
          />
        </div>

        <Button
          isDisabled={cooldownPercents > 0}
          className={styles.button}
          data-testid='resetConfirmButton'
          aria-label='Continue'
          size='lg'
          type='submit'
          fullWidth
        >
          {cooldownPercents > 0 ? cooldownContent : buttonText}
        </Button>
      </AuthForm>
    </AuthWrapper>
  )
}
