import SecurityIcon from '@mui/icons-material/Security'
import { Link, Typography } from '@mui/material'
import clsx from 'clsx'
import { BaseReactProps } from 'helpers/types'

import styles from './ProtectedByStripe.module.scss'

export default function ProtectedByStripe({ className }: BaseReactProps) {
  return (
    <Typography className={clsx(styles.container, className)} component='span' variant='body2'>
      <SecurityIcon htmlColor='#38B42D' fontSize='small' className={styles.icon} /> Protected by&nbsp;
      <Link href='https://stripe.com/' className={styles.link} target='_blank' rel='noopener' underline='hover'>
        Stripe
      </Link>
    </Typography>
  )
}
