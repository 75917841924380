import { useLocalStorage } from './localStorage'

export const SAVED_EMAIL_KEY = 'savedEmail'

export function useSavedEmail() {
  const [savedEmail, rememberEmail, forgetEmail] = useLocalStorage(SAVED_EMAIL_KEY, '')

  return {
    savedEmail,
    rememberEmail,
    forgetEmail,
  }
}
