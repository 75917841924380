import { ButtonOrLink } from '@compass/components'
import { Check } from '@mui/icons-material'
import { Step, StepLabel, Stepper as MuiStepper } from '@mui/material'
import clsx from 'clsx'
import { PropsWithChildren, ReactNode, useCallback, useEffect, useRef } from 'react'

import styles from './Stepper.module.scss'

export interface StepperClasses {
  root?: string
  stepper?: string
}

export interface StepData {
  label: string
  endIcon?: React.ReactNode
  icon?: React.ReactNode
}

export interface StepperProps {
  steps: StepData[]
  activeStep: number
  finished?: boolean
  action?: string
  actionTo?: string
  actionToFunction?: () => void
  isExternalLayout?: boolean
  classes?: StepperClasses
  onMoveBack?: () => void
  onAction?: () => void
  additionalActions?: ReactNode
}

export function Stepper({
  steps,
  activeStep,
  finished,
  action,
  actionTo,
  actionToFunction,
  isExternalLayout,
  onMoveBack,
  onAction,
  classes,
  children,
  additionalActions,
}: PropsWithChildren<StepperProps>) {
  const currentStepEl = useRef<HTMLElement>(null)
  const isBidirectional = !!onMoveBack
  const characterSpacing = 12

  useEffect(() => {
    if (currentStepEl.current) {
      // Scroll to the active step
      currentStepEl.current.scrollTop = currentStepEl.current.scrollHeight
    }
  }, [currentStepEl, activeStep, steps])

  const handleAction = useCallback(() => {
    actionToFunction?.()
    onAction?.()
  }, [actionToFunction, onAction])

  return (
    <div className={clsx(styles.root, { [styles.external]: isExternalLayout }, classes?.root)}>
      <div className={styles.header}>
        <MuiStepper
          activeStep={activeStep}
          classes={{ root: clsx(styles.stepper, classes?.stepper) }}
          style={{
            width: `${steps.reduce((accWidth, step) => accWidth + step.label.length * characterSpacing, 0) + 112}px`,
          }}
        >
          {steps.map(({ label, endIcon }, index) => (
            <Step key={label} ref={index === activeStep ? currentStepEl : undefined} className={styles.step}>
              <StepLabel
                StepIconComponent={StepIcon}
                StepIconProps={
                  {
                    step: index + 1,
                    active: index === activeStep,
                    completed: index < activeStep || (activeStep === steps.length - 1 && finished),
                    isBidirectional,
                    stepIcon: steps[index].icon,
                  } as StepIconProps
                }
                onClick={index === activeStep - 1 ? onMoveBack : undefined}
                style={{ cursor: onMoveBack && index === activeStep - 1 ? 'pointer' : undefined }}
                classes={{
                  root: clsx({ [styles.faded]: isBidirectional ? index !== activeStep : index > activeStep }),
                  label: styles.stepText,
                  iconContainer: styles.stepIcon,
                }}
              >
                {label}
                {index === activeStep && endIcon}
              </StepLabel>
            </Step>
          ))}
        </MuiStepper>

        <div className={styles.actions}>
          {additionalActions}
          {action && (actionTo || actionToFunction) ? (
            <ButtonOrLink href={actionTo} onPress={handleAction} variant='outline' className={styles.action}>
              {action}
            </ButtonOrLink>
          ) : null}
        </div>
      </div>

      {children}
    </div>
  )
}

interface StepIconProps {
  step: number
  active: boolean
  completed: boolean
  isBidirectional: boolean
  stepIcon?: React.ReactNode
}

function StepIcon({ step, active, completed, isBidirectional, stepIcon }: StepIconProps) {
  return (
    <span
      className={clsx(styles.stepIcon, {
        [styles.inactive]: !active && !completed,
        [styles.active]: active,
        [styles.completed]: completed,
      })}
    >
      {stepIcon && !completed ? stepIcon : isBidirectional || !completed ? step : <Check />}
    </span>
  )
}
