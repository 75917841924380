import { CheckCircleOutline, ErrorOutline, HighlightOff, NotInterested } from '@mui/icons-material'
import clsx from 'clsx'
import { HealthIndicatorSeverity } from 'models/health'

import styles from './WidgetSeverityIndicator.module.scss'

export interface WidgetSeverityIndicatorProps {
  severity: HealthIndicatorSeverity
}

export function WidgetSeverityIndicator({ severity = HealthIndicatorSeverity.Fine }: WidgetSeverityIndicatorProps) {
  switch (severity) {
    case HealthIndicatorSeverity.NotApplicable:
      return <NotInterested className={styles.badge} />

    case HealthIndicatorSeverity.Fine:
      return <CheckCircleOutline className={clsx(styles.badge, styles.success)} />

    case HealthIndicatorSeverity.High:
      return <HighlightOff className={clsx(styles.badge, styles.danger)} />

    case HealthIndicatorSeverity.Mid:
      return <ErrorOutline className={clsx(styles.badge, styles.warning)} />

    case HealthIndicatorSeverity.Low:
      return <ErrorOutline className={clsx(styles.badge, styles.info)} />
  }
}
