import { Alert, Paper, Skeleton, TableBody, TableContainer, TableHead } from '@mui/material'
import clsx from 'clsx'
import { Table, TableCell, TableRow } from 'components/Table/Table'
import Tag from 'components/Tag/Tag'
import { BaseReactProps } from 'helpers/types'
import { SSLCertificateValidationStatus } from 'models'

import { SupportEmail } from '../../../../components/SupportEmail'
import { WithCopyButton } from '../../../../components/WithCopyButton/WithCopyButton'
import styles from './DNSRecordsTable.module.scss'

export enum DnsRecordType {
  A = 'A',
  CNAME = 'CNAME',
}

export interface DnsRecord {
  domainName: string
  dnsValidationName: string
  dnsValidationValue: string
  type?: DnsRecordType
  status?: SSLCertificateValidationStatus
  onCopy?: () => void
}

export interface DNSRecordsTableProps extends BaseReactProps {
  records?: DnsRecord[]
  type?: 'display' | 'action'
  isInvalidState?: boolean
}

export const DNSRecordsTable = ({
  className,
  records = [],
  type = 'display',
  isInvalidState,
}: DNSRecordsTableProps) => {
  return (
    <div className={clsx(styles.root, className)}>
      <TableContainer className={styles.table} component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ maxWidth: 480 }}>Subdomain</TableCell>
              {type === 'display' && <TableCell style={{ maxWidth: 192 }}>Type</TableCell>}
              <TableCell style={{ maxWidth: 400 }}>Value</TableCell>
              {type === 'action' && <TableCell style={{ maxWidth: 192 }}>Status</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableContent records={records} type={type} isInvalidState={isInvalidState} />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

interface TableContentProps {
  records: DnsRecord[]
  type: 'display' | 'action'
  isInvalidState?: boolean
}

function TableContent({ records, type, isInvalidState }: TableContentProps) {
  if (isInvalidState) {
    return (
      <TableRow>
        <TableCell colSpan={4}>
          <Alert severity='error'>
            Invalid certificate state. This is likely due to a CAA record conflict, please reference our documentation
            here more information. If it is not caused by a CAA record conflict please contact <SupportEmail />
          </Alert>
        </TableCell>
      </TableRow>
    )
  }

  const isEmpty = records.length === 0
  if (isEmpty) {
    return (
      <TableRow>
        <TableCell colSpan={4}>
          <Skeleton height={20} data-testid='table-loading-skeleton' />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {records.map((record) => (
        <TableRow key={`${record.dnsValidationName}_${record.dnsValidationValue}`}>
          <TableCell>
            <Host withCopy={type === 'action'}>{record.dnsValidationName}</Host>
          </TableCell>
          {type === 'display' && <TableCell>{record.type}</TableCell>}
          <TableCell>
            <WithCopyButton text={record.dnsValidationValue} onCopy={record.onCopy}>
              {record.dnsValidationValue}
            </WithCopyButton>
          </TableCell>
          {type === 'action' && (
            <TableCell>
              <ValidationStatus status={record.status} />
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  )
}

interface HostProps {
  children: string
  withCopy?: boolean
}

function Host({ children, withCopy }: HostProps) {
  const splitDomain = children.replace(/\.$/, '').split('.')
  const subdomain = splitDomain.slice(0, -2).join('.')
  const mainDomain = splitDomain.slice(-2).join('.')

  return (
    <span className={styles.host}>
      {withCopy ? (
        <>
          <WithCopyButton text={subdomain}>{subdomain}.</WithCopyButton>
          <span className={styles.domain}>{mainDomain}</span>
        </>
      ) : (
        <>
          {subdomain}.{mainDomain}
        </>
      )}
    </span>
  )
}

const ValidationStatus = ({ status }: { status?: SSLCertificateValidationStatus }) => {
  if (!status) {
    return null
  }

  switch (status) {
    case SSLCertificateValidationStatus.Pending:
      return <Tag label='Pending' color='yellow' />
    case SSLCertificateValidationStatus.Validated:
      return <Tag label='Validated' color='blue' />
    case SSLCertificateValidationStatus.Failed:
      return <Tag label='Failed' color='red' />
  }
}
