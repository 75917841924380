import { Divider, ListItem, Skeleton, Stack, Theme, Typography, useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import KeyValueList from 'components/KeyValueList/KeyValueList'
import { Table, TableBody, TableBodyData, TableCell, TableHead, TableRow } from 'components/Table/Table'
import { SdkRowDetails, UsageLocations } from 'models/sdkHealth'

import { useRandomIntegerBetween } from '../../hooks/useRandomIntegerBetween'
import {
  ResourceIdentifier,
  SdkLastRequest,
  SdkLatestVersion,
  SdkName,
  SdkRequestCount,
  SdkVersion,
} from './rowValueFormatters'
import styles from './SdkHealthRowDetails.module.scss'

export type RowType = 'web' | 'mobile' | 'server'

type SdkHealthRowDetailsProps = {
  row?: SdkRowDetails
  type: RowType
}

export const SdkHealthRowDetails = ({ row, type }: SdkHealthRowDetailsProps) => {
  const items = [
    { key: 'SDK', value: <SdkName value={row?.sdkName} integrationName={row?.sdkName} /> },

    ...(row?.version === '' || row?.version === '0.0.0'
      ? []
      : [
          {
            key: 'Your version',
            value: <SdkVersion value={row?.version} severity={row?.severity} latestVersion={row?.latestVersion} />,
          },
        ]),
    {
      key: 'Last request',
      value: <SdkLastRequest value={row?.lastRequestAt} />,
    },
    {
      key: 'Requests (30 days)',
      value: <SdkRequestCount value={row?.numOfRequests} short />,
    },
    ...(row?.latestVersion === '' || row?.latestVersion === '0.0.0'
      ? []
      : [
          {
            key: 'Latest version',
            value: <SdkLatestVersion value={row?.latestVersion} url={row?.latestVersionUrlChangelog} />,
          },
        ]),
  ]

  return (
    <>
      <Stack gap={2}>
        <Typography variant='caption'>Details</Typography>
        <KeyValueList items={items} />
      </Stack>
      {type === 'server' || row?.usageLocations?.length === 0 ? null : (
        <Stack gap={2}>
          <Typography variant='caption'>Top {type === 'web' ? 'domains' : 'apps'}</Typography>
          <Usage type={type} row={row} />
        </Stack>
      )}
    </>
  )
}

const Usage = ({ row, type }: SdkHealthRowDetailsProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return isMobile ? (
    <UsageList type={type} usages={row?.usageLocations} />
  ) : (
    <UsageTable type={type} usages={row?.usageLocations} />
  )
}

type UsageProps = {
  usages?: UsageLocations[]
  type: RowType
}
const UsageTable = ({ usages, type }: UsageProps) => {
  return (
    <div className={styles.usageContainer}>
      <Table size='small' className={styles.matchingTable}>
        <TableHead>
          <TableRow>
            <TableCell width='55%' className={styles.firstColumn}>
              {type === 'web' ? 'Domain' : 'App'}
            </TableCell>
            <TableCell width='25%'>Requests</TableCell>
            <TableCell width='20%' className={styles.lastColumn}>
              Last request
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody columnCount={3}>
          <TableBodyData>
            {usages == null || usages.length > 0 ? (
              <>
                {usages?.map(({ resource, numOfRequests, lastRequestAt }, index) => (
                  <TableRow key={index}>
                    <TableCell className={styles.firstColumn}>
                      <Typography variant='bodyMMedium'>
                        <ResourceIdentifier value={resource} type={type} />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='bodyM'>
                        <SdkRequestCount value={numOfRequests} short />
                      </Typography>
                    </TableCell>
                    <TableCell className={styles.lastColumn}>
                      <Typography variant='bodyM'>
                        <SdkLastRequest value={lastRequestAt} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
                {usages == null ? (
                  <>
                    <SkeletonRows />
                    <SkeletonRows />
                    <SkeletonRows />
                  </>
                ) : null}
              </>
            ) : null}
          </TableBodyData>
        </TableBody>
      </Table>
    </div>
  )
}

const SkeletonRows = () => {
  const widthA = useRandomIntegerBetween(50, 210)
  const widthB = useRandomIntegerBetween(70, 140)
  const widthC = useRandomIntegerBetween(40, 80)

  return (
    <>
      <TableRow>
        <TableCell>
          <Skeleton width={widthA} />
        </TableCell>
        <TableCell>
          <Skeleton width={widthB} />
        </TableCell>
        <TableCell>
          <Skeleton width={widthC} />
        </TableCell>
      </TableRow>
    </>
  )
}

const skeletonUsages = Array(3).fill(undefined)
const UsageList = ({ usages, type }: UsageProps) => {
  return (
    <Stack divider={<Divider />} className={clsx(styles.usageContainer, styles.mobileList)}>
      {(usages ?? skeletonUsages).map((usage, index) => (
        <ListItem key={index} sx={{ px: 0, py: 2 }}>
          <Stack direction='column' gap={1}>
            <Typography variant='bodyMMedium'>
              <ResourceIdentifier value={usage?.resource} type={type} />
            </Typography>
            <Typography variant='bodyM'>
              <SdkRequestCount value={usage?.numOfRequests} />
            </Typography>
            <Typography variant='bodyM'>
              <SdkLastRequest value={usage?.lastRequestAt} />
            </Typography>
          </Stack>
        </ListItem>
      ))}
    </Stack>
  )
}
