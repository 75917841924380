import { LinkButton } from '@compass/components'
import { Paper, Typography } from '@mui/material'
import { SUPPORT_EMAIL_MAILTO } from 'const'
import { ExternalLayout } from 'features/commonUI'

import { ampli } from '../../../../models/ampli'
import ErrorImage from './error.svg'
import styles from './SignUpConfirm.module.scss'

export function SignUpConfirmError() {
  return (
    <ExternalLayout srcPage='signupConfirm'>
      <Paper className={styles.container}>
        <div className={styles.topImage} style={{ width: '100%' }}>
          <ErrorImage />
        </div>
        <h1 className={styles.title}>An error occurred...</h1>
        <Typography variant='body2' className={styles.description}>
          We encountered an unexpected error. Please contact our support engineers.
        </Typography>
        <LinkButton className={styles.button} href={SUPPORT_EMAIL_MAILTO} onPress={() => ampli.contactSupportClicked()}>
          Contact support
        </LinkButton>
      </Paper>
    </ExternalLayout>
  )
}
