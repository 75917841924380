import { Badge, Button, LinkButton } from '@compass/components'
import { CheckCircleOutlined, Close, Link as LinkIcon } from '@mui/icons-material'
import { Dialog, Fade, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { DISCORD_INVITE_URL } from 'const'
import { DialogActions, DialogContent, DialogTitle, TextFieldWithCounter } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useToast } from 'hooks'
import { useIntegrationRequest } from 'hooks/api/integrations'
import { useCurrentUser } from 'hooks/api/users'
import { ChevronRight } from 'lucide-react'
import { Subscription, SubscriptionType } from 'models'
import { ampli } from 'models/ampli'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { IntegrationCategory, IntegrationMetadata } from '../IntegrationMetadata/integrationMetadata'
import DiscordLogo from './discord-logo.svg'
import FingerprintLogo from './fingerprint-logo.svg'
import styles from './IntegrationListItem.module.scss'

type Props = {
  integration: IntegrationMetadata
  subscription: Pick<Subscription, 'id' | 'type'>
  target?: React.HTMLAttributeAnchorTarget
}

function IntegrationStatusIndicator({ integration }: { integration: IntegrationMetadata }) {
  if (integration.isEnterpriseOnly) {
    return (
      <Badge variant='solid' tone='default' className='cursor-pointer'>
        Enterprise
      </Badge>
    )
  }
  if (integration.isDemandTest) {
    return (
      <Badge variant='subtle' tone='secondary' className='cursor-pointer'>
        Request access
      </Badge>
    )
  }
  return <ChevronRight className='size-4 text-gray-800' />
}

export default function IntegrationListItem({ integration, subscription, target = '_self' }: Props) {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [useCase, setUseCase] = useState('')
  const { currentSubscriptionId } = useCurrentSubscription()
  const { mutate: submitIntegrationRequest, isLoading } = useIntegrationRequest()
  const { showToast } = useToast()
  const { data: currentUser } = useCurrentUser()
  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false)

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const isIntegrationIncludedInPlan =
    !integration.isEnterpriseOnly ||
    integration.category === IntegrationCategory.Active ||
    [SubscriptionType.Prepaid, SubscriptionType.ProofOfConcept].includes(subscription.type)

  const href = isIntegrationIncludedInPlan
    ? buildRoute(AppRoute.Integration, {
        subscriptionId: subscription.id,
        integrationTag: integration.integrationTag,
      })
    : buildRoute(AppRoute.SubscriptionPlan, {
        subscriptionId: subscription.id,
      })

  const handleClick = (e: React.MouseEvent) => {
    if (integration.isDemandTest) {
      e.preventDefault()
      setIsPopupOpen(true)
      ampli.fakeDoorOpened({ testName: 'Integrations Demand Test', pointOfInterest: integration.title })
    }
  }

  const handleClosePopup = () => {
    setIsPopupOpen(false)
  }

  const onRequestIntegration = () => {
    if (useCase.length > 500) {
      showToast({
        message: 'Your feedback is too long. Please keep it under 500 characters.',
        severity: 'error',
      })
      return
    }
    submitIntegrationRequest(
      { data: { currentSubscriptionId, integration: integration.title, useCase } },
      {
        onSuccess: () => {
          setIsRequestSubmitted(true)
          ampli.fakeDoorFormSubmitted({ testName: 'Integrations Demand Test', pointOfInterest: integration.title })
        },
        onError: () => {
          showToast({
            message:
              'An error occurred when submitting your integration request. Please contact support if this error persists.',
            severity: 'error',
          })
        },
      }
    )
  }

  const content = (
    <Paper variant='button' data-testid='integration_button' data-integration={integration.integrationTag}>
      <Stack direction='row' alignItems='center' spacing={2} padding={2}>
        <integration.iconComponent style={{ width: '32px', height: '32px' }} />
        <Stack flexGrow='1' overflow='hidden'>
          <Typography variant='bodyMMedium'>{integration.title}</Typography>
          <Typography variant='bodyS'>{integration.subtitle}</Typography>
        </Stack>
        <IntegrationStatusIndicator integration={integration} />
      </Stack>
    </Paper>
  )

  return (
    <>
      {integration.isDemandTest ? (
        <div onClick={handleClick} style={{ cursor: 'pointer' }}>
          {content}
        </div>
      ) : (
        <Link target={target} style={{ textDecoration: 'none' }} to={href}>
          {content}
        </Link>
      )}

      <Dialog
        open={isPopupOpen}
        onClose={handleClosePopup}
        classes={{ paper: styles.dialog }}
        fullScreen={smDown}
        maxWidth='sm'
        fullWidth={true}
      >
        <div style={{ opacity: isRequestSubmitted ? 0 : 1, transition: 'opacity 0.3s' }}>
          <DialogTitle onClose={handleClosePopup}>
            <Typography variant={smDown ? 'h2' : 'h1'}>Integrations early access waitlist</Typography>
          </DialogTitle>
          <DialogContent>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='center'
              spacing='12px'
              sx={{ padding: '8px 32px 32px' }}
            >
              <integration.iconComponent style={{ width: '48px', height: '48px' }} />
              <LinkIcon sx={{ color: '#999999' }} />
              <FingerprintLogo />
            </Stack>
            <Typography variant='body1' className={styles.message} marginBottom='32px'>
              We will add your email, <strong>{currentUser?.email}</strong>, to the waitlist and notify you as soon as
              the {integration.title} integration becomes available. In the meantime, we&apos;d appreciate knowing how
              you plan to use this integration.
            </Typography>
            <Typography variant='body1' className={styles.message} marginBottom='4px'>
              Use case (optional)
            </Typography>
            <TextFieldWithCounter
              maxSym={500}
              onChange={setUseCase}
              placeholder={`I'd like to integrate Fingerprint with ${integration.title} to...`}
            />
          </DialogContent>
          <DialogActions disableSpacing className={styles.buttons}>
            <Button onPress={handleClosePopup} variant='ghost' size='lg' className={styles.button}>
              Cancel
            </Button>
            <Button onPress={onRequestIntegration} isDisabled={isLoading} size='lg' className={styles.button}>
              Join Waitlist
            </Button>
          </DialogActions>
        </div>
        <SuccessMessage onClose={handleClosePopup} isRequestSubmitted={isRequestSubmitted} />
      </Dialog>
    </>
  )
}

function SuccessMessage({ onClose, isRequestSubmitted }: { onClose: () => void; isRequestSubmitted: boolean }) {
  return (
    <Fade in={isRequestSubmitted} className={styles.discordMessage}>
      <div>
        <IconButton size='small' onClick={onClose} className={styles.closeButton} data-testid='dialog-close-button'>
          <Close />
        </IconButton>
        <div className={styles.successMessage}>
          <CheckCircleOutlined color='success' />
          <Typography variant='h3' marginY='8px'>
            Thanks for your interest!
          </Typography>
          <Typography variant='body1' fontSize={14} className={styles.bodyText}>
            We have added you to the waitlist. In the meantime, feel free to join our Discord.
          </Typography>
          <LinkButton className={styles.discordButton} href={DISCORD_INVITE_URL} target='_blank'>
            <DiscordLogo fontSize='inherit' />
            Join Discord
          </LinkButton>
          <Typography variant='body1' fontSize={12} className={styles.bodyText}>
            800+ members
          </Typography>
        </div>
      </div>
    </Fade>
  )
}
