import jwtDecode from 'jwt-decode'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { AccessTokenPayload, RefreshTokenPayload } from '../models'

const supportsWebLocksApi = !!(typeof navigator !== 'undefined' && navigator.locks?.request)

const LOCK_NAME_JWT_REFRESH = 'jwt-refresh'
const LOCAL_STORAGE_JWT_KEY = 'jwtAuth'

type TokenPair = {
  accessToken: string
  refreshToken?: string
}

type RefreshableJWTStorageContextType = {
  isAuthorized: boolean
  possiblyStaleAccessToken: string | null
  refreshToken?: string
  accessTokenPayload?: AccessTokenPayload
  getAccessToken: () => Promise<string | null>
  setTokens: (tokens: TokenPair) => void
  clearTokens: () => void
}

const refreshableJWTStorageContext = createContext<RefreshableJWTStorageContextType>({
  possiblyStaleAccessToken: null,
  isAuthorized: false,
  getAccessToken: async () => null,
  setTokens: () => {},
  clearTokens: () => {},
})

/**
 * Converts JWT NumericDate to UNIX Timestamp
 */
function jwtNumericDateToTimestamp(numericDate: number) {
  return numericDate * 1000
}

export function getStoredJWTPair() {
  const data = localStorage.getItem(LOCAL_STORAGE_JWT_KEY)
  if (!data) {
    return null
  }

  try {
    return JSON.parse(data) as TokenPair
  } catch (_) {
    return null
  }
}

export function setStoredJWTPair(tokenPair: TokenPair | null) {
  if (tokenPair) {
    localStorage.setItem(LOCAL_STORAGE_JWT_KEY, JSON.stringify(tokenPair))
  } else {
    localStorage.removeItem(LOCAL_STORAGE_JWT_KEY)
  }
}

/**
 * Returns tokens along with their parsed payloads or null if at least one of them is malformed
 */
function getTokensWithPayloads(tokenPair: TokenPair | null) {
  let assignableTokenPair = tokenPair
  let tokenPayloads = null

  try {
    if (assignableTokenPair) {
      tokenPayloads = {
        accessTokenPayload: jwtDecode<AccessTokenPayload>(assignableTokenPair.accessToken),
        refreshTokenPayload: assignableTokenPair.refreshToken
          ? jwtDecode<RefreshTokenPayload>(assignableTokenPair.refreshToken)
          : undefined,
      }
    }
  } catch (_) {
    tokenPayloads = null
    assignableTokenPair = null
    // Ignored intentionally
  }

  return (
    assignableTokenPair &&
    tokenPayloads && {
      ...assignableTokenPair,
      ...tokenPayloads,
    }
  )
}

export const useRefreshableJWTStorage = () => useContext(refreshableJWTStorageContext)

type LocalTokenPairState = TokenPair & {
  accessTokenPayload: AccessTokenPayload
  refreshTokenPayload?: RefreshTokenPayload
}

type RefreshableJWTStorageProviderProps = {
  children: React.ReactNode
  handleTokenRequestExchange: (refreshToken: string) => Promise<TokenPair | null>
}

/**
 * This provider is meant to handle token storage and keeping the token pair refreshed.
 * All business logic should be handled downstream.
 */
export function RefreshableJWTStorageProvider({
  children,
  handleTokenRequestExchange,
}: RefreshableJWTStorageProviderProps) {
  const [localTokenState, setLocalTokenState] = useState<LocalTokenPairState | null>(
    () => getTokensWithPayloads(getStoredJWTPair()) // Immediately initialize the state with stored credentials
  )

  /**
   * This ref is used as a mutable component-scoped variable to deduplicate potentially parallel
   *  token refresh requests. Utilizing setState is not viable due to it being tied to React's refresh
   *  cycles, while we need updates to state take effect immediately.
   * It serves both as a flag to indicate that refresh process is in progress as well as means to access its result.
   */
  const tokenRefreshPromise = useRef<ReturnType<typeof handleTokenRequestExchange>>()

  const updateLocalTokenState = useCallback((tokenPair: TokenPair | null, shouldUpdateLocalStorage = true) => {
    const tokensWithPayloads = getTokensWithPayloads(tokenPair)

    setLocalTokenState(tokensWithPayloads)

    if (shouldUpdateLocalStorage) {
      setStoredJWTPair(tokensWithPayloads ? tokenPair : null)
    }
  }, [])

  const executeRefreshTokenRequest = useCallback(
    async (refreshToken: string) => {
      /**
       * Re-fetching token state from local storage here is a part of refresh deduplication across multiple open
       *  tabs. We need to check if other application instance (in other browser tab) has already went through
       *  refresh flow and pull in the new token pair if it succeeded.
       */
      const storedJWTPair = getStoredJWTPair()
      if (storedJWTPair && storedJWTPair?.refreshToken !== refreshToken) {
        updateLocalTokenState(storedJWTPair, false)
        return storedJWTPair
      }

      /**
       * This ref is used to deduplicate refresh requests within a single application instance.
       *  If a promise is present, new calls to obtain fresh access tokens will rely on its result instead of
       *  initiating a new refresh flow. This is done to avoid hitting Web Locks and LocalStorage APIs
       *  too often within a short timeframe and ensure refresh flow will remain deduplicated within a single tab
       *  if Web Locks aren't available.
       */
      try {
        tokenRefreshPromise.current = handleTokenRequestExchange(refreshToken)
        const result = await tokenRefreshPromise.current
        if (result) {
          updateLocalTokenState(result)
        } else {
          throw new Error('Could not refresh tokens')
        }

        return result
      } catch (e) {
        // If refresh flow failed, we can no longer guarantee that refresh token is valid
        updateLocalTokenState(localTokenState ? { accessToken: localTokenState.accessToken } : null)

        throw e
      } finally {
        tokenRefreshPromise.current = undefined
      }
    },
    [handleTokenRequestExchange, updateLocalTokenState, localTokenState]
  )

  const refreshTokenPair = useCallback(
    async (refreshToken: string) => {
      // See above for detailed explanation. Returns result of ongoing refresh flow if one was started
      if (tokenRefreshPromise.current) {
        return tokenRefreshPromise.current
      }

      /**
       * Web Locks API ensures lock can only be acquired once, so multiple refresh flows
       *  aren't started in multiple application instances (browser tabs).
       */
      if (supportsWebLocksApi) {
        return navigator.locks.request(LOCK_NAME_JWT_REFRESH, async () => executeRefreshTokenRequest(refreshToken))
      }

      return executeRefreshTokenRequest(refreshToken)
    },
    [executeRefreshTokenRequest]
  )

  /**
   * This effect pulls updated tokens from LocalStorage, if other application instance updated the tokens asynchronously
   *  as part of refresh flow, logging out or logging in.
   */
  useEffect(() => {
    const storageCallback = (evt: StorageEvent) => {
      if (evt.key !== LOCAL_STORAGE_JWT_KEY) {
        return
      }

      if (tokenRefreshPromise.current) {
        return
      }

      const storedTokenPair = getStoredJWTPair()
      if (storedTokenPair?.refreshToken !== localTokenState?.refreshToken) {
        updateLocalTokenState(storedTokenPair, false)
      }
    }

    window.addEventListener('storage', storageCallback)
    return () => window.removeEventListener('storage', storageCallback)
  }, [localTokenState?.refreshToken, updateLocalTokenState, refreshTokenPair])

  const contextState = useMemo<RefreshableJWTStorageContextType>(() => {
    return {
      isAuthorized: !!localTokenState?.accessToken,
      possiblyStaleAccessToken: localTokenState?.accessToken || null,
      refreshToken: localTokenState?.refreshToken,
      accessTokenPayload: localTokenState?.accessTokenPayload,
      getAccessToken: async () => {
        if (!localTokenState) {
          return null
        }

        const { accessTokenPayload, refreshTokenPayload } = localTokenState

        const accessTokenTTL = jwtNumericDateToTimestamp(accessTokenPayload.exp - accessTokenPayload.iat)
        const accessTokenTTLLeft = jwtNumericDateToTimestamp(accessTokenPayload.exp) - Date.now()
        const refreshTokenTTLLeft = refreshTokenPayload
          ? jwtNumericDateToTimestamp(refreshTokenPayload.exp) - Date.now()
          : -1

        const isAccessTokenStale = accessTokenTTLLeft < 1000 * 60
        const shouldAttemptRefresh = accessTokenTTLLeft < accessTokenTTL / 2
        const isRefreshTokenStale = refreshTokenTTLLeft < 1000 * 60

        if (isAccessTokenStale) {
          if (!localTokenState.refreshToken || isRefreshTokenStale) {
            // If both access and refresh tokens are stale, there's nothing to do except logging the user out
            updateLocalTokenState(null)
            return null
          }

          const result = await refreshTokenPair(localTokenState.refreshToken)
          return result?.accessToken ?? null
        }

        if (shouldAttemptRefresh && localTokenState.refreshToken && !isRefreshTokenStale) {
          // Not awaited intentionally, refresh can happen in background as current access token isn't stale yet
          refreshTokenPair(localTokenState.refreshToken)
        }

        return localTokenState?.accessToken ?? null
      },
      setTokens: updateLocalTokenState,
      clearTokens: () => updateLocalTokenState(null),
    }
  }, [localTokenState, updateLocalTokenState, refreshTokenPair])

  return <refreshableJWTStorageContext.Provider value={contextState}>{children}</refreshableJWTStorageContext.Provider>
}
