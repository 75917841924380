import { AppRoute, buildRoute } from 'appRoutes'
import { UserRoundPen } from 'lucide-react'

import { CommonMenuItem } from './CommonMenuSection'

export const useProfileMenuCommonItems: () => CommonMenuItem[] = () => {
  return [
    {
      key: 'my-profile',
      label: 'My profile',
      icon: <UserRoundPen />,
      link: buildRoute(AppRoute.ProfileSettings),
      associatedRoutes: [AppRoute.ProfileSettings],
    },
  ]
}
