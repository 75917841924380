import { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { AppRoute, buildRoute } from '../../appRoutes'
import { trackSubscriptionCanceled } from '../../helpers/vendor'
import { useDocumentTitle, useSubscription, useSubscriptionDelete, useSubscriptionSurvey, useToast } from '../../hooks'
import { PurchaseLayout } from '../commonUI'
import { useCurrentSubscription } from '../subscription'
import alexAvatar from './alex.jpg'
import { Form, Step } from './components/Form/Form'
import gabeAvatar from './gabe.jpg'

const slackPreviewMessageData = [
  {
    avatarSrc: alexAvatar,
    title: 'Alvin',
    time: '9:41 AM',
    message: 'We should collect feedback from churned customers.',
  },
  {
    avatarSrc: gabeAvatar,
    title: 'Gabe',
    time: '9:43 AM',
    message: 'Good idea, let’s place a survey that beams to Slack.',
  },
]

export const CancellationSurveyPage = () => {
  useDocumentTitle('Cancel Subscription')
  const match = useRouteMatch(AppRoute.SubscriptionCancellationSurveyConfirm)
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: subscription } = useSubscription(currentSubscriptionId)
  const history = useHistory()
  const { showToast } = useToast()
  const [activeStep, setActiveStep] = useState(match ? Step.Confirmation : Step.Feedback)
  const { mutate: sendSubscriptionCancelRequest, isLoading: isCancelingSubscription } =
    useSubscriptionDelete(subscription)
  const { mutate: sendSurveyResult, isLoading: isSurveyResultLoading } = useSubscriptionSurvey()

  const nextStep = () => {
    setActiveStep((step) => {
      if (step + 1 === Step.Confirmation) {
        history.push(
          buildRoute(AppRoute.SubscriptionCancellationSurveyConfirm, { subscriptionId: currentSubscriptionId })
        )
      }
      return step + 1
    })
  }

  const previousStep = () => {
    setActiveStep((step) => {
      if (step - 1 === Step.Feedback) {
        history.push(buildRoute(AppRoute.SubscriptionCancellationSurvey, { subscriptionId: currentSubscriptionId }))
      }
      return step - 1
    })
  }

  const handleSubmit = (message: string, reasons: string[], selectedCompetitor?: string) => {
    if (message.length > 0 || reasons.length > 0) {
      sendSurveyResult({
        params: { subscriptionId: currentSubscriptionId },
        data: { message, selectedCompetitor, reasons },
      })
      // survey results save errors omitted intentionally
    }
    sendSubscriptionCancelRequest(
      { params: { id: currentSubscriptionId } },
      {
        onSuccess: () => {
          trackSubscriptionCanceled(subscription!.type)
          history.push(buildRoute(AppRoute.SubscriptionPlan, { subscriptionId: currentSubscriptionId }))
        },
        onError: (error) => {
          showToast({
            severity: 'error',
            message:
              error.message ??
              'An error occurred when cancelling your subscription. Please contact support if this error persists.',
          })
        },
      }
    )
  }

  return (
    <PurchaseLayout>
      <Form
        activeStep={activeStep}
        nextStep={nextStep}
        previousStep={previousStep}
        isLoading={isCancelingSubscription || isSurveyResultLoading}
        onCancel={() => history.push(buildRoute(AppRoute.SubscriptionPlan, { subscriptionId: currentSubscriptionId }))}
        onSubmit={(data) => {
          handleSubmit(data.message ?? '', data.reasons, data.selectedCompetitor)
        }}
        slackMessages={slackPreviewMessageData}
      />
    </PurchaseLayout>
  )
}
