import { Theme, useMediaQuery } from '@mui/material'
import { formatDate } from 'features/commonUI'

import styles from './PromotionBanner.module.scss'

export type PromotionBannerProps = {
  onClick?: () => void
  endDate?: string
}

export default function PromotionBanner({ onClick, endDate }: PromotionBannerProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <div className={styles.wrapper} onClick={() => onClick?.()}>
      {isMobile ? (
        <>
          <b>Smart Signals - 50% off</b> {endDate && `until ${formatDate(endDate)}`}
        </>
      ) : (
        <>
          Make smart, data-driven decisions with <b>Smart Signals - 50% off</b>{' '}
          {endDate && `until ${formatDate(endDate)}`}
        </>
      )}
    </div>
  )
}
