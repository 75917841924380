import VerifyOtpDialog from 'components/VerifyOtpDialog/VerifyOtpDialog'
import { GenericError } from 'const'
import { DialogProps } from 'features/commonUI'
import { UserContext } from 'models'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { TransferOwnershipDialog } from '../TransferOwnershipDialog/TransferOwnershipDialog'
import { ManagedUser } from '../UserManagement/UserManagementData'

enum TransferStep {
  Transfer = 'transfer',
  Otp = 'otp',
}

export interface TransferOwnershipProps extends DialogProps {
  context: UserContext
  target?: ManagedUser
  error?: GenericError | null
  onSendOtp: (onSuccess?: () => void, onError?: () => void) => void
  onConfirmTransfer: (password: string, target?: ManagedUser) => void
  isSendingOtp?: boolean
  otpError?: GenericError | null
}

export function TransferOwnership({
  context,
  target,
  onClose,
  error,
  onSendOtp,
  onConfirmTransfer,
  isSendingOtp,
  otpError,
  open,
  ...props
}: TransferOwnershipProps) {
  const formMethods = useForm<{ password: string; acknowledgement: boolean }>({
    defaultValues: {
      password: '',
      acknowledgement: false,
    },
  })
  const { reset } = formMethods

  const [step, setStep] = useState(TransferStep.Transfer)

  function handleClose() {
    reset()
    setStep(TransferStep.Transfer)
    onClose?.()
  }

  useEffect(() => {
    if (!open) {
      // Reset acknowledgement and transfer step whenever the dialog closes.
      reset()
      setStep(TransferStep.Transfer)
    }
  }, [open, reset])

  switch (step) {
    case TransferStep.Transfer:
      return (
        <FormProvider {...formMethods}>
          <TransferOwnershipDialog
            context={context}
            target={target}
            onConfirmTransfer={onConfirmTransfer}
            onConfirmSsoTransfer={() => onSendOtp(() => setStep(TransferStep.Otp), handleClose)}
            onClose={handleClose}
            open={open}
            {...props}
          />
        </FormProvider>
      )
    case TransferStep.Otp:
      return (
        <VerifyOtpDialog
          context={context}
          onVerifyOtp={(otp) => onConfirmTransfer(otp, target)}
          onSendOtp={onSendOtp}
          onClose={handleClose}
          isLoading={isSendingOtp}
          error={otpError}
          open={open}
          {...props}
        />
      )
  }
}
