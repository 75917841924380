import { COMPANY_NAME } from 'const'
import { debounce } from 'helpers/debounce'
import { useEffect, useMemo, useState } from 'react'

export function useDocumentTitle(title: string) {
  useEffect(() => {
    document.title = `${title} – ${COMPANY_NAME}`
  }, [title])
}

const getViewportDimensions = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
})

export function useViewportDimensions(debounceMs = 100) {
  const [viewportDimensions, setViewportDimensions] = useState(getViewportDimensions())

  const onResize = useMemo(
    () => debounce(() => setViewportDimensions(getViewportDimensions()), debounceMs),
    [debounceMs]
  )

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [onResize])

  return viewportDimensions
}
