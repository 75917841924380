import { Button } from '@compass/components'
import { ampli } from 'models/ampli'

import { download } from '../../../../helpers/download'
import { MIMEType } from '../../../../helpers/types'
import { DnsRecord } from '../DNSRecordsTable/DNSRecordsTable'
import styles from './ExportDNSRecordsButton.module.scss'

export interface ExportDNSRecordsButtonProps {
  records: DnsRecord[]
}

export function ExportDNSRecordsButton({ records }: ExportDNSRecordsButtonProps) {
  if (records.length === 0) {
    return null
  }

  return (
    <Button
      variant='outline'
      className={styles.exportButton}
      onPress={() => {
        ampli.exportAsCsvClicked()
        exportAsCSV(records)
      }}
    >
      Export as CSV
    </Button>
  )
}

const csvColumns = ['Domain Name', 'Record Name', 'Record Type', 'Record Value']
const exportAsCSV = (records?: DnsRecord[]) => {
  if (!records || records.length === 0) {
    return
  }

  const rows = [csvColumns, ...records.map((r) => [r.domainName, r.dnsValidationName, r.type, r.dnsValidationValue])]

  const csvContent = rows.map((e) => e.join(',')).join('\n')
  download(csvContent, 'records.csv', MIMEType.Csv)
}
