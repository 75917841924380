import { useQuery } from '@tanstack/react-query'
import { InvoiceListItem, PaginationAndSortingOptions } from 'models'

import { getQueryParamsWithSanitizePaginationAndSorting } from '../../helpers/api'
import { extractData, useBuildRequest, useGenericErrorHandler, withMeta } from './base'

export function useInvoices({ limit, offset, sortBy, order }: PaginationAndSortingOptions<InvoiceListItem>) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()
  const queryParams = getQueryParamsWithSanitizePaginationAndSorting<InvoiceListItem>({ limit, offset, sortBy, order })

  return withMeta(
    useQuery(
      ['invoices', limit, offset, sortBy, order],
      () =>
        withErrorHandling(
          buildRequest('invoices', {
            queryParams,
          })
        ),
      { keepPreviousData: true }
    )
  )
}

export function useInvoice(id: string, subscriptionId?: string) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()
  const isUpcoming = id === 'upcoming'
  const enabled = (id && !isUpcoming) || (isUpcoming && subscriptionId != null)

  return useQuery({
    queryKey: ['invoice', id, subscriptionId] as const,
    queryFn: () =>
      extractData(
        withErrorHandling(
          isUpcoming && subscriptionId
            ? buildRequest('invoiceUpcomingGet', {
                params: {
                  subscriptionId,
                },
              })
            : buildRequest('invoiceGet', {
                params: {
                  id,
                  subscriptionId,
                },
              })
        )
      ),
    enabled,
  })
}
