import { useDateRangeContext } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useCurrentUser } from 'hooks/api/users'
import { useSubscriptionDistributionsQuery } from 'hooks/subscriptionDistributions'
import keyBy from 'lodash/keyBy'
import { SubscriptionDistribution } from 'models'
import { useEffect, useMemo, useState } from 'react'

import {
  useUserSubscriptionSettings,
  useUserSubscriptionSettingsUpdate,
} from '../../../../hooks/api/useUserSubscriptionSettings'
import { distributionsFilter, filterNonEmptyTypes, replaceByIndex } from '../WorkspaceInsights/formatters'
import { DistributionAction } from './DistributionAction'
import { DistributionWidget } from './DistributionWidget'

export function DistributionCharts() {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: currentUser } = useCurrentUser()
  const { dateRange } = useDateRangeContext()

  const { data, isError } = useSubscriptionDistributionsQuery(
    currentSubscriptionId,
    dateRange?.startDate?.toUTC()?.toISO()!,
    dateRange?.endDate?.toUTC()?.toISO()!,
    currentUser != null
  )
  const { data: userSettings } = useUserSubscriptionSettings(currentSubscriptionId, 'metric_list_preference')
  const { mutate: updateUserSettings } = useUserSubscriptionSettingsUpdate()

  const [toDisplay, setToDisplay] = useState([undefined, undefined, undefined] as Array<
    SubscriptionDistribution | undefined
  >)

  useEffect(() => {
    if (userSettings) {
      setToDisplay(
        userSettings
          .filter(distributionsFilter)
          .map((metric) => metric.statKey)
          .slice(0, 3)
      )
    }
  }, [userSettings])

  const distributions = useMemo(() => (userSettings ? keyBy(data?.charts, 'type') : {}), [data, userSettings])
  const all = useMemo(
    () => (userSettings ? (Object.keys(distributions) as SubscriptionDistribution[]) : []),
    [distributions, userSettings]
  )
  const unavailable = useMemo(
    () => new Set(userSettings ? toDisplay.filter(filterNonEmptyTypes) : []),
    [toDisplay, userSettings]
  )

  return (
    <>
      {toDisplay.map((type, index) => {
        const action =
          type === undefined ? null : (
            <DistributionAction
              blockIndex={index}
              selected={type}
              all={all}
              unavailable={unavailable}
              onSelect={(selected: SubscriptionDistribution) => {
                if (selected != null) {
                  if (userSettings) {
                    updateUserSettings({
                      params: { subscriptionId: currentSubscriptionId },
                      data: {
                        settings_name: 'metric_list_preference',
                        settings: replaceByIndex(userSettings, index, {
                          statKey: selected,
                          statType: 'distribution',
                        }),
                      },
                    })
                  }
                  setToDisplay((oldValues) => {
                    const newValue = [...oldValues]
                    newValue[index] = selected
                    return newValue
                  })
                }
              }}
            />
          )

        return (
          <DistributionWidget
            key={`distribution_${index}`}
            type={type}
            distribution={type && distributions?.[type]}
            isError={isError}
            action={action}
          />
        )
      })}
    </>
  )
}
