import { useQuery } from '@tanstack/react-query'

import { HOST, IS_ON_VERCEL, RANDOM_PATH_LENGTH, WARDEN_URL } from '../const'
import { generateRandomString } from '../helpers/common'
import { PlatformEvent } from '../models/event'

export function useSmartSignals(requestId?: string) {
  return useQuery({
    queryKey: ['smartSignalsEvent', requestId],
    queryFn: async () => {
      const randomPath = generateRandomString(RANDOM_PATH_LENGTH)

      let res: Response
      if (IS_ON_VERCEL) {
        // Call vercel function through random path to avoid adblockers.
        res = await fetch(`${HOST}/${randomPath}/`, {
          headers: {
            'x-vercel-function': 'events',
            'x-request-id': requestId ?? '',
          },
        })
      } else {
        // Development only, do not set REACT_APP_FPJS_SECRET_TOKEN otherwise.
        res = await fetch(`${WARDEN_URL}/events/${requestId}`, {
          headers: {
            'Auth-API-Key': process.env.REACT_APP_FPJS_SECRET_TOKEN ?? '',
          },
        })
      }

      const data: PlatformEvent = await res.json()
      return data
    },
    enabled: !!requestId,
    retry: false,
  })
}
