import { Skeleton, Tooltip } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { DashboardWidgetLink } from 'components/DashboardWidget/Link/DashboardWidgetLink'
import { HealthChip } from 'features/commonUI'
import { getThrottleLabel, URGENCY_LABEL_MAP, useSdkHealthStatusQuery } from 'features/health'
import { useCurrentSubscription } from 'features/subscription'
import { SdkHealthStatus } from 'models'

import styles from './HealthWidget.module.scss'
import { Widget } from './Widget'

export function HealthWidget() {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: sdkStatus } = useSdkHealthStatusQuery(currentSubscriptionId)

  return <HealthWidgetContent subscriptionId={currentSubscriptionId} sdkStatus={sdkStatus} />
}

export function HealthWidgetContent({
  subscriptionId,
  sdkStatus,
}: {
  subscriptionId?: string
  sdkStatus?: SdkHealthStatus
}) {
  const chip = extractChip(sdkStatus)

  return (
    <Widget title='Health'>
      <div className={styles.healthWrapper}>{chip}</div>
      {subscriptionId ? (
        <DashboardWidgetLink to={buildRoute(AppRoute.Health, { subscriptionId })} text='View health' />
      ) : (
        <Skeleton width={80} />
      )}
    </Widget>
  )
}

function extractChip(sdkStatus?: SdkHealthStatus) {
  if (!sdkStatus) {
    return <Skeleton width={120} />
  }

  const updateLabel = URGENCY_LABEL_MAP[sdkStatus.highestUrgency] ?? 'Latest'
  const throttleLabel = getThrottleLabel(sdkStatus.throttledRequestsPercentage)

  const title = `SDK version: ${updateLabel}. Throttling: ${throttleLabel}.`

  switch (sdkStatus.overviewStatus) {
    case 'stable':
      return (
        <Tooltip title='Your installation is up to date and all requests are getting through.'>
          <div>
            <HealthChip color='green' label='No issues found' />
          </div>
        </Tooltip>
      )

    case 'not_applicable':
      return (
        <Tooltip title='You haven&#39;t sent any requests yet.'>
          <div>
            <HealthChip color='gray' label='No data' />
          </div>
        </Tooltip>
      )

    case 'issues_found':
      return (
        <Tooltip title={title}>
          <div>
            <HealthChip color='blue' label='Issues found' />
          </div>
        </Tooltip>
      )

    case 'minor_issues_found':
      return (
        <Tooltip title={title}>
          <div>
            <HealthChip color='yellow' label='Minor issues found' />
          </div>
        </Tooltip>
      )

    case 'severe_issues_found':
      return (
        <Tooltip title={title}>
          {sdkStatus.highestUrgency === 'critical' ? (
            <div>
              <HealthChip color='black' label='Critical issues found' />
            </div>
          ) : (
            <div>
              <HealthChip color='red' label='Severe issues found' />
            </div>
          )}
        </Tooltip>
      )

    default:
      return null
  }
}
