import { PaymentMethod } from 'models'

export interface CardDescriptionProps {
  paymentMethod: PaymentMethod
  compact?: boolean
  withExpDate?: boolean
  className?: string
}

export default function CardDescription({
  paymentMethod,
  compact,
  withExpDate = true,
  className,
}: CardDescriptionProps) {
  const compactDescription = (
    <>
      {fourNumberPlaceholder} {paymentMethod.cardLast4}
    </>
  )

  return (
    <span className={className} data-testid='fourNumberPlaceholder'>
      {compact ? (
        <>{compactDescription}</>
      ) : (
        <>
          {fourNumberPlaceholder} {fourNumberPlaceholder} {compactDescription}
        </>
      )}
      {withExpDate ? ` ${getShortExpDate(paymentMethod.cardExpMonth, paymentMethod.cardExpYear)}` : null}
    </span>
  )
}

const fourNumberPlaceholder = <span>&bull;&bull;&bull;&bull;</span>

function getShortExpDate(expMonth: number, expYear: number) {
  return `${expMonth.toString().padStart(2, '0')}/${expYear.toString().slice(-2)}`
}
