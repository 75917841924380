import { InfoOutlined } from '@mui/icons-material'
import { Box, Stack, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material'

import styles from './HealthChartHeader.module.scss'

type HealthChartHeaderProps = {
  title: string
  tooltip: string
}
export function HealthChartHeader({ title, tooltip }: HealthChartHeaderProps) {
  // On mobile screens the "Last 24 hours" should break into a new line,
  // but on other screens it should be on the right side, aligned to the top right corner.
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      gap={isMobile ? 0 : 1}
      alignItems={isMobile ? 'start' : 'center'}
      justifyContent='space-between'
      flexWrap='wrap'
    >
      <Stack direction='row' gap={1} alignItems='center' justifyContent='space-between' flexWrap='wrap'>
        <Typography variant='bodyLMedium'>{title}</Typography>
        <Tooltip
          title={
            <Typography variant='bodyS' color='white'>
              {tooltip}
            </Typography>
          }
        >
          <InfoOutlined className={styles.infoIcon} />
        </Tooltip>
      </Stack>
      <Box flex={1} textAlign={isMobile ? undefined : 'right'}>
        <Typography variant='bodyM'>Last 24 hours</Typography>
      </Box>
    </Stack>
  )
}
