import { RateReviewOutlined } from '@mui/icons-material'
import { Fab } from '@mui/material'
import { ErrorBoundary } from '@rollbar/react'
import { AppRoute, buildRoute } from 'appRoutes'
import { ErrorFallbackUI } from 'components/index'
import { SidebarMain } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useAuth, useSubscription, useUiPreferences } from 'hooks'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import PromotionBanner from '../../../../components/PromotionBanner/PromotionBanner'
import { globalExperimentsDisable } from '../../../../helpers/vendor/amplitudeExperiments/globalSwitch'
import { useFeedbackData, useUpdateFeedbackData } from '../../../../hooks/feedback'
import { SubscriptionTier } from '../../../../models'
import { ampli } from '../../../../models/ampli'
import { FeedbackDrawer } from '../../../feedbackAndReviewPrompt'
import styles from './Layout.module.scss'

export type Props = {
  withScroll?: boolean
  children: React.ReactNode
}

export function Layout({ children, withScroll }: Props) {
  const { uiPreferences, updateUiPreferences } = useUiPreferences()
  const history = useHistory()
  const { data: userFeedbackData, isLoading: userFeedbackDataLoading } = useFeedbackData()
  const { mutate: updateUserFeedbackData } = useUpdateFeedbackData()
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: subscription, isLoading } = useSubscription(subscriptionId)
  const [showFeedbackDrawer, setShowFeedbackDrawer] = useState(false)
  const [showFeedbackButton, setShowFeedbackButton] = useState(false)
  const { isImpersonating } = useAuth()

  const handleSidebarClose = useCallback(() => {
    updateUiPreferences({ sidebar: { isOpened: false } })
  }, [updateUiPreferences])

  const currentPromotion = subscription?.promotions?.find(
    (p) =>
      new Date() > new Date(p.startAt) &&
      new Date() < new Date(p.endAt) &&
      [SubscriptionTier.Plus, SubscriptionTier.Pro].includes(p.priceLookupKey)
  )
  const showPromotionBanner =
    !isLoading && subscription && currentPromotion && (subscription?.discounts?.length ?? 0) === 0

  const wrapperStyle = {
    minHeight: showPromotionBanner ? 'calc(100% - 36px)' : undefined,
    ...(withScroll ? { overflow: 'scroll' } : {}),
  }

  useEffect(() => {
    if (showPromotionBanner) {
      ampli.bannerDisplayed({ bannerName: 'Smart Signals -50% ', isExperimentRelated: true })
    }
  }, [showPromotionBanner])

  useEffect(() => {
    if (globalExperimentsDisable) {
      // Don't add feedback drawer for playwright e2e and units
      return
    }

    const urlParams = new URLSearchParams(window.location.search)
    if (
      !userFeedbackDataLoading &&
      (urlParams.get('score') !== null || window.localStorage.getItem('__feedbackScore') !== null)
    ) {
      if (!uiPreferences.isFeedbackDrawerDismissed) {
        // Feedback drawer removes localstorage item when shown
        setShowFeedbackDrawer(true)
      }
      setShowFeedbackButton(true)
      return
    }

    const drawerShownTimes = parseInt(userFeedbackData?.dashboardDrawer?.timesShown ?? '0', 10)
    if (
      !userFeedbackDataLoading &&
      userFeedbackData?.dashboardDrawer &&
      drawerShownTimes < 3 &&
      userFeedbackData?.scorePoints === undefined &&
      !isImpersonating
    ) {
      setShowFeedbackButton(true)
      if (!uiPreferences.isFeedbackDrawerDismissed) {
        setShowFeedbackDrawer(true)
        updateUserFeedbackData({
          data: {
            email: userFeedbackData?.email,
            dashboardDrawer: {
              timesShown: (drawerShownTimes + 1).toString(),
            },
          },
        })
      }
    }
  }, [
    userFeedbackData,
    updateUserFeedbackData,
    userFeedbackDataLoading,
    uiPreferences.isFeedbackDrawerDismissed,
    isImpersonating,
  ])

  return (
    <>
      {showPromotionBanner && (
        <PromotionBanner
          endDate={currentPromotion?.endAt}
          onClick={() => {
            ampli.bannerActionPerformed({
              bannerName: 'Smart Signals -50%',
              isExperimentRelated: true,
              actionName: 'Banner Click',
            })
            history.push(buildRoute(AppRoute.SubscriptionPlan, { subscriptionId }))
          }}
        />
      )}
      <div className={styles.wrapper} style={wrapperStyle}>
        <SidebarMain
          mobileOpen={uiPreferences.sidebar?.isOpened}
          onClose={handleSidebarClose}
          style={{
            paddingTop: showPromotionBanner ? '36px' : undefined,
          }}
        />
        <div className={styles.main} data-testid='main-content'>
          <ErrorBoundary fallbackUI={ErrorFallbackUI}>{children}</ErrorBoundary>
        </div>
        <FeedbackDrawer
          isOpen={showFeedbackDrawer}
          onClose={() => {
            setShowFeedbackDrawer(false)
            updateUiPreferences({ isFeedbackDrawerDismissed: true })
          }}
          alreadyRated={userFeedbackData?.scorePoints !== undefined}
          currentFeedbackData={userFeedbackData}
        />
        {showFeedbackButton && (
          <Fab
            color='primary'
            style={{
              position: 'fixed',
              bottom: 20,
              right: 20,
            }}
            onClick={() => setShowFeedbackDrawer(true)}
          >
            <RateReviewOutlined fontSize='medium' />
          </Fab>
        )}
      </div>
    </>
  )
}
