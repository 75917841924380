export function isSafari() {
  return navigator.userAgent && /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export function getScreenDimensions() {
  return {
    width: window.screen.width,
    height: window.screen.height,
  }
}
