import { Region, regions } from '../const/countriesRegion'

export enum IpRegion {
  APAC = 'APAC',
  EMEA = 'EMEA',
  AMERICAS = 'Americas',
}

// for ChiliPiper forms (AMER/LATAM = Americas)

const regionToIpRegionMap: Record<Region, IpRegion> = {
  [Region.APAC]: IpRegion.APAC,
  [Region.EMEA]: IpRegion.EMEA,
  [Region.LATAM]: IpRegion.AMERICAS,
  [Region.AMER]: IpRegion.AMERICAS,
}

export function getCountryRegion(countryCode: string): IpRegion {
  const region = Object.keys(regions).find((key) => regions[key as Region].includes(countryCode)) as Region

  return regionToIpRegionMap[region] || Region.AMER
}
