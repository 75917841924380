import { forwardRef, LegacyRef } from 'react'
import { composeRenderProps, Link as AriaLink, LinkProps, TooltipTrigger } from 'react-aria-components'

import { ButtonExtraProps, buttonStyles } from './Button/Button'
import { Tooltip } from './Tooltip'

export type LinkButtonProps = LinkProps & ButtonExtraProps
function LinkButton(
  {
    variant = 'primary',
    tone,
    alt,
    size = 'default',
    isIcon,
    fullWidth,
    isLoading,
    tooltip,
    ...props
  }: LinkButtonProps,
  ref?: LegacyRef<HTMLAnchorElement>
) {
  const styleProps = { variant, tone, alt, size, fullWidth, isIcon } as const
  return (
    <TooltipTrigger delay={1000}>
      <AriaLink
        {...props}
        ref={ref}
        isDisabled={props.isDisabled || isLoading}
        className={composeRenderProps(props.className, (className, renderProps) =>
          buttonStyles({
            ...renderProps,
            ...styleProps,
            className,
          })
        )}
      />
      {(tooltip ?? props['aria-label']) ? <Tooltip>{tooltip ?? props['aria-label']}</Tooltip> : null}
    </TooltipTrigger>
  )
}

const _LinkButton = forwardRef(LinkButton)

export { _LinkButton as LinkButton }
