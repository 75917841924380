import { DateTime } from 'luxon'

import { pluralize } from './pluralize'

/**
 * Gets the number of days that have passed since a given date.
 * `from` should be less than `to`, otherwise 0 returned.
 *
 * @param from Start of the time period.
 * @param to End of the time period
 * @returns the number of days that have passed since a given date.
 */
export function differenceInDays(from: Date | string, to: Date | string) {
  const secondsInDay = 86400
  const passedSeconds = DateTime.fromJSDate(new Date(from)).diff(DateTime.fromJSDate(new Date(to)), 'seconds').seconds

  if (passedSeconds >= 0) {
    return 0
  }
  const remainingDays = Math.floor(passedSeconds / secondsInDay)
  return Math.abs(remainingDays)
}

export const labelToUnitMap = {
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
}

/**
 * Converts a given date or string representation of a date to a human-readable format.
 * If the date is in the future, it returns the time left until the date.
 * If the date is in the past or undefined, it returns 'Soon'.
 *
 * @param date - The date to be converted. Can be a string, Date object, or undefined.
 * @param showLeft - Optional parameter to indicate whether to include the 'left' suffix. Default is true.
 * @returns A human-readable representation of the date or 'Soon' if the date is in the past or undefined.
 */
export function humanizeTimeToDate(date: string | Date | undefined, showLeft = true) {
  if (date) {
    const difference = new Date(date).getTime() - Date.now()
    for (const [label, unit] of Object.entries(labelToUnitMap)) {
      if (difference > unit) {
        const unitsTotal = Math.ceil(difference / unit)
        return `${pluralize(unitsTotal, label)}${showLeft ? ' left' : ''}`
      }
    }
  }

  return 'Soon'
}
