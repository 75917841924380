import { InfoOutlined } from '@mui/icons-material'
import { IconButton, Tooltip, Typography } from '@mui/material'
import clsx from 'clsx'

import { formatNum, formatPercent } from '../../../../../helpers/format'
import { IntegrationUsageValueColor } from '../../IntegrationPage/types'
import styles from './ChartCounter.module.scss'
import Close from './Icons/Close.svg'
export interface ChartCounterProps {
  label: string
  color: IntegrationUsageValueColor
  value: number
  disabled: boolean
  onDisabled: () => void
  total: number
  isTotal?: boolean
  tooltipText?: string
}
export default function ChartCounter({
  label,
  color,
  value,
  disabled,
  onDisabled,
  total,
  tooltipText,
  isTotal = false,
}: ChartCounterProps) {
  const formattedValue = formatNum(value)
  const percentage = formatPercent(value / total)
  return (
    <div className={clsx(styles[disabled ? 'counterContainerDisabled' : 'counterContainer'])}>
      <Typography variant='bodyS' className={styles.title}>
        <span>{label}</span>
        <div className={styles.disableContainer}>
          {tooltipText && (
            <Tooltip title={tooltipText} placement='top' className={styles.tooltipIcon}>
              <InfoOutlined />
            </Tooltip>
          )}
          {!isTotal && (
            <IconButton
              title='Toggle counter'
              centerRipple={true}
              className={clsx(styles.disableButton, styles[`disableButton${disabled ? 'Disabled' : 'Enabled'}`])}
              onClick={onDisabled}
            >
              <Close className={clsx(styles.closeIcon, { [styles.disabledIcon]: disabled })} />
            </IconButton>
          )}
        </div>
      </Typography>
      <div className={styles.valueContainer}>
        <Typography variant='h2' component='h1' style={{ color }} className={styles.value}>
          {formattedValue}
        </Typography>
        <Typography variant='bodyS' fontWeight={700} style={{ color }}>
          {percentage}
        </Typography>
      </div>
    </div>
  )
}
