export function getLocale() {
  try {
    if (navigator.languages !== undefined) {
      return navigator.languages[0]
    } else {
      return navigator.language
    }
  } catch (_e) {
    return 'en-US'
  }
}

export const getClientTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const isLocale24h = () => {
  return !new Intl.DateTimeFormat(getLocale(), { hour: 'numeric' }).format(0).match(/AM/i)
}
