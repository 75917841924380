import { useAccountPreferences } from 'hooks'
import { AccountBannerName } from 'models'
import { Fragment, PropsWithChildren, ReactNode } from 'react'

import styles from './BannerSection.module.scss'

export interface BannerDescriptorProps {
  handleClose: () => void
}

export interface BannerDescriptor {
  key: AccountBannerName
  render: (props: BannerDescriptorProps) => ReactNode
  shouldBeShown?: boolean
}

export interface BannerSectionProps {
  banners: BannerDescriptor[]
}

const maxVisibleBanners = 2

export function BannerSection({ banners, children }: PropsWithChildren<BannerSectionProps>) {
  const { getBannerState: getAccountBannerState, handleCloseBanner: handleCloseAccountBanner } = useAccountPreferences()

  const visibleBanners = banners
    .filter(({ key, shouldBeShown = true }) => {
      const { isHidden: isClosed } = getAccountBannerState(key)
      return !isClosed && shouldBeShown
    })
    .slice(0, maxVisibleBanners)

  return (
    <div className={styles.wrapper}>
      {visibleBanners.map(({ render, key }) => (
        <Fragment key={key}>{render({ handleClose: () => handleCloseAccountBanner(key) })}</Fragment>
      ))}
      {children}
    </div>
  )
}
