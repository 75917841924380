import { LinkButton } from '@compass/components'
import { Typography } from '@mui/material'

import { ProxyIntegrationRequests, SdkHealthSdkDetails } from '../../../../models'
import { SemanticVersion } from '../../../health/utils/SemanticVersion'
import { IntegrationMetadata } from '../../components/IntegrationMetadata/integrationMetadata'
import IntegrationPage from '../../components/IntegrationPage/IntegrationPage'
import { IntegrationPageSectionDefinition } from '../../components/IntegrationPage/IntegrationPageSection/IntegrationPageSection'
import { IntegrationPageAlertProps } from '../../components/IntegrationPage/IntegrationTopFrame/IntegrationTopFrame'
import { IntegrationStatus } from '../../components/IntegrationPage/types'
import ChartIcon from '../../components/UsageChart/Icons/ChartIcon.svg'
import { ProxyIntegrationUsageChart } from '../../components/UsageChart/ProxyIntegrationUsageChart'
import styles from './IntegrationGenericContents.module.scss'
import { IntegrationGenericMDX } from './IntegrationGenericMDX'

export type IntegrationGenericContentsProps = {
  subscriptionId: string
  requestsMade?: ProxyIntegrationRequests
  health?: SdkHealthSdkDetails
  isLoading?: boolean
  integration: IntegrationMetadata
}

function totalRequestsMade(requestsMade?: ProxyIntegrationRequests): number {
  return requestsMade?.proxyRequests?.reduce((sum, curr) => sum + curr.value, 0) || 0
}

function extractStatusFromUsageData(requestsMade?: ProxyIntegrationRequests): IntegrationStatus {
  return totalRequestsMade(requestsMade) > 0 ? IntegrationStatus.Installed : IntegrationStatus.NotInstalled
}

function extractStatusLabelFromStatus(integrationStatus: IntegrationStatus): string {
  switch (integrationStatus) {
    case IntegrationStatus.Installed:
      return `Kudos! The integration has been successfully installed.`
    default:
    case IntegrationStatus.NotInstalled:
      return `Click (or tap) the “View documentation” button to get started.`
  }
}

export function IntegrationGenericContents({
  subscriptionId,
  isLoading,
  health,
  requestsMade,
  integration,
}: IntegrationGenericContentsProps) {
  const repositoryVersion = health?.latestVersion
  const lastCustomerVersionFromUsage = health?.versions.sort((a, b) => {
    return SemanticVersion.compare(SemanticVersion.fromString(b.version), SemanticVersion.fromString(a.version))
  })[0]?.version

  const status = extractStatusFromUsageData(requestsMade)
  const statusLabel = extractStatusLabelFromStatus(status)

  const chartVisible = requestsMade && totalRequestsMade(requestsMade) > 0

  const lastXDays = Math.min(requestsMade?.allRequests?.length ?? 0, 30)

  const versionDiff = SemanticVersion.getDifference(
    SemanticVersion.fromString(lastCustomerVersionFromUsage ?? ''),
    SemanticVersion.fromString(repositoryVersion ?? '')
  )

  let alertTitle = ''
  switch (versionDiff) {
    case 'major':
      alertTitle = 'Update required'
      break
    case 'minor':
      alertTitle = 'Update needed'
      break
    case 'patch':
      alertTitle = 'Update recommended'
      break
  }

  const getAlertProps = (): IntegrationPageAlertProps | undefined => {
    // Show warning if user is using outdated integration
    if (versionDiff !== 'same') {
      return {
        alertSeverity: 'warning',
        alertTitle,
        alertContent: (
          <div>
            <Typography variant='bodyM' className={styles.updateAlertText}>
              Outdated SDKs/integrations lower accuracy and may cause errors. An update is recommended to achieve
              maximum Fingerprint strength.
            </Typography>
          </div>
        ),
      }
    }

    return undefined
  }

  const sections: IntegrationPageSectionDefinition[] = []
  if (status === IntegrationStatus.NotInstalled) {
    if (chartVisible) {
      const section: IntegrationPageSectionDefinition = {
        key: 'usage',
        title: `${integration.title} Usage`,
        info: `Last ${lastXDays} Days`,
        content: <ProxyIntegrationUsageChart requests={requestsMade} integrationName={integration.title} />,
      }

      sections.push(section)
    } else {
      if (integration.mdx) {
        return <IntegrationGenericMDX integration={integration} />
      }
    }
  } else {
    const section: IntegrationPageSectionDefinition = chartVisible
      ? {
          key: 'usage',
          title: `${integration.title} Usage`,
          info: 'Last 30 Days',
          content: <ProxyIntegrationUsageChart requests={requestsMade} integrationName={integration.title} />,
        }
      : {
          key: 'usage',
          title: `${integration.title} Usage`,
          info: '',
          content: (
            <div className={styles.noUsageDataContainer}>
              <div className={styles.noDataIconContainer}>
                <ChartIcon />
              </div>
              <div>
                <Typography variant='semiBody1' component='h4' className={styles.noUsageDataTitle} align='center'>
                  No Usage Data Yet
                </Typography>
                <Typography variant='bodyM' className={styles.noUsageDataContainerText} align='center'>
                  We need more data to generate this chart. Make some API requests!
                </Typography>
              </div>
              <LinkButton href={integration.docsLink} target='_blank'>
                View Documentation
              </LinkButton>
            </div>
          ),
        }
    sections.push(section)
  }

  return (
    <>
      <IntegrationPage
        {...getAlertProps()}
        isLoading={isLoading}
        integrationStatus={status}
        title={integration.title}
        subscriptionId={subscriptionId}
        statusLabel={statusLabel}
        docsUrl={integration.docsLink}
        repoUrl={integration.sourceCodeLink}
        Logo={integration.iconComponent}
        metadata={
          chartVisible && status !== IntegrationStatus.NotInstalled
            ? [
                {
                  title: 'Your version',
                  value: lastCustomerVersionFromUsage,
                  versionBadge: versionDiff !== 'same' ? versionDiff : undefined,
                },
                {
                  title: 'Latest version',
                  value: repositoryVersion,
                },
              ]
            : [
                {
                  title: 'Latest version',
                  value: repositoryVersion,
                },
              ]
        }
        sections={sections}
      />
    </>
  )
}
