export type DatadogSite = 'US1' | 'US3' | 'US5' | 'EU1' | 'US1-FED' | 'AP1'

export const DATADOG_SITES: Record<DatadogSite, string> = {
  US1: 'datadoghq.com',
  US3: 'us3.datadoghq.com',
  US5: 'us5.datadoghq.com',
  EU1: 'datadoghq.eu',
  'US1-FED': 'ddog-gov.com',
  AP1: 'ap1.datadoghq.com',
}

export const DATADOG_URLS: Record<DatadogSite, string> = {
  US1: 'https://browser-intake-datadoghq.com',
  US3: 'https://browser-intake-us3-datadoghq.com',
  US5: 'https://browser-intake-us5-datadoghq.com',
  EU1: 'https://browser-intake-datadoghq.eu',
  'US1-FED': 'https://browser-intake-ddog-gov.com',
  AP1: 'https://browser-intake-ap1-datadoghq.com',
}
