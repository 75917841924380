import { Skeleton, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { DashboardWidgetLink } from 'components/DashboardWidget/Link/DashboardWidgetLink'
import { SUBSCRIPTION_DISPLAY_PLANS } from 'const'
import { formatDate, HealthChip } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { getSubscriptionDisplayPlan } from 'helpers/subscription'
import { useSubscription } from 'hooks'
import { ExpandedSubscription, SubscriptionStatus, SubscriptionType } from 'models'

import styles from './PlanWidget.module.scss'
import { Widget } from './Widget'

export function PlanWidget() {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: subscription } = useSubscription(currentSubscriptionId)

  return <PlanWidgetContent subscription={subscription} />
}

export function PlanWidgetContent({ subscription }: { subscription?: ExpandedSubscription }) {
  const plan = subscription
    ? SUBSCRIPTION_DISPLAY_PLANS[getSubscriptionDisplayPlan(subscription as ExpandedSubscription)]
    : null

  const isEnterprise = subscription?.type === SubscriptionType.Prepaid
  const mayNeedToChangeBilling =
    subscription != null &&
    [
      SubscriptionStatus.PastDue,
      SubscriptionStatus.Unpaid,
      SubscriptionStatus.Incomplete,
      SubscriptionStatus.IncompleteExpired,
    ].includes(subscription.status)
  const route = isEnterprise || mayNeedToChangeBilling ? AppRoute.Billing : AppRoute.SubscriptionPlan
  const linkText = isEnterprise || mayNeedToChangeBilling ? 'View billing' : 'View plans'
  const link = subscription ? (
    <DashboardWidgetLink
      to={buildRoute(route, { subscriptionId: subscription.id })}
      text={linkText}
      data-testid='plan-link'
    />
  ) : null

  const chip = extractChip(subscription)

  return (
    <Widget title='Plan'>
      <div className={styles.nameWrapper}>
        <Typography variant='bodyMMedium' data-testid='plan-widget-value'>
          {plan?.name ?? <Skeleton width={120} />}
        </Typography>
        {chip}
      </div>
      {link ?? <Skeleton width={140} />}
    </Widget>
  )
}

function extractChip(subscription?: ExpandedSubscription) {
  if (!subscription) {
    return null
  }

  if (subscription.type === SubscriptionType.ProofOfConcept) {
    return <HealthChip color='gray' label='Proof of Concept' />
  }

  switch (subscription.status) {
    case SubscriptionStatus.Trialing:
      return <HealthChip color='gray' label='Trial' />

    case SubscriptionStatus.Unpaid:
      return <HealthChip color='yellow' label='Unpaid' />

    case SubscriptionStatus.PastDue:
      return <HealthChip color='red' label='Past due' />

    case SubscriptionStatus.Canceled:
      return <HealthChip color='red' label='Canceled' />

    case SubscriptionStatus.Active:
      return subscription.cancelAt ? (
        <HealthChip color='red' label={`Cancels ${formatDate(subscription.cancelAt, 'short')}`} />
      ) : null

    case SubscriptionStatus.Incomplete:
    case SubscriptionStatus.IncompleteExpired:
    case SubscriptionStatus.ProofOfConcept:
    case SubscriptionStatus.Restricted:
    case SubscriptionStatus.Creating:
      return null
  }
}
