import { Paper, Typography } from '@mui/material'
import { ExternalLayout } from 'features/commonUI'

import styles from './SignUpConfirm.module.scss'
import Success from './success.svg'

export type SignUpConfirmSuccessProps = {
  timeout: number
}
export function SignUpConfirmSuccess({ timeout }: SignUpConfirmSuccessProps) {
  return (
    <ExternalLayout srcPage='signupConfirm'>
      <Paper className={styles.container}>
        <div className={styles.topImage}>
          <Success />
        </div>
        <h1 className={styles.title}>You’re signed up!</h1>
        <Typography variant='body2' className={styles.description}>
          This page will redirect automagically in {timeout} seconds.
        </Typography>
      </Paper>
    </ExternalLayout>
  )
}
