import { Platform, SuspectScore } from 'models'

import { DOCS_SMART_SIGNALS_OVERVIEW } from '../../const'

export const SUSPECT_SCORE_NAMES: Record<SuspectScore, string> = {
  [SuspectScore.Incognito]: 'Incognito detection',
  [SuspectScore.Tampering]: 'Tampering detection',
  [SuspectScore.HighActivity]: 'High-activity device',
  [SuspectScore.BadBots]: 'Bot detection (bad bot)',
  [SuspectScore.VpnTimezone]: 'VPN (time zone mismatch)',
  [SuspectScore.VpnPublic]: 'VPN (public VPN service)',
  [SuspectScore.VpnMobile]: 'VPN (mobile detection)',
  [SuspectScore.VirtualMachine]: 'Virtual machine detection',
  [SuspectScore.PrivacySettings]: 'Privacy settings',
  [SuspectScore.IpBlocklistEmailSpam]: 'IP blocklist (email spam)',
  [SuspectScore.IpBlocklistAttackSource]: 'IP blocklist (known attack source)',
  [SuspectScore.Tor]: 'Tor exit node',
  [SuspectScore.Proxy]: 'Public proxy',
  [SuspectScore.Emulator]: 'Android emulator detection',
  [SuspectScore.RootApps]: 'Android tampering detection',
  [SuspectScore.AppCloners]: 'App cloners detection',
  [SuspectScore.Jailbreak]: 'Jailbreak detection',
  [SuspectScore.Frida]: 'Frida detection',
  [SuspectScore.DeveloperTools]: 'Developer tools',
  [SuspectScore.RemoteControl]: 'Remote control',
  [SuspectScore.VpnOsMismatch]: 'VPN OS mismatch',
}

export const SUSPECT_SCORE_DOCS: Record<SuspectScore, string> = {
  [SuspectScore.Incognito]: `${DOCS_SMART_SIGNALS_OVERVIEW}#browser-incognito-detection`,
  [SuspectScore.Tampering]: `${DOCS_SMART_SIGNALS_OVERVIEW}#browser-tamper-detection`,
  [SuspectScore.HighActivity]: `${DOCS_SMART_SIGNALS_OVERVIEW}#high-activity-device`,
  [SuspectScore.BadBots]: `${DOCS_SMART_SIGNALS_OVERVIEW}#browser-bot-detection`,
  [SuspectScore.VpnTimezone]: `${DOCS_SMART_SIGNALS_OVERVIEW}#vpn-detection-for-browsers`,
  [SuspectScore.VpnPublic]: `${DOCS_SMART_SIGNALS_OVERVIEW}#vpn-detection-for-browsers`,
  [SuspectScore.VpnMobile]: `${DOCS_SMART_SIGNALS_OVERVIEW}#vpn-detection-for-mobile-devices`,
  [SuspectScore.VirtualMachine]: `${DOCS_SMART_SIGNALS_OVERVIEW}#virtual-machine-detection`,
  [SuspectScore.PrivacySettings]: `${DOCS_SMART_SIGNALS_OVERVIEW}#privacy-focused-settings`,
  [SuspectScore.IpBlocklistEmailSpam]: `${DOCS_SMART_SIGNALS_OVERVIEW}#ip-blocklist-matching`,
  [SuspectScore.IpBlocklistAttackSource]: `${DOCS_SMART_SIGNALS_OVERVIEW}#ip-blocklist-matching`,
  [SuspectScore.Tor]: `${DOCS_SMART_SIGNALS_OVERVIEW}#ip-blocklist-matching`,
  [SuspectScore.Proxy]: `${DOCS_SMART_SIGNALS_OVERVIEW}#ip-blocklist-matching`,
  [SuspectScore.Emulator]: `${DOCS_SMART_SIGNALS_OVERVIEW}#android-emulator-detection`,
  [SuspectScore.RootApps]: `${DOCS_SMART_SIGNALS_OVERVIEW}#android-tamper-detection`,
  [SuspectScore.AppCloners]: `${DOCS_SMART_SIGNALS_OVERVIEW}#cloned-app-detection`,
  [SuspectScore.Jailbreak]: `${DOCS_SMART_SIGNALS_OVERVIEW}#jailbroken-device-detection`,
  [SuspectScore.Frida]: `${DOCS_SMART_SIGNALS_OVERVIEW}#frida-detection`,
  [SuspectScore.DeveloperTools]: `${DOCS_SMART_SIGNALS_OVERVIEW}#developer-tools-detection`,
  [SuspectScore.RemoteControl]: `${DOCS_SMART_SIGNALS_OVERVIEW}#remote-control-tools-detection`,
  [SuspectScore.VpnOsMismatch]: `${DOCS_SMART_SIGNALS_OVERVIEW}#vpn-detection-for-browsers`,
}

export const PLATFORM_NAMES: Record<Platform, 'Web' | 'Android' | 'iOS'> = {
  [Platform.Browser]: 'Web',
  [Platform.Android]: 'Android',
  [Platform.Ios]: 'iOS',
}

/**
 * Default scores and weights used to render the loading state for the suspect score ui.
 */
export const LOADER_WEIGHTS = {
  [Platform.Browser]: {
    [SuspectScore.DeveloperTools]: 0,
    [SuspectScore.RemoteControl]: 0,
    [SuspectScore.VpnOsMismatch]: 0,
    [SuspectScore.Incognito]: 4,
    [SuspectScore.Tampering]: 8,
    [SuspectScore.HighActivity]: 6,
    [SuspectScore.BadBots]: 7,
    [SuspectScore.VpnTimezone]: 3,
    [SuspectScore.VpnPublic]: 4,
    [SuspectScore.VirtualMachine]: 14,
    [SuspectScore.PrivacySettings]: 6,
    [SuspectScore.IpBlocklistEmailSpam]: 14,
    [SuspectScore.IpBlocklistAttackSource]: 13,
    [SuspectScore.Tor]: 14,
    [SuspectScore.Proxy]: 14,
  },
  [Platform.Android]: {
    [SuspectScore.HighActivity]: 5,
    [SuspectScore.VpnTimezone]: 4,
    [SuspectScore.VpnPublic]: 5,
    [SuspectScore.VpnMobile]: 6,
    [SuspectScore.IpBlocklistEmailSpam]: 12,
    [SuspectScore.IpBlocklistAttackSource]: 13,
    [SuspectScore.Tor]: 16,
    [SuspectScore.Proxy]: 12,
    [SuspectScore.Emulator]: 9,
    [SuspectScore.RootApps]: 12,
    [SuspectScore.AppCloners]: 9,
    [SuspectScore.Frida]: 14,
  },
  [Platform.Ios]: {
    [SuspectScore.HighActivity]: 6,
    [SuspectScore.VpnTimezone]: 4,
    [SuspectScore.VpnPublic]: 5,
    [SuspectScore.VpnMobile]: 6,
    [SuspectScore.IpBlocklistEmailSpam]: 13,
    [SuspectScore.IpBlocklistAttackSource]: 13,
    [SuspectScore.Tor]: 17,
    [SuspectScore.Proxy]: 15,
    [SuspectScore.Jailbreak]: 10,
  },
}
