import { AppProduct } from 'models'
import { PlatformEvent } from 'models/event'

type FormattedProduct = {
  value: string
  faded?: boolean
  warning?: boolean
}

/**
 * Maps a product to a formatter that turns an event into a short string representing the result of that product.
 * A formatter returning `undefined` means there is no value or no meaningful way to display it as a short string.
 */
export const PRODUCT_FORMATTERS: Record<AppProduct, (event?: PlatformEvent) => FormattedProduct | undefined> = {
  [AppProduct.Identification]: (event) =>
    event?.products.identification?.data?.visitorId
      ? {
          value: event?.products.identification?.data?.visitorId,
        }
      : undefined,
  [AppProduct.Botd]: (event) => {
    const result = event?.products.botd?.data?.bot.result
    if (result !== undefined) {
      return mapBoolean(result !== 'notDetected')
    }
    return undefined
  },
  [AppProduct.IpResolution]: () => undefined,
  [AppProduct.Incognito]: (event) => mapBoolean(event?.products.incognito?.data?.result),
  [AppProduct.MobileRootApps]: (event) => mapBoolean(event?.products.rootApps?.data?.result),
  [AppProduct.MobileEmulator]: (event) => mapBoolean(event?.products.emulator?.data?.result),
  [AppProduct.MobileAppCloners]: (event) => mapBoolean(event?.products.clonedApp?.data?.result),
  // For android there will always be a factory reset timestamp, we cannot guess what "recent" means for the customers.
  [AppProduct.MobileRecentFactoryReset]: () => undefined,
  [AppProduct.MobileJailbreak]: (event) => mapBoolean(event?.products.jailbroken?.data?.result),
  [AppProduct.MobileFrida]: (event) => mapBoolean(event?.products.frida?.data?.result),
  [AppProduct.MobileGeolocationSpoofing]: (event) => mapBoolean(event?.products.locationSpoofing?.data?.result),
  [AppProduct.IpBlacklist]: (event) => mapBoolean(event?.products.ipBlocklist?.data?.result),
  [AppProduct.Tor]: (event) => mapBoolean(event?.products.tor?.data?.result),
  [AppProduct.PrivacySettings]: (event) => mapBoolean(event?.products.privacySettings?.data?.result),
  [AppProduct.VirtualMachine]: (event) => mapBoolean(event?.products.virtualMachine?.data?.result),
  [AppProduct.Vpn]: (event) => mapBoolean(event?.products.vpn?.data?.result),
  [AppProduct.Proxy]: (event) => mapBoolean(event?.products.proxy?.data?.result),
  [AppProduct.Tampering]: (event) => mapBoolean(event?.products.tampering?.data?.result),
  [AppProduct.RawDeviceAttributes]: () => undefined,
}

function mapBoolean(value?: boolean): FormattedProduct | undefined {
  if (value === undefined) {
    return undefined
  }

  return {
    value: value ? 'Detected' : 'Not detected',
    faded: value === false,
    warning: value === true,
  }
}
