import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import {
  ChipProps,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { AppRoute, buildRoute } from 'appRoutes'
import { LinkWithIcon } from 'components'
import { memo } from 'react'

import styles from './ActionTable.module.scss'

export interface TableAction {
  label: string
  onClick: () => void
  disabled?: boolean
  disabledTooltip?: string
}

interface EntityWithId {
  id: string | number | undefined
}

export interface TableProps<T extends EntityWithId> {
  className?: string
  entities: T[]
  titleVariant?: Variant
  isLoading?: boolean
  columnsNumber: number
  actionsSize?: ChipProps['size']
  headerRow: React.ReactNode
  row: (entity: T) => React.ReactNode
  documentationLink?: string
  emptyStateText?: string
}

export default memo(function ActionTable<T extends EntityWithId>(props: TableProps<T>) {
  const { headerRow, documentationLink, className } = props

  return (
    <section className={className}>
      <TableContainer className={styles.tableContainer} component={Paper}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>{headerRow}</TableRow>
          </TableHead>
          <TableBody>
            <ContentRow {...props} />
          </TableBody>
        </Table>
      </TableContainer>
      {documentationLink && (
        <LinkWithIcon
          icon={DescriptionOutlinedIcon}
          to={buildRoute(AppRoute.ReadmeLogin, {}, { redirectUrl: documentationLink })}
          target='_blank'
        >
          Docs
        </LinkWithIcon>
      )}
    </section>
  )
})

function ContentRow<T extends EntityWithId>({
  entities,
  isLoading = true,
  row,
  emptyStateText,
  columnsNumber,
}: TableProps<T>) {
  if (isLoading || !entities) {
    return (
      <TableRow>
        <TableCell align='center' colSpan={columnsNumber}>
          <Skeleton data-testid='table-loading-skeleton' />
        </TableCell>
      </TableRow>
    )
  }

  if (entities.length === 0) {
    return (
      <TableRow>
        <TableCell align='center' colSpan={columnsNumber}>
          <Typography>{emptyStateText ?? 'No Data'}</Typography>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {entities.map((entity) => (
        <TableRow key={entity.id}>{row(entity)}</TableRow>
      ))}
    </>
  )
}
