import { LinkButton } from '@compass/components'
import { Card, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import clsx from 'clsx'
import { ampli } from 'models/ampli'

import styles from './UpgradeToEnterprise.module.scss'

export interface UpgradeToEnterpriseProps {
  className?: string
  pathname: string
}

export default function UpgradeToEnterprise({ className, pathname }: UpgradeToEnterpriseProps) {
  return (
    <Card className={clsx(styles.container, className)}>
      <Typography variant='semiBody2' component='h2'>
        Upgrade to Enterprise
      </Typography>
      <Typography variant='body3'>
        Get hands-on support and a custom contract for your large scale organization.
      </Typography>

      <LinkButton
        variant='secondary'
        aria-label='Contact us'
        href={AppRoute.ContactSalesEnterprise}
        onPress={() => {
          ampli.appCtaClicked({ 'App Page Path': pathname, ctaName: 'Contact us' })
        }}
      >
        Contact us
      </LinkButton>
    </Card>
  )
}
