import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

type Stringified<T> = {
  [k in keyof T]?: string
}

export function useSearchParams<T extends Record<string, any>>(managedKeys: string[]) {
  const history = useHistory()

  // Always take the fresh value, since history replace is not noticed.
  const search = window.location.search
  const currentSearchParams = useMemo(() => new URLSearchParams(search), [search])
  const params = useMemo(
    () => Object.fromEntries(currentSearchParams.entries()) as Stringified<T>,
    [currentSearchParams]
  )

  const setSearchParams = useCallback(
    (values: T) => {
      const newValues: Record<string, string> = {
        ...Object.fromEntries(managedKeys.map((key) => [key, undefined])),
        ...values,
      }

      const newSearchParams = new URLSearchParams(currentSearchParams)
      for (const [key, value] of Object.entries(newValues)) {
        if (value) {
          newSearchParams.set(key, value)
        } else {
          newSearchParams.delete(key)
        }
      }

      const newUrl = [history.location.pathname, newSearchParams.toString()].filter(Boolean).join('?')
      // Have to use this instead of window.history.replaceState(null, '', newUrl)
      // that way tests would interfere with each other.
      history.replace(newUrl)
    },
    [currentSearchParams, history, managedKeys]
  )

  return [params, setSearchParams] as const
}
