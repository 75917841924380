import { Theme, useMediaQuery } from '@mui/material'
import { DOCS_URL, SUPPORT_PAGE_URL } from 'const'
import { BookMarkedIcon, MailIcon } from 'lucide-react'
import { ampli } from 'models/ampli'

import { CommonMenuItem } from './CommonMenuSection'

export const useMobileMenuCommonItems: () => CommonMenuItem[] | null = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  if (isDesktop) {
    return null
  }

  return [
    {
      key: 'docs',
      icon: <BookMarkedIcon />,
      label: 'Docs',
      link: DOCS_URL,
    },
    {
      key: 'support',
      icon: <MailIcon />,
      label: 'Support',
      link: SUPPORT_PAGE_URL,
      onAction: () => {
        ampli.contactSupportClicked({ source: 'sidebar' })
      },
    },
  ]
}
