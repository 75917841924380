import { PredefinedRange } from 'helpers/dateRange'

export type VisitsFilterView = {
  requestId?: string
  visitorId?: string
  ipAddress?: string
  url?: string
  linkedId?: string
  origin?: string
  since?: string
  before?: string
  period?: PredefinedRange['id']
}

export const defaultValues: VisitsFilterView = {
  ipAddress: '',
  linkedId: '',
  origin: '',
  requestId: '',
  url: '',
  visitorId: '',
  period: 'today',
}
