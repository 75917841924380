import { PropertyFilter } from 'models'

export interface PaginatedWebhookEventsResponse {
  events: WebhookEvent[]
  /** @deprecated **/
  lastTimestamp?: number
  paginationKey?: string
}

export interface WebhookEvent {
  requestBody: string
  requestHeaders: string
  responseBody: string
  responseHeaders: string
  status: string
  responseStatusCode: number
  subscriptionID: string
  requestID: string
  visitorID: string
  timestamp: number
  attempted: boolean
  attempts: number
  posted: boolean
  verification: boolean
  endpointID: string
}

export enum WebhookEventStatus {
  All = 'all',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export interface WebhookEventsFilter {
  status?: WebhookEventStatus
}

export type WebhookEventsIdFilter = Pick<WebhookEvent, 'requestID' | 'visitorID'>

export type WebhookEventsFilterData = {
  id?: PropertyFilter<WebhookEventsIdFilter>
}
