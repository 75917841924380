import { ChevronRight } from '@mui/icons-material'
import { Box, Link, Typography } from '@mui/material'
import clsx from 'clsx'

import styles from './GeneralSettingsEntry.module.scss'

export interface GeneralSettingsEntryProps {
  label: string
  value: string | undefined
  linkText: string
  onClickLink: () => void
}

export default function GeneralSettingsEntry({ label, value, linkText, onClickLink }: GeneralSettingsEntryProps) {
  return (
    <Box className={styles.settingsEntry}>
      <Typography variant='semiBody1' component='h3' className={styles.settingLabel}>
        {label}
      </Typography>
      <Typography variant='body2' component='div' className={clsx({ [styles.greyedOut]: !value })}>
        {value ?? 'N/A'}
      </Typography>
      <Link onClick={onClickLink} className={styles.settingUpdateLink}>
        {linkText}
        <ChevronRight className={styles.chevron} />
      </Link>
    </Box>
  )
}
