import { AppRoute, buildRoute } from 'appRoutes'
import { useAuth } from 'hooks'
import { ampli } from 'models/ampli'
import { useCallback } from 'react'

import { CommonMenuItem } from './CommonMenuSection'

export const useLogoutCommonItems: (props?: { onAction?: () => void }) => CommonMenuItem[] = (props) => {
  const { removeCredentials } = useAuth()

  const onSignOut = useCallback(() => {
    ampli.signOutClicked()
    removeCredentials()
    props?.onAction?.()
  }, [props, removeCredentials])

  return [
    {
      key: 'log-out',
      label: 'Log out',
      destructive: true,
      link: buildRoute(AppRoute.Login),
      onAction: onSignOut,
      associatedRoutes: [],
    },
  ]
}
