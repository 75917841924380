import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { Lock } from '@mui/icons-material'
import { AppRoute } from 'appRoutes'
import { StaticMessage } from 'components'
import { ExternalLayout } from 'features/commonUI'
import { getCookie } from 'helpers/cookies'
import { CustomEventGTM, sendCustomEventGTM } from 'helpers/vendor'
import { sendMetadata } from 'helpers/vendor/metadataIo'
import { useAuth, useDocumentTitle, useHomepageRedirect, useSsoLogInMutation, useToast } from 'hooks'
import { useIsFirstRender } from 'hooks/firstRender'
import { useQueryParams } from 'hooks/queryParams'
import { useSmartSignals } from 'hooks/smartSignals'
import { providerToAmplitudeTypeMap, SignupTypeByOAuthProvider, SsoAuthModel } from 'models'
import { ampli } from 'models/ampli'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export function SSOPage() {
  useHomepageRedirect()
  useDocumentTitle('SSO Log In')

  const { showToast } = useToast()
  const { setCredentials } = useAuth()
  const isFirstRender = useIsFirstRender()

  const { error: authorizationError, code, state } = useQueryParams()
  const history = useHistory()

  const { mutate: sendSSOLogInRequest, isError, error } = useSsoLogInMutation()

  const { data: visitorData } = useVisitorData({ extendedResult: true })
  const { data: smartSignals } = useSmartSignals(visitorData?.requestId)

  useEffect(() => {
    if (authorizationError || !isFirstRender) {
      return
    }

    let parsedState = {} as Partial<SsoAuthModel>

    if (state) {
      const decodedState = decodeURI(state)
      parsedState = JSON.parse(window.atob(decodedState))
    }

    const { redirectUrl, provider } = parsedState

    sendSSOLogInRequest(
      {
        data: {
          code,
          provider,
        },
      },
      {
        onSuccess: (result) => {
          setCredentials({ accessToken: result.accessToken, refreshToken: result.refreshToken })

          // Track OAuth signup.
          if (provider && result.isSignup) {
            sendCustomEventGTM(CustomEventGTM.AccountSignUp)

            ampli.createAccount({
              signupType: SignupTypeByOAuthProvider[provider],
              botDetected: smartSignals?.products?.botd?.data?.bot?.result === 'bad' ? 'true' : 'false',
              suspectScore: smartSignals?.products?.suspectScore?.data?.result?.toString() ?? '0',
            })

            // Send metadata to Metadata.io (If we have metadata_cid on cookies we need to send the email to Metadata.io for attribution purposes)
            const email = result.context?.email
            const metadataCid = getCookie('metadata_cid')

            if (metadataCid && email) {
              sendMetadata(email)
            }
          }

          if (provider) {
            ampli.userLoggedIn({ loginType: providerToAmplitudeTypeMap[provider] })
          }

          history.replace(redirectUrl ?? '/')
        },
      }
    )
  }, [
    history,
    code,
    state,
    sendSSOLogInRequest,
    authorizationError,
    setCredentials,
    isError,
    isFirstRender,
    smartSignals,
  ])

  useEffect(() => {
    if (authorizationError || isError) {
      showToast({
        id: 'ssoError',
        message: `SSO Error: ${error?.message ?? 'There was a problem with the SSO authentication.'}`,
        severity: 'error',
      })
      history.replace(AppRoute.Login)
    }
  }, [authorizationError, isError, error, showToast, history])

  if (authorizationError || isError) {
    return (
      <ExternalLayout>
        <StaticMessage
          Icon={Lock}
          title='SSO Authentication'
          message={error?.message ?? 'There was a problem with the SSO authentication.'}
        />
      </ExternalLayout>
    )
  }

  return (
    <ExternalLayout>
      <StaticMessage Icon={Lock} title='SSO Authentication' message='Exchanging SSO token...' />
    </ExternalLayout>
  )
}
