export type GetDistributionsQuery = {
  from?: string
  to?: string
}

export enum SubscriptionDistribution {
  BrowserCounters = 'browsers',
  ClientTypeCounters = 'clientTypes',
  ClientVersionCounters = 'clientVersions',
  CountryCounters = 'countries',
  DomainCounters = 'domains',
  // Ipv4Counters = 'IpsV4',
  OperatingSystemCounters = 'operatingSystems',
  UrlCounters = 'urls',
}

type DistributionValueMapping =
  | {
      type: SubscriptionDistribution.BrowserCounters
      browserName: string
      totalEvents: string
    }
  | {
      type: SubscriptionDistribution.ClientTypeCounters
      clientType: string
      totalEvents: string
    }
  | {
      type: SubscriptionDistribution.ClientVersionCounters
      clientInformation: string
      totalEvents: string
    }
  | {
      type: SubscriptionDistribution.CountryCounters
      IpInfo: string
      totalEvents: string
    }
  | {
      type: SubscriptionDistribution.DomainCounters
      domain: string
      totalEvents: string
    }
  | {
      type: SubscriptionDistribution.OperatingSystemCounters
      os: string
      totalEvents: string
    }
  | {
      type: SubscriptionDistribution.UrlCounters
      url: string
      totalEvents: string
    }
// | {
//     type: SubscriptionDistribution.Ipv4Counters
//     ipv4: string
//     totalEvents: string
//   }

export type DistributionNamedValue<Distribution extends SubscriptionDistribution> = Omit<
  DistributionValueMapping & { type: Distribution },
  'type'
>

export type DistributionReturnType = {
  type: SubscriptionDistribution
  data: DistributionNamedValue<SubscriptionDistribution>[]
}

export type SubscriptionDistributionChartResponse = {
  from: string
  to: string
  charts: DistributionReturnType[]
}
