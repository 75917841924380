import { useQuery, useQueryClient } from '@tanstack/react-query'

import { SuccessResponse } from '../../const'
import { UserSettings } from '../../models'
import { useAuth } from '../user'
import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

const USER_SETTINGS_QUERY_KEY = 'userSettings' as const

export function useUserSettings<T extends keyof UserSettings>(settingsName: T, enabled = true) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()
  const { isAuthorized } = useAuth()

  return useQuery({
    queryKey: [USER_SETTINGS_QUERY_KEY, settingsName] as const,
    queryFn: () =>
      extractData<UserSettings[T]>(
        withErrorHandling(buildRequest('userSettings', { params: { settingsName } })) as Promise<
          SuccessResponse<UserSettings[T]>
        >
      ),
    enabled: enabled && isAuthorized,
  })
}

export function useUserSettingsUpdate<T extends keyof UserSettings>(settingsName: T) {
  const queryKey = [USER_SETTINGS_QUERY_KEY, settingsName] as const
  const queryClient = useQueryClient()

  return useRequestMutation('userSettingsUpdate', {
    onMutate: async ({ data }) => {
      await queryClient.cancelQueries({ queryKey })
      const previousValue = queryClient.getQueryData<UserSettings[T]>(queryKey)
      queryClient.setQueryData<UserSettings[T]>(queryKey, data?.settings)
      return { previousValue }
    },
    onError: (_, __, { previousValue }) => {
      queryClient.setQueryData<UserSettings[T]>(queryKey, previousValue)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKey })
    },
  })
}
