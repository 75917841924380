import { WebhookEvent, WebhookEventsFilterData, WebhookEventStatus } from '../models'
import { useWebhookEvent } from './api/webhookEvents'
import { PaginatedData, useNoSqlPaginatedQuery } from './noSqlPaginatedQuery'

const DEFAULT_WEBHOOK_EVENT_QUERY_LIMIT = 10

interface PaginatedWebhookEventsBaseParams {
  subscriptionId: string
  webhookId: string
  enabled?: boolean
}

export interface UsePaginatedWebhookEventsParams extends PaginatedWebhookEventsBaseParams {
  limit?: number
  reverse?: boolean
  filter?: WebhookEventsFilterData
}

export function usePaginatedWebhookEvents({
  subscriptionId,
  webhookId,
  limit,
  reverse,
  filter,
}: UsePaginatedWebhookEventsParams) {
  const status = WebhookEventStatus.All

  const webhookEvents: PaginatedData<WebhookEvent> = useNoSqlPaginatedQuery({
    queryDescriptor: {
      key: ['webhookEvents', subscriptionId, webhookId, status],
      dataKey: 'events',
      endpoint: 'webhookEventsGet',
      options: {
        params: {
          subscriptionId,
          webhookId,
        },
        queryParams: {
          limit: limit ?? DEFAULT_WEBHOOK_EVENT_QUERY_LIMIT,
          status,
        },
      },
      config: {
        enabled: !filter?.id,
      },
    },
    pagination: {
      limit: limit ?? DEFAULT_WEBHOOK_EVENT_QUERY_LIMIT,
      reverse,
    },
    paginationType: 'reversed',
    refreshOnChange: [subscriptionId, webhookId],
  })

  const visitorWebhookEvents = usePaginatedVisitorWebhookEvents({
    subscriptionId,
    webhookId,
    visitorId: filter?.id?.value ?? '',
    status,
    enabled: filter?.id?.key === 'visitorID',
  })

  const {
    data: webhookEvent,
    error: webhookEventError,
    isFetching: isFetchingWebhookEvent,
  } = useWebhookEvent({
    subscriptionId,
    webhookId,
    requestId: filter?.id?.value ?? '',
    enabled: filter?.id?.key === 'requestID',
  })

  if (filter?.id?.key === 'visitorID') {
    return visitorWebhookEvents
  } else if (filter?.id?.key === 'requestID') {
    return { data: webhookEvent ? [webhookEvent] : [], error: webhookEventError, isLoading: isFetchingWebhookEvent }
  }

  return webhookEvents
}

export interface UsePaginatedVisitorWebhookEventsParams extends PaginatedWebhookEventsBaseParams {
  visitorId: string
  limit?: number
  status?: WebhookEventStatus
}

export function usePaginatedVisitorWebhookEvents({
  subscriptionId,
  webhookId,
  visitorId,
  limit,
  status,
  enabled,
}: UsePaginatedVisitorWebhookEventsParams): PaginatedData<WebhookEvent> {
  return useNoSqlPaginatedQuery({
    queryDescriptor: {
      key: ['visitorWebhookEvents', subscriptionId, webhookId, visitorId, status ?? WebhookEventStatus.All],
      dataKey: 'events',
      endpoint: 'visitorWebhookEventsGet',
      options: {
        params: {
          subscriptionId,
          webhookId,
          visitorId,
        },
        queryParams: {
          limit: limit ?? DEFAULT_WEBHOOK_EVENT_QUERY_LIMIT,
          status,
        },
      },
      config: {
        enabled,
      },
    },
    pagination: {
      limit: limit ?? DEFAULT_WEBHOOK_EVENT_QUERY_LIMIT,
    },
    refreshOnChange: [subscriptionId, webhookId, visitorId],
  })
}
