import { useQuery } from '@tanstack/react-query'
import { GenericError } from 'const'

import { extractData, useBuildRequest } from './base'

export interface UseEventParams {
  subscriptionId: string
  requestId: string
}

export function useEvent({ subscriptionId, requestId }: UseEventParams) {
  const buildRequest = useBuildRequest()
  return useQuery({
    queryKey: ['event', subscriptionId, requestId] as const,
    queryFn: () =>
      extractData(
        buildRequest('eventGet', {
          params: {
            subscriptionId,
            requestId,
          },
        })
      ),
    enabled: subscriptionId != null && requestId != null,
    onError: (err: GenericError) => err, // type infer helper
  })
}
