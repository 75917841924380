import { useMemo } from 'react'

import { DateRange, PredefinedRange } from '../../../helpers/dateRange'
import { useDateRangeContext } from '../components/DateRangeContext'

export const useDefaultPredefinedRanges = (): { ranges: Record<PredefinedRange['id'], PredefinedRange> } => {
  const { today } = useDateRangeContext()

  const ranges = useMemo(() => {
    // Required invariant: all possible ranges must be listed here, see definition of PredefinedRange type
    const definedRanges: PredefinedRange[] = [
      {
        id: 'today',
        label: 'Today',
        startDate: today.startOf('day'),
        endDate: today.startOf('minute'),
        compareStart: today.startOf('day').minus({ days: 1 }),
        compareEnd: today.endOf('day').minus({ days: 1 }),
      },
      {
        id: 'last_7_days',
        label: 'Last 7 days',
        startDate: today.startOf('day').minus({ days: 7 }),
        endDate: today.endOf('day').minus({ days: 1 }),
        compareStart: today.startOf('day').minus({ days: 14 }),
        compareEnd: today.endOf('day').minus({ days: 8 }),
      },
      {
        id: 'last_30_days',
        label: 'Last 30 days',
        startDate: today.startOf('day').minus({ days: 30 }),
        endDate: today.endOf('day').minus({ days: 1 }),
        compareStart: today.startOf('day').minus({ days: 60 }),
        compareEnd: today.endOf('day').minus({ days: 31 }),
      },
      {
        id: 'last_3_months',
        label: 'Last 3 months',
        startDate: today.startOf('day').minus({ months: 3 }),
        endDate: today.endOf('day').minus({ days: 1 }),
        compareStart: today.startOf('day').minus({ months: 6 }),
        compareEnd: today.endOf('day').minus({ months: 3 }).minus({ days: 1 }),
      },
      {
        id: 'last_6_months',
        label: 'Last 6 months',
        startDate: today.startOf('day').minus({ months: 6 }),
        endDate: today.endOf('day').minus({ days: 1 }),
        compareStart: today.startOf('day').minus({ months: 12 }),
        compareEnd: today.endOf('day').minus({ months: 6 }).minus({ days: 1 }),
      },
      {
        id: 'last_12_months',
        label: 'Last 12 months',
        startDate: today.startOf('day').minus({ months: 12 }),
        endDate: today.endOf('day').minus({ days: 1 }),
        compareStart: today.startOf('day').minus({ months: 24 }),
        compareEnd: today.endOf('day').minus({ months: 12 }).minus({ days: 1 }),
      },
      {
        id: 'week_to_date',
        label: 'Week to date',
        // note: using bare startOf('week') here to start from Monday independently on locale, details in DASH-1550
        startDate: today.startOf('week'),
        endDate: today.endOf('day').minus({ days: 1 }),
        compareStart: today.startOf('week').minus({ weeks: 1 }),
        compareEnd: today.endOf('day').minus({ weeks: 1 }).minus({ days: 1 }),
      },
      {
        id: 'month_to_date',
        label: 'Month to date',
        startDate: today.startOf('month'),
        endDate: today.endOf('day').minus({ days: 1 }),
        compareStart: today.startOf('month').minus({ months: 1 }),
        compareEnd: today.endOf('day').minus({ months: 1 }).minus({ days: 1 }),
      },
    ]

    return Object.fromEntries(definedRanges.map((entry) => [entry.id, entry])) as Record<
      PredefinedRange['id'],
      PredefinedRange
    >
  }, [today])

  return { ranges }
}

export const toCustomDateRange = (range: Required<DateRange>) => {
  const diff = range.endDate.diff(range.startDate, 'days').days ?? 0
  return {
    ...range,
    compareStart: range.startDate.minus({ days: diff }),
    compareEnd: range.endDate.minus({ days: diff }),
  }
}
