import clsx from 'clsx'
import { ComponentProps, ElementType, PropsWithChildren } from 'react'

import styles from './Stack.module.css'

type BreakpointConfiguration<T> = {
  default?: T
  sm?: T
  md?: T
  lg?: T
  xl?: T
  '2xl'?: T
}

type Direction = 'row' | 'column'
type Gap = number
type Params<T extends ElementType> = {
  direction?: Direction | BreakpointConfiguration<Direction>
  gap?: Gap | BreakpointConfiguration<Gap>
  gapX?: Gap | BreakpointConfiguration<Gap>
  gapY?: Gap | BreakpointConfiguration<Gap>
  className?: string
  as?: T
} & ComponentProps<T>

export function Stack<T extends ElementType>({
  children,
  direction,
  gap,
  gapX,
  gapY,
  className,
  as: Component = 'div',
  ...props
}: PropsWithChildren<Params<T>>) {
  const directionClasses =
    typeof direction === 'string'
      ? direction === 'row'
        ? 'flex-row'
        : 'flex-col'
      : clsx({
          'flex-col': direction?.default === 'column',
          'flex-row': direction?.default === 'row',
          'sm:flex-col': direction?.sm === 'column',
          'sm:flex-row': direction?.sm === 'row',
          'md:flex-col': direction?.md === 'column',
          'md:flex-row': direction?.md === 'row',
          'lg:flex-col': direction?.lg === 'column',
          'lg:flex-row': direction?.lg === 'row',
          'xl:flex-col': direction?.xl === 'column',
          'xl:flex-row': direction?.xl === 'row',
          '2xl:flex-col': direction?.['2xl'] === 'column',
          '2xl:flex-row': direction?.['2xl'] === 'row',
        })

  const x = valueObject(gapX, gap)
  const y = valueObject(gapY, gap)

  return (
    <Component
      style={
        {
          '--gap-x': x?.default,
          '--sm-gap-x': x?.sm,
          '--md-gap-x': x?.md,
          '--lg-gap-x': x?.lg,
          '--xl-gap-x': x?.xl,
          '--2xl-gap-x': x['2xl'],
          '--gap-y': y?.default,
          '--sm-gap-y': y?.sm,
          '--md-gap-y': y?.md,
          '--lg-gap-y': y?.lg,
          '--xl-gap-y': y?.xl,
          '--2xl-gap-y': y?.['2xl'],
        } as any
      }
      className={clsx(['flex', directionClasses, className, styles.gap])}
      {...props}
    >
      {children}
    </Component>
  )
}

function valueObject<T>(
  orientationValues?: T | BreakpointConfiguration<T>,
  genericValues?: T | BreakpointConfiguration<T>
): BreakpointConfiguration<T> {
  const values = orientationValues ?? genericValues
  return values == null || typeof values === 'number'
    ? {
        default: values,
        sm: values,
        md: values,
        lg: values,
        xl: values,
        '2xl': values,
      }
    : values
}
