import { Badge, SelectItem, SelectListBox, SelectPopover, SelectTrigger } from '@compass/components'
import { DialogTrigger } from 'react-aria-components'

import { colorMap, PlatformFilterIndicator } from '../PlatformFilterIndicator/PlatformFilterIndicator'
import styles from './PlatformSelector.module.scss'

interface PlatformSelectorProps {
  value: PlatformType[]
  metricsCount: number
  maxMetricsCount: number
  onChange: (type: PlatformType[]) => void
}

type SignalType = {
  key: string
  label: string
}

export type PlatformType = 'ios' | 'android' | 'js'

const signalTypes: SignalType[] = [
  { key: 'js', label: 'Web' },
  { key: 'android', label: 'Android' },
  { key: 'ios', label: 'iOS' },
]

export function PlatformSelector({ value, metricsCount, maxMetricsCount, onChange }: PlatformSelectorProps) {
  return (
    <>
      <DialogTrigger>
        <SelectTrigger tone='secondary' className='flex-grow justify-center'>
          <PlatformFilterIndicator platforms={value?.map((v) => v)} hideNonSelected={false} />
          Smart Signals
          <Badge tone='secondary'>
            {metricsCount}/{maxMetricsCount}
          </Badge>
        </SelectTrigger>
        <SelectPopover>
          <SelectListBox
            aria-label='Platform filter'
            selectionBehavior='toggle'
            selectionMode='multiple'
            selectedKeys={value}
            onSelectionChange={(selection) => onChange([...selection] as PlatformType[])}
          >
            {signalTypes.map((signal) => (
              <SelectItem key={signal.key} id={signal.key} value={signal} textValue={signal.label}>
                <span className={styles.colorBall} style={{ backgroundColor: colorMap[signal.key] }} />
                {signal.label}
              </SelectItem>
            ))}
          </SelectListBox>
        </SelectPopover>
      </DialogTrigger>
    </>
  )
}
