import { useCallback } from 'react'

import {
  useCloudFrontIntegrationData,
  useCreateCloudFrontIntegration,
  useDeleteCloudFrontIntegration,
  useUpdateCloudFrontIntegration,
} from '../../../../hooks/api/integrations_cloudfront'
import { useSdkHealthQuery } from '../../../health/hooks/useSdkHealthQuery'
import { useCurrentSubscription } from '../../../subscription'
import { ThirdPartyIntegration } from '../../components/IntegrationMetadata/integrationMetadata'
import { useIntegrationStatsQuery } from '../../hooks/useIntegrationStatsQuery'
import { IntegrationCloudFrontContents } from './IntegrationCloudFrontContents'

export function IntegrationCloudFrontPage() {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data, isLoading: isSDKHealthLoading } = useSdkHealthQuery(currentSubscriptionId)
  const { data: requestsMade, isLoading: statsIsLoading } = useIntegrationStatsQuery(
    ThirdPartyIntegration.CloudFront,
    currentSubscriptionId
  )
  const { data: cloudFrontData, isLoading: getIsLoading } = useCloudFrontIntegrationData(currentSubscriptionId)
  const { mutateAsync: createCloudFrontIntegrationRequest, isLoading: createIsLoading } =
    useCreateCloudFrontIntegration()
  const { mutateAsync: updateCloudFrontIntegrationRequest, isLoading: updateIsLoading } =
    useUpdateCloudFrontIntegration()
  const { mutateAsync: deleteCloudFrontIntegrationRequest, isLoading: deleteIsLoading } =
    useDeleteCloudFrontIntegration()

  const isLoading = isSDKHealthLoading || statsIsLoading || getIsLoading || createIsLoading
  const isConnecting = createIsLoading || updateIsLoading

  const cloudFrontHealth = data?.platform.find((t) => t.sdkName === 'CloudFront')

  const connectCloudFrontIntegration = useCallback(
    async (managementLambdaURL: string, authToken: string) => {
      const reqObj = {
        data: {
          managementLambdaPublicURL: managementLambdaURL.trim(),
          managementLambdaAuthToken: authToken,
        },
        params: { subscriptionId: currentSubscriptionId },
      }
      if (cloudFrontData) {
        await updateCloudFrontIntegrationRequest(reqObj)
      } else {
        await createCloudFrontIntegrationRequest(reqObj)
      }
    },
    [currentSubscriptionId, cloudFrontData, createCloudFrontIntegrationRequest, updateCloudFrontIntegrationRequest]
  )

  const disconnectCloudFrontIntegration = useCallback(async () => {
    await deleteCloudFrontIntegrationRequest({
      params: { subscriptionId: currentSubscriptionId },
    })
  }, [currentSubscriptionId, deleteCloudFrontIntegrationRequest])

  return (
    <>
      <IntegrationCloudFrontContents
        subscriptionId={currentSubscriptionId}
        requestsMade={requestsMade}
        health={cloudFrontHealth}
        isLoading={isLoading}
        isConnecting={isConnecting}
        isDeleting={deleteIsLoading}
        cloudFrontData={cloudFrontData}
        connectCloudFrontIntegration={connectCloudFrontIntegration}
        disconnectCloudFrontIntegration={disconnectCloudFrontIntegration}
      />
    </>
  )
}
