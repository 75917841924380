import { CircularProgress } from '@mui/material'

import styles from './IntegrationPage.module.scss'
import IntegrationPageContents, {
  IntegrationPageContentsProps,
} from './IntegrationPageContents/IntegrationPageContents'
import { IntegrationTopFrameProps } from './IntegrationTopFrame/IntegrationTopFrame'

type Props = IntegrationTopFrameProps &
  IntegrationPageContentsProps & {
    isLoading?: boolean
    subscriptionId: string
  }

export default function IntegrationPage(props: Props) {
  const { isLoading } = props

  return isLoading ? (
    <div className={styles.progressContainer} data-testid='integration-page-loader'>
      <CircularProgress />
    </div>
  ) : (
    <IntegrationPageContents {...props} />
  )
}
