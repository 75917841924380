import { Button } from '@compass/components'
import { Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import Loader from 'components/Loader/Loader'
import { ContentColumn, SubHeader } from 'features/commonUI'
import { useCurrentUser } from 'hooks/api/users'
import { UserRoundPlus } from 'lucide-react'
import { AccountTransferRequest, User, UserRole, UserStatus } from 'models'
import { useMemo } from 'react'

import { UsersCompactTable } from '../UsersCompactTable/UsersCompactTable'
import { UsersList } from '../UsersList/UsersList'
import { UsersTable } from '../UsersTable/UsersTable'
import { ManagedUser } from './UserManagementData'

export interface UserManagementPermissions {
  edit: boolean
  delete: boolean
  transferOwnership: boolean
}

export interface UserManagementProps {
  users?: User[]
  isLoading?: boolean
  isSsoEnabled?: boolean
  permissions: UserManagementPermissions
  onOpenAddDialog: () => void
  onOpenEditDialog: (userId: string) => void
  onOpenDeleteDialog: (userId: string) => void

  pendingAccountTransfer?: AccountTransferRequest
  onTransferAccount: (target: ManagedUser) => void
  onCancelTransferAccount: (target: ManagedUser) => void
}

export function UserManagement({
  users,
  isLoading,
  permissions,
  onOpenDeleteDialog,
  onOpenAddDialog,
  onOpenEditDialog,
  pendingAccountTransfer,
  onTransferAccount,
  onCancelTransferAccount,
}: UserManagementProps) {
  const isReadonly = !permissions.edit && !permissions.delete
  const { data: currentUser } = useCurrentUser()

  const theme = useTheme()

  const displayFullTable = useMediaQuery(theme.breakpoints.up('md'))
  const displayCompactTable = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const displayList = useMediaQuery(theme.breakpoints.down('sm'))

  const managedUsers = useMemo<ManagedUser[] | undefined>(
    () =>
      isLoading || !users
        ? undefined
        : users.map((user) => ({
            ...user,
            isEditable: permissions.edit,
            isDeletable: permissions.delete && currentUser?.id !== user.id && user.role !== UserRole.Owner,
            canReceiveOwnership:
              !pendingAccountTransfer &&
              permissions.transferOwnership &&
              user.role !== UserRole.Owner &&
              user.status === UserStatus.Active,
            isPendingTransfer: permissions.transferOwnership && pendingAccountTransfer?.newOwnerId === user.id,
          })),
    [isLoading, users, permissions, currentUser, pendingAccountTransfer]
  )

  return (
    <ContentColumn>
      <SubHeader
        title='Team'
        description={
          <Typography variant='bodyM'>
            {isReadonly ? 'Review' : 'Manage'} access to the Fingerprint Dashboard.
          </Typography>
        }
        actions={
          isReadonly ? null : (
            <Button onPress={onOpenAddDialog} isDisabled={isLoading}>
              <UserRoundPlus />
              Add people
            </Button>
          )
        }
      />
      <Paper className='p-6 relative'>
        {isLoading ? <Loader testId='user-management-loader' /> : null}

        {displayFullTable && (
          <UsersTable
            currentUser={currentUser}
            onEditUser={onOpenEditDialog}
            onDeleteUser={onOpenDeleteDialog}
            users={managedUsers}
            pendingAccountTransfer={pendingAccountTransfer}
            onTransferOwnership={onTransferAccount}
            onCancelOwnershipTransfer={onCancelTransferAccount}
          />
        )}

        {displayCompactTable && (
          <UsersCompactTable
            currentUser={currentUser}
            onEditUser={onOpenEditDialog}
            onDeleteUser={onOpenDeleteDialog}
            users={managedUsers}
            pendingAccountTransfer={pendingAccountTransfer}
            onTransferOwnership={onTransferAccount}
            onCancelOwnershipTransfer={onCancelTransferAccount}
          />
        )}

        {displayList && (
          <UsersList
            currentUser={currentUser}
            onEditUser={onOpenEditDialog}
            onDeleteUser={onOpenDeleteDialog}
            users={managedUsers}
            pendingAccountTransfer={pendingAccountTransfer}
            onTransferOwnership={onTransferAccount}
            onCancelOwnershipTransfer={onCancelTransferAccount}
          />
        )}
      </Paper>
    </ContentColumn>
  )
}
