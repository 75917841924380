import { Button } from '@compass/components'
import { ExpandLess, ExpandMore, InfoOutlined } from '@mui/icons-material'
import { Alert, Collapse, FormControlLabel, InputLabel, Link, Switch, TextField, Typography } from '@mui/material'
import clsx from 'clsx'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from 'features/commonUI'
import { getErrorParams } from 'helpers/data'
import { muiRegister } from 'helpers/reactHookForm'
import { URL_MAX_LENGTH, validateDomainName } from 'helpers/validation'
import { ApplicationFeatureName, SubscriptionWebhook } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { DOCS_WEBHOOK_SECURITY_URL, USE_WEBHOOK_SIGNATURE } from '../../../../const'
import { useIsApplicationFeatureEnabled } from '../../../../hooks'
import { useCurrentSubscription } from '../../../subscription'
import styles from './WebhookDialog.module.scss'

export type WebhookFormData = Pick<
  SubscriptionWebhook,
  'url' | 'basicAuthUser' | 'basicAuthPassword' | 'description' | 'active'
>

export interface WebhookDialogProps extends DialogProps {
  webhook?: SubscriptionWebhook
  onSubmit: (data: WebhookFormData) => void
}

export default function WebhookDialog({ webhook, onSubmit, onClose, ...dialogProps }: WebhookDialogProps) {
  const defaultValues = useMemo(
    () => ({
      id: webhook?.id ?? '',
      url: webhook?.url ?? '',
      description: webhook?.description ?? '',
      basicAuthUser: webhook?.basicAuthUser ?? '',
      basicAuthPassword: webhook?.basicAuthPassword ?? '',
      active: webhook?.active ?? true,
    }),
    [webhook]
  )

  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  const isWebhookSignatureEnabled = useIsApplicationFeatureEnabled(
    subscriptionId,
    ApplicationFeatureName.WebhookSignatures
  )

  const handleClose = () => {
    reset()

    if (onClose) {
      onClose()
    }
  }

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  })
  const [isAuthSectionOpen, setIsAuthSectionOpen] = useState(false)
  const isActive = watch('active')

  useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  return (
    <Dialog onClose={handleClose} {...dialogProps}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogTitle onClose={handleClose}>{!webhook ? 'Create new Webhook' : 'Edit Webhook'}</DialogTitle>

        <DialogContent>
          {USE_WEBHOOK_SIGNATURE && isWebhookSignatureEnabled && (
            <Alert severity='info' icon={<InfoOutlined />} className={styles.alert}>
              <>
                Webhooks can be secured using signing, so using basic authentication for extra security is optional.{' '}
                <Link href={DOCS_WEBHOOK_SECURITY_URL} target='_blank' color='inherit'>
                  Learn more about securing webhooks
                </Link>
              </>
              .
            </Alert>
          )}

          {webhook?.id && <input type='hidden' value={webhook.id} {...register('id')} />}

          <InputLabel htmlFor='url'>URL</InputLabel>
          <TextField
            id='url'
            required
            variant='outlined'
            fullWidth
            placeholder='https://domain.com'
            defaultValue={webhook?.url}
            inputProps={{ 'aria-label': 'URL' }}
            {...muiRegister(register, 'url', {
              required: 'URL address is required',
              maxLength: { value: URL_MAX_LENGTH, message: 'URL is too long' },
              validate: {
                domainName: validateDomainName,
              },
            })}
            {...getErrorParams('url', errors)}
            className={styles.input}
          />

          <InputLabel htmlFor='description'>Description (optional)</InputLabel>
          <TextField
            id='description'
            variant='outlined'
            fullWidth
            placeholder='A short description of my webhook.'
            inputProps={{ 'aria-label': 'Description' }}
            defaultValue={webhook?.description}
            {...muiRegister(register, 'description', {
              maxLength: {
                value: 256,
                message: 'Description is too long',
              },
            })}
            {...getErrorParams('description', errors)}
            className={styles.input}
          />

          <Typography
            variant='body2'
            className={styles.expandLabel}
            onClick={() => setIsAuthSectionOpen(!isAuthSectionOpen)}
          >
            Basic authentication
            {isAuthSectionOpen ? <ExpandLess /> : <ExpandMore />}
          </Typography>

          <Collapse in={isAuthSectionOpen}>
            <InputLabel htmlFor='basicAuthUser'>User</InputLabel>
            <TextField
              id='basicAuthUser'
              variant='outlined'
              fullWidth
              placeholder='Username'
              defaultValue={webhook?.basicAuthUser}
              inputProps={{ 'aria-label': 'User' }}
              {...muiRegister(register, 'basicAuthUser', {
                maxLength: {
                  value: 128,
                  message: 'Username is too long',
                },
              })}
              {...getErrorParams('basicAuthUser', errors)}
              className={styles.input}
            />

            <InputLabel htmlFor='basicAuthPassword'>Password</InputLabel>
            <TextField
              id='basicAuthPassword'
              variant='outlined'
              fullWidth
              placeholder='Password'
              type='password'
              autoComplete='new-password'
              defaultValue={webhook?.basicAuthPassword}
              inputProps={{ 'aria-label': 'Password' }}
              {...muiRegister(register, 'basicAuthPassword', {
                maxLength: {
                  value: 128,
                  message: 'Password is too long',
                },
              })}
              {...getErrorParams('basicAuthPassword', errors)}
              className={styles.input}
            />
          </Collapse>

          {webhook && (
            <>
              <InputLabel className={clsx(styles.label, styles.statusLabel)}>Webhook status</InputLabel>
              <FormControlLabel
                control={<Switch color='primary' defaultChecked={isActive ?? true} />}
                label={isActive ? 'Enabled' : 'Disabled'}
                {...muiRegister(register, 'active')}
              />
            </>
          )}
        </DialogContent>

        <DialogActions className={styles.actions}>
          <Button variant='ghost' onPress={handleClose}>
            Cancel
          </Button>
          <Button type='submit'>{!webhook ? 'Create Webhook' : 'Edit Webhook'}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
