import { Typography } from '@mui/material'
import Loader from 'components/Loader/Loader'
import { Header, MainColumn, SubHeader } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useDocumentTitle } from 'hooks'
import { useEntityLimit } from 'hooks/api/context'
import { useEncryptionKeys, useSubscriptionTokens } from 'hooks/api/tokens'
import { ApiKey, ApiKeyType, Limits } from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useMemo, useState } from 'react'

import { USE_ENCRYPTED_KEYS, USE_MANAGEMENT_KEYS } from '../../const'
import ActivateApiKeyDialog from './components/ActivateApiKeyDialog/ActivateApiKeyDialog'
import { default as CreateApiKeyDialog } from './components/CreateApiKeyDialog/CreateApiKeyDialog'
import DeactivateApiKeyDialog from './components/DeactivateApiKeyDialog/DeactivateApiKeyDialog'
import { default as DeleteApiKeyDialog } from './components/DeleteApiKeyDialog/DeleteApiKeyDialog'
import { apiKeyDisplayNameMap, default as ApiKeysSection } from './components/KeySection/KeySection'
import { default as ManageApiKeyDialog } from './components/ManageApiKeyDialog/ManageApiKeyDialog'

export function ApiKeysPage() {
  useDocumentTitle('API keys')
  const { currentSubscriptionId } = useCurrentSubscription()

  const [isManageDialogOpen, setIsManageDialogOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false)
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false)
  const [defaultCreateType, setDefaultCreateType] = useState(ApiKeyType.Public)
  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey>()

  const { data: apiKeysData, isLoading: isLoadingApiKeys } = useSubscriptionTokens(currentSubscriptionId)
  const { data: encryptedApiKeysData, isLoading: isLoadingEncryptedApiKeys } = useEncryptionKeys(currentSubscriptionId)
  const maxApiKeyCreatedLimit = useEntityLimit(currentSubscriptionId, Limits.Tokens)

  const sortedApiKeys = Array.from(apiKeysData ?? []).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))

  const encryptedApiKeys = Array.from(encryptedApiKeysData ?? [])
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    .map(
      (encryptedKey) =>
        ({
          ...encryptedKey,
          type: 'encryption',
        }) as ApiKey
    )

  const apiKeys = useMemo(() => {
    return [...sortedApiKeys, ...encryptedApiKeys]
  }, [sortedApiKeys, encryptedApiKeys])

  const onClickCreateApiKey = useCallback((apiKeyType: ApiKeyType) => {
    ampli.apiKeyAddingStarted({ apiKeyType: apiKeyDisplayNameMap[apiKeyType] })
    setDefaultCreateType(apiKeyType)
    setIsCreateDialogOpen(true)
  }, [])

  const onClickActivateKey = useCallback(
    (apiKeyId: string) => {
      //ampli.apiKeyAddingStarted({ apiKeyType: apiKeyDisplayNameMap[apiKeyType] })
      setSelectedApiKey(apiKeys.find((key) => key.id === apiKeyId))
      setIsActivateDialogOpen(true)
    },
    [apiKeys]
  )

  const onClickDeactivateKey = useCallback(
    (apiKeyId: string) => {
      setSelectedApiKey(apiKeys.find((key) => key.id === apiKeyId))
      setIsDeactivateDialogOpen(true)
    },
    [apiKeys]
  )

  const onClickManageKey = useCallback(
    (apiKeyId: string) => {
      setSelectedApiKey(apiKeys.find((key) => key.id === apiKeyId))
      setIsManageDialogOpen(true)
    },
    [apiKeys]
  )

  const onClickDeleteKey = useCallback(
    (apiKeyId: string) => {
      setSelectedApiKey(apiKeys.find((key) => key.id === apiKeyId))
      setIsDeleteDialogOpen(true)
    },
    [apiKeys]
  )

  const onCloseDialog = useCallback(() => {
    setIsDeleteDialogOpen(false)
    setIsManageDialogOpen(false)
    setIsActivateDialogOpen(false)
    setIsDeactivateDialogOpen(false)
    setSelectedApiKey(undefined)
  }, [])

  const onCloseCreateDialog = useCallback((apiKeyType: ApiKeyType, completed: boolean) => {
    if (!completed) {
      ampli.apiKeyAddingCancelled({ apiKeyType: apiKeyDisplayNameMap[apiKeyType] })
    }
    setIsCreateDialogOpen(false)
  }, [])

  const sharedKeySectionProps = useMemo(
    () => ({
      isLoadingApiKeys,
      canCreateNewKeys: sortedApiKeys.length < maxApiKeyCreatedLimit,
      onClickManageKey,
      onClickDeleteKey,
      onClickActivateKey,
      onClickDeactivateKey,
    }),
    [
      isLoadingApiKeys,
      sortedApiKeys.length,
      maxApiKeyCreatedLimit,
      onClickManageKey,
      onClickDeleteKey,
      onClickActivateKey,
      onClickDeactivateKey,
    ]
  )

  return (
    <>
      <Header title='API keys' />
      <MainColumn>
        {isLoadingApiKeys && isLoadingEncryptedApiKeys && <Loader testId='api-keys-loader' />}
        <SubHeader
          title={<Typography variant='h2'>API keys</Typography>}
          description={
            <>
              <Typography variant='bodyM'>Manage API keys in your workspace.</Typography>
            </>
          }
        />
        {isCreateDialogOpen && (
          <CreateApiKeyDialog open onCloseCreateDialog={onCloseCreateDialog} createType={defaultCreateType} />
        )}
        {isManageDialogOpen && selectedApiKey && (
          <ManageApiKeyDialog open onClose={onCloseDialog} apiKey={selectedApiKey} />
        )}
        {isActivateDialogOpen && selectedApiKey && (
          <ActivateApiKeyDialog open onClose={onCloseDialog} apiKey={selectedApiKey} />
        )}
        {isDeactivateDialogOpen && selectedApiKey && (
          <DeactivateApiKeyDialog open onClose={onCloseDialog} apiKey={selectedApiKey} />
        )}
        {isDeleteDialogOpen && selectedApiKey && (
          <DeleteApiKeyDialog open onClose={onCloseDialog} apiKey={selectedApiKey} />
        )}
        <ApiKeysSection
          apiKeyTableType={ApiKeyType.Public}
          apiKeys={apiKeys.filter((key) => key.type === ApiKeyType.Public)}
          onClickCreateKey={() => onClickCreateApiKey(ApiKeyType.Public)}
          {...sharedKeySectionProps}
        />
        <ApiKeysSection
          apiKeyTableType={ApiKeyType.Secret}
          apiKeys={apiKeys.filter((key) => key.type === ApiKeyType.Secret)}
          onClickCreateKey={() => onClickCreateApiKey(ApiKeyType.Secret)}
          {...sharedKeySectionProps}
        />
        <ApiKeysSection
          apiKeyTableType={ApiKeyType.BrowserProxy}
          apiKeys={apiKeys.filter((key) => key.type === ApiKeyType.BrowserProxy)}
          onClickCreateKey={() => onClickCreateApiKey(ApiKeyType.BrowserProxy)}
          {...sharedKeySectionProps}
        />
        {USE_ENCRYPTED_KEYS && (
          <ApiKeysSection
            apiKeyTableType={ApiKeyType.Encryption}
            apiKeys={apiKeys.filter((key) => key.type === ApiKeyType.Encryption)}
            onClickCreateKey={() => onClickCreateApiKey(ApiKeyType.Encryption)}
            {...sharedKeySectionProps}
          />
        )}
        {USE_MANAGEMENT_KEYS && (
          <ApiKeysSection
            apiKeyTableType={ApiKeyType.Management}
            apiKeys={apiKeys.filter((key) => key.type === ApiKeyType.Management)}
            onClickCreateKey={() => onClickCreateApiKey(ApiKeyType.Management)}
            {...sharedKeySectionProps}
          />
        )}
      </MainColumn>
    </>
  )
}
