import { Button } from '@compass/components'
import { Alert } from '@mui/lab'
import { TextField, Typography } from '@mui/material'
import { ReactNode, useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { DialogActions } from '../../../commonUI'
import styles from '../../pages/IntegrationCloudflare/IntegrationCloudflareRemoval.module.scss'

export type IntegrationRemovalDialogProps = {
  onSubmit: () => void | Promise<void>
  beforeInputContent?: ReactNode
  integrationName?: string
  onClose: () => void
  title: string
}

const LONG_NAME_LENGTH = 32
const LONG_NAME_KEYWORD = 'obliterate'

export function IntegrationRemovalDialog({
  integrationName,
  beforeInputContent,
  onSubmit,
  onClose,
  title,
}: IntegrationRemovalDialogProps) {
  const [error, setSubmitError] = useState<Error | undefined>()
  const keyword = !integrationName || integrationName.length > LONG_NAME_LENGTH ? LONG_NAME_KEYWORD : integrationName

  const form = useForm<{
    confirm: string
  }>({
    defaultValues: {
      confirm: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const handleSubmit = useCallback(async () => {
    setSubmitError(undefined)
    try {
      await onSubmit()

      onClose()
    } catch (e) {
      setSubmitError(e as Error)
    }
  }, [onClose, onSubmit])

  return (
    <form className={styles.container} onSubmit={form.handleSubmit(handleSubmit)}>
      <Typography variant='h1'>{title}</Typography>
      {error ? <Alert severity='error'>{error.message}</Alert> : null}
      {beforeInputContent}
      <div>
        <Typography>
          Type <strong>{keyword}</strong> to confirm deletion
        </Typography>
        <Controller
          rules={{
            validate: (value) => {
              if (value !== keyword) {
                return `Please enter the word "${keyword}". This is required!`
              }

              return true
            },
          }}
          render={(props) => (
            <TextField
              inputProps={{
                'data-testid': props.field.name,
              }}
              className={styles.input}
              fullWidth
              {...props.field}
              error={Boolean(props.fieldState.error)}
              helperText={props.fieldState.error?.message}
              variant='outlined'
            />
          )}
          name='confirm'
          control={form.control}
        />
      </div>
      <DialogActions className={styles.actions}>
        <Button variant='primary' onPress={onClose}>
          Cancel
        </Button>
        <Button type='submit' variant='destructive'>
          Got it, delete integration
        </Button>
      </DialogActions>
    </form>
  )
}
