import { Button } from '@compass/components'
import { Typography } from '@mui/material'
import clsx from 'clsx'

import styles from './Day.module.scss'

interface DayProps {
  filled?: boolean
  outlined?: boolean
  highlighted?: boolean
  disabled?: boolean
  startOfRange?: boolean
  endOfRange?: boolean
  onClick?: () => void
  onHover?: () => void
  value: number | string
}

export default function Day({
  startOfRange,
  endOfRange,
  disabled,
  highlighted,
  outlined,
  filled,
  onClick,
  onHover,
  value,
}: DayProps) {
  let variant: 'outline' | 'none' | 'primary' = 'none'

  if (filled) {
    variant = 'primary'
  } else if (!disabled && outlined) {
    variant = 'outline'
  }

  return (
    <div
      className={clsx(
        styles.buttonContainer,
        startOfRange && styles.leftBorderRadius,
        endOfRange && styles.rightBorderRadius,
        !disabled && highlighted && styles.highlighted
      )}
    >
      <Button
        className={styles.button}
        variant={variant}
        isDisabled={disabled}
        onPress={onClick}
        onHoverStart={onHover}
      >
        <Typography
          color={!disabled ? 'textPrimary' : 'textSecondary'}
          className={clsx(styles.text, !disabled && filled && styles.filled)}
          variant='body2'
        >
          {value}
        </Typography>
      </Button>
    </div>
  )
}
