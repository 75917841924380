import { Link } from '@mui/material'

import {
  DOCS_INTEGRATIONS_SECTION_URL,
  DOCS_PUBLIC_MANAGEMENT_API,
  DOCS_SEALED_KEYS,
  DOCS_SERVER_API,
} from '../../../../const'
import { ApiKeyType } from '../../../../models'

export function KeyDescription({ keyType }: { keyType: ApiKeyType }) {
  switch (keyType) {
    case ApiKeyType.BrowserProxy:
      return (
        <>
          Use proxy keys to authenticate with{' '}
          <Link href={DOCS_INTEGRATIONS_SECTION_URL} target='_blank' underline='hover'>
            proxy integrations
          </Link>
          .
        </>
      )
    case ApiKeyType.Secret:
      return (
        <>
          Use secret keys to make server-to-server requests to the{' '}
          <Link href={DOCS_SERVER_API} target='_blank' underline='hover'>
            Fingerprint Server API
          </Link>
          .
        </>
      )
    case ApiKeyType.Encryption:
      return (
        <>
          Use secret keys to decrypt{' '}
          <Link href={DOCS_SEALED_KEYS} target='_blank' underline='hover'>
            client-side payloads
          </Link>
          .
        </>
      )
    case ApiKeyType.Management:
      return (
        <>
          Use management keys to make requests to the Fingerprint Management API.{' '}
          <Link href={DOCS_PUBLIC_MANAGEMENT_API} target='_blank' underline='hover'>
            Learn more about the Management API.
          </Link>
        </>
      )
    case ApiKeyType.Public:
      return <>Use public keys to make client-side requests and generate the visitorId.</>
  }
}

export function ShortKeyDescription({ keyType }: { keyType: ApiKeyType }) {
  switch (keyType) {
    case ApiKeyType.BrowserProxy:
      return (
        <>
          Use proxy keys to authenticate with{' '}
          <Link href={DOCS_INTEGRATIONS_SECTION_URL} target='_blank' underline='hover'>
            proxy integrations
          </Link>
          .
        </>
      )
    case ApiKeyType.Secret:
      return (
        <>
          Use secret keys to make server-to-server requests to the{' '}
          <Link href={DOCS_SERVER_API} target='_blank' underline='hover'>
            Fingerprint Server API
          </Link>
          .
        </>
      )
    case ApiKeyType.Encryption:
      return (
        <>
          Use secret keys to decrypt{' '}
          <Link href={DOCS_SEALED_KEYS} target='_blank' underline='hover'>
            client-side payloads
          </Link>
          .
        </>
      )
    case ApiKeyType.Management:
      return (
        <>
          Use management keys to request the{' '}
          <Link href={DOCS_PUBLIC_MANAGEMENT_API} target='_blank' underline='hover'>
            management API
          </Link>
          .
        </>
      )
    case ApiKeyType.Public:
      return <>Use public keys to make client-side requests and generate the visitorId.</>
  }
}
