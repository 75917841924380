import { LinearProgress } from '@mui/material'
import clsx from 'clsx'

import styles from './Loader.module.scss'

export interface LoaderProps {
  testId?: string
  className?: string
}

export default function Loader({ testId, className }: LoaderProps) {
  return (
    <div data-testid={testId ?? 'loader'} className={clsx(styles.loader, className)}>
      <LinearProgress className={styles.progress} />
    </div>
  )
}
