import { AppRoute, buildRoute } from 'appRoutes'
import { Settings } from 'lucide-react'

import { CommonMenuItem } from './CommonMenuSection'

export const useAccountMenuCommonItems: () => CommonMenuItem[] = () => {
  return [
    {
      key: 'settings',
      label: 'Settings',
      icon: <Settings />,
      link: buildRoute(AppRoute.WorkspaceList),
      associatedRoutes: [AppRoute.WorkspaceList],
    },
  ]
}
